import { getLinkedAccountList, apiUserAccountData, apiChangeAgreementAddress, apiAddTransactionHash } from "./ActionUtils"
import store from '../store';
import { openOverlay, closeOverlay } from './OverlayActions';
import React from 'react';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGADDRESS_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function refreshChangeAddressList() {
  return function(dispatch) {
    let auth = store.getState().auth;
    getLinkedAccountList(auth.uuid, auth.username, auth.username).then((list) => {
      if (list) {
        let addressList = [];
        for (let i = 0; i < list.length; i++) {
          let entry = list[i];
          if (entry.account_type === "ethereum") {
            addressList.push(entry);
          }
        }

        dispatch({
          type: 'DIALOGADDRESS_ADDRESS_LIST',
          payload: {user: auth.username, list: addressList}
        });
      }
    });
  }
}

export function refreshUserDefaultAddress() {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiUserAccountData(auth.uuid, auth.username, auth.username).then((response) => {
      if (response.status === "success") {
        let data = response.data;
        if (data && data.hasOwnProperty("default_address")) {
          dispatch({
            type: 'DIALOGADDRESS_DEFAULT_ADDRESS',
            payload: data.default_address
          });
        }
      }
    });
  }
}

export function submitAddressForAgreement(agreementid, versionid, status, address) {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiChangeAgreementAddress(auth.uuid, auth.username, agreementid, versionid, status, address).then((respObj) => {
      if (respObj.success) {
        dispatch({
          type: 'AGREEMENT_ADDRESS_UPDATED',
          payload: { agreementid, address }
        });
        
        dispatch(closeOverlay());
      }
      else if (respObj.locked) {
        dispatch(openOverlay("message", {title: "Unable to update", message:<span>{"Unable to update at this time due to a pending transaction. Try again in a few minutes."}</span>}));
      }
    });
  }
}

export function lockAgreement(agreementid) {
  return function(dispatch) {
    console.log("lockAgreement: " + agreementid);
    let auth = store.getState().auth;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, "", "", "lock").then((success) => {
      if (success) {
        console.log("Lock added");        
      }
    });
  }
}

export function unlockAgreement(agreementid) {
  return function(dispatch) {
    console.log("unlockAgreement: " + agreementid);
    let auth = store.getState().auth;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, "", "", "clear").then((success) => {
      if (success) {
        console.log("Lock cleared");
      }
    });    
  }  
}
