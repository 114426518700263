//import update from 'immutability-helper';

const emptyState = {
}

const PleaseLoginReducer = (state = emptyState, action) => {
  switch (action.type) {
    default: {
      return state
    }
  }
}

export default PleaseLoginReducer