import { imageConvertAndResize, urlConvertAndResize } from "./ActionUtils"

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGADDPHOTO_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function saveImageValue(file) {
  return function(dispatch) {
    imageConvertAndResize(file, 200, 150).then(({thumbnail, image}) => {
      dispatch({
        type: 'DIALOGADDPHOTO_SAVE_IMAGE',
        payload: {thumbnail, image}
      });
    });
  }
}

export function saveImageFromURL(url) {
  return function(dispatch) {
    urlConvertAndResize(url, 200, 133).then(({thumbnail, image}) => {
      dispatch({
        type: 'DIALOGADDPHOTO_SAVE_IMAGE',
        payload: {thumbnail, image}
      });

      dispatch({
        type: 'DIALOGADDPHOTO_CHANGE_INPUT_VALUE',
        payload: {name: "showEnterURL", value: false}
      });
    });
  }
}

export function addPhoto(thumbnailContent, imageContent) {
  return function(dispatch) {
    if (thumbnailContent !== "" && imageContent !== "") {
      dispatch({
        type: 'POSTING_ADD_PHOTO',
        payload: { thumbnailContent, imageContent }
      });  
    }
  }
}