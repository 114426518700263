//import update from 'immutability-helper';

const emptyState = {
  selectedUser: "",
  mode: "start",
  contractTypeMaxButtonWidth: 0,
  paymentMaxButtonWidth: 0
}

const DialogNewContractReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGNEWCONTRACT_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGNEWCONTRACT_SELECT_MODE" : {
      let { mode } = action.payload;
      return {...state, mode};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "new_contract") {
        let { user="" } = params;
        return {...state, ...emptyState, selectedUser: user };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogNewContractReducer