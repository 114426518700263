import utils from 'web3-utils';
import store from '../store';
import { shared } from "../Shared";
import { apiEvidenceURL, getEthersJSContractForAgreement } from "./ActionUtils";
import { hasDeployTransactionUpdate, hasActionTransactionUpdate, hasEvidenceTransactionUpdate } from "./AuthActions";
import { ethers } from 'ethers';

export function padStringAsTopic(str) {
  let paddedStr = "0x0000000000000000000000000000000000000000000000000000000000000000";
  if (str.length <= 64) {
    let startChars = 66 - str.length;
    return paddedStr.substring(0, startChars) + str;
  }

  return paddedStr;
}

export function remove0x(str) {
  if (str.length >= 2 && str.substring(0, 2) === "0x") {
    return str.substring(2);
  }

  return str;
}

// -----------------------------------------------------------
// AgreementManager

export async function eth_createAgreementA(
  agreementid, 
  contractType,
  agreementHash, 
  metaevidenceHash, 
  partyA, partyB, arbiter, 
  partyAStakeAmount, partyBStakeAmount, partyAInitialArbitratorFee, partyBInitialArbitratorFee, disputeFee, automaticResolution, nDaysToRespondToArbitrationRequest, arbitrationRequestAllowedAfterTimestamp, autoResolveAfterTimestamp
) {
  let agreementURI = apiEvidenceURL("https://api.atstake.net/prod/metaevidence/" + metaevidenceHash);
  let arbExtraData = utils.asciiToHex("");

  store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "start", ""));

  let receipt = null;
  let contract = getEthersJSContractForAgreement(contractType);
  let overrides = {
    value: ethers.utils.bigNumberify(partyAStakeAmount.toString())
  };

  try {
    const txObj = await contract.createAgreementA(
      agreementHash.toString(), 
      agreementURI.toString(),
      [partyA.toString(), partyB.toString(), arbiter.toString()], 
      [partyAStakeAmount.toString(), partyBStakeAmount.toString(), partyAInitialArbitratorFee.toString(), partyBInitialArbitratorFee.toString(), disputeFee.toString(), automaticResolution.toString(), nDaysToRespondToArbitrationRequest.toString(), arbitrationRequestAllowedAfterTimestamp.toString(), autoResolveAfterTimestamp.toString()], 
      arbExtraData,
      overrides
    );

    const hash = txObj ? txObj.hash : "";
    store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "hash", hash));

    receipt = await window.ethersProvider.waitForTransaction(hash);
    store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "receipt", receipt));
  }
  catch (err) {
    console.log("Deploy transaction error (B)");
    console.log(err);
    receipt = null
    store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "error", err));
  }

  return receipt;
}

export async function eth_depositB(
  agreementid,
  contractType,
  deployedid,
  partyBStakeAmount
) {  
    let actionType = "deposit";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);
    let overrides = {
      value: ethers.utils.bigNumberify(partyBStakeAmount.toString())
    };
  
    try {
      const txObj = await contract.depositB(
        deployedid.toString(),
        overrides
      );
  
      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));
  
      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_resolveAsParty(
  agreementid,
  contractType,
  deployedid,
  resolutionWei
) {
    let actionType = "resolve_party";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.resolveAsParty(
        deployedid.toString(),
        resolutionWei.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_resolveAsArbitrator(
  agreementid,
  contractType,
  deployedid,
  resolutionWei
) {
    let actionType = "resolve_arbitrator";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.resolveAsArbitrator(
        deployedid.toString(),
        resolutionWei.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_earlyWithdrawA(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "early_withdraw";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.earlyWithdrawA(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_withdraw(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "withdraw";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.withdraw(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_requestArbitration(
  agreementid,
  contractType,
  deployedid,
  disputeFee
) {
    let actionType = "arbitration";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);
    let overrides = {
      value: ethers.utils.bigNumberify(disputeFee.toString())
    };

    try {
      const txObj = await contract.requestArbitration(
        deployedid.toString(),
        overrides
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_withdrawDisputeFee(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "withdraw_fee";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.withdrawDisputeFee(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_requestDefaultJudgment(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "default_judgment";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.requestDefaultJudgment(
        deployedid.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_requestAutomaticResolution(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "auto_resolution";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.requestAutomaticResolution(
        deployedid.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function eth_submitEvidence(
  agreementid,
  contractType,
  deployedid,
  evidence
) {
  let actionType = "submit_evidence";
  store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "start", ""));

  let receipt = null;
  let contract = getEthersJSContractForAgreement(contractType);

  try {
    const txObj = await contract.submitEvidence(
      deployedid.toString(),
      evidence.toString()
    );

    const hash = txObj ? txObj.hash : "";
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "hash", hash));

    receipt = await window.ethersProvider.waitForTransaction(hash);
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "receipt", receipt));
  }
  catch (err) {
    receipt = null
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "error", err));
  }

  return receipt;
}

export async function eth_getEvidence(
  contractType,
  deployedid,
  arbitratorAddress
) {
  if (window.ethersProvider) {
    let auth = store.getState().auth;
    let address = shared.getContractAddress(auth.testMode, contractType);
    let fromBlock = utils.asciiToHex("1");
    let toBlock = utils.asciiToHex("latest");
    let topicEventName = utils.sha3("Evidence(address,uint256,address,string)");
    let topicArbitratorAddress = padStringAsTopic(remove0x(arbitratorAddress));
    let topicDeployedid = padStringAsTopic(shared.decimalToHexBN(deployedid, "eth_getEvidence"));

    let respArr = await window.ethersProvider.getLogs({
      fromBlock: fromBlock,
      toBlock: toBlock,
      address: address,
      topics: [topicEventName, topicArbitratorAddress, topicDeployedid]
    });

    return processLogData(respArr);
  }
  
  return [];
}

export async function eth_getMetaEvidence(
  contractType,
  deployedid
) {
  if (window.ethersProvider) {
    let auth = store.getState().auth;
    let address = shared.getContractAddress(auth.testMode, contractType);
    let fromBlock = utils.asciiToHex("1");
    let toBlock = utils.asciiToHex("latest");
    let topicEventName = utils.sha3("MetaEvidence(uint256,string)");
    let topicDeployedid = padStringAsTopic(shared.decimalToHexBN(deployedid, "eth_getMetaEvidence"));
  
    let respArr = await window.ethersProvider.getLogs({
      fromBlock: fromBlock,
      toBlock: toBlock,
      address: address,
      topics: [topicEventName, topicDeployedid]
    });

    return processLogData(respArr);
  }
  
  return [];
}

// -----------------------------------------------------------
// AgreementManagerERC20

export async function erc20_createAgreementA(
  agreementid, 
  contractType,
  agreementHash, 
  metaevidenceHash, 
  partyA, partyB, arbiter, tokenA, tokenB, arbitratorToken, 
  partyAStakeAmount, partyBStakeAmount, partyAInitialArbitratorFee, partyBInitialArbitratorFee, disputeFee, automaticResolutionTokenA, automaticResolutionTokenB, nDaysToRespondToArbitrationRequest, arbitrationRequestAllowedAfterTimestamp, autoResolveAfterTimestamp, partyATokenPower, partyBTokenPower, arbitratorTokenPower
) {
    let partyAStakeAmountETH = 0;
    if (tokenA === "0x0000000000000000000000000000000000000000") {
      partyAStakeAmountETH = partyAStakeAmount;
    }

    let agreementURI = apiEvidenceURL("https://api.atstake.net/prod/metaevidence/" + metaevidenceHash);
    let arbExtraData = utils.asciiToHex("");

    store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "start", ""));
    
    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);
    let overrides = {
      value: ethers.utils.bigNumberify(partyAStakeAmountETH.toString())
    };
  
    try {
      const txObj = await contract.createAgreementA(
        agreementHash.toString(), 
        agreementURI.toString(),
        [partyA.toString(), partyB.toString(), arbiter.toString(), tokenA.toString(), tokenB.toString(), arbitratorToken.toString() ], 
        [partyAStakeAmount.toString(), partyBStakeAmount.toString(), partyAInitialArbitratorFee.toString(), partyBInitialArbitratorFee.toString(), disputeFee.toString(), automaticResolutionTokenA.toString(), automaticResolutionTokenB.toString(), nDaysToRespondToArbitrationRequest.toString(), arbitrationRequestAllowedAfterTimestamp.toString(), autoResolveAfterTimestamp.toString(), partyATokenPower.toString(), partyBTokenPower.toString(), arbitratorTokenPower.toString()], 
        arbExtraData,
        overrides
      );
  
      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "hash", hash));
  
      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "receipt", receipt));
    }
    catch (err) {
      console.log("Deploy transaction error (B)");
      console.log(err);
      receipt = null
      store.dispatch(hasDeployTransactionUpdate(agreementid, contractType, "local", "error", err));
    }
  
    return receipt;
}

export async function erc20_depositB(
  agreementid,
  contractType,
  deployedid,
  partyBStakeAmountETH
) {
    let actionType = "deposit";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);
    let overrides = {
      value: ethers.utils.bigNumberify(partyBStakeAmountETH.toString())
    };
  
    try {
      const txObj = await contract.depositB(
        deployedid.toString(),
        overrides
      );
  
      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));
  
      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_resolveAsParty(
  agreementid,
  contractType,
  deployedid,
  resTokenA,
  resTokenB
) {
    let actionType = "resolve_party";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.resolveAsParty(
        deployedid.toString(),
        resTokenA.toString(),
        resTokenB.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_resolveAsArbitrator(
  agreementid,
  contractType,
  deployedid,
  resTokenA,
  resTokenB
) {
    let actionType = "resolve_arbitrator";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.resolveAsArbitrator(
        deployedid.toString(),
        resTokenA.toString(),
        resTokenB.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_earlyWithdrawA(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "early_withdraw";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.earlyWithdrawA(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_withdraw(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "withdraw";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.withdraw(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_requestArbitration(
  agreementid,
  contractType,
  deployedid,
  disputeFeeEth
) {
    let actionType = "arbitration";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);
    let overrides = {
      value: ethers.utils.bigNumberify(disputeFeeEth.toString())
    };

    try {
      const txObj = await contract.requestArbitration(
        deployedid.toString(),
        overrides
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_withdrawDisputeFee(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "withdraw_fee";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.withdrawDisputeFee(
        deployedid.toString()
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_requestDefaultJudgment(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "default_judgment";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.requestDefaultJudgment(
        deployedid.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_requestAutomaticResolution(
  agreementid,
  contractType,
  deployedid
) {
    let actionType = "auto_resolution";
    store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "start", ""));

    let receipt = null;
    let contract = getEthersJSContractForAgreement(contractType);

    try {
      const txObj = await contract.requestAutomaticResolution(
        deployedid.toString(),
        true
      );

      const hash = txObj ? txObj.hash : "";
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash", hash));

      receipt = await window.ethersProvider.waitForTransaction(hash);
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "receipt", receipt));
    }
    catch (err) {
      receipt = null
      store.dispatch(hasActionTransactionUpdate(agreementid, actionType, "error", err));
    }

    return receipt;
}

export async function erc20_submitEvidence(
  agreementid,
  contractType,
  deployedid,
  evidence
) {
  let actionType = "submit_evidence";
  store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "start", ""));

  let receipt = null;
  let contract = getEthersJSContractForAgreement(contractType);

  try {
    const txObj = await contract.submitEvidence(
      deployedid.toString(),
      evidence.toString()
    );

    const hash = txObj ? txObj.hash : "";
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "hash", hash));

    receipt = await window.ethersProvider.waitForTransaction(hash);
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "receipt", receipt));
  }
  catch (err) {
    receipt = null
    store.dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "error", err));
  }

  return receipt;
}

export async function erc20_getEvidence(
  contractType,
  deployedid,
  arbitratorAddress
) {
  if (window.ethersProvider) {
    let auth = store.getState().auth;
    let address = shared.getContractAddress(auth.testMode, contractType);
    let fromBlock = utils.asciiToHex("1");
    let toBlock = utils.asciiToHex("latest");
    let topicEventName = utils.sha3("Evidence(address,uint256,address,string)");
    let topicArbitratorAddress = padStringAsTopic(remove0x(arbitratorAddress));
    let topicDeployedid = padStringAsTopic(shared.decimalToHexBN(deployedid, "erc20_getEvidence"));

    let respArr = await window.ethersProvider.getLogs({
      fromBlock: fromBlock,
      toBlock: toBlock,
      address: address,
      topics: [topicEventName, topicArbitratorAddress, topicDeployedid]
    });

    return processLogData(respArr);
  }
  
  return [];
}

export async function erc20_getMetaEvidence(
  contractType,
  deployedid
) {
  if (window.ethersProvider) {
    let auth = store.getState().auth;
    let address = shared.getContractAddress(auth.testMode, contractType);
    let fromBlock = utils.asciiToHex("1");
    let toBlock = utils.asciiToHex("latest");
    let topicEventName = utils.sha3("MetaEvidence(uint256,string)");
    let topicDeployedid = padStringAsTopic(shared.decimalToHexBN(deployedid, "erc20_getMetaEvidence"));
  
    let respArr = await window.ethersProvider.getLogs({
      fromBlock: fromBlock,
      toBlock: toBlock,
      address: address,
      topics: [topicEventName, topicDeployedid]
    });

    return processLogData(respArr);
  }
  
  return [];
}

export function processLogData(respArr) {
  let evidenceArr = [];
  if (respArr && respArr.length > 0) {
    for (let i = 0; i < respArr.length; i++) {
      let data = respArr[i].data;
      if (data.length > 2 + 64 + 64) {
        data = "0x" + data.substring(2 + 64 + 64);
        let evidenceStr = utils.hexToAscii(data);
        evidenceStr = evidenceStr.replace(/\0/g, '');
        evidenceArr.push(evidenceStr);
      }
    }  
  }
  return evidenceArr;
}