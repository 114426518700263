import update from 'immutability-helper';
import moment from 'moment-timezone';

const emptyState = {
  username: "",
  accessCodeInput: "",
  showRequestAccessCode: false,
  hasAcceptedDisclaimer: false,
  hasAcceptedTerms: false,
  signupFailed: false,
  failureMessage: "",
  invalidAccessCode: false,
  errorEmptyUsername: false,
  usernameExistsMap: {},
  initTimestampUnix: 0,
  ignoreUnsupportedWallet: false,
  askForEmail: false,
  email: ""
}

const DialogLoginReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGLOGIN_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;

      if (name === "signupFailed" || name === "errorEmptyUsername" || name === "invalidAccessCode") {
        return {...state, [name]: value};
      }
      
      return {...state, [name]: value, signupFailed: false, errorEmptyUsername: false, invalidAccessCode: false};
    }
    case "SIGNUP_FAILURE" : {
      let { errorMsg="" } = action.payload;
      
      return {...state, signupFailed: true, failureMessage: errorMsg};
    }
    case "LOGIN_ASK_FOR_EMAIL" : {      
      return {...state, askForEmail: true };
    }
    case "ACCESS_CODE_FAILURE" : {
      let val = action.payload;
      
      return {...state, invalidAccessCode: val};
    }
    case "USERNAME_EXISTS" : {
      let {username="", exists=false} = action.payload;

      let newUsernameExistsMap = update(
        state.usernameExistsMap || {}, 
        {$merge: {[username]: exists}}
      );

      return {...state, usernameExistsMap: newUsernameExistsMap};
    }
    case "OPEN_OVERLAY": {
      let { dialog } = action.payload;
      
      if (dialog === "login") {
        let initTimestampUnix = moment().tz('America/Los_Angeles').unix();
        return {...state, ...emptyState, initTimestampUnix };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogLoginReducer