import React, { Component } from 'react';
import './FavoriteToggle.css';
import favoriteUnselImg from '../star_empty.svg';
import favoriteSelImg from '../star_yellow.svg';

class FavoriteToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    let selected = !this.props.selected;
    let pending = this.props.pending;
    let contact = this.props.contact;
    if (!pending && this.props.onClick) { this.props.onClick(contact, selected); }
  }

  render() {
    if (this.props.selected) {
      return (
        <div><img onClick={this.handleClick} className="favoriteIcon" src={favoriteSelImg} alt="Is favorite" /></div>
      );
    }
    else {
      return (
        <div><img onClick={this.handleClick} className="favoriteIcon" src={favoriteUnselImg} alt="Not favorite" /></div>
      );  
    }
  }
}
  
export default FavoriteToggle;