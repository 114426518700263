//import update from 'immutability-helper';

const emptyState = {
  agreementid: "",
  user: "",
  rating: 0,
  ratingText: "",
  pending: false,
  complete: false
}

const DialogReviewReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGREVIEW_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "REVIEW_UPDATE" : {
      let { agreementid="", user="", rating=0, ratingText="", pending=false, complete=false } = action.payload;
      return {...state, agreementid, user, rating, ratingText, pending, complete };
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "review") {
        let { agreementid="", user="", rating=0, ratingText="" } = params;
        return {...state, ...emptyState, agreementid, user, rating, ratingText };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogReviewReducer