import React, { Component } from 'react'
import { connect } from 'react-redux';
import './FAQ.css';
import './Common.css';
import { genURL } from "./ActionUtils";
import Link from './Link';

class FAQ extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.selectPage = this.selectPage.bind(this);
    this.scrollFromHash = this.scrollFromHash.bind(this);
  }

  scrollFromHash(hash) {
    window.setTimeout(() => {
      if (hash === "#unavailable") {
        document.getElementById('unavailable').scrollIntoView({block: "start", behavior: "smooth"});
      }
      else if (hash === "#getwallet") {
        document.getElementById('getwallet').scrollIntoView({block: "start", behavior: "smooth"});
      }
      else if (hash === "#whyethereum") {
        document.getElementById('whyethereum').scrollIntoView({block: "start", behavior: "smooth"});
      }
      else if (hash === "#unconfirmed") {
        document.getElementById('unconfirmed').scrollIntoView({block: "start", behavior: "smooth"});
      }
    }, 300);
  }
  
  componentDidMount() {
    this.scrollFromHash(this.props.menu.hash);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.menu.hash !== prevProps.menu.hash) {
      this.scrollFromHash(this.props.menu.hash);
    }
  }

  selectPage(page) {
  }
  
  render() {
    return (
      <div> 
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageTitle">Frequently Asked Questions</div>
          </div>
        </div>

        <div className="pageNarrowSection">
          <div className="restrictSectionWidth">
            <div className="faqHeading">What is Ethereum?</div>
            <div className="pageLightText moveTop10">
              <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://en.wikipedia.org/wiki/Ethereum"}>Ethereum</Link> is a cryptocurrency (similar
              to <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://en.wikipedia.org/wiki/Bitcoin"}>Bitcoin</Link>)
              and an open financial platform which is available to anyone in the world with an Internet connection.
            </div>

            <div id="whyethereum" className="faqHeading moveTop20">Why does Atstake use Ethereum?</div>
            <div className="pageLightText moveTop10">
              Ethereum enables money to be held in a mechanism 
              called <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://coincenter.org/entry/what-is-multi-sig-and-what-can-it-do"}>two-of-three multisig</Link> such
              that no single individual has full control over it, which reduces your risk
              when contracting with others.
              Before public blockchains like Bitcoin or Ethereum this was not possible via the Internet. 
              Making this feature easier to use was our main motivation in creating Atstake.
            </div>
            <div className="pageLightText moveTop10">
              Ethereum also allows users to enforce contracts directly between each other without needing to trust any 
              specific intermediary (including Atstake). This allows greater control over how contracts are enforced than could otherwise be achieved.
            </div>

            <div className="faqHeading moveTop20">Who decides the outcome of a contract?</div>
            <div className="pageLightText moveTop10">
              Each contract on Atstake specifies an arbitrator who is responsible for settling any disagreements between the parties.
              The arbitrator can be any willing user of Atstake, as long as everyone agrees. The arbitrator is never called on to 
              settle a contract (and is never paid) unless the parties can't agree. The arbitrator can never send contract funds to themselves.
            </div>

            <div className="faqHeading moveTop20">Why should I trust the arbitrator to behave honestly?</div>
            <div className="pageLightText moveTop10">
              We've carefully designed Atstake so that all participants have incentives to report 
              contract outcomes honestly. <Link href={genURL("/incentives")} className="bluelink" noTarget={true} rel="noopener noreferrer">Learn more ></Link>
            </div>

            <div className="faqHeading moveTop20">Do I need to pay arbitration fees to use Atstake?</div>
            <div className="pageLightText moveTop10">You'll never have to pay an arbitration fee
            unless you disagree with both the arbitrator and your counterparty about the outcome of the contract.
            We've designed a <Link href={genURL("/incentives")} className="bluelink" noTarget={true} rel="noopener noreferrer">system of incentives</Link> that makes arbitration rare.
            A reliable strategy for never paying an arbitration fee is to 
            only participate in contracts with clear outcome criteria, and to ensure that at least your counterparty or the arbiter 
            cares enough about their reputation to not want to publicly lie about the contract outcome.</div>

            <div className="faqHeading moveTop20">How can I find an arbitrator for my contract?</div>
            <div className="pageLightText moveTop10">Atstake will suggest a default arbitrator for your contracts. If you'd like to choose your
            own, our <Link href={genURL("/people")} className="bluelink" noTarget={true} rel="noopener noreferrer">People</Link> page lists arbitrators for you to choose from.
            We track their reputations
            and highlight their fees so you can make an informed choice. If there's anyone who both you and your counterparty know and trust, they
            might be a good choice. If they don't already have an Atstake account they need only an Ethereum address to sign up.</div>

            <div className="faqHeading moveTop20">How do I find someone to participate in a contract or transaction with me?</div>
            <div className="pageLightText moveTop10">Browse postings by other users on 
            our <Link href={genURL("/postings")} className="bluelink" noTarget={true} rel="noopener noreferrer">Postings</Link> page, 
            or use the 'new posting' menu button to create your own posting.</div>

            <div className="faqHeading moveTop20">Can't my counterparty and the arbitrator collude to steal from me?</div>
            <div className="pageLightText moveTop10">This is unlikely if either your counterparty or the arbitrator
            have a reputation that they care about more than the money you're staking. 
            This is because our use of Ethereum makes it easy to prove this type of dishonesty.
            For more details read about
            Atstake's <Link href={genURL("/incentives")} className="bluelink" noTarget={true} rel="noopener noreferrer">incentives</Link>.</div>

            <div className="faqHeading moveTop20">Why should I use Atstake instead of Craigslist / Amazon / Upwork / Ebay / PayPal / AirBnB?</div>
            <div className="pageLightText moveTop10">
            Our Ethereum-based system of enforcing fair contract outcomes allows us to
            give users better protection than other services for less cost.
            </div>

            <div className="pageLightText moveTop10">
            We charge no commission and our users never pay 
            fees unless they lose a dispute. In contrast 
            Amazon and AirBnB take a 15% commission, Upwork and Ebay charge 10%,
            and PayPal charges 3% in the US and Canada and often over 6% for international payments. 
            Craigslist doesn't charge fees for many of their categories, but unlike Atstake they do nothing to 
            ensure that contracts are fairly enforced.
            </div>

            <div className="pageLightText moveTop10">
            Atstake also gives you more control over the types of contracts you can create and how they are enforced. For instance PayPal's dispute
            resolution system is intended mostly for ecommerce and they will refuse to enforce many service contracts.
            In contrast you can use Atstake as long as you find any entity in the world willing to enforce your contract.
            </div>

            <div className="pageLightText moveTop10">
            Finally, you may prefer Atstake is if you want to operate entirely within the Ethereum economy. 
            Everyone on Atstake is an Ethereum user.
            </div>

            <div className="faqHeading moveTop20">Why should I use Atstake instead of Haven / OpenBazaar?</div>
            <div className="pageLightText moveTop10">
            We're big fans 
            of <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://gethaven.app">Haven</Link> (the mobile app version 
            of <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://openbazaar.com/">OpenBazaar</Link>) and 
            we share many similarties with them.
            </div>

            <div className="pageLightText moveTop10">
            You might prefer Atstake if you want to operate in the Ethereum ecosystem. Atstake contracts use Ethereum and/or any
            ERC20 token. Haven currently doesn't support Ethereum.
            </div>

            <div className="pageLightText moveTop10">
            You should also choose Atstake if you want to create a contract more complicated than a simple conditional payment. 
            With Atstake you can create any contract where one or both people
            lock up money and then this money is distributed in any way based on any criteria. With Haven you can only create contracts where
            money is locked up by one person.
            </div>

            <div id="getwallet" className="faqHeading moveTop20">How do I get an Ethereum address?</div>
            <div className="pageLightText moveTop10">To use Atstake on mobile, download an Ethereum wallet app 
            such as <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://wallet.coinbase.com">Coinbase Wallet</Link>.
            Then follow the intsructions within the app for creating an address.</div>
            <div className="pageLightText moveTop10">On a desktop 
            install <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://metamask.io/"}>Metamask</Link>, 
            a browser extension available for the Chrome, Firefox, Brave, and Opera web browsers. 
            Follow the instructions for creating an address.
            You'll need to have your Metamask account unlocked to use most of Atstake's functionality.</div>

            <div className="faqHeading moveTop20">How do I get some Ether (ETH) so that I can use it in a contract?</div>
            <div className="pageLightText moveTop10">The easiest way is to buy it from an exchange using your local currency.
            One user-friendly option is <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://coinbase.com"}>Coinbase</Link>.
            You'll need to send the ETH from the exchange to the Ethereum address you created with Metamask or your mobile 
            Ethereum wallet.</div>

            <div className="faqHeading moveTop20">Can I try out Atstake without using real money?</div>
            <div className="pageLightText moveTop10">Yes. Use your wallet to switch to the Rinkeby Test Network and you'll automatically enter test mode.
            Switch back to the Main Ethereum Network to disable test mode.
            Use <Link href={genURL("/faucet")} className="bluelink" noTarget={true} rel="noopener noreferrer">our faucet</Link> to get free test mode Ether and tokens.</div>
            <div className="pageLightText moveTop10">Note that not all Ethereum wallets support Rinkeby. 
            If yours doesn't, try one of our <Link href={genURL("/faq#getwallet")} className="bluelink" noTarget={true} rel="noopener noreferrer">recommended wallets</Link>.</div>

            <div className="faqHeading moveTop20">Ether is too volatile.
            Can I use Atstake with a more stable currency?</div>
            <div className="pageLightText moveTop10">Yes. We support staking the ERC-20 
            tokens  <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://www.coinbase.com/usdc"}>USDC</Link> and
            <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://makerdao.com/en/dai/"}> DAI</Link>, which are intended to track the value 
            of the US dollar. If you want to use an ERC-20 token but don't see it on our site,
            email us at support@atstake.net and ask us to add it.</div>

            <div className="faqHeading moveTop20">Will my funds get stuck if I deposit first but my counterparty 
            refuses to deposit what they owe?</div>
            <div className="pageLightText moveTop10">
            No. Until all required deposits have been made the first person to deposit has the option to withdraw their funds early and cancel the contract.
            </div>

            <div id="unconfirmed" className="faqHeading moveTop20">What should I do if my transaction doesn't confirm after a very long time?</div>
            <div className="pageLightText moveTop10">
            Usually this is caused by the transaction being sent with too low of a fee. Some wallets unfortunately aren't very good at fee estimation, 
            requiting this situation to occassionally be fixed manually.</div>
            <div className="pageLightText moveTop10">If you look at your transaction within your Ethereum wallet there should either 
            be a way to cancel it (so you can send another) or speed it up by increasing the transaction fee.
            You can monitor the state of your transactions  
            using <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://etherscan.io/"}>Etherscan</Link>.
            </div>
            <div className="pageLightText moveTop10">
              If you sent a deposit transaction containing money which was confirmed by the Ethereum network but wasn't detected by Atstake, 
              email us at support@atstake.net. You can also access the contract directly on 
              our <Link href={genURL("/rawcontracts")} className="bluelink" noTarget={true} rel="noopener noreferrer">raw contracts</Link> page.
            </div>

            <div className="faqHeading moveTop20">How do I know that the Ethereum code which controls all contracts 
            and holds all funds is trustworthy?</div>
            <div className="pageLightText moveTop10">All of our contract code is available at 
            our <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://github.com/atstake/atstake"}> github repository</Link>. 
            The repository also contains instructions on how to verify that our deployed code matches this code,
            as well as evidence regarding the security of our code.</div>

            <div className="faqHeading moveTop20">How do I know whether an Atstake user is
            really the Twitter/Facebook/Reddit user who they claim to be?</div>
            <div className="pageLightText moveTop10">When an Atstake user associates one of their external accounts to an 
            Atstake account, we require that they publicly post on this account claiming control of their Ethereum address.
            These posts are available for you to review on any user's Atstake page.</div>

            <div className="faqHeading moveTop20">Do I have to associate my Atstake account with my social media accounts?</div>
            <div className="pageLightText moveTop10">
            You don't have to link your Atstake account to external accounts (Twitter, Facebook, etc.), but by doing so you show 
            others that you're willing to stake your 
            reputation on your contracts. This makes other people more likely to enter into contracts with you. 
            This works better the more strongly your external accounts are associated with an identity that you care about.</div>

            <div id="unavailable" className="faqHeading moveTop20">What does clicking the 'x' on a contract when viewing my list of
            contracts do?</div>
            <div className="pageLightText moveTop10">This will move the contract into your 'hidden contracts', which you can 
            access with the dropdown above your contracts list. The contract won't move immediately when you click it, in case clicking
            was an accident and you want to 'unhide' it. Instead, the contract will be moved the next time you load your contracts list
            or refresh the page.</div>

            <div id="unavailable" className="faqHeading moveTop20">What happens to my contracts if Atstake becomes unavailable?</div>
            <div className="pageLightText moveTop10">Because Atstake is just an interface to Ethereum contracts, you're
            still be able to interact with your contracts no matter what happens to Atstake. 
            We've written <Link noTarget={true} rel="noopener noreferrer" className="bluelink" 
            href={"https://github.com/atstake/atstake/blob/master/AgreementManager_Manual_Use.md"}>instructions</Link> for
            how to do this.
            You may want to save your own copy of these instructions in case they become unavailable in the future.
            </div>
            <div className="pageLightText moveTop10">
            You can get some practice interacting directly with our contracts on
            our <Link href={genURL("/rawcontracts")} className="bluelink" noTarget={true} rel="noopener noreferrer">raw contracts</Link> page.</div>

            <div className="faqHeading moveTop20">How can I make it impossible for Atstake to see the text of my contract?</div>
            <div className="pageLightText moveTop10">
            Use a <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://passwordsgenerator.net/sha256-hash-generator/"}>hash function</Link> to 
            hash the contract text, then submit something like the following to Atstake: 
            "Terms are specified by text corresponding to the hash 7ae9027af651d45f2d28be80d0572a425f1c24d09f96dae0472de4c87c54b468."</div>
            <div className="pageLightText moveTop10">
            When using this technique you'll need to communicate the contract text to your counterparty outside of Atstake.
            This method also allows you to hide the contract details from the arbitrator. only revealing them if there's a dispute.</div>

            <div className="faqHeading moveTop20">What should I do if I still have a question?</div>
            <div className="pageLightText moveTop10">
            Contact us at support@atstake.net.</div>
          </div>
          <div className="moveTop20" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    faq: state.faq
  }
}

const FAQContainer = connect(mapStateToProps, null)(FAQ);
export default FAQContainer;