//import update from 'immutability-helper';

const emptyState = {
  agreementid: "",
  list: [], 
  versionListLoaded: false
}

const DialogVersionsReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGVERSIONS_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGVERSIONS_LIST": {      
      let { list=[] } = action.payload;
      return {...state, list: list, versionListLoaded: true };
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "versions") {
        let { agreementid="" } = params;
        return {...state, ...emptyState, agreementid: agreementid, list: [], versionListLoaded: false };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogVersionsReducer