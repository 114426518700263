import React, { Component } from 'react'
import { connect } from 'react-redux';
import './RawContracts.css';
import './Common.css';
import GapCard from "./GapCard";
import TitleCard from "./TitleCard";
import ButtonAction from './ButtonAction';
import InputBasic from './InputBasic';
import Dropdown from './Dropdown';
import { getActionList, handleSubmit } from "./RawContractsActions";
import Link from './Link';
import { getTestModeFromStore } from "./ActionUtils";
import { shared } from '../Shared';

class RawContracts extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleGetState = this.handleGetState.bind(this);
    this.getFieldValueStr = this.getFieldValueStr.bind(this);
    this.getFieldValueBool = this.getFieldValueBool.bind(this);
    this.toggleExpandSection = this.toggleExpandSection.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  componentDidMount() {
  }

  changeInputValue(name, value) {
    this.props.dispatch({
      type: "RAW_CONTRACTS_CHANGE_INPUT_VALUE",
      payload: { name, value }
    });
  }

  handleGetState(name, value) {
    alert("Get state");
  }

  getFieldValueStr(field) {
    let value = "";
    if (this.props.rawContracts.hasOwnProperty(field)) {
      value = this.props.rawContracts[field];
    }    
    return value;
  }

  getFieldValueBool(field) {
    let value = "";
    if (this.props.rawContracts.hasOwnProperty(field)) {
      value = this.props.rawContracts[field];
    }    
    return value;
  }

  toggleExpandSection(expandId) {
    this.props.dispatch({
      type: "RAW_CONTRACTS_CHANGE_INPUT_VALUE",
      payload: { name: expandId, value: !this.getFieldValueBool(expandId) }
    });
  }

  handleSubmit(actn, contractType) {
    this.props.dispatch(handleSubmit(actn, contractType));
  }
  
  render() {

    let testMode = getTestModeFromStore();
    let contractType = this.props.rawContracts.contractType;
    let contractTypeList = [];
    contractTypeList.push({
      name: "simple_eth", 
      text: "AgreementManagerETH_Simple"
    });
    contractTypeList.push({
      name: "simple_erc20", 
      text: "AgreementManagerERC20_Simple"
    });
    /*
    contractTypeList.push({
      name: "kleros_eth", 
      text: "AgreementManagerETH_ERC792"
    });
    contractTypeList.push({
      name: "kleros_erc20", 
      text: "AgreementManagerERC20_ERC792"
    });
    */

    let contractAddress = shared.getContractAddress(testMode, contractType);
    let contractLink = shared.getContractLink(testMode, contractType);
    let contractNetwork = shared.getNetworkNameLong(testMode);
    let actionList = getActionList(contractType);
    let actionsElemList = [];
    for (let i = 0; i < actionList.length; i++) {
      let actionObj = actionList[i];
      let params = actionObj.params;
      let paramsElemList = [];
      for (let j = 0; j < params.length; j++) {
        let paramObj = params[j];
        let inputKey = contractType + "_" + actionObj.action + "_" + paramObj.field;
        paramsElemList.push(<div className="moveTop10" key={j}>
            <div className="rawContractsLabel">{paramObj.display}</div>
            <InputBasic placeholder={paramObj.display} type="text" className="rawContractsInput moveTop5" name={inputKey} value={this.getFieldValueStr(inputKey)} onChange={this.changeInputValue} />
        </div>);
      }

      let expandId = "expand_" + contractType + "_" + actionObj.action;
      let isExpanded = this.getFieldValueBool(expandId);
      let response = this.getFieldValueStr(contractType + "_" + actionObj.action + "_response");
      let responseElemList = [];
      if (response && response !== "") {
        let respList = JSON.parse(response);
        for (let i = 0; i < respList.length; i++) {
          let respObj = respList[i];
          let respKey = Object.keys(respObj)[0];
          responseElemList.push(<div className="rawContractsRespCnt moveTop5" key={i}><div className="rawContractsRespLabel">{respKey}:</div><div className="rawContractsRespValue">{JSON.stringify(respObj[respKey])}</div></div>);
        }
      }

      actionsElemList.push(
        <div className="rawContractsActionCnt moveTop5" key={i}>
          <div className="rawContractsActionTitle" onClick={() => this.toggleExpandSection(expandId)}>{actionObj.display}&nbsp;<span>({actionObj.action})</span>{isExpanded ? <span className="rawContractsArrow">&#x25BC;</span> : <span className="rawContractsArrow">&#x25BA;</span>}</div>
          <div style={{display: isExpanded ? "" : "none"}} id={expandId} className="rawContractsActionExpand">
            {paramsElemList}
            <div className="moveTop10">
              <ButtonAction name={actionObj.action} value={contractType} className="" text="Submit" onClick={this.handleSubmit}/>
            </div>
            {
              actionObj.hasOwnProperty("read") && responseElemList.length > 0 ? (
                <div className="moveTop10">
                  <div className="rawContractsLabel">Response</div>
                  <div className="rawContractsActionResponse moveTop5">{responseElemList}</div>
                </div>  
              ) : null
            }
          </div>
        </div>
      );
    }

    if (false) {
      return (
        <div className="contentSection">
          <GapCard />
          <TitleCard title="Contracts page" /> 
          <div className="contentCard">
            Coming soon
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="contentSection">
          <GapCard />
          <TitleCard title="Contracts page" /> 
          <div className="contentCard">
            This page allows you to interact directly with Atstake's smart contracts which can be viewed here: <Link className="bluelink" noTarget={true} rel="noopener noreferrer" href="https://github.com/atstake/atstake">{"https://github.com/atstake/atstake"}</Link>.
            <div className="rawContractsInput moveTop20">
              <Dropdown name="contractType" className="" selected={this.props.rawContracts.contractType} list={contractTypeList} onChange={this.changeInputValue} />
            </div>
            <div className="moveTop5">
              <Link className="small bluelink" noTarget={true} rel="noopener noreferrer" href={contractLink}>Contract {contractAddress} on {contractNetwork}</Link>
            </div>
            <div className="moveTop20">
              {actionsElemList}
            </div>  
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    rawContracts: state.rawContracts
  }
}

const RawContractsContainer = connect(mapStateToProps, null)(RawContracts);
export default RawContractsContainer;