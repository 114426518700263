import { connect } from 'react-redux';
import React, { Children, Component } from 'react'
import { init, updateScreenDimensions } from './AuthActions';
import { reloadBlockchainSyncList } from './BlockchainActions';
import App from '../App'
import { shared } from '../Shared';
import { track } from './ActionUtils';

/*
 * Create component.
 */

class AuthContainer extends Component {

  constructor(props, context) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.updateScreenDimensions = this.updateScreenDimensions.bind(this);
    this.urlPageIs = this.urlPageIs.bind(this);
    this.urlPageIsNotFound = this.urlPageIsNotFound.bind(this);
    this.getPageFromPathname = this.getPageFromPathname.bind(this);
    this.trackPage = this.trackPage.bind(this);
  }

  updateScreenDimensions() {
    this.props.dispatch(updateScreenDimensions(document.body.clientWidth, window.innerHeight));
  }

  componentWillMount() {
    const { status } = this.props.auth;

    this.updateScreenDimensions();

    if(status === "pending") {
      this.props.dispatch(init());
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateScreenDimensions);
    this.trackPage();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.cleanLocationJSON !== prevProps.auth.cleanLocationJSON) {
      if (this.props.menu.hash === "") {
        window.scrollTo(0, 0);
      }
    }

    if (this.props.auth.pageguid !== prevProps.auth.pageguid) {
      this.trackPage();
    }
    else if (prevProps.auth.status === "pending" && this.props.auth.status !== prevProps.auth.status) {
      this.trackPage();
    }

    if (this.props.auth.status !== prevProps.auth.status) {
      this.updateScreenDimensions();
    }

    // this.props.auth.initCheckAddress
    if (this.props.auth.ethAddress !== prevProps.auth.ethAddress || this.props.auth.ethNetworkName !== prevProps.auth.ethNetworkName) {
      if (this.props.auth.status !== "pending") {
        this.props.dispatch(reloadBlockchainSyncList());
      }
    }
  }

  trackPage() {
    if (this.props.auth.pageguid !== "" && this.props.auth.status !== "pending") {

      let {
        fullPath="/",
        hash=""
      } = this.props.menu;

      let page = this.getPageFromPathname(this.props.auth.pathname, this.props.auth.status);
      let locationJSON = this.props.auth.locationJSON;
      this.props.dispatch(track("page", page, JSON.stringify({locationJSON, hash, fullPath})));
    }
  }

  getPageFromPathname(pathname, status) {
    let basepage = pathname.replace(/^\/|\/$/g, '');

    if (status === "pending") {
      return "pending";
    }
    else if (status === "loggedin") {
      if (shared.PAGE_LIST.includes(basepage)) {
        return basepage;
      }
      else if (basepage === "") {
        return "home";
      }
      else {
        return "not_found";
      }
    }
    else if (status === "loggedout") {
      if (shared.PAGE_LIST.includes(basepage)) {
        return basepage;
      }
      else if (basepage === "") {
        return "home";
      }
      else {
        return "not_found";
      }
    }
  }

  urlPageIs(page) {
    if (shared.PAGE_LIST.includes(page) && this.props.auth.pathname === "/" + page) {
      return true;
    }
    else if (page === "home" && this.props.auth.pathname === "/") {
      return true;
    }

    return false;
  }

  urlPageIsNotFound() {
    let pathname = this.props.auth.pathname;
    pathname = pathname.replace(/^\/|\/$/g, '');

    if (!shared.PAGE_LIST.includes(pathname) && pathname !== "") {
      return true;
    }

    return false;
  }

  render() {

    if (this.urlPageIs("verify")) {
      if (this.props.verifyComp) {
        return this.props.verifyComp;
      }
    }

    // - - - - - - - - - - - - - - PENDING - - - - - - - - - - - - - -
    if (this.props.auth.status === "pending") {
      if (this.props.pendingComp) {
        return this.props.pendingComp;
      }
      else {
        return <div></div>;
      }
    }
    // - - - - - - - - - - - - - - ALWAYS - - - - - - - - - - - - - -
    else if (this.urlPageIs("incentives")) {
      if (this.props.incentivesComp) {
        return <App>{this.props.incentivesComp}</App>;
      }
    }
    else if (this.urlPageIs("attributions")) {
      if (this.props.attributionsComp) {
        return <App>{this.props.attributionsComp}</App>;
      }
    }
    else if (this.urlPageIs("faq")) {
      if (this.props.faqComp) {
        return <App>{this.props.faqComp}</App>;
      }
    }
    else if (this.urlPageIs("examples")) {
      if (this.props.examplesComp) {
        return <App>{this.props.examplesComp}</App>;
      }
    }
    else if (this.urlPageIs("about")) {
      if (this.props.aboutComp) {
        return <App>{this.props.aboutComp}</App>;
      }
    }
    else if (this.urlPageIs("help")) {
      if (this.props.helpComp) {
        return <App>{this.props.helpComp}</App>;
      }
    }
    else if (this.urlPageIs("how")) {
      if (this.props.howComp) {
        return <App>{this.props.howComp}</App>;
      }
    }
    else if (this.urlPageIs("faucet")) {
      if (this.props.faucetComp) {
        return <App>{this.props.faucetComp}</App>;
      }
    }
    else if (this.urlPageIs("ethereum")) {
      if (this.props.ethereumComp) {
        return <App>{this.props.ethereumComp}</App>;
      }
    }
    else if (this.urlPageIs("rawcontracts")) {
      if (this.props.rawContractsComp) {
        return <App>{this.props.rawContractsComp}</App>;
      }
    }
    else if (this.urlPageIs("contracts")) {
      if (this.props.contractsComp) {
        return <App>{this.props.contractsComp}</App>;
      }
    }
    else if (this.urlPageIs("tos")) {
      if (this.props.tosComp) {
        return <App>{this.props.tosComp}</App>;
      }
    }
    // - - - - - - - - - - - - - - LOGGED OUT - - - - - - - - - - - - - -
    else if (this.props.auth.status === "loggedout") { 
      if (this.urlPageIs("view")) {
        if (this.props.viewComp) {
          return <App>{this.props.viewComp}</App>;
        }
      }
      else if (this.urlPageIs("people")) {
        if (this.props.peopleComp) {
          return <App>{this.props.peopleComp}</App>;
        }
      }
      else if (this.urlPageIs("ratings")) {
        if (this.props.ratingsComp) {
          return <App>{this.props.ratingsComp}</App>;
        }
      }
      else if (this.urlPageIs("postings")) {
        if (this.props.marketsComp) {
          return <App>{this.props.marketsComp}</App>;
        }
      }
      else if (this.urlPageIs("posting")) {
        if (this.props.postingComp) {
          return <App>{this.props.postingComp}</App>;
        }
      }
      else if (this.urlPageIs("newposting")) {
        if (this.props.postingComp) {
          return <App>{this.props.postingComp}</App>;
        }
      }
      else if (this.urlPageIs("userpostings")) {
        if (this.props.postingsPageComp) {
          return <App>{this.props.postingsPageComp}</App>;
        }
      }
      else if (this.urlPageIs("profile")) {
        if (this.props.userComp) {
          return <App>{this.props.userComp}</App>;
        }
      }
      else if (this.urlPageIs("home")) {
        if (this.props.loggedoutComp) {
          return <App>{this.props.loggedoutComp}</App>;
        }
      }
      else if (this.urlPageIsNotFound()) {
        if (this.props.notFoundComp) {
          return <App>{this.props.notFoundComp}</App>;
        }
      }
      else if (this.props.pleaseLoginComp) {
        return <App>{this.props.pleaseLoginComp}</App>;
      }
      else {
        return <App>{this.props.loggedoutComp}</App>;
      }
    }

    return Children.only(this.props.children);
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    menu: state.menu
  }
}

const AuthContainerWrap = connect(mapStateToProps, null)(AuthContainer);
export default AuthContainerWrap;