import { getLinkedAccountList } from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGREPORT_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function refreshEmailAddressList() {
  return function(dispatch) {
    let auth = store.getState().auth;
    getLinkedAccountList(auth.uuid, auth.username, auth.username).then((list) => {
      if (list) {
        let emailList = [];
        for (let i = 0; i < list.length; i++) {
          let entry = list[i];
          if (entry.account_type === "email") {
            emailList.push(entry);
          }
        }

        dispatch({
          type: 'DIALOGREPORT_EMAIL_LIST',
          payload: {user: auth.username, list: emailList}
        });
      }
    });
  }
}