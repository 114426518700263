import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogDepositWarning.css';
import { changeInputValue } from './DialogDepositWarningActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay, openOverlay } from './OverlayActions'
import moment from 'moment-timezone';
import { shared } from '../Shared';

class DialogDepositWarning extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }
  
  handleYes(name, value) {
    let dialog = this.props.dialogDepositWarning.nextDialog;
    let params = this.props.dialogDepositWarning.nextParams;
    this.props.dispatch(openOverlay(dialog, params));
  }

  handleNo(name, value) {
    this.props.dispatch(closeOverlay());
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {

    let nextParams = this.props.dialogDepositWarning.nextParams;
    let { action="" } = nextParams;
    let dialogTitle = (action === "implicit_finalize") ? "Deposit funds without approval" : "Deposit funds";
    let { autoResolveUnix=0, defaultResolution="", user1Disp="", user2Disp="", agreementType="" } = this.props.agreement;

    let agreementTypeDisp = "contract proposal";
    if (agreementType === "payment") {
      agreementTypeDisp = "payment";
    }
    else if (agreementType === "request") {
      agreementTypeDisp = "payment request";
    }

    let dayRemainingStr = "";
    let dateNow = moment().tz('America/Los_Angeles');
    let autoResolveDateObj = moment.tz(autoResolveUnix, "X", 'America/Los_Angeles');
    let diffHours = autoResolveDateObj.diff(dateNow, 'hours');
    if (diffHours < 24) {
      if (diffHours === 1) {
        dayRemainingStr = "in 1 hour";
      }
      else if (diffHours <= 0) {
        dayRemainingStr = "immediately";
      }
      else {
        dayRemainingStr = "in " + diffHours + " hours";
      } 
    }
    else {
      let diffDays = autoResolveDateObj.diff(dateNow, 'days');
      if (diffDays === 1) {
        dayRemainingStr = "in 1 day";
      }
      else if (diffDays <= 0) {
        dayRemainingStr = "immediately";
      }
      else {
        dayRemainingStr = "in " + diffDays + " days";
      }
    }
    
    let dialogMessage = <span>{"Warning: This "}{agreementTypeDisp}{" can be finalized by either party " + dayRemainingStr + " with the outcome "}<span className="resolutionBlock">{shared.resolutionDisplay(defaultResolution, user1Disp, user2Disp)}</span>
    {". You should only deposit if the contract terms can be fulfilled and verified before this time. Are you sure you want to deposit?"}</span>;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          <div className="">{dialogMessage}</div>
          <ButtonAction name="no" className="moveRight20 moveTop20" text="No, go back" onClick={this.handleNo}/>
          <ButtonAction name="yes" className="moveTop20" text="Yes, deposit funds" onClick={this.handleYes}/>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogDepositWarning: state.dialogDepositWarning
  }
}

const DialogDepositWarningContainer = connect(mapStateToProps, null)(DialogDepositWarning);
export default DialogDepositWarningContainer;