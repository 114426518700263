import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogChangeDisplayname.css';
import { changeInputValue, submitDisplayname, saveImageValue, saveImageFromURL } from './DialogChangeDisplaynameActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import { closeOverlay, tallOverlay } from './OverlayActions';
import InputBasic from './InputBasic';
import InputFile from './InputFile';
import ButtonAction from './ButtonAction';
import defaultProfileImg from '../profile.svg';
import { shared } from '../Shared';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';

class DialogChangeDisplayname extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.showEnterImageURL = this.showEnterImageURL.bind(this);
    this.cancelImageURL = this.cancelImageURL.bind(this);
    this.handleImageURL = this.handleImageURL.bind(this);
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || 
      this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height || 
      this.props.dialogChangeDisplayname.showEnterURL !== prevProps.dialogChangeDisplayname.showEnterURL || 
      this.props.dialogChangeDisplayname.hasError !== prevProps.dialogChangeDisplayname.hasError
      ) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleFileUpload(name, value) {
    this.props.dispatch(saveImageValue(value));
  }

  showEnterImageURL(name, value) {
    this.props.dispatch(changeInputValue("showEnterURL", true));
  }

  cancelImageURL(name, value) {
    this.props.dispatch(changeInputValue("showEnterURL", false));
  }

  handleImageURL(name, value) {
    let { photoURL="" } = this.props.dialogChangeDisplayname;
    if (photoURL !== "") {
      this.props.dispatch(saveImageFromURL("https://cors-anywhere.herokuapp.com/" + photoURL));
    }
  }

  cancel(name, value) {
    this.props.dispatch(closeOverlay());
  }

  submit(name, value) {
    let {
      displayname="",
      photoContent="",
      hasPhotoToUpload=false
    } = this.props.dialogChangeDisplayname;

    let photoData = hasPhotoToUpload ? photoContent : "";    
    this.props.dispatch(submitDisplayname(displayname, photoData));
  }

  render() {
    
    let {
      photoFile="",
      photoURL="",
      displayname="",
      summary="",
      photoContent="",
      showEnterURL=false,
      hasError=false,
      errorMsg=""
    } = this.props.dialogChangeDisplayname;

    let {
      walletType="",
      android=false
    } = this.props.auth;

    let disableLocalFile = false;
    let disableLocalFileMsg = "";
    if (android && walletType === "alpha") { 
      disableLocalFile = true;
      disableLocalFileMsg = "Alpha Wallet on Android does not support file uploads.";
    }
    else if (android && walletType === "coinbase") { 
      disableLocalFile = true;
      disableLocalFileMsg = "Coinbase Wallet on Android does not support file uploads.";
    }

    let disableSubmit = false;
    if (/^[a-zA-Z0-9_. ]*$/i.test(displayname)) {
      // Displayname is valid
    }
    else {
      disableSubmit = true;
      hasError = true;
      errorMsg = "Name can only use letters, numbers, dot and underscore";
    }

    if (displayname.length <= 40) {
      // Displayname is valid
    }
    else {
      disableSubmit = true;
      hasError = true;
      errorMsg = "Name must be 40 characters or less";
    }

    if (summary.length <= 1000) {
      // Summary is valid
    }
    else {
      disableSubmit = true;
      hasError = true;
      errorMsg = "Summary must be 1000 characters or less";
    }

    let photoSrc = "";
    if (photoContent === "") {
      photoSrc = defaultProfileImg;
    }
    else {
      photoSrc = photoContent;
    }

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        {!showEnterURL ? (
          <div className="contentCard">
            <DialogTitle title="Edit name and photo" />
            <div style={{background: "var(--content-border-color)", width: "200px", height: "200px", border: "1px solid var(--content-border-color)"}}><img style={{maxWidth: "200px", maxHeight: "200px", verticalAlign: "top"}} src={photoSrc} alt="Profile" /></div>
            <div className="moveTop5">
              <InputFile name="photoFile" disabled={disableLocalFile} disabledMsg={disableLocalFileMsg} className="moveTop5 moveRight20" value={photoFile} minWidth={DEFAULT_BUTTON_WIDTH} onChange={this.handleFileUpload} />
              <ButtonAction name="submit" link={true} text="Link to photo" className="moveTop5" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.showEnterImageURL}/>
            </div>
            <div className="moveTop20">Name</div>
            <div className="moveTop5">
              <InputBasic maxlength={shared.MAX_DISPLAY_NAME_LENGTH} placeholder="Your name" className="dialogDisplaynameInput" name="displayname" value={displayname} onChange={this.changeInputValue} />
            </div>
            <div className="moveTop15">
              <ButtonAction name="cancel" className="moveTop5 moveRight20" color="black" text="Cancel" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.cancel}/>
              <ButtonAction disabled={disableSubmit} name="submit" className="moveTop5" text="Submit" minWidth={DEFAULT_BUTTON_WIDTH} onClick={disableSubmit ? null : this.submit}/>
            </div>
            {hasError && <div className="moveTop5 inputError">{errorMsg}</div>}
          </div>
        ) : (
          <div className="contentCard">
            <DialogTitle title="Link to photo" />
            <div className="moveTop20">Enter the URL of a photo</div>
            <div className="moveTop5">
              <InputBasic placeholder="URL" className="dialogDisplaynameInput" name="photoURL" value={photoURL} onChange={this.changeInputValue} />
            </div>
            <div className="moveTop15">
              <ButtonAction name="cancel" className="moveTop5 moveRight20" color="black" text="Cancel" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.cancelImageURL}/>
              <ButtonAction name="submit" className="moveTop5 " text="Select" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.handleImageURL}/>
            </div>
          </div>
        )}
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogChangeDisplayname: state.dialogChangeDisplayname
  }
}

const DialogChangeDisplaynameContainer = connect(mapStateToProps, null)(DialogChangeDisplayname);
export default DialogChangeDisplaynameContainer;