import React, { Component } from 'react';
import './Common.css';
import './InputBasic.css';

class InputBasic extends Component {
    constructor(props) {
      super(props);

      this.state = {isFocused: false};

      // This binding is necessary to make `this` work in the callback
      this.handleChange = this.handleChange.bind(this);
      this.handleButtonAction = this.handleButtonAction.bind(this);
      this.handleKeyboard = this.handleKeyboard.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
      document.addEventListener("keydown", this.handleKeyboard, false);
    }

    componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKeyboard, false);
      clearTimeout(this.timeoutId);
    }

    handleFocus(event) {
      this.setState({isFocused: true});
    }

    handleBlur(event) {
      this.setState({isFocused: false});
    }

    handleKeyboard(event) {
      if (event.type !== "keydown") { return }
      if (!this.state.isFocused) { return }

      if (event.keyCode === 13) {
        if (this.props.hasOwnProperty("onEnter")) {
          this.props.onEnter();
        }
      }
    }
    
    handleChange(event) {

      let restrict = "";
      if (this.props.hasOwnProperty("restrict")) {
        restrict = this.props.restrict;
      }

      let maxlength = 0;
      if (this.props.hasOwnProperty("maxlength")) {
        maxlength = parseInt(this.props.maxlength, 10);
      }

      let value = event.currentTarget.value;
      let filter = "";
      let filterDots = false;

      if (restrict === "twitter") { filter = /[^a-zA-Z0-9_]/gi; }
      if (restrict === "float") { filter = /[^0-9.]/gi; filterDots = true; }
      if (restrict === "integer") { filter = /[^0-9]/gi; }
      if (restrict === "alphanum") { filter = /[^a-zA-Z0-9]/gi; }
      if (restrict === "username") { filter = /[^a-zA-Z0-9_]/gi; }
      if (restrict === "upperalphanum") { filter = /[^a-zA-Z0-9]/gi; }
      
      value = value.replace(filter, "");
      if (filterDots) {
        if (value.length > 20) {
          value = value.substring(0, 20);
        }
      }

      if (restrict === "username") {
        value = value.toLowerCase();
      }

      if (restrict === "upperalphanum") {
        value = value.toUpperCase();
      }

      if (maxlength !== 0 && value.length > maxlength) {
        value = value.substring(0, maxlength);
      }
      
      if (this.props.hasOwnProperty("onChange")) {
        this.props.onChange(event.currentTarget.getAttribute("name"), value);
      }
    }

    handleButtonAction(event) {
      if (this.props.onButtonAction) { this.props.onButtonAction(this.props.name); }
    }

    render() {  
        let disabled = this.props.disabled ? this.props.disabled : false;
        let placeholder = this.props.placeholder ? this.props.placeholder : "";
        let logo = this.props.logo ? this.props.logo : "";
        let append = this.props.append ? this.props.append : "";
        let appendRight = this.props.appendRight ? this.props.appendRight : "";
        let buttonActionText = this.props.buttonActionText ? this.props.buttonActionText : "";

        return (
          <div className={this.props.className} style={{position: "relative", display:"inline-block"}} >
            <input readOnly={disabled} style={{position: "relative", zIndex:"2"}} placeholder={placeholder} type="text" autoComplete="off" className={"textInput textInputAutoWidth" + (logo === "" && append === "" && appendRight === "" ? " whiteBg" : " transparentBg") + (disabled ? (appendRight === "" ? " disabled" : " disabledAppendRight") : "") + (logo !== "" ? " iconPadding" : "")} name={this.props.name} value={this.props.value + (disabled && append !== "" ? " " + append : "")} onChange={this.handleChange} onBlur={this.handleBlur} onFocus={this.handleFocus} />
            {!disabled && logo !== "" && <div className={this.props.value === "" ? "hide" : "inputLeftSideExplainer"}><span style={{color:"#fff", visibility:"hidden"}}>{this.props.value}&nbsp;</span><span>{logo}</span></div>}
            <div className="autosuggestLeftSideExplainer"><span style={{color:"#fff", visibility:"hidden"}}>&nbsp;</span><span className="autosuggestSearchIcon">{logo}</span></div>
            {!disabled && append !== "" && <div className={this.props.value === "" ? "hide" : "inputLeftSideExplainer"}><span style={{color:"#fff", visibility:"hidden"}}>{this.props.value}&nbsp;</span><span>{append}</span></div>}
            {!disabled && appendRight !== "" && <div className={this.props.value === "" ? "hide" : "inputRightSideExplainer"}><span>{appendRight}</span></div>}
            {disabled && appendRight !== "" && <div style={{background:"var(--disabled-field-bg-color)"}} className={this.props.value === "" ? "hide" : "inputRightSideExplainer"}><span>{appendRight}</span></div>}
            {disabled && buttonActionText !== "" && <div onClick={this.handleButtonAction} className="changeLink">{buttonActionText}</div>}
          </div>
        );
    }
  }
  
  export default InputBasic;
