import { connect } from 'react-redux';
import React, { Component } from 'react'
import './DialogFunding.css';
import { changeInputValue, sendToAddress } from './DialogFundingActions';
import DialogTitle from './DialogTitle';
import LineCard from './LineCard';
import InputBasic from './InputBasic';
import ButtonAction from './ButtonAction';
import utils from 'web3-utils';
import loadImg from '../load_blue.gif';
import { track, addCommasToNumber, getPriceMessageForToken, getTestModeFromStore } from './ActionUtils';
import { shared } from "../Shared";
import Link from './Link';

class DialogFunding extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCopy = this.handleCopy.bind(this);
    this.handleTransfer = this.handleTransfer.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
  }
  
  handleCopy(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"copy_address", dialog:"balances"})));
    var copyElem = document.getElementById("clipboardText");
    copyElem.focus();
    copyElem.select();
    copyElem.setSelectionRange(0, copyElem.value.length);
    document.execCommand("copy");
    this.props.dispatch(changeInputValue("showCopiedMsg", true));
  }

  handleTransfer(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"transfer", dialog:"balances"})));
    let type = this.props.dialogFunding.type;
    let toAddress = this.props.dialogFunding.toAddressInput;
    if (type === "withdraw" && utils.isAddress(toAddress)) {
      let token = this.props.dialogFunding.token;
      let amount = parseFloat(this.props.dialogFunding.amount);
      this.props.dispatch(sendToAddress(token, amount, toAddress));
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleTextareaChange(event) {
    // Do nothing
  }
  
  render() {
    
    let type = this.props.dialogFunding.type;
    let token = this.props.dialogFunding.token;
    let selectedAddress = this.props.auth.ethAddress;
    if (selectedAddress === "") {
      selectedAddress = "ETH wallet disconnected";
    }

    let dialogTitle = "Balances";
    if (type === "deposit") {
      dialogTitle = "Deposit " + token.toUpperCase();
    }
    else if (type === "withdraw") {
      dialogTitle = "Withdraw " + token.toUpperCase();
    }

    let tokenUppercase = token.toUpperCase();

    let balanceFloat = 0;
    if (tokenUppercase === "ETH") {
      let ethBalance = this.props.auth.ethBalance;
      if (ethBalance) {
        balanceFloat = parseFloat(ethBalance);
      }
    }
    else {
      let erc20Balances = this.props.auth.erc20Balances;
      if (erc20Balances.hasOwnProperty(tokenUppercase)) {
        balanceFloat = parseFloat(erc20Balances[tokenUppercase].balance);
      }
    }
    let balanceWithCommas = addCommasToNumber(balanceFloat);

    let { showCopiedMsg=false, withdrawStatus="", transactionHash="" } = this.props.dialogFunding;

    let amountWithCommas = addCommasToNumber(this.props.dialogFunding.amount);

    let priceMessage = getPriceMessageForToken(this.props.dialogFunding.amount, this.props.dialogFunding.token);
    if (amountWithCommas.length + priceMessage.length > 30) {
      priceMessage = "";
    } 

    let transferDisabled = true;
    let amountFloat = parseFloat(this.props.dialogFunding.amount);
    if (amountFloat > 0 && amountFloat < balanceFloat && utils.isAddress(this.props.dialogFunding.toAddressInput)) {
      transferDisabled = false;
    }   

    return (
      <div className="contentSection">
        <div className="adjacentCard">
          <DialogTitle title={dialogTitle} />
          {type === "deposit" && (<div>
            <div>
              <div>Send {token.toUpperCase()} to your wallet address below to use these funds with Atstake.</div>
              <div className="moveTop20"><textarea suppressContentEditableWarning={true} contentEditable={true} className="copyTextArea" onClick={this.handleCopy} readOnly={false} type="text" value={selectedAddress} onChange={this.handleTextareaChange} id="clipboardText" /></div>
              <div><ButtonAction name="transfer" className="moveRight5" text="Copy address" onClick={this.handleCopy}/><span className={showCopiedMsg ? "copySuccessMsg" : "hide"}>Copied to clipboard!</span></div>
            </div>
          </div>)}
          {type === "withdraw" && (<div>
            {withdrawStatus === "pending" ? (
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>{"Waiting for "}<Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), transactionHash)}>withdraw transaction</Link>{" to complete."}</div>
                <div><img style={{position:"relative", left:"-8px"}} className="loadIcon" src={loadImg} alt="Loading" /></div>
              </div>
            ) : (
              <div>
                <div className="contentLabel">You have {balanceWithCommas} {tokenUppercase}. Enter the amount of {token.toUpperCase()} and the address you'd like to withdraw to.</div>
                <InputBasic restrict="float" placeholder="Amount" type="text" appendRight={priceMessage} className="dialogInput moveTop20" name="amount" value={amountWithCommas} onChange={this.changeInputValue} />
                <InputBasic maxlength={shared.MAX_ETHEREUM_ADDRESS_LENGTH} placeholder="To Ethereum address" type="text" className="dialogInput moveTop20" name="toAddressInput" value={this.props.dialogFunding.toAddressInput} onChange={this.changeInputValue} />
                <div><ButtonAction disabled={transferDisabled} name="transfer" className="moveTop20" text="Transfer" onClick={this.handleTransfer}/></div>
                {withdrawStatus === "success" && <div className="moveTop5" style={{fontSize:"12px", color:"var(--green-color)"}}>Withdraw completed</div>}
                {withdrawStatus === "error" && <div className="moveTop5" style={{fontSize:"12px", color:"var(--red-color)"}}>Error withdrawing</div>}
              </div>
            )}
          </div>)}
        </div>
        <div className="adjacentCard">
          {type === "deposit" && token === "wbtc" && (
            <div className="">Purchase WBTC using <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.airswap.io">Airswap</Link> or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.wbtc.network">another WBTC provider</Link>.</div>
          )}
          {type === "deposit" && token === "dai" && (
            <div className="">Purchase DAI by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://pro.coinbase.com">Coinbase Pro</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://eth2dai.com">Eth2Dai</Link>, or visit <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://makerdao.com">Maker's website</Link>.</div>
          )}
          {type === "deposit" && token === "usdc" && (
            <div className="">Purchase USDC by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://usdc.circle.com/start">Circle</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://coinbase.com">Coinbase</Link>, or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://en.wikipedia.org/wiki/Cryptocurrency_exchange">another exchange</Link>.</div>
          )}
          {type === "deposit" && token !== "wbtc" && token !== "dai" && token !== "usdc" && (
            <div className="">Purchase {token.toUpperCase()} by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://coinbase.com">Coinbase</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://binance.com">Binance</Link>, or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://en.wikipedia.org/wiki/Cryptocurrency_exchange">another exchange</Link>.</div>
          )}
          {type === "withdraw" && token === "wbtc" && (
            <div className="">Sell WBTC using <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.airswap.io">Airswap</Link> or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.wbtc.network">another WBTC provider</Link>.</div>
          )}
          {type === "withdraw" && token === "dai" && (
            <div className="">Sell DAI by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://pro.coinbase.com">Coinbase Pro</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://eth2dai.com">Eth2Dai</Link>, or visit <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://makerdao.com">Maker's website</Link>.</div>
          )}
          {type === "withdraw" && token === "usdc" && (
            <div className="">Sell USDC by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://usdc.circle.com/start">Circle</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://coinbase.com">Coinbase</Link>, or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://en.wikipedia.org/wiki/Cryptocurrency_exchange">another exchange</Link>.</div>
          )}
          {type === "withdraw" && token !== "wbtc" && token !== "dai" && token !== "usdc" && (
            <div className="">Sell {token.toUpperCase()} by creating an account with <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://coinbase.com">Coinbase</Link>, <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://binance.com">Binance</Link>, or <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://en.wikipedia.org/wiki/Cryptocurrency_exchange">another exchange</Link>.</div>
          )}
        </div>
        <LineCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogFunding: state.dialogFunding
  }
}

const DialogFundingContainer = connect(mapStateToProps, null)(DialogFunding);
export default DialogFundingContainer;