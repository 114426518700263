import { connect } from 'react-redux';
import React, { Component } from 'react';
import './Incentives.css';
import Link from './Link';

class Incentives extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {


    return (
      <div>
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageTitle">Incentivizing fair outcomes</div>
            <div className="pageCardSmallHeading moveTop40">
              We've created incentives so that people won't benefit from reporting false contract outcomes.

              <div className="pageCardSmallHeading moveTop20">
              There are four main components of our incentive system: arbitration, reputation, no single point of failure, 
              and (optional) security deposits.</div>
            </div>
          </div>
        </div>
        <div className="pageNarrowSection" style={{backgroundColor:"var(--page-section-b-bg-color)"}}>
          <div className="restrictSectionWidth">
            <div className="pageCardHeading moveTop20">Arbitration</div>
            <div className="pageLightText moveTop5">
                Our arbitration system relies on an Ethereum smart contract implemtning 
                a <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://coincenter.org/entry/what-is-multi-sig-and-what-can-it-do"}>two-of-three multisignature</Link> system. 
                Our contracts are designed with three participants: two parties and an arbitrator. Our system ensures that money 
                can't be moved unilaterally without at least two of the participants agreeing. 
            </div>
            <div className="pageLightText moveTop20">
                The arbitrator must be pre-approved by both parties, allowing them each to reduce their risk by rejecting
                arbitrators who they don't trust.
                When the parties agree on the outcome the arbitrator does nothing (and is not paid a fee). 
                When the parties disagree the arbiter decides the outcome and the arbitrator's fee is paid by the party
                who disagrees with the arbitrator. Because the loser of a dispute pays the full arbitration fee, both parties have 
                an incentive not to report unreasonable outcomes.
            </div>
            <div className="pageLightText moveTop20">
                This system ensures that two of the three people involved would have to be dishonest to cause 
                a contract with a clear outcome to be wrongly decided 
                or to result in an honest person paying an arbitration fee. Even someone
                who doesn't fulfill their end of the contract has an incentive to report 
                this fact accurately to avoid having to pay an arbitration fee.
            </div>

            <div className="pageCardHeading moveTop40">Reputation</div>
            <div className="pageLightText moveTop5">
                If someone reports a false claim about whether they fulfilled the terms of a contract, the Ethereum blockchain will contain 
                proof that this claim was made by someone controlling their Ethereum address.
            </div>
            <div className="pageLightText moveTop20">
                We make it easy to associate someone's Ethereum address with their identity by allowing users to prove a two-way link between
                their Ethereum address and other accounts that are tied to their identity (Twitter, Facebook, Reddit, etc.). 
                We display these proofs on every user's public Atstake profile. The more strongly associated someone's 
                Atstake account is with an identity that they care about, the less willing they'll be to create public proof of their dishonesty.
            </div>
            <div className="pageLightText moveTop20">
                In addition to external sources of reputation,
                each user's profile contains a reputation section showing how other Atstake users rated them after previous contracts.
            </div>
            <div className="pageLightText moveTop20">
                One implication of this system is that as long as the combined damage to the reputations of the
                arbitrator and your counterparty (from public proof of their conspiracy against you) is greater than any 
                money you'll deposit, they won't benefit from such a conspiracy.
            </div>

            <div className="pageCardHeading moveTop40">No single point of failure</div>
            <div className="pageLightText moveTop5">
                The Ethereum code that holds all funds for the contract is designed so that no single person can move or freeze funds.
                The arbitrator can't submit a ruling unless summoned by one of the parties, and can't ever send any staked funds to anyone 
                other than the two parties to the contract. 
                The limits that the contract places on the power of the participants reduces the incentive to behave dishonestly.
            </div>
            
            <div className="pageCardHeading moveTop40">Security deposits (optional)</div>
            <div className="pageLightText moveTop5">
                As an optional incentive to behave honestly, the parties to a contract can stake funds that are meant to
                function as an extra security deposit.
            </div>
            <div className="pageLightText moveTop20">
                For instance, suppose Alice is buying a vase from Bob for $50 USDC.
                Instead of staking only the $50 to Bob's $0 stake, Alice could stake $80 and Bob could stake $30. 
                They've both staked $30 more than would be required by a simpler contract. 
                The contract terms could be written such that if the arbitrator judges anyone to have acted in bad faith, they
                forfeit their $30 security deposit to the other person.
                This gives both parties even more incentive to behave honestly
                and allows for compensation to the other party if one party behaves badly.
            </div>
            <div className="moveTop20" />
          </div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    incentives: state.incentives,
    overlay: state.overlay
  }
}

const IncentivesContainer = connect(mapStateToProps, null)(Incentives);
export default IncentivesContainer;