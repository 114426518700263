import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Examples.css';
import './Common.css';
import GapCard from "./GapCard";
import Link from './Link';
import domainTransferImg from '../domaintransfer2.png';
import stairwayImg from '../stairway.png';
import towelVestImg from '../towelvest.png';
import runEveryDayImg from '../runeveryday.png';
import securityDepositImg from '../securitydeposit.png';
import secretAgreementImg from '../secretagreement.png';
import insuranceImg from '../insurance.png';

class Examples extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.selectPage = this.selectPage.bind(this);
  }
  
  componentDidMount() {
  }

  selectPage(page) {
  }
  
  render() {
    return (
      <div>
        <div className="pageSection">
          <GapCard />
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageTitle">Example Contracts</div>
              <div className="pageLightText moveTop40">Atstake is a general tool which can be used for many types of contracts. 
              The examples below illustrate Atstake's flexibility and will hopefully give you ideas for how to use it.</div>

              <div className="pageLightText moveTop20">These examples have simple descriptions for brevity.
              Depending on your circumstance you may want to specify your contracts in more detail.</div>
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-b-bg-color)"}}>
          <GapCard />
          <GapCard />
          <div className="restrictSectionWidth">
          <div className="pageCardHeading">Domain name transfers</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={domainTransferImg} alt="Domain name transfer" /></div>
                    
            <div className="pageLightText moveTop20">
            The easier it is to prove whether the terms of your agreement have been satisfied the better Atstake will work. 
            </div>

            <div className="pageLightText moveTop20">
            Domain name transfers are an example of something easy for anyone to verify, which means that any arbitrator who cares 
            about their reputation is very unlikely to give an incorrect ruling for this type of agreement.
            </div>

            <div className="pageLightText moveTop20">
            Existing domain name <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://domainnamewire.com/2014/01/21/domain-escrow-price-comparison/">escrow services</Link> charge hundreds 
            and sometimes thousands of USD for their services even if the transaction completes without a dispute. 
            </div>

            <div className="pageLightText moveTop20">
            A similar example is online ticket reselling. 
            For tickets whose transfer can be independently verified, Atstake allows for safe ticket sales without <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.stubhub.com/">Stubhub's</Link> high fees.
            </div>

            <div className="pageCardHeading moveTop40">In-person services</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={stairwayImg} alt="Stairway repair" /></div>

            <div className="pageLightText moveTop20">
            For services that can't be performed immediately when money is exchanged, the service provider may worry about not being fully paid and the purchaser of the service may worry
            that the quality of the service may be less than what was agreed on.
            </div>

            <div className="pageLightText moveTop20">
            Atstake solves this by ensuring that the funds are locked up and can't
            be moved by either party until an agreement is reached or the arbitrator approves.
            </div>

            <div className="pageCardHeading moveTop40">Non-performance penalties</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={towelVestImg} alt="Towel-vest website" /></div>

            <div className="pageLightText moveTop20">
            This agrement shows a penalty for non-performance. If the buyer needs the website before a certain date
            they can request that the service provider forfeits a bond if they fail to complete the website on time. Without this penalty the 
            service provider may be too tempted to not do any work at all if a better opportunity presents itself.
            </div>

            <div className="pageLightText moveTop20">
            Note that this agreement refers to an external document. This is safe if the content of this document could be proven to the arbitrator if necessary.
            To make these proofs even more reliable the hash of a document's content can be included.
            </div>

            <div className="pageCardHeading moveTop40">Achievement of personal goals</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={runEveryDayImg} alt="Running" /></div>

            <div className="pageLightText moveTop20">
            Atstake can be used to hold yourself accountable for achieving your goals. 
            This is the idea
            behind <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://stickk.com"}>Stickk</Link>. 
            Atstake charges no fees beyond what it costs to use the Ethereum network (~$0.25 USD per agreement) whereas Stickk
            charges 20% of the amount transferred (and 50% if transferred to a charity).
            </div>

            <div className="pageCardHeading moveTop40">Security deposits</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={securityDepositImg} alt="Security deposit" /></div>

            <div className="pageLightText moveTop20">
            You may have an expensive item that you're willing to let someone use as long as you know that it won't be difficult to get them to
            pay for any damages. Pursing claims after the fact without a security deposit can be a huge hassle. Atstake can give you peace of 
            mind in these situations with almost no set-up cost, while also not requiring the other person to trust you with unilateral control
            of their deposit.
            </div>

            <div className="pageCardHeading moveTop40">Insurance</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={insuranceImg} alt="Insurance" /></div>

            <div className="pageLightText moveTop20">
            Sellers of insurance don't generally lock up their potential payouts for every insurance policy. This would often require locking up
            too much capital, and their reputations and the threat of legal recourse is usually enough for people to trust that these companies
            will pay out when they should.
            </div>
            
            <div className="pageLightText moveTop20">
            However in some cases insurance where both sides lock up funds might be the best 
            option -- for instance in areas where people aren't integrated into the existing financial system and in cases where
            the probability of a payout is relatively high. 
            </div>

            <div className="pageLightText moveTop20">
            We aren't confident that Atstake will find a use case here, but we think it's an interesting possibility. 
            </div>

            <div className="pageCardHeading moveTop40">Secret agreements</div>
            <div className="moveTop10"><img style={{width:"360px", border:"1px solid #ccc"}} className="moveTop5" src={secretAgreementImg} alt="Secret agreement" /></div>

            <div className="pageLightText moveTop20">
            You can hide the details of your agreements from other people by marking their visibility 'Ratings only' or 'Private'. 
            However the text of these agreements is still available to Atstake (so that we can show it to your counterparty and the arbitrator).
            </div>

            <div className="pageLightText moveTop20">
            If you and your counterparty prefer to coordinate outside of Atstake for the contract details, you can keep these details hidden even
            from Atstake 
            by <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://passwordsgenerator.net/sha256-hash-generator/"}>hashing</Link> the
            information as shown above. This also allows you to only reveal these details to the arbitrator if
            there's a dispute. 
            </div>

            <div className="pageCardHeading moveTop40">Many more...</div>

            <div className="pageLightText moveTop20">
            This is just a small sample of what's possible with Atstake. You can create any agreement that involves either one or two parties staking funds, 
            then these funds being distributed based on any criteria. 
            </div>
          </div>
          <GapCard />
          <GapCard />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    examples: state.examples
  }
}

const ExamplesContainer = connect(mapStateToProps, null)(Examples);
export default ExamplesContainer;