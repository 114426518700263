//import update from 'immutability-helper';

const emptyState = {
  agreementid: "",
  versionid: "",
  status: "",
  title: null,
  message: null,
  selectedAddress: "", 
  selectedUsername: "", 
  selectedIsYou: false, 
  mode: "",
  changeType: "",
  addressList: [],
  addressListLoaded: false,
  defaultAddress: "",
  defaultAddressLoaded: false,
  addressDropdownSelected: ""
}

const DialogAddressReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGADDRESS_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGADDRESS_ADDRESS_LIST" : {
      let { list=[] } = action.payload;
      return {...state, addressList: list, addressListLoaded: true};
    }
    case "DIALOGADDRESS_DEFAULT_ADDRESS" : {
      let defaultAddress = action.payload;
      return {...state, defaultAddress: defaultAddress, defaultAddressLoaded: true};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "address") {
        let { 
          agreementid="",
          versionid="",
          status="",
          title="", 
          message="", 
          selectedAddress="", 
          selectedUsername="", 
          selectedIsYou=false, 
          mode="",
          changeType="" 
        } = params;

        return {
          ...state, 
          ...emptyState, 
          addressDropdownSelected: selectedAddress,
          agreementid,
          versionid,
          status,
          title, 
          message, 
          selectedAddress, 
          selectedUsername, 
          selectedIsYou, 
          mode,
          changeType 
        };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogAddressReducer