//import update from 'immutability-helper';

const emptyState = {
  postingid: "",
  hasError: false
}

const DialogContractFromPostingReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGCONTRACTFROMPOSTING_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "contract_from_posting") {
        let { postingid="" } = params;
        return {...state, ...emptyState, postingid };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogContractFromPostingReducer