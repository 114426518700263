import React, { Component } from 'react'
import { connect } from 'react-redux';
import { navNewURL, formatPhoneNumber } from './ActionUtils';
import './UserAccount.css';
import { updateProfileSummary, updateProfileEmail, changeInputValue, changeArbitrationInput, updateArbitrationSettings, refreshLinkedAccountListForUser, refreshUserStats } from './UserAccountActions';
import { refreshProfileData } from './ProfileHeaderActions';
import { openOverlay } from './OverlayActions';
import TitleCard from './TitleCard';
import GapCard from './GapCard';
import LoadCard from './LoadCard';
import ButtonAction from './ButtonAction';
import InputCheckbox from './InputCheckbox';
import Attestation from './Attestation';
import { getIsProfileOwnAccount, pageNotLoaded, titleByType, getEthereumAddressList, calcPercentage, getContentWidth } from "./ActionUtils";
import { 
  DEFAULT_BUTTON_WIDTH
} from './Constants.js';
import moment from 'moment-timezone';
import ArbitratorSettingsCard from './ArbitratorSettingsCard';
import InputTextarea from './InputTextarea';
import { track } from './ActionUtils';
import { shared } from '../Shared';
import ProfileHeader from './ProfileHeader';
import NotFoundUser from './NotFoundUser';

class UserAccount extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.changeArbitrationInput = this.changeArbitrationInput.bind(this);
    this.titleByType = this.titleByType.bind(this);
    this.inputForType = this.inputForType.bind(this);
    this.updateArbitrationSettings = this.updateArbitrationSettings.bind(this);
    this.handlePrivacyClick = this.handlePrivacyClick.bind(this);
    this.navFunding = this.navFunding.bind(this);
    this.addNewAttestation = this.addNewAttestation.bind(this);
    this.addNewEthereumAttestation = this.addNewEthereumAttestation.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.viewAgreement = this.viewAgreement.bind(this);
    this.ratingsNav = this.ratingsNav.bind(this);
    this.submitSummary = this.submitSummary.bind(this);
    this.submitEmailPref = this.submitEmailPref.bind(this);
  }

  componentDidMount() {
    let {
      selectedUser=""
    } = this.props.profileHeader;

    if (selectedUser !== "") {
      this.props.dispatch(refreshLinkedAccountListForUser(selectedUser));
      this.props.dispatch(refreshProfileData(selectedUser));
      this.props.dispatch(refreshUserStats(selectedUser));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let selectedUser = this.props.profileHeader.selectedUser;
    let prevSelectedUser = prevProps.profileHeader.selectedUser;

    if (
      (selectedUser !== prevSelectedUser || 
      (this.props.user.hasLatestData !== prevProps.user.hasLatestData && 
      this.props.user.hasLatestData === false)) && 
      selectedUser !== ""
    ) {
      this.props.dispatch(refreshUserStats(selectedUser));
    }

    if (
      (selectedUser !== prevSelectedUser || 
      (this.props.user.hasLatestData !== prevProps.user.hasLatestData && 
      this.props.user.hasLatestData === false)) && 
      selectedUser !== ""
    ) {
      this.props.dispatch(refreshLinkedAccountListForUser(selectedUser));
    }

    if (
      (selectedUser !== prevSelectedUser || 
      (this.props.user.hasLatestData !== prevProps.user.hasLatestData &&
      this.props.user.hasLatestData === false)) && 
      selectedUser !== ""
    ) {
      this.props.dispatch(refreshProfileData(selectedUser));
    }
  }

  ratingsNav(selectedUser, selectedFilter) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"ratings_link", selectedUser, selectedFilter})));
    this.props.dispatch(navNewURL('/ratings?user=' + selectedUser + '&filter=' + selectedFilter));
  }

  submitSummary(name, value) {
    this.props.dispatch(changeInputValue("disableUpdateButtons", true));
    this.props.dispatch(updateProfileSummary(this.props.user.summary));
  }

  submitEmailPref(name, value) {
    this.props.dispatch(changeInputValue("disableUpdateButtons", true));
    let email_settings = {
      new_messages: this.props.user.emailNewMessages,
      new_contracts: this.props.user.emailNewContracts,
      contract_updates: this.props.user.emailContractUpdates,
      announcements: this.props.user.emailAnnouncements
    };
    this.props.dispatch(updateProfileEmail(email_settings));
  }

  addNewAttestation(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"add_new_attestation"})));

    this.props.dispatch(openOverlay("add_attestation", {
      type: "",
      privacy: "", 
      value: "",
      step: "new",
      stepInfo: {}
    }));
  }

  addNewEthereumAttestation(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"add_new_ethereum"})));

    this.props.dispatch(openOverlay("add_attestation", {
      type: "ethereum",
      privacy: "", 
      value: "",
      step: "new",
      stepInfo: {}
    }));
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  changeArbitrationInput(name, value, mode) {
    this.props.dispatch(changeArbitrationInput(name, value, mode));
  }

  updateArbitrationSettings(mode) {
    this.props.dispatch(changeInputValue("disableUpdateButtons", true));
    this.props.dispatch(track("action", "button", JSON.stringify({type:"update_arbitration_settings"})));

    let { 
      arbitrationDataProd={}, 
      arbitrationDataTest={}
    } = this.props.user;

    let arbitrationData = {};
    if (mode === "prod") {
      arbitrationData = arbitrationDataProd;
    }
    else if (mode === "test") {
      arbitrationData = arbitrationDataTest;
    }

    let {
      fee_value="",
      fee_type="",
      auto_status="",
      description=""
    } = arbitrationData;

    this.props.dispatch(updateArbitrationSettings(fee_value, fee_type, auto_status, description, mode));
  }

  handlePrivacyClick(allowEdit, type, value, verified, privacy, user1, defaultEthereum, ethereumAddressList) {
    this.props.dispatch(openOverlay("view_attestation", {allowEdit, type, value, verified, user1, mode: "privacy", privacy, defaultEthereum, ethereumAddressList}));
  }

  handleFilterChange(name, value) {
    let {
      selectedUser=""
    } = this.props.profileHeader;

    if (name === "selectedFilter") {
      this.props.dispatch(navNewURL('/profile?user=' + selectedUser + '&filter=' + value));
    }
  }

  viewAgreement(agreementId, versionId) {
    this.props.dispatch(navNewURL('/view?agreementid=' + agreementId + "&versionid=" + versionId));
  }

  navFunding(event) {
    this.props.dispatch(navNewURL('/balances'));
  }

  titleByType(type) {
    return titleByType(type);
  }

  inputForType(type) {
    let input = this.props.user.newAttestationInput;
    let selectedAddress = this.props.auth.ethAddress;

    if (type === "ethereum") {
      return selectedAddress;
    }
    
    return input;
  }
  
  render() {
    if (pageNotLoaded(this.props.user)) { return null };

    let username = this.props.auth.username;
    let loggedout = this.props.auth.status === "loggedout";

    let {
      selectedUser="",
      queryParamUser="",
      userExists=false,
      dataLoaded: headerDataLoaded = false  
    } = this.props.profileHeader;

    let userStats = this.props.user.userStats;  
    let userStatsLoaded = this.props.user.userStatsLoaded; 
    let linkedAccountsLoaded = this.props.user.linkedAccountsLoaded;
    let linkedAccountList = this.props.user.linkedAccountList;

    if ((headerDataLoaded && !userExists) || (loggedout && queryParamUser === "")) {
      return (
        <div>
            <ProfileHeader />
            <NotFoundUser selectedUser={selectedUser} />
        </div>
      );
    } else {
      // If two of the sections aren't loaded, show a general loading screen. 
      // If just one is missing we can show just loading for it
      let unloadedSections = 0;
      if (!headerDataLoaded) unloadedSections++;
      if (!userStatsLoaded) unloadedSections++;
      if (!linkedAccountsLoaded) unloadedSections++;
      if (unloadedSections >= 2) {
        return (
          <div>
              <ProfileHeader />
              <GapCard />
              <LoadCard key="load" />
          </div>
        );
      }
    }

    let ownAccount = getIsProfileOwnAccount();
    let contentWidth = getContentWidth();
    let firstColumnWidth = Math.min(180, Math.round(contentWidth * 0.31));

    let defaultEthereumAddress = this.props.user.defaultEthereumAddress;
    let summary = this.props.user.summary || "";
    let showSummary = ((summary !== null) && (summary.trim() !== "")) || ownAccount;
    let attestationCardList = [];
    let ethereumCardList = [];
    let attestationElem = [];
    let ethereumElem = [];
    let disabledElem = [];
    let ethereumAddressList = getEthereumAddressList(linkedAccountList, defaultEthereumAddress);
    for (let i = 0; i < linkedAccountList.length; i++) {
      let entry = linkedAccountList[i];
      let elem = null;
      let value = entry.account_type === "phone" ? formatPhoneNumber(entry.account_name) : entry.account_name;
      if (entry.account_type === "ethereum") {
        let isDefault = (defaultEthereumAddress === entry.account_name);
        elem = <Attestation key={i+ "_entry"} allowEdit={ownAccount} auth={this.props.auth} ethereumAddressList={ethereumAddressList} isDefault={isDefault} username={username} user1={entry.username} verified={entry.verified} privacy={entry.privacy} className="" type={entry.account_type} value={value} onClick={this.handlePrivacyClick} />;
        if (entry.privacy === "disabled") {
          disabledElem.push(elem);
        }
        else {
          ethereumElem.push(elem);
        }
      }
      else {
        elem = <Attestation key={i+ "_entry"} allowEdit={ownAccount} auth={this.props.auth} ethereumAddressList={null} isDefault={false} username={username} user1={entry.username} verified={entry.verified} privacy={entry.privacy} className="" type={entry.account_type} value={value} onClick={this.handlePrivacyClick} />;
        attestationElem.push(elem);
      }
    }

    if (attestationElem.length === 0) {
      if (linkedAccountsLoaded) {
        attestationCardList.push(
          <div key="card" className="contentCard">
            {ownAccount && <span>Link Twitter, Facebook, Reddit, and/or email accounts to build trust.</span>}
            {!ownAccount && <span>None</span>}
            {ownAccount && <div className="moveTop20"><ButtonAction enableMessageLine={true} message={this.props.user.btnLinkedStatus} name="add" className="" text="Add" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.addNewAttestation}/></div>}
          </div>
        );
      }
      else {
        attestationCardList.push(<LoadCard key="load" />);
      }
    }
    else {
      if (ownAccount) {
        attestationElem.push(
          <div key="cnt" className="moveTop20">
            <ButtonAction enableMessageLine={true} message={this.props.user.btnLinkedStatus} name="add" className="" text="Add" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.addNewAttestation}/>
          </div>
        );
      }

      attestationCardList.push(
        <div key="cnt" style={{paddingTop:"15px"}} className="contentCard">
          {attestationElem}
        </div>
      );
    }

    if (ethereumElem.length === 0 && disabledElem.length === 0) {
      if (linkedAccountsLoaded) {
        ethereumCardList.push(
          <div key="none_card" className="contentCard">
            Hidden
            {ownAccount && <div className="moveTop20"><ButtonAction enableMessageLine={true} message={this.props.user.btnEthereumStatus} name="add" className="" text="Add" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.addNewEthereumAttestation}/></div>}
          </div>
        );
        ethereumCardList.push(<GapCard key="gap2" />);
      }
      else {
        ethereumCardList.push(<LoadCard key="load" />);
        ethereumCardList.push(<GapCard key="gap" />);
      }
    }
    else {
      ethereumCardList.push(
        <div key="cnt" style={{paddingTop:"15px"}} className="contentCard">
          {ethereumElem}
          {disabledElem}
          {ownAccount && 
            <div className="moveTop20">
              <ButtonAction enableMessageLine={true} message={this.props.user.btnEthereumStatus} name="add" className="" text="Add" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.addNewEthereumAttestation}/>
            </div>
          }
        </div>
      );
    }

    let posCounterpartyRatingPercent = "0%";
    //let posArbitratorRatingPercent = "0%";
    //let rulingsPercent = "0%";
    let arbitrationWonPercent = "0%";
    //let counterpartyEarlyWithdrawPercent = "0%";
    let posRatingsAsArbitratorPercent = "0%";
    let doublePosRatingsAsArbitratorPercent = "0%";
    let doubleNegRatingsAsArbitratorPercent = "0%";
    if (userStatsLoaded) {
      posCounterpartyRatingPercent = calcPercentage(userStats.positiveRatingsByCounterparties, userStats.totalRatingsByCounterparties);
      //posArbitratorRatingPercent = calcPercentage(userStats.positiveRatingsByArbitrator, userStats.totalRatingsByArbitrator);
      arbitrationWonPercent = calcPercentage(userStats.agreementsWonInArbitration, userStats.agreementsDecidedInArbitration);
      //counterpartyEarlyWithdrawPercent = calcPercentage(userStats.counterpartyEarlyWithdrawals, userStats.totalCompletedAgreementsAsParty);
      //rulingsPercent = calcPercentage(userStats.totalRulingsGiven, userStats.totalCompletedAgreementsAsArbitrator);
      posRatingsAsArbitratorPercent = calcPercentage(userStats.positiveRatingsAsArbitrator, userStats.totalRatingsAsArbitrator);
      doublePosRatingsAsArbitratorPercent = calcPercentage(userStats.unanimousPositiveRatingsAsArbitrator, userStats.totalCompletedAgreementsAsArbitrator);
      doubleNegRatingsAsArbitratorPercent = calcPercentage(userStats.unanimousNegativeRatingsAsArbitrator, userStats.totalCompletedAgreementsAsArbitrator);
    }

    let createdUnix = this.props.user.createdUnix;
    let createdDisplay = "";
    if (createdUnix !== 0) {
      createdDisplay = moment.tz(createdUnix, "X", 'America/Los_Angeles').format('MMM D, YYYY');
    }

    let disableUpdateButtons = this.props.user.disableUpdateButtons;

    let testMode = this.props.auth.testMode;

    return (
      <div>
        <ProfileHeader />
        <div className="contentSection">
          <GapCard />
          {showSummary && <TitleCard title="Summary" />}
          {showSummary && <div className="contentCard">
            {ownAccount ? (
              <div className="">
                <InputTextarea maxlength={shared.MAX_PROFILE_SUMMARY_LENGTH} placeholder="Information about yourself" className="textAreaFullWidthTall" name="summary" value={summary} onChange={this.changeInputValue} />
                <div className="moveTop20"><ButtonAction disabled={disableUpdateButtons} enableMessageLine={true} message={this.props.user.btnSummaryStatus} name="update" className="" text="Update" minWidth={DEFAULT_BUTTON_WIDTH} onClick={disableUpdateButtons ? null : this.submitSummary}/></div>
              </div>
            ) : (
              <div className="profileSummary"><span className="preserveNewlines">{summary}</span></div>
            )}
          </div>}
          {showSummary && <GapCard />}
          <TitleCard title="Statistics" />
          <div className="contentCard">
          <table style={{tableLayout:"fixed", width:"100%", wordWrap: "break-word"}}><tbody>
              <tr className=""><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0", width:(firstColumnWidth + "px")}}>
                <span className="accountSummaryLabel">Completed contracts</span></td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}>
              {userStatsLoaded ? userStats.totalCompletedAgreementsAsParty + " as party, " + userStats.totalCompletedAgreementsAsArbitrator + " as arbitrator" : "..."}</td></tr>

              { userStats.totalRatingsByCounterparties > 0 && 
              <tr className="accountSummaryRow"><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0"}}>
                <span className="accountSummaryLabel">Ratings as party</span></td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}>
              {userStatsLoaded ? <span>{posCounterpartyRatingPercent + " positive ("}<span className="bluelink" onClick={() => this.ratingsNav(selectedUser, "ratings_participant")}>{userStats.totalRatingsByCounterparties + " total"}</span>)</span> : "..."}</td></tr>
              }

              { userStats.totalRatingsAsArbitrator > 0 && 
              <tr className="accountSummaryRow"><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0"}}>
                <span className="accountSummaryLabel">Ratings as arbitrator</span></td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}>
              {userStatsLoaded ? <span>{posRatingsAsArbitratorPercent + " positive ("}<span className="bluelink" onClick={() => this.ratingsNav(selectedUser, "ratings_arbitrator")}>{userStats.totalRatingsAsArbitrator + " total"}</span>)</span> : "..."}</td></tr>
              }

              { userStats.totalRatingsAsArbitrator > 0 && 
              <tr className="accountSummaryRow"><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0"}}>
              </td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}>
              {userStatsLoaded ? doublePosRatingsAsArbitratorPercent + " unanimous positive, " + doubleNegRatingsAsArbitratorPercent + " unanimous negative" : "..."}</td></tr>
              }

              { userStats.totalCompletedAgreementsAsParty > 0 && 
              <tr className="accountSummaryRow"><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0"}}>
                <span className="accountSummaryLabel">Dispute results</span></td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}>
              {userStatsLoaded ? (userStats.agreementsDecidedInArbitration > 0 ? "Won " + arbitrationWonPercent + " (" + userStats.agreementsDecidedInArbitration + " total)" : "No disputes") : "..."}</td></tr>
              }

              <tr className="accountSummaryRow"><td className="accountSummaryCell" style={{textAlign: "right", padding:"10px 0"}}>
                <span className="accountSummaryLabel">Joined</span></td><td className="accountSummaryCell" style={{padding:"10px 0px 10px 10px"}}><span>{createdDisplay}</span></td></tr>

            </tbody></table>
          </div>
          <GapCard />
          <TitleCard title="Linked accounts" />
          {attestationCardList}
          <GapCard /> 
          <div>
          <TitleCard title="Arbitrator settings" />
          <ArbitratorSettingsCard 
            mode={testMode ? "test" : "prod"}
            ownaccount={ownAccount}
            selectedUser={selectedUser} 
            onUpdate={ownAccount ? this.updateArbitrationSettings : null} 
            onChange={ownAccount ? this.changeArbitrationInput : null} 
            arbitrationData={testMode ? this.props.user.arbitrationDataTest : this.props.user.arbitrationDataProd} 
            updateStatus={this.props.user.btnArbitratorStatus}
            disableUpdate={disableUpdateButtons}
          />
          <GapCard /> 
          </div>
          <TitleCard title="Ethereum addresses" />
          {ethereumCardList}
          {ownAccount && <GapCard />}
          {ownAccount && <TitleCard title="Email notifications" />}
          {ownAccount && <div className="contentCard">{this.props.user.userHasEmail ? (
            <div>
              <div className="moveTop5"><InputCheckbox name="emailNewMessages" value={this.props.user.emailNewMessages} label="New messages and comments" onChange={this.changeInputValue} /></div>
              <div className="moveTop5"><InputCheckbox name="emailNewContracts" value={this.props.user.emailNewContracts} label="New contracts" onChange={this.changeInputValue} /></div>
              <div className="moveTop5"><InputCheckbox name="emailContractUpdates" value={this.props.user.emailContractUpdates} label="Contract updates" onChange={this.changeInputValue} /></div>
              <div className="moveTop5"><InputCheckbox name="emailAnnouncements" value={this.props.user.emailAnnouncements} label="Atstake announcements" onChange={this.changeInputValue} /></div>
              <div className="moveTop20"><ButtonAction disabled={disableUpdateButtons} enableMessageLine={true} message={this.props.user.btnEmailStatus} name="update" className="" text="Update" minWidth={DEFAULT_BUTTON_WIDTH} onClick={disableUpdateButtons ? null : this.submitEmailPref}/></div>
            </div>
          ) : (
            <div>Please <span className="bluelink" onClick={this.addNewAttestation}>link an email address</span> to receive notifications.</div>
          )}
          </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    user: state.user,
    profileHeader: state.profileHeader
  }
}

const UserAccountContainer = connect(mapStateToProps, null)(UserAccount);
export default UserAccountContainer;