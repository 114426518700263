import { navNewURL } from "./ActionUtils"
import { closeOverlay } from './OverlayActions';
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWPOSTING_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function setHasLocation(hasLocation) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWPOSTING_SET_HAS_LOCATION',
      payload: {hasLocation}
    });
  }
}

export function selectLocation(type, display) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWPOSTING_SELECT_LOCATION',
      payload: {type, display}
    });
  }
}

export function selectCategory(breadType, breadCategory) {
  return function(dispatch) {
    let dialogNewPosting = store.getState().dialogNewPosting;

    let {
      hasLocation=false,
      breadLocation=""
    } = dialogNewPosting;

    if (!hasLocation) {
      breadLocation = "global";
    }

    dispatch(navNewURL('/newposting?location=' + breadLocation + '&type=' + breadType + '&category=' + breadCategory));
    dispatch(closeOverlay());
  }
}

export function selectPostingType(type) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWPOSTING_SELECT_POSTING_TYPE',
      payload: {type}
    });
  }
}

export function selectMode(mode) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWPOSTING_SELECT_MODE',
      payload: {mode}
    });
  }
}
