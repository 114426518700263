import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogBlockchainArbitrate.css';
import { changeInputValue } from './DialogBlockchainArbitrateActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { summaryFromAgreementBlockchain, hasEnoughFunds } from "./ActionUtils";
import { firstLetterUpperCase } from './AgreementMessageUtils';
import { 
  requestArbitration,
  approveAllowanceForAgreement
} from './BlockchainActions';
import { shared } from "../Shared";
import Link from './Link';
import moment from 'moment-timezone';
import loadImg from '../load_blue.gif';
import { track, getTestModeFromStore } from './ActionUtils';

class DialogBlockchainArbitrate extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.request = this.request.bind(this);
    this.handleApproveAllowanceForArbitratorFee = this.handleApproveAllowanceForArbitratorFee.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  request(name, value) {
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { disputeFee="0" } = blockchainDataObj;
    let { deployedid="", agreedData="", contractType="", arbitrationFeeType="" } = this.props.agreement;
    this.props.dispatch(track("action", "button", JSON.stringify({type:"request_arbitration", dialog:"blockchain_arbitrate", deployedid, contractType, arbitrationFeeType})));

    this.props.dispatch(requestArbitration(blockchainDataObj, deployedid, agreedData, contractType, disputeFee, arbitrationFeeType));
  }

  handleApproveAllowanceForArbitratorFee(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"approve_allowance", dialog:"blockchain_arbitrate"})));

    let { youAreUser1=false, youAreUser2=false, agreedSha3="", agreedData="", contractType="" } = this.props.agreement;
    if (agreedSha3 !== "" && agreedData !== "" && (youAreUser1 || youAreUser2)) {
      let agreedDataObj = JSON.parse(agreedData);
      let stakeType = agreedDataObj.arbitration_fee_type;
      let stakeAmount = agreedDataObj.arbitration_fee_raw;
      //console.log("stakeType: " + stakeType + ", stakeAmount: " + stakeAmount);
      this.props.dispatch(approveAllowanceForAgreement(stakeType, stakeAmount, contractType));
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    let {
      arbitrationFeeType="",
      arbitrationFeeInput="",
      contractType=""
    } = this.props.agreement;

    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let {
      user1Disp = "",
      user2Disp = "",
      youAreUser1=false,
      youAreUser2=false,
      user1RequestedArbitration=false,
      user2RequestedArbitration=false
    } = blockchainDataObj;

    let otherUser = firstLetterUpperCase(youAreUser1 ? user2Disp : user1Disp);

    let contributionType = arbitrationFeeType;
    let contributionAmount = arbitrationFeeInput;
    
    let dialogTitle = "Arbitration";
    let hasAllowance = false;
    let waitingForApproveAllowance = this.props.dialogBlockchainArbitrate.waitingForApproveAllowance;
    let allowanceApprovalTransactionComplete = this.props.dialogBlockchainArbitrate.allowanceApprovalTransactionComplete;
    let waitingApproveAllowanceHash = this.props.dialogBlockchainArbitrate.waitingApproveAllowanceHash;
    let nowTimestampUnix = moment().tz('America/Los_Angeles').unix();
    let waitingTimestampUnix = this.props.dialogBlockchainArbitrate.waitingTimestampUnix;

     // Don't wait for allowance approval if already detected
    if (hasAllowance || nowTimestampUnix > waitingTimestampUnix + 30) {
      waitingForApproveAllowance = false;
    }

    if (contributionType === "eth") {
      hasAllowance = true;
    }
    else {
      let amountStr = shared.multiplyDecimals(contributionAmount, shared.decimalsForToken(contributionType));
      let fundsObj = hasEnoughFunds(contractType, amountStr, contributionType);      
      hasAllowance = fundsObj.hasAllowance;
    }

    if (allowanceApprovalTransactionComplete) {
      hasAllowance = true;
    }

    let requestButtonTxt = "Deposit and request arbitration";
    let requestOrAgreeMessage = "requesting";
    if (contributionAmount === "0") {
      requestButtonTxt = "Request arbitration";
    }
    if ((youAreUser1 && user2RequestedArbitration) || (youAreUser2 && user1RequestedArbitration)) {
      requestButtonTxt = "Deposit and agree to arbitration";
      requestOrAgreeMessage = "agreeing to";
      if (contributionAmount === "0") {
        requestButtonTxt = "Agree to arbitration";
      }
    }

    let arbitrationRefundMessage = "The fee will be refunded if you win the dispute and forfeited if you lose. " + 
      "If you think it's unlikely that you'll win, consider changing your outcome report instead of " + requestOrAgreeMessage + " arbitration.";
    let theyRequestedArbitrationMessage = "deposited their arbitration fee";
    let youRequestedArbitrationMessage = "deposit";
    
    if (contributionAmount === "0") {
      arbitrationRefundMessage = "";
      theyRequestedArbitrationMessage = "requested arbitration";
      youRequestedArbitrationMessage = "request arbitration";
    }

    let transactionLinkElement = waitingApproveAllowanceHash === "" ? "transaction" : <Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), waitingApproveAllowanceHash)}>{"transaction"}</Link>;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          {waitingForApproveAllowance ? (
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>{"Giving permission to transfer " + contributionType.toUpperCase() + " from your wallet. The "}{transactionLinkElement}{" should take about fifteen seconds."}</div>
                <div><img style={{position:"relative", left:"-8px"}} className="loadIcon" src={loadImg} alt="Loading" /></div>
              </div>
            ) : (
            <div>
              <div>
                <span>Arbitration fee is {contributionAmount} {contributionType.toUpperCase()}. {arbitrationRefundMessage}</span>
              </div>
              <div className="moveTop20"><span style={{fontWeight:"bold"}}>{otherUser}</span>{(youAreUser1 && user2RequestedArbitration) || (youAreUser2 && user1RequestedArbitration) ? <span> already {theyRequestedArbitrationMessage}. Arbitration will be finalized when you {youRequestedArbitrationMessage}.</span> : <span> has not yet {theyRequestedArbitrationMessage}.</span>}</div>
              {!hasAllowance && <div className="moveTop20">{"Before you can deposit you must give permission to the Ethereum contract to transfer " + contributionType.toUpperCase() + " from your wallet."}</div>}
              {waitingApproveAllowanceHash !== "" && !hasAllowance && <div className="moveTop20">Your previous {transactionLinkElement} hasn't been confirmed yet. Either wait longer or try giving permission again with a higher fee.</div>}
              <div className="moveTop20">
                {(youAreUser1 || youAreUser2) && !hasAllowance && <ButtonAction name="allowance" className="moveRight20" text={"Give permission"} onClick={this.handleApproveAllowanceForArbitratorFee}/>}
                {(youAreUser1 || youAreUser2) && hasAllowance && <ButtonAction name="request" className="moveRight20" text={requestButtonTxt} onClick={this.request}/>}
              </div>
            </div>
          )}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogBlockchainArbitrate: state.dialogBlockchainArbitrate
  }
}

const DialogBlockchainArbitrateContainer = connect(mapStateToProps, null)(DialogBlockchainArbitrate);
export default DialogBlockchainArbitrateContainer;