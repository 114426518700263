//import update from 'immutability-helper';

const emptyState = {
  data: {}
}

const HowReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "HOW_INIT": {
      let newData = JSON.parse(JSON.stringify(action.payload));
      return {...state, data: newData};
    }
    default: {
      return state
    }
  }
}

export default HowReducer