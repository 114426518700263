import { connect } from 'react-redux';
import React, { Component } from 'react'
import './TwoColumnContainer.css';

/*
 * Create component.
 */

class TwoColumnContainer extends Component {

  constructor(props, context) {
    super(props);
    this.read = this.read.bind(this);
  }

  read(field, j) {
    let elem = this.props[field];
    if (Array.isArray(elem)) {
      return elem[j];
    }
    else {
      return elem;
    }
  }

  render() {

    let columns = this.props.auth.columns;
    let numRows = this.props.numRows || 1;
    let i = 0;

    let showBorder = false;
    if (this.props.showBorder && this.props.showBorder === true) {
      showBorder = true;
    }

    let tableClass = showBorder ? "tableCntBorder" : "tableCnt";

    if (this.props.singleColumnHasMax === "1" && columns === 1) {
      tableClass += " tableSingleColumn";
    }

    let outerClassName = "";
    if (this.props.className) {
      outerClassName = this.props.className;
    }

    let elemList1 = [];
    let elemList2 = [];
    let elemList3 = [];
    let elemList4 = [];

    for (let j = 0; j < numRows; j++) {

      if (this.props.fullComp) {
        elemList1.push(
          <tr key={i++}>
            <td className="tdFull">
              {this.read("fullComp", j)}
            </td>
          </tr>
        );
      }
      else if (this.props.middleComp) {
        if (columns === 3) {
          elemList1.push(
            <tr key={i++}>
              <td className="tdLeftThree">
                {this.read("leftComp", j)}
              </td>
              <td className="tdMiddleThree">
                {this.read("middleComp", j)}
              </td>
              <td className="tdRightThree">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
        else if (columns === 2) {
          elemList1.push(
            <tr key={i++}>
              <td className="tdLeft">
                {this.read("leftComp", j)}
              </td>
              <td className="tdRight">
                {this.read("middleComp", j)}
              </td>
            </tr>
          );

          elemList2.push(
            <tr key={i++}>
              <td className="tdLeft">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
        else {
          elemList1.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("leftComp", j)}
              </td>
            </tr>
          );

          elemList2.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("middleComp", j)}
              </td>
            </tr>
          );

          elemList3.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
      }
      else if (this.props.middleLeftComp) {
        if (columns === 3) {
          elemList1.push(
            <tr key={i++}>
              <td className="tdMiddleLeftThree">
                {this.read("middleLeftComp", j)}
              </td>
              <td className="tdRightThree">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
        else {
          elemList1.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("middleLeftComp", j)}
              </td>
            </tr>
          );

          elemList2.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
      }
      else if (this.props.fourCol1Comp || this.props.fourCol2Comp || this.props.fourCol3Comp || this.props.fourCol4Comp) {
        if (columns === 3) {
          elemList1.push(
            <tr key={i++}>
              <td className="tdFourCol1">
                {this.read("fourCol1Comp", j)}
              </td>
              <td className="tdFourCol2">
                {this.read("fourCol2Comp", j)}
              </td>
              <td className="tdFourCol3">
                {this.read("fourCol3Comp", j)}
              </td>
              <td className="tdFourCol4">
                {this.read("fourCol4Comp", j)}
              </td>
            </tr>
          );
        }
        else {
          elemList1.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("fourCol1Comp", j)}
              </td>
            </tr>
          );

          elemList2.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("fourCol2Comp", j)}
              </td>
            </tr>
          );

          elemList3.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("fourCol3Comp", j)}
              </td>
            </tr>
          );

          elemList4.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("fourCol4Comp", j)}
              </td>
            </tr>
          );
        }
      }
      else {
        if (columns === 2 || columns === 3) {
          elemList1.push(
            <tr key={i++}>
              <td className="tdLeft">
                {this.read("leftComp", j)}
              </td>
              <td className="tdRight">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
        else {
          elemList1.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("leftComp", j)}
              </td>
            </tr>
          );

          elemList2.push(
            <tr key={i++}>
              <td className="tdFull">
                {this.read("rightComp", j)}
              </td>
            </tr>
          );
        }
      }
    }

    return (
      <div className={outerClassName}>
        <table className={tableClass}>
          <tbody>
            {elemList1}
            {elemList2}
            {elemList3}
            {elemList4}
          </tbody>
        </table>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const TwoColumnContainerWrap = connect(mapStateToProps, null)(TwoColumnContainer);
export default TwoColumnContainerWrap;