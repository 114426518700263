//import update from 'immutability-helper';

const emptyState = {
  showEnterURL: false,
  photoFile: null,
  photoURL: "",
  thumbnailContent: "",
  imageContent: "",
  color: "",
  hasPhotoToUpload: false
}

const DialogMessageReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGADDPHOTO_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGADDPHOTO_SAVE_IMAGE" : {
      let { thumbnail={}, image={} } = action.payload;

      let thumbnailContent = "";
      let imageContent = "";
      let color = "";
      if (thumbnail.hasOwnProperty("value")) {
        thumbnailContent = thumbnail.value;
      }
      if (thumbnail.hasOwnProperty("color")) {
        color = thumbnail.color;
      }
      if (image.hasOwnProperty("value")) {
        imageContent = image.value;
      }

      return {...state, thumbnailContent, imageContent, color, hasPhotoToUpload: true };
    }
    case "OPEN_OVERLAY": {
      let { dialog } = action.payload; //params
      if (dialog === "add_photo") {
        return {...state, ...emptyState };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogMessageReducer