import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogVisibility.css';
import { changeInputValue } from './DialogVisibilityActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import ButtonSelectSubtext from './ButtonSelectSubtext';
import QuestionTooltip from './QuestionTooltip';
import { closeOverlay, tallOverlay } from './OverlayActions';
import { submitChangeVisibility } from './DialogVisibilityActions';
import { getVisibilityTooltipContent, getTextWidth } from './ActionUtils';
import { DEFAULT_BUTTON_WIDTH, BUTTON_TOGGLE_EXTRA_PIXELS, BUTTON_TOGGLE_FONT } from './Constants.js';
import { shared } from '../Shared';

class DialogVisibility extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }

    let maxWidth = 0;
    let visibilityTypeList = ["public", "discreet", "private"];
    for (let i = 0; i < visibilityTypeList.length; i++) {
      let visibilityType = visibilityTypeList[i];
      let curWidth = Math.ceil(getTextWidth(shared.visibilityText(visibilityType), BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
      if (curWidth > maxWidth) {
        maxWidth = curWidth;
      }
    }
    this.props.dispatch(changeInputValue("maxVisibilityButtonWidth", maxWidth));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }

  handleCancel(name, value) {
    this.props.dispatch(closeOverlay());
  }

  handleSubmit(name, value) {
    let {
      agreementid="",
      selectedVisibility=""
    } = this.props.dialogVisibility;

    this.props.dispatch(submitChangeVisibility(agreementid, selectedVisibility));
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleToggle(showMessage) {
  }
  
  render() {
    
    let {
      user1Disp="",
      user2Disp="",
      title="",
      agreementVisibility="",
      user1VisibilityRequest="",
      user2VisibilityRequest="",
      status=""
    } = this.props.agreement;

    let {
      selectedVisibility=""
    } = this.props.dialogVisibility;

    let allowEdit = (status !== "finalized");

    let truncatedTitle = title;
    if (truncatedTitle.length > 50) {
      truncatedTitle = truncatedTitle.substring(0, 50) + "...";
    }

    let visibilityTypeList = ["public", "discreet", "private"];
    let visibilityMsg = shared.visibilityText(agreementVisibility);
    let selectButtonElem = [];
    for (let i = 0; i < visibilityTypeList.length; i++) {
      let visibilityType = visibilityTypeList[i];

      let statusList = [];
      if (agreementVisibility !== "" && agreementVisibility === visibilityType) {
        statusList.push("Current visibility");
      }

      if (user1VisibilityRequest !== "" && user1VisibilityRequest === visibilityType) {
        statusList.push("Requested by " + user1Disp);
      }

      if (user2VisibilityRequest !== "" && user2VisibilityRequest === visibilityType) {
        statusList.push("Requested by " + user2Disp);
      }

      selectButtonElem.push(
        <ButtonSelectSubtext key={i} statusList={statusList} text={shared.visibilityText(visibilityType)} minWidth={this.props.dialogVisibility.maxVisibilityButtonWidth} name="selectedVisibility" value={visibilityType} selected={selectedVisibility} onChange={this.changeInputValue}/>
      );
    }

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title="Visibility of agreement" />
          <div>
            Agreement titled <span style={{fontWeight:"bold"}}>{truncatedTitle}</span> {allowEdit ? "currently" : ""} has <span style={{fontWeight:"bold"}}>{visibilityMsg}</span> <span style={{whiteSpace: "nowrap"}}>visibility.
            <QuestionTooltip alt="Learn more" message={
              getVisibilityTooltipContent()
            } screenWidth={this.props.auth.screenDimensions.width} textAligned={true} leftVersion={true} onToggle={this.handleToggle} />
            </span>
          </div>
          {allowEdit ? (
            <div>
              <div className="moveTop20">Change visibility:</div>
              <div className="moveTop5">{selectButtonElem}</div>
              <div className="moveTop20">
                <ButtonAction name="cancel" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" color="black" text="Cancel" onClick={this.handleCancel}/>
                <ButtonAction disabled={!allowEdit} name="submit" minWidth={DEFAULT_BUTTON_WIDTH} className="" text="Submit" onClick={allowEdit ? this.handleSubmit : null}/>
              </div>
            </div>
          ) : (
            <div className="moveTop20">
              <div className="moveTop20">You can't change an agreement's visibility after funds have been deposited.</div>
              <ButtonAction name="ok" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20 moveTop20" text="OK" onClick={this.handleCancel}/>
            </div>
          )}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogVisibility: state.dialogVisibility
  }
}

const DialogVisibilityContainer = connect(mapStateToProps, null)(DialogVisibility);
export default DialogVisibilityContainer;