import React, { Component } from 'react';
import GapCard from './GapCard';
import './PaginationCard.css';

class PaginationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let onNext = this.props.hasOwnProperty("onNext") ? this.props.onNext : null;
    let onPrev = this.props.hasOwnProperty("onPrev") ? this.props.onPrev : null;

    let offset = this.props.hasOwnProperty("offset") ? this.props.offset : 0;
    let offsetNum = parseInt(offset, 10);
    let count = this.props.hasOwnProperty("count") ? this.props.count : 50;
    let countNum = parseInt(count, 10);
    let shown = this.props.hasOwnProperty("shown") ? this.props.shown : 0;
    let shownNum = parseInt(shown, 10);

    let firstNum = offsetNum + 1;
    let lastNum = offsetNum + Math.min(countNum, shownNum);
    if (lastNum === 0) { 
      firstNum = 0; 
    }

    let hidePagination = false;
    if (offsetNum === 0 && shownNum < countNum) {
      hidePagination = true;
    }

    if (hidePagination) {
      return <div></div>;
    }

    let showTopGap = false;
    if (this.props.hasOwnProperty("showTopGap") && this.props.showTopGap) {
      showTopGap = true;
    }

    return (
      <div>
        {showTopGap && <GapCard />}
        <div className="paginationCard">
          <table style={{tableLayout:"fixed", width:"100%"}}><tbody>
            <tr>
              <td>
                <div>
                  {offsetNum === 0 ? "" : (
                    <span className="bluelink" onClick={onPrev}>&lt; Prev</span>
                  )}
                </div>
              </td>
              <td style={{textAlign:"center"}}>{firstNum} to {lastNum}</td>
              <td style={{textAlign:"right"}}>
                <div>
                  {shownNum < countNum ? "" : (
                    <span className="bluelink" onClick={onNext}>Next &gt;</span>
                  )}
                </div>
              </td>
            </tr>
          </tbody></table>
        </div>
        <GapCard />
      </div>
    );
  }
}
  
export default PaginationCard;