import React, { Component } from 'react';
import './WrongNetworkCard.css';
import TitleCard from "./TitleCard";

class WrongNetworkCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <TitleCard title="Change your network" />
        <div className="permissionDeniedCard">
          <div>This agreement titled "<span style={{fontWeight:"bold"}}>{this.props.title}</span>" uses the {this.props.networkName}.</div>
          <div className="moveTop20">To view this agreement please switch your network to the {this.props.networkName}.</div>
        </div>
      </div>
    );
  }
}
  
export default WrongNetworkCard;