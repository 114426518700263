import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getIsMobile } from "./ActionUtils";
import './ProfileHeader.css';
import atstakeImg from '../atstake.png';
import Link from './Link';
import ButtonAction from './ButtonAction';
import { openOverlay } from './OverlayActions';
import { 
  NEW_CONTRACT_LABEL,
  MESSAGE_LABEL
} from './Constants.js';
import defaultProfileImg from '../profile.svg';
import store from '../store';
import { refreshProfileData, favoriteSelect } from './ProfileHeaderActions';
import { navNewURL } from "./ActionUtils";
import FavoriteToggle from './FavoriteToggle';

class ProfileHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getSelectedUser = this.getSelectedUser.bind(this);
    this.changeDisplayname = this.changeDisplayname.bind(this);
    this.handleNewContract = this.handleNewContract.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleFavoriteClick = this.handleFavoriteClick.bind(this);
  }

  getSelectedUser(props) {
    let {
      queryParamUser=""
    } = props.profileHeader;

    let {
      username=""
    } = props.auth;

    let selectedUser = "";
    if (queryParamUser === "") {
      selectedUser = username;
    }
    else {
      selectedUser = queryParamUser;
    }

    return { queryParamUser, username, selectedUser };
  }

  componentDidMount() {
    let userObj = this.getSelectedUser(this.props);
    
    if (userObj.selectedUser !== "") {
      this.props.dispatch(refreshProfileData(userObj.selectedUser));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let userObj = this.getSelectedUser(this.props);
    let prevUserObj = this.getSelectedUser(prevProps);

    if (
      (
        userObj.selectedUser !== prevUserObj.selectedUser || 
        userObj.username !== prevUserObj.username || 
        userObj.queryParamUser !== prevUserObj.queryParamUser
      ) && 
      userObj.selectedUser !== ""
    ) {
      this.props.dispatch(refreshProfileData(userObj.selectedUser));
    }
  }

  changeDisplayname(event) {
    let {
      profileFile="",
      displayname="",
      summary=""
    } = this.props.profileHeader;

    let photoContent = "";
    if (profileFile !== "") {
      photoContent = "https://atstake.s3.amazonaws.com/" + profileFile;
    }

    store.dispatch(openOverlay("change_displayname", {displayname, summary, photoContent}));
  }

  handleNewContract(name, value) {
    let {
      selectedUser="",
    } = this.props.profileHeader;
    let loggedin = this.props.auth.status === "loggedin";

    if (loggedin) {
      this.props.dispatch(openOverlay("new_contract", {user: selectedUser}));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }

  handleMessage(name, value) {
    let {
      selectedUser="",
    } = this.props.profileHeader;
    let loggedin = this.props.auth.status === "loggedin";
    
    if (loggedin) {
      this.props.dispatch(navNewURL('/messages?user=' + selectedUser));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }

  handleFavoriteClick(contact, selected) {
    this.props.dispatch(favoriteSelect(contact, selected));
  }

  render() {

    let mobile = getIsMobile();
    let profileImgSize = 160;
    let profileImgTop = 0;
    let profileOnLeft = true;
    if (mobile) {
      profileImgSize = 160;
      profileOnLeft = false;
    }
    
    let { 
      username="" 
    } = this.props.auth;

    let {
      selectedUser="",
      selectedTab="",
      profileFile="",
      displayname="",
      userExists="",
      //dataLoaded=false
    } = this.props.profileHeader;

    let photoSrc = "";
    if (profileFile === "") {
      photoSrc = defaultProfileImg;
    }
    else {
      photoSrc = "https://atstake.s3.amazonaws.com/" + profileFile;
    }

    let loggedin = this.props.auth.status === "loggedin";

    let { 
      favoriteSelected=false,
      favoritePending=false
    } = this.props.profileHeader;

    let ownAccount = (username === selectedUser);

    return (
      <div className="">
        <div className="homeTopBanner moveTop20">
          <div className="profileInner">
            <div className="">
              <div className="favoriteCard">{loggedin && !ownAccount && userExists && <FavoriteToggle selected={favoriteSelected} pending={favoritePending} contact={selectedUser} onClick={this.handleFavoriteClick} />}</div>
            </div>
            <table className="profileCard" style={{textAlign:"left"}}>
              <tbody>
                <tr>
                  {profileOnLeft && <td style={{width: (profileImgSize + "px"), paddingTop: (profileImgTop + "px")}} className="profileImgCell"><img className="profileImg" src={photoSrc} alt="Profile" title="Profile" /></td>}
                  <td className="profileCntCell">
                    {profileOnLeft ? <div className="profileTopGap"></div> : <div className="profileContainerImgInline"><img style={{width: profileImgSize + "px", height: profileImgSize + "px"}} className="profileImgInline" src={photoSrc} alt="Profile" title="Profile" /></div>}
                    {userExists && <div className="profileUsername"><img className="atstakeIconLarge" src={atstakeImg} title="Atstake account" alt="Atstake account" />{selectedUser}</div>}
                    {userExists && <div className="profileName">{displayname}</div>}
                    {(!userExists) && <div className="profileName">&nbsp;</div>}        
                    {userExists && ownAccount && <div className="profileActionBtnGroup"><span onClick={this.changeDisplayname} className="profileActionBtn bluelink">Edit name and photo</span></div>}
                    {userExists && !ownAccount && 
                      <div className="profileActionBtnGroup">
                        <ButtonAction link={true} name="new_contract" className="profileActionBtn moveRight20" text={NEW_CONTRACT_LABEL} onClick={this.handleNewContract}/>
                        <ButtonAction link={true} name="message" className="profileActionBtn moveRight20" text={MESSAGE_LABEL} onClick={this.handleMessage}/>
                      </div>
                    }
                    {(!userExists) && <div className="profileActionBtnGroup"><div className="profileActionBtn">&nbsp;</div></div>}
                  </td>
                </tr>
              </tbody>
            </table>
            {/*<div style={{width: (profileImgSize + "px"), paddingTop: (profileImgTop + "px"), overflow: "hidden", borderRadius: "100px"}}><img className="profileImg" src={photoSrc} alt="Profile" title="Profile" /></div>*/}
          </div>
        </div>

        <div className="seperatorCardOuter moveTop40">
          <div className="seperatorCardInner">
            <div className="profileTabs">
              <Link href={"/profile" + (ownAccount ? "" : "?user=" + selectedUser)} noRefresh={true} useSpan={true} noTarget={true} className={selectedTab === "profile" ? "navLinkSelected" : "navLink"} >About</Link>
              <Link href={"/userpostings" + (ownAccount ? "" : "?user=" + selectedUser)} noRefresh={true} useSpan={true} noTarget={true} className={selectedTab === "userpostings" ? "navLinkSelected" : "navLink"} >Postings</Link>
              <Link href={"/contracts" + (ownAccount ? "" : "?user=" + selectedUser)} noRefresh={true} useSpan={true} noTarget={true} className={selectedTab === "contracts" ? "navLinkSelected" : "navLink"} >Contracts</Link>
              <Link href={"/ratings" + (ownAccount ? "" : "?user=" + selectedUser)} noRefresh={true} useSpan={true} noTarget={true} className={selectedTab === "ratings" ? "navLinkSelected" : "navLink"} >Ratings</Link>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
  
const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    profileHeader: state.profileHeader
  }
}

const ProfileHeaderContainer = connect(mapStateToProps, null)(ProfileHeader);
export default ProfileHeaderContainer;