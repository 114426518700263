import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import AuthReducer from './components/AuthReducer'
import MenuReducer from './components/MenuReducer'
import UserAccountReducer from './components/UserAccountReducer'
import PeopleReducer from './components/PeopleReducer'
import AgreementReducer from './components/AgreementReducer'
import DashboardReducer from './components/DashboardReducer'
import HomePageReducer from './components/HomePageReducer'
import OverlayReducer from './components/OverlayReducer';
import FAQReducer from './components/FAQReducer';
import ExamplesReducer from './components/ExamplesReducer';
import AboutReducer from './components/AboutReducer';
import HelpReducer from './components/HelpReducer';
import HowReducer from './components/HowReducer';
import EthereumReducer from './components/EthereumReducer';
import StatsReducer from './components/StatsReducer';
import RawContractsReducer from './components/RawContractsReducer';
import FaucetReducer from './components/FaucetReducer';
import FundingReducer from './components/FundingReducer';
import DialogAddAttestationReducer from './components/DialogAddAttestationReducer';
import DialogViewAttestationReducer from './components/DialogViewAttestationReducer';
import DialogFundingReducer from './components/DialogFundingReducer';
import DialogLoginReducer from './components/DialogLoginReducer';
import DialogConfirmReducer from './components/DialogConfirmReducer';
import DialogChangeDisplaynameReducer from './components/DialogChangeDisplaynameReducer';
import DialogMessageReducer from './components/DialogMessageReducer';
import DialogContractFromPostingReducer from './components/DialogContractFromPostingReducer';
import DialogNewPostingReducer from './components/DialogNewPostingReducer';
import DialogNewContractReducer from './components/DialogNewContractReducer';
import DialogAddPhotoReducer from './components/DialogAddPhotoReducer';
import DialogPhotoPreviewReducer from './components/DialogPhotoPreviewReducer';
import DialogAddressReducer from './components/DialogAddressReducer';
import DialogVersionsReducer from './components/DialogVersionsReducer';
import VerifyReducer from './components/VerifyReducer';
import IncentivesReducer from './components/IncentivesReducer';
import AttributionsReducer from './components/AttributionsReducer';
import TOSReducer from './components/TOSReducer';
import ProfileHeaderReducer from './components/ProfileHeaderReducer';
import NotFoundPageReducer from './components/NotFoundPageReducer';
import PleaseLoginReducer from './components/PleaseLoginReducer';
import RatingsReducer from './components/RatingsReducer';
import MessagesReducer from './components/MessagesReducer';
import MarketsReducer from './components/MarketsReducer';
import PostingReducer from './components/PostingReducer';
import PostingsPageReducer from './components/PostingsPageReducer';
import BlockchainReducer from './components/BlockchainReducer';
import DialogReportReducer from './components/DialogReportReducer';
import DialogReviewReducer from './components/DialogReviewReducer';
import DialogBlockchainResolveReducer from './components/DialogBlockchainResolveReducer';
import DialogBlockchainDepositReducer from './components/DialogBlockchainDepositReducer';
import DialogBlockchainArbitrateReducer from './components/DialogBlockchainArbitrateReducer';
import DialogAccessCodeReducer from './components/DialogAccessCodeReducer';
import ErrorReducer from './components/ErrorReducer';
import DialogTestModeReducer from './components/DialogTestModeReducer';
import DialogVisibilityReducer from './components/DialogVisibilityReducer';
import DialogDepositWarningReducer from './components/DialogDepositWarningReducer';

const reducer = combineReducers({
  auth: AuthReducer,
  menu: MenuReducer,
  user: UserAccountReducer,
  people: PeopleReducer,
  agreement: AgreementReducer,
  dashboard: DashboardReducer,
  homePage: HomePageReducer,
  faq: FAQReducer,
  examples: ExamplesReducer,
  about: AboutReducer,
  help: HelpReducer,
  how: HowReducer,
  ethereum: EthereumReducer,
  stats: StatsReducer,
  rawContracts: RawContractsReducer,
  faucet: FaucetReducer,
  balances: FundingReducer,
  ratings: RatingsReducer,
  messages: MessagesReducer,
  markets: MarketsReducer,
  posting: PostingReducer,
  postingsPage: PostingsPageReducer,
  overlay: OverlayReducer,
  blockchain: BlockchainReducer,
  dialogAddAttestation: DialogAddAttestationReducer,
  dialogViewAttestation: DialogViewAttestationReducer,
  dialogFunding: DialogFundingReducer,
  dialogLogin: DialogLoginReducer,
  dialogConfirm: DialogConfirmReducer,
  dialogDepositWarning: DialogDepositWarningReducer,
  dialogChangeDisplayname: DialogChangeDisplaynameReducer,
  dialogMessage: DialogMessageReducer,
  dialogContractFromPosting: DialogContractFromPostingReducer,
  dialogNewPosting: DialogNewPostingReducer,
  dialogNewContract: DialogNewContractReducer,
  dialogAddPhoto: DialogAddPhotoReducer,
  dialogPhotoPreview: DialogPhotoPreviewReducer,
  dialogTestMode: DialogTestModeReducer,
  dialogAddress: DialogAddressReducer,
  dialogVersions: DialogVersionsReducer,
  dialogReport: DialogReportReducer,
  dialogReview: DialogReviewReducer,
  dialogBlockchainResolve: DialogBlockchainResolveReducer,
  dialogBlockchainDeposit: DialogBlockchainDepositReducer,
  dialogBlockchainArbitrate: DialogBlockchainArbitrateReducer,
  dialogAccessCode: DialogAccessCodeReducer,
  dialogVisibility: DialogVisibilityReducer,
  verify: VerifyReducer,
  incentives: IncentivesReducer,
  attributions: AttributionsReducer,
  tos: TOSReducer,
  profileHeader: ProfileHeaderReducer,
  notFound: NotFoundPageReducer,
  pleaseLogin: PleaseLoginReducer,
  error: ErrorReducer,
  routing: routerReducer
})

export default reducer
