import React, { Component } from 'react'
import { connect } from 'react-redux';
import { refreshFaucetData, requestFaucetCoins, deactivateFaucetButton } from './FaucetActions';
import { datetimeDisplayString } from './ActionUtils'; //shortenAddress
import ButtonAction from './ButtonAction';
import './Common.css';
import Link from './Link';
import { openOverlay } from './OverlayActions';

class Faucet extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.submitFaucetRequest = this.submitFaucetRequest.bind(this);
    this.doNothing = this.doNothing.bind(this);
    this.showLogin = this.showLogin.bind(this);
  }
  
  componentDidMount() {
    if (this.props.auth.username !== "") {
      this.props.dispatch(refreshFaucetData(this.props.auth.username));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // This code apparently does absolutely nothing.. the old faucet and new faucet are always the same 
    let oldFaucet = prevProps.faucet;
    let newFaucet = this.props.faucet;
//    console.log("old faucet next: " + oldFaucet.faucet_next_allowed_use + ", new faucet next: " + newFaucet.faucet_next_allowed_use);
//    console.log("old faucet status: " + oldFaucet.status + ", new faucet status: " + newFaucet.status);
    if (oldFaucet.faucet_next_allowed_use !== newFaucet.faucet_next_allowed_use) {
      this.props.dispatch(refreshFaucetData(this.props.auth.username));
    }
  }

  submitFaucetRequest(address) {
    this.props.dispatch(deactivateFaucetButton());
    this.props.dispatch(requestFaucetCoins(address));
  }

  doNothing(event) {
    // Do nothing. This is here so that we can disable the faucet button
  }

  showLogin(event) {
    this.props.dispatch(openOverlay("login", {}));
  }
  
  render() {
    let faucetData = this.props.faucet;
    let loggedin =  this.props.auth.status === "loggedin";

    return (
      <div>
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageTitle">Faucet</div>
            <div className="pageLightText moveTop40">
              Our faucet allows you to get fake Ether and fake tokens so that you can get a feel for 
              how Atstake works without risking anything of real value. 
            </div>

            <div className="pageLightText moveTop20">
              This fake Ether exists on the <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://www.rinkeby.io">Rinkeby Test Network</Link>. 
              To use it you'll need an Ethereum wallet which supports Rinkeby. 
              On mobile we recommend <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://wallet.coinbase.com">Coinbase Wallet</Link>.
              On desktop we recommend&nbsp;
              <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://metamask.io">Metamask</Link>.
            </div>

            <div className="pageLightText moveTop20">
              {faucetData.faucet_last_used > 0 &&
                <div> 
                <div className="moveTop20">We already sent coins to you on {datetimeDisplayString(faucetData.faucet_last_used)} (at address {faucetData.faucet_address}).
                To use these coins you must configure your wallet to use the Rinkeby Test Network.</div>

                <div className="moveTop20">If you need more test coins you can get them from the <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://faucet.rinkeby.io/">Rinkeby faucet</Link>.</div>
                </div>
              }
              {faucetData.faucet_last_used === 0 &&
                <div>
                  <div className="moveTop20"> 
                  The coins will take about one minute to arrive at your current address: {this.props.auth.ethAddress}. 
                  </div>
                  <div className="moveTop20"><ButtonAction name={this.props.auth.ethAddress} className={faucetData.deactivate ? "btnActionGray" : ""} text="Send me coins" onClick={faucetData.deactivate ? this.doNothing : this.submitFaucetRequest}/></div>
                  {faucetData.status === "success" && <div className="moveTop20">Your coins are on the way! To use these coins you must configure your wallet to use the Rinkeby Test Network.</div>}
                  {faucetData.status === "error" && <div className="moveTop20">Error sending coins</div>}
                </div>
              }
              {!loggedin && <div className="moveTop20">Please <span onClick={this.showLogin} className="bluelink">log in</span> to use the Faucet.</div>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    faucet: state.faucet
  }
}

const FaucetContainer = connect(mapStateToProps, null)(Faucet);
export default FaucetContainer;