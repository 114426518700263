import { track } from './ActionUtils';

export function openMenuList() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_MENU_LIST'
    });

    dispatch(track("action", "button", JSON.stringify({type:"open_menu"})));
  }
}

export function closeMenuList() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_MENU_LIST'
    });
  }
}

export function selectNavDropdown(action) {
  return function(dispatch) {
    dispatch({
      type: 'MENU_NAV_DROPDOWN',
      payload: action
    });
  }
}