import React, { Component } from 'react';
import './StatusCard.css';

class StatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let list = this.props.list;
    let selected = this.props.selected;
    let spanArr = [];
    for (let i = 0; i < list.length; i++) {
      let num = i + 1;
      let entry = list[i];
      if (i !== 0) {
        spanArr.push(<span key={i + "_arrow"} className="statusStepArrow">&nbsp;&rarr;&nbsp;</span>);
      }
      spanArr.push(<span key={i + "_entry"} className={selected === num ? "statusStepSelected" : "statusStep"}>{entry}</span>);
    }

    return (
      <div className="statusCard">
        {spanArr}
      </div>
    );
  }
}
  
export default StatusCard;