import { apiGetAgreementList } from "./ActionUtils";
import store from '../store';

export function getRatingObj(entry, selectedUser, checkRatingBy) {
  let { title="" } = entry;
  let { agreementid="", user1="", user2="", arbitrator="", created_unix=0 } = entry.agreement;

  let check_ratings_key = "ratings_by_" + checkRatingBy;
  let ratingBy = "";
  if (entry.agreement.hasOwnProperty(checkRatingBy) && entry.agreement[checkRatingBy] !== "") {
    ratingBy = entry.agreement[checkRatingBy];
  }

  if (
    ratingBy !== "" && 
    entry.agreement.hasOwnProperty(check_ratings_key) && 
    entry.agreement[check_ratings_key] !== "{}"
  ) {
    let ratingsCheckObj = JSON.parse(entry.agreement[check_ratings_key]);
    if (ratingsCheckObj.hasOwnProperty(selectedUser) && ratingsCheckObj[selectedUser] !== 0) {
      let ratingScore = ratingsCheckObj[selectedUser];
      return {
        agreementid,
        title,
        ratingBy, 
        ratingScore, 
        isArbitrator: (selectedUser === arbitrator),
        isParticipant: (selectedUser === user1 || selectedUser === user2),
        created_unix
      };
    }
  }

  return null;
}

export function refreshRatings(selectedUser, selectedFilter) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;
    let filter = selectedFilter === "" ? "ratings_all" : selectedFilter;
    let maxVisibility = "private";

    apiGetAgreementList(uuid, username, selectedUser, filter, 0, 50, maxVisibility).then((list) => {
      let ratingsList = [];
      if (list) {
        for (let i = 0; i < list.length; i++) {
          let entry = list[i];

          let ratingUser1Obj = getRatingObj(entry, selectedUser, "user1");
          if (ratingUser1Obj !== null) {
            ratingsList.push(ratingUser1Obj);
          }

          let ratingUser2Obj = getRatingObj(entry, selectedUser, "user2");
          if (ratingUser2Obj !== null) {
            ratingsList.push(ratingUser2Obj);
          }

          let ratingArbitratorObj = getRatingObj(entry, selectedUser, "arbitrator");
          if (ratingArbitratorObj !== null) {
            ratingsList.push(ratingArbitratorObj);
          }
        }
      }

      dispatch({
        type: 'RATINGS_LIST',
        payload: ratingsList
      });
    });
  }
}