//import update from 'immutability-helper';

const emptyState = {
  type: null, 
  token: null,
  amount: "",
  toAddressInput: "",
  showCopiedMsg: false,
  withdrawStatus: "",
  transactionHash: ""
}

const DialogFundingReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGFUNDING_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      
      return {...state, [name]: value};
    }
    case "DIALOGFUNDING_WITHDRAW_STATUS" : {
      let { status="", hash="" } = action.payload;
      if (status === "success") {
        return {...state, withdrawStatus: status, transactionHash: hash, amount: "", toAddressInput: ""};
      }

      return {...state, withdrawStatus: status, transactionHash: hash};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      let { type, token } = params;
      
      if (dialog === "balances") {
        return {...state, ...emptyState, type: type, token: token, amount: "", toAddressInput: "", showCopiedMsg: false };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogFundingReducer