//import update from 'immutability-helper';

const emptyState = {
  guid: "",
  email: "",
  status: "pending"
}

const VerifyReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;
      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "verify") {
        let { guid="" } = query;
        return {...state, 
          guid: guid,
          email: "",
          status: "pending"
        };
      }

      // Empty everything if page not selected
      return {};
    }
    case "VERIFY_STATUS" : {
      let { status="", guid="", email="" } = action.payload;
      return {...state, status: status, guid: guid, email: email  };
    }
    default: {
      return state
    }
  }
}

export default VerifyReducer