import { apiUpdateProfileSummary, apiUpdateProfileEmail, getLinkedAccountList, getUserStats, apiUpdateArbitrationSettings } from "./ActionUtils";
//import { refreshProfileData } from './ProfileHeaderActions';
import store from '../store';

export function updateProfileSummary(summary) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch(changeInputValue("btnSummaryStatus", { text:"", error: true }));
    apiUpdateProfileSummary(auth.uuid, auth.username, summary).then((success) => {
      if (success) {
        dispatch(changeInputValue("btnSummaryStatus", { text:"", error: false }));
      }
      else {
        dispatch(changeInputValue("btnSummaryStatus", { text:"Error updating", error: true }));
      }
      dispatch(changeInputValue("disableUpdateButtons", false));
    });
  }
}

export function updateProfileEmail(email_settings) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch(changeInputValue("btnEmailStatus", { text:"", error: true }));
    apiUpdateProfileEmail(auth.uuid, auth.username, email_settings).then((success) => {
      if (success) {
        dispatch(changeInputValue("btnEmailStatus", { text:"", error: false }));
      }
      else {
        dispatch(changeInputValue("btnEmailStatus", { text:"Error updating", error: true }));
      }
      dispatch(changeInputValue("disableUpdateButtons", false));
    });
  }
}

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'USER_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function changeArbitrationInput(name, value, mode) {
  return function(dispatch) {
    dispatch({
      type: 'USER_CHANGE_ARBITRATION_INPUT',
      payload: {name, value, mode}
    });
  }
}

export function updateArbitrationSettings(fee_value, fee_type, auto_status, description, mode) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch(changeInputValue("btnArbitratorStatus", { text:"", error: true }));
    apiUpdateArbitrationSettings(auth.uuid, auth.username, fee_value, fee_type, auto_status, description, mode).then((success) => {
      if (success) {
        //let { selectedUser="" } = store.getState().profileHeader;
        //dispatch(refreshProfileData(selectedUser)); // Why did we ever feel the need to do this here?
        dispatch(changeInputValue("btnArbitratorStatus", { text:"", error: false }));
      }
      else {
        dispatch(changeInputValue("btnArbitratorStatus", { text:"Error updating", error: true }));
      }
      dispatch(changeInputValue("disableUpdateButtons", false));
    });
  }
}

export function refreshUserStats(user) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getUserStats(auth.uuid, auth.username, user).then((response) => {
      if (response.status === "success") {
        let stats = response.stats;
        dispatch({
          type: 'USER_STATS',
          payload: {stats}
        });
      }
    });
  }
}

export function refreshLinkedAccountListForUser(user) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getLinkedAccountList(auth.uuid, auth.username, user).then((list) => {
      dispatch({
        type: 'USER_LINKED_ACCOUNT_LIST',
        payload: {user,list}
      });
    });
  }
}
