import { connect } from 'react-redux';
import React, { Component } from 'react';
import './Ratings.css';
import './Common.css';
import GapCard from "./GapCard";
import Dropdown from './Dropdown';
import { track, navNewURL } from './ActionUtils';
import { refreshRatings } from './RatingsActions';
import { refreshProfileData } from './ProfileHeaderActions';
import moment from 'moment-timezone';
import LoadCard from './LoadCard';
import NoResultsCard from './NoResultsCard';
import ProfileHeader from './ProfileHeader';
import { getIsProfileOwnAccount } from './ActionUtils';
import ReviewCard from './ReviewCard';
import NotFoundUser from './NotFoundUser';

class Ratings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.navigateAgreement = this.navigateAgreement.bind(this);
    this.navigateUser = this.navigateUser.bind(this);
  }

  componentDidMount() {
    let { selectedUser="", selectedFilter="" } = this.props.profileHeader;
    if (selectedUser !== "") {
      this.props.dispatch(refreshRatings(selectedUser, selectedFilter));
    }
  }

  componentDidUpdate(prevProps, prevState) { 
    let needUserDataRefresh = this.props.ratings.needUserDataRefresh;
    let selectedUser = this.props.profileHeader.selectedUser;

    if (
      (selectedUser !== prevProps.profileHeader.selectedUser ||
      this.props.ratings.ratingsLoaded !== prevProps.ratings.ratingsLoaded) &&
      this.props.ratings.ratingsLoaded === false
    ) {
      let { selectedUser="", selectedFilter="" } = this.props.profileHeader;
      if (selectedUser !== "") {
        this.props.dispatch(refreshRatings(selectedUser, selectedFilter));
      }
    }

    if (
      selectedUser !== "" &&
      (needUserDataRefresh !== prevProps.ratings.needUserDataRefresh) && 
      needUserDataRefresh      
    ) {
      this.props.dispatch(refreshProfileData(selectedUser));
    }
  }

  handleFilterChange(name, value) {
    let ownAccount = getIsProfileOwnAccount();
    let { selectedUser="" } = this.props.profileHeader;
    if (name === "selectedFilter") {
      if (ownAccount) {
        if (value === "") {
          this.props.dispatch(navNewURL('/ratings'));
        }
        else {
          this.props.dispatch(navNewURL('/ratings?filter=' + value));
        }
      }
      else {
        if (value === "") {
          this.props.dispatch(navNewURL('/ratings?user=' + selectedUser));
        }
        else {
          this.props.dispatch(navNewURL('/ratings?user=' + selectedUser + '&filter=' + value));
        }
      }
    }
  }

  navigateAgreement(agreementid) {
    this.props.dispatch(track("action", "button", JSON.stringify({name: "ratings_agreement", agreementid})));
    this.props.dispatch(navNewURL('/view?agreementid=' + agreementid));
  }

  navigateUser(user) {
    this.props.dispatch(track("action", "button", JSON.stringify({name: "ratings_user", user})));
    this.props.dispatch(navNewURL('/profile?user=' + user));
  }

  render() {    
    let {
      selectedUser="",
      queryParamUser="",
      selectedFilter="",
      userExists=false,
      dataLoaded: headerDataLoaded = false
    } = this.props.profileHeader;

    let loggedout = this.props.auth.status === "loggedout";

    let { ratingsList=[], ratingsLoaded=false } = this.props.ratings;

    if ((headerDataLoaded && !userExists) || (loggedout && queryParamUser === "")) {
      return (
        <div>
            <ProfileHeader />
            <NotFoundUser selectedUser={selectedUser} />
        </div>
      );
    }
    else if (!headerDataLoaded || !ratingsLoaded) {
      return (
        <div>
            <ProfileHeader />
            <GapCard />
            <LoadCard key="load" />
        </div>
      );
    }

    let filterDropdownList = [];
    filterDropdownList.push({
      name: "", 
      text: "Ratings of " + selectedUser
    });
    filterDropdownList.push({
      name: "ratings_participant", 
      text: "Ratings of " + selectedUser + " as party"
    });
    filterDropdownList.push({
      name: "ratings_arbitrator", 
      text: "Ratings of " + selectedUser + " as arbitrator"   
    });

    let ratingsElemList = [];
    if (ratingsLoaded) {
      for (let i = 0; i < ratingsList.length; i++) {
        let ratingObj = ratingsList[i];
        let isPositive = ratingObj.ratingScore.score > 0;
        let ratingsReviewText = ratingObj.ratingScore.text || "";
        let displayDate = moment.tz(ratingObj.created_unix, "X", 'America/Los_Angeles').format('MMM D, YYYY');
        let displayRole = ratingObj.isArbitrator ? "arbitrator" : "party";

        ratingsElemList.push(<GapCard size="small" key={"gap_" + i}/>);
        ratingsElemList.push(
          <ReviewCard 
            key={"card_" + i} 
            isPositive={isPositive}
            ratingBy={ratingObj.ratingBy}
            title={ratingObj.title}
            agreementid={ratingObj.agreementid}
            displayRole={displayRole}
            displayDate={displayDate}
            ratingsReviewText={ratingsReviewText}
            onUserClick={this.navigateUser} 
            onAgreementClick={this.navigateAgreement} 
          />
        );
      }

      if (ratingsElemList.length === 0) {
        ratingsElemList.push(<GapCard size="small" key={"gap_noresults"}/>);
        ratingsElemList.push(<NoResultsCard key="noresults" />);
      }
    }
    else {
      ratingsElemList.push(<GapCard size="small" key={"gap_load"}/>);
      ratingsElemList.push(<LoadCard key="load" />);
    }

    return (
      <div className="contentSection">
        <ProfileHeader />
        <GapCard />
        <div className="titleDropdown">
          <Dropdown name="selectedFilter" selected={selectedFilter} list={filterDropdownList} onChange={this.handleFilterChange} />
        </div>
        {ratingsElemList}
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    ratings: state.ratings,
    profileHeader: state.profileHeader,
    overlay: state.overlay
  }
}

const RatingsContainer = connect(mapStateToProps, null)(Ratings);
export default RatingsContainer;