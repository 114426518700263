//import update from 'immutability-helper';

const emptyState = {
  selectedVisibility: "",
  agreementid: "",
  maxVisibilityButtonWidth: 0
}

const DialogVisibilityReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGVISIBILITY_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "visibility") {
        let { selectedVisibility="", agreementid="" } = params;
        return {...state, ...emptyState, selectedVisibility, agreementid };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogVisibilityReducer