
// The size of our standard buttons. Intended to fit short labels like 'Update' and 'Add' in a standard size.
// If your label will be longer you'll need your own custom logic. Try to keep buttons which appear next to 
// each other the same size.
export const DEFAULT_BUTTON_WIDTH = "84px";

// These constants need to be kept in sync with the values for .btnToggle
export const BUTTON_TOGGLE_EXTRA_PIXELS = 33; // 15 for left/right padding, 3 extra just in case.
export const BUTTON_TOGGLE_FONT = "16px Arial";

// These constants need to be kept in sync with the values for .btnAction
export const BUTTON_ACTION_EXTRA_PIXELS = 33; // 15 for left/right padding, 3 extra just in case.
export const BUTTON_ACTION_FONT = "16px Arial";

// These constants need to be kept in sync with the values for .btn
export const BUTTON_EXTRA_PIXELS = 33; // 15 for left/right padding, 3 extra just in case.
export const BUTTON_FONT = "16px Arial";

// We'd normally define these labels in the component that uses them, but multiple components use these buttons
export const CONDITIONAL_PAYMENT_LABEL = "Conditional payment";
export const GENERAL_CONTRACT_LABEL = "General contract";
export const SEND_PAYMENT_LABEL = "Send";
export const REQUEST_PAYMENT_LABEL = "Request";

// Labels for new contract and message
export const NEW_CONTRACT_LABEL = "New contract";
export const MESSAGE_LABEL = "Message";

export const EXPERIMENT_HOMEPAGE = 0;
export const EXPERIMENT_COMPLEX = [
  {1: true, 2: false},
  {1: false, 2: true},
  {1: true, 2: true}
];
export const EXPERIMENT_DASHBOARD = 3;
export const EXPERIMENT_COUNT = 4;

export const HOMEPAGE_SECTIONS = [
  { user: "", bread_location: "global", bread_type: "", bread_category: "", search: "", postingids: "101,119,121,118,169,108,106,116,113,103,130,117,109", next_key: "" },
  { user: "", bread_location: "", bread_type: "", bread_category: "", search: "", postingids: "", next_key: "" },
  //{ user: "", bread_location: "global", bread_type: "service_offered", bread_category: "", search: "", postingids: "", next_key: "" },
  { user: "", bread_location: "global", bread_type: "item_offered", bread_category: "domains", search: "", postingids: "", next_key: "" },
  //{ user: "", bread_location: "seattle", bread_type: "rental_offered", bread_category: "vehicle", search: "", postingids: "", next_key: "" }
];
