import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Overlay.css';
import './Common.css';
import { openOverlay, closeOverlay } from './OverlayActions'
import DialogAddAttestation from './DialogAddAttestation';
import DialogViewAttestation from './DialogViewAttestation';
import DialogFunding from './DialogFunding';
import DialogLogin from './DialogLogin';
import DialogConfirm from './DialogConfirm';
import DialogDepositWarning from './DialogDepositWarning';
import DialogChangeDisplayname from './DialogChangeDisplayname';
import DialogMessage from './DialogMessage';
import DialogContractFromPosting from './DialogContractFromPosting';
import DialogNewPosting from './DialogNewPosting';
import DialogNewContract from './DialogNewContract';
import DialogAddPhoto from './DialogAddPhoto';
import DialogPhotoPreview from './DialogPhotoPreview';
import DialogDebug from './DialogDebug';
import DialogTestMode from './DialogTestMode';
import DialogAddress from './DialogAddress';
import DialogVersions from './DialogVersions';
import DialogBlockchainResolve from './DialogBlockchainResolve';
import DialogBlockchainDeposit from './DialogBlockchainDeposit';
import DialogBlockchainArbitrate from './DialogBlockchainArbitrate';
import DialogReport from './DialogReport';
import DialogReview from './DialogReview';
import DialogAccessCode from './DialogAccessCode';
import DialogVisibility from './DialogVisibility';
import xImg from '../xGrey.png';
import loadImg from '../load_blue.gif';
import { shared } from '../Shared';

class Overlay extends Component {

  constructor(props, context) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.closeOverlay = this.closeOverlay.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.showDebugDialog = this.showDebugDialog.bind(this);
    this.node = null;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  closeOverlay(event) {
    this.props.dispatch(closeOverlay({event}));
  }

  handleOutsideClick(event) {
    if(!this.node || this.node.contains(event.target)) {
        return;
    }

    this.closeOverlay(event);
  }

  showDebugDialog() {
    this.props.dispatch({
      type: 'METAMASK_OPENED',
      payload: false
    });

    this.props.dispatch(openOverlay("debug", {}));
  }
  
  render() {
    let showOverlay = this.props.overlay.open;
    let metamaskOpened = this.props.auth.metamaskOpened;
    let dialog = this.props.overlay.dialog;
    let tall = this.props.overlay.tall;

    if (metamaskOpened) {
      return (
        <div>
          <div className="fadeOverlay"></div>
          <div className="confirmTransactionMsg">
            <div>Please confirm or reject transaction</div>
            <div className="confirmTransactionLoad"><img className="loadIcon" src={loadImg} alt="Loading" /></div>
            <div style={{textAlign: "center", marginTop: "80px"}}><span style={{fontSize: "12px", color: "#444", cursor: "pointer"}} onClick={this.showDebugDialog}>Debug info</span></div>
          </div>
        </div>
      );
    }
    else if (!showOverlay) {
      return <div className="hide"></div>;
    }
    else {
      return (
          <div>
            <div className={"fadeOverlay" + (showOverlay ? "" : " hide")}></div>
            <div className={(tall ? "contentOverlayTall" : "contentOverlay") + (showOverlay ? "" : " hide")}>
              <div ref={node => this.node = node} className={"overlayDialog" + (dialog === "photo_preview" ? " overlayPreview" : "")}>
                {dialog !== "photo_preview" && <img onClick={this.closeOverlay} title="Close" alt="Close" src={xImg} className="xImg"></img>}
                {shared.DIALOG_LIST.includes(dialog) && (
                  <div>
                    {dialog === "add_attestation" && <DialogAddAttestation />}
                    {dialog === "view_attestation" && <DialogViewAttestation />}
                    {dialog === "balances" && <DialogFunding />}
                    {dialog === "login" && <DialogLogin />}
                    {dialog === "confirm" && <DialogConfirm />}
                    {dialog === "change_displayname" && <DialogChangeDisplayname />}
                    {dialog === "message" && <DialogMessage />}
                    {dialog === "contract_from_posting" && <DialogContractFromPosting />}
                    {dialog === "new_posting" && <DialogNewPosting />}
                    {dialog === "new_contract" && <DialogNewContract />}
                    {dialog === "add_photo" && <DialogAddPhoto />}
                    {dialog === "photo_preview" && <DialogPhotoPreview />}
                    {dialog === "debug" && <DialogDebug />}
                    {dialog === "testmode" && <DialogTestMode/>}
                    {dialog === "address" && <DialogAddress />}
                    {dialog === "versions" && <DialogVersions />}
                    {dialog === "report" && <DialogReport />}
                    {dialog === "review" && <DialogReview />}
                    {dialog === "blockchain_resolve" && <DialogBlockchainResolve />}
                    {dialog === "blockchain_deposit" && <DialogBlockchainDeposit />}
                    {dialog === "blockchain_arbitrate" && <DialogBlockchainArbitrate />}
                    {dialog === "access_code" && <DialogAccessCode />}
                    {dialog === "visibility" && <DialogVisibility />}
                    {dialog === "deposit_warning" && <DialogDepositWarning />}
                  </div>
                )}
              </div>
            </div>
          </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    menu: state.menu,
    auth: state.auth,
    overlay: state.overlay
  }
}

const OverlayContainer = connect(mapStateToProps, null)(Overlay);
export default OverlayContainer;