import React, { Component } from 'react';
import './ArbitratorSettingsCard.css';
import Dropdown from './Dropdown';
import { getDropdownTokenList } from './TokenUtils';
import InputTextarea from './InputTextarea';
import ButtonAction from './ButtonAction';
import ButtonSelect from './ButtonSelect';
import InputBasic from './InputBasic';
import { addCommasToNumber, getPriceMessageWidthCutoff, getTextWidth } from './ActionUtils';
import { DEFAULT_BUTTON_WIDTH, BUTTON_TOGGLE_EXTRA_PIXELS, BUTTON_TOGGLE_FONT } from './Constants.js';
import { shared } from '../Shared';

const ALWAYS_BUTTON_LABEL = "Always";
const MANUAL_BUTTON_LABEL = "Manual review";
const NEVER_BUTTON_LABEL = "Never";

class ArbitratorSettingsCard extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.state = { maxSettingsButtonWidth: 0};
  }

  handleChange(name, value) {
    let mode = this.props.mode;
    if (this.props.onChange) { this.props.onChange(name, value, mode); }
  }

  handleUpdate(name, value) {
    let mode = this.props.mode;
    if (this.props.onUpdate) { this.props.onUpdate(mode); }
  }

  componentDidMount() {
    let alwaysWidth = Math.ceil(getTextWidth(ALWAYS_BUTTON_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let manualWidth = Math.ceil(getTextWidth(MANUAL_BUTTON_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let neverWidth = Math.ceil(getTextWidth(NEVER_BUTTON_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;

    this.setState({
      maxSettingsButtonWidth: Math.max(alwaysWidth, manualWidth, neverWidth)
    });
  }

  render() {

    let {
      fee_value="",
      fee_type="",
      auto_status="",
      description=""
    } = this.props.arbitrationData;

    let mode = this.props.mode;
    let yourAccount = this.props.ownaccount;
    let selectedUserElem = <span style={{fontWeight:"bold"}}>{this.props.selectedUser}</span>;
    let feeDropdownList = getDropdownTokenList();
    let arbitrationFeeWithCommas = addCommasToNumber(fee_value);
    let priceMessage = getPriceMessageWidthCutoff(fee_value, fee_type, arbitrationFeeWithCommas);
    let disableUpdate = this.props.disableUpdate;

    return (
      <div className="contentCard">
        {yourAccount &&
          <div>
            <div className="contentLabel">Will you arbitrate other people's {mode === "test" ? "test mode " : ""}contracts?</div>
            <div className="contactSelectDiv">
              <ButtonSelect minWidth={this.state.maxSettingsButtonWidth} className="moveRight20 moveTop10" text={ALWAYS_BUTTON_LABEL} name="auto_status" value="agreed" selected={auto_status} onChange={this.handleChange}/>
              <ButtonSelect minWidth={this.state.maxSettingsButtonWidth} className="moveRight20 moveTop10" text={MANUAL_BUTTON_LABEL} name="auto_status" value="pending" selected={auto_status} onChange={this.handleChange}/>
              <ButtonSelect minWidth={this.state.maxSettingsButtonWidth} className="moveTop10" text={NEVER_BUTTON_LABEL} name="auto_status" value="rejected" selected={auto_status} onChange={this.handleChange}/>
            </div>
            <div className="contentLabel moveTop20">{"What is your minimum " + (mode === "test" ? "test mode " : "") + "arbitration fee?"}</div>
            <div className="moveTop5">
              <InputBasic appendRight={priceMessage} restrict="float" placeholder="Amount" type="text" className="amountUserArbitrationFeeInput moveRight5" name="fee_value" value={arbitrationFeeWithCommas} onChange={this.handleChange} />
              <Dropdown size="small" name="fee_type" selected={fee_type} list={feeDropdownList} onChange={this.handleChange} />
            </div>
            <div>
              <div className="contentLabel moveTop20">Description of {mode === "test" ? "test mode " : ""}arbitration services</div>
              <div className="moveTop5">
                <InputTextarea maxlength={shared.MAX_ARBITRATION_DESCRIPTION_LENGTH} placeholder={"Enter any conditions about what kind of contracts you're willing to arbitrate, details about your fees, and anything else you’d like people considering your arbitration services to know."} className="textAreaFullWidth" name="description" value={description} onChange={this.handleChange} />
              </div>
            </div>
            <div>
              <ButtonAction disabled={disableUpdate} enableMessageLine={true} message={this.props.updateStatus} name="update" className="moveTop20" text="Update" minWidth={DEFAULT_BUTTON_WIDTH} onClick={disableUpdate ? null : this.handleUpdate}/>
            </div>
          </div>
        }
        {
          !yourAccount && <div>
            {auto_status === "rejected" && <div className="contentLabel">{selectedUserElem} is not offering arbitration services.</div>}
            {auto_status !== "rejected" && 
              <div>
                <div className="contentLabel">
                  {auto_status === "pending" && <div className="moveTop5">{selectedUserElem} must explicitly agree to arbitrate each {mode === "test" ? "test mode contract" : "contract"} before it can begin.</div>}
                  {auto_status === "agreed" && <div className="moveTop5">{selectedUserElem} will arbitrate any {mode === "test" ? "test mode contract" : "contract"} unless stated otherwise in their description below. These contracts can begin without any action from {selectedUserElem}.</div>}
                </div>
                <div className="contentLabel moveTop20"><div className="strongText">Minimum fee</div>
                  <div className="moveTop5">{arbitrationFeeWithCommas} {shared.displayForToken(fee_type)}</div>
                </div>
                { description !== "" && 
                  <div className="contentLabel moveTop20">
                    <div className="strongText">Description of arbitration services</div>
                    <div className="moveTop5">{description}</div>
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    );
  }
}
  
export default ArbitratorSettingsCard;