import React, { Component } from 'react'
import { connect } from 'react-redux';
import { navNewURL, track } from './ActionUtils'
import './People.css';
import './Common.css';
import { 
  changeAutosuggestInput, 
  clearAutosuggestResults, 
  changeAutosuggestResults, 
  refreshContacts,
  refreshArbitratorList,
  handleAutosuggestFavorite
} from './PeopleActions';
import TitleCard from './TitleCard';
import GapCard from './GapCard';
import LoadCard from './LoadCard';
import NoResultsCard from './NoResultsCard';
//import AccountCard from './AccountCard';
import ArbitratorList from './ArbitratorList';
import InputAutosuggest from './InputAutosuggest';
import PaginationCard from './PaginationCard';
import { pageNotLoaded, genURL } from './ActionUtils';
import Link from './Link';

class People extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.autosuggestChangeInput = this.autosuggestChangeInput.bind(this);
    this.autosuggestChangeResults = this.autosuggestChangeResults.bind(this);
    this.autosuggestChangeSelection = this.autosuggestChangeSelection.bind(this);
    this.autosuggestClearResults = this.autosuggestClearResults.bind(this);
    this.handleAccountCardClick = this.handleAccountCardClick.bind(this);
    this.autosuggestFavoriteClick = this.autosuggestFavoriteClick.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
  }
  
  componentDidMount() {
    this.props.dispatch(refreshContacts());
    this.props.dispatch(refreshArbitratorList());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.people.contactsLoaded !== prevProps.people.contactsLoaded && 
      this.props.people.contactsLoaded === false
    ) {
      this.props.dispatch(refreshContacts());
    }

    if (
      this.props.people.arbitratorsLoaded !== prevProps.people.arbitratorsLoaded && 
      this.props.people.arbitratorsLoaded === false
    ) {
      this.props.dispatch(refreshArbitratorList());
    }
  }

  autosuggestClearResults(name, input, nonce) {
    this.props.dispatch(clearAutosuggestResults(name, input, nonce));
  }

  autosuggestChangeInput(name, value, search) {
    this.props.dispatch(changeAutosuggestInput(name, value, search));
  }

  autosuggestChangeResults(name, value, search, max, more, selected, nonce) {
    this.props.dispatch(changeAutosuggestResults(name, value, search, max, more, selected, nonce));
  }

  autosuggestChangeSelection(name, type, value, arbitration) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"autosuggest_select", name, selection: type + "#" + value})));
    this.props.dispatch(navNewURL('/profile?user=' + value));
  }

  handleAccountCardClick(value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"top_arbitrator_card", value})));
    this.props.dispatch(navNewURL('/profile?user=' + value));
  }

  autosuggestFavoriteClick(name, contact, favorite) {
    this.props.dispatch(handleAutosuggestFavorite(name, contact, favorite));
  }

  prev(name, value) {
    let offsetNum = parseInt(this.props.people.offset, 10);
    let countNum = parseInt(this.props.people.count, 10);
    let newOffset = offsetNum > countNum ? offsetNum - countNum : 0;
    this.props.dispatch(navNewURL("/people?offset=" + newOffset + "&count=" + countNum));
  }

  next(name, value) {
    let offsetNum = parseInt(this.props.people.offset, 10);
    let countNum = parseInt(this.props.people.count, 10);
    let newOffset = offsetNum + countNum <= 1000 ? offsetNum + countNum : 1000;
    this.props.dispatch(navNewURL("/people?offset=" + newOffset + "&count=" + countNum));
  }

  render() {
    if (pageNotLoaded(this.props.people)) { return null };

    let loggedin = this.props.auth.status === "loggedin";

    let {
      offset=0,
      count=0,
      contactList=[],
      favoriteList=[],
      arbitratorList=[],
      contactsLoaded=false,
      arbitratorsLoaded=false
    } = this.props.people;
    
    let usersElem = [];
    let delim = "";
    for (let i = 0; i < contactList.length; i++) {
      let contact = contactList[i];
      usersElem.push(<span key={i}>{delim} <Link className="blacklink" noTarget={true} href={genURL("/profile?user=" + contact.username)}>{contact.username}</Link></span>);
      delim = ",";
    }

    let contactsCardElem = [];
    if (usersElem.length === 0) {
      if (contactsLoaded) {
        contactsCardElem.push(<NoResultsCard key="noresults" />);
      }
      else {
        contactsCardElem.push(<LoadCard key="load" />);
      }
    }
    else {
      contactsCardElem.push(
        <div key="content" className="contentCard">{usersElem}</div>
      );  
    }

    let favoriteListElem = [];
    delim = "";
    for (let i = 0; i < favoriteList.length; i++) {
      let favorite = favoriteList[i];
      favoriteListElem.push(<span key={i}>{delim} <Link className="blacklink" noTarget={true} href={genURL("/profile?user=" + favorite)}>{favorite}</Link></span>);
      delim = ",";
    }

    let favoritesCardElem = [];
    if (favoriteListElem.length === 0) {
      if (contactsLoaded) {
        favoritesCardElem.push(<NoResultsCard key="noresults" />);
      }
      else {
        favoritesCardElem.push(<LoadCard key="load" />);
      }
    }
    else {
      favoritesCardElem.push(
        <div key="content" className="contentCard">{favoriteListElem}</div>
      );  
    }

    let arbitratorsElem = [];
    if (arbitratorList.length === 0) {
      if (arbitratorsLoaded) {
        arbitratorsElem.push(<NoResultsCard key="noresults" />);
      }
      else {
        arbitratorsElem.push(<LoadCard key="load" />);
      }
    }
    else {
      arbitratorsElem.push(<ArbitratorList key="cnt_arbitrator_list" arbitratorList={arbitratorList} onClick={this.handleAccountCardClick} auth={this.props.auth} />)
      arbitratorsElem.push(<GapCard key="gap_arbitrator_list" />);
      arbitratorsElem.push(<PaginationCard key="pagination" offset={offset} count={count} shown={arbitratorList.length} onPrev={this.prev} onNext={this.next} />);
    }

    return (
      <div className="contentSection">
        <GapCard /> 
        <TitleCard title="Search people" />
        <div className="contentCard">
          <InputAutosuggest disabled={false} showPrice={false} className="" name="searchAutosuggest" value={this.props.people.searchAutosuggest} onChange={this.autosuggestChangeInput} onChangeAutosuggest={this.autosuggestChangeResults} onClearResults={this.autosuggestClearResults} onSelect={this.autosuggestChangeSelection} onFavoriteClick={this.autosuggestFavoriteClick} />
        </div>
        <GapCard />
        {loggedin && favoriteList.length > 0 && 
          <div>
            <TitleCard title="Starred contacts" />
            {favoritesCardElem}
            <GapCard />
          </div>
        }
        {loggedin && <div>
          <TitleCard title="Recent contacts" />
          {contactsCardElem}
        </div>}
        <GapCard />
        <div>
          <TitleCard title="Top arbitrators" />
          {arbitratorsElem}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    people: state.people
  }
}

const PeopleContainer = connect(mapStateToProps, null)(People);
export default PeopleContainer;