//import update from 'immutability-helper';

const emptyState = {
  type: "",
  value: "",
  mode: "",
  privacy: "",
  privacyNonce: 0,
  user1: "",
  verified: {},
  defaultEthereum: "",
  showVerifiedMessage: false,
  updateMsg: "",
  allowEdit: false,
  maxVisibilityButtonWidth: 0
}

const DialogViewAttestationReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGVIEWATTESTATION_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;

      if (name !== "updateMsg") {
        return {...state, [name]: value, updateMsg: ""};
      }
      
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      let { type="", value="", user1="", mode="", privacy="", defaultEthereum="", verified={}, ethereumAddressList=null, allowEdit=false } = params;
      if (dialog === "view_attestation") {
        return {...state, ...emptyState, type: type, value: value, mode: mode, privacy: privacy, privacyNonce: 0, verified: verified, user1: user1, showVerifiedMessage: false, defaultEthereum, ethereumAddressList, allowEdit };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogViewAttestationReducer