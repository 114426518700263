import React, { Component } from 'react';
import './Common.css';

class DialogTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let titleElem = this.props.title;
    if (titleElem === "") { 
      titleElem = <span>&nbsp;</span>;
    }
    
    let hideSeparator = this.props.hasOwnProperty("noseparator") && this.props.noseparator === true;

    return (
      <div>
        <div className="dialogTitle">{titleElem}</div>
        {!hideSeparator && <div className="dialogTitleBorder moveTop20"></div>}
        {!hideSeparator && <div className="moveTop20"></div>}
      </div>
    );
  }
}
  
export default DialogTitle;