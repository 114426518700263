//import update from 'immutability-helper';

import { 
  shared
} from '../Shared';

function getRemainder(tokenType, stakeAmount, resInput) {
  let dec = shared.decimalsForToken(tokenType);
  let resAmount = shared.multiplyDecimals(resInput, dec);
  if (resInput !== "" && shared.greaterThanEqualBN(stakeAmount, resAmount, "")) {
    let remainderAmount = shared.subBN(stakeAmount, resAmount, "");
    let remainderInput = shared.divideDecimals(remainderAmount, dec);
    return remainderInput;
  }

  return "";
}

const emptyState = {
  action: null, 
  onSubmit: null, 
  submitError: false, 
  resolutionInput: "",
  combinedStakeAmount: "",
  user1StakeAmount: "",
  user2StakeAmount: "",
  combinedTokenType: "",
  user1TokenType: "",
  user2TokenType: "",
  isSingleTokenType: false,

  customInputUser1Combined: "",
  customInputUser2Combined: "",
  customInputUser1Token1: "",
  customInputUser1Token2: "",
  customInputUser2Token1: "",
  customInputUser2Token2: "",

  customInputCombined: "",
  customInputToken1: "",
  customInputToken2: "",
  maxButtonWidth: 0
}

const DialogBlockchainResolveReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGBLOCKCHAINRESOLVE_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;

      let name2 = "";
      let value2 = "";
      if (name === "customInputUser1Combined") {
        name2 = "customInputUser2Combined";
        value2 = getRemainder(state.combinedTokenType, state.combinedStakeAmount, value);
      }
      else if (name === "customInputUser2Combined") {
        name2 = "customInputUser1Combined";
        value2 = getRemainder(state.combinedTokenType, state.combinedStakeAmount, value);
      }
      else if (name === "customInputUser1Token1") {
        name2 = "customInputUser2Token1";
        value2 = getRemainder(state.user1TokenType, state.user1StakeAmount, value);
      }
      else if (name === "customInputUser1Token2") {
        name2 = "customInputUser2Token2";
        value2 = getRemainder(state.user2TokenType, state.user2StakeAmount, value);
      }
      else if (name === "customInputUser2Token1") {
        name2 = "customInputUser1Token1";
        value2 = getRemainder(state.user1TokenType, state.user1StakeAmount, value);
      }
      else if (name === "customInputUser2Token2") {
        name2 = "customInputUser1Token2";
        value2 = getRemainder(state.user2TokenType, state.user2StakeAmount, value);
      }

      if (name2 !== "") {
        return {...state, [name]: value, [name2]: value2, submitError: false };
      }

      return {...state, [name]: value, submitError: false };
    }
    case "OPEN_OVERLAY": {
      let { dialog="", params={} } = action.payload;
      if (dialog === "blockchain_resolve") {
        let { 
          action=null, 
          onSubmit=null, 
          resolutionInput="",
          combinedStakeAmount="",
          user1StakeAmount="",
          user2StakeAmount="",
          combinedTokenType="",
          user1TokenType="",
          user2TokenType="",
          isSingleTokenType=false } = params;
        return {
          ...state, 
          ...emptyState,
          action, 
          onSubmit, 
          resolutionInput,  
          combinedStakeAmount,         
          user1StakeAmount,
          user2StakeAmount,
          combinedTokenType,
          user1TokenType,
          user2TokenType,
          isSingleTokenType
        };
      }

      // Empty everything if not selected
      return {};
    }
    case "DIALOGBLOCKCHAINRESOLVE_SUBMIT_ERROR": {
      let submitError = action.payload;
      return {...state, submitError: submitError };
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogBlockchainResolveReducer