//import update from 'immutability-helper';

const emptyState = {
  issueDetails: "",
  issueEmail: "",
  emailList: [],
  emailListLoaded: false,
  emailInputChanged: false,
  submitDisabled: false,
  isSubmitted: false
}

const DialogReportReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGREPORT_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      if (name === "issueEmail") {
        return {...state, [name]: value, emailInputChanged: true};
      } else {
        return {...state, [name]: value};
      }
    }
    case "DIALOGREPORT_EMAIL_LIST" : {
      let { list=[] } = action.payload;
      if (list.length > 0 && !state.emailInputChanged) {
        return {...state, emailList: list, issueEmail: list[0].account_name, emailListLoaded: true};
      }
      return {...state, emailList: list, emailListLoaded: true};
    }
    case "OPEN_OVERLAY": {
      let { dialog } = action.payload;
      if (dialog === "report") {
        return {...state, ...emptyState, issueDetails: "", issueEmail: "" };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogReportReducer