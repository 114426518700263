//import utils from 'web3-utils';
import moment from 'moment-timezone';
import React from 'react'
import ButtonAction from './ButtonAction';
import { datetimeDisplayString, getUserLinkElement, getTestModeFromStore, genURL, minutesBeforeNow } from './ActionUtils';
import './Common.css';
import './AgreementMessageUtils.css';
import reviewLikeImg from '../review_like_grey.png';
import reviewDislikeImg from '../review_dislike_grey.png';
import reviewLikeSelectedImg from '../review_like_blue.png';
import reviewDislikeSelectedImg from '../review_dislike_black.png';
import { 
  rateUser
} from './AgreementActions';
import { openOverlay, openOverlayDepositWarning, shouldShowDepositWarning, hasTimestampExpired } from './OverlayActions';
import { shared } from "../Shared";
import Link from './Link';

export function firstLetterUpperCase(str) {
  return shared.firstLetterUpperCase(str);
}

export function getShowChangeResolution(rawResolutionList) {
  for (let i = 0; i < rawResolutionList.length; i++) {
    let entry = rawResolutionList[i];
    let showResolveBtn = entry.showResolveBtn;
    let hasResolution = entry.res ? true : false;

    if (showResolveBtn && hasResolution) {
      return true;
    }
  }

  return false;
}

export function getResolutionStatusList(automaticResolutionDisplay, user1ResolutionDisplay, user2ResolutionDisplay, arbitratorResolutionDisplay, resolutionDisplay, user1Disp, user2Disp, arbitratorDisp, youAreUser1, youAreUser2, youAreArbitrator, arbitrationRequested, isResolved, countdownRequestAutomaticResolution) {
  let list = [];

  if (youAreUser1) {
    list.push({res: user1ResolutionDisplay, by: "Your outcome report", showResolveBtn: !isResolved});
    list.push({res: user2ResolutionDisplay, by: firstLetterUpperCase(user2Disp) + "'s outcome report", showResolveBtn: false});
    /*if (!countdownRequestAutomaticResolution) {
      list.push({res: automaticResolutionDisplay, by: "Automatic outcome", showResolveBtn: false});
    }*/
  }
  else if (youAreUser2) {
    list.push({res: user2ResolutionDisplay, by: "Your outcome report", showResolveBtn: !isResolved});
    list.push({res: user1ResolutionDisplay, by: firstLetterUpperCase(user1Disp) + "'s outcome report", showResolveBtn: false});
    /*if (!countdownRequestAutomaticResolution) {
      list.push({res: automaticResolutionDisplay, by: "Automatic outcome", showResolveBtn: false});
    }*/
  }
  else {
    list.push({res: user1ResolutionDisplay, by: firstLetterUpperCase(user1Disp) + "'s outcome report", showResolveBtn: false});
    list.push({res: user2ResolutionDisplay, by: firstLetterUpperCase(user2Disp) + "'s outcome report", showResolveBtn: false});
    /*if (!countdownRequestAutomaticResolution) {
      list.push({res: automaticResolutionDisplay, by: "Automatic outcome", showResolveBtn: false});
    }*/
  }

  if (arbitrationRequested) {
    let arbibratorResolutionLabel = "Arbitrator outcome report";
    if (youAreArbitrator) {
      arbibratorResolutionLabel = "Your outcome report";
    }
    list.push({res: arbitratorResolutionDisplay, by: arbibratorResolutionLabel, showResolveBtn: !isResolved && youAreArbitrator});
  }

  if (isResolved) {
    list.push({res: resolutionDisplay, by: "Final outcome", showResolveBtn: false});
  }

  return list;
}

export function displayResolutions(rawResolutionList, onResolveClick) {
  let keyNum = 0;
  let elemList = [];
  for (let i = 0; i < rawResolutionList.length; i++) {
    let entry = rawResolutionList[i];
    let resolutionDisplay = entry.res ? entry.res : "Waiting";
    let showResolveBtn = entry.showResolveBtn;
    let hasResolution = entry.res ? true : false;
    let entryName = entry.by;
    elemList.push(
      <div key={keyNum++} style={{verticalAlign:"top", display:"inline-block"}} className="moveTop20 moveRight20">
        <div style={{fontSize:"16px", paddingBottom:"5px"}}>{entryName}: </div>
        {showResolveBtn && !hasResolution && (
          <div style={{display:"inline-block", padding:"", background:""}}>
            <ButtonAction color={onResolveClick === null ? "disabled" : ""} className="" text="Report" onClick={onResolveClick}/>
          </div>
        )}
        {showResolveBtn && hasResolution && (
          <div>
            <div className="resolutionBlock">{resolutionDisplay}</div>
            <div><span className={onResolveClick === null ? "moveTop5 bluelink small disabled" : "moveTop5 bluelink small"} onClick={onResolveClick}>Change report</span></div>
          </div>
        )}
        {!showResolveBtn && (
          <div className="resolutionBlock">{resolutionDisplay}</div>
        )}
      </div>
    );
  }

  return (
    <div className="moveTop20" style={{width: "calc(100% - 20px)", padding:"0px 20px 20px 20px", background: "#f9f9f9", border:"1px solid #eee", borderRadius: "var(--input-radius)"}}>{elemList}</div>
  );
}

export function timeRemainingDisplayString(remaining_seconds) {

  if (remaining_seconds > 0) {
    let dur = moment.duration(remaining_seconds, "seconds");
    return dur.humanize();  
  }
  else {
    return "now";
  }
  
  //let hours = dur.hours();
  //let minutes = dur.minutes();
  //let seconds = dur.seconds();
}

export function ratingsControl(dispatch, rateUserList, ratingsByYou, agreementVisibility) {
  let ratingsElem = [];
  if (agreementVisibility !== "private") {
    for (let i = 0; i < rateUserList.length; i++) {
      let rateUserObj = rateUserList[i];
      let userKey = rateUserObj.userKey;
      let userLink = rateUserObj.userLink;
  
      let userRating = ratingsByYou.hasOwnProperty(userKey) && ratingsByYou[userKey].hasOwnProperty("score") ? ratingsByYou[userKey].score : 0;
      let userText = ratingsByYou.hasOwnProperty(userKey) && ratingsByYou[userKey].hasOwnProperty("text") ? ratingsByYou[userKey].text : "";

      ratingsElem.push(
        <div key={i} className="contentLabel moveTop5 moveLeft20">
        {userLink}  {
          userRating === 1 ? 
          <img onClick={() => dispatch(rateUser(userKey, userRating, userText, 1))} src={reviewLikeSelectedImg} className="reviewLikeIcon" style={{paddingLeft:"3px"}} alt="positive" title="positive" /> : 
          <img onClick={() => dispatch(rateUser(userKey, userRating, userText, 1))} src={reviewLikeImg} className="reviewLikeIcon" style={{paddingLeft:"3px"}} alt="positive" title="positive" />
        } {userRating === -1 ? 
          <img onClick={() => dispatch(rateUser(userKey, userRating, userText, -1))} src={reviewDislikeSelectedImg} className="reviewDislikeIcon" alt="negative" title="negative" /> : 
          <img onClick={() => dispatch(rateUser(userKey, userRating, userText, -1))} src={reviewDislikeImg} className="reviewDislikeIcon" alt="negative" title="negative" />
        }
        </div>
      );
    }
  }

  return ratingsElem;
}

export function getStatusMessageAndActions(mode, agreementObj, blockchainDataObj, onVersionClick, onResolveClick, dispatch) {
  if (!agreementObj) { agreementObj = {} };
  if (!blockchainDataObj) { blockchainDataObj = {} };

  let { 
    agreementid="",
    agreementLoaded=false,
    youAreUser1=false, 
    youAreUser2=false,
    youAreArbitrator=false,
    agreedArbitratorStatus="", 
    agreedArbitratorUsername="",
    user1Locked="", 
    user2Locked="",
    user1Disp="",
    user2Disp="",
    versionid="",
    versionNum="",
    versionUser1Proposed="",
    versionUser2Proposed="",
    versionUser1Num="",
    versionUser2Num="",
    user1ExplicitApproval=false,
    user2ExplicitApproval=false,
    user1AllowsImplicitApproval=false,
    user2AllowsImplicitApproval=false,
    status="",
    user2DisplayValue="",
    user1StakeDisplay="",
    user2StakeDisplay="",
    user1ZeroContribution=false,
    user2ZeroContribution=false,
    hasTransactionAction=false,
    transactionHash="",
    transactionByUsername="",
    transactionByYou=false,
    transactionByOtherParty=false,
    transactionObj=null,
    transactionLockUntilUnix=0,
    ratingsByYou={},
    completeTimestamp=0,
    autoResolveUnix=0,
    createdTimestamp=0,
    agreementVisibility="",
    user1VisibilityRequest="",
    user2VisibilityRequest="",
    user2Address=""
  } = agreementObj;

  let {
    blockchainSynced=false,
    databaseSynced=false,
    blockchainInit=false,
    hasDataIntegrity=false,
    dataIntegrityReason="",
    errorTrace="",
    user1StakePaid=false,
    user2StakePaid=false,
    allStakeFundsDeposited=false,
    isResolved=false,
    isCanceled=false,
    user1DidResolve=false,
    user2DidResolve=false,
    user1CanResolve=false,
    user2CanResolve=false,
    arbitratorDidResolve=false,
    arbitratorCanResolve=false,
    arbitratorCanWithdrawFee=false,
    disputeFeeDisplay="",
    user1CanRequestArbitration=false,
    user2CanRequestArbitration=false,
    user1RequestedArbitration=false,
    user2RequestedArbitration=false,
    user1Withdrew=false,
    user2Withdrew=false,
    user1CanWithdraw=false,
    user2CanWithdraw=false,
    user1CanEarlyWithdraw=false,
    user2CanEarlyWithdraw=false,
    user1DisplayWithdrawAmount="",
    user2DisplayWithdrawAmount="",
    resolutionNothingUser1=false,
    resolutionNothingUser2=false,
    user1HasFeeToWithdraw=false,
    user2HasFeeToWithdraw=false,
    canRequestAutomaticResolution=false,
    countdownRequestAutomaticResolution=false,
    timeToRequestAutomaticResolution=0,
    autoResolveAfterTimestamp=0,
    user1CanRequestDefaultJudgment=false,
    user2CanRequestDefaultJudgment=false,
    user1CountdownRequestDefaultJudgment=false,
    user2CountdownRequestDefaultJudgment=false,
    timeToRequestDefaultJudgment=0,
    nextArbitrationStepAllowedAfterTimestamp=0,
    user1CountdownRequestArbitration=false,
    user2CountdownRequestArbitration=false,
    timeToRequestArbitration=0,
    user1ResolutionDisplay=null,
    user2ResolutionDisplay=null,
    arbitratorResolutionDisplay=null,
    resolutionDisplay=null,
    automaticResolutionDisplay=null,
    usingWrongAddress=false,
    usingWrongNetwork=false,
    flagNoAddress=false,
    shouldUseAddress="",
    hasBalanceForDeposit = false,
    hasBalanceForArbitrationFee = false,
    hasETHForTransactionFee = false,
    arbitratorWithdrewDisputeFee = false
  } = blockchainDataObj;

  let hasBalanceForDepositAndTransactionFee = hasBalanceForDeposit && hasETHForTransactionFee;
  let hasBalanceForArbitrationFeeAndTransactionFee = hasBalanceForArbitrationFee && hasETHForTransactionFee;

  let depositInsufficentFundsMsg = "";
  if (!hasBalanceForDeposit) {
    depositInsufficentFundsMsg = " Insufficient funds to deposit.";
  } else if (!hasETHForTransactionFee) {
    depositInsufficentFundsMsg = " Insufficient ETH to pay transaction fee.";
  }
  let depositInsufficentArbitrationFee = "";
  if (!hasBalanceForArbitrationFee) {
    depositInsufficentArbitrationFee = <div className="moveTop20">Insufficient funds to pay arbitration fee.</div>;
  } else if (!hasETHForTransactionFee) {
    depositInsufficentArbitrationFee = <div className="moveTop20">Insufficient ETH to pay transaction fee.</div>;
  }
  
  // : <div className="moveTop20">Insufficient funds to pay arbitration fee.</div>;

  let user1Link = user1Disp;
  let user1LinkUC = firstLetterUpperCase(user1Disp);
  if (user1Locked) {
    user1Link = getUserLinkElement(user1Locked, user1Disp);
    user1LinkUC = getUserLinkElement(user1Locked, firstLetterUpperCase(user1Disp));
  }

  let user2Link = user2Disp;
  let user2LinkUC = firstLetterUpperCase(user2Disp);
  if (user2Locked) {
    user2Link = getUserLinkElement(user2Locked, user2Disp);
    user2LinkUC = getUserLinkElement(user2Locked, firstLetterUpperCase(user2Disp));
  }

  let arbitratorLink = getUserLinkElement(agreedArbitratorUsername, agreedArbitratorUsername);

  let arbitratorHasAgreed = (agreedArbitratorStatus === "agreed");
  let isUser1ProposedVersion = (versionid !== "" && versionid === versionUser1Proposed);
  let isUser2ProposedVersion = (versionid !== "" && versionid === versionUser2Proposed);

  let isUser1ProposedVersionExplicit = isUser1ProposedVersion && user1ExplicitApproval;
  let isUser2ProposedVersionExplicit = isUser2ProposedVersion && user2ExplicitApproval;
  let versionUser1ProposedExplicit = user1ExplicitApproval ? versionUser1Proposed : "";
  let versionUser2ProposedExplicit = user2ExplicitApproval ? versionUser2Proposed : "";
  let statusExplicit = status;
  if (status === "agreed" && (!user1ExplicitApproval || !user2ExplicitApproval)) {
    statusExplicit = "proposed";
  }

  let hasImplicitApproval = false;
  if ((youAreUser1 && user2AllowsImplicitApproval) || (youAreUser2 && user1AllowsImplicitApproval)) {
    hasImplicitApproval = true;
  }

  let statusActions = [];
  let toggleActions = [];
  let toggleActionSelected = "";
  let statusDesc = <div></div>;
  let statusNotice = "";
  let selectedStep = 1;
  /*
  When parties have approved different versions, actionRequired will be "you" for a party unless
  they have approved the latest version of that agreement. This puts the ball in the court of the person
  who has responded least recently.
  */
  let actionRequired = "";
  let arbitratorDisp = "arbitrator";
  let arbitrationRequested = user1RequestedArbitration && user2RequestedArbitration;  
  let resolutionStatusList = getResolutionStatusList(automaticResolutionDisplay, user1ResolutionDisplay, user2ResolutionDisplay, arbitratorResolutionDisplay, resolutionDisplay, user1Disp, user2Disp, arbitratorDisp, youAreUser1, youAreUser2, youAreArbitrator, arbitrationRequested, isResolved, countdownRequestAutomaticResolution);
  let showChangeResolution = getShowChangeResolution(resolutionStatusList);

  let warningType = "";
  if (youAreUser1 && youAreUser2) {
    warningType = "self_agreement";
  }
  else if (statusExplicit === "finalized" || statusExplicit === "agreed" || (statusExplicit === "proposed" && hasImplicitApproval)) {
    if (usingWrongAddress) {
      if (flagNoAddress) {
        warningType = "disconnected";
      }
      else {
        warningType = "wrong_address";
      }  
    }
    else if (usingWrongNetwork) {
      warningType = "wrong_network";
    }

    if (statusExplicit === "finalized" && !blockchainInit && flagNoAddress) {
      warningType = "disconnected";
    }
  }

  let hasWarning = (warningType !== "");

  if (hasWarning) {
    onResolveClick = null;
  }

  let allowImplicitApprovalMsg = "";
  if (hasImplicitApproval && user2Address !== "") { 
    allowImplicitApprovalMsg = <div className="moveTop20">If you know that the payment terms are acceptable to {youAreUser1 ? user2Link : user1Link} you can <span onClick={hasWarning ? null : (() => dispatch(openOverlayDepositWarning("blockchain_deposit", {action: "implicit_finalize"}, shouldShowDepositWarning(createdTimestamp, autoResolveUnix))))} className={hasWarning ? "disabledlink" : "bluelink"}>deposit now</span>.</div>;
  }

  let hasUser1VisibilityRequest = (user1VisibilityRequest !== "" && user1VisibilityRequest !== agreementVisibility);
  let hasUser2VisibilityRequest = (user2VisibilityRequest !== "" && user2VisibilityRequest !== agreementVisibility);
  let visibilityChangeMsg = "";
  let visibilityChangeNoticeForYou = "";
  if (statusExplicit !== "finalized") {
    if (youAreUser1) {
      if (hasUser1VisibilityRequest) {
        visibilityChangeMsg = <span>You have requested changing visibility to <span className="bluelink" onClick={() => dispatch(openOverlay("visibility", {agreementid, selectedVisibility: user1VisibilityRequest}))}>{shared.visibilityText(user1VisibilityRequest)}</span>. </span>;
      }
      if (hasUser2VisibilityRequest) {
        visibilityChangeMsg = <span>{visibilityChangeMsg}{user2LinkUC} has requested changing visibility to <span className="bluelink" onClick={() => dispatch(openOverlay("visibility", {agreementid, selectedVisibility: user2VisibilityRequest}))}>{shared.visibilityText(user2VisibilityRequest)}</span>. </span>;
        visibilityChangeNoticeForYou = firstLetterUpperCase(user2Disp) + " has requested changing visibility";
      }
    }
    else if (youAreUser2) {
      if (hasUser2VisibilityRequest) {
        visibilityChangeMsg = <span>You have requested changing visibility to <span className="bluelink" onClick={() => dispatch(openOverlay("visibility", {agreementid, selectedVisibility: user2VisibilityRequest}))}>{shared.visibilityText(user2VisibilityRequest)}</span>. </span>;
      }
      if (hasUser1VisibilityRequest) {
        visibilityChangeMsg = <span>{visibilityChangeMsg}{user1LinkUC} has requested changing visibility to <span className="bluelink" onClick={() => dispatch(openOverlay("visibility", {agreementid, selectedVisibility: user1VisibilityRequest}))}>{shared.visibilityText(user1VisibilityRequest)}</span>. </span>;
        visibilityChangeNoticeForYou = firstLetterUpperCase(user1Disp) + " has requested changing visibility";
      }
    }
  }

  let transactionHelpElem = <Link href={genURL("/faq#unconfirmed")} className="bluelink" noTarget={true} rel="noopener noreferrer">View help</Link>;

  // Compute warnings about in-flight transactions .. 
  let pendingTransactionWarning = "";
  if (mode === "view" && !isResolved && agreementLoaded) {
    let txList = [];
    if (youAreUser1) {
      txList = JSON.parse(agreementObj.transactionsUser1);
    }
    else if (youAreUser2) {
      txList = JSON.parse(agreementObj.transactionsUser2);
    }
    else if (youAreArbitrator) {
      txList = JSON.parse(agreementObj.transactionsArbitrator);
    }
    // find all pending transactions
    let pendingList = [];
    for (let i = 0; i < txList.length; i++ ){
      let tx = txList[i];
      if (tx.status === "pending") {
        pendingList.push(tx);
      }
    }
    if (pendingList.length > 1) {
      let pendingElemList = [];
      for (let i = 0; i < pendingList.length; i++) {
        pendingElemList.push(
          <span key={i}><Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), pendingList[i].hash)}>{(i+1)}</Link>{(i === pendingList.length-1 ? "" : ", ")}</span>
        );
      }
      pendingTransactionWarning = <div><span className="statusWarningMild">Warning</span>: You have multiple pending tranasctions ({pendingElemList}). You may want to wait, cancel them, or speed them up before submitting another. {transactionHelpElem}.</div>;
    }
    else if (pendingList.length === 1) {
      pendingTransactionWarning = <div><span className="statusWarningMild">Warning</span>: You have a pending <Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), pendingList[0].hash)}>{shared.getTransactionTypeDisplay(pendingList[0].type)} transaction</Link>. You may want to wait, cancel it, or speed it up before submitting another. {transactionHelpElem}.</div>;
    }
  }

  if (mode === "new") {
    selectedStep = 1;
    statusDesc = <div>Proposing a new contract.</div>
  }
  else if (mode === "edit") {
    if (statusExplicit === "finalized" && isResolved) {
      selectedStep = 4;
      statusDesc = <div>Editing not allowed.</div>
    }
    else if (statusExplicit === "finalized" && allStakeFundsDeposited) {
      selectedStep = 3;
      statusDesc = <div>Editing not allowed.</div>
    }
    else if (statusExplicit === "finalized") {
      selectedStep = 2;
      statusDesc = <div>Editing not allowed.</div>
    }
    else if (statusExplicit === "agreed") {
      statusDesc = <div>Editing not allowed.</div>
    }
    else if (statusExplicit === "proposed") {
      if (youAreUser1) {
        statusDesc = <div>Editing contract with {user2Link} based on version #{versionNum}.</div>
        statusActions.push("cancel_edit");
        statusActions.push("new_version");
      }
      else if (youAreUser2) {
        statusDesc = <div>Editing contract with {user1Link} based on version #{versionNum}.</div>
        statusActions.push("cancel_edit");
        statusActions.push("new_version");
      }
      else {
        statusDesc = <div>Editing not allowed.</div>
      }
    }
    else if (statusExplicit === "unlinked") {
      if (youAreUser1) {
        statusDesc = <div>Editing contract with {user2Link} based on version #{versionNum}.</div>
        statusActions.push("cancel_edit");
        statusActions.push("new_version");
      }
      else if (youAreUser2) {
        statusDesc = <div>Editing not allowed. Waiting on you to link your account.</div>        
      }
      else {
        statusDesc = <div>Editing not allowed.</div>
      }
    }
  }
  else if (mode === "view") {
    if (statusExplicit === "finalized" && isResolved) {
      selectedStep = 4;

      statusNotice = "Outcome finalized";
      actionRequired = "resolved";
      if (youAreUser1) {
        let rateUserList = [{userKey: user2Locked, userLink: user2Link}];
        if (arbitrationRequested) {
          rateUserList.push({userKey: agreedArbitratorUsername, userLink: arbitratorLink});
        }

        let resolver = "";
        // We can add more complex logic later to display more about how the resolution occurred.
        if (arbitratorDidResolve) {
          resolver = <span>{arbitratorLink} (the arbitrator)</span>;
        } 

        // Calculate info for distribution display
        let distributionDisplay = "";
        if (user1CanWithdraw) {
          distributionDisplay = "The following funds are waiting for your withdrawal: " + user1DisplayWithdrawAmount + ".";
        } else if (user1Withdrew) {
          if (!resolutionNothingUser1 || user1HasFeeToWithdraw){
            distributionDisplay = user1DisplayWithdrawAmount + " was distributed to you" + (user1HasFeeToWithdraw ? " (includes arbitration fee refund)." : ".");
          }
        }
        let counterpartyDistributionDisplay = "";
        if (user2Withdrew) {
          if (!resolutionNothingUser2 || user2HasFeeToWithdraw){
            counterpartyDistributionDisplay = <span>{user2DisplayWithdrawAmount} was distributed to {user2Link}{(user2HasFeeToWithdraw ? " (includes arbitration fee refund)." : ".")}</span>;
          } 
        }

        statusDesc = 
        <div>
          <div className="contentLabel">Contract's outcome was finalized{resolver !== "" ? <span> by {resolver}</span> : "" }{completeTimestamp > 0 ? " on " + datetimeDisplayString(completeTimestamp) : ""}.</div>
          <div className="contentLabel moveTop20">Final outcome: <span className="resolutionBlock">{resolutionDisplay}</span></div>
          {agreementVisibility !== "private" && <div className="contentLabel moveTop20">Leave {arbitrationRequested ? "ratings" : "a rating"}:</div>}
          {ratingsControl(dispatch, rateUserList, ratingsByYou, agreementVisibility)}
          <div className="contentLabel moveTop20">{distributionDisplay}{distributionDisplay !== "" ? " " : ""}{counterpartyDistributionDisplay}</div>
        </div>
        if (user1CanWithdraw) {
          statusNotice = "Withdraw funds";
          statusActions.push("withdraw");
          actionRequired = "you";
        }
      }
      else if (youAreUser2) {
        let rateUserList = [{userKey: user1Locked, userLink: user1Link}];
        if (arbitrationRequested) {
          rateUserList.push({userKey: agreedArbitratorUsername, userLink: arbitratorLink});
        }

        let resolver = "";
        // We can add more complex logic later to display more about how the resolution occurred.
        if (arbitratorDidResolve) {
          resolver = <span>{arbitratorLink} (the arbitrator)</span>;
        }

        // Calculate info for distribution display
        let distributionDisplay = "";
        if (user2CanWithdraw) {
          distributionDisplay = "The following funds are waiting for your withdrawal: " + user2DisplayWithdrawAmount + ".";
        } else if (user2Withdrew) {
          if (!resolutionNothingUser2 || user2HasFeeToWithdraw){
            distributionDisplay = user2DisplayWithdrawAmount + " was distributed to you" + (user2HasFeeToWithdraw ? " (includes arbitration fee refund)." : ".");
          }
        }
        let counterpartyDistributionDisplay = "";
        if (user1Withdrew) {
          if (!resolutionNothingUser1 || user1HasFeeToWithdraw){
            counterpartyDistributionDisplay = <span>{user1DisplayWithdrawAmount} was distributed to {user1Link}{(user1HasFeeToWithdraw ? " (includes arbitration fee refund)." : ".")}</span>;
          } 
        }

        statusDesc = 
        <div>
          <div className="contentLabel">Contract's outcome was finalized{resolver !== "" ? <span> by {resolver}</span> : "" }{completeTimestamp > 0 ? " on " + datetimeDisplayString(completeTimestamp) : ""}.</div>
          <div className="contentLabel moveTop20">Final outcome: <span className="resolutionBlock">{resolutionDisplay}</span></div>
          {agreementVisibility !== "private" && <div className="contentLabel moveTop20">Leave {arbitrationRequested ? "ratings" : "a rating"}:</div>}
          {ratingsControl(dispatch, rateUserList, ratingsByYou, agreementVisibility)}
          <div className="contentLabel moveTop20">{distributionDisplay}{distributionDisplay !== "" ? " " : ""}{counterpartyDistributionDisplay}</div>
        </div>        
        if (user2CanWithdraw) {
          statusNotice = "Withdraw funds";
          statusActions.push("withdraw");
          actionRequired = "you";
        }
      }
      else {
        let resolver = "";
        // We can add more complex logic later to display more about how the resolution occurred.
        if (arbitratorDidResolve) {
          if (youAreArbitrator) {
            resolver = <span>you (the arbitrator)</span>;
          } else {
            resolver = <span>{arbitratorLink} (the arbitrator)</span>;
          }
        }

        // Calculate info for distribution display
        let user1DistributionDisplay = "";
        if (user1Withdrew) {
          if (!resolutionNothingUser1 || user1HasFeeToWithdraw){
            user1DistributionDisplay = <span>{user1DisplayWithdrawAmount} was distributed to {user1Link}{user1HasFeeToWithdraw ? " (includes arbitration fee refund)." : "."}</span>;
          } 
        }
        let user2DistributionDisplay = "";
        if (user2Withdrew) {
          if (!resolutionNothingUser2 || user2HasFeeToWithdraw){
            user2DistributionDisplay = <span>{user2DisplayWithdrawAmount} was distributed to {user2Link}{user2HasFeeToWithdraw ? " (includes arbitration fee refund)." : "."}</span>;
          } 
        }

        statusDesc = 
        <div>
          <div className="contentLabel">Contract's outcome was finalized{resolver !== "" ? <span> by {resolver}</span> : "" }{completeTimestamp > 0 ? " on " + datetimeDisplayString(completeTimestamp) : ""}.</div>
          <div className="contentLabel moveTop20">Final outcome: <span className="resolutionBlock">{resolutionDisplay}</span></div>
          <div className="contentLabel moveTop20">{user1DistributionDisplay}{user1DistributionDisplay !== "" ? " " : ""}{user2DistributionDisplay}</div>        
        </div>
      }

      if (!user1CanWithdraw && !user2CanWithdraw) {
        statusNotice = "Completed";
        actionRequired = "completed";
      }
    }
    else if (statusExplicit === "finalized" && allStakeFundsDeposited) {
      selectedStep = 3;

      // ====================== YOU ARE USER1 ====================== 
      if (youAreUser1) {
        let resElem = displayResolutions(resolutionStatusList, onResolveClick);
        if (showChangeResolution && onResolveClick !== null) {
          statusActions.push("change_resolution");
        }

        if (arbitratorCanResolve) {
          statusDesc = <div>
            Arbitration started. Waiting for arbitrator {arbitratorLink} to report the outcome. {arbitratorWithdrewDisputeFee ? "Arbitrator already withdrew the dispute fee." : ""} Outcome status:
            {resElem}
            <div className="moveTop20">
              You and {user2Link} may still reach an agreement before the arbitrator's ruling by changing your outcome reports.
              Whichever of you changes your report to the final outcome last will pay the arbitration fee.
              {arbitratorCanWithdrawFee ? " If you reach an agreement before the arbitrator withdraws their fee neither of you will pay this fee." : ""}
            </div>
          </div>
          statusNotice = "Waiting on arbitrator decision";
          actionRequired = "";
        }
        else if (user1RequestedArbitration) {
          statusDesc = <div>
            Arbitration requested by you. Waiting on {user2Link} to respond. Outcome status:
            {resElem}
          </div>
          statusNotice = "Waiting on " + user2DisplayValue + " to respond to arbitration request";
          actionRequired = "";
        }
        else if (user2RequestedArbitration) {
          if (user1CanResolve && !user1CanRequestArbitration) {
            statusDesc = <div>
              Arbitration requested by {user2Link}. You must enter an outcome report before you can respond to the arbitration request. Outcome status:
              {resElem}
            </div>;
            statusActions.push("agree_arbitration_disabled");
          }
          else {
            statusDesc = <div>
              Arbitration requested by {user2Link}. Waiting on you to respond. Outcome status:
              {resElem}
            </div>;
          }
          
          statusNotice = "Waiting on your response to arbitration request";
          actionRequired = "you";
        }
        else if (!user1DidResolve) {
          statusDesc = <div>
            Funds have been deposited. Waiting on you to report the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on you to report outcome";
          actionRequired = "you";
        }
        else if (!user2DidResolve) {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user2Link} to report the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on " + user2Disp + " to report outcome";
          actionRequired = "";
        }        
        else {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user1Link} and {user2Link} to agree on the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on outcome reports";
          actionRequired = "";
          if (youAreUser1) {
            statusNotice = "Waiting on you and " + user2Disp + " to agree on the outcome";
            actionRequired = "you";
          }
          if (youAreUser2) {
            statusNotice = "Waiting on you and " + user1Disp + " to agree on the outcome";
            actionRequired = "you";
          }
        }

        if (pendingTransactionWarning !== "") {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {pendingTransactionWarning}
            </div>
          </div>;
        }

        // youAreUser1 === true
        if (user1CanRequestArbitration) {
          if (user2RequestedArbitration) {
            if (hasBalanceForArbitrationFeeAndTransactionFee) {
              statusActions.push("agree_arbitration");
            }
            else {
              statusActions.push("agree_arbitration_disabled");
            }
          }
          else {
            if (hasBalanceForArbitrationFeeAndTransactionFee) {
              statusActions.push("request_arbitration");
            }
            else {
              statusActions.push("request_arbitration_disabled");
            }
          }
        }

        if (user1CountdownRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              You can request a default judgment on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestDefaultJudgment)} remaining) if {user2Link} doesn't respond or reach an agreement with you before then.
            </div>
          </div>;
        }
        else if (user2CountdownRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {user2LinkUC} can request a default judgment on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestDefaultJudgment)} remaining) if you don't respond or reach an agreement with them before then.
              {user1CanRequestArbitration && depositInsufficentArbitrationFee}
            </div>
          </div>;
        }

        if (countdownRequestAutomaticResolution) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              If you don’t agree with {user2Link} or request arbitration by {datetimeDisplayString(autoResolveAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestAutomaticResolution)} remaining) then either you or {user2Link} can trigger the outcome: <div className="moveTop5 resolutionBlock">{automaticResolutionDisplay}</div>
              {user1CanRequestArbitration && depositInsufficentArbitrationFee}
            </div>
          </div>;
        }
        if (user1CountdownRequestArbitration) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              You can request arbitration starting on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestArbitration)} remaining).
            </div>
          </div>;
        }
        if (user1CanRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              Because {user2Link} has not responded to your arbitration request, you may now trigger a default judgment that will set the contract's 
              outcome to your reported outcome.
            </div>
          </div>;
          statusActions.push("request_default_judgment");
        } else if (user2CanRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              Because you haven't yet responded to {user2Link}'s arbitration request, they can now trigger a default outcome. This would set the contract's outcome to their reported outcome. You can prevent this by agreeing to arbitration.
            </div>
          </div>;
        }
        if (canRequestAutomaticResolution) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
                Enough time has elapsed that you or {user2Link} can trigger an automatic outcome of: <div className="moveTop5 resolutionBlock">{automaticResolutionDisplay}</div>
                <div className="moveTop20">Requesting arbitration blocks both of you from triggering the automatic outcome.{user1DidResolve ? "" : " You must report an outcome before you can request arbitration."}</div>
            </div>
          </div>;
          statusActions.push("request_automatic_resolution");
        }
      }

      // ====================== YOU ARE USER2 ====================== 
      else if (youAreUser2) {
        let resElem = displayResolutions(resolutionStatusList, onResolveClick);
        if (showChangeResolution && onResolveClick !== null) {
          statusActions.push("change_resolution");
        }
        
        if (arbitratorCanResolve) {
          statusDesc = <div>
            Arbitration started. Waiting for arbitrator {arbitratorLink} to report the outcome. {arbitratorWithdrewDisputeFee ? "Arbitrator already withdrew the dispute fee." : ""} Outcome status:
            {resElem}
            <div className="moveTop20">
              You and {user1Link} may still reach an agreement before the arbitrator's ruling by changing your outcome reports.
              Whichever of you changes to the final outcome last will pay the arbitration fee.
              {arbitratorCanWithdrawFee ? " If you reach an agreement before the arbitrator withdraws their fee neither of you will pay this fee." : ""}
            </div>
          </div>
          statusNotice = "Waiting on arbitrator decision";
          actionRequired = "";
        }
        else if (user2RequestedArbitration) {
          statusDesc = <div>
            Arbitration requested by you. Waiting on {user1Link} to respond. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on " + user1Locked + " to respond to arbitration request";
          actionRequired = "";
        }
        else if (user1RequestedArbitration) {
          if (user2CanResolve && !user2CanRequestArbitration) {
            statusDesc = <div>
              Arbitration requested by {user1Link}. You must enter an outcome report before you can respond to the arbitration request. Outcome status:
              {resElem}
            </div>;
            statusActions.push("agree_arbitration_disabled");
          }
          else {
            statusDesc = <div>
              Arbitration requested by {user1Link}. Waiting on you to respond. Outcome status:
              {resElem}
            </div>;
          }
          statusNotice = "Waiting on your response to arbitration request";
          actionRequired = "you";          
        }
        else if (!user2DidResolve) {
          statusDesc = <div>
            Funds have been deposited. Waiting on you to report the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on you to report outcome";
          actionRequired = "you";
        }
        else if (!user1DidResolve) {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user1Link} to report the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on " + user1Disp + " to report outcome";
          actionRequired = "";
        }
        else {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user1Link} and {user2Link} to agree on the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on outcome reports";
          actionRequired = "";
          if (youAreUser1) {
            statusNotice = "Waiting on you and " + user2Disp + " to agree on the outcome";
            actionRequired = "you";
          }
          if (youAreUser2) {
            statusNotice = "Waiting on you and " + user1Disp + " to agree on the outcome";
            actionRequired = "you";
          }
        }

        if (pendingTransactionWarning !== "") {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {pendingTransactionWarning}
            </div>
          </div>;
        }

        // youAreUser2 === true
        if (user2CanRequestArbitration) {
          if (user1RequestedArbitration) {
            if (hasBalanceForArbitrationFeeAndTransactionFee) {
              statusActions.push("agree_arbitration");
            }
            else {
              statusActions.push("agree_arbitration_disabled");
            }
          }
          else {
            if (hasBalanceForArbitrationFeeAndTransactionFee) {
              statusActions.push("request_arbitration");
            }
            else {
              statusActions.push("request_arbitration_disabled");
            }
          }
        }

        if (user2CountdownRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              You can request a default judgment on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestDefaultJudgment)} remaining) if {user1Link} doesn't respond or reach an agreement with you before then.
            </div>
          </div>;
        }
        else if (user1CountdownRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {user1LinkUC} can request a default judgment on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestDefaultJudgment)} remaining) if you don't respond or reach an agreement with them before then.
              {user2CanRequestArbitration && depositInsufficentArbitrationFee}
            </div>
          </div>;
        }

        if (countdownRequestAutomaticResolution) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              If you don’t agree with {user1Link} or request arbitration by {datetimeDisplayString(autoResolveAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestAutomaticResolution)} remaining) then either you or {user1Link} can trigger the outcome: <div className="moveTop5 resolutionBlock">{automaticResolutionDisplay}</div>
              {user2CanRequestArbitration && depositInsufficentArbitrationFee}
            </div>
          </div>;
        }
        if (user2CountdownRequestArbitration) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              You can request arbitration starting on {datetimeDisplayString(nextArbitrationStepAllowedAfterTimestamp)} ({timeRemainingDisplayString(timeToRequestArbitration)} remaining).
            </div>
          </div>;
        }
        if (user2CanRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              Because {user1Link} has not responded to your arbitration request, you may now trigger a default judgment that will set the contract's 
              outcome to your reported outcome.
            </div>
          </div>;
          statusActions.push("request_default_judgment");
        } else if (user1CanRequestDefaultJudgment) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              Because you haven't yet responded to {user1Link}'s arbitration request, they can now trigger a default outcome. This would set the contract's outcome to their reported outcome. You can prevent this by agreeing to arbitration.
            </div>
          </div>;
        }
        if (canRequestAutomaticResolution) {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
                Enough time has elapsed that you or {user1Link} can trigger an automatic outcome of: <div className="moveTop5 resolutionBlock">{automaticResolutionDisplay}</div>
                <div className="moveTop20">Requesting arbitration blocks both of you from triggering the automatic outcome.{user2DidResolve ? "" : " You must report an outcome before you can request arbitration."}</div>
            </div>
          </div>;
          statusActions.push("request_automatic_resolution");
        }
      }

      // ====================== YOU ARE ARBITRATOR ====================== 
      else if (youAreArbitrator) {
        let resElem = displayResolutions(resolutionStatusList, onResolveClick);

        if (arbitratorCanResolve) {
          statusDesc = <div>
            Arbitration started. Waiting for you (the arbitrator) to report the outcome{arbitratorCanWithdrawFee ? " or indicate you're working on the dispute by withdrawing the dispute fee": ""}. {arbitratorWithdrewDisputeFee ? "You already withdrew the dispute fee." : ""} Outcome status:
            {resElem}
          </div>
          statusNotice = "Waiting on you to arbitrate";
          actionRequired = "you";
        }
        else {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user1Link} and {user2Link} to agree on the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on outcome reports";
          actionRequired = "";
        }

        if (pendingTransactionWarning !== "") {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {pendingTransactionWarning}
            </div>
          </div>;
        }
      }

      // ====================== YOU ARE NOT PARTICIPATING ====================== 
      else {
        let resElem = displayResolutions(resolutionStatusList, onResolveClick);

        if (arbitratorCanResolve) {
          statusDesc = <div>
            Arbitration started. Waiting for arbitrator {arbitratorLink} to report the outcome. {arbitratorWithdrewDisputeFee ? "Arbitrator already withdrew the dispute fee." : ""} Outcome status:
            {resElem}
          </div>
          statusNotice = "Waiting on arbitrator decision";
          actionRequired = "";
        }
        else {
          statusDesc = <div>
            Funds have been deposited. Waiting on {user1Link} and {user2Link} to agree on the outcome. Outcome status:
            {resElem}
          </div>;
          statusNotice = "Waiting on outcome reports";
          actionRequired = "";
        }
      }
    }
    else if (statusExplicit === "finalized") {
      selectedStep = 2;

      if (isCanceled) {
        statusDesc = <div>Contract has been canceled.</div>
        statusNotice = "Canceled";
        actionRequired = "canceled";
      }
      else {
        if (youAreUser1) {
          if (user1StakePaid) {
            statusDesc = <div>You already deposited. Waiting on {user2Link} to deposit {user2StakeDisplay}.</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to deposit funds";
            if (user1CanEarlyWithdraw) {
              statusActions.push("withdraw_cancel");
            }
          }
          else {
            statusDesc = <div>{user2LinkUC} has already deposited. Waiting on you to deposit {user1StakeDisplay}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you to deposit funds";

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("deposit");
            }
            else {
              statusActions.push("deposit_disabled");
            }

            actionRequired = "you";
          }
        }
        else if (youAreUser2) {
          if (user2StakePaid) {
            statusDesc = <div>You already deposited. Waiting on {user1Link} to deposit {user1StakeDisplay}.</div>
            statusNotice = "Waiting on " + user1Locked + " to deposit funds";
            if (user2CanEarlyWithdraw) {
              statusActions.push("withdraw_cancel");
            }
          }
          else {
            statusDesc = <div>{user1LinkUC} has already deposited. Waiting on you to deposit {user2StakeDisplay}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you to deposit funds";

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("deposit");
            }
            else {
              statusActions.push("deposit_disabled");
            }

            actionRequired = "you";
          }
        }
        else {
          if (user1StakePaid) {
            statusDesc = <div>Waiting on {user2Link} to deposit {user2StakeDisplay}.</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to deposit funds";
          }
          else {
            statusDesc = <div>Waiting on {user1Link} to deposit {user1StakeDisplay}.</div>
            statusNotice = "Waiting on " + user1Locked + " to deposit funds";
          }
        }

        if (pendingTransactionWarning !== "") {
          statusDesc = <div>
            {statusDesc}
            <div className="moveTop20">
              {pendingTransactionWarning}
            </div>
          </div>;
        }
      }
    }
    else if (statusExplicit === "agreed") {
      let isCurrent = (versionUser1ProposedExplicit === versionid);
      if (arbitratorHasAgreed) {
        if (youAreUser1) {
          if (user1ZeroContribution) {
            statusDesc = <div>Waiting on {user2Link} to deposit {user2StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to deposit funds";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
          else if (user2ZeroContribution) {
            statusDesc = <div>Waiting on you to deposit {user1StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you to deposit funds";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("finalize");
            }
            else {
              statusActions.push("finalize_disabled");
            }

            actionRequired = "you";
          }
          else {
            statusDesc = <div>Waiting on you and {user2Link} to deposit funds. Your deposit amount is {user1StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you and " + user2DisplayValue + " to deposit funds"; //"Waiting on funds to be deposited";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("finalize");
            }
            else {
              statusActions.push("finalize_disabled");
            }

            actionRequired = "you";
          }
        }
        else if (youAreUser2) {
          if (user2ZeroContribution) {
            statusDesc = <div>Waiting on {user1Link} to deposit {user1StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.</div>
            statusNotice = "Waiting on " + user1Locked + " to deposit funds";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
          else if (user1ZeroContribution) {
            statusDesc = <div>Waiting on you to deposit {user2StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you to deposit funds";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("finalize");
            }
            else {
              statusActions.push("finalize_disabled");
            }

            actionRequired = "you";
          }
          else {
            statusDesc = <div>Waiting on you and {user1Link} to deposit funds. Your deposit amount is {user2StakeDisplay}. You both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.{depositInsufficentFundsMsg}</div>
            statusNotice = "Waiting on you and " + user1Locked + " to deposit funds"; //"Waiting on funds to be deposited";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");

            if (hasBalanceForDepositAndTransactionFee) {
              statusActions.push("finalize");
            }
            else {
              statusActions.push("finalize_disabled");
            }

            actionRequired = "you";
          }
        }
        else {
          if (user1ZeroContribution) {
            statusDesc = <div>Waiting on {user2Link} to deposit {user2StakeDisplay}. They both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to deposit funds";
          }
          else if (user2ZeroContribution) {
            statusDesc = <div>Waiting on {user1Link} to deposit {user1StakeDisplay}. They both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.</div>
            statusNotice = "Waiting on " + user1Locked + " to deposit funds";
          }
          else {
            statusDesc = <div>Waiting on {user1Link} and {user2Link} to deposit funds. They both approved {isCurrent ? "this version" : <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>}.</div>
            statusNotice = "Waiting on " + user1Locked + " and " + user2DisplayValue + " to deposit funds"; //"Waiting on funds to be deposited";
          }
        }
      }
      else {
        statusDesc = <div>Waiting on arbitrator {arbitratorLink} to agree to arbitrate.</div>
        statusNotice = "Waiting on arbitrator to agree to arbitrate";
        if (youAreUser1 || youAreUser2) {
          statusActions.push("unselect");
          statusActions.push("unselect_and_edit");
        }
      }

      // Currently we don't need this because it gets blocked by other logic that shows a waiting spinner whenever a
      // deploy transaction is in flight. But that may change.
      if (pendingTransactionWarning !== "") {
        statusDesc = <div>
          {statusDesc}
          <div className="moveTop20">
            {pendingTransactionWarning}
          </div>
        </div>;
      }
    }
    else if (statusExplicit === "proposed") {

      // ====================== YOU ARE USER1 ====================== 
      if (youAreUser1) {
        if (isUser1ProposedVersionExplicit) {
          if (versionUser2ProposedExplicit === "") {
            statusDesc = <div>You have approved this version. Waiting on {user2Link} to approve terms.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to approve terms";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
          else {
            statusDesc = <div>You have approved this version, but {user2Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user2DisplayValue + " have approved different versions";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
        }
        else if (isUser2ProposedVersionExplicit) {
          if (versionUser1ProposedExplicit === "") {
            statusDesc = <div>This version approved by {user2Link}. Waiting on you to approve terms.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on you to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else {
            statusDesc = <div>This version approved by {user2Link}, but you have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user2DisplayValue + " have approved different versions";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }          
        }
        else {
          if (versionUser1ProposedExplicit === "" && versionUser2ProposedExplicit === "") {
            statusDesc = <div>Waiting on both you and {user2Link} to approve a version.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on both you and " + user2DisplayValue + " to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else if (versionUser1ProposedExplicit !== "") {
            statusDesc = <div>You have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>, but waiting on {user2Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
          }
          else if (versionUser2ProposedExplicit !== "") {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>Version #{versionUser2Num}</span> approved by {user2Link}, but waiting on you.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on you to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else {  
            statusDesc = <div>You have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>, but {user2Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user2DisplayValue + " have approved different versions";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
        }
      }

      // ====================== YOU ARE USER2 ====================== 
      else if (youAreUser2) {
        if (isUser2ProposedVersionExplicit) {
          if (versionUser1ProposedExplicit === "") {
            statusDesc = <div>You have approved this version. Waiting on {user1Link} to approve terms.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user1Locked + " to approve terms";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
          else {
            statusDesc = <div>You have approved this version, but {user1Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user1Locked + " have approved different versions";
            statusActions.push("unselect");
            statusActions.push("unselect_and_edit");
          }
        }
        else if (isUser1ProposedVersionExplicit) {
          if (versionUser2ProposedExplicit === "") {
            statusDesc = <div>This version approved by {user1Link}. Waiting on you to approve terms.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on you to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else {
            statusDesc = <div>This version approved by {user1Link}, but you have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user1Locked + " have approved different versions";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }          
        }
        else {
          if (versionUser1ProposedExplicit === "" && versionUser2ProposedExplicit === "") {
            statusDesc = <div>Waiting on both you and {user1Link} to decide on a version.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on both you and " + user1Locked + " to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else if (versionUser2ProposedExplicit !== "") {
            statusDesc = <div>You have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>, but waiting on {user1Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user1Locked + " to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
          }
          else if (versionUser1ProposedExplicit !== "") {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>Version #{versionUser1Num}</span> approved by {user1Link}, but waiting on you.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on you to approve terms";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
          else {
            statusDesc = <div>You have approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>, but {user1Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "You and " + user1Locked + " have approved different versions";
            statusActions.push("select_this_version");
            statusActions.push("edit_this_version");
            actionRequired = "you";
          }
        }
      }

      // ====================== YOU ARE NOT PARTICIPATING ====================== 
      else {
        if (isUser1ProposedVersionExplicit) {
          if (versionUser2ProposedExplicit === "") {
            statusDesc = <div>This version approved by {user1Link}, but waiting on {user2Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to approve terms";
            //statusActions.push("unselect");
            //statusActions.push("unselect_and_edit");
          }
          else {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>Version #{versionUser1Num}</span> approved by {user1Link}, but {user2Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "Participants have approved different versions";
            //statusActions.push("unselect");
            //statusActions.push("unselect_and_edit");
          }
        }
        else if (isUser2ProposedVersionExplicit) {
          if (versionUser1ProposedExplicit === "") {
            statusDesc = <div>This version approved by {user2Link}, but waiting on {user1Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user1Locked + " to approve terms";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
          else {
            statusDesc = <div>This version approved by {user2Link}, but {user1Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>version #{versionUser1Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "Participants have approved different versions";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
        }
        else {
          if (versionUser1ProposedExplicit === "" && versionUser2ProposedExplicit === "") {
            statusDesc = <div>Waiting on both {user1Link} and {user2Link} to approve a version.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on both " + user1Locked + " and " + user2DisplayValue + " to approve terms";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
          else if (versionUser1ProposedExplicit !== "") {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>Version #{versionUser1Num}</span> approved by {user1Link}, but waiting on {user2Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user2DisplayValue + " to approve terms";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
          else if (versionUser2ProposedExplicit !== "") {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>Version #{versionUser2Num}</span> approved by {user2Link}, but waiting on {user1Link}.{allowImplicitApprovalMsg}</div>
            statusNotice = "Waiting on " + user1Locked + " to approve terms";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
          else {
            statusDesc = <div><span onClick={onVersionClick} className="blacklink" data-versionid={versionUser1ProposedExplicit}>Version #{versionUser1Num}</span> approved by {user1Link}, but {user2Link} has approved <span onClick={onVersionClick} className="blacklink" data-versionid={versionUser2ProposedExplicit}>version #{versionUser2Num}</span>.{allowImplicitApprovalMsg}</div>
            //statusNotice = "Waiting for terms to be agreed";
            statusNotice = "Participants have approved different versions";
            //statusActions.push("select_this_version");
            //statusActions.push("edit_this_version");
          }
        }
      }
      // It would be surprising that we need to display this for 'proposed', especially since stuck deposit transactions currently
      // block all other actions. But that might change in the future
      if (pendingTransactionWarning !== "") {
        statusDesc = <div>
          {statusDesc}
          <div className="moveTop20">
            {pendingTransactionWarning}
          </div>
        </div>;
      }

    }
    else if (statusExplicit === "unlinked") {
      // Don't give a linking-specific message here, to avoid revealing when someone does/doesn't have an account
      statusDesc = <div>Waiting on {user2Link} to approve terms.</div>
      statusNotice = "Waiting on " + user2DisplayValue + " to approve terms";
      if (youAreUser1) {
        if (isUser1ProposedVersionExplicit) {
          statusActions.push("unselect");
          statusActions.push("unselect_and_edit");
        }
      }
      else if (youAreUser2) {
        statusDesc = <div>Waiting on you to link your account.</div>
        statusNotice = "Waiting on you to link your account";
        actionRequired = "you";
      }
    }
  }

  if (mode === "view" || mode === "edit") {    
    if (visibilityChangeMsg !== "") {
      statusDesc = <div><div>{statusDesc}</div><div className="moveTop20">{visibilityChangeMsg}</div></div>;
      if (visibilityChangeNoticeForYou !== "") {
        statusNotice = visibilityChangeNoticeForYou;
        actionRequired = "you";
      }
    }

    if (youAreArbitrator) {
      if (arbitratorCanWithdrawFee) {
        statusDesc = <div><div>{statusDesc}</div><div className="moveTop20">Dispute fee of {disputeFeeDisplay} is available for withdrawal.</div></div>;
        statusActions.push("withdraw_fee");
        if (isResolved) {
          statusNotice = "Withdraw dispute fee";
          actionRequired = "you";
        }
      }
    }

    if (youAreArbitrator && !isCanceled && !isResolved) {
      if (agreedArbitratorStatus === "agreed") {
        if (statusExplicit !== "finalized") {
          statusDesc = <div>Do you agree to arbitrate this contract?</div>
          statusNotice = "You've agreed to arbitrate this contract";
          toggleActions.push("arbitration_agree");
          toggleActions.push("arbitration_reject");
          toggleActions.push("arbitration_pending");
          toggleActionSelected = "arbitration_agree";
          actionRequired = "arbitrator";
        }
        else {
          let agreeToArbitrateMsg = "You've agreed to arbitrate this contract.";
          if (!user1RequestedArbitration || !user2RequestedArbitration) {
            agreeToArbitrateMsg = "You've agreed to arbitrate this contract. Arbitration hasn't been requested.";
          }
  
          statusDesc = <div>{agreeToArbitrateMsg}<div className="moveTop20">{statusDesc}</div></div>
          if (actionRequired === "you") {
            statusNotice = "Waiting on you to arbitrate";
          } else {
            statusNotice = "You've agreed to arbitrate this contract";
          }  
        }
      }
      else if (agreedArbitratorStatus === "rejected") {
        if (statusExplicit !== "finalized") {
          statusDesc = <div>Do you agree to arbitrate this contract?</div>
          statusNotice = "You've declined to arbitrate this contract";
          toggleActions.push("arbitration_agree");
          toggleActions.push("arbitration_reject");
          toggleActions.push("arbitration_pending");
          toggleActionSelected = "arbitration_reject";
          actionRequired = "arbitrator";
        }
      }
      else {
        if (statusExplicit !== "finalized") {
          statusDesc = <div>Do you agree to arbitrate this contract?</div>
          toggleActions.push("arbitration_agree");
          toggleActions.push("arbitration_reject");
          toggleActions.push("arbitration_pending");
          toggleActionSelected = "arbitration_pending";
          statusNotice = "Do you agree to arbitrate this contract?";
          actionRequired = "you";
        }
      }
    }
  }

  let showLoadIcon = false;
  let waitingTimeMessage = <span>It should take about fifteen seconds.</span>;  
  if ((statusExplicit === "finalized" || statusExplicit === "agreed" || statusExplicit === "proposed") && hasTransactionAction && transactionObj && transactionObj.hash !== "") {
    statusDesc = <div>{"Waiting for "}<Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), transactionObj.hash)}>{shared.getTransactionTypeDisplay(transactionObj.type) + " transaction"}</Link> to be confirmed. {waitingTimeMessage}</div>;
    statusNotice = "";
    statusActions = [];
    showLoadIcon = true;
  }
  else if ((statusExplicit === "agreed" || statusExplicit === "proposed") && transactionHash !== "") {
    let minutesAfterLockStarted = 0; 
    if (transactionLockUntilUnix !== 0) {
      minutesAfterLockStarted = minutesBeforeNow(transactionLockUntilUnix - shared.AGREEMENT_LOCK_EXPIRATION_SECONDS);
    }
    if (minutesAfterLockStarted >= 3) { 
      waitingTimeMessage = <span>It has been pending for {minutesAfterLockStarted} minutes -- consider {transactionByOtherParty ? <span>asking {getUserLinkElement(transactionByUsername, transactionByUsername)} to increase the transaction fee.</span> : <span>increasing the transaction fee from within your wallet.</span>} {transactionHelpElem}.</span> 
    }
    let whoString = transactionByOtherParty ? <span>{getUserLinkElement(transactionByUsername, transactionByUsername)}'s</span> : "your"; 
    statusDesc = <div>{"Waiting for "}{whoString} <Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), transactionHash)}>deposit transaction</Link> to be confirmed. {waitingTimeMessage}</div>;
    statusNotice = "Waiting for deposit transaction to be confirmed";
    statusActions = [];
    showLoadIcon = true;
    actionRequired = (transactionByYou ? "you" : "");
  }
  else if ((statusExplicit === "agreed" || statusExplicit === "proposed") && transactionHash === "" && transactionByOtherParty && !hasTimestampExpired(transactionLockUntilUnix)) {
    statusDesc = <div>Waiting for {getUserLinkElement(transactionByUsername, transactionByUsername)} to complete deposit transaction.</div>;
    statusNotice = "Waiting for " + transactionByUsername + " to complete deposit transaction";
    statusActions = [];
    actionRequired = "";
  }
  else if (mode === "view" && warningType === "self_agreement") {
    statusActions = [];
    statusDesc = "";
    statusNotice = "Invalid contract. You cannot have a contract with yourself.";
    actionRequired = "";
  }
  else if (statusExplicit === "finalized" && !blockchainInit) {

    if (flagNoAddress) {
      selectedStep = 0;
      statusDesc = "";
      statusNotice = "...";
      statusActions = [];
      actionRequired = false;
    }
    else if (!blockchainSynced) {
      selectedStep = 0;
      statusDesc = "Syncing data ...";
      statusNotice = "...";
      statusActions = [];
      showLoadIcon = true;
      actionRequired = false;
    }
    else if (!databaseSynced) {
      selectedStep = 0;
      statusDesc = "Syncing data ...";
      statusNotice = "";
      statusActions = [];
      showLoadIcon = true;
      actionRequired = false;
    }
    else if (!hasDataIntegrity) {
      selectedStep = 0;
      statusDesc = <div>Contract on blockchain doesn't match the hash. Do not deposit money into this contract.<div className="hide">{dataIntegrityReason}</div></div>;
      statusNotice = "";
      statusActions = [];
      actionRequired = false;
    }
    else {
      selectedStep = 0;
      statusDesc = "There was an error loading blockchain data. Please refresh. Trace: " + errorTrace;
      statusNotice = "";
      statusActions = ["refresh"];
      actionRequired = false;
    }
  }

  return { statusNotice, statusActions, statusDesc, selectedStep, toggleActions, toggleActionSelected, isUser1ProposedVersion, isUser2ProposedVersion, showLoadIcon, actionRequired, hasWarning, shouldUseAddress, warningType };
}