import { connect } from 'react-redux';
import React, { Component } from 'react';
import './About.css';
import './Common.css';
import TwoColumnContainer from './TwoColumnContainer';
import imageElliotTeamphoto from '../teamphoto_elliot2.png';
import imageJustinTeamphoto from '../teamphoto_justin2.jpg';
import imageTwitterLogo from '../logo-twitter.png';
import imageGithubLogo from '../logo-github.png';
import imageLinkedinLogo from '../logo-linkedin.png';
import GapCard from "./GapCard";
import Link from './Link';
import { genURL } from "./ActionUtils";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let isSingleColumn = this.props.auth.columns === 1;
    let teamImagesExtraPadding = "200px";

    return (
      <div>
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div style={{textAlign: "left"}} className="pageTitle">What is Atstake?</div>
            <div className="pageLightText moveTop20">
              Atstake is a platform for creating two-person contracts where one or both people stake money which is later distributed between them based on any criteria. 
              Atstake offers both a way to <Link noTarget={true} href={genURL("/postings")} className="bluelink">discover</Link> counterparties
              for your contracts as well as tools to ensure your contracts are <Link noTarget={true} href={genURL("/incentives")} className="bluelink">fairly enforced</Link>.
            </div>
            <div className="pageLightText moveTop20">
              Atstake is an extremely general platform capable of handling many types of 
              contracts. <Link noTarget={true} href={genURL("/examples")} className="bluelink">See examples &#9656;</Link>
            </div>
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-b-bg-color)"}}>
          <div className="restrictSectionWidth">
            <div style={{textAlign: "left"}} className="pageTitle">Why we created Atstake</div>
            <div className="pageLightText moveTop20">
              Public blockchains like Ethereum provide the ability to enforce contracts between two people in a way that is less expensive and less risky than was 
              possible before the creation of these networks. <Link href={genURL("/faq#whyethereum")} className="bluelink" noTarget={true}>Learn more ></Link>.
            </div>
            <div className="pageLightText moveTop20">
              We created Atstake because we're excited about the potential for public blockchains to dramatically improve the functioning of the 
              global economy by improving how contracts are enforced. 
            </div>
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-c-bg-color)"}}>
          <div style={{textAlign: "center"}} className="pageTitleWhite">Team</div>
          <div id="team" style={{backgroundColor:"var(--subtitle-bg-color)"}} >
            <div className="restrictSectionWidth">
              <div className="">
                <TwoColumnContainer showBorder={false} className="moveTop20" leftComp={
                  <div style={{paddingLeft:(isSingleColumn ? "" : teamImagesExtraPadding)}} className="pageWhiteText moveTop40">
                    <table style={{margin:"0px auto"}}><tbody><tr>
                      <td style={{textAlign:"center"}}>
                        <div className="teamImgCnt"><img style={{width: "150px", top: "-12px", left: "0px"}} className="teamImg" src={imageElliotTeamphoto} alt="Elliot Olds" /></div>
                      </td>
                    </tr><tr>
                      <td style={{textAlign:"center"}}>
                        <div className="moveTop20">Elliot Olds</div> 
                        <div className="moveTop5">Cofounder / developer</div>
                        <div className="moveTop10">
                          <Link className="moveRight10" href="https://twitter.com/elliot_olds" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageTwitterLogo} alt="Twitter" /></Link>
                          <Link className="moveRight12" href="https://github.com/elliotolds" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageGithubLogo} alt="Github" /></Link>
                          <Link className="" href="https://www.linkedin.com/in/elliotolds" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageLinkedinLogo} alt="LinkedIn" /></Link>
                        </div>
                      </td>
                    </tr></tbody></table>
                  </div>
                } rightComp={
                  <div style={{paddingRight:(isSingleColumn ? "" : teamImagesExtraPadding)}} className="pageWhiteText moveTop40">
                    <table style={{margin:"0px auto"}}><tbody><tr>
                      <td style={{textAlign:"center"}}>
                        <div className="teamImgCnt"><img style={{width: "300px", top: "-56px", left: "-55px"}} className="teamImg" src={imageJustinTeamphoto} alt="Justin Hamilton" /></div>
                      </td>
                    </tr><tr>
                      <td style={{textAlign:"center"}}>
                        <div className="moveTop20">Justin Hamilton</div> 
                        <div className="moveTop5">Cofounder / developer</div>
                        <div className="moveTop10">
                          <Link className="moveRight10" href="https://twitter.com/ethgeek" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageTwitterLogo} alt="Twitter" /></Link>
                          <Link className="moveRight12" href="https://github.com/ethereumgeek" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageGithubLogo} alt="Github" /></Link>
                          <Link className="" href="https://www.linkedin.com/in/justindh" noTarget={true} rel="noopener noreferrer"><img className="socialMediaImg" src={imageLinkedinLogo} alt="LinkedIn" /></Link>
                        </div>
                      </td>
                    </tr></tbody></table>
                  </div>
              } fourCol1Comp={null} fourCol4Comp={null} />
                <div style={{height:"20px"}}></div>
              </div>
            </div>
          </div>
          <div className="pageLightText moveTop40"></div>
          <div className="pageLightText moveTop40"></div>
          <div className="pageWhiteText" style={{textAlign: "center"}}>
            Thanks to <Link className="bluelink" href="https://twitter.com/LevDubinets">Lev Dubinets</Link>,&nbsp; 
            <Link className="bluelink" href="https://twitter.com/melbrunner">Melanie Brunner</Link>,&nbsp;
            <Link className="bluelink" href="https://twitter.com/AnaMackert">Anastasia Mackert</Link>,&nbsp;
            <Link className="bluelink" href="https://www.linkedin.com/in/troyma/">Troy Ma</Link>,
            and <Link className="bluelink" href="https://www.linkedin.com/in/nikolai-aristov-6106571/">Nikolai Aristov</Link> for their consulting work.
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-d-bg-color)"}}>
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageCardHeading moveTop20">
              Have more questions? See our <Link href={genURL("/help")} className="bluelink" noTarget={true}>help page</Link>.
            </div>
          </div>
          <GapCard />
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    about: state.about,
    overlay: state.overlay
  }
}

const AboutContainer = connect(mapStateToProps, null)(About);
export default AboutContainer;