import React, { Component } from 'react'
import { connect } from 'react-redux';
import { navNewURL, getTextWidth } from './ActionUtils';
import './DialogNewContract.css';
import { changeInputValue, selectMode } from './DialogNewContractActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import { closeOverlay } from './OverlayActions'
import './Common.css';
import { 
  BUTTON_EXTRA_PIXELS,
  BUTTON_FONT,
  CONDITIONAL_PAYMENT_LABEL,
  GENERAL_CONTRACT_LABEL,
  SEND_PAYMENT_LABEL,
  REQUEST_PAYMENT_LABEL
} from './Constants.js';
import store from '../store';

class DialogNewContract extends Component {

  constructor(props, context) {
    super(props);

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.selectMode = this.selectMode.bind(this);
    this.newContract = this.newContract.bind(this);
  }

  componentDidMount() {
    let paymentWidth = Math.ceil(getTextWidth(CONDITIONAL_PAYMENT_LABEL, BUTTON_FONT)) + BUTTON_EXTRA_PIXELS;
    let contractWidth = Math.ceil(getTextWidth(GENERAL_CONTRACT_LABEL, BUTTON_FONT)) + BUTTON_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("contractTypeMaxButtonWidth", Math.max(paymentWidth, contractWidth)));

    let sendWidth = Math.ceil(getTextWidth(SEND_PAYMENT_LABEL, BUTTON_FONT)) + BUTTON_EXTRA_PIXELS;
    let requestWidth = Math.ceil(getTextWidth(REQUEST_PAYMENT_LABEL, BUTTON_FONT)) + BUTTON_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("paymentMaxButtonWidth", Math.max(sendWidth, requestWidth)));
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  selectMode(mode) {
    this.props.dispatch(selectMode(mode));
  }

  newContract(event, selectedUser, ownAccount) {
    event.preventDefault();
    let type = event.currentTarget.getAttribute("name");

    if (ownAccount) {
      store.dispatch(navNewURL('/new?type=' + type));
    }
    else {
      store.dispatch(navNewURL('/new?user=' + selectedUser + '&type=' + type));
    }

    this.handleOk(type);
  }
  
  render() {
    
    let contractTypeWidthString = this.props.dialogNewContract.contractTypeMaxButtonWidth + "px";
    let paymentWidthString = this.props.dialogNewContract.paymentMaxButtonWidth + "px";
    
    let {
      selectedUser="",
      mode=""
    } = this.props.dialogNewContract;

    let {
      username=""
    } = this.props.auth;

    let ownAccount = (username !== "") && (username === selectedUser);

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={!ownAccount ? "New contract with " + selectedUser : "New contract"} />
          {mode === "start" && (
            <div>
              <div className="">
                What type of contract?
              </div>
              <div className="newButtonCnt">
                <button style={{minWidth: contractTypeWidthString}} className="btn newBtnLeft moveTop20" onClick={() => this.selectMode("payment")}>{CONDITIONAL_PAYMENT_LABEL}</button>
                <button name="contract" style={{minWidth: contractTypeWidthString}} className="btn newBtnRight moveTop20" onClick={(event) => this.newContract(event, selectedUser, ownAccount)}>{GENERAL_CONTRACT_LABEL}</button>
              </div>
              <div className="moveTop20">
                If only one person will deposit funds choose 'conditional payment', otherwise choose 'general contract'.
              </div>
            </div>
          )}
          {mode === "payment" && (
            <div>
              <div className="">
                Will you send or request the conditional payment?
              </div>
              <div className="newButtonCnt">
                <button name="payment" style={{minWidth: paymentWidthString}} className="btn newBtnLeft moveTop20" onClick={(event) => this.newContract(event, selectedUser, ownAccount)}>{SEND_PAYMENT_LABEL}</button>
                <button name="request" style={{minWidth: paymentWidthString}} className="btn newBtnRight moveTop20" onClick={(event) => this.newContract(event, selectedUser, ownAccount)}>{REQUEST_PAYMENT_LABEL}</button>
              </div>
            </div>
          )}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogNewContract: state.dialogNewContract
  }
}

const DialogNewContractContainer = connect(mapStateToProps, null)(DialogNewContract);
export default DialogNewContractContainer;