import React, { Component } from 'react'
import { connect } from 'react-redux';
import { navNewURL } from './ActionUtils';
import './DialogConfirm.css';
import { changeInputValue } from './DialogConfirmActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import { closeOverlay } from './OverlayActions'

class DialogConfirm extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }
  
  handleYes(name, value) {
    let pathname = this.props.dialogConfirm.pathname;
    let query = this.props.dialogConfirm.query;
    let params = "";
    let delim = "?";
    if (query) {
      for (let key in query) {
        if (query.hasOwnProperty(key)  && query[key] !== "") {
          params += delim + key + "=" + encodeURIComponent(query[key]);
          delim = "&";
        }
      }
    }

    if (pathname === "back") {
      window.oldHref = window.location.href;
      window.history.back();
      window.setTimeout(() => {
        if (window.oldHref === window.location.href) {
          this.props.dispatch(navNewURL('/'));
        }
      }, 300);
    }
    else {
      this.props.dispatch(navNewURL('/' + pathname + params));
    }

    this.props.dispatch(closeOverlay());
  }

  handleNo(name, value) {
    this.props.dispatch(closeOverlay());
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let dialogTitle = "Are you sure?";
    if (this.props.dialogConfirm.title !== null) {
      dialogTitle = this.props.dialogConfirm.title;
    }

    let dialogMessage = this.props.dialogConfirm.message;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          {dialogMessage !== null && (<div className="padBottom20">{dialogMessage}</div>)}
          <ButtonAction name="yes" className="moveRight20" text="Yes" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.handleYes}/>
          <ButtonAction name="no" className="" color="black" text="No" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.handleNo}/>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogConfirm: state.dialogConfirm
  }
}

const DialogConfirmContainer = connect(mapStateToProps, null)(DialogConfirm);
export default DialogConfirmContainer;