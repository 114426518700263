import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Markets.css';
import './Common.css';
import GapCard from "./GapCard";
import TitleCard from "./TitleCard";
import ButtonAction from './ButtonAction';
import CategoryList from './CategoryList';
import PostingList from './PostingList';
import { changeInputValue, refreshMarketSections } from "./MarketsActions";
import { openOverlay } from './OverlayActions';
import Breadcrumb from './Breadcrumb';
import { getCategoryTree, navNewURL, getIsMobile, urlForBreadcrumb, getContentWidth } from "./ActionUtils";
import SearchCard from './SearchCard';
import CategoryBar from './CategoryBar';
import { openMenuList } from './HomePageActions';
import Link from './Link';
import { shared } from '../Shared';
import loadImg from '../load_blue.gif';
const NEW_POSTING_LABEL = "New posting";

class Markets extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleNewPosting = this.handleNewPosting.bind(this);
    this.openMenuList = this.openMenuList.bind(this);
    this.loadMoreResults = this.loadMoreResults.bind(this);
  }

  componentDidMount() {
    let {
      breadLocation="",
      breadType="",
      breadCategory="",
      searchQuery=""
    } = this.props.markets;

    this.props.dispatch(refreshMarketSections(breadLocation, breadType, breadCategory, searchQuery, ""));
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      breadLocation="",
      breadType="",
      breadCategory="",
      searchQuery="",
      postingsLoaded=false
    } = this.props.markets;

    if (
      breadLocation !== prevProps.markets.breadLocation || 
      breadType !== prevProps.markets.breadType || 
      breadCategory !== prevProps.markets.breadCategory || 
      searchQuery !== prevProps.markets.searchQuery ||
      (postingsLoaded === false && prevProps.markets.postingsLoaded === true)
    ) {
      this.props.dispatch(refreshMarketSections(breadLocation, breadType, breadCategory, searchQuery, ""));
    }
  }

  handleChange(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleSearch(name, value) {
    let { searchBox="" } = this.props.markets;
    this.props.dispatch(navNewURL('/postings?search=' + encodeURIComponent(searchBox)));
  }

  handleNewPosting(name, value) {
    this.props.dispatch(openOverlay("new_posting", {}));
  }

  openMenuList(event) {
    this.props.dispatch(openMenuList());
  }

  loadMoreResults(name, value) {
    let {
      breadLocation="",
      breadType="",
      breadCategory="",
      searchQuery="",
      paginationKey="",
      postingsLoaded=false,
      updatesLoaded=false
    } = this.props.markets;

    if (postingsLoaded && updatesLoaded) {
      this.props.dispatch(refreshMarketSections(breadLocation, breadType, breadCategory, searchQuery, paginationKey));
    }
  }
  
  render() {

    let {
      breadLocation="",
      breadType="",
      breadCategory="",
      searchQuery="",
      expandMore=false,
      postingList=[],
      paginationKey="",
      postingsLoaded=false,
      updatesLoaded=false
    } = this.props.markets; 

    let hasSearch = (searchQuery !== "");

    let marketTitleDisplay = "";
    let categoryList = getCategoryTree(breadLocation, breadType, breadCategory, true);
    
    if (!postingsLoaded) {
      postingList = [];
    }

    let categoriesLabel = "Categories";
    let postingsLabel = "Postings";
    if (breadLocation === "" && breadType === "" && breadCategory === "") {
      categoriesLabel = "Locations";
      postingsLabel = "All postings";
    }

    let totalPostingsInThisCategory = postingList.length;
    
    let sectionsListElem = [];
    if (postingsLoaded && postingList.length === 0) {
      // Skip
    }
    else {
      sectionsListElem.push(<GapCard key={"gap"} />);
  
      let titleLink = urlForBreadcrumb(breadLocation, breadType, breadCategory);
      sectionsListElem.push(
        <div key={"title"} className="marketCategoryTitle">
          <Link noTarget={true} className="marketlink moveRight10" href={titleLink}>{hasSearch ? "Postings for: " + searchQuery : postingsLabel}</Link> {/*<Link noTarget={true} className="underlinelink" href={titleLink}>See more</Link>*/}
        </div>
      );
  
      sectionsListElem.push(
        <div key={"postings"} className="moveTop10">
          <PostingList showLoad={!postingsLoaded} data={postingList} limitRows={false} rows={100000} />
        </div>
      );
    }

    if (sectionsListElem.length === 0) {
      sectionsListElem.push(
        <div key={"content"}className="contentCard">No postings {hasSearch ? "matching your search terms exist" : " exist for this category"}{breadLocation !== "" ? (" in " + shared.getBreadcrumbDisplay(breadLocation, "location")) : ""}.</div>
      );
    }

    let breadList = [];

    if (breadLocation !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadLocation, "location"), field: breadLocation, level: "location" }
      );
    }

    if (breadType !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadType, "type"), field: breadType, level: "type" }
      );  
    }

    if (breadCategory !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadCategory, "category"), field: breadCategory, level: "category" }
      );  
    }

    if (breadList.length > 0) {
      marketTitleDisplay = breadList[breadList.length - 1].display;
    }

    let isMobile = getIsMobile();
    let contentWidth = getContentWidth();
    let showCategoryBar = !isMobile && contentWidth > 0;
    let loggedin = this.props.auth.status === "loggedin";
    let moreResultsEnabled = postingsLoaded && updatesLoaded && paginationKey !== "";

    return (
      <div className="homeSection">
        <SearchCard 
          showMenuButton={!showCategoryBar}
          name="searchBox" 
          value={this.props.markets.searchBox} 
          onChange={this.handleChange}
          onSubmit={this.handleSearch}
          onMenu={this.openMenuList}
        />
        {showCategoryBar && (
          <CategoryBar location={breadLocation}/>
        )}
        {!hasSearch && <GapCard />}
        {!hasSearch && (loggedin || breadList.length > 0) && <div className="contentCard">
          {marketTitleDisplay !== "" && <div className="marketTitle">{marketTitleDisplay}</div>}
          {breadList.length > 0 ? <Breadcrumb noTarget={true} list={breadList} /> : ""}
          {loggedin && <ButtonAction name="posting" className={breadList.length > 0 ? "moveTop20 moveRight20" : "moveRight20"} text={NEW_POSTING_LABEL} onClick={this.handleNewPosting}/>}
        </div>}
        {!hasSearch && categoryList.length > 0 && breadType !== "other" && totalPostingsInThisCategory > 0 &&
          <div>
            <GapCard />
            <TitleCard title={categoriesLabel} />
            <div className="moveTop10">
              <CategoryList expandMore={expandMore} showMore={!expandMore} parent="home" data={categoryList} breadLocation={breadLocation} breadType={breadType} breadCategory={breadCategory} />
            </div>
          </div>
        }
        <GapCard />
        {sectionsListElem}
        {moreResultsEnabled && <div className="moveTop40" style={{textAlign: "center"}}>
          <ButtonAction link={true} disabled={!moreResultsEnabled} name="more_results" className="" text="More results" onClick={moreResultsEnabled ? this.loadMoreResults : null}/>
        </div>}
        {!moreResultsEnabled && !updatesLoaded && <div className="moveTop40" style={{textAlign: "center"}}><img className="loadIcon" src={loadImg} alt="Loading" /></div>}
        <div className="moveTop40"></div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    markets: state.markets
  }
}

const MarketsContainer = connect(mapStateToProps, null)(Markets);
export default MarketsContainer;