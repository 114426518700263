import { Buffer } from 'safe-buffer';
import utils from 'web3-utils';
import store from '../store';
import { shared } from '../Shared';

import { 
  eth_createAgreementA, 
  erc20_createAgreementA, 
  eth_depositB, 
  erc20_depositB, 
  eth_resolveAsParty, 
  erc20_resolveAsParty, 
  eth_resolveAsArbitrator, 
  erc20_resolveAsArbitrator, 
  eth_earlyWithdrawA, 
  erc20_earlyWithdrawA,
  eth_withdraw, 
  erc20_withdraw,
  eth_requestArbitration,
  erc20_requestArbitration,
  eth_withdrawDisputeFee,
  erc20_withdrawDisputeFee,
  eth_requestDefaultJudgment,
  erc20_requestDefaultJudgment,
  eth_requestAutomaticResolution,
  erc20_requestAutomaticResolution,
  eth_submitEvidence,
  erc20_submitEvidence,
  eth_getEvidence,
  erc20_getEvidence,
  eth_getMetaEvidence,
  erc20_getMetaEvidence
} from "./BlockchainUtils"

import { approveAllowance, apiRequestPost, getEthersJSContractForAgreement } from './ActionUtils';

const USE_PROXY = true;

export function reloadBlockchainSyncList() {
  return function(dispatch) {
    if (typeof window.syncList !== "undefined" && window.syncList.length > 0) {
      dispatch(updateBlockchainSyncList(window.syncList));
    }
  }
}

export function updateBlockchainSyncList(list) {
  return function(dispatch) {
    window.syncList = list;
    window.syncStarted = false;
    dispatch(checkBlockchainSync());
  }
}

export function initBlockchainFromCache(list) {
  return function(dispatch) {
    let combinedDataObj = {};
    for (let i = 0; i < list.length; i++) {
      let entryObj = list[i];
      let { 
        agreementid="",
        deployedid="",
        transactiontype="",
        transactionhash="",
        blockchain_data="",
        agreed_sha3=""
      } = entryObj;

      if (blockchain_data !== "" && agreed_sha3 !== "") {
        let state = JSON.parse(blockchain_data);
        let sha3 = agreed_sha3;
        combinedDataObj[agreementid] = {
          agreementid,
          deployedid,
          transactiontype,
          transactionhash,
          state,
          sha3
        };
      }
    }

    if (Object.keys(combinedDataObj).length > 0) {
      dispatch({
        type: 'BLOCKCHAIN_STATE_DATA',
        payload: combinedDataObj
      });  
    }
  }
}

export function checkBlockchainSync() {
  return function(dispatch) {
    if (window.ethersProvider && window.syncStarted === false) {
      //console.log("Sync starting");
      window.syncStarted = true;

      let list = window.syncList;
      runBlockchainSync(list, USE_PROXY).then((responseObj) => {
        let combinedDataObj = {};
        if (responseObj) {
          for (let i = 0; i < list.length; i++) {
            let entryObj = list[i];
            let { 
              agreementid="",
              deployedid="",
              transactiontype="",
              transactionhash=""
            } = entryObj;
            let key = transactiontype + "_" + deployedid;
            if (responseObj.hasOwnProperty(key)) {
              let state = responseObj[key];
              if (state !== null) {
                let { sha3="" } = state;

                combinedDataObj[agreementid] = {
                  agreementid,
                  deployedid,
                  transactiontype,
                  transactionhash,
                  state,
                  sha3
                };
              }
            }
            else {
              console.log("Missing blockchain key");
            }
          }

          if (list === window.syncList) {
            console.log("Blockchain data updated");

            dispatch({
              type: 'BLOCKCHAIN_STATE_DATA',
              payload: combinedDataObj
            });
  
            if (window.syncRemovePrompt === true) {
              window.syncRemovePrompt = false;
              dispatch({
                type: 'AGREEMENT_CLEAR_BLOCKCHAIN_TRANSACTION'
              });
            }
          }
          else {
            if (window.syncRemovePrompt === true) {
              window.syncRemovePrompt = false;
            }
            console.log("Skip state update");
          }
        }
      });
    }
    
  }
}

export async function runBlockchainSync(list, useProxy) {
  let mapObj = {};

  if (useProxy) {
    mapObj = await apiBlockchainProxy(list);
  }
  else {
    if (window.ethersProvider && list.length > 0) {
      let promiseStateList = [];
      for (let i = 0; i < list.length; i++) {
        let entryObj = list[i];
        promiseStateList.push(readBlockchainData(entryObj));
      }
      //console.log("Waiting ...");
      try {
        mapObj = await Promise.all(promiseStateList).then((responseList) => {
          let responseMap = {};
          for (let i = 0; i < list.length; i++) {
            let entryObj = list[i];
            let responseObj = responseList[i];
            let key = entryObj.transactiontype + "_" + entryObj.deployedid;
            responseMap[key] = responseObj;
          }
          return responseMap;
        });
        //console.log("... Done");
      }
      catch (err) {
        //console.log("... Error");
        //console.log(err);
      }
    }
  }

  return mapObj;
}

export async function readBlockchainData(entryObj) {
  let dataObj = {};
  let sha3 = "";

  let {
    deployedid = "", 
    transactiontype = "", 
    transactionhash = "",
    agreed_data = "",
    agreed_sha3 = "",
    user1_finalized = false
  } = entryObj;

  if (shared.CONTRACT_TYPE_LIST.includes(transactiontype)) {
    let agreedObj = null;
    if (agreed_data !== "") {
      agreedObj = JSON.parse(agreed_data);
    }
    
    let contract = getEthersJSContractForAgreement(transactiontype);
    let promiseList = [];
    promiseList.push(contract.getState(deployedid));
    promiseList.push(getSha3FromTxHash(transactionhash, deployedid));

    let responseList = await Promise.all(promiseList);
    if (responseList.length === 2) {
      let blockchainEntry = responseList[0];
      let sha3Obj = responseList[1];
      if (sha3Obj && sha3Obj.hasOwnProperty("sha3")) {
        sha3 = sha3Obj.sha3;
      }

      dataObj = shared.readBlockchainEntry(blockchainEntry, transactiontype, agreedObj, agreed_sha3, user1_finalized);
      dataObj.sha3 = sha3;
    }
  }
  
  return dataObj;
}

export async function apiBlockchainProxy(list) {
  let auth = store.getState().auth;
  let uuid = auth.uuid;
  let username = auth.username;

  let agreementIdList = [];
  for (let i = 0; i < list.length; i++) {
    let { agreementid="" } = list[i];
    if (agreementid !== "") {
      agreementIdList.push({agreementid});
    }
  }

  let resp = await apiRequestPost("blockchain_proxy", { 
    uuid, 
    username
  }, agreementIdList).then((response) => {
    if (response && response.hasOwnProperty("data") && response.status === "success") {
      return response.data;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return resp;
}

export async function getSha3FromTxHash(transactionhash, deployedid) {
  if (transactionhash !== "" && window.ethersProvider) {
    try {
      let receipt = await window.ethersProvider.waitForTransaction(transactionhash);
      if (receipt && receipt.hasOwnProperty("status")) {
        if (receipt.status) {
          let sha3 = shared.readSha3FromReceipt(receipt, deployedid);
          return { deployedid, sha3, status: true };
        }
      }

      return { status: false };
    }
    catch (err) {
      console.log("Error fetching tx [*]");
    }
  }  

  return null;
}

export function approveAllowanceForAgreement(tokenType, tokenAmount, contractType) {
  return function(dispatch) {
    if(tokenType === "eth"){
      console.log("WARNING: tokenType was ETH in approveAllowanceForAgreement");
    }else{
      approveAllowance(tokenType, tokenAmount, contractType, dispatch).then((hasAllowance) => {
        console.log("Allowance approved");
      });
    }
  }
}

export function deployNewAgreement(youAreUser1, youAreUser2, agreedSha3, metaevidenceHash, agreedData) {
  return function(dispatch) {

    /*let auth = store.getState().auth;
    if (auth.ethNetwork !== "4") {
      alert("Deploying to mainnet is currently disabled.");
      return;
    }*/
    
    let agreedDataObj = JSON.parse(agreedData);
    let partyAStakeType = agreedDataObj.amount_type1;
    let partyBStakeType = agreedDataObj.amount_type2;
    let arbitratorFeeType = agreedDataObj.arbitration_fee_type;

    if (youAreUser1 || youAreUser2) {
      if (
        partyAStakeType === "eth" && 
        partyBStakeType === "eth" && 
        arbitratorFeeType === "eth"
      ) {
        let agreementHash = agreedSha3;
        let agreementid = agreedDataObj.agreementid;
        let partyA = agreedDataObj.user1_address;
        let partyB = agreedDataObj.user2_address;
        let arbiter = agreedDataObj.arbitrator_address;
        let partyAStakeAmount = agreedDataObj.amount_raw1;
        let partyBStakeAmount = agreedDataObj.amount_raw2;
        let partyAInitialArbitratorFee = "0";
        let partyBInitialArbitratorFee = "0";
        let disputeFee = agreedDataObj.arbitration_fee_raw;
        //TODO: Eliminate toString
        let automaticResolution = shared.addBN(agreedDataObj.default_resolution_type1_give1.toString(), agreedDataObj.default_resolution_type2_give1.toString(), "deployNewAgreement");
        let nDaysToRespondToArbitrationRequest = agreedDataObj.arbitration_days;
        let arbitrationRequestAllowedAfterTimestamp = agreedDataObj.arbitration_request_unix;
        let autoResolveAfterTimestamp = agreedDataObj.auto_resolve_unix;

        if (youAreUser2) {
          partyA = agreedDataObj.user2_address;
          partyB = agreedDataObj.user1_address;
          arbiter = agreedDataObj.arbitrator_address;
          partyAStakeAmount = agreedDataObj.amount_raw2;
          partyBStakeAmount = agreedDataObj.amount_raw1;
          //TODO: Eliminate toString
          automaticResolution = shared.addBN(agreedDataObj.default_resolution_type1_give2.toString(), agreedDataObj.default_resolution_type2_give2.toString(), "deployNewAgreement");
        }

        let contractType = "simple_eth";
        eth_createAgreementA(
          agreementid,
          contractType,
          agreementHash, 
          metaevidenceHash, 
          partyA, partyB, arbiter, 
          partyAStakeAmount, partyBStakeAmount, partyAInitialArbitratorFee, partyBInitialArbitratorFee, disputeFee, automaticResolution, 
          nDaysToRespondToArbitrationRequest, arbitrationRequestAllowedAfterTimestamp, autoResolveAfterTimestamp
        );
      }
      else {

        let agreementHash = agreedSha3;
        let agreementid = agreedDataObj.agreementid;
        let partyA = agreedDataObj.user1_address;
        let partyB = agreedDataObj.user2_address;
        let arbiter = agreedDataObj.arbitrator_address;
        let tokenA = agreedDataObj.address_token1;
        let tokenB = agreedDataObj.address_token2;
        let tokenAType = agreedDataObj.amount_type1;
        let arbitratorToken = agreedDataObj.address_token_arbitration;

        let partyAStakeAmount = agreedDataObj.amount_raw1;
        let partyBStakeAmount = agreedDataObj.amount_raw2;
        let partyAInitialArbitratorFee = "0";
        let partyBInitialArbitratorFee = "0";
        let disputeFee = agreedDataObj.arbitration_fee_raw;
        let automaticResolutionTokenA = agreedDataObj.default_resolution_type1_give1;
        let automaticResolutionTokenB = agreedDataObj.default_resolution_type2_give1;
        let nDaysToRespondToArbitrationRequest = agreedDataObj.arbitration_days;
        let arbitrationRequestAllowedAfterTimestamp = agreedDataObj.arbitration_request_unix;
        let autoResolveAfterTimestamp = agreedDataObj.auto_resolve_unix;

        let partyATokenPower = agreedDataObj.amount_power1;
        let partyBTokenPower = agreedDataObj.amount_power2;
        let arbitratorTokenPower = agreedDataObj.arbitration_fee_power;

        if (youAreUser2) {
          partyA = agreedDataObj.user2_address;
          partyB = agreedDataObj.user1_address;
          arbiter = agreedDataObj.arbitrator_address;
          partyAStakeAmount = agreedDataObj.amount_raw2;
          partyBStakeAmount = agreedDataObj.amount_raw1;
          tokenA = agreedDataObj.address_token2;
          tokenB = agreedDataObj.address_token1;
          tokenAType = agreedDataObj.amount_type2;
          partyATokenPower = agreedDataObj.amount_power2;
          partyBTokenPower = agreedDataObj.amount_power1;
          automaticResolutionTokenA = agreedDataObj.default_resolution_type2_give2;
          automaticResolutionTokenB = agreedDataObj.default_resolution_type1_give2;
        }

        let contractType = "simple_erc20";
        approveAllowance(tokenAType, partyAStakeAmount, contractType, dispatch).then((hasAllowance) => {
          if (hasAllowance) {
            return erc20_createAgreementA(
              agreementid,
              contractType,
              agreementHash, 
              metaevidenceHash, 
              partyA, partyB, arbiter, tokenA, tokenB, arbitratorToken, 
              partyAStakeAmount, partyBStakeAmount, partyAInitialArbitratorFee, partyBInitialArbitratorFee, disputeFee, automaticResolutionTokenA, automaticResolutionTokenB, nDaysToRespondToArbitrationRequest, arbitrationRequestAllowedAfterTimestamp, autoResolveAfterTimestamp, partyATokenPower, partyBTokenPower, arbitratorTokenPower
            );
          }
          else {
            return null;
          }
        });
      }
    }
  }
}

export function deposit(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyB=false,
      agreementid=""  
    } = youAreObj;

    // Only party B can call deposit
    if ((youAreUser1 || youAreUser2) && youArePartyB) {
      let agreedDataObj = JSON.parse(agreedData);
      let stakeAmount = agreedDataObj.amount_raw1;
      let tokenType = agreedDataObj.amount_type1;
      if (youAreUser2) {
        stakeAmount = agreedDataObj.amount_raw2;
        tokenType = agreedDataObj.amount_type2;
      }

      if (shared.contractInterfaceETH(contractType)) {
        eth_depositB(
          agreementid,
          contractType,
          deployedid,
          stakeAmount
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {

        let stakeAmountETH = "0";
        if (tokenType === "eth") {
          stakeAmountETH = stakeAmount;
        }

        erc20_depositB(
          agreementid,
          contractType,
          deployedid,
          stakeAmountETH
        );
      }
    }
  }
}

export function resolveAsParty(youAreObj, deployedid, resolutionValueETH, resolutionValueERC20, agreedData, contractType) {
  return async function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid=""  
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)  && resolutionValueETH !== "" && resolutionValueERC20 !== "") {

      if (shared.contractInterfaceETH(contractType)) {
        let resolutionWei = resolutionValueETH;

        eth_resolveAsParty(
          agreementid,
          contractType,
          deployedid,
          resolutionWei
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        let resolutionValueSplit = resolutionValueERC20.split("_");
        if (resolutionValueSplit.length > 1) {
          let resTokenA = resolutionValueSplit[0];
          let resTokenB = resolutionValueSplit[1];

          erc20_resolveAsParty(
            agreementid,
            contractType,
            deployedid,
            resTokenA,
            resTokenB
          );
        }
      }
    }
  }
}

export function resolveAsArbitrator(youAreObj, deployedid, resolutionValueETH, resolutionValueERC20, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreArbitrator=false,
      agreementid=""
    } = youAreObj;

    if (youAreArbitrator && resolutionValueETH !== "" && resolutionValueERC20 !== "") {

      if (shared.contractInterfaceETH(contractType)) {
        let resolutionWei = resolutionValueETH;

        eth_resolveAsArbitrator(
          agreementid,
          contractType,
          deployedid,
          resolutionWei
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        let resolutionValueSplit = resolutionValueERC20.split("_");
        let resTokenA = resolutionValueSplit[0];
        let resTokenB = resolutionValueSplit[1];

        erc20_resolveAsArbitrator(
          agreementid,
          contractType,
          deployedid,
          resTokenA,
          resTokenB
        );
      }
    }
  }
}

export function earlyWithdraw(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false,
      agreementid=""  
    } = youAreObj;

    // Only party A can early withdraw
    if ((youAreUser1 || youAreUser2) && youArePartyA) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_earlyWithdrawA(
          agreementid,
          contractType,
          deployedid
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_earlyWithdrawA(
          agreementid,
          contractType,
          deployedid
        );
      }
    }
  }
}

export function withdraw(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid="" 
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_withdraw(
          agreementid,
          contractType,
          deployedid
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_withdraw(
          agreementid,
          contractType,
          deployedid
        );
      }
    }
  }
}

export function requestArbitration(youAreObj, deployedid, agreedData, contractType, disputeFee, arbitrationFeeType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid="" 
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)) {
      if (shared.contractInterfaceETH(contractType)) {
        let disputeFeeEth = disputeFee;
        eth_requestArbitration(
          agreementid,
          contractType,
          deployedid,
          disputeFeeEth
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        let disputeFeeEth = "0";
        if (arbitrationFeeType === "eth") {
          disputeFeeEth = disputeFee;
        }
        erc20_requestArbitration(
          agreementid,
          contractType,
          deployedid,
          disputeFeeEth
        );
      }
    }
  }
}

export function withdrawDisputeFee(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreArbitrator=false,
      agreementid=""
    } = youAreObj;

    if (youAreArbitrator) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_withdrawDisputeFee(
          agreementid,
          contractType,
          deployedid
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_withdrawDisputeFee(
          agreementid,
          contractType,
          deployedid
        );
      }
    }
  }
}

export function requestDefaultJudgment(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid=""  
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_requestDefaultJudgment(
          agreementid,
          contractType,
          deployedid
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_requestDefaultJudgment(
          agreementid,
          contractType,
          deployedid
        );
      }
    }
  }
}

export function requestAutomaticResolution(youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid=""  
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_requestAutomaticResolution(
          agreementid,
          contractType,
          deployedid
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_requestAutomaticResolution(
          agreementid,
          contractType,
          deployedid
        );
      }
    }
  }
}

export function submitEvidence(evidence, youAreObj, deployedid, agreedData, contractType) {
  return function(dispatch) {

    let { 
      youAreUser1=false, 
      youAreUser2=false, 
      youArePartyA=false, 
      youArePartyB=false,
      agreementid=""
    } = youAreObj;

    if ((youAreUser1 || youAreUser2) && (youArePartyA || youArePartyB)) {

      if (shared.contractInterfaceETH(contractType)) {
        eth_submitEvidence(
          agreementid,
          contractType,
          deployedid,
          evidence
        );
      }
      else if (shared.contractInterfaceERC20(contractType)) {
        erc20_submitEvidence(
          agreementid,
          contractType,
          deployedid,
          evidence
        );
      }
    }
  }
}
  
export function syncBlockchainEvidence(agreementid, deployedid, arbitratorAddress, contractType) {
  return function(dispatch) {    
    if (shared.contractInterfaceETH(contractType)) {
      eth_getEvidence(
        contractType,
        deployedid,
        arbitratorAddress
      ).then((evidenceArr) => {
        dispatch({
          type: 'BLOCKCHAIN_EVIDENCE',
          payload: {agreementid, deployedid, arbitratorAddress, evidenceArr}
        });
        dispatch({
          type: 'AGREEMENT_CLEAR_EVIDENCE_TRANSACTION'
        });
      });

      eth_getMetaEvidence(
        contractType,
        deployedid
      ).then((evidenceArr) => {
        console.log("getMetaEvidence");
        console.log(evidenceArr);
      });
    }
    else if (shared.contractInterfaceERC20(contractType)) {
      erc20_getEvidence(
        contractType,
        deployedid,
        arbitratorAddress
      ).then((evidenceArr) => {
        dispatch({
          type: 'BLOCKCHAIN_EVIDENCE',
          payload: {agreementid, deployedid, arbitratorAddress, evidenceArr}
        });
        dispatch({
          type: 'AGREEMENT_CLEAR_EVIDENCE_TRANSACTION'
        });
      });

      erc20_getMetaEvidence(
        contractType,
        deployedid
      ).then((evidenceArr) => {
        console.log("getMetaEvidence");
        console.log(evidenceArr);
      });
    }
  }
}

export function getMultihash(str) {
  let sha3hash = utils.sha3(str);
  sha3hash = "1620" + sha3hash.replace("0x", "");
  let sha3buf = new Buffer(sha3hash, "hex");
  const Base58 = require("base-58");
  let multihash = Base58.encode(sha3buf);
  return multihash;
}