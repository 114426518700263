import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogMessage.css';
import { changeInputValue } from './DialogMessageActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay } from './OverlayActions'
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';

class DialogMessage extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let title = "";
    if (this.props.dialogMessage.title !== null) {
      title = this.props.dialogMessage.title;
    }

    let message = this.props.dialogMessage.message;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={title} />
          {message !== null && (<div className="padBottom20">{message}</div>)}
          <ButtonAction name="ok" minWidth={DEFAULT_BUTTON_WIDTH} className="" text="OK" onClick={this.handleOk}/>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogMessage: state.dialogMessage
  }
}

const DialogMessageContainer = connect(mapStateToProps, null)(DialogMessage);
export default DialogMessageContainer;