import update from 'immutability-helper';
import { shared } from '../Shared';

const emptyState = {
  contactList: [],
  favoriteList: [],
  contactsLoaded: false, 
  arbitratorList: [],
  arbitratorsLoaded: false,
  searchAutosuggest: {
    search: "all",
    input: "",
    results: [],
    selected: null,
    selectedArbitration: null,
    more: false,
    match: {},
    init: false
  }
}

const PeopleReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let offset = "0";
      if (query && Object.prototype.hasOwnProperty.call(query, "offset")) {
        offset = query.offset;
      }

      let count = "50";
      if (query && Object.prototype.hasOwnProperty.call(query, "count")) {
        count = query.count;
      }

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "people") {
        return {...state, 
          ...emptyState,
          offset,
          count
        };
      }

      // Empty everything if page not selected
      return {};
    }
    case "PEOPLE_CONTACT_LIST" : {
      let { results=[], favorites=[] } = action.payload;
      return {...state, contactList: results, favoriteList: favorites, contactsLoaded: true };
    }
    case "PEOPLE_ARBITRATOR_LIST" : {
      let { list=[] } = action.payload;
      return {...state, arbitratorList: list, arbitratorsLoaded: true };
    }
    case "PEOPLE_CHANGE_AUTOSUGGEST_INPUT" : {
      let { name, value, search } = action.payload;

      if (value.length > shared.MAX_IDENTIFIER_LENGTH) {
        value = value.substring(0, shared.MAX_IDENTIFIER_LENGTH);
      }

      let newSelected = state[name].selected;
      if (state[name].input !== value) {
        newSelected = null;
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {input: value, selected: newSelected, search: search}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "PEOPLE_CHANGE_AUTOSUGGEST_RESULTS" : {
      let { name, results, more, match, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: results, more: more, match: match, init: true}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "PEOPLE_CLEAR_AUTOSUGGEST_RESULTS" : {
      let { name, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: [], more: false, match: {}, init: false}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "PEOPLE_AUTOSUGGEST_FAVORITE" : {
      let { name="", contact="", favoriteSelected=false, favoritePending=false } = action.payload;            
      
      let hasUpdate = false;
      let resultList = state[name].results || [];
      for (let i = 0; i < resultList.length; i++) {
        let result = resultList[i];
        if (result.value === contact) {
          let newResultObj = JSON.parse(JSON.stringify(result));
          newResultObj.favoriteSelected = favoriteSelected;
          newResultObj.favoritePending = favoritePending;

          resultList = update(
            resultList || [], 
            {[i] : {$set: newResultObj}}
          );

          hasUpdate = true;

          break;
        }
      }

      if (hasUpdate) {
        let newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: resultList}}
        );
        
        return {...state, [name]: newAutosuggest};  
      }

      return {...state};
    }
    default: {
      return state
    }
  }
}

export default PeopleReducer