import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogBlockchainDeposit.css';
import { changeInputValue } from './DialogBlockchainDepositActions';
import { deployAgreement, implicitSelectDeployAgreement } from './AgreementActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { summaryFromAgreementBlockchain, hasEnoughFunds, getUserLinkElement, track, getTestModeFromStore } from "./ActionUtils";
import { shared } from "../Shared";
import Link from './Link';
import { 
  deposit,
  approveAllowanceForAgreement
} from './BlockchainActions';
import loadImg from '../load_blue.gif';
import moment from 'moment-timezone';

class DialogBlockchainDeposit extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.deploy = this.deploy.bind(this);
    this.deposit = this.deposit.bind(this);
    this.implicitDeploy = this.implicitDeploy.bind(this);
    this.handleApproveAllowanceForDeposit = this.handleApproveAllowanceForDeposit.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  deploy(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"submit_deposit_deploy", dialog:"blockchain_deposit"})));
    let { youAreUser1=false, youAreUser2=false, agreementid="", agreedSha3="", metaevidenceHash="", agreedData="" } = this.props.agreement;
    if (agreedSha3 !== "" && agreedData !== "" && (youAreUser1 || youAreUser2)) {
      this.props.dispatch(deployAgreement(youAreUser1, youAreUser2, agreementid, agreedSha3, metaevidenceHash, agreedData));
    }
  }

  implicitDeploy(name, value) {

    let { youAreUser1=false, youAreUser2=false, agreementid="", versionid="", agreedSha3="", metaevidenceHash="", agreedData="", user1Locked="", user2Locked="" } = this.props.agreement;

    let counterPartyLocked = user2Locked;
    if (youAreUser2) {
      counterPartyLocked = user1Locked;
    }

    this.props.dispatch(track("action", "button", JSON.stringify({type:"submit_deposit_deploy", dialog:"blockchain_deposit", implicit_select: true})));
    if (youAreUser1 || youAreUser2) {
      this.props.dispatch(implicitSelectDeployAgreement(youAreUser1, youAreUser2, agreementid, versionid, agreedSha3, metaevidenceHash, agreedData, counterPartyLocked));
    }

    //console.log("implicitDeploy");
  }

  deposit(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"submit_deposit_existing", dialog:"blockchain_deposit"})));
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;

    this.props.dispatch(deposit(blockchainDataObj, deployedid, agreedData, contractType));
  }

  handleApproveAllowanceForDeposit(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"approve_allowance", dialog:"blockchain_deposit"})));

    let { user1ContributionType="", user2ContributionType="", user1ContributionInput="", user2ContributionInput="", agreedSha3="", agreedData="", youAreUser1=false, youAreUser2=false, versionContractType="" } = this.props.agreement;
    if (youAreUser1 || youAreUser2) {
      let stakeType = youAreUser1 ? user1ContributionType : user2ContributionType;
      let stakeAmount = youAreUser1 ? user1ContributionInput : user2ContributionInput;
      let stakeAmountRaw = shared.rawValueForToken(stakeAmount, stakeType);

      if (agreedSha3 !== "" && agreedData !== "") {
        let agreedDataObj = JSON.parse(agreedData);
        stakeType = youAreUser1 ? agreedDataObj.amount_type1 : agreedDataObj.amount_type2;
        stakeAmountRaw = youAreUser1 ? agreedDataObj.amount_raw1 : agreedDataObj.amount_raw2;  
      }

      //console.log("stakeType: " + stakeType + ", stakeAmount: " + stakeAmountRaw);
      this.props.dispatch(approveAllowanceForAgreement(stakeType, stakeAmountRaw, versionContractType));
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let {
      youAreUser1=false,
      youAreUser2=false,
      user1Locked="", 
      user2Locked="",
      user1ContributionType="",
      user2ContributionType="",
      user1ContributionInput="",
      user2ContributionInput="",
      versionContractType="",
      user1Disp="",
      user2Disp=""
    } = this.props.agreement;


    let counterPartyLink = "<none>";
    let counterPartyLinkUC = "<none>";
    if (youAreUser1) {
      if (user2Locked) {
        counterPartyLink = getUserLinkElement(user2Locked, user2Disp); 
        counterPartyLinkUC = getUserLinkElement(user2Locked, shared.firstLetterUpperCase(user2Disp)); 
      } else {
        counterPartyLink = user2Disp;
        counterPartyLinkUC = shared.firstLetterUpperCase(user2Disp);
      }
    } else if (youAreUser2) {
      if (user1Locked) {
        counterPartyLink = getUserLinkElement(user1Locked, user1Disp); 
        counterPartyLinkUC = getUserLinkElement(user1Locked, shared.firstLetterUpperCase(user1Disp));
      } else {
        counterPartyLink = user1Disp;
        counterPartyLinkUC = shared.firstLetterUpperCase(user1Disp);
      }
    }

    let contributionType = user1ContributionType;
    let contributionAmount = user1ContributionInput;
    if (youAreUser2) {
      contributionType = user2ContributionType;
      contributionAmount = user2ContributionInput;
    }
    
    let action = this.props.dialogBlockchainDeposit.action;
    let dialogTitle = (action === "implicit_finalize") ? "Deposit funds without approval" : "Deposit funds";
    let hasAllowance = false;
    let hasBalance = false;
    let hasETH = false;
    let waitingForApproveAllowance = this.props.dialogBlockchainDeposit.waitingForApproveAllowance;
    let allowanceApprovalTransactionComplete = this.props.dialogBlockchainDeposit.allowanceApprovalTransactionComplete;
    let waitingApproveAllowanceHash = this.props.dialogBlockchainDeposit.waitingApproveAllowanceHash;
    let nowTimestampUnix = moment().tz('America/Los_Angeles').unix();
    let waitingTimestampUnix = this.props.dialogBlockchainDeposit.waitingTimestampUnix;

    // Don't wait for allowance approval if already detected
    if (hasAllowance || nowTimestampUnix > waitingTimestampUnix + 30) {
      waitingForApproveAllowance = false;
    }
    
    let amountStr = shared.multiplyDecimals(contributionAmount, shared.decimalsForToken(contributionType));
    let fundsObj = hasEnoughFunds(versionContractType, amountStr, contributionType);
    hasAllowance = fundsObj.hasAllowance;
    hasBalance = fundsObj.hasBalance;
    hasETH = fundsObj.hasETH;

    let insufficientFundsMessage = "";
    if (!hasBalance && !hasETH && contributionType !== "eth") {
      insufficientFundsMessage = <span>You have insufficient funds to pay the deposit, and you don't have enough ETH to pay any transaction fees.</span>;
    } else if (!hasBalance) {
      insufficientFundsMessage = <span>You have insufficient funds to pay the deposit.</span>;
    } else if (!hasETH) {
      insufficientFundsMessage = <span>You have insufficient ETH to pay the transaction fee.</span>;
    }

    if (allowanceApprovalTransactionComplete) {
      hasAllowance = true;
    }

    let hasBalanceAndETH = hasBalance && hasETH;
    let hasBalanceAndETHAndAllowance = hasBalanceAndETH && hasAllowance;

    let depositBtnElem =
    <div className="moveTop10">
      {hasBalanceAndETHAndAllowance && action === "implicit_finalize" && <ButtonAction name="deploy" className="moveRight20" text="Submit deposit without approval" onClick={this.implicitDeploy}/>}
      {hasBalanceAndETHAndAllowance && action === "finalize" && <ButtonAction name="deploy" className="moveRight20" text="Submit deposit" onClick={this.deploy}/>}
      {hasBalanceAndETHAndAllowance && action === "deposit" && <ButtonAction name="deposit" className="moveRight20" text="Submit deposit" onClick={this.deposit}/>}
      {!hasBalanceAndETHAndAllowance && <ButtonAction disabled={true} name="deposit_placeholder" className="moveRight20" text={action === "implicit_finalize" ? "Submit deposit without approval" : "Submit deposit"} onClick={null}/>}
    </div>;

    let transactionLinkElement = waitingApproveAllowanceHash === "" ? "transaction" : <Link className="bluelink" target="_blank" rel="noopener noreferrer" href={shared.getTransactionLink(getTestModeFromStore(), waitingApproveAllowanceHash)}>{"transaction"}</Link>;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          {(youAreUser1 || youAreUser2) ? (
            <div>
              {waitingForApproveAllowance ? (
                <div className="contentLabel">
                  <div style={{lineHeight:"18px"}}>{"Giving permission to transfer " + contributionType.toUpperCase() + " from your wallet. The "}{transactionLinkElement}{" should take about fifteen seconds."}</div>
                  <div><img style={{position:"relative", left:"-8px"}} className="loadIcon" src={loadImg} alt="Loading" /></div>
                </div>
              ) : (
                <div>
                  {
                      action === "implicit_finalize" ? 
                      <div>
                        <div>{counterPartyLinkUC} has not yet approved this payment. If you know that the payment terms are acceptable to {counterPartyLink} you can deposit {contributionType === "eth" ? contributionAmount + " " + contributionType.toUpperCase() : ""} now.</div>
                        {!hasBalanceAndETH && <div className="moveTop20">{insufficientFundsMessage}</div>}
                      </div> : ""
                  }
                  {contributionType === "eth" ? depositBtnElem : (
                    <div>
                      <div className="moveTop20">{"1. Give permission to the agreement contract to transfer " + contributionType.toUpperCase() + " from your wallet."}</div>
                      {waitingApproveAllowanceHash !== "" && !hasAllowance && <div className="moveTop20">Your previous {transactionLinkElement} hasn't been confirmed yet. Either wait longer or try giving permission again with a higher fee.</div>}
                      <div className="moveTop10">
                        {!hasAllowance && hasETH && <ButtonAction name="allowance" className="moveRight20" text={"Give permission"} onClick={this.handleApproveAllowanceForDeposit}/>}
                        {!hasAllowance && !hasETH && <ButtonAction disabled={true} name="allowance" className="moveRight20" text={"Give permission"} onClick={null}/>}
                        {hasAllowance && <ButtonAction disabled={true} name="allowance" className="moveRight20" text={"Permission granted"} onClick={null}/>}
                      </div>
                      <div className="moveTop20">{"2. Deposit " + contributionAmount + " " + contributionType.toUpperCase() + " into the agreement contract."}</div>
                      {depositBtnElem}
                    </div>
                  )}
                </div>
              )}
            </div>
            ) : (<div>You are not a participant in this agreement.</div>)}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogBlockchainDeposit: state.dialogBlockchainDeposit
  }
}

const DialogBlockchainDepositContainer = connect(mapStateToProps, null)(DialogBlockchainDeposit);
export default DialogBlockchainDepositContainer;