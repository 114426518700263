import { getIsProfileOwnAccount, apiGetAgreementList } from "./ActionUtils"
import { updateBlockchainSyncList, initBlockchainFromCache } from "./BlockchainActions"
import store from '../store';

export function refreshAgreementList() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;

    let {
      selectedUser="",
      selectedFilter="",
      offset="",
      count="",
    } = store.getState().profileHeader;

    let ownAccount = getIsProfileOwnAccount();

    if (ownAccount) {
      apiGetAgreementList(uuid, username, username, selectedFilter, offset, count, "private").then((list) => {
        dispatch(processAgreementList(list));
      });  
    }
    else {
      let maxVisibility = "public";
      let filter = "visible_to_you";
      if (selectedFilter === "visible_to_you" || selectedFilter === "") {
        maxVisibility = "private";
        filter = "visible_to_you";
      }
      else if (selectedFilter === "with_you") {
        maxVisibility = "private";
        filter = "with_you";
      }
      else if (selectedFilter === "public") {
        maxVisibility = "public";
        filter = "";
      }
  
      apiGetAgreementList(uuid, username, selectedUser, filter, offset, count, maxVisibility).then((list) => {
        dispatch(processAgreementList(list));
      });
    }
  }
}

export function processAgreementList(list) {
  return function(dispatch) {
    dispatch({
      type: 'DASHBOARD_AGREEMENT_LIST',
      payload: {list}
    });
    
    let syncList = [];
    if (list) {
      for (let i = 0; i < list.length; i++) {
        let entry = list[i];          
        let { agreementid="", deployedid="", status="", transactiontype="", transactionhash="", agreed_data="", agreed_sha3="", finalized_by="", user1="", blockchain_data="" } = entry.agreement;
        if (deployedid !== "" && status === "finalized") {
          syncList.push({
            agreementid, 
            deployedid, 
            transactiontype, 
            transactionhash,
            agreed_data,
            agreed_sha3,
            user1_finalized: (finalized_by === user1),
            blockchain_data
          });
        }
      }
    }

    dispatch(initBlockchainFromCache(syncList));
    dispatch(updateBlockchainSyncList(syncList));
  }
}

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DASHBOARD_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}