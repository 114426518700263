import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Agreement.css';
import { 
  changeInputValue, 
  changeAutosuggestInput, 
  changeAutosuggestResults, 
  clearAutosuggestResults, 
  changeAutosuggestSelection, 
  createNewAgreement, 
  createNewVersion, 
  selectVersion, 
  unselectVersion, 
  hideAgreement, 
  unhideAgreement, 
  changeArbitratorStatus, 
  refreshAgreement,
  refreshComments,
  refreshMyPendingTransactions,
  createComment,
  deployAgreement,
  changeArbitrationMode,
  executeAgreementAssertions,
  handleAutosuggestFavorite,
  checkForReplacementDeployTransaction,
  clearLockOnAgreement 
} from './AgreementActions';
import { getStatusMessageAndActions, firstLetterUpperCase } from './AgreementMessageUtils';
import GapCard from './GapCard';
import LoadCard from './LoadCard';
import PermissionDeniedCard from './PermissionDeniedCard';
import WrongNetworkCard from './WrongNetworkCard';
import TitleCard from './TitleCard';
import StatusCard from './StatusCard';
import CommentCard from './CommentCard';
import Dropdown from './Dropdown';
import ButtonSelect from './ButtonSelect';
import ButtonAction from './ButtonAction';
import InputDatePicker from './InputDatePicker';
import InputAutosuggest from './InputAutosuggest';
import InputSelectedUser from './InputSelectedUser';
import InputBasic from './InputBasic';
import InputTextarea from './InputTextarea';
import QuestionTooltip from './QuestionTooltip';
import TwoColumnContainer from './TwoColumnContainer';
import { openOverlay, openOverlayDepositWarning, shouldShowDepositWarning } from './OverlayActions';
import { requireConfirmNav } from './AuthActions';
import moment from 'moment-timezone';
import { 
  navNewURL,
  pageNotLoaded, 
  summaryFromAgreementBlockchain, 
  upperCase,
  hasAllowance,
  getPriceMessageWidthCutoff,
  getUserLinkElement,
  getIsTestAgreement,
  apiEvidenceURL,
  track,
  addCommasToNumber, 
  getVisibilityTooltipContent,
  getTestModeFromStore,
  getTextWidth,
  getDropdownVisibilityList,
  getDefaultArbitrationBullets
} from "./ActionUtils";
import { 
  DEFAULT_BUTTON_WIDTH, 
  BUTTON_TOGGLE_FONT, 
  BUTTON_TOGGLE_EXTRA_PIXELS,
  BUTTON_ACTION_FONT,
  BUTTON_ACTION_EXTRA_PIXELS,
 } from './Constants.js';
import { shared } from '../Shared';
import { 
  earlyWithdraw,
  deposit,
  withdraw,
  withdrawDisputeFee,
  requestDefaultJudgment,
  requestAutomaticResolution,
  submitEvidence,
  syncBlockchainEvidence
} from './BlockchainActions';
import { getDropdownTokenList } from './TokenUtils';
import loadImg from '../load_blue.gif';
import warningImg from '../exclamation.png';
import visibilityImg from '../eye.png';
import commentsImg from '../comments2.png';
import jumpUpImg from '../jump_up_arrow_grey.png';
import versionsImg from '../versions.png';
import downloadImg from '../download2.png';
import tackImg from "../tack2.svg";
import Link from './Link';

const ARB_ACCEPT_LABEL = "Yes";
const ARB_REJECT_LABEL = "No";
const ARB_UNDECIDED_LABEL = "Undecided";

const CREATE_CONTRACT_LABEL = "Create contract";
const CREATE_PAYMENT_LABEL = "Create payment";
const CREATE_REQUEST_LABEL = "Create request";

const CREATE_VERSION_LABEL = "Submit edit";
const CANCEL_VERSION_LABEL = "Cancel edit";

class Agreement extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.hideAgreement = this.hideAgreement.bind(this);
    this.unhideAgreement = this.unhideAgreement.bind(this);
    this.createAgreement = this.createAgreement.bind(this);
    this.cancelNew = this.cancelNew.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.comment = this.comment.bind(this);
    this.editVersion = this.editVersion.bind(this);
    this.createVersion = this.createVersion.bind(this);
    this.selectVersion = this.selectVersion.bind(this);
    this.unselectVersion = this.unselectVersion.bind(this);
    this.unselectEditVersion = this.unselectEditVersion.bind(this);
    this.viewUser1Version = this.viewUser1Version.bind(this);
    this.viewUser2Version = this.viewUser2Version.bind(this);
    this.finalize = this.finalize.bind(this);
    this.deposit = this.deposit.bind(this);
    this.withdrawAndCancel = this.withdrawAndCancel.bind(this);
    this.arbitrationAgree = this.arbitrationAgree.bind(this);
    this.arbitrationReject = this.arbitrationReject.bind(this);
    this.arbitrationPending = this.arbitrationPending.bind(this);
    this.resolve = this.resolve.bind(this);
    this.arbitrationRequest = this.arbitrationRequest.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.withdrawDisputeFee = this.withdrawDisputeFee.bind(this);
    this.requestDefaultJudgment = this.requestDefaultJudgment.bind(this);
    this.requestAutomaticResolution = this.requestAutomaticResolution.bind(this);
    this.refresh = this.refresh.bind(this);
    this.showComments = this.showComments.bind(this);
    this.jumpToTop = this.jumpToTop.bind(this);
    this.showVersionsDialog = this.showVersionsDialog.bind(this);
    this.showPosting = this.showPosting.bind(this);
    this.showVisibilityDialog = this.showVisibilityDialog.bind(this);
    this.changeAddressDialog = this.changeAddressDialog.bind(this);
    this.viewAddressDialog = this.viewAddressDialog.bind(this);
    this.handleVersionClick = this.handleVersionClick.bind(this);
    this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.changeArbitrationMode = this.changeArbitrationMode.bind(this);
    this.getPriceMessage = this.getPriceMessage.bind(this);
    this.autosuggestChangeInput = this.autosuggestChangeInput.bind(this);
    this.autosuggestChangeResults = this.autosuggestChangeResults.bind(this);
    this.autosuggestChangeSelection = this.autosuggestChangeSelection.bind(this);
    this.autosuggestFavoriteClick = this.autosuggestFavoriteClick.bind(this);
    this.autosuggestClearResults = this.autosuggestClearResults.bind(this);
    this.scrollFromHash = this.scrollFromHash.bind(this);
  }

  componentDidMount() {
    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionid;
    if (agreementid !== "") {
      this.props.dispatch(refreshAgreement(agreementid, versionid));
      this.props.dispatch(refreshComments(agreementid));
    }
    executeAgreementAssertions();
    this.scrollFromHash(this.props.menu.hash);

    this.timerID = setInterval(
      () => this.checkForUpdates(),
      45000
    );

    let arbAcceptWidth = Math.ceil(getTextWidth(ARB_ACCEPT_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let arbRejectWidth = Math.ceil(getTextWidth(ARB_REJECT_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let arbUndecidedWidth = Math.ceil(getTextWidth(ARB_UNDECIDED_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("maxArbAcceptanceButtonWidth", Math.max(arbAcceptWidth, arbRejectWidth, arbUndecidedWidth)));

    let createContractWidth = Math.ceil(getTextWidth(CREATE_CONTRACT_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    let createPaymentWidth = Math.ceil(getTextWidth(CREATE_PAYMENT_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    let createRequestWidth = Math.ceil(getTextWidth(CREATE_REQUEST_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("maxCreateAgreementButtonWidth", Math.max(createContractWidth, createPaymentWidth, createRequestWidth)));

    let createVersionWidth = Math.ceil(getTextWidth(CREATE_VERSION_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    let cancelVersionWidth = Math.ceil(getTextWidth(CANCEL_VERSION_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("maxCreateVersionButtonWidth", Math.max(createVersionWidth, cancelVersionWidth)));
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  componentDidUpdate(prevProps, prevState) {
    let agreementid = this.props.agreement.agreementid;

    if (this.props.menu.hash !== prevProps.menu.hash) {
      this.scrollFromHash(this.props.menu.hash);
    }

    if (
      (
        this.props.agreement.agreementLoaded !== prevProps.agreement.agreementLoaded || 
        agreementid !== prevProps.agreement.agreementid
      ) &&
      this.props.agreement.agreementLoaded === false
    ) {
      let versionid = this.props.agreement.versionid;
      if (agreementid !== "") {
        this.props.dispatch(refreshAgreement(agreementid, versionid));
      }    
    }

    let prevTransactions = "[]";
    let curTransactions = "[]";
    if (this.props.agreement.youAreUser1) {
      prevTransactions = prevProps.agreement.transactionsUser1;
      curTransactions = this.props.agreement.transactionsUser1;
    } else if (this.props.agreement.youAreUser2) {
      prevTransactions = prevProps.agreement.transactionsUser2;
      curTransactions = this.props.agreement.transactionsUser2;
    } else if (this.props.agreement.youAreArbitrator) {
      prevTransactions = prevProps.agreement.transactionsArbitrator;
      curTransactions = this.props.agreement.transactionsArbitrator;
    }
    //console.log("cur transactions: " + curTransactions);
    if (curTransactions !== prevTransactions) {
      if (curTransactions.length > 2) {
        //console.log("About to refresh, ... prev, cur:");
        //console.log(prevTransactions);
        //console.log(curTransactions);
        this.props.dispatch(refreshMyPendingTransactions(this.props.agreement, curTransactions));
      }
      let txList = JSON.parse(this.props.agreement.transactionsUser1);
      txList = txList.concat(JSON.parse(this.props.agreement.transactionsUser2));
      //console.log("total tx list: ");
      //console.log(txList);
      if (this.couldHaveReplacementDeployTransaction(this.props.agreement, txList, true)) {
        this.props.dispatch(checkForReplacementDeployTransaction(this.props.dispatch, agreementid));
      }
    }

    if (
      this.props.agreement.commentsLoaded !== prevProps.agreement.commentsLoaded && 
      this.props.agreement.commentsLoaded === false
    ) {
      let agreementid = this.props.agreement.agreementid;
      if (agreementid !== "") {
        this.props.dispatch(refreshComments(agreementid));
      }
    }

    if (agreementid !== "" && prevProps.agreement) {
      if (
        prevProps.agreement.agreementid !== agreementid || 
        prevProps.agreement.status !== this.props.agreement.status || 
        prevProps.agreement.deployedid !== this.props.agreement.deployedid
      ) {
        let {agreedData="", status="", deployedid="", contractType=""} = this.props.agreement;
        let agreedDataObj = agreedData === "" ? {} : JSON.parse(agreedData);
        if (status === "finalized" && agreedDataObj.hasOwnProperty("arbitrator_address")) {
          let arbitratorAddress = agreedDataObj.arbitrator_address;
          this.props.dispatch(syncBlockchainEvidence(agreementid, deployedid, arbitratorAddress, contractType));
        }
      }
    }
  }

  scrollFromHash(hash) {
    window.setTimeout(() => {
      if (hash === "#comments") {
        this.showComments();
        /*let element = document.getElementById('lastcomments');
        if (element) {
          element.scrollIntoView({block: "start", behavior: "smooth"});
        }*/
      }
    }, 1000);
  }

  hasTransactionType(txList, status, type) {
    for (let i = 0; i < txList.length; i++ ) {
      let tx = txList[i];
      if (status !== "") {
        if (type !== "") {
          if (tx.status === status && tx.type === type) {
            return true;
          }
        } else {
          if (tx.status === status) {
            return true;
          }
        }
      } else { // no status
        if (type !== "") {
          if (tx.type === type) {
            return true;
          }
        }
        else {
          // no status or type restructions
          return true;
        }
      }
    }
    return false;
  }

  checkForUpdates() {
    console.log("checkForUpdates()");
    
    let {
      agreementid="",
      versionid="",
      transactionsUser1="",
      transactionsUser2="",
      transactionsArbitrator="",
      transactionLockUntilUnix=0,
      transactionByYou=false,
      youAreUser1=false,
      youAreUser2=false,
      youAreArbitrator=false,
    } = this.props.agreement;

    //console.log("tx_user1: " + transactionsUser1);
    //console.log("tx_user2: " + transactionsUser2);
    //console.log(this.props.agreement);

    let shouldRefresh = false;

    let curTransactions = "[]";
    let txList = JSON.parse(transactionsUser1);
    txList = txList.concat(JSON.parse(transactionsUser2));
    txList = txList.concat(JSON.parse(transactionsArbitrator));

    if (this.hasTransactionType(txList, "pending", "")){
      shouldRefresh = true;
    }

    if (youAreUser1) {
      curTransactions = transactionsUser1;
    } else if (youAreUser2) {
      curTransactions = transactionsUser2;
    } else if (youAreArbitrator) {
      curTransactions = transactionsArbitrator;
    }
    if (curTransactions.length > 2) {
      this.props.dispatch(refreshMyPendingTransactions(this.props.agreement, curTransactions));
    }
    if (this.couldHaveReplacementDeployTransaction(this.props.agreement, txList, true)) {
      this.props.dispatch(checkForReplacementDeployTransaction(this.props.dispatch, agreementid));

      // if the creation tx is 'unseen' and we've been waiting over 30 min, and it's your tx, clear the lock
      let pendingCreate = this.hasTransactionType(JSON.parse(curTransactions), "pending", "create");
      if (!pendingCreate) {
        let now = moment.tz('America/Los_Angeles').unix();
        let lockExpired = transactionLockUntilUnix;
        if (lockExpired !== 0 && transactionByYou && (now - lockExpired >= 60*15)) { // wait another 15 minutes after the lock expires
          console.log("Clearing lock on agreement: " + agreementid);
          this.props.dispatch(clearLockOnAgreement(agreementid, true));
          shouldRefresh = true;
        }
      }
    }

    if (shouldRefresh) {
      window.setTimeout(() => {
        this.props.dispatch(refreshAgreement(agreementid, versionid));
      }, 3000);
    }
  }

  couldHaveReplacementDeployTransaction(agreementObj, txList, allowUnseen) {
    if (
      (agreementObj.status !== "agreed" && agreementObj.status !== "proposed") || 
      agreementObj.agreedSha3 === "" ||
      agreementObj.transactionHash === ""
    ) {
      return false;
    }

    for (let i = 0; i < txList.length; i++ ){
      let tx = txList[i];
      if (tx.status === "pending") {
        return true;
      }
      if (allowUnseen && tx.status === "unseen") {
        return true;
      }
    }
    return false;
  }

  handleVersionClick(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"version_click"})));

    let agreementid = this.props.agreement.agreementid;
    let versionid = event.currentTarget.getAttribute("data-versionid");
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(navNewURL('/view?agreementid=' + agreementid + "&versionid=" + versionid));
    }
  }

  handleEvidenceClick(evidenceId) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"evidence_click"})));

    if (evidenceId) {
      let evidence = apiEvidenceURL("https://api.atstake.net/prod/evidence/" + evidenceId);
      let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
      let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
      this.props.dispatch(submitEvidence(evidence, blockchainDataObj, deployedid, agreedData, contractType));
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));

    let { 
      status=""
    } = this.props.agreement;

    if (status !== "finalized" && name !== "commentBox") {
      this.props.dispatch(requireConfirmNav());
    }
  }

  changeArbitrationMode(name, value) {
    this.props.dispatch(changeArbitrationMode(value));

    let { 
      status=""
    } = this.props.agreement;

    if (status !== "finalized" && name !== "commentBox") {
      this.props.dispatch(requireConfirmNav());
    }
  }

  getPriceMessage(input, type) {
    let inputWithCommas = addCommasToNumber(input);
    let msg = getPriceMessageWidthCutoff(input, type, inputWithCommas);
    return msg;
  }

  autosuggestClearResults(name, input, nonce, setArbitrationInfo) {
    this.props.dispatch(clearAutosuggestResults(name, input, nonce, setArbitrationInfo));
  }

  autosuggestChangeInput(name, value, search) {
    this.props.dispatch(changeAutosuggestInput(name, value, search));
  }

  autosuggestChangeResults(name, value, search, max, more, selected, nonce) {
    this.props.dispatch(changeAutosuggestResults(name, value, search, max, more, selected, nonce));
  }

  autosuggestChangeSelection(name, type, value, arbitration) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"autosuggest_select", name, selection: type + "#" + value})));
    this.props.dispatch(changeAutosuggestSelection(name, type, value, arbitration));
    this.props.dispatch(requireConfirmNav());
  }

  autosuggestFavoriteClick(name, contact, favorite) {
    this.props.dispatch(handleAutosuggestFavorite(name, contact, favorite));
  }

  hideAgreement(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"hide_agreement"})));

    let { hiddenForYouPending=false } = this.props.agreement;
    if (!hiddenForYouPending) {
      this.props.dispatch(hideAgreement());
    }
  }

  unhideAgreement(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"unhide_agreement"})));

    let { hiddenForYouPending=false } = this.props.agreement;
    if (!hiddenForYouPending) {
      this.props.dispatch(unhideAgreement());
    }
  }

  createAgreement(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"create_agreement"})));
    this.props.dispatch(createNewAgreement());
  }

  editVersion(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"edit_version"})));

    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionid;
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(navNewURL('/edit?agreementid=' + agreementid + "&versionid=" + versionid));
    }
  }

  createVersion(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"create_version"})));
    this.props.dispatch(createNewVersion());
  }

  cancelNew(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"cancel_new"})));
    this.props.dispatch(openOverlay("confirm", {pathname: "contracts", query: {}, title: "Are you sure?", message: "Do you want to cancel and discard all changes?"}));
  }

  cancelEdit(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"cancel_edit"})));
    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionid;
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(openOverlay("confirm", {pathname: "view", query: {agreementid,versionid}, title: "Are you sure?", message: "Do you want to cancel and discard all changes?"}));
    }
  }

  selectVersion(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"approve_version"})));
    this.props.dispatch(selectVersion());
  }

  unselectVersion(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"cancel_approval"})));
    this.props.dispatch(unselectVersion(""));
  }

  unselectEditVersion(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"edit_this_version"})));

    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionid;
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(unselectVersion('/edit?agreementid=' + agreementid + "&versionid=" + versionid));
    }
  }

  viewUser1Version(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"view_user1_version"})));

    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionUser1Proposed;
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(navNewURL('/view?agreementid=' + agreementid + "&versionid=" + versionid));
    }
  }

  viewUser2Version(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"view_user2_version"})));

    let agreementid = this.props.agreement.agreementid;
    let versionid = this.props.agreement.versionUser2Proposed;
    if (agreementid !== "" && versionid !== "") {
      this.props.dispatch(navNewURL('/view?agreementid=' + agreementid + "&versionid=" + versionid));
    }
  }

  finalize(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"finalize"})));

    let {
      youAreUser2=false,
      user1ContributionType="",
      user2ContributionType="",
      user1ContributionInput="",
      user2ContributionInput="",
      contractType="",
      autoResolveUnix=0,
      createdTimestamp=0
    } = this.props.agreement;

    let contributionType = user1ContributionType;
    let contributionAmount = user1ContributionInput;
    if (youAreUser2) {
      contributionType = user2ContributionType;
      contributionAmount = user2ContributionInput;
    }

    let showWarning = shouldShowDepositWarning(createdTimestamp, autoResolveUnix);
    hasAllowance(contributionType, contributionAmount, contractType).then((enoughAllowance) => {
      if (enoughAllowance && !showWarning) {
        let { youAreUser1=false, youAreUser2=false, agreementid="", agreedSha3="", metaevidenceHash="", agreedData="" } = this.props.agreement;
        if (agreedSha3 !== "" && agreedData !== "" && (youAreUser1 || youAreUser2)) {
          this.props.dispatch(deployAgreement(youAreUser1, youAreUser2, agreementid, agreedSha3, metaevidenceHash, agreedData));
        }
      }
      else {
        this.props.dispatch(openOverlayDepositWarning("blockchain_deposit", {
          action: "finalize"
        }, showWarning));
      }  
    });
  }

  deposit(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"deposit"})));

    let {
      deployedid="", 
      agreedData="",
      youAreUser2=false,
      user1ContributionType="",
      user2ContributionType="",
      user1ContributionInput="",
      user2ContributionInput="",
      contractType="",
      autoResolveUnix=0,
      createdTimestamp=0
    } = this.props.agreement;

    let contributionType = user1ContributionType;
    let contributionAmount = user1ContributionInput;
    if (youAreUser2) {
      contributionType = user2ContributionType;
      contributionAmount = user2ContributionInput;
    }

    console.log("about to deposit.. opening overlay..");

    let showWarning = shouldShowDepositWarning(createdTimestamp, autoResolveUnix);
    hasAllowance(contributionType, contributionAmount, contractType).then((enoughAllowance) => {
      if (enoughAllowance && !showWarning) {
        let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
        this.props.dispatch(deposit(blockchainDataObj, deployedid, agreedData, contractType));  
      }
      else {
        this.props.dispatch(openOverlayDepositWarning("blockchain_deposit", {
          action: "deposit"
        }, showWarning));  
      } 
    });
  }

  withdrawAndCancel(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"cancel_and_withdraw"})));

    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    this.props.dispatch(earlyWithdraw(blockchainDataObj, deployedid, agreedData, contractType));
  }

  arbitrationAgree(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"agree_to_arbitrate"})));
    this.props.dispatch(changeArbitratorStatus("agreed"));
  }

  arbitrationReject(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"decline_to_arbitrate"})));
    this.props.dispatch(changeArbitratorStatus("rejected"));
  }

  arbitrationPending(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"undecided_to_arbitrate"})));
    this.props.dispatch(changeArbitratorStatus("pending"));
  }

  resolve(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"resolve"})));

    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { 
      youAreUser1=false, 
      youAreUser2=false,
      automaticResolutionType="", 
      combinedStakeAmount="",
      user1StakeAmount="",
      user2StakeAmount="",
      combinedTokenType="",
      user1TokenType="",
      user2TokenType="",
      user1ResolutionType="",
      user2ResolutionType="",
      isSingleTokenType=false 
    } = blockchainDataObj;

    let selectedResolution = automaticResolutionType;
    if (youAreUser1 && user1ResolutionType !== "" && user1ResolutionType !== null) {
      selectedResolution = user1ResolutionType;
    }
    else if (youAreUser2 && user2ResolutionType !== "" && user2ResolutionType !== null) {
      selectedResolution = user2ResolutionType;
    }
    
    this.props.dispatch(openOverlay("blockchain_resolve", {
      action: "resolve", 
      resolutionInput: selectedResolution,
      combinedStakeAmount,
      user1StakeAmount,
      user2StakeAmount,
      combinedTokenType,
      user1TokenType,
      user2TokenType,
      isSingleTokenType
    }));
  }

  arbitrationRequest(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"request_arbitration"})));
    this.props.dispatch(openOverlay("blockchain_arbitrate", {
    }));
  }

  withdraw(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"withdraw"})));
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    this.props.dispatch(withdraw(blockchainDataObj, deployedid, agreedData, contractType));
  }

  withdrawDisputeFee(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"withdraw_dispute_fee"})));
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    this.props.dispatch(withdrawDisputeFee(blockchainDataObj, deployedid, agreedData, contractType));
  }

  requestDefaultJudgment(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"request_default_judgment"})));
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    this.props.dispatch(requestDefaultJudgment(blockchainDataObj, deployedid, agreedData, contractType));
  }

  requestAutomaticResolution(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"request_automatic_resolution"})));
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    this.props.dispatch(requestAutomaticResolution(blockchainDataObj, deployedid, agreedData, contractType));
  }

  refresh(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"refresh"})));
    window.location.reload();
  }

  comment(name, value) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"new_comment"})));
    let agreementid = this.props.agreement.agreementid;
    let commentText = this.props.agreement.commentBox;
    this.props.dispatch(createComment(agreementid, commentText));
  }

  showComments(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"scroll_to_comments"})));
    window.scrollTo(0, document.body.scrollHeight);
  }

  jumpToTop(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"jump_to_top"})));
    window.scrollTo(0, 0);
  }

  showVersionsDialog(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"open_versions_dialog"})));
    let agreementid = this.props.agreement.agreementid;
    this.props.dispatch(openOverlay("versions", {agreementid}));
  }

  showPosting(event) {
    let {
      postingid=""
    } = this.props.agreement;
    
    if (postingid !== "") {
      this.props.dispatch(navNewURL('/posting?postingid=' + postingid));
    }
  }

  showVisibilityDialog(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"open_visibility_dialog"})));
    let agreementid = this.props.agreement.agreementid;
    let selectedVisibility = this.props.agreement.agreementVisibility;
    this.props.dispatch(openOverlay("visibility", {agreementid, selectedVisibility}));
  }

  changeAddressDialog(name, changeType) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"change_address_dialog"})));
    let { 
      agreementid="",
      versionid="",
      status="",
      youAreUser1=false, 
      youAreUser2=false,
      user1Address="",
      user2Address="",
      user1Locked="", 
      user2DisplayValue=""
    } = this.props.agreement;

    let selectedAddress = "";
    let selectedUsername = "";
    let selectedIsYou = false;
    if (name === "user1") {
      selectedAddress = user1Address;
      selectedUsername = user1Locked;
      selectedIsYou = youAreUser1;
    }
    else if (name === "user2") {
      selectedAddress = user2Address;
      selectedUsername = user2DisplayValue;
      selectedIsYou = youAreUser2;
    }

    this.props.dispatch(openOverlay("address", {agreementid, versionid, status, title:"Choose address", message:"", selectedAddress, selectedUsername, selectedIsYou, mode:"change", changeType}));
  }

  viewAddressDialog(name) {
    let { 
      user1Address="",
      user2Address="",
      arbitratorAddress="",
      versionContractType="",
      agreementid=""
    } = this.props.agreement;

    let isTestAgreement = getIsTestAgreement(agreementid);
    let address = "";
    if (name === "user1") {
      address = user1Address;
    } else if (name === "user2") {
      address = user2Address;
    } else if (name === "arbitrator") {
      address = arbitratorAddress;
    } else if (name === "agreement") {
      address = shared.getContractAddress(isTestAgreement, versionContractType);
    }

    if (address !== "") {
      let url = "https://" + (isTestAgreement ? "rinkeby." : "") + "etherscan.io/address/" + address;
      window.open(url, "_blank");
    }
  }

  render() {
    if (pageNotLoaded(this.props.agreement)) { return null };

    let agreementLoaded = this.props.agreement.agreementLoaded;
    let agreementPermissionDenied = this.props.agreement.agreementPermissionDenied;
    let createPending = this.props.agreement.createAgreementPending || this.props.agreement.createVersionPending;
    let agreementType = this.props.agreement.agreementType;
    let mode = this.props.agreement.mode;
    let disabled = (mode === "view") || createPending;
    let disabledUser2 = disabled || (mode === "view") || (mode === "edit");
    let versionCount = this.props.agreement.versionCount || 0;

    let screenWidth = this.props.auth.screenDimensions.width;
    let isNarrow = (screenWidth < 700);
    let versionCountMsg = versionCount;
    
    if (!isNarrow) {
      versionCountMsg = versionCount + " versions";
      if (versionCount === 1) {
        versionCountMsg = versionCount + " version";
      }  
    }

    let postingMsg = "";
    if (!isNarrow) {
      postingMsg = "posting";
    }

    let stepList = ["1. Agree", "2. Deposit", "3. Confirm fulfillment", "4. Funds distributed"];
    if (agreementType === "contract") {
      stepList = ["1. Agree", "2. Deposit", "3. Report outcome", "4. Funds distributed"];
    }

    let { 
      agreementid="",
      deployedid="",
      postingid="",
      youAreUser1=false, 
      youAreUser2=false,
      youAreArbitrator=false,
      user1Locked="", 
      user2Locked="", 
      user2LinkId="",
      agreedArbitratorUsername="",
      user1Address="",
      user2Address="",
      arbitratorAddress="",
      versionNum="",
//      hiddenForYou=false,
      user2DisplayValue="",
      user1Disp="",
      user2Disp="",
      arbitratorDisp="",
      status="",
      searchArbitratorAutosuggest={},
      showErrorMsg=false,
      errorMessageText="",
      errorMessageType="",
      metacontentHash="",
      hasEvidenceTransactionAction=false,
      agreementVisibility="",
      previewid="",
      versionContractType
    } = this.props.agreement;

    let user1LinkUC = firstLetterUpperCase(user1Disp);
    if (user1Locked) {
      user1LinkUC = getUserLinkElement(user1Locked, firstLetterUpperCase(user1Disp), "normalWeight");
    }

    let user2LinkUC = firstLetterUpperCase(user2Disp);
    if (user2Locked) {
      user2LinkUC = getUserLinkElement(user2Locked, firstLetterUpperCase(user2Disp), "normalWeight");
    }

    let arbitratorLinkUC = firstLetterUpperCase(arbitratorDisp) + " (arbitrator)";
    if (agreedArbitratorUsername) {
      arbitratorLinkUC = getUserLinkElement(agreedArbitratorUsername, firstLetterUpperCase(arbitratorDisp + " (arbitrator)"), "normalWeight");
    }

    let youHaveCommentPermissions = youAreUser1 || youAreUser2 || youAreArbitrator;

    if (
      errorMessageType === "user2_linkid" ||
      errorMessageType === "user2_link_type" ||
      errorMessageType === "user2_link_value"
    ) {
      errorMessageType = "user2";
    }

    if (
      errorMessageType === "arbitration_fee_value" ||
      errorMessageType === "arbitration_fee_type"
    ) {
      errorMessageType = "arbitration_fee";
    }

    if (
      errorMessageType === "amount_value1" ||
      errorMessageType === "amount_type1"
    ) {
      errorMessageType = "amount1";
    }

    if (
      errorMessageType === "amount_value2" ||
      errorMessageType === "amount_type2"
    ) {
      errorMessageType = "amount2";
    }

    if (mode === "new") {
      youAreUser1 = true;
      youAreUser2 = false;
    }

    let { selectedArbitration=null } = searchArbitratorAutosuggest;
    let defaultArbitrationFee = "";
    let defaultArbitrationFeePriceMsg = "";
    let minArbitrationFeeMsg = "";
    if (selectedArbitration && selectedArbitration.hasOwnProperty("fee_value") && selectedArbitration.hasOwnProperty("fee_type")) {
      defaultArbitrationFee = selectedArbitration.fee_value + " " + upperCase(selectedArbitration.fee_type);
      defaultArbitrationFeePriceMsg = this.getPriceMessage(selectedArbitration.fee_value, selectedArbitration.fee_type);
      minArbitrationFeeMsg = "Arbitrator's minimum fee is " + defaultArbitrationFee + (defaultArbitrationFeePriceMsg === "" ? "" : " (" + defaultArbitrationFeePriceMsg + ")");
    }

    let selectedArbitratorKey = "";
    let selectedArbitratorLock = "";
    if (searchArbitratorAutosuggest && searchArbitratorAutosuggest.hasOwnProperty("selected")) {
      selectedArbitratorKey = searchArbitratorAutosuggest.selected;
      if (selectedArbitratorKey) {
        let splitArr = selectedArbitratorKey.split('#');
        selectedArbitratorLock = splitArr.length > 1 ? splitArr[1] : "";
      }
    }

    let leftSelectedUserLocked = "";
    let leftSelectedUserLinkId = "";
    let leftSelectedUserLabel = "";
    let rightSelectedUserLocked = "";
    let rightSelectedUserLinkId = "";
    let rightSelectedUserLabel = "";

    let leftUserContributionInputName = "";
    let leftUserContributionTypeName = "";
    let leftUserContributionLabel = "";
    let leftUserErrorType = "";

    let rightUserContributionInputName = "";
    let rightUserContributionTypeName = "";
    let rightUserContributionLabel = "";
    let rightUserErrorType = ""

    let addressUser1Short = user1Address;
    if (addressUser1Short === "") {
      addressUser1Short = "No address selected";
    }

    let addressUser2Short = user2Address;
    if (addressUser2Short === "") {
      addressUser2Short = "No address selected";
    }

    let addressArbitratorShort = arbitratorAddress;
    if (addressArbitratorShort === "") {
      addressArbitratorShort = "No address selected";
    }

    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);

    let {
      canSubmitEvidence=false,
      shouldUseAddress=""
    } = blockchainDataObj;

    let { 
      statusActions, 
      statusDesc, 
      selectedStep,
      showLoadIcon,
      toggleActions, 
      toggleActionSelected,
      warningType
    } = getStatusMessageAndActions(mode, this.props.agreement, blockchainDataObj, this.handleVersionClick, this.resolve, this.props.dispatch);
    
    let hasWarning = (warningType !== null && warningType !== "");
    
    let statusWarning = "";
    if (warningType === "disconnected") {
      statusWarning = <div className="statusWarning">
        <img className="warningIcon" src={warningImg} alt="Warning" />
        ETH wallet disconnected.
      </div>;
    }
    else if (warningType === "wrong_address") {
      let changeElem = "";
      if (status === "finalized") {
        changeElem = <span>Please use Ethereum address {shouldUseAddress}.</span>;
      }
      else {
        changeElem = <span>Please either:
          <ul>
            <li>Switch your wallet to use {shouldUseAddress}.</li>
            <li><span className="bluelink" onClick={() => this.changeAddressDialog(youAreUser2 ? "user2" : "user1", "current")}>Update this contract</span> to use your current address.</li>
          </ul>
        </span>;
      }

      statusWarning = <div className="statusWarning">
        <img className="warningIcon" src={warningImg} alt="Warning" />
        Your Ethereum address does not match this contract. {changeElem}
      </div>;
    }
    else if (warningType === "wrong_network") {
      statusWarning = <div className="statusWarning">
        <img className="warningIcon" src={warningImg} alt="Warning" />
        Please use the {shared.getNetworkNameLong(this.props.auth.testMode)}.
      </div>;
    }
    else if (warningType === "self_agreement") {
      statusWarning = <div className="statusWarning">
        <img className="warningIcon" src={warningImg} alt="Warning" />
        Invalid contract. You cannot have an contract with yourself.
      </div>;
    }

    if (this.props.auth.status !== "loggedin") {
      // Suppress blockchain warning messages when not logged in.
      statusWarning = "";
    }

    if (youAreUser1) {
      leftSelectedUserLocked = user2Locked;
      leftSelectedUserLinkId = user2LinkId;
      leftUserErrorType = "user2";
      rightUserErrorType = "user1";
      if (agreementType === "contract") {
        leftSelectedUserLabel = "Contract with";

        leftUserContributionInputName = "user1ContributionInput";
        leftUserContributionTypeName = "user1ContributionType";
        leftUserContributionLabel = "I'm depositing";
        rightUserContributionInputName = "user2ContributionInput";
        rightUserContributionTypeName = "user2ContributionType";
        rightUserContributionLabel = "They're depositing";
      }
      else if (agreementType === "payment") {
        leftSelectedUserLabel = "Payment to";

        leftUserContributionInputName = "user1ContributionInput";
        leftUserContributionTypeName = "user1ContributionType";
        leftUserContributionLabel = "Paying this amount";
      }
      else if (agreementType === "request") {
        leftSelectedUserLabel = "Requesting payment from";

        leftUserContributionInputName = "user2ContributionInput";
        leftUserContributionTypeName = "user2ContributionType";
        leftUserContributionLabel = "Requesting this amount";
      }
    }
    else if (youAreUser2) {
      leftSelectedUserLocked = user1Locked;
      leftSelectedUserLinkId = "";
      leftUserErrorType = "user1";
      rightUserErrorType = "user2";
      if (agreementType === "contract") {
        leftSelectedUserLabel = "Contract with";

        leftUserContributionInputName = "user2ContributionInput";
        leftUserContributionTypeName = "user2ContributionType";
        leftUserContributionLabel = "I'm depositing";
        rightUserContributionInputName = "user1ContributionInput";
        rightUserContributionTypeName = "user1ContributionType";
        rightUserContributionLabel = "They're depositing";
      }
      else if (agreementType === "request") {
        leftSelectedUserLabel = "Payment to";

        leftUserContributionInputName = "user2ContributionInput";
        leftUserContributionTypeName = "user2ContributionType";
        leftUserContributionLabel = "Paying this amount";
      }
      else if (agreementType === "payment") {
        leftSelectedUserLabel = "Receiving payment from";

        leftUserContributionInputName = "user1ContributionInput";
        leftUserContributionTypeName = "user1ContributionType";
        leftUserContributionLabel = "Receiving this amount";
      }
    }
    else {
      if (agreementType === "request") {
        leftSelectedUserLocked = user2Locked;
        leftSelectedUserLinkId = user2LinkId;
        rightSelectedUserLocked = user1Locked;
        rightSelectedUserLinkId = "";
        leftUserErrorType = "user2";
        rightUserErrorType = "user1";  
        leftSelectedUserLabel = "Payment from";
        rightSelectedUserLabel = "Payment to";

        leftUserContributionInputName = "user2ContributionInput";
        leftUserContributionTypeName = "user2ContributionType";
        leftUserContributionLabel = firstLetterUpperCase(user2DisplayValue) + " is paying";
      }
      else {
        leftSelectedUserLocked = user1Locked;
        leftSelectedUserLinkId = "";
        rightSelectedUserLocked = user2Locked;
        rightSelectedUserLinkId = user2LinkId;
        leftUserErrorType = "user1";
        rightUserErrorType = "user2";
        if (agreementType === "contract") {
          leftSelectedUserLabel = "Contract between";
          rightSelectedUserLabel = "And";

          leftUserContributionInputName = "user1ContributionInput";
          leftUserContributionTypeName = "user1ContributionType";
          leftUserContributionLabel = firstLetterUpperCase(user1Locked) + " depositing";
          rightUserContributionInputName = "user2ContributionInput";
          rightUserContributionTypeName = "user2ContributionType";
          rightUserContributionLabel = firstLetterUpperCase(user2DisplayValue) + " depositing";
        }
        else if (agreementType === "payment") {
          leftSelectedUserLabel = "Payment from";
          rightSelectedUserLabel = "Payment to";

          leftUserContributionInputName = "user1ContributionInput";
          leftUserContributionTypeName = "user1ContributionType";
          leftUserContributionLabel = firstLetterUpperCase(user1Locked) + " is paying";
        }
      }
    }

    let leftUserContributionErrorType = leftUserContributionTypeName === "user1ContributionType" ? "amount1" : "amount2";
    let rightUserContributionErrorType = rightUserContributionTypeName === "user1ContributionType" ? "amount1" : "amount2";

    let statusActionsList = [];
    let maxButtonTextWidth = parseInt(DEFAULT_BUTTON_WIDTH);
    for (let i = 0; i < statusActions.length; i++) {
      let actionName = statusActions[i];
      let actionOnClick = null;
      let actionText = "";
      let actionColor = "";

      if (actionName === "cancel_edit") {
        actionOnClick = this.cancelEdit;
        actionText = "Cancel edit";
        actionColor = "black";
      }
      else if (actionName === "new_version") {
        actionOnClick = this.createVersion;
        actionText = "Submit edit";
      }
      else if (actionName === "select_this_version") {
        actionOnClick = this.selectVersion;
        actionText = "Approve this version";
      }
      else if (actionName === "unselect") {
        actionOnClick = this.unselectVersion;
        actionText = "Cancel approval";
        actionColor = "black";
      }
      else if (actionName === "unselect_and_edit") {
        actionOnClick = this.unselectEditVersion;
        actionText = "Edit this version";
      }
      else if (actionName === "edit_this_version") {
        actionOnClick = this.editVersion;
        actionText = "Edit this version";
      }
      else if (actionName === "view_user1_version") {
        actionOnClick = this.viewUser1Version;
        actionText = "View " + user1Locked + "'s version";
      }
      else if (actionName === "view_user2_version") {
        actionOnClick = this.viewUser2Version;
        actionText = "View " + user2DisplayValue + "'s version";
      }
      else if (actionName === "arbitration_agree") {
        actionOnClick = this.arbitrationAgree;
        actionText = "Agree to arbitrate";
      }
      else if (actionName === "arbitration_reject") {
        actionOnClick = this.arbitrationReject;
        actionText = "Decline to arbitrate";
      }
      else if (actionName === "arbitration_pending") {
        actionOnClick = this.arbitrationPending;
        actionText = "Undecided to arbitrate";
      }
      else if (actionName === "finalize") {
        actionOnClick = this.finalize;
        actionText = "Deposit funds";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "finalize_disabled") {
        actionOnClick = null;
        actionText = "Deposit funds";
        actionColor="gray";
      }
      else if (actionName === "deposit") {
        actionOnClick = this.deposit;
        actionText = "Deposit funds";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "deposit_disabled") {
        actionOnClick = null;
        actionText = "Deposit funds";
        actionColor="gray";
      }
      else if (actionName === "withdraw_cancel") {
        actionOnClick = this.withdrawAndCancel;
        actionText = "Cancel and withdraw funds";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "resolve") {
        actionOnClick = this.resolve;
        actionText = "Report";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "resolve_as_arbitrator") {
        actionOnClick = this.resolve;
        actionText = "Report as arbitrator";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "change_resolution") {
        actionOnClick = this.resolve;
        actionText = "Change report";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "request_arbitration") {
        actionOnClick = this.arbitrationRequest;
        actionText = "Request arbitration";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "request_arbitration_disabled") {
        actionOnClick = null;
        actionText = "Request arbitration";
        actionColor="gray";
      }      
      else if (actionName === "agree_arbitration") {
        actionOnClick = this.arbitrationRequest;
        actionText = "Agree to arbitration";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "agree_arbitration_disabled") {
        actionOnClick = null;
        actionText = "Agree to arbitration";
        actionColor="gray";
      }
      else if (actionName === "withdraw") {
        actionOnClick = this.withdraw;
        actionText = "Withdraw funds";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "withdraw_fee") {
        actionOnClick = this.withdrawDisputeFee;
        actionText = "Withdraw dispute fee";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "request_default_judgment") {
        actionOnClick = this.requestDefaultJudgment;
        actionText = "Trigger default judgment";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "request_automatic_resolution") {
        actionOnClick = this.requestAutomaticResolution;
        actionText = "Trigger automatic outcome";
        if (hasWarning) { actionColor="gray"; actionOnClick=null; }
      }
      else if (actionName === "refresh") {
        actionOnClick = this.refresh;
        actionText = "Refresh";
      }

      // Before we create the elements, we need to figure out how large all the buttons should be.
      // We temporarily store the info we'll need to generate the Buttons in statusActionsList.
      if (actionText !== "") {
        statusActionsList.push({actionOnClick, actionText, actionColor});
        let w = Math.ceil(getTextWidth(actionText, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
        if (w > maxButtonTextWidth) {
          maxButtonTextWidth = w;
        }
      }
    }

    // Now add the buttons with the right width
    let statusActionsElem = [];
    for (let i = 0; i < statusActionsList.length; i++) {
      let item = statusActionsList[i];
      statusActionsElem.push(<ButtonAction key={i} color={item.actionColor} className="moveTop20 moveRight20" text={item.actionText} minWidth={maxButtonTextWidth} onClick={item.actionOnClick}/>);
    }

    let toggleActionsElem = [];
    for (let i = 0; i < toggleActions.length; i++) {
      let actionName = toggleActions[i];
      let actionOnClick = null;
      let actionText = "";
      let actionColor = "";
      if (actionName === "arbitration_agree") {
        actionOnClick = this.arbitrationAgree;
        actionText = "Yes";
        actionColor = "green";
      }
      else if (actionName === "arbitration_reject") {
        actionOnClick = this.arbitrationReject;
        actionText = "No";
        actionColor = "black";
      }
      else if (actionName === "arbitration_pending") {
        actionOnClick = this.arbitrationPending;
        actionText = "Undecided";
      }

      if (actionText !== "") {
        statusActionsElem.push(<ButtonSelect key={i} name="toggleActions" minWidth={this.props.agreement.maxArbAcceptanceButtonWidth} className="moveTop20 moveRight20" color={actionColor} text={actionText} value={actionName} selected={toggleActionSelected} onChange={actionOnClick}/>);
      }
    }
  
    let dropdownTokenList = getDropdownTokenList();
    let dropdownVisibilityList = getDropdownVisibilityList();

    let dropdownResolutionList = [];

    if (mode === "new") {
      dropdownResolutionList.push({
        name: "all_user2", 
        text: "All funds to them"
      });

      dropdownResolutionList.push({
        name: "all_user1", 
        text: "All funds to you"
      });
    }
    else {
      dropdownResolutionList.push({
        name: "all_user2", 
        text: shared.resolutionDisplay("all_user2", user1Disp, user2Disp)
      });

      dropdownResolutionList.push({
        name: "all_user1", 
        text: shared.resolutionDisplay("all_user1", user1Disp, user2Disp)
      });
    }

    dropdownResolutionList.push({
      name: "refund",
      text: shared.resolutionDisplay("refund", user1Disp, user2Disp)
    });

    dropdownResolutionList.push({
      name: "trade", 
      text: shared.resolutionDisplay("trade", user1Disp, user2Disp)
    });

    dropdownResolutionList.push({
      name: "fifty", 
      text: shared.resolutionDisplay("fifty", user1Disp, user2Disp)
    });

    let statusTitle = "Terms for version #" + versionNum;
    if (mode === "edit") {
      statusTitle = "Editing based on version #" + versionNum;
    }

    let createButtonText = CREATE_CONTRACT_LABEL;
    if (agreementType === "payment") {
      createButtonText = CREATE_PAYMENT_LABEL;
    }
    else if (agreementType === "request") {
      createButtonText = CREATE_REQUEST_LABEL;
    }

    let evidenceData = this.props.blockchain.evidenceData;
    let evidenceInit = false;
    let evidenceArr = [];
    if (evidenceData && evidenceData.agreementid === agreementid) {
      evidenceArr = evidenceData.evidenceArr;
      evidenceInit = true;
    }
    let commentsLoaded = this.props.agreement.commentsLoaded;
    let commentElem = [];
    let commentList = this.props.agreement.commentList || [];
    for (let i = 0; i < commentList.length; i++) {
      let commentEntry = commentList[i];
      let commentTimestamp = moment.tz(commentEntry.timestamp_unix, "X", 'America/Los_Angeles').format('h:mma MMM D, YYYY');
      commentElem.push(<CommentCard username={this.props.auth.username} key={i + "_comment"} enableEvidenceLink={status === "finalized" && canSubmitEvidence && commentEntry.evidence_hash && evidenceInit && !hasEvidenceTransactionAction} onEvidenceClick={this.handleEvidenceClick} comment={commentEntry.text} evidenceHash={commentEntry.evidence_hash} evidenceArr={evidenceArr} commentUser={commentEntry.username} timestamp={commentTimestamp} /> );
    }

    let commentCount = commentList.length;
    let commentCountStr = commentCount;
    if (!isNarrow) {
      commentCountStr = "1 comment";
      if (commentCount !== 1) {
        commentCountStr = commentCount + " comments";
      }
    }

    let downloadMsg = isNarrow ? "" : "download";

    let visibilityMsg = shared.visibilityText(agreementVisibility) + (isNarrow ? "" : " visibility");
    // Don't show download link for now -- without opening a dialog explaining why it's useful it's too confusing for now.
    // TODO: make this a dialog
    let showDownloadLink = false; //(status === "agreed" || status === "finalized");
    let agreementURI = apiEvidenceURL("https://api.atstake.net/prod/metacontent/" + metacontentHash);

    let descriptionPlaceholder = "";
    if(!disabled) {
      if(agreementType === "payment" || agreementType === "request") {
        descriptionPlaceholder = "Detailed description of item or service to be provided, delivery method, relevant dates, and other agreed upon terms.";
      } else {
        descriptionPlaceholder = "Detailed description of the possible outcomes, how funds should be distributed for each outcome, relevant dates, and other agreed upon terms.";
      }
    }

    let testMode = getTestModeFromStore();
    let isTestAgreement = false;
    if (agreementid !== "") {
      isTestAgreement = getIsTestAgreement(agreementid);
    } else {
      isTestAgreement = testMode;
    }

    // Calculate whether to show the condensed summary of advanced options, or show the full custom options 
    let defaultArbitrationSettings = shared.defaultArbitrationSettings(agreementType);
    let defaultArbitratorSettings = isTestAgreement ? shared.defaultArbitratorSettingsTest() : shared.defaultArbitratorSettingsProd();
    let showDefaultView = false;
    let minimumFeeValue = defaultArbitratorSettings.fee_value;
    let minimumFeeType = defaultArbitratorSettings.fee_type;
    if (selectedArbitration) {
      minimumFeeValue = selectedArbitration.fee_value;
      minimumFeeType = selectedArbitration.fee_type;
    }
    if (this.props.agreement.arbitrationMode !== "custom") {
      // We make some assumptions in the below code that our default settings having particular values. 
      // We have a bunch of assert statements in executeAgreementAssertions to enforce these assumptions. 
      // If we ever need to change our default values, we can write some more general code here.
      if (agreementType === "payment" || agreementType === "request") {
        if (this.props.agreement.requestArbitrationDate === "" &&
            this.props.agreement.daysToRespondInput.toString() === defaultArbitrationSettings.arbitration_response_days.toString() &&
            this.props.agreement.arbitrationFeeInput === minimumFeeValue &&
            this.props.agreement.arbitrationFeeType === minimumFeeType
        ) {
          showDefaultView = true;
        }
      } else { // handle the contract case
        if (this.props.agreement.requestArbitrationDate === "" &&
            this.props.agreement.daysToRespondInput.toString() === defaultArbitrationSettings.arbitration_response_days.toString() &&
            this.props.agreement.arbitrationFeeInput === minimumFeeValue &&
            this.props.agreement.arbitrationFeeType === minimumFeeType &&
            this.props.agreement.autoResolveDate === ""
        ) {
          showDefaultView = true;
        }
      }
    }

    // Figure out the contract address
    let contractAddress = "";
    let networkName = "";
    if (agreementid !== "") {
      contractAddress = shared.getContractAddress(isTestAgreement, versionContractType);
      networkName = shared.firstLetterUpperCase(shared.getNetworkName(isTestAgreement));
    }

    let deployedidString = deployedid !== "" ? "(agreement ID " + deployedid + (isTestAgreement ? " on " + networkName : "") + ")" : "";

    // We'll possibly put this element in the right column of an existing two column element, or in the first column of another two column element, 
    // so we'll define it here so as to not duplicate code.
    let contractAddressElement = contractAddress !== "" &&
      <div>
        <div className="contentLabel moveTop20">Contract {deployedidString}</div>
        <div className="moveTop5">
          <InputBasic onButtonAction={this.viewAddressDialog} buttonActionText="view" disabled={true} placeholder="Ethereum address" type="text" className="fullWidthInputLinkEllipsis" name="agreement" value={contractAddress} />
        </div>
      </div>;

    if (
      this.props.auth.status === "loggedin" && 
      isTestAgreement !== testMode && 
      (youAreUser1 || youAreUser2 || youAreArbitrator)
    ) {
      return (
        <div className="contentSection">
          <GapCard /> 
            <WrongNetworkCard title={this.props.agreement.title} networkName={shared.getNetworkNameLong(isTestAgreement)} />
          <GapCard />
        </div>
      );
    }
    else if (agreementLoaded || mode === "new") {
      return (
        <div className="contentSection">
          {mode === "edit" && (
            <div>
              <GapCard /> 
              <TitleCard title={this.props.agreement.title} />
              <div className="contentCard">
                <div className="">{statusWarning}{statusDesc}</div>
                {statusActionsElem}
                {toggleActionsElem}
              </div>
            </div>
          )}
          {mode === "view" && (
            <div>
              <GapCard /> 
              <StatusCard list={stepList} selected={selectedStep} />
              <GapCard hide="mobile" /> 
              <TitleCard title={this.props.agreement.title} />
              <div className="contentCard">
                <div>
                  {/* show/hide has been confusing to users  (youAreUser1 || youAreUser2 || youAreArbitrator) && <div className="hideIconCnt"><span onClick={hiddenForYou ? this.unhideAgreement : this.hideAgreement} className="hideIcon agGrayText" title={hiddenForYou ? "Show this contract in your contracts list" : "Move this contract to your hidden contracts list"}> <u>{hiddenForYou ? "show" : "hide"}</u></span></div>*/}
                  <div style={{paddingRight:"28px", wordBreak: "break-word"}} className="">{statusWarning}{statusDesc}{showLoadIcon && <div><img className="loadIcon" src={loadImg} alt="Loading" /></div>}</div>
                  {statusActionsElem}
                  {toggleActionsElem}
                </div>

                {commentsLoaded && 
                  <div className="">
                    <div className="statusInfoLink moveTop20" onClick={this.showVisibilityDialog}><img className="statusIcon" src={visibilityImg} alt="Visibility" />{visibilityMsg}</div>
                    <div className="statusInfoLink moveTop20" onClick={this.showComments}><img className="statusIcon" src={commentsImg} alt="Comments" />{commentCountStr}</div>
                    <div className="statusInfoLink moveTop20" onClick={this.showVersionsDialog}><img className="statusIcon" src={versionsImg} alt="Versions" />{versionCountMsg}</div>
                    {postingid !== "" && <div className="statusInfoLink moveTop20" onClick={this.showPosting}><img className="statusIcon" src={tackImg} alt="Posting" />{postingMsg}</div>}
                    {showDownloadLink && <Link className="statusInfoLink moveTop20" target="_blank" rel="noopener noreferrer" href={agreementURI}><img className="statusIcon" src={downloadImg} alt="Download" />{downloadMsg}</Link>}
                  </div>
                }

              </div>
            </div>
          )}
          <GapCard />     
          {mode === "view" && <TitleCard onClick={this.showVersionsDialog} title={statusTitle} />}
          {mode === "edit" && <TitleCard title={statusTitle} />}
          {mode === "new" && agreementType === "contract" && <TitleCard title="New contract" />}
          {mode === "new" && agreementType === "payment" && <TitleCard title="New conditional payment" />}
          {mode === "new" && agreementType === "request" && <TitleCard title="New request for conditional payment" />}
          <div className={"contentCard" + (mode === "view" ? " grayscale" : "")} style={{paddingTop:"0px"}}>
              {(mode !== "new" && !youAreUser1 && !youAreUser2) && (
                <div>
                  <TwoColumnContainer singleColumnHasMax="1" leftComp={
                    <div>
                      <div className="contentLabel moveTop20">
                        {leftSelectedUserLabel}
                      </div>
                      <div className="moveTop5">
                        <InputSelectedUser username={this.props.auth.username} className="" selectedUserLocked={leftSelectedUserLocked} selectedUserLinkId={leftSelectedUserLinkId} />
                      </div>
                      {showErrorMsg && errorMessageType === leftUserErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                    </div>
                  } rightComp={
                    <div>
                      <div className="contentLabel moveTop20">
                        {rightSelectedUserLabel}
                      </div>
                      <div className="moveTop5">
                        <InputSelectedUser username={this.props.auth.username} className="" selectedUserLocked={rightSelectedUserLocked} selectedUserLinkId={rightSelectedUserLinkId} />
                      </div>
                      {showErrorMsg && errorMessageType === rightUserErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                    </div>
                  } />

                  <TwoColumnContainer singleColumnHasMax="1" fullComp={
                    <div>
                      <div className="contentLabel moveTop20">Regarding</div>
                      <InputBasic maxlength={shared.MAX_CONTRACT_TITLE_LENGTH} disabled={disabled} placeholder="Brief description / title" type="text" className="fullWidthInput moveRight5 moveTop5" name="title" value={this.props.agreement.title} onChange={this.changeInputValue} />
                      {showErrorMsg && errorMessageType === "title" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                    </div>
                  } />
                </div>
              )}
              {(mode === "new" || youAreUser1 || youAreUser2) && (
                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">
                      {leftSelectedUserLabel}
                    </div>
                    <div className="moveTop5">
                      {mode !== "new" && <InputSelectedUser className="" selectedUserLocked={leftSelectedUserLocked} selectedUserLinkId={leftSelectedUserLinkId} />}
                      {mode === "new" && <InputAutosuggest disabled={disabledUser2} showPrice={false} className="" name="searchPartyAutosuggest" passArbitrationInfo={false} value={this.props.agreement.searchPartyAutosuggest} onChange={this.autosuggestChangeInput} onChangeAutosuggest={this.autosuggestChangeResults} onClearResults={this.autosuggestClearResults} onSelect={this.autosuggestChangeSelection} onFavoriteClick={this.autosuggestFavoriteClick} />}
                    </div>
                    {showErrorMsg && errorMessageType === leftUserErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } rightComp={
                  <div>
                    <div className="contentLabel moveTop20">Regarding</div>
                    <InputBasic maxlength={shared.MAX_CONTRACT_TITLE_LENGTH} disabled={disabled} placeholder="Brief description / title" type="text" className="fullWidthInput moveRight5 moveTop5" name="title" value={this.props.agreement.title} onChange={this.changeInputValue} />
                    {showErrorMsg && errorMessageType === "title" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } />
              )}
              { (agreementType === "contract") && 
              <TwoColumnContainer singleColumnHasMax="1" leftComp={
                <div>
                  <div className="contentLabel moveTop20">{leftUserContributionLabel}</div>
                  <div className="moveTop5">
                    <InputBasic appendRight={this.getPriceMessage(this.props.agreement[leftUserContributionInputName], this.props.agreement[leftUserContributionTypeName])} disabled={disabled} restrict="float" placeholder="Amount" type="text" className="amountTwoColumnInput moveRight5" name={leftUserContributionInputName} value={addCommasToNumber(this.props.agreement[leftUserContributionInputName])} onChange={this.changeInputValue} />
                    <Dropdown disabled={disabled} size="small" name={leftUserContributionTypeName} selected={this.props.agreement[leftUserContributionTypeName]} list={dropdownTokenList} onChange={this.changeInputValue} />
                  </div>
                  {showErrorMsg && errorMessageType === leftUserContributionErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } rightComp={
                <div>
                  <div className="contentLabel moveTop20">{rightUserContributionLabel}</div>
                  <div className="moveTop5">
                    <InputBasic appendRight={this.getPriceMessage(this.props.agreement[rightUserContributionInputName], this.props.agreement[rightUserContributionTypeName])} disabled={disabled} restrict="float" placeholder="Amount" type="text" className="amountTwoColumnInput moveRight5" name={rightUserContributionInputName} value={addCommasToNumber(this.props.agreement[rightUserContributionInputName])} onChange={this.changeInputValue} />
                    <Dropdown disabled={disabled} size="small" name={rightUserContributionTypeName} selected={this.props.agreement[rightUserContributionTypeName]} list={dropdownTokenList} onChange={this.changeInputValue} />
                  </div>
                  {showErrorMsg && errorMessageType === rightUserContributionErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            }

            { (agreementType === "payment" || agreementType === "request") && 
              <TwoColumnContainer singleColumnHasMax="1" leftComp={
                <div>
                  <div className="contentLabel moveTop20">{leftUserContributionLabel}</div>
                  <div className="moveTop5">
                    <InputBasic appendRight={this.getPriceMessage(this.props.agreement[leftUserContributionInputName], this.props.agreement[leftUserContributionTypeName])} disabled={disabled} restrict="float" placeholder="Amount" type="text" className="amountTwoColumnInput moveRight5" name={leftUserContributionInputName} value={addCommasToNumber(this.props.agreement[leftUserContributionInputName])} onChange={this.changeInputValue} />
                    <Dropdown disabled={disabled} size="small" name={leftUserContributionTypeName} selected={this.props.agreement[leftUserContributionTypeName]} list={dropdownTokenList} onChange={this.changeInputValue} />
                  </div>
                  {showErrorMsg && errorMessageType === leftUserContributionErrorType && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } rightComp={
                <div>
                  <div className="contentLabel moveTop20">Payment auto-completes</div>
                  <div className="moveTop5">
                    <InputDatePicker 
                        onChange={this.changeInputValue}
                        className="wizardInputDatepicker" 
                        value={this.props.agreement.autoResolveDate} 
                        disabled={disabled} 
                        hover={false}
                        name="autoResolveDate"
                        blankText="Never"
                        hoverText=""
                        device={this.props.auth.device}
                    />
                  </div>
                  {showErrorMsg && errorMessageType === "auto_resolve_unix" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            }


            <TwoColumnContainer singleColumnHasMax="1" fullComp={
              <div>
                <div className="labelInput moveTop20">Detailed description</div>
                <div className="moveTop5"><InputTextarea maxlength={shared.MAX_CONTRACT_TERMS_LENGTH} disabled={disabled} placeholder={descriptionPlaceholder} className="textAreaInput widthAgreementTerms" name="agreementTerms" value={this.props.agreement.agreementTerms} onChange={this.changeInputValue} /></div>
                {showErrorMsg && errorMessageType === "terms" && <div className="moveTop5 inputError">{errorMessageText}</div>}
              </div>
            } />

            { mode === "new" && 
              <TwoColumnContainer singleColumnHasMax="1" leftComp={
                <div>
                  <div className="contentLabel moveTop20">
                    <span>Visibility</span>
                    <QuestionTooltip alt="Learn more" message={
                      getVisibilityTooltipContent()
                    } screenWidth={this.props.auth.screenDimensions.width} textAligned={true} />
                  </div>
                  <div className="moveTop5">
                    {(mode === "new") ? (
                      <Dropdown size="small" disabled={disabled} name="agreementVisibility" selected={this.props.agreement.agreementVisibility} list={dropdownVisibilityList} onChange={this.changeInputValue} />
                    ) : (
                      <InputBasic onButtonAction={this.showVisibilityDialog} buttonActionText={status === "finalized" ? "" : "change"} disabled={true} placeholder="Visibility of agreement" type="text" className="smallWidthInput" name="visibility" value={shared.visibilityText(this.props.agreement.agreementVisibility)} />  
                    )}
                  </div>
                </div>
              } />
            }

            <TwoColumnContainer singleColumnHasMax="1" leftComp={
              <div>
                <div className="contentLabel moveTop20">
                  <span>Using arbitrator</span>
                  <QuestionTooltip alt="Learn more" message={
                    <div>
                      <div>Atstake allows you to choose anyone to arbitrate your contracts.</div>
                      <div className="moveTop10">To find existing users who are willing to arbitrate contracts go to the 'People' page.
                      We recommend that you carefully review the identity and reputation of any user before selecting them as your arbitrator.</div>
                      <div className="moveTop10">If you want someone to arbitrate your contract who isn't currently an Atstake user, 
                      ask them to sign up and to configure their arbitration settings on their 'Account' page.</div>
                    </div>
                  } screenWidth={this.props.auth.screenDimensions.width} textAligned={true} />
                </div>
                
                {mode !== "view" && (
                  <div className="moveTop5"><InputAutosuggest disabled={disabled} showPrice={true} className="moveTop5" name="searchArbitratorAutosuggest" passArbitrationInfo={true} value={this.props.agreement.searchArbitratorAutosuggest} onChange={this.autosuggestChangeInput} onChangeAutosuggest={this.autosuggestChangeResults} onClearResults={this.autosuggestClearResults} onSelect={this.autosuggestChangeSelection} onFavoriteClick={this.autosuggestFavoriteClick} /></div>
                )}
                {mode === "view" && (
                  <div className="moveTop5">
                    <InputSelectedUser username={this.props.auth.username} className="" selectedUserLocked={selectedArbitratorLock} selectedUserLinkId={selectedArbitratorKey} />
                  </div>
                )}

                {showErrorMsg && errorMessageType === "arbitrator" && <div className="moveTop5 inputError">{errorMessageText}</div>}
              </div>
                } />

            <TwoColumnContainer singleColumnHasMax="1" leftComp={
              <div>
                <div className="contentLabel moveTop20">Arbitration settings</div>
                <div className="moveTop5">
                  <ButtonSelect disabled={disabled} className="moveRight20" text="Default" minWidth={DEFAULT_BUTTON_WIDTH} name="arbitrationMode" value="default" selected={this.props.agreement.arbitrationMode} onChange={this.changeArbitrationMode}/>
                  <ButtonSelect disabled={disabled} className="" text="Custom" minWidth={DEFAULT_BUTTON_WIDTH} name="arbitrationMode" value="custom" selected={this.props.agreement.arbitrationMode} onChange={this.changeArbitrationMode}/>
                </div>
              </div>
            } />

            <TwoColumnContainer singleColumnHasMax="1" fullComp={
              showDefaultView && 
              (<div>
                {getDefaultArbitrationBullets(agreementType, defaultArbitrationSettings, defaultArbitrationFee, defaultArbitrationFeePriceMsg, false, false)}
              </div>
              )
            } />

            {!showDefaultView && (
              <div>
                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">Arbitration fee</div>
                    <div className="moveTop5">
                      <InputBasic appendRight={this.getPriceMessage(this.props.agreement["arbitrationFeeInput"], this.props.agreement["arbitrationFeeType"])} disabled={disabled} restrict="float" placeholder="Fee amount" type="text" className="amountTwoColumnInput moveRight5" name="arbitrationFeeInput" value={addCommasToNumber(this.props.agreement.arbitrationFeeInput)} onChange={this.changeInputValue} />
                      <Dropdown disabled={disabled} size="small" name="arbitrationFeeType" selected={this.props.agreement.arbitrationFeeType} list={dropdownTokenList} onChange={this.changeInputValue} />
                    </div>
                    {minArbitrationFeeMsg !== "" && <div className="moveTop5 minFeeMsg">{minArbitrationFeeMsg}</div>}
                    {showErrorMsg && errorMessageType === "arbitration_fee" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } />

                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">Arbitration can be requested starting on</div>
                    <div className="moveTop5">
                      <InputDatePicker 
                          onChange={this.changeInputValue}
                          className="wizardInputDatepicker" 
                          value={this.props.agreement.requestArbitrationDate} 
                          disabled={disabled} 
                          hover={false}
                          name="requestArbitrationDate"
                          blankText="Any time"
                          hoverText=""
                          device={this.props.auth.device}
                      />
                    </div>
                    {showErrorMsg && errorMessageType === "arbitration_request_unix" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } rightComp={
                  <div>
                    <div className="contentLabel moveTop20">We both must respond to arbitration requests within</div>
                    <InputBasic maxlength={shared.NUMBER_OF_DAYS_MAX_STRING_LENGTH} disabled={disabled} append={this.props.agreement.daysToRespondInput === "1" ? "day" : "days"} restrict="integer" placeholder="Number of days" type="text" className="standardInput moveTop5" name="daysToRespondInput" value={this.props.agreement.daysToRespondInput} onChange={this.changeInputValue} />
                    {showErrorMsg && errorMessageType === "arbitration_days" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } />

                { (agreementType !== "payment" && agreementType !== "request") && 
                  <TwoColumnContainer singleColumnHasMax="1" leftComp={
                    <div>
                      <div className="contentLabel moveTop20">Automatic outcome occurs on</div>
                      <div className="moveTop5">
                        <InputDatePicker 
                            onChange={this.changeInputValue}
                            className="wizardInputDatepicker" 
                            value={this.props.agreement.autoResolveDate} 
                            disabled={disabled} 
                            hover={false}
                            name="autoResolveDate"
                            blankText="Never"
                            hoverText=""
                            device={this.props.auth.device}
                        />
                      </div>
                      {showErrorMsg && errorMessageType === "auto_resolve_unix" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                    </div>
                  } rightComp={
                    (this.props.agreement.autoResolveDate !== "") &&
                      <div>
                        <div className="contentLabel moveTop20">When the automatic outcome occurs then</div>
                        <div className="moveTop5">
                          <Dropdown disabled={disabled} size="normal" name="defaultResolution" selected={this.props.agreement.defaultResolution} list={dropdownResolutionList} onChange={this.changeInputValue} />
                        </div>
                        {showErrorMsg && errorMessageType === "default_resolution" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div>
                  } />
                }
                
              </div>
            )}

            {mode === "new" && (
              <TwoColumnContainer singleColumnHasMax="1" fullComp={
                <div>
                  <ButtonAction disabled={disabled} color="black" className="moveTop20 moveRight20" text="Cancel" minWidth={this.props.agreement.maxCreateAgreementButtonWidth} onClick={disabled ? null : this.cancelNew}/>
                  <ButtonAction disabled={disabled} className="moveTop20" text={createButtonText} minWidth={this.props.agreement.maxCreateAgreementButtonWidth} onClick={disabled ? null : this.createAgreement}/>
                  {showErrorMsg && errorMessageType === "" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            )}

            {mode === "edit" && (
              <TwoColumnContainer singleColumnHasMax="1" fullComp={
                <div>
                  <ButtonAction disabled={disabled} color="black" className="moveTop20 moveRight20" text={CANCEL_VERSION_LABEL} minWidth={this.props.agreement.maxCreateAgreementButtonWidth} onClick={disabled ? null : this.cancelEdit}/>
                  <ButtonAction disabled={disabled} className="moveTop20" text={CREATE_VERSION_LABEL} minWidth={this.props.agreement.maxCreateAgreementButtonWidth} onClick={disabled ? null : this.createVersion}/>
                  {showErrorMsg && errorMessageType === "" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            )}

          </div>
          {(mode === "view" || mode === "edit") && (
            <div>
              <GapCard /> 
              <TitleCard title={"Addresses" + (isTestAgreement ? " (" + networkName + ")" : "")} />
              <div style={{paddingTop:"0px"}} className="contentCard">
                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    {youAreUser2 && <div className="contentLabel moveTop20">{user2LinkUC}</div>}
                    {!youAreUser2 && <div className="contentLabel moveTop20">{user1LinkUC}</div>}
                    <div className="moveTop5">
                      {(youAreUser1 || youAreUser2) && (status === "unlinked" || status === "proposed" || status === "agreed") ? (
                        <InputBasic onButtonAction={() => this.changeAddressDialog(youAreUser2 ? "user2" : "user1", "")} buttonActionText="change" disabled={true} placeholder="Ethereum address" type="text" className="fullWidthInputLinkEllipsis" name={youAreUser2 ? "user2" : "user1"} value={youAreUser2 ? addressUser2Short : addressUser1Short} />
                       ) : (
                        <InputBasic onButtonAction={this.viewAddressDialog} buttonActionText="view" disabled={true} placeholder="Ethereum address" type="text" className="fullWidthInputLinkEllipsis" name={youAreUser2 ? "user2" : "user1"} value={youAreUser2 ? addressUser2Short : addressUser1Short} />  
                       )}
                    </div>
                  </div>
                } rightComp={
                  <div>
                    {youAreUser2 && <div className="contentLabel moveTop20">{user1LinkUC}</div>}
                    {!youAreUser2 && <div className="contentLabel moveTop20">{user2LinkUC}</div>}
                    <div className="moveTop5">
                      <InputBasic onButtonAction={this.viewAddressDialog} buttonActionText="view" disabled={true} placeholder="Ethereum address" type="text" className="fullWidthInputLinkEllipsis" name={youAreUser2 ? "user1" : "user2"} value={youAreUser2 ? addressUser1Short : addressUser2Short} />
                    </div>
                  </div>
                } />

                { /* The logic of this block and the block following it are intertwined. Don't change this logic without making sure it doesn't break the next code block */
                  (status === "agreed" || status === "finalized") &&
                  <TwoColumnContainer singleColumnHasMax="1" leftComp={
                    <div>
                      <div className="contentLabel moveTop20">{arbitratorLinkUC}</div>
                      <div className="moveTop5">
                        <InputBasic onButtonAction={this.viewAddressDialog} buttonActionText="view" disabled={true} placeholder="Ethereum address" type="text" className="fullWidthInputLinkEllipsis" name="arbitrator" value={addressArbitratorShort} />
                      </div>
                    </div>
                  } rightComp={
                    contractAddressElement
                  } />
                }
                { /* The logic of this block and the block above it are intertwined. Don't change this logic without making sure it doesn't break the above code block */
                  (status !== "agreed" && status !== "finalized") && contractAddress !== "" &&
                  <TwoColumnContainer singleColumnHasMax="1" leftComp={
                    contractAddressElement
                  } />
                }
              </div>
            </div>
          )}
          {mode === "view" && (commentList.length > 0 || youHaveCommentPermissions) && (
            <div>
              <GapCard /> 
              <TitleCard title="Comments" />
              {commentElem}
              {youHaveCommentPermissions && <div className="contentCard">
                <TwoColumnContainer singleColumnHasMax="1" fullComp={
                  <div>
                    <div><InputTextarea maxlength={shared.MAX_CONTRACT_COMMENT_LENGTH} placeholder={"Leave a comment"} className="textAreaComment widthAgreementTerms" name="commentBox" value={this.props.agreement.commentBox} onChange={this.changeInputValue} /></div>
                    <ButtonAction className="moveTop20" text="Comment" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.comment}/>
                  </div>
                } />
              </div>
              }
            </div>
          )}
          <div id="lastcomments"> </div>
          <GapCard />
          <div style={{textAlign: "center"}}>
            <div className="statusInfoLink jumpToTop" onClick={this.jumpToTop}><img className="statusIcon" src={jumpUpImg} alt="Jump to top of page" />jump to top</div> 
          </div>        
        </div>
      );
    }
    else {
      return (
        <div className="contentSection">
          <GapCard /> 
          {agreementPermissionDenied ? <PermissionDeniedCard hasPreviewID={previewid !== ""} /> : <LoadCard />}
          <GapCard /> 
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    blockchain: state.blockchain
  }
}

const AgreementContainer = connect(mapStateToProps, null)(Agreement);
export default AgreementContainer;