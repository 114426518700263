

import { apiBatchListPostingsWithTree, getPostingsPageSections } from "./ActionUtils"

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'POSTINGSPAGE_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function refreshPostingsList(user, next_key) {
  return function(dispatch) {
    console.log("refreshPostingsList");
    if (next_key !== "") {
      dispatch(changeInputValue("updatesLoaded", false));
    }

    let pageSections = getPostingsPageSections(user, next_key);
    apiBatchListPostingsWithTree(pageSections).then(({categories, sections}) => {
      dispatch({
        type: 'CATEGORY_TREE',
        payload: categories
      });
      if (sections && sections.length > 0 && sections[0].hasOwnProperty("postings")) {
        dispatch({
          type: next_key === "" ? 'POSTINGSPAGE_LIST' : 'POSTINGSPAGE_MORE',
          payload: sections[0]
        });
      }
    });
  }
}
