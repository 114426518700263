import update from 'immutability-helper';

const emptyState = {
  breadLocation: "",
  breadType: "",
  breadCategory: "",
  expandMore: false,
  searchBox: "",
  searchQuery: "",
  postingList: [],
  paginationKey: "",
  postingsLoaded: false,
  updatesLoaded: false
}

const MarketsReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "postings") {
        let breadLocation = "";
        let breadType = "";
        let breadCategory = "";
        let expandMore = false;
        let searchBox = "";
        let searchQuery = "";
        if (query && Object.prototype.hasOwnProperty.call(query, "location")) {
          breadLocation = query.location;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "type")) {
          breadType = query.type;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "category")) {
          breadCategory = query.category;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "more")) {
          expandMore = (query.more === "1");
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "search")) {
          searchBox = query.search;
          searchQuery = query.search;
        }

        return {
          ...emptyState,
          breadLocation,
          breadType,
          breadCategory,
          expandMore,
          searchBox,
          searchQuery
        };
      }

      return state;
    }
    case "MARKETS_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "MARKETS_POSTINGS_LIST": {
      let {
        postings=[],
        pagination_key=""
      } = action.payload;

      let postingList = JSON.parse(JSON.stringify(postings));
      let paginationKey = JSON.parse(JSON.stringify(pagination_key));
      return {...state, postingList, paginationKey, postingsLoaded: true, updatesLoaded: true};
    }
    case "MARKETS_POSTINGS_MORE": {
      let {
        postings=[],
        pagination_key=""
      } = action.payload;

      let postingList = update(
        state.postingList || [], 
        {$push: postings}
      );
      let paginationKey = JSON.parse(JSON.stringify(pagination_key));

      return {...state, postingList, paginationKey, postingsLoaded: true, updatesLoaded: true};
    }
    default: {
      return state
    }
  }
}

export default MarketsReducer