import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogNewPosting.css';
import { 
  changeInputValue, 
  selectLocation, 
  selectCategory, 
  selectPostingType, 
  setHasLocation,
  selectMode 
} from './DialogNewPostingActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay } from './OverlayActions'
import { 
  DEFAULT_BUTTON_WIDTH
} from './Constants.js';
import { shared } from '../Shared';
import Breadcrumb from './Breadcrumb';
//import InputBasic from './InputBasic';
//import searchImg from '../search.png';

class DialogNewPosting extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.hasFilterMatch = this.hasFilterMatch.bind(this);
    this.locationSelect = this.locationSelect.bind(this);
    this.categorySelect = this.categorySelect.bind(this);
    this.handleLocationSpecific = this.handleLocationSpecific.bind(this);
    this.selectPostingType = this.selectPostingType.bind(this);
    this.selectMode = this.selectMode.bind(this);
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  hasFilterMatch(display, input) {
    if (shared.lowerCase(display).substring(0, input.length) === shared.lowerCase(input)) {
      return true;
    }

    return false;
  }

  locationSelect(locationObj) {
    let { key="", display="" } = locationObj;
    this.props.dispatch(selectLocation(key, display));
  }

  categorySelect(locationObj) {
    let { key="" } = locationObj;

    let {
      breadType=""
    } = this.props.dialogNewPosting;

    this.props.dispatch(selectCategory(breadType, key));
  }

  handleLocationSpecific(hasLocation) {
    this.props.dispatch(setHasLocation(hasLocation));
  }

  selectPostingType(key) {
    if (key === "other") {
      this.props.dispatch(selectCategory(key, ""));
    }
    else {
      this.props.dispatch(selectPostingType(key));
    }
  }

  selectMode(mode) {
    this.props.dispatch(selectMode(mode));
  }

  // function to help build html tables such that all elements in earlier columns are 'before' elements in later columns.
  buildTableHelper(elementList, maxColumns, onClickFunction, displayProcessorFunction, classNameString) {
    let rowList = []
    let cellList = [];
    // We want the elements to be ordered by column (everything in the first column 'before' everything in the second).
    // But we build the table row by row, so we need to do some math to put the right element in each cell.
    // First, we see if any elements don't fit into a perfectly rectangular table:
    let extraElements = elementList.length % maxColumns; // elements that don't fit into an R x Y rectangle
    // If such elements exist, we need one more row than we'd need if they didn't exist:
    let numRows = Math.floor(elementList.length / maxColumns) + (extraElements > 0 ? 1 : 0);
    // Now that we know how many rows we'll have, we need to check again if there will be extra elements.
    extraElements = elementList.length % numRows;
    // If we don't have extra, we can get away with one fewer column.
    let numColumns = Math.floor(elementList.length / numRows) + (extraElements > 0 ? 1 : 0);
    for (let row = 0; row < numRows; row++) {
      for (let arrayIndex = row; arrayIndex < elementList.length; arrayIndex += numRows) {
        let elemObj = elementList[arrayIndex];
        let elemDisplay = elemObj.display
        if (displayProcessorFunction !== null) {
          elemDisplay = displayProcessorFunction(elemObj.display);
        }
        cellList.push(
          <td key={"cell_" + arrayIndex}><span onClick={() => onClickFunction(elemObj)} className={classNameString}>{elemDisplay}</span></td>
        );
      }
      // add an empty element if necessary
      if (row + numRows * (numColumns - 1) < elementList.length) {
        cellList.push(
          <td key={"emptycell_0"}></td>
        );
      }

      rowList.push(<tr key={"row_" + row}>{cellList}</tr>);
      cellList = [];
    }
    return rowList;
  }
  
  render() {

    let {
      mode="",
      hasLocation=false,
      searchLocations="",
      breadLocation="",
      breadType="",
      breadCategory=""
    } = this.props.dialogNewPosting;

    let locationList = shared.BREADCRUMB_LOCATION_LIST;

    let categoryList = shared.getPostingCategoryList(breadType);

    let filteredLocationList = [];
    for (let i = 0; i < locationList.length; i++) {
      let locationObj = locationList[i];
      if (searchLocations === "" || this.hasFilterMatch(locationObj.display, searchLocations) || this.hasFilterMatch(locationObj.key, searchLocations)) {
        filteredLocationList.push(locationObj)
      }
    }

    let screenWidth = this.props.auth.screenDimensions.width;
    let maxColumns = 5;
    if (mode === "choose_location") {
      if (screenWidth < 400) {
        maxColumns = 2;
      }
      else if (screenWidth < 500) {
        maxColumns = 3;
      }
      else if (screenWidth < 600) {
        maxColumns = 4;
      }  
    }
    else {
      maxColumns = 4;
      if (screenWidth < 500) {
        maxColumns = 2;
      }
      else if (screenWidth < 600) {
        maxColumns = 3;
      }
    }

    // Location list
    let locationRowList = [];
    if (mode === "choose_location") {
      locationRowList = this.buildTableHelper(filteredLocationList, maxColumns, this.locationSelect, null, "locationResult moveTop5 moveRight20");
    }

    // If it's a service or rental, we don't want to display the term 'service' or 'rental' for each item in the new postings dialog.
    let trimCategoryObj = breadType === "service_offered" || breadType === "service_wanted" || breadType === "rental_offered" || breadType === "rental_wanted";
    
    // If we don't display 'service' or 'rental', modify how we describe the category
    let categoryModifierText = breadType === "service_offered" || breadType === "service_wanted" ? " service" : "";
    if (breadType === "rental_offered" || breadType === "rental_wanted") {
      categoryModifierText = " rental"
    }

    // Category list
    let trimFunction = trimCategoryObj ? shared.trimLastToken : null;
    let categoryRowList = [];
    if (mode === "category_type") {
      categoryRowList = this.buildTableHelper(categoryList, maxColumns, this.categorySelect, trimFunction, "categoryResult moveTop5 moveRight20");
    }

    // Posting type list
    let breadTypeElemList = [];
    for (let i = 0; i < shared.BREADCRUMB_POSTING_TYPE_LIST.length; i++) {
      let breadTypeObj = shared.BREADCRUMB_POSTING_TYPE_LIST[i];
      breadTypeElemList.push(
        <div key={i}>
          <ButtonAction link={true} name={breadTypeObj.key} className="moveTop5 moveRight20" text={breadTypeObj.display} onClick={() => this.selectPostingType(breadTypeObj.key)}/>
        </div>
      );
    }

    let breadList = [];

    if (breadLocation !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadLocation, "location"), field: breadLocation, level: "location" }
      );
    }

    if (breadType !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadType, "type"), field: breadType, level: "type" }
      );  
    }

    if (breadCategory !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadCategory, "category"), field: breadCategory, level: "category" }
      );  
    }
    
    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title="New posting" />
          {mode === "start" && (
            <div>
              <div className="">
                Is your posting location specific?
              </div>
              <ButtonAction name="no" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20 moveRight20" text="No" onClick={() => this.handleLocationSpecific(false)}/>
              <ButtonAction name="yes" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20 moveRight20" text="Yes" onClick={() => this.handleLocationSpecific(true)}/>
            </div>
          )}
          {mode === "choose_location" && (
            <div>
              <div className="">
                Choose your location below
                <div>
                  {/*<InputBasic logo={<img className="autosuggestImg" src={searchImg} title="Search all" alt="Search all" />} placeholder="Search locations" type="text" className="locationSearchInput moveTop5 moveRight5" name="searchLocations" value={this.props.dialogNewPosting.searchLocations} onChange={this.changeInputValue} />*/}
                  <div style={{marginTop: "15px"}}>
                    <table>
                      <tbody>
                        {locationRowList}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <ButtonAction name="back" color="black" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20 moveRight20" text="Back" onClick={() => this.selectMode("start")} />
              <ButtonAction name="other" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20 moveRight20" text="Other location" onClick={() => this.locationSelect({ key: "other", display: "Other" })}/>
            </div>
          )}
          {mode === "posting_type" && (
            <div>
              <Breadcrumb list={breadList} />
              <div className="moveTop20">
                What type of posting is this?
                <div style={{marginTop: "15px"}}>
                  {breadTypeElemList}
                </div>
                <div className="moveTop20">
                  <ButtonAction name="back" color="black" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Back" onClick={() => this.selectMode(hasLocation ? "choose_location" : "start")}/>
                </div>
              </div>
            </div>
          )}
          {mode === "category_type" && (
            <div>
              <Breadcrumb list={breadList} />
              <div className="moveTop20">
                Please choose a{categoryModifierText} category
                <div style={{marginTop: "15px"}}>
                  <table>
                    <tbody>
                      {categoryRowList}
                    </tbody>
                  </table>
                </div>
                <div className="moveTop20">
                  <ButtonAction name="back" color="black" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Back" onClick={() => this.selectMode("posting_type")}/>
                </div>
              </div>
            </div>
          )}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogNewPosting: state.dialogNewPosting
  }
}

const DialogNewPostingContainer = connect(mapStateToProps, null)(DialogNewPosting);
export default DialogNewPostingContainer;