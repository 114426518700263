//import update from 'immutability-helper';

const emptyState = {
  title: null,
  message: null
}

const DialogMessageReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGMESSAGE_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "message") {
        let { title="", message="" } = params;
        return {...state, ...emptyState, title: title, message: message };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogMessageReducer