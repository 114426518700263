import { shared } from '../Shared';
//import store from '../store';

export function getTemplateTokenList(breadType) {
  let dropdownList = [];

  let displayForType = shared.getDisplayHelper(shared.BREADCRUMB_POSTING_TYPE_LIST, breadType);
  dropdownList.push({
    name: breadType, 
    text: displayForType
  });

  dropdownList.push({
    name: "custom", 
    text: "Custom"
  });

  dropdownList.push({
    name: "none", 
    text: "None"
  });

  return dropdownList;
}




