import { connect } from 'react-redux';
import React, { Component } from 'react';
import './Ethereum.css';
//import GapCard from "./GapCard";
//import TitleCard from "./TitleCard";

class Ethereum extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {


    return (
      <div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    how: state.how,
    overlay: state.overlay
  }
}

const EthereumContainer = connect(mapStateToProps, null)(Ethereum);
export default EthereumContainer;