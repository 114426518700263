import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getIsProfileOwnAccount, navNewURL } from './ActionUtils'
import './Dashboard.css';
//import TitleCard from './TitleCard';
import ButtonAction from './ButtonAction';
import GapCard from './GapCard';
import LineCard from './LineCard';
import Dropdown from './Dropdown';
import NoResultsCard from './NoResultsCard';
import PaginationCard from './PaginationCard';
import LoadCard from './LoadCard';
import AgreementCard from './AgreementCard';
import { refreshAgreementList, changeInputValue } from './DashboardActions';
import { refreshProfileData } from './ProfileHeaderActions';
import { pageNotLoaded, summaryFromAgreementVersion, summaryFromAgreementBlockchain } from "./ActionUtils";
import { getStatusMessageAndActions } from './AgreementMessageUtils';
import { openOverlay } from './OverlayActions';
import ProfileHeader from './ProfileHeader';
import { 
  DEFAULT_BUTTON_WIDTH
 } from './Constants.js';
 import NotFoundUser from './NotFoundUser';

class Dashboard extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.viewAgreement = this.viewAgreement.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleNewContract = this.handleNewContract.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(refreshAgreementList());
  }

  componentDidUpdate(prevProps, prevState) {
    let needUserDataRefresh = this.props.dashboard.needUserDataRefresh;
    let selectedUser = this.props.profileHeader.selectedUser;

    if (
      this.props.dashboard.agreementsLoaded !== prevProps.dashboard.agreementsLoaded && 
      this.props.dashboard.agreementsLoaded === false
    ) {
      this.props.dispatch(refreshAgreementList());
    }

    if (
      this.props.dashboard.agreementsLoaded !== prevProps.dashboard.agreementsLoaded && 
      this.props.dashboard.agreementsLoaded === true
    ) {
      let lastAgreementElem = document.getElementById('last_agreement');
      if (lastAgreementElem) {
        lastAgreementElem.scrollIntoView({block: "center", behavior: "smooth"});
      }
    }

    if (
      selectedUser !== "" &&
      (needUserDataRefresh !== prevProps.dashboard.needUserDataRefresh) && 
      needUserDataRefresh
    ) {
      this.props.dispatch(refreshProfileData(selectedUser));
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleFilterChange(name, value) {
    let { selectedUser="" } = this.props.profileHeader;
    let ownAccount = getIsProfileOwnAccount();

    if (ownAccount) {
      this.props.dispatch(navNewURL('/contracts?filter=' + value));
    }
    else {
      this.props.dispatch(navNewURL('/contracts?user=' + selectedUser + '&filter=' + value));
    }
  }

  handleLinkClick(dest) {
    switch (dest) {
      case "test_mode": {
        let testMode = this.props.auth.testMode;
        this.props.dispatch(openOverlay("testmode", {mode: (testMode ? "test":"prod") }));     
        break;
      }
      default: {
        // Do nothing
        break;
      }
    }
  }

  handleNewContract(name, value) {
    let {
      selectedUser=""
    } = this.props.profileHeader;
    this.props.dispatch(openOverlay("new_contract", {user: selectedUser}));
  }

  viewAgreement(agreementId, versionId) {
    this.props.dispatch(navNewURL('/view?agreementid=' + agreementId + "&versionid=" + versionId));
  }

  prev(name, value) {
    let {
      selectedFilter=""
    } = this.props.profileHeader;

    let offsetNum = parseInt(this.props.dashboard.offset, 10);
    let countNum = parseInt(this.props.dashboard.count, 10);
    let newOffset = offsetNum > countNum ? offsetNum - countNum : 0;
    this.props.dispatch(navNewURL('/contracts?filter=' + selectedFilter + "&offset=" + newOffset + "&count=" + countNum));
  }

  next(name, value) {
    let {
      selectedFilter=""
    } = this.props.profileHeader;

    let offsetNum = parseInt(this.props.dashboard.offset, 10);
    let countNum = parseInt(this.props.dashboard.count, 10);
    let newOffset = offsetNum + countNum <= 1000 ? offsetNum + countNum : 1000;
    this.props.dispatch(navNewURL('/contracts?filter=' + selectedFilter + "&offset=" + newOffset + "&count=" + countNum));
  }

  render() {
    if (pageNotLoaded(this.props.dashboard)) { return null };

    let username = this.props.auth.username;
    let loggedout = this.props.auth.status === "loggedout";
    let agreementsLoaded = this.props.dashboard.agreementsLoaded;

    let {
      selectedUser="",
      queryParamUser="",
      selectedFilter="",
      userExists=false,
      dataLoaded: headerDataLoaded = false
    } = this.props.profileHeader;

    if ((headerDataLoaded && !userExists) || (loggedout && queryParamUser === "")) {
      return (
        <div id="dashboard_cnt" className="contentSection">
            <ProfileHeader />
            <NotFoundUser selectedUser={selectedUser} />
        </div>
      );
    }
    else if (!headerDataLoaded || !agreementsLoaded) {
      return (
        <div>
            <ProfileHeader />
            <GapCard />
            <LoadCard key="load" />
        </div>
      );
    }

    let ownAccount = getIsProfileOwnAccount();
    let agreementElem = [];
    let agreementList = this.props.dashboard.agreementList;
    let offset = this.props.dashboard.offset;
    let count = this.props.dashboard.count;

    if (agreementsLoaded) {
      let dataList = [];
      for (let i = 0; i < agreementList.length; i++) {
        let agreement = agreementList[i];
        let agreementDataObj = summaryFromAgreementVersion(agreement, username);
        let blockchainDataObj = summaryFromAgreementBlockchain(agreementDataObj);
        let messageDataObj = getStatusMessageAndActions("view", agreementDataObj, blockchainDataObj, null, null, this.props.dispatch);
        let dataObj = { version: agreement, agreementDataObj, blockchainDataObj, messageDataObj };

        let actionRequiredYou = (messageDataObj.hasOwnProperty("actionRequired") && messageDataObj.actionRequired === "you");
        let actionRequiredOthers = (messageDataObj.hasOwnProperty("actionRequired") && messageDataObj.actionRequired === "");
        let hasAction = (messageDataObj.hasOwnProperty("statusNotice") && messageDataObj.statusNotice !== "");
        if (
          (selectedFilter !== "action_required_you" && selectedFilter !== "action_required_others") || 
          (selectedFilter === "action_required_you" && hasAction && actionRequiredYou) ||
          (selectedFilter === "action_required_others" && hasAction && actionRequiredOthers)) {
          dataList.push(dataObj);
        }
      }

      if (dataList.length > 0) {
        agreementElem.push(<AgreementCard hiddenPage={selectedFilter === "hidden"} key={"0_entry"} dispatch={this.props.dispatch} username={username} data={dataList} onClick={this.viewAgreement} lastAgreementId={this.props.menu.lastAgreementId} />);
      }

      if (agreementElem.length === 0) {
        agreementElem.push(<NoResultsCard key="noresults" />);
      }
      else {
        agreementElem.push(<LineCard key="line" />);
      }

      agreementElem.push(<PaginationCard showTopGap={true} offset={offset} count={count} shown={agreementList.length} onPrev={this.prev} onNext={this.next} key="pagination" />);
    }
    else {
      agreementElem.push(<LoadCard id="load" key="load" />);
    }

    let filterDropdownList = [];
    if (ownAccount) {
      filterDropdownList.push({
        name: "all", 
        text: "All contracts with you"
      });
      filterDropdownList.push({
        name: "participant", 
        text: "You as a participant"
      });
      filterDropdownList.push({
        name: "arbitrator", 
        text: "You as the arbitrator"
      });
      filterDropdownList.push({
        name: "action_required_you", 
        text: "Action required by you"
      });
      filterDropdownList.push({
        // The way this currently works is that if action is required by you for an agreement,
        // that agreement won't appear in the 'action required only by others' list even if
        // from their perspective it appears in their 'action required by you' list.
        // The word "only" appears in the UI here to make that clear to users. 
        name: "action_required_others", 
        text: "Action required only by others"
      });
      filterDropdownList.push({
        name: "hidden", 
        text: "Hidden contracts"
      });
    }
    else {
      filterDropdownList.push({
        name: "visible_to_you", 
        text: "All visible contracts"
      });
      filterDropdownList.push({
        name: "with_you", 
        text: "Contracts with you"
      });
      filterDropdownList.push({
        name: "public", 
        text: "Public contracts"    
      });
    }

    return (
        <div id="dashboard_cnt" className="contentSection">
          <ProfileHeader />
          <GapCard />
          <div className="blankCard">
            <ButtonAction key="add_photo" color="light" className="" text={<div><div style={{fontSize:"48px"}}>+</div>{ !ownAccount ? <div><div>New contract with</div><div className="moveTop5">{selectedUser}</div></div> : <div>New contract</div>}</div>} minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.handleNewContract}/>
          </div>
          <GapCard />
          <div className="titleDropdown">
            <Dropdown name="dashboardFilter" selected={selectedFilter} list={filterDropdownList} onChange={this.handleFilterChange} />
          </div>
          {agreementElem}
          <div className="moveTop40"></div>
        </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dashboard: state.dashboard,
    blockchain: state.blockchain,
    profileHeader: state.profileHeader
  }
}

const DashboardContainer = connect(mapStateToProps, null)(Dashboard);
export default DashboardContainer;