import update from 'immutability-helper';
import { summaryFromAgreementVersion, dateUnixToString, getTestModeFromStore, getArbitratorAutosuggestObj } from "./ActionUtils"
import moment from 'moment-timezone';
import { shared } from '../Shared';

const emptyState = {
  loadTimestamp: 0,
  agreementPermissionDenied: false,
  agreementLoaded: false,
  createAgreementPending: false,
  createVersionPending: false,
  agreementid: "",
  versionid: "",
  postingid: "",
  versionNum: "",
  rawVersionData: null,
  versionCount: 0,
  user1Address: "",
  user2Address: "",
  arbitratorAddress: "",
  user1AddressDefault: "", 
  user2AddressDefault: "", 
  arbitratorAddressDefault: "", 
  
  youAreUser1: false,
  youAreUser2: false,
  youAreArbitrator: false,
  youAreThisVersionAbitrator: false,
  youAgreedToAbitrate: false,
  user1Locked: "",
  user2Locked: "",
  user2LinkId: "",
  user2LinkIdType: "",
  user2LinkIdValue: "",
  user2DisplayValue: "",
  user1ExplicitApproval: false,
  user2ExplicitApproval: false,
  agreedArbitratorUsername: "",
  agreedArbitratorStatus: "",
  arbitratorStatusPending: false,
  
  versionUser1Proposed: "",
  versionUser2Proposed: "",
  versionLatestProposed: "",
  versionUser1Num: "",
  versionUser2Num: "",
  versionLatestNum: "",
  versionUser1Timestamp: "",
  versionUser2Timestamp: "",
  versionLatestTimestamp: "",

  agreementVisibility: "",
  user1VisibilityRequest: "",
  user2VisibilityRequest: "",
  status: "",
  agreedSha3: "",
  agreedData: "",
  agreedDataObj: {},
  metaevidenceHash: "",
  deployedid: "",
  finalizedBy: "",
  contractType: "",
  versionContractType: "",
  transactionType: "",
  transactionHash: "",
  transactionSource: "",
  transactionByUsername: "",
  transactionByYou: "",
  transactionsUser1: "[]",
  transactionsUser2: "[]",
  transactionsArbitrator: "[]",
  hiddenUser1: false,
  hiddenUser2: false,
  hiddenArbitrator: false,
  hiddenForYou: false,
  hiddenForYouPending: false,
  hiddenPending: false,
  ratingsByYou: {},

  commentList: [],
  commentsLoaded: false,
  commentSubmitting: false,
  mode: "",
  agreementType: "",
  searchPartyAutosuggest: {
    search: "all",
    input: "",
    results: [],
    selected: null,
    selectedArbitration: null,
    more: false,
    match: {},
    init: false
  },
  user1ContributionInput: "",
  user1ContributionType: "",
  user2ContributionInput: "",
  user2ContributionType: "",
  user1ZeroContribution: false,
  user2ZeroContribution: false,
  title: "",
  agreementTerms: "",
  detailsDisp: "",
  versionArbitrator: "",
  searchArbitratorAutosuggest: {
    search: "all",
    input: "",
    results: [],
    selected: null,
    selectedArbitration: null,
    more: false,
    match: {},
    init: false
  },
  arbitrationFeeInput: "",
  arbitrationFeeType: "",
  autoResolveDate: "",
  autoResolveUnix: 0,
  autoResolveDisplay: "",
  defaultResolution: "",
  requestArbitrationDate: "",
  requestArbitrationDisplay: "",
  daysToRespondInput: "",
  commentBox: "",
  arbitrationMode: "",
  hasTransactionAction: false, 
  hasEvidenceTransactionAction: false, 
  transactionObj: null,
  evidenceTransactionObj: null,
  showErrorMsg: false,
  errorMessageText: "",
  errorMessageType: "",
  displayAmount: [],
  createdTimestamp: "",
  user1StakeDisplay: "",
  user2StakeDisplay: "",
  user1Disp: "",
  user2Disp: "",
  arbitratorDisp: "",
  previewid: "",
  customFeeInput: "",
  customFeeType: "",
  customRequestArbitrationDate: "",
  customDaysToRespond: "",
  customAutoResolveDate: "",
  customArbitrator: "",
  maxArbAcceptanceButtonWidth: 0,
  maxCreateAgreementButtonWidth: 0,
  maxCreateVersionButtonWidth: 0
}

const AgreementReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "new") {
        let type = "contract";
        if (query && Object.prototype.hasOwnProperty.call(query, "type")) {
          type = query.type;
        }

        let user2 = "";
        let selectedUser2 = null;
        if (query && Object.prototype.hasOwnProperty.call(query, "user")) {
          user2 = query.user;
          selectedUser2 = "atstake#" + user2.toLowerCase();
        }

        let nowUnix = moment.tz('America/Los_Angeles').unix();
        let arbitrationSettings = shared.defaultArbitrationSettings(type);
        let autoResolveUnix = 0; // No auto-resolution
        if (arbitrationSettings.auto_resolve_days > 0) {
          autoResolveUnix = nowUnix + (arbitrationSettings.auto_resolve_days * 24 * 60 * 60);
        }
        let daysToRespondInput = arbitrationSettings.arbitration_response_days;
        let defaultResolution = arbitrationSettings.default_resolution;
        //let arbRequestDays = arbitrationSettings.arbitration_request_days;

        let autoResolveDate = dateUnixToString(autoResolveUnix);
        let searchArbitratorAutosuggest = getArbitratorAutosuggestObj();

        let defaultArbitratorSettings = getTestModeFromStore() ? shared.defaultArbitratorSettingsTest() : shared.defaultArbitratorSettingsProd();
        let arbitrationFeeInput = defaultArbitratorSettings.fee_value;
        let arbitrationFeeType = defaultArbitratorSettings.fee_type;

        return {...state, ...emptyState, 
          mode: "new",
          agreementType: type,
          searchPartyAutosuggest: {
            search: "all",
            input: user2,
            results: [],
            selected: selectedUser2,
            selectedArbitration: null,
            more: false,
            match: {},
            init: false
          },
          searchArbitratorAutosuggest,
          arbitrationFeeInput,
          arbitrationFeeType,
          youAreUser1: true,
          agreementVisibility: "discreet",
          status: "new",
          user1ContributionType: "eth",
          user2ContributionType: "eth",
          daysToRespondInput,
          arbitrationMode: "default",
          defaultResolution,
          autoResolveDate
        };
      }
      else if (newSelectedPage === "view" || newSelectedPage === "edit") {
        let agreementid = "";
        let versionid = "";
        let previewid = "";
        if (query && Object.prototype.hasOwnProperty.call(query, "agreementid")) {
          agreementid = query.agreementid;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "versionid")) {
          versionid = query.versionid;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "previewid")) {
          previewid = query.previewid;
        }

        return {...state, ...emptyState, 
          mode: newSelectedPage,
          agreementid: agreementid,
          versionid: versionid,
          previewid: previewid
        };
      }

      // Empty everything if page not selected
      return {};
    }
    case "AGREEMENT_SELECTED" : {
      let { 
        version=null, 
        username="", 
        versionCount=0, 
        user1Address="", 
        user2Address="", 
        arbitratorAddress="",
        user1AddressDefault="", 
        user2AddressDefault="", 
        arbitratorAddressDefault="" 
      } = action.payload;

      let agreementLoaded = false;
      let rawVersionData = null;
      let versionData = {};
      if (version && version.agreement) {
        agreementLoaded = true;
        rawVersionData = version;
        versionData = summaryFromAgreementVersion(version, username);

        let { hasOnlyMenuTokens=false } = versionData;
        if (!hasOnlyMenuTokens) {
          window.forceTokenSync = true;
          window.forceAllTokens = true;
        }
      }

      let loadTimestamp = moment.tz('America/Los_Angeles').unix();
      
      return {
        ...state, 
        ...versionData, 
        agreementLoaded: agreementLoaded, 
        rawVersionData: rawVersionData, 
        versionCount: versionCount, 
        user1Address: user1Address, 
        user2Address: user2Address, 
        arbitratorAddress: arbitratorAddress,
        user1AddressDefault: user1AddressDefault,
        user2AddressDefault: user2AddressDefault,
        arbitratorAddressDefault: arbitratorAddressDefault,
        loadTimestamp: loadTimestamp
      };
    }
    case "AGREEMENT_PENDING_FINALIZE_TRANSACTION" : {
      let { transactionType="", transactionHash="" } = action.payload;
      return {...state, transactionType, transactionHash, transactionSource: "local" };
    }
    case "AGREEMENT_COMMENTS" : {
      let { list=[] } = action.payload;
      return {...state, commentList: list, commentsLoaded: true };
    }
    case "AGREEMENT_COMMENT_SUBMITTING" : {
      let val = action.payload;
      return {...state, commentSubmitting: val };
    }
    case "AGREEMENT_NEW_COMMENT" : {
      let { comment=null } = action.payload;
      if (comment !== null) {

        let newCommentList = update(
          state.commentList || [], 
          {$push: [comment]}
        );
        
        return {...state, commentList: newCommentList, commentBox: "", commentSubmitting: false };
      }
      return {...state};
    }
    case "AGREEMENT_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value, showErrorMsg: false};
    }
    case "AGREEMENT_CHANGE_ARBITRATION_MODE" : {
      let { value } = action.payload;
      if (value === "default" && state.arbitrationMode !== "default") {
        // If we're switching to default, set everything back to the default values.
        let defaultArbitratorSettings = getTestModeFromStore() ? shared.defaultArbitratorSettingsTest(): shared.defaultArbitratorSettingsProd();
        let defaultArbitrationSettings = shared.defaultArbitrationSettings(state.agreementType);
        let arbDate = "";
        if (defaultArbitrationSettings.arbitration_request_days > 0) {
          arbDate = dateUnixToString(moment.tz('America/Los_Angeles').unix() + defaultArbitrationSettings.arbitration_request_days * 24 * 60 * 60);
        }
        let autoResolveDate = state.autoResolveDate;
        if(state.agreementType !== "payment" && state.agreementType !== "request") {
          autoResolveDate = "";
        }
        let feeVal = defaultArbitratorSettings.fee_value;
        let feeType = defaultArbitratorSettings.fee_type;
        let customFeeInput = state.arbitrationFeeInput;
        let customFeeType = state.arbitrationFeeType;
        let { selectedArbitration=null } = state.searchArbitratorAutosuggest;
        if (selectedArbitration) {
          feeVal = selectedArbitration.fee_value;
          feeType = selectedArbitration.fee_type;
        }
        return {
          ...state, 
          arbitrationMode: value, 
          arbitrationFeeInput: feeVal,
          arbitrationFeeType: feeType,
          requestArbitrationDate: arbDate,
          daysToRespondInput: defaultArbitrationSettings.arbitration_response_days,
          autoResolveDate: autoResolveDate,
          // Store the old custom values in state, so that we can restore
          // then if the user switches back to custom.
          customFeeInput: customFeeInput,
          customFeeType: customFeeType,
          customRequestArbitrationDate: state.requestArbitrationDate,
          customDaysToRespond: state.daysToRespondInput,
          customAutoResolveDate: state.autoResolveDate,
          customArbitrator: state.searchArbitratorAutosuggest.input
        };
      } else if (value === "custom" && state.arbitrationMode !== "custom") {
        if (state.customFeeInput !== "") {
          // We have saved custom state, so restore it
          let autoResolveVal = (state.agreementType === "payment" || state.agreementType === "request") ? state.autoResolveDate : state.customAutoResolveDate;
          let feeVal = state.customFeeInput;
          let feeType = state.customFeeType;
          if (state.searchArbitratorAutosuggest.input !== state.customArbitrator) {
            // If the user changed arbitrator while in default mode, then override the custom fee info from before
            feeVal = state.arbitrationFeeInput;
            feeType = state.arbitrationFeeType;
          }
          return {
            ...state, 
            arbitrationMode: value,
            arbitrationFeeInput: feeVal,
            arbitrationFeeType: feeType,
            requestArbitrationDate: state.customRequestArbitrationDate,
            daysToRespondInput: state.customDaysToRespond,
            autoResolveDate: autoResolveVal
          };
        }
        return {...state, arbitrationMode: value};
      }

      return {...state};
    }
    case "AGREEMENT_CHANGE_AUTOSUGGEST_INPUT" : {
      let { name, value, search } = action.payload;

      if (value.length > shared.MAX_IDENTIFIER_LENGTH) {
        value = value.substring(0, shared.MAX_IDENTIFIER_LENGTH);
      }

      let newSelected = state[name].selected;
      let newSelectedArbitration = state[name].selectedArbitration;
      if (state[name].input !== value) {
        newSelected = null;
        newSelectedArbitration = null;
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {input: value, selected: newSelected, selectedArbitration: newSelectedArbitration, search: search}}
      );

      return {...state, [name]: newAutosuggest, showErrorMsg: false};
    }
    case "AGREEMENT_CHANGE_AUTOSUGGEST_RESULTS" : {
      let { name, results, more, match, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: results, more: more, match: match, init: true}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "AGREEMENT_CLEAR_AUTOSUGGEST_RESULTS" : {
      let { name, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: [], more: false, match: {}, init: false}}
      );

      return {...state, [name]: newAutosuggest};
    }    
    case "AGREEMENT_CHANGE_AUTOSUGGEST_SELECTION" : {
      let { name, type, value, arbitration } = action.payload;
      let newAutosuggest = {};
      let newSelectedArbitration = arbitration ? JSON.parse(JSON.stringify(arbitration)) : state[name].selectedArbitration;
      let arbitrationFeeInput = state.arbitrationFeeInput; 
      let arbitrationFeeType = state.arbitrationFeeType;
      
      if (type === null || value === null) {
        newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: [], more: false, match: {}, init: false, selected: null, selectedArbitration: null, input: ""}}
        );
      }
      else {
        if (arbitration) {
          arbitrationFeeInput = newSelectedArbitration.fee_value;
          arbitrationFeeType = newSelectedArbitration.fee_type;  
        }
        
        let newSelected = type + "#" + value.toLowerCase();
        newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: [], more: false, match: {}, init: false, selected: newSelected, selectedArbitration: newSelectedArbitration, input: value}}
        );
      }

      return {...state, [name]: newAutosuggest, arbitrationFeeInput, arbitrationFeeType};
    }
    case "AGREEMENT_AUTOSUGGEST_FAVORITE" : {
      let { name="", contact="", favoriteSelected=false, favoritePending=false } = action.payload;            
      
      let hasUpdate = false;
      let resultList = state[name].results || [];
      for (let i = 0; i < resultList.length; i++) {
        let result = resultList[i];
        if (result.value === contact) {
          let newResultObj = JSON.parse(JSON.stringify(result));
          newResultObj.favoriteSelected = favoriteSelected;
          newResultObj.favoritePending = favoritePending;

          resultList = update(
            resultList || [], 
            {[i] : {$set: newResultObj}}
          );

          hasUpdate = true;

          break;
        }
      }

      if (hasUpdate) {
        let newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: resultList}}
        );
        
        return {...state, [name]: newAutosuggest};  
      }

      return {...state};
    }
    case "AGREEMENT_ADDRESS_UPDATED" : {
      return {...state, agreementLoaded: false};
    }
    case "AGREEMENT_BLOCKCHAIN_TRANSACTION" : {
      let newTransactionObj = action.payload;
      newTransactionObj.time_unix = moment.tz('America/Los_Angeles').unix();
      return {...state, hasTransactionAction: true, transactionObj: newTransactionObj};
    }
    case "AGREEMENT_CLEAR_BLOCKCHAIN_TRANSACTION" : {
      return {...state, hasTransactionAction: false, transactionObj: null};
    }
    case "AGREEMENT_EVIDENCE_TRANSACTION" : {
      let newEvidenceTransactionObj = action.payload;
      newEvidenceTransactionObj.time_unix = moment.tz('America/Los_Angeles').unix();
      return {...state, hasEvidenceTransactionAction: true, evidenceTransactionObj: newEvidenceTransactionObj};
    }
    case "AGREEMENT_CLEAR_EVIDENCE_TRANSACTION" : {
      return {...state, hasEvidenceTransactionAction: false, evidenceTransactionObj: null};
    }
    case "AGREEMENT_PERMISSION_DENIED" : {
      return {...state, agreementPermissionDenied: true};
    }
    case "AGREEMENT_CREATE_PENDING" : {
      let { pending=false } = action.payload;
      return {...state, createAgreementPending: pending};
    }
    case "AGREEMENT_UPDATE_HIDDEN" : {
      let { agreementid="", hidden=false, pending=false } = action.payload;
      if (state.agreementid === agreementid) {
        return {...state, hiddenForYou: hidden, hiddenForYouPending: pending };
      }
      return {...state};
    }
    case "REVIEW_UPDATE" : {
      let { agreementid="", user="", rating=0, ratingText="", pending=false, complete=false } = action.payload;
      if (agreementid === state.agreementid && complete && !pending) {
        let newRatingsObj = { timestamp: 0, score: rating, text: ratingText};
        let newRatingsByYou = update(
          state.ratingsByYou || {}, 
          {$merge: {[user]: newRatingsObj}}
        );
        return {...state, ratingsByYou: newRatingsByYou };
      }
      return {...state};
    }
    case "AGREEMENT_ARBITRATION_STATUS" : {
      let { agreementid="", arbitrationStatus="", pending=false } = action.payload;
      if (state.agreementid === agreementid) {
        return {...state, agreedArbitratorStatus: arbitrationStatus, arbitratorStatusPending: pending };
      }
      return {...state};
    }
    case "AGREEMENT_SUBMIT_ERROR" : {
      let { errorField="", errorMsg="" } = action.payload;

      return {
        ...state, 
        createAgreementPending: false, 
        createVersionPending: false,
        showErrorMsg: true,
        errorMessageText: errorMsg,
        errorMessageType: errorField
      };
    }
    case "VERSION_CREATE_PENDING" : {
      let { pending=false } = action.payload;
      return {...state, createVersionPending: pending};
    }
    default: {
      return state
    }
  }
}

export default AgreementReducer