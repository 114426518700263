//import update from 'immutability-helper';

const emptyState = {
  faucet_address: "",
  faucet_last_used: -1,
  faucet_next_allowed_use: 0,
  status: "",
  deactivate: false
}

const FaucetReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "FAUCET_DATA": {
      return {...state, ...action.payload };
    }
    case "FAUCET_REQUEST": {
      //console.log("new status is: " + action.payload.status);
      //console.log(action.payload);
      return {...state, status : action.payload.status };
    }
    case "FAUCET_BUTTON_DEACTIVATION": {
      return {...state, deactivate : true };
    }
    default: {
      return state
    }
  }
}

export default FaucetReducer