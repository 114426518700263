//import { apiRequest } from "./ActionUtils"

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWCONTRACT_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function selectMode(mode) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGNEWCONTRACT_SELECT_MODE',
      payload: {mode}
    });
  }
}