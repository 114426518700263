import React, { Component } from 'react';
import './ReviewCard.css';
import reviewLikeSelectedImg from '../review_like_blue.png';
import reviewDislikeSelectedImg from '../review_dislike_black.png';

class ReviewCard extends Component {
  render() {
    let {
      isPositive=false,
      ratingBy="",
      title="",
      agreementid="",
      displayRole="",
      displayDate="",
      ratingsReviewText=""
    } = this.props;

    return (
      <div className="contentCard">
        <img src={isPositive ? reviewLikeSelectedImg : reviewDislikeSelectedImg} className={isPositive ? "ratingsLikeIcon" : "ratingsDislikeIcon"} alt={isPositive ? "Positive" : "Negative"} title={isPositive ? "Positive" : "Negative"} />
        <span className="ratingsText">by <span className="blacklink" onClick={() => this.props.onUserClick(ratingBy)}>{ratingBy}</span><span> for {title !== "" ? <span onClick={() => this.props.onAgreementClick(agreementid)} className="blacklink">{title}</span> : "a non-public agreement"}</span> as {displayRole} <span style={{color:"#888"}}> &middot; {displayDate}</span></span>
        {ratingsReviewText !== "" && <div className="reviewText moveTop20"><span className="reviewContent">{ratingsReviewText}</span> </div>}
      </div>
    );
  }
}
  
export default ReviewCard;