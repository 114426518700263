import { apiUpdateProfilePhoto, imageConvertAndResize, urlConvertAndResize } from "./ActionUtils"
import store from '../store';
import { closeOverlay } from './OverlayActions';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGCHANGEDISPLAYNAME_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function submitDisplayname(displayname, photoContent) {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiUpdateProfilePhoto(auth.uuid, auth.username, displayname, photoContent).then((success) => {
      if (success) {
        dispatch(closeOverlay({profileDataUpdate: true}));
      }
      else {
        dispatch({
          type: 'DIALOGCHANGEDISPLAYNAME_ERROR',
          payload: {hasError: true, errorMsg: "Error updating profile"}
        });
      }
    });
  }
}

export function saveImageValue(file) {
  return function(dispatch) {
    imageConvertAndResize(file, 200, 200).then(({thumbnail, image}) => {
      dispatch({
        type: 'DIALOGCHANGEDISPLAYNAME_SAVE_IMAGE',
        payload: {name: "photoContent", thumbnail, image}
      });
    });
  }
}

export function saveImageFromURL(url) {
  return function(dispatch) {
    urlConvertAndResize(url, 200, 200).then(({thumbnail, image}) => {
      dispatch({
        type: 'DIALOGCHANGEDISPLAYNAME_SAVE_IMAGE',
        payload: {name: "photoContent", thumbnail, image}
      });

      dispatch({
        type: 'DIALOGCHANGEDISPLAYNAME_CHANGE_INPUT_VALUE',
        payload: {name: "showEnterURL", value: false}
      });
    });
  }
}
