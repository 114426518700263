import React, { Component } from 'react';
import './NotFoundUser.css';
import { genURL } from './ActionUtils';
import Link from './Link';
import GapCard from './GapCard';

class NotFoundUser extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="contentSection">
        <GapCard />
        <div className="contentCard">
          <div style={{fontSize:"24px"}}>User {this.props.selectedUser} doesn't exist!</div>
          <div className="moveTop20">Check the URL you entered or <Link noTarget={true} href={genURL("/people")} className="bluelink">visit the people page</Link>.</div>
        </div>
      </div>
    );
  }
}
  
export default NotFoundUser;