import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogAccessCode.css';
import { getAccessCode, changeInputValue } from './DialogAccessCodeActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay } from './OverlayActions';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import moment from 'moment-timezone';

class DialogAccessCode extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getAccessCode());
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    let code = this.props.dialogAccessCode.code;
    let expiration_unix = this.props.dialogAccessCode.expiration_unix;

    let remainingTime = 0;
    let expired = true;
    if (expiration_unix !== 0) {
      let now_unix = moment.tz('America/Los_Angeles').unix();
      if (now_unix < expiration_unix) {
        remainingTime = expiration_unix - now_unix;
        expired = false;
      }
    }

    let remainingTimeMinutes = Math.floor(remainingTime / 60);
    let remainingTimeSec = remainingTime - (remainingTimeMinutes * 60);

    let furtherInstructions = "To use the access code, go to Atstake's \"Sign up\" page while using your other address.";

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title="Access code" />
          {code === "" && expired && <div className="padBottom20">Generating access code ...</div>}
          {code !== "" && expired && <div className="padBottom20"><div>Your access code {code} has expired.</div> <div className="moveTop20">{furtherInstructions}</div> </div>}
          {code !== "" && !expired && <div className="padBottom20"><div>Your access code {code} expires in {remainingTimeMinutes}m {remainingTimeSec}s.</div><div className="moveTop20">{furtherInstructions}</div></div>}
          <ButtonAction name="ok" minWidth={DEFAULT_BUTTON_WIDTH} className="" text="OK" onClick={this.handleOk}/>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogAccessCode: state.dialogAccessCode
  }
}

const DialogAccessCodeContainer = connect(mapStateToProps, null)(DialogAccessCode);
export default DialogAccessCodeContainer;