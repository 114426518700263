import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogReview.css';
import { changeInputValue, submitUserReview } from './DialogReviewActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay } from './OverlayActions'
import InputTextarea from './InputTextarea';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import reviewLikeImg from '../review_like_grey.png';
import reviewDislikeImg from '../review_dislike_grey.png';
import reviewLikeSelectedImg from '../review_like_blue.png';
import reviewDislikeSelectedImg from '../review_dislike_black.png';

class DialogReview extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.setRating = this.setRating.bind(this);
    this.setRatingText = this.setRatingText.bind(this);
  }

  handleCancel(name, value) {
    this.props.dispatch(closeOverlay());
  }

  handleSubmit(name, value) {

    let {
      agreementid="",
      user="",
      rating=0,
      ratingText=""
    } = this.props.dialogReview;

    this.setRatingText(ratingText); // We set the rating already on the click
    this.props.dispatch(submitUserReview(agreementid, user, rating, ratingText));
    this.props.dispatch(closeOverlay());
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  setRating(rating) {
    this.changeInputValue("rating", rating);
  }

  setRatingText(ratingText) {
    this.changeInputValue("ratingText", ratingText);
  }
  
  render() {    

    let {
      title="",
    } = this.props.agreement;

    let {
      rating=0,
      pending=false
    } = this.props.dialogReview;

    return (
      <div className="contentSection">
        <div className="contentCard">
          <DialogTitle title="Leave a review" />
          <div className="reviewAgreementCnt">
            <div className="reviewAgreementTitle">{title}</div>
          </div>
          <div>
            <div className="labelBold moveTop20">My review</div>
            <div>
                {rating === 1 ? (
                  <img src={reviewLikeSelectedImg} className="reviewLikeIcon" style={{paddingLeft:"3px"}} alt="positive" title="positive" />
                ) : (
                  <img onClick={() => this.setRating(1)} src={reviewLikeImg} className="reviewLikeIcon" style={{paddingLeft:"3px"}} alt="positive" title="positive" />
                )}
                {rating === -1 ? (
                  <img src={reviewDislikeSelectedImg} className="reviewDislikeIcon" style={{paddingLeft:"5px"}} alt="negative" title="negative" />
                ) : (
                  <img onClick={() => this.setRating(-1)} src={reviewDislikeImg} className="reviewDislikeIcon" style={{paddingLeft:"5px"}} alt="negative" title="negative" />
                )}              
            </div>
            <div className="moveTop20"><InputTextarea placeholder="Leave a comment (optional)" className="textAreaReview widthReportIssue" name="ratingText" value={this.props.dialogReview.ratingText} onChange={this.changeInputValue} /></div>
            <div className="moveTop20">
              <ButtonAction disabled={pending} name="cancel" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" color="black" text="Cancel" onClick={pending ? null : this.handleCancel}/>
              <ButtonAction disabled={pending} name="submit" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Post review" onClick={pending ? null : this.handleSubmit}/>
            </div>
          </div>
        </div>
        <GapCard />
      </div>
    ); 
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogReview: state.dialogReview
  }
}

const DialogReviewContainer = connect(mapStateToProps, null)(DialogReview);
export default DialogReviewContainer;