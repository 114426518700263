import React, { Component } from 'react';
import './NoResultsCard.css';

class NoResultsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="noResultsCard">
        None
      </div>
    );
  }
}
  
export default NoResultsCard;