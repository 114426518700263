import React from 'react' //, { Component }
import { openOverlay } from './OverlayActions';
import { 
  apiNewPosting, 
  apiListPostings, 
  navNewURL,
  apiRequest, 
  apiPostingLifecycle,
  resultsNewByType, 
  apiGetUser, 
  apiFavorite,
  track,
  getSelectedArbitrator
} from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function postingLifecycle(lifecycle_status) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;
    let postingid = store.getState().posting.postingid;

    apiPostingLifecycle(uuid, username, postingid, lifecycle_status).then((success) => {
      if (success) {
        dispatch(refreshPosting(postingid));
      }
    });
  }
}

export function createNewPosting() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;

    let {
      postingid="",
      photoList=[],
      breadLocation="",
      breadType="",
      breadCategory="",
      title="",
      description="",
      priceInput="",
      priceType="",
      depositInput="", 
      depositType="", 
      locationDetails="",
      deadlineInput="",
      contractTemplate="",
      contractCustomTerms="",
      agreementVisibility="",
      arbitrationMode="",
      arbitrationFeeInput="",
      arbitrationFeeType="",
      requestArbitrationDays="",
      daysToRespondInput="",
      autoResolveDays="",
      hasAutomaticOutcome=false,
      defaultResolution="",
      searchArbitratorAutosuggest={},
      showDepositBySeller=false
    } = store.getState().posting;

    let photoIndexList = [];
    let imageList = [];
    for (let i = 0; i < photoList.length; i++) {
      imageList.push(photoList[i].imageContent);
      photoIndexList.push(photoList[i].photoIndex);
    }

    if (breadType === "other") {
      breadCategory = "other";
    }

    let arbitrator = getSelectedArbitrator(searchArbitratorAutosuggest);
    let isCustomTemplate = contractTemplate === "custom";
    
    apiNewPosting(
      uuid, 
      username, 
      postingid,
      breadLocation, 
      breadType, 
      breadCategory, 
      title, 
      description, 
      locationDetails, 
      priceInput, 
      priceType, 
      showDepositBySeller ? depositInput : "0", 
      showDepositBySeller ? depositType : "eth", 
      deadlineInput,
      contractTemplate,
      isCustomTemplate ? contractCustomTerms : "", 
      isCustomTemplate ? agreementVisibility : "", 
      isCustomTemplate ? arbitrationMode : "", 
      isCustomTemplate ? arbitrator : "", 
      isCustomTemplate ? arbitrationFeeInput : "", 
      isCustomTemplate ? arbitrationFeeType : "", 
      isCustomTemplate ? daysToRespondInput : "", 
      isCustomTemplate ? requestArbitrationDays : "",
      isCustomTemplate ? (hasAutomaticOutcome ? autoResolveDays : "0") : "",
      isCustomTemplate ? (hasAutomaticOutcome ? "1" : "0") : "",
      isCustomTemplate ? (hasAutomaticOutcome ? defaultResolution : "refund") : "",
      photoIndexList,
      imageList
    ).then(({success, postingid, errorField, errorMsg, warningMsg}) => {
      if (success) {
        dispatch(navNewURL('/posting?postingid=' + postingid));
        if (warningMsg !== "") {
          dispatch(openOverlay("message", {title: "Warning", message:<span>{warningMsg}</span>}));
        }
      }
      else {
        dispatch({
          type: 'POSTING_SUBMIT_ERROR',
          payload: {errorField, errorMsg}
        });

        dispatch({
          type: 'LOG_ERROR',
          payload: {reason:"New posting failed", error:JSON.stringify({errorField, errorMsg})}
        });

        console.log("Error creating posting");
      }

      dispatch({
        type: 'POSTING_CHANGE_INPUT_VALUE',
        payload: {name: "allUpdatePostingButtonsDisabled", value: false}
      });
    });
  }
}

export function refreshPosting(postingid) {
  return function(dispatch) {
    // We can pass in empty string for uuid and selecteduser because there's special logic on the backend
    // that always shows you a posting if you know the postingid
    apiListPostings("", "", "", "", "", "", "", postingid, "").then((respObj) => {
      dispatch({
        type: 'POSTING_SELECT',
        payload: respObj
      }); 
    });
  }
}

export function deleteExistingPhoto(i) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_DELETE_PHOTO',
      payload: i
    });
  }
}

export function changeArbitrationMode(value) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_CHANGE_ARBITRATION_MODE',
      payload: {value}
    });
  }
}

export function changeAutosuggestInput(name, value, search) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });
  }
}

export function changeAutosuggestResults(name, value, search, max, more, selected, nonce) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let exclude = agreement.user2Locked;
    
    dispatch({
      type: 'POSTING_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });

    let filter = (name === "searchArbitratorAutosuggest") ? "arbitrator" : "";

    apiRequest("autosuggest", { input: value, search: search, max: max, uuid: auth.uuid, username: auth.username, exclude, filter }).then((response) => {
      let data = response.data;
      let hasMore = response.hasMore && more;
      let exactMatchMap = response.exactMatchMap;
      
      let results = [];
      let selectedResult = null;
      let resultsFromMatch = data || [];
      for (let i = 0; i < resultsFromMatch.length; i++) {
        let resultEntry = resultsFromMatch[i];
        let type = resultEntry.type;
        if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
          selectedResult = resultEntry;
        }
        else {
          results.push(resultEntry);
        }
      }

      if (name !== "searchArbitratorAutosuggest") {
        let resultsByType = resultsNewByType(search, value, exactMatchMap);
        for (let i = 0; i < resultsByType.length; i++) {
          let resultEntry = resultsByType[i];
          let type = resultEntry.type;
          if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
            selectedResult = resultEntry;
          }
          else {
            results.push(resultEntry);
          }
        }
      }

      if (selectedResult !== null) {
        selectedResult.selected = true;
        results.unshift(selectedResult);
      }

      dispatch({
        type: 'POSTING_CHANGE_AUTOSUGGEST_RESULTS',
        payload: {name, results: results, more: hasMore, match: exactMatchMap, nonce: nonce }
      });
    });
  }
}

export function changeAutosuggestSelection(name, type, value, arbitration) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_CHANGE_AUTOSUGGEST_SELECTION',
      payload: { name, type, value, arbitration }
    });
  }
}

export function handleAutosuggestFavorite(name, contact, oldFavoriteSelected) {
  return function(dispatch) {    
    let favoriteSelected = !oldFavoriteSelected;
    let auth = store.getState().auth;
    dispatch({
      type: 'POSTING_AUTOSUGGEST_FAVORITE',
      payload: {name, contact, favoriteSelected, favoritePending:true}
    });
    apiFavorite(auth.uuid, auth.username, contact, favoriteSelected).then((success) => {
      if (success) {
        dispatch({
          type: 'POSTING_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected, favoritePending:false}
        });
        dispatch(track("action", "button", JSON.stringify({type:"autosuggest_favorite", contact, favoriteSelected})));
      }
      else {
        dispatch({
          type: 'POSTING_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected: oldFavoriteSelected, favoritePending:false}
        });
      }
    });
  }
}


export function clearAutosuggestResults(name, input, nonce, setArbitrationInfo) {
  return function(dispatch) {
    dispatch({
      type: 'POSTING_CLEAR_AUTOSUGGEST_RESULTS',
      payload: {name, nonce}
    });
    
    if (input !== "") {
      apiGetUser(input).then((response) => {
        let { hasUsername=false, arbitration_settings=null } = response;
        if (hasUsername) {
          let arbitration = setArbitrationInfo ? arbitration_settings : null;
          dispatch({
            type: 'POSTING_CHANGE_AUTOSUGGEST_SELECTION',
            payload: {name, type: "atstake", value: input, arbitration }
          });    
        }
      });
    }
  }
}
