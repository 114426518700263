import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Posting.css';
import './Common.css';
import GapCard from "./GapCard";
import TitleCard from "./TitleCard";
import InputBasic from './InputBasic';
import Link from './Link';
import TwoColumnContainer from './TwoColumnContainer';
import { 
  changeInputValue, 
  createNewPosting, 
  refreshPosting, 
  deleteExistingPhoto,
  changeAutosuggestInput, 
  changeAutosuggestResults, 
  clearAutosuggestResults, 
  changeAutosuggestSelection, 
  handleAutosuggestFavorite,
  changeArbitrationMode,
  postingLifecycle 
} from './PostingActions';
import { getDropdownTokenList } from './TokenUtils';
import { getTemplateTokenList } from './TemplateUtils';
import Dropdown from './Dropdown';
import InputTextarea from './InputTextarea';
import InputContractTerms from './InputContractTerms';
import ButtonAction from './ButtonAction';
import Breadcrumb from './Breadcrumb';
import { 
  navNewURL, 
  getAccountLink, 
  getPriceMessageWidthCutoff, 
  addCommasToNumber, 
  getVisibilityTooltipContent,
  getDropdownVisibilityList, 
  getDefaultArbitrationBullets,
  dateUnixToString,
  track
} from "./ActionUtils";
import { openOverlay } from './OverlayActions';
import deletePhotoImg from '../xcircle.svg';
import {
  DEFAULT_BUTTON_WIDTH,
  NEW_CONTRACT_LABEL
} from './Constants';
import { shared } from '../Shared';
import ButtonSelect from './ButtonSelect';
import QuestionTooltip from './QuestionTooltip';
import InputAutosuggest from './InputAutosuggest';
import InputSelectedUser from './InputSelectedUser';
import LoadCard from './LoadCard';
import { requireConfirmNav } from './AuthActions';

const ADD_PHOTO_LABEL = "Add photo";
const CREATE_POSTING_LABEL = "Create posting";
const CANCEL_POSTING_LABEL = "Cancel";
const CANCEL_UPDATE_LABEL = "Cancel edit";
const UPDATE_POSTING_LABEL = "Update posting";
const SIGN_IN_POSTING_LABEL = "Sign in to post";

class Posting extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.changeArbitrationMode = this.changeArbitrationMode.bind(this);
    this.getPriceMessage = this.getPriceMessage.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.createPosting = this.createPosting.bind(this);
    this.addPhoto = this.addPhoto.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.message = this.message.bind(this);
    this.edit = this.edit.bind(this);
    this.createContract = this.createContract.bind(this);
    this.showTermDetails = this.showTermDetails.bind(this);
    this.hideTermDetails = this.hideTermDetails.bind(this);
    this.depositBySeller = this.depositBySeller.bind(this);
    this.signIn = this.signIn.bind(this);
    this.fillDefaultTemplate = this.fillDefaultTemplate.bind(this);
    this.autosuggestChangeInput = this.autosuggestChangeInput.bind(this);
    this.autosuggestChangeResults = this.autosuggestChangeResults.bind(this);
    this.autosuggestChangeSelection = this.autosuggestChangeSelection.bind(this);
    this.autosuggestFavoriteClick = this.autosuggestFavoriteClick.bind(this);
    this.autosuggestClearResults = this.autosuggestClearResults.bind(this);
    this.changeHasAutomaticOutcome = this.changeHasAutomaticOutcome.bind(this);
    this.renewPosting = this.renewPosting.bind(this);
    this.expirePosting = this.expirePosting.bind(this);
    this.blockPosting = this.blockPosting.bind(this);
  }

  componentDidMount() {
    let {
      postingid=""
    } = this.props.posting;

    if (postingid !== "") {
      this.props.dispatch(refreshPosting(postingid));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      postingid="",
      postingLoaded=false
    } = this.props.posting;

    if (
      postingid !== prevProps.posting.postingid ||
      (postingLoaded === false && prevProps.posting.postingLoaded === true)
    ) {
      if (postingid !== "") {
        this.props.dispatch(refreshPosting(postingid));
      }
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  changeArbitrationMode(name, value) {
    this.props.dispatch(changeArbitrationMode(value));
  }

  getPriceMessage(input, type) {
    let inputWithCommas = addCommasToNumber(input);
    let msg = getPriceMessageWidthCutoff(input, type, inputWithCommas);
    return msg;
  }

  changeHasAutomaticOutcome(hasOutcome) {
    this.props.dispatch(changeInputValue("hasAutomaticOutcome", hasOutcome));
  }

  autosuggestClearResults(name, input, nonce, setArbitrationInfo) {
    this.props.dispatch(clearAutosuggestResults(name, input, nonce, setArbitrationInfo));
  }

  autosuggestChangeInput(name, value, search) {
    this.props.dispatch(changeAutosuggestInput(name, value, search));
  }

  autosuggestChangeResults(name, value, search, max, more, selected, nonce) {
    this.props.dispatch(changeAutosuggestResults(name, value, search, max, more, selected, nonce));
  }

  autosuggestChangeSelection(name, type, value, arbitration) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"autosuggest_select", name, selection: type + "#" + value})));
    this.props.dispatch(changeAutosuggestSelection(name, type, value, arbitration));
    this.props.dispatch(requireConfirmNav());
  }

  autosuggestFavoriteClick(name, contact, favorite) {
    this.props.dispatch(handleAutosuggestFavorite(name, contact, favorite));
  }

  cancelEdit(name, value) {
    let {
      postingid="",
    } = this.props.posting;

    let existingPosting = (postingid !== "");
    if (existingPosting) {
      this.props.dispatch(changeInputValue("allowEdit", false));
      this.props.dispatch(changeInputValue("postingLoaded", false));
    }
    else {
      this.props.dispatch(navNewURL('/contracts'));
    }
  }

  createPosting(name, value) {
    this.props.dispatch(changeInputValue("allUpdatePostingButtonsDisabled", true))
    this.props.dispatch(createNewPosting());
  }

  addPhoto(name, value) {
    this.props.dispatch(openOverlay("add_photo", {}));
  }

  showPreview(photoSrc) {
    this.props.dispatch(openOverlay("photo_preview", { photo: photoSrc }));
  }

  deletePhoto(event, indexToDelete) {
    event.stopPropagation();
    this.props.dispatch(deleteExistingPhoto(indexToDelete));
  }

  message(name, value) {
    let {
      user="",
    } = this.props.posting;

    let loggedin = this.props.auth.status === "loggedin";

    if (loggedin) {
      this.props.dispatch(navNewURL('/messages?user=' + user));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }

  edit(name, value) {
    this.props.dispatch(changeInputValue("allowEdit", true));
  }

  showTermDetails(name, value) {
    this.props.dispatch(changeInputValue("showDetailedTerms", true));
  }

  hideTermDetails(name, value) {
    this.props.dispatch(changeInputValue("showDetailedTerms", false));
  }

  depositBySeller(showDepositBySeller) {
    if (!showDepositBySeller) {
      this.props.dispatch(changeInputValue("depositInput", ""));
      this.props.dispatch(changeInputValue("depositType", "eth"));  
    }

    this.props.dispatch(changeInputValue("showDepositBySeller", showDepositBySeller));
  }

  signIn(name, value) {
    this.props.dispatch(openOverlay("login", {}));
  }

  fillDefaultTemplate(template) {
    let templateTerms = shared.getTermsForTemplate(template);
    this.props.dispatch(changeInputValue("contractCustomTerms", templateTerms));
  }

  createContract(name, value) {
    let {
      postingid=""
    } = this.props.posting;
    
    let loggedin = this.props.auth.status === "loggedin";

    if (loggedin) {
      this.props.dispatch(openOverlay("contract_from_posting", {postingid}));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }

  renewPosting(event) {
    this.props.dispatch(changeInputValue("allUpdatePostingButtonsDisabled", true))
    this.props.dispatch(postingLifecycle("active"));
  }

  expirePosting(event) {
    this.props.dispatch(changeInputValue("allUpdatePostingButtonsDisabled", true))
    this.props.dispatch(postingLifecycle("expired"));
  }

  blockPosting(event) {
    this.props.dispatch(postingLifecycle("blocked"));
  }

  render() {
    let {
      postingLoaded=false,
      postingid="",
      photoList=[],
      breadLocation="",
      breadType="",
      breadCategory="",
      user="",
      priceInput="",
      priceType="",
      depositInput="",
      depositType="",
      locationDetails="",
      title="",
      description="",
      deadlineInput="",
      contractTemplate="",
      contractCustomTerms="",
      searchArbitratorAutosuggest={},
      allowEdit=false,
      arbitrationMode="",
      requestArbitrationDays="",
      daysToRespondInput="",
      arbitrationFeeInput="",
      arbitrationFeeType="",
      autoResolveDays="",
      defaultResolution="",
      hasAutomaticOutcome=false,
      showDepositBySeller=false,
      showDetailedTerms=false,
      disableDetailedTerms=false,
      showErrorMsg=false,
      errorMessageText="",
      errorMessageType="",
      lifecycleStatus="",
      expiredUnix=0,
      updateUnix=0,
      showAdmin=false,
      allUpdatePostingButtonsDisabled=false
    } = this.props.posting;

    let isLoggedIn = (this.props.auth.status === "loggedin");

    if (contractTemplate !== "custom") {
      contractCustomTerms = shared.getTermsForTemplate(contractTemplate);
    }

    let dropdownTokenList = getDropdownTokenList();
    let dropdownTemplateList = getTemplateTokenList(breadType);

    let {
      username=""
    } = this.props.auth;
    let existingPosting = (postingid !== "");
    let userPoster = existingPosting ? user : username;
    let ownAccount = (username === userPoster);
    let disabled = !allowEdit;

    let photoElemList = [];
    for (let i = 0; i < photoList.length; i++) {
      let thumbnailSrc = photoList[i].thumbnailContent;
      let imageSrc = photoList[i].imageContent;
      photoElemList.push(
      <div key={i} onClick={() => this.showPreview(imageSrc)} className="moveTop20 moveRight20" style={{position: "relative", cursor: "pointer", display:"inline-block", verticalAlign: "top", background: "var(--content-border-color)", width: "100px", height: "75px", border: "1px solid var(--content-border-color)", textAlign: "center"}}><img style={{maxWidth: "100px", maxHeight: "75px", verticalAlign: "top"}} src={thumbnailSrc} alt="Thumbnail" />{allowEdit && <img onClick={(event) => this.deletePhoto(event, i)} style={{position: "absolute", filter: "grayscale(1)", top: "-7px", right: "-7px", cursor: "pointer", width: "20px", height: "20px"}} src={deletePhotoImg} alt="Delete" />}</div>
      );
    }

    if (allowEdit) {
      photoElemList.push(
        <ButtonAction key="add_photo" disabled={disabled} color="light" className="moveTop20 moveRight20" text={<div><div style={{fontSize:"28px"}}>+</div>{ADD_PHOTO_LABEL}</div>} minWidth={this.props.posting.maxButtonWidth} onClick={disabled ? null : this.addPhoto}/>
      );
    }

    let breadList = [];

    if (breadLocation !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadLocation, "location"), field: breadLocation, level: "location" }
      );
    }

    if (breadType !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadType, "type"), field: breadType, level: "type" }
      );  
    }

    if (breadCategory !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadCategory, "category"), field: breadCategory, level: "category" }
      );  
    }

    let isCustomTemplate = this.props.posting.contractTemplate === "custom";
    let isNoneTemplate = this.props.posting.contractTemplate === "none";
    let contractTermsDisabled = (disabled || !isCustomTemplate);
    let dropdownVisibilityList = getDropdownVisibilityList();
    let selectedArbitratorKey = "";
    let selectedArbitratorLock = "";
    if (searchArbitratorAutosuggest && searchArbitratorAutosuggest.hasOwnProperty("selected")) {
      selectedArbitratorKey = searchArbitratorAutosuggest.selected;
      if (selectedArbitratorKey) {
        let splitArr = selectedArbitratorKey.split('#');
        selectedArbitratorLock = splitArr.length > 1 ? splitArr[1] : "";
      }
    }
    
    let agreementType = "contract";
    if (breadType === "item_offered" && (depositInput === "" || depositInput === "0" || parseFloat(depositInput) === 0.0)) {
      agreementType = "payment";
    }
    else if (breadType === "item_wanted" && (depositInput === "" || depositInput === "0" || parseFloat(depositInput) === 0.0)) {
      agreementType = "request";
    }

    let buyerLabel = "buyer";
    let providerLabel = "seller";
    let descriptionPlaceholder = "Detailed description of what is being offered and/or requested and related conditions";
    let deadlinePlaceholder = "How long will it take after payment is deposited for the service to be provided? Include timelines for milestones if applicable.";
    let deadlineLabel = "Deadline to provide service";
    if (breadType === "item_wanted" || breadType === "item_offered") {
      descriptionPlaceholder = "Detailed description of item";
      deadlinePlaceholder = "How long will it take after payment is deposited for the item to be delivered? If this varies by location, specify that here.";
      deadlineLabel = "Deadline to complete delivery";
      if (breadType === "item_wanted") {
        if (ownAccount) {
          buyerLabel = "you";
        } else {
          providerLabel = "you";
        }
      } else {
        if (ownAccount) {
          providerLabel = "you";
        } else {
          buyerLabel = "you";
        }
      }
    } else if (breadType === "service_wanted" || breadType === "service_offered") {
      descriptionPlaceholder = "Detailed description of service and related conditions";
      if (breadType === "service_wanted") {
        if (ownAccount) {
          buyerLabel = "you";
        } else {
          providerLabel = "you";
        }
      } else {
        if (ownAccount) {
          providerLabel = "you";
        } else {
          buyerLabel = "you";
        }
      }
    } else if (breadType === "rental_wanted" || breadType === "rental_offered") {
      descriptionPlaceholder = "Detailed description of rental item, how much of the deposit will be returned, and other conditions";
      deadlinePlaceholder = "How long will it take after payment is deposited for the rental item to be provided? If this varies by location, specify that here.";
      deadlineLabel = "Deadline to provide item";
      buyerLabel = "renter";
      providerLabel = "owner";
      if (breadType === "rental_wanted") {
        if (ownAccount) {
          buyerLabel = "you";
        } else {
          providerLabel = "you";
        }
      } else {
        if (ownAccount) {
          providerLabel = "you";
        } else {
          buyerLabel = "you";
        }
      }
    } else if (breadType === "other") {
      buyerLabel = ownAccount ? "counterparty" : "you";
      providerLabel = ownAccount ? "you" : user;
    }

    let useDepositWording = breadType === "rental_wanted" || breadType === "rental_offered" || breadType === "other";

    let defaultArbitrationSettings = shared.defaultArbitrationSettings(agreementType);
    let { selectedArbitration=null } = searchArbitratorAutosuggest;
    let defaultArbitrationFee = "";
    let defaultArbitrationFeePriceMsg = "";
    let minArbitrationFeeMsg = "";
    if (selectedArbitration && selectedArbitration.hasOwnProperty("fee_value") && selectedArbitration.hasOwnProperty("fee_type")) {
      defaultArbitrationFee = selectedArbitration.fee_value + " " + shared.upperCase(selectedArbitration.fee_type);
      defaultArbitrationFeePriceMsg = this.getPriceMessage(selectedArbitration.fee_value, selectedArbitration.fee_type);
      minArbitrationFeeMsg = "Arbitrator's minimum fee is " + defaultArbitrationFee + (defaultArbitrationFeePriceMsg === "" ? "" : " (" + defaultArbitrationFeePriceMsg + ")");
    }

    let showDefaultView = false;
    if (arbitrationMode !== "custom") {
      // We make some assumptions in the below code that our default settings having particular values. 
      // We have a bunch of assert statements in executeAgreementAssertions to enforce these assumptions. 
      // If we ever need to change our default values, we can write some more general code here.
      if (agreementType === "payment" || agreementType === "request") {
        if (selectedArbitration && 
            requestArbitrationDays.toString() === defaultArbitrationSettings.arbitration_request_days.toString() &&
            daysToRespondInput.toString() === defaultArbitrationSettings.arbitration_response_days.toString() &&
            arbitrationFeeInput === selectedArbitration.fee_value &&
            arbitrationFeeType === selectedArbitration.fee_type
        ) {
          showDefaultView = true;
        }
      } else { // handle the contract case
        if (selectedArbitration && 
            requestArbitrationDays.toString() === defaultArbitrationSettings.arbitration_request_days.toString() &&
            daysToRespondInput.toString() === defaultArbitrationSettings.arbitration_response_days.toString() &&
            arbitrationFeeInput === selectedArbitration.fee_value &&
            arbitrationFeeType === selectedArbitration.fee_type
        ) {
          showDefaultView = true;
        }
      }
    }

    if (!postingLoaded) {
      return (
        <div className="contentSection">
          <LoadCard />
        </div>
      );
    }

    let dropdownResolutionList = [];

    let takerDisp = !ownAccount ? "you" : "them";
    let posterDisp = ownAccount ? "you" : "them";
    
    dropdownResolutionList.push({
      name: "all_user2", 
      text: shared.resolutionDisplay("all_user2", takerDisp, posterDisp)
    });

    dropdownResolutionList.push({
      name: "all_user1", 
      text: shared.resolutionDisplay("all_user1", takerDisp, posterDisp)
    });

    dropdownResolutionList.push({
      name: "refund",
      text: shared.resolutionDisplay("refund", takerDisp, posterDisp)
    });

    dropdownResolutionList.push({
      name: "trade", 
      text: shared.resolutionDisplay("trade", takerDisp, posterDisp)
    });

    dropdownResolutionList.push({
      name: "fifty", 
      text: shared.resolutionDisplay("fifty", takerDisp, posterDisp)
    });

    if (allowEdit && !showErrorMsg && title.length > 40) {
      showErrorMsg = true;
      errorMessageType = "title";
      errorMessageText = "Title can be at most 40 characters";
    }

    return (
      <div className="contentSection">
        <GapCard />
        <TitleCard title={allowEdit ? "New posting" : <span>{shared.getBreadcrumbDisplay(breadType, "type")} by {<Link noTarget={true} className="blacklink" href={getAccountLink(user)} >{ownAccount ? "you" : user}</Link>}</span>} /> 
        {lifecycleStatus === "expired" && <div>
          <div className="expiredCard">
            This posting expired{expiredUnix === 0 ? "" : " on " + dateUnixToString(expiredUnix)}.{ownAccount && <span> <span onClick={this.renewPosting} className="bluelink">Click to renew</span>.</span>}
          </div>
          <GapCard />
        </div>}
        {showAdmin && <div>
          <div className="expiredCard">
            Admin: Posting status is <span style={{fontWeight:"bold"}}>{lifecycleStatus}</span> for posting <span style={{fontWeight:"bold"}}>{postingid}</span>.
            {<div className="moveTop10"> <span onClick={this.renewPosting} className="bluelink">Set active</span></div>}
            {<div className="moveTop10"> <span onClick={this.expirePosting} className="bluelink">Set expired</span></div>}
            {<div className="moveTop10"> <span onClick={this.blockPosting} className="bluelink">Set blocked</span></div>}
          </div>
          <GapCard />
        </div>}
        <div className="contentCard">
            <Breadcrumb noTarget={true} list={breadList} />

            <TwoColumnContainer singleColumnHasMax="1" 
              leftComp={
                <div>
                  <div className="contentLabel moveTop20">Title</div>
                  <InputBasic maxlength={shared.MAX_POSTING_TITLE_LENGTH} disabled={disabled} placeholder={allowEdit ? "Posting title" : ""} type="text" className="fullWidthInput moveRight5 moveTop5" name="title" value={title} onChange={this.changeInputValue} />
                  {showErrorMsg && errorMessageType === "title" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } 
              
              rightComp={
                <div>
                  <div className="contentLabel moveTop20">Location details</div>
                  <InputBasic maxlength={shared.MAX_POSTING_LOCATION_DETAILS_LENGTH} disabled={disabled} placeholder={allowEdit ? "Enter location details" : ""} type="text" className="fullWidthInput moveRight5 moveTop5" name="locationDetails" value={locationDetails} onChange={this.changeInputValue} />
                  {showErrorMsg && errorMessageType === "location_details" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              }
            />

            <TwoColumnContainer singleColumnHasMax="1" 
              leftComp={
                <div>
                  <div className="contentLabel moveTop20"><span className="moveRight10">{showDepositBySeller || useDepositWording ? ("Deposit by " + buyerLabel) : "Price"}</span>{(showDepositBySeller || !allowEdit) ? null : <span onClick={() => this.depositBySeller(true)} className="small bluelink">Add deposit by {providerLabel} +</span>}</div>
                  <div className="moveTop5">
                    <InputBasic appendRight={this.getPriceMessage(priceInput, priceType)} disabled={disabled} restrict="float" placeholder={allowEdit ? "Amount" : ""} type="text" className="amountTwoColumnInput moveRight5" name="priceInput" value={addCommasToNumber(priceInput)} onChange={this.changeInputValue} />
                    <Dropdown disabled={disabled} size="small" name="priceType" selected={priceType} list={dropdownTokenList} onChange={this.changeInputValue} />
                  </div>
                  {showErrorMsg && (errorMessageType === "price_amount" || errorMessageType === "price_type") && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } 

              rightComp={
                showDepositBySeller ? <div>
                <div className="contentLabel moveTop20"><span className="moveRight10">Deposit by {providerLabel}</span>{(!showDepositBySeller || !allowEdit) ? null : <span onClick={() => this.depositBySeller(false)} className="small bluelink">Remove deposit by {providerLabel} -</span>}</div>
                  <div className="moveTop5">
                    <InputBasic appendRight={this.getPriceMessage(depositInput, depositType)} disabled={disabled} restrict="float" placeholder={allowEdit ? "Amount" : ""} type="text" className="amountTwoColumnInput moveRight5" name="depositInput" value={addCommasToNumber(depositInput)} onChange={this.changeInputValue} />
                    <Dropdown disabled={disabled} size="small" name="depositType" selected={depositType} list={dropdownTokenList} onChange={this.changeInputValue} />
                  </div>
                  {showErrorMsg && (errorMessageType === "deposit_amount" || errorMessageType === "deposit_type") && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div> : null
              }
            />

            <TwoColumnContainer singleColumnHasMax="1" fullComp={
              <div>
                <div className="labelInput moveTop20">Description</div>
                <div className="moveTop5"><InputTextarea maxlength={shared.MAX_POSTING_DESCRIPTION_LENGTH} disabled={disabled} placeholder={allowEdit ? descriptionPlaceholder : ""} className="textAreaMedium widthAgreementTerms" name="description" value={description} onChange={this.changeInputValue} /></div>
                {showErrorMsg && errorMessageType === "description" && <div className="moveTop5 inputError">{errorMessageText}</div>}
              </div>
            } />

            <TwoColumnContainer singleColumnHasMax="1" 
              fullComp={
                <div>
                  <div className="contentLabel moveTop20">{deadlineLabel}</div>
                  <div className="moveTop5"><InputTextarea maxlength={shared.MAX_POSTING_DEADLINE_LENGTH} disabled={disabled} placeholder={allowEdit ? deadlinePlaceholder : ""} className="textAreaSmall widthAgreementTerms" name="deadlineInput" value={deadlineInput} onChange={this.changeInputValue} /></div>
                  {showErrorMsg && errorMessageType === "deadline" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } 
            />

            <TwoColumnContainer fullComp={
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>{photoElemList}</td>
                    </tr>
                  </tbody>
                </table>
                {showErrorMsg && errorMessageType === "photo_list" && <div className="moveTop5 inputError">{errorMessageText}</div>}
              </div>
            } />

            {existingPosting && updateUnix !== 0 && <TwoColumnContainer singleColumnHasMax="1" 
              fullComp={
                <div>
                  <div className="small moveTop20">This posting last updated on {dateUnixToString(updateUnix)}.</div>
                </div>
              } 
            />}

        </div>

        <div className="moveTop20"></div>
        <TitleCard title="Terms and conditions" /> 
        <div className="contentCard">
          <TwoColumnContainer singleColumnHasMax="1" leftComp={
              <div>
                <div className="labelInput ">Template for terms and conditions</div>
                <div className="moveTop5">
                  <Dropdown disabled={disabled} size="medium" name="contractTemplate" selected={contractTemplate} list={dropdownTemplateList} onChange={this.changeInputValue} />
                </div>
                
                {showErrorMsg && errorMessageType === "contract_template" && <div className="moveTop5 inputError">{errorMessageText}</div>}
              </div>
            } />

          {isNoneTemplate && <div className="moveTop20">You or your counterparty will later have to create a contract for this posting from scratch.</div>}

          {!disableDetailedTerms && <div>
              <div className="moveTop20">
                {showDetailedTerms ? <span onClick={this.hideTermDetails} className="bluelink">Hide details -</span> : <span onClick={this.showTermDetails}className="bluelink">Show details +</span>}
              </div>

              {showDetailedTerms && <TwoColumnContainer singleColumnHasMax="1" fullComp={
                <div>
                  <div className="moveTop5"><InputContractTerms maxlength={shared.MAX_POSTING_CONTRACT_DETAILS_LENGTH} postingid={postingid} youArePoster={ownAccount} userPoster={userPoster} title={title} description={description} priceAmount={priceInput} priceType={priceType} depositAmount={depositInput} depositType={depositType} deadlineInput={deadlineInput} locationDetails={locationDetails} breadLocation={breadLocation} breadType={breadType} breadCategory={breadCategory} photoList={photoList} disabled={contractTermsDisabled} placeholder={allowEdit ? "Describe expections, deadlines, quality measures, and what should happen if something goes wrong." : ""} className="textAreaInput widthAgreementTerms" name="contractCustomTerms" value={contractCustomTerms} onChange={this.changeInputValue} /></div>
                  {isCustomTemplate && <div className="moveTop5">
                    <span className="bluelink small" onClick={() => this.fillDefaultTemplate(breadType)}>{"Fill in default template (" + shared.getBreadcrumbDisplay(breadType, "type") + ")"}</span>
                  </div>}
                  {showErrorMsg && errorMessageType === "contract_details" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />}

              {showDetailedTerms && !isCustomTemplate && <TwoColumnContainer singleColumnHasMax="1" fullComp={                 
                <div>
                  <div className="contentLabel moveTop40">Arbitration settings</div>
                  <div className="moveTop5">
                    {getDefaultArbitrationBullets(agreementType, defaultArbitrationSettings, defaultArbitrationFee, defaultArbitrationFeePriceMsg, true, true)}
                  </div>
                </div>
              } />}

              {showDetailedTerms && isCustomTemplate && <div>

                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">
                      <span>Visibility</span>
                      <QuestionTooltip alt="Learn more" message={
                        getVisibilityTooltipContent()
                      } screenWidth={this.props.auth.screenDimensions.width} textAligned={true} />
                    </div>
                    <div className="moveTop5">
                      {(!disabled) ? (
                        <Dropdown size="small" disabled={disabled} name="agreementVisibility" selected={this.props.posting.agreementVisibility} list={dropdownVisibilityList} onChange={this.changeInputValue} />
                      ) : (
                        <InputBasic onButtonAction={this.showVisibilityDialog} buttonActionText={""} disabled={true} placeholder="Visibility of agreement" type="text" className="smallWidthInput" name="visibility" value={shared.visibilityText(this.props.posting.agreementVisibility)} />  
                      )}
                      {showErrorMsg && errorMessageType === "visibility" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                    </div>
                  </div>
                } />

                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">
                      <span>Using arbitrator</span>
                      <QuestionTooltip alt="Learn more" message={
                        <div>
                          <div>Atstake allows you to choose anyone to arbitrate your contracts.</div>
                          <div className="moveTop10">To find existing users who are willing to arbitrate contracts go to the 'People' page.
                          We recommend that you carefully review the identity and reputation of any user before selecting them as your arbitrator.</div>
                          <div className="moveTop10">If you want someone to arbitrate your contract who isn't currently an Atstake user, 
                          ask them to sign up and to configure their arbitration settings on their 'About' page under their profile.</div>
                        </div>
                      } screenWidth={this.props.auth.screenDimensions.width} textAligned={true} />
                    </div>
                    
                    {!disabled && (
                      <div className="moveTop5"><InputAutosuggest disabled={disabled} showPrice={true} className="moveTop5" name="searchArbitratorAutosuggest" passArbitrationInfo={true} value={this.props.posting.searchArbitratorAutosuggest} onChange={this.autosuggestChangeInput} onChangeAutosuggest={this.autosuggestChangeResults} onClearResults={this.autosuggestClearResults} onSelect={this.autosuggestChangeSelection} onFavoriteClick={this.autosuggestFavoriteClick} /></div>
                    )}
                    {disabled && (
                      <div className="moveTop5">
                        <InputSelectedUser username={this.props.auth.username} className="" selectedUserLocked={selectedArbitratorLock} selectedUserLinkId={selectedArbitratorKey} />
                      </div>
                    )}

                    {showErrorMsg && errorMessageType === "arbitrator" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                  } />

                <TwoColumnContainer singleColumnHasMax="1" leftComp={
                  <div>
                    <div className="contentLabel moveTop20">Arbitration settings</div>
                    <div className="moveTop5">
                      <ButtonSelect disabled={disabled} className="moveRight20" text="Default" minWidth={DEFAULT_BUTTON_WIDTH} name="arbitrationMode" value="default" selected={this.props.posting.arbitrationMode} onChange={this.changeArbitrationMode}/>
                      <ButtonSelect disabled={disabled} className="" text="Custom" minWidth={DEFAULT_BUTTON_WIDTH} name="arbitrationMode" value="custom" selected={this.props.posting.arbitrationMode} onChange={this.changeArbitrationMode}/>
                    </div>
                    {showErrorMsg && errorMessageType === "arbitration_mode" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                  </div>
                } />

                {showDefaultView && <TwoColumnContainer singleColumnHasMax="1" fullComp={                 
                  <div>
                    {getDefaultArbitrationBullets(agreementType, defaultArbitrationSettings, defaultArbitrationFee, defaultArbitrationFeePriceMsg, true, false)}
                  </div>
                } />}

                {!showDefaultView && <div>
                  <TwoColumnContainer singleColumnHasMax="1" leftComp={
                      <div>
                        <div className="contentLabel moveTop20">Arbitration fee</div>
                        <div className="moveTop5">
                          <InputBasic appendRight={this.getPriceMessage(arbitrationFeeInput, arbitrationFeeType)} disabled={disabled} restrict="float" placeholder="Fee amount" type="text" className="amountTwoColumnInput moveRight5" name="arbitrationFeeInput" value={addCommasToNumber(arbitrationFeeInput)} onChange={this.changeInputValue} />
                          <Dropdown disabled={disabled} size="small" name="arbitrationFeeType" selected={arbitrationFeeType} list={dropdownTokenList} onChange={this.changeInputValue} />
                        </div>
                        {minArbitrationFeeMsg !== "" && <div className="moveTop5 minFeeMsg">{minArbitrationFeeMsg}</div>}
                        {showErrorMsg && (errorMessageType === "arbitration_fee_value" || errorMessageType === "arbitration_fee_type") && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div>
                    } />

                    <TwoColumnContainer singleColumnHasMax="1" leftComp={
                      <div>
                        <div className="contentLabel moveTop20">Arbitration can be requested after</div>
                        <InputBasic maxlength={shared.NUMBER_OF_DAYS_MAX_STRING_LENGTH} disabled={disabled} append={requestArbitrationDays === "1" ? "day" : "days"} restrict="integer" placeholder="Number of days" type="text" className="standardInput moveTop5" name="requestArbitrationDays" value={requestArbitrationDays} onChange={this.changeInputValue} />
                        {showErrorMsg && errorMessageType === "arbitration_request_days" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div>
                    } rightComp={
                      <div>
                        <div className="contentLabel moveTop20">We both must respond to arbitration requests within</div>
                        <InputBasic maxlength={shared.NUMBER_OF_DAYS_MAX_STRING_LENGTH} disabled={disabled} append={daysToRespondInput === "1" ? "day" : "days"} restrict="integer" placeholder="Number of days" type="text" className="standardInput moveTop5" name="daysToRespondInput" value={daysToRespondInput} onChange={this.changeInputValue} />
                        {showErrorMsg && errorMessageType === "arbitration_days" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div>
                    } />

                    <TwoColumnContainer singleColumnHasMax="1" leftComp={
                      <div>
                        <div className="contentLabel moveTop20">Automatic outcome occurs {hasAutomaticOutcome && <span>after </span>}{allowEdit && !hasAutomaticOutcome && <span onClick={() => this.changeHasAutomaticOutcome(true)} className="small bluelink">Add automatic outcome +</span>}</div>
                        {hasAutomaticOutcome && <InputBasic maxlength={shared.NUMBER_OF_DAYS_MAX_STRING_LENGTH} disabled={disabled} append={autoResolveDays === "1" ? "day" : "days"} restrict="integer" placeholder="Number of days" type="text" className="standardInput moveTop5" name="autoResolveDays" value={autoResolveDays} onChange={this.changeInputValue} />}
                        {!hasAutomaticOutcome && <div className="disabledInputField moveTop5">Never</div>}
                        {showErrorMsg && errorMessageType === "auto_resolve_days" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div>
                    } rightComp={
                      hasAutomaticOutcome ? 
                      <div>
                        <div className="contentLabel moveTop20">When the automatic outcome occurs then {allowEdit && hasAutomaticOutcome && <span onClick={() => this.changeHasAutomaticOutcome(false)} className="small bluelink">Remove automatic outcome -</span>}</div>
                        <div className="moveTop5">
                          <Dropdown disabled={disabled} size="normal" name="defaultResolution" selected={defaultResolution} list={dropdownResolutionList} onChange={this.changeInputValue} />
                        </div>
                        {showErrorMsg && errorMessageType === "default_resolution" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                      </div> : null
                    } />
                </div>}
                
              </div>}
          </div>}
        </div>
        <div className="contentSection moveTop15">
          <div className="contentCard">
            {allowEdit ? (
              <TwoColumnContainer singleColumnHasMax="1" fullComp={
                <div>
                  <ButtonAction disabled={disabled} color="black" className="moveTop5 moveRight20" text={existingPosting ? CANCEL_UPDATE_LABEL : CANCEL_POSTING_LABEL} minWidth={this.props.posting.maxButtonWidth} onClick={disabled ? null : this.cancelEdit}/>
                  {isLoggedIn && <ButtonAction disabled={disabled || allUpdatePostingButtonsDisabled} className="moveTop5" text={existingPosting ? UPDATE_POSTING_LABEL : CREATE_POSTING_LABEL} minWidth={this.props.posting.maxButtonWidth} onClick={disabled || allUpdatePostingButtonsDisabled ? null : this.createPosting}/>}
                  {!isLoggedIn && <ButtonAction disabled={disabled} className="moveTop5" text={SIGN_IN_POSTING_LABEL} minWidth={this.props.posting.maxButtonWidth} onClick={disabled ? null : this.signIn}/>}
                  {showErrorMsg && errorMessageType === "" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            ) : (
              <TwoColumnContainer singleColumnHasMax="1" fullComp={
                ownAccount ? 
                <div>
                  {lifecycleStatus === "active" && <ButtonAction disabled={allUpdatePostingButtonsDisabled} color="black" className="moveTop5 moveRight20" text="Expire posting" minWidth={this.props.posting.maxButtonWidth} onClick={allUpdatePostingButtonsDisabled ? null : this.expirePosting}/>}
                  {lifecycleStatus === "expired" && <ButtonAction disabled={allUpdatePostingButtonsDisabled} color="black" className="moveTop5 moveRight20" text="Renew posting" minWidth={this.props.posting.maxButtonWidth} onClick={allUpdatePostingButtonsDisabled ? null: this.renewPosting}/>}
                  <ButtonAction className="moveTop5 moveRight20" text="Edit posting" minWidth={this.props.posting.maxButtonWidth} onClick={this.edit}/>
                </div> : 
                <div>
                  <ButtonAction className="moveTop5 moveRight20" text={"Message " + user} minWidth={this.props.posting.maxButtonWidth} onClick={this.message}/>               
                  <ButtonAction className="moveTop5 moveRight20" text={NEW_CONTRACT_LABEL} minWidth={this.props.posting.maxButtonWidth} onClick={this.createContract}/>
                  {showErrorMsg && errorMessageType === "" && <div className="moveTop5 inputError">{errorMessageText}</div>}
                </div>
              } />
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    posting: state.posting
  }
}

const PostingContainer = connect(mapStateToProps, null)(Posting);
export default PostingContainer;