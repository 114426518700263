import React, { Component } from 'react';
import './NavIcon.css';
import Link from './Link';
import { connect } from 'react-redux';
import { dateUnixToShortString } from './ActionUtils';

class NavIcon extends Component {
    constructor(props) {
      super(props);

      // This binding is necessary to make `this` work in the callback
      this.handleMenuClick = this.handleMenuClick.bind(this);
      this.handleOutsideClick = this.handleOutsideClick.bind(this);
      this.handleMenuSelect = this.handleMenuSelect.bind(this);
      this.node = null;
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
    }

    handleOutsideClick(event) {
      let showDropdown = this.props.showDropdown || false;
      if (showDropdown) {
        if(this.node.contains(event.target)) {
          return;
        }
  
        let handleOnClick = this.props.onMenuClick || null;
        handleOnClick("");  
      }
    }

    handleMenuClick(event) {
      let handleOnClick = this.props.onMenuClick || null;
      let showDropdown = this.props.showDropdown || false;
      let action = this.props.action || "";
      if (handleOnClick) {
        if (showDropdown) {
          handleOnClick("");
        }
        else {
          handleOnClick(action);
        }
      }
    }

    handleMenuSelect(event, action) {
      let handleOnClick = this.props.onMenuClick || null;
      let onNavigateMenu = this.props.onNavigateMenu || null;
      if (onNavigateMenu) {
        handleOnClick("");
        onNavigateMenu(event, action);
      }
    }

    render() {
      let image = this.props.image || "";
      let label = this.props.label || "";
      let action = this.props.action || "";
      let showLabels = this.props.showLabels || false;
      let showDropdown = this.props.showDropdown || false;
      let menuTop = this.props.menuTop || null;
      let menuList = this.props.menuList || null;
      let hasMenuList = menuList !== null && menuList.length > 0;
      let onNavigateMenu = this.props.onNavigateMenu || null;
      let notifyCount = this.props.notifyCount || 0;
      let adjust = this.props.adjust || "";

      let menuListElem = [];
      if (hasMenuList) {
        for (let i = 0; i < menuList.length; i++) {
          let menuObj = menuList[i];
          let {
            icon=null,
            timestamp_unix=0,
            action=""
          } = menuObj;
          
          let hasTimestamp = (timestamp_unix !== 0);
          let timeStr = dateUnixToShortString(timestamp_unix);

          let entryElem = (
            <table style={{width: "100%"}}><tbody>
              <tr className="">
                <td className="menuDropdownImgEntry"><img className="notifyImg" src={icon} alt="" /></td>
                <td className="menuDropdownMsgEntry">{menuObj.label}</td>
                {hasTimestamp && <td className="menuDropdownTimeEntry">{timeStr}</td>}
              </tr>
            </tbody></table>
          );
          
          // People don't like not being able to right-click on manu items, so only
          // make the logout option a button
          if (onNavigateMenu && action === "logout") {
            menuListElem.push(
              <div  key={"entry_" + i}>
                <span name={action} className="menuDropdownLink" onClick={(event) => this.handleMenuSelect(event, action)}>
                  {entryElem}
                </span>
              </div>
            );
          }
          else { 
            menuListElem.push(
              <div  key={"entry_" + i}>
                <Link href={"/" + action} noTarget={true} rel="noopener noreferrer" className="menuDropdownLink">
                  {entryElem}
                </Link>
              </div>
            );  
          } 
        }
      }

      let menuActionImage = "menuActionImg";
      if (adjust === "big") {
        menuActionImage = "menuActionBigImg";
      }
      else if (adjust === "biggest") {
        menuActionImage = "menuActionBiggestImg";
      }
      else if (adjust === "medium") {
        menuActionImage = "menuActionMediumImg";
      } else if (adjust === "down") {
        menuActionImage = "menuActionDownImg";
      }
      
      let contentElem = (
        <div ref={node => this.node = node}>
          <div className="menuActionCell" onClick={this.handleMenuClick}>
            {notifyCount > 0 && <div className="notificationWrap"><div className="notificationInner"><span className="notification">{notifyCount < 100 ? notifyCount : "99+"}</span></div></div>}
            <img className={menuActionImage} src={image} alt="" />
            {showLabels ? <div>
              {label}
              {hasMenuList && <span className="menuPolyCnt">
                <svg className="menuPolySvg" xmlns="http://www.w3.org/2000/svg" viewBox="7 10 10 6" aria-hidden="true" focusable="false"><polygon className="menuPolyTri" points="16.5 10 12 16 7.5 10 16.5 10"></polygon></svg>
              </span>}
            </div> : <div className="menuSideArr">
              {hasMenuList && (
                <span className="menuPolyCnt">
                  <svg className="menuPolySvg" xmlns="http://www.w3.org/2000/svg" viewBox="7 10 10 6" aria-hidden="true" focusable="false"><polygon className="menuPolyTri" points="16.5 10 12 16 7.5 10 16.5 10"></polygon></svg>
                </span>
              )}
            </div>}
          </div>
          {showDropdown && <div className="menuActionAnchor">
            <div className="menuActionDropdown">
              <span className="topArrowOuter"></span>
              <span className="topArrowInner"></span>
              <div className="menuDropdownTop">{menuTop}</div>
              {menuListElem}
            </div>
          </div>}
        </div>
      );

      if (hasMenuList) {
        return contentElem;
      }
      else {
        /* People don't like that they can't right click on menu items.. 
        if (onNavigateMenu) {
          return <span className="menuActionLink" name={action} onClick={onNavigateMenu}>{contentElem}</span>;
        }*/

        return <Link className="menuActionLink" href={"/" + action} noTarget={true} rel="noopener noreferrer">{contentElem}</Link>;
      }
    }
  }

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const NavIconContainer = connect(mapStateToProps, null)(NavIcon);
export default NavIconContainer;