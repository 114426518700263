import update from 'immutability-helper';

const emptyState = {
  errorList: []
}

const ErrorReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "LOG_ERROR": {
      let errorObj = action.payload;
      let newErrorList = update(state.errorList, {$push: [errorObj]});
      if (newErrorList.length > 50) {
        newErrorList.shift();
      }
      return {...state, errorList: newErrorList};
    }
    default: {
      return state
    }
  }
}

export default ErrorReducer