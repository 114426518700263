import { connect } from 'react-redux';
import React, { Component } from 'react';
import './Help.css';
import { openOverlay } from './OverlayActions';
import GapCard from "./GapCard";
import Link from './Link';
import { genURL } from "./ActionUtils";
import TwoColumnContainer from './TwoColumnContainer';

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // This binding is necessary to make `this` work in the callback
    this.handleTestModeClick = this.handleTestModeClick.bind(this);
    this.scrollFromHash = this.scrollFromHash.bind(this);
  }

  scrollFromHash(hash) {
    window.setTimeout(() => {
      if (hash === "#gettingstarted") {
        document.getElementById('gettingstarted').scrollIntoView({block: "start", behavior: "smooth"});
      }
    }, 300);
  }

  componentDidMount() {
    this.scrollFromHash(this.props.menu.hash);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.menu.hash !== prevProps.menu.hash) {
      this.scrollFromHash(this.props.menu.hash);
    }
  }


  handleTestModeClick(event) {
    let testMode = this.props.auth.testMode;
    this.props.dispatch(openOverlay("testmode", {pathname: "", mode: (testMode ? "test":"prod") }));
    console.log("handling click");
  }

  render() {

    return (
      <div>
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div style={{textAlign: "left"}} className="pageTitle">What is Atstake?</div>
            <div className="pageLightText moveTop20">
              Atstake is a platform for creating two-person contracts where one or both people stake money which is later distributed between them based on any criteria. 
              Atstake offers both a way to <Link href={genURL("/postings")} className="bluelink" noTarget={true}>discover</Link> counterparties
              for your contracts as well as tools to ensure your contracts are <Link href={genURL("/incentives")} className="bluelink" noTarget={true}>fairly enforced</Link>.
            </div>
            <div className="pageLightText moveTop20">
              Atstake is an extremely general platform capable of handling many types of 
              contracts. <Link href={genURL("/examples")} className="bluelink" noTarget={true}>See examples &#9656;</Link>
            </div>
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-d-bg-color)"}}>
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageTitle">Learn</div>
            <TwoColumnContainer showBorder={false} className="moveTop40" leftComp={
              <div>
                <div className="pageLightText moveTop20 medium" style={{textAlign: "center"}}>
                  <Link href={genURL("/how")} className="bluelink" noTarget={true}>How Atstake works</Link>
                </div>
              </div>
            } rightComp={
              <div>
                <div className="pageLightText moveTop20 medium" style={{textAlign: "center"}}>
                  <Link href={genURL("/faq#whyethereum")} className="bluelink" noTarget={true}>Why we use Ethereum</Link>
                </div>
              </div>
            } />
            <TwoColumnContainer showBorder={false} className="" leftComp={
              <div>
                <div className="pageLightText moveTop20 medium" style={{textAlign: "center"}}>
                  <Link href={genURL("/incentives")} className="bluelink" noTarget={true}>How we ensure fair outcomes</Link>
                </div>
              </div>
            } rightComp={
              <div>
                <div className="pageLightText moveTop20 medium" style={{textAlign: "center"}}>
                  <Link href={genURL("/about")} className="bluelink" noTarget={true}>About our motivation and team</Link>
                </div>
              </div>
            } />
            <GapCard />
            <GapCard />
            <div className="pageLightText medium moveTop40" style={{textAlign: "center"}}>
              For in depth answers to common questions see our <Link href={genURL("/faq")} className="bluelink" noTarget={true}>FAQ</Link>.
            </div>
          </div>
        </div>
        <div className="pageSection">
          <div className="restrictSectionWidth">
            <div id="gettingstarted" style={{textAlign: "left"}} className="pageTitle">Getting started</div>
            <div className="pageLightText moveTop20">The best way to understand Atstake at a high level is to take this <Link href={"https://medium.com/@atstake/a-quick-visual-tour-of-atstake-8debcad9035f"} className="bluelink" noTarget={true} rel="noopener noreferrer">visual tour</Link>.</div>
            
            <div className="pageLightText moveTop20">Check out our <Link href={genURL("/examples")} className="bluelink" noTarget={true} rel="noopener noreferrer">example agreements</Link> for ideas about how to use Atstake.</div>
            
            <div className="pageLightText moveTop20">Browse our <Link href={genURL("/postings")} className="bluelink" noTarget={true} rel="noopener noreferrer">Postings</Link> to see what types of services, goods, and contracts other users are offering and requestng.</div>
              
            <div className="pageLightText moveTop20">If you already know who you want to create a contract with, use the 'new contract' button
            on your <Link href={genURL("/contracts")} className="bluelink" noTarget={true} rel="noopener noreferrer">Contracts</Link> page. 
            You can propose an agreement to someone's email, Twitter, Facebook or other accounts even if they haven't signed up for Atstake yet.</div>

            <div className="pageLightText moveTop20">Looking for an arbitrator? Check out the list of arbitrators on the <Link href={genURL("/people")} className="bluelink" noTarget={true} rel="noopener noreferrer">People</Link> page. 
            Use the default settings and Atstake will arbitrate your agreement, or invite your preferred arbitrator to join Atstake.</div>
            <div className="pageLightText moveTop20">If you want to be an arbitrator, configure your arbitration settings on
            your <Link href={genURL("/account")} className="bluelink" noTarget={true} rel="noopener noreferrer">account page</Link>.</div>
            
            <div className="pageLightText moveTop20">You can try Atstake with fake money to see how it works by 
            enabling <span onClick={this.handleTestModeClick} className="bluelink">test mode</span> and requesting some free coins 
            from <Link href={genURL("/faucet")} className="bluelink" noTarget={true} rel="noopener noreferrer">our faucet</Link>.</div>
          </div>
        </div>
        <div className="pageSection" style={{backgroundColor:"var(--page-section-d-bg-color)"}}>
          <div className="restrictSectionWidth">
            <div style={{textAlign: "center"}} className="pageCardHeading moveTop20">
              Didn't find what you 
              were looking for? <Link href="mailto:support@atstake.net" className="bluelink" target="_blank" rel="noopener noreferrer">Email us</Link>.
            </div>
            <GapCard />
          </div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    help: state.help,
    menu: state.menu,
    overlay: state.overlay
  }
}

const HelpContainer = connect(mapStateToProps, null)(Help);
export default HelpContainer;