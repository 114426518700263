//import update from 'immutability-helper';

const emptyState = {
  waitingForApproveAllowance: false,
  allowanceApprovalTransactionComplete: false,
  waitingApproveAllowanceHash: "",
  waitingTimestampUnix: 0,
  action: null,
  dataObj: {}
}

const DialogBlockchainArbitrateReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "APPROVE_ALLOWANCE_STATUS" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog="", params={} } = action.payload;
      if (dialog === "blockchain_arbitrate") {
        let { 
          action=null,
          dataObj={} 
        } = params;
        return {
          ...state, 
          ...emptyState,
          action,
          dataObj
        };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogBlockchainArbitrateReducer