import { connect } from 'react-redux';
import React, { Component } from 'react'
import { logInWithSignature, logOut } from "./AuthActions";
import { openOverlay, closeOverlay } from './OverlayActions';
import './Menu.css';
import { navNewURL } from './ActionUtils'
import { openMenuList, closeMenuList, selectNavDropdown } from "./MenuActions";
import { shortenAddress } from './ActionUtils';
import { getMenuTokens } from './TokenUtils';
import { shared } from '../Shared';
import { track, addCommasToNumber, getTopMarketLink } from './ActionUtils';
import logoImageWhiteBG from "../logo_modified.svg";
import logoOnlyWhiteBG from "../logo_only.svg";
//import addMenuImg from "../add_darkgrey.png";
import envelopeMenuImg from "../envelope_darkgrey.png";
import handshakeMenuImg from "../handshake_darkgrey.png";
//import notificationsMenuImg from "../notifications_darkgrey.png";
import peopleMenuImg from "../people_darkgrey.png";
import profileMenuImg from "../profile_darkgrey.png";
import questionMenuImg from "../question_darkgrey.png";
import signoutImg from "../signout_darkgrey.png";
import starImg from "../star_darkgrey.png";
import postingImg from "../tack_darkgrey.png";
import walletImg from "../wallet_darkgrey.png";
import feedbackImg from "../chat_white.png";
import NavIcon from "./NavIcon";
import Link from './Link';
import { getIsMobile, getContentWidth } from './ActionUtils';

class Menu extends Component {
  constructor(props, context) {
    super(props);

      // This binding is necessary to make `this` work in the callback
      this.navigate = this.navigate.bind(this);
      this.navigateBack = this.navigateBack.bind(this);
      this.navigateMenu = this.navigateMenu.bind(this);
      this.navigateLogo = this.navigateLogo.bind(this);
      this.navigateDialog = this.navigateDialog.bind(this);
      this.logIn = this.logIn.bind(this);
      this.logOut = this.logOut.bind(this);
      this.openMenuList = this.openMenuList.bind(this);
      this.closeMenuList = this.closeMenuList.bind(this);
      this.submitFeedback = this.submitFeedback.bind(this);
      this.testMode = this.testMode.bind(this);
      this.showAddressInfo = this.showAddressInfo.bind(this);
      this.handleTestMsgClick = this.handleTestMsgClick.bind(this);
      this.openLoginDialog = this.openLoginDialog.bind(this);
      this.openNewPostingDialog = this.openNewPostingDialog.bind(this);
      this.handleNavClick = this.handleNavClick.bind(this);
      this.handleDropdownClick = this.handleDropdownClick.bind(this);
  }

  navigate(event, track_type) {
    let pathname = event.currentTarget.getAttribute("name");
    if (pathname === "home") {
      pathname = "";
    }

    let link = "";
    if (event.currentTarget.hasAttribute("link")) {
      link = event.currentTarget.getAttribute("link");
    }

    this.props.dispatch(track("action", "button", JSON.stringify({name: pathname, type: track_type})));

    event.preventDefault();
    if (this.props.auth.confirmNav) {
      this.props.dispatch(openOverlay("confirm", {pathname: pathname, query: {}, title: "Are you sure?", message: "Do you want to cancel and discard all changes?"}));
    }
    else {
      if (pathname === "back") {
        window.oldHref = window.location.href;
        window.history.back();
        window.setTimeout(() => {
          if (window.oldHref === window.location.href) {
            this.props.dispatch(navNewURL('/'));
          }
        }, 300);
      }
      else {
        if (link === "") {
          link = '/' + pathname;
        }

        this.props.dispatch(navNewURL(link));
      }
    }
  }
  
  handleDropdownClick(event, action) {
    if (action === "logout") {
      this.logOut();
    }
    else {
      this.navigateMenu(event);
    }
  }

  navigateMenu(event) {
    this.navigate(event, "menu_navigate");
  }

  navigateLogo(event) {
    this.navigate(event, "logo_navigate");
  }

  navigateBack(event) {
    this.navigate(event, "back_navigate");
  }

  navigateDialog(event) {
    this.navigate(event, "dialog_navigate");
    this.props.dispatch(closeOverlay());
  }

  openMenuList(event) {
    this.props.dispatch(openMenuList());
  }

  closeMenuList(event) {
    this.props.dispatch(closeMenuList());
  }

  submitFeedback(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({name: "report", type: "menu_navigate"})));
    this.props.dispatch(openOverlay("report", {}));
  }

  testMode(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({name: "testmode", type: "menu_navigate"})));
    let testMode = this.props.auth.testMode;
    this.props.dispatch(openOverlay("testmode", {mode: (testMode ? "test":"prod") }));
  }

  showAddressInfo(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"menu_show_address_info"})));

    let selectedAddress = this.props.auth.ethAddress;
    let authUsername = this.props.auth.username;
    let hasUsernameMatch = this.props.auth.hasUsernameMatch;
    let hasAccount = this.props.auth.hasAccount;
    let isDisabled = this.props.auth.isDisabled;
    //let shortSelectedAddress = shortenAddress(selectedAddress);
    let title = "Your Ethereum wallet";

    let flagWrongNetwork = false;
    let ethNetwork = this.props.auth.ethNetwork;
    let ethNetworkName = this.props.auth.ethNetworkName;
    if (ethNetwork !== "" && !shared.isSupportedNetwork(ethNetwork)) {
      flagWrongNetwork = true;
    }

    if (flagWrongNetwork) {
      title = "Unsupported network";
      this.props.dispatch(openOverlay("message", {title, message:"Please change your Ethereum wallet to use the " + shared.getNetworkNameLong(false) + " or " + shared.getNetworkNameLong(true) + ". You are currently using the " + ethNetworkName + "."}));
    }
    else if (hasAccount && hasUsernameMatch && isDisabled) {
      this.props.dispatch(openOverlay("message", {title, message:<span>{"Ethereum address "}{<span style={{fontWeight:"bold"}}>{selectedAddress}</span>}{" is linked with your Atstake account "}{<span style={{fontWeight:"bold"}}>{authUsername}</span>}{". It has been disabled and cannot be used to log in."}</span>}));
    }
    else if (hasAccount && hasUsernameMatch) {
    this.props.dispatch(openOverlay("message", {title, message:<span>{"Ethereum address "}{<span style={{fontWeight:"bold"}}>{selectedAddress}</span>}{" is linked with your Atstake account "}{<span style={{fontWeight:"bold"}}>{authUsername}</span>}{"."}<br/><br/><span>{" You are currently using the " + ethNetworkName + "."}</span></span>}));
    }
    else if (hasAccount) {
      this.props.dispatch(openOverlay("message", {title, message:<span>{"Ethereum address "}{<span style={{fontWeight:"bold"}}>{selectedAddress}</span>}{" is linked with a different Atstake account."}<br/><br/>{"You may want to "}<span className="bluelink" name="account" onClick={this.openLoginDialog}>log in with that account</span>{", or switch to a different Ethereum address."}</span>}));
    }
    else {
      this.props.dispatch(openOverlay("message", {title, message:<span>{"Ethereum address "}{<span style={{fontWeight:"bold"}}>{selectedAddress}</span>}{" is not linked with any Atstake account. Visit the "}<span className="blacklink" name="profile" onClick={this.navigateDialog}>Profile page</span>{" if you'd like to link it."}</span>}));
    }
  }

  handleTestMsgClick(event) {
    let testMode = this.props.auth.testMode;
    this.props.dispatch(openOverlay("testmode", {mode: (testMode ? "test":"prod") }));
  }

  logIn(event) {
    let selectedAddress = this.props.auth.ethAddress;
    let hasAccount = this.props.auth.hasAccount;
    let isDisabled = this.props.auth.isDisabled;
    let initCheckAddress = this.props.auth.initCheckAddress;

    this.props.dispatch(track("action", "button", JSON.stringify({type:"log_in"})));
    if (initCheckAddress && selectedAddress !== "" && hasAccount && !isDisabled) {
      this.props.dispatch(logInWithSignature(selectedAddress));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }

  logOut(event) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"log_out"})));
    this.props.dispatch(logOut());
  }

  openLoginDialog(event) {
    this.props.dispatch(openOverlay("login", {}));
  }

  openNewPostingDialog(event) {
    this.props.dispatch(openOverlay("new_posting", {}));
  }

  handleNavClick(action) {
    this.props.dispatch(selectNavDropdown(action));
  }

  render() {  
    
    let ethBalance = this.props.auth.ethBalance;
    let erc20Balances = this.props.auth.erc20Balances;
    let hasShortScreen = this.props.auth.screenDimensions.height < 500;

    let { menuListOpen=false, selectedPage="" } = this.props.menu;
    let { status="pending" } = this.props.auth;
    let selectedAddress = this.props.auth.ethAddress;
    let shortSelectedAddress = shortenAddress(selectedAddress);

    let backText = "Back";
    let showBack = false;
    let backPath = "back";
    if (this.props.auth.navParam === "contracts") {
      backPath = "contracts";
      backText = "Contracts";
    }

    if (selectedPage === "view" || selectedPage === "new" || selectedPage === "edit") {
      showBack = true;
    }
    else if (selectedPage === "contracts") {
      let { filter="" } = this.props.menu.queryParams;
      if (filter !== "") {
        showBack = true;
      }
    }
    else if (selectedPage === "ratings") {
      showBack = true;
    }

    if (status === "loggedout") {
      showBack = false;
    }

    let authUsername = this.props.auth.username;
    let hasUsernameMatch = this.props.auth.hasUsernameMatch;
    let hasAccount = this.props.auth.hasAccount;
    let isDisabled = this.props.auth.isDisabled;
    let initCheckAddress = this.props.auth.initCheckAddress;

    let flagAddress = false;
    if (initCheckAddress) {
      flagAddress = !hasUsernameMatch || !hasAccount || isDisabled;
    }

    let flagWrongNetwork = false;
    let ethNetwork = this.props.auth.ethNetwork;
    let ethNetworkName = this.props.auth.ethNetworkName;
    if (initCheckAddress && ethNetwork !== "" && !shared.isSupportedNetwork(ethNetwork)) {
      flagWrongNetwork = true;
    }

    let flagNoAddress = false;
    if (initCheckAddress && selectedAddress === "") {
      flagNoAddress = true;
    }

    let unreadMessageCount = this.props.auth.unreadCount;

    let menuTokens = getMenuTokens();
    let balanceElem = [];
    if (initCheckAddress) {
      if (flagWrongNetwork) {
        balanceElem.push(<div key="wrongnetwork" className="balanceInfo" style={{height:"40px"}}><span onClick={initCheckAddress ? this.showAddressInfo : null} style={{fontWeight:"bold", color:"#ff7766", cursor:"pointer"}}>Unsupported Network</span></div>);
      }
      else if (flagNoAddress) {
        // Nothing
      }
      else {
        for (let i = 0; i < menuTokens.length; i++) {
          let tokenSymbol = menuTokens[i];
          let tokenSymbolUpper = tokenSymbol.toUpperCase();
          let balanceFixed = "";
    
          if (tokenSymbol === "eth") {
            if (ethBalance) {
              balanceFixed = Math.floor(parseFloat(ethBalance)*100)/100;
            }
          }
          else {
            if (erc20Balances.hasOwnProperty(tokenSymbolUpper)) {
              let tokenBalance = erc20Balances[tokenSymbolUpper].balance;
              balanceFixed = Math.floor(parseFloat(tokenBalance)*100)/100;
            }
          }
    
          let balanceWithCommas = addCommasToNumber(balanceFixed);
          balanceElem.push(<div key={i} className="balanceInfo" style={{marginTop: (i === 0 && !hasShortScreen ? "8px" : "")}}>{balanceFixed !== "" ? tokenSymbolUpper + ": " + balanceWithCommas : <span>&nbsp;</span>}</div>);
        }
      }
    }
    else {
      balanceElem.push(<div key="placeholder" className="balanceInfo" style={{height:"40px"}}><span>&nbsp;</span></div>);
    }

    let isMobile = getIsMobile();
    let contentWidth = getContentWidth();
    let showNavLabels = !isMobile && contentWidth > 700;
    let selectedNavMenu = this.props.menu.selectedNavMenu;

    let profileElem = (
      <div>
        <div className="menuProfileUsername">{authUsername}</div>
        <div onClick={initCheckAddress ? this.showAddressInfo : null} style={{cursor:(initCheckAddress ? "pointer" : "default")}} className={"menuProfileWallet"}>
          {initCheckAddress ? (<span>{shortSelectedAddress}</span>) : (<span>&nbsp;</span>)}
          {initCheckAddress && flagNoAddress && <div style={{padding: "0px 0px 8px 0px", fontWeight: "bold", fontSize: "12px", textAlign: "center"}}>ETH wallet disconnected</div>}
          {initCheckAddress && !flagNoAddress && <div style={{padding: "0px 0px 8px 0px", fontWeight: "bold", fontSize: "12px", textAlign: "center"}}>{ethNetworkName}</div>}
          {initCheckAddress && !flagNoAddress && !flagWrongNetwork && flagAddress && <div style={{padding: "0px 0px 8px 0px", fontWeight: "bold", fontSize: "12px", textAlign: "center"}}>{hasAccount ? "Ethereum address linked to different Atstake account" : "Ethereum address not linked"}</div>}
        </div>
        <div className="menuProfileBalance">{balanceElem}</div>
      </div>
    );
    
    return (
      <div>
        <div>
          <div className={"menuFadeOverlay" + (menuListOpen ? "" : " hide")}></div>
          <div className={"menuContentOverlay" + (menuListOpen ? " showMenuContentOverlay" : " hideMenuContentOverlay")} onClick={this.closeMenuList}>
            <div className={"menuContentPanel" + (menuListOpen ? " showMenuContentPanel" : " hideMenuContentPanel") + (hasShortScreen ? " menuShort" : "")}>
              <div style={{borderBottom: "1px solid var(--menu-border-color)"}} className="menuTopTitle">Categories</div>
              <div style={{height: "20px"}}></div>
              <div name="postings" link={getTopMarketLink("location")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Location</div>
              <div name="postings" link={getTopMarketLink("service_offered")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Service Offered</div>
              <div name="postings" link={getTopMarketLink("service_wanted")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Service Wanted</div>
              <div name="postings" link={getTopMarketLink("item_offered")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Item For Sale</div>
              <div name="postings" link={getTopMarketLink("item_wanted")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Item Wanted</div>
              <div name="postings" link={getTopMarketLink("rental_wanted")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Rental Offered</div>
              <div name="postings" link={getTopMarketLink("rental_offered")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Rental Wanted</div>
              {/*<div name="postings" link={getTopMarketLink("other")} onClick={this.navigateMenu} className={"menuOverlayItem"}>Other</div>*/}
            </div>
          </div>
        </div>
        
        <div>
          <div className="newMenuBar">
            <div className="newMenuInner">
              {!showBack && !isMobile && (
                <Link href={"/"} noTarget={true} rel="noopener noreferrer">
                  <img name="home" onClick={null} className="logoImg" src={logoImageWhiteBG} alt="Atstake" />
                </Link>
              )}
              {!showBack && isMobile && (
                <Link href={"/"} noTarget={true} rel="noopener noreferrer">
                  <img name="home" onClick={null} className="logoImg" src={logoOnlyWhiteBG} alt="Atstake" />
                </Link>
              )}
              {showBack && (
                <div name={backPath} onClick={this.navigateBack} className="backBtn">❮ {backText}</div>
              )}
            </div>
            { status === "loggedin" && (
              <div className={showNavLabels ? "newMenuInnerRight" : "newMenuInnerRightLabels"}>
                <table>
                  <tbody>
                    <tr>
                      <td className="">
                        <NavIcon adjust="down" image={postingImg} label="Postings" action="postings" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                      </td>
                      <td className="menuGapCell"></td>
                      <td className="">
                        <NavIcon adjust="biggest" image={handshakeMenuImg} label="Contracts" action="contracts" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                      </td>
                      <td className="menuGapCell"></td>
                      <td className="">
                        <NavIcon adjust="big" image={peopleMenuImg} label="People" action="people" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                      </td>
                      <td className="menuGapCell"></td>
                      <td className="">
                        <NavIcon adjust="medium" image={envelopeMenuImg} label="Messages" action="messages" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} notifyCount={unreadMessageCount} />
                      </td>
                      <td className="menuGapCell"></td>
                      <td className="">
                        <NavIcon image={profileMenuImg} label="You" action="profile" showLabels={showNavLabels} showDropdown={selectedNavMenu === "profile"} menuTop={profileElem} menuList={[{label:"Profile", action:"profile", icon: profileMenuImg}, {label:"Postings", action:"userpostings", icon: postingImg}, {label:"Contracts", action:"contracts", icon: handshakeMenuImg}, {label:"Ratings", action:"ratings", icon: starImg}, {label:"Wallet", action:"balances", icon: walletImg}, {label:"Log out", action: "logout", icon: signoutImg}]} onMenuClick={this.handleNavClick} onNavigateMenu={this.handleDropdownClick} />
                      </td>
                      <td className="menuGapCellWide"></td>
                      <td className="">
                        <NavIcon image={questionMenuImg} label="Help" action="help" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              )
            }
            { status === "loggedout" && (
                <div className={showNavLabels ? "newMenuInnerRight" : "newMenuInnerRightLabels"}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="">
                          <NavIcon adjust="down" image={postingImg} label="Postings" action="postings" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                        </td>
                        <td className="menuGapCell"></td>
                        <td className="">
                          <NavIcon adjust="big" image={peopleMenuImg} label="People" action="people" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                        </td>
                        <td className="menuGapCell"></td>
                        <td className="">
                          <NavIcon image={questionMenuImg} label="Help" action="help" showLabels={showNavLabels} menuList={null} onMenuClick={null} onNavigateMenu={this.navigateMenu} />
                        </td>
                        <td className="menuGapCell"></td>
                        <td className="">
                          <button style={{position:"relative", top:"-2px", left: "3px"}} onClick={this.logIn} className="btn btnMenu">{hasAccount ? "Log in" : "Sign up"}</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }
          </div>
          <div className="menuPlaceholder"></div>
        </div>

        <div>
          <div className="feedbackPrompt">
            <div className="feedbackCircle">
              <img name="feedback" onClick={this.submitFeedback} className="feedbackImg" src={feedbackImg} alt="Feedback" />
            </div>
          </div>
        </div>
     </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu
  }
}

const MenuContainer = connect(mapStateToProps, null)(Menu);
export default MenuContainer;