import { apiBatchListPostingsWithTree, getMarketsPageSections } from "./ActionUtils"

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'MARKETS_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function refreshMarketSections(bread_location, bread_type, bread_category, search, next_key) {
  return function(dispatch) {
    if (next_key !== "") {
      dispatch(changeInputValue("updatesLoaded", false));
    }

    let marketSections = getMarketsPageSections(bread_location, bread_type, bread_category, search, next_key);
    apiBatchListPostingsWithTree(marketSections).then(({categories, sections}) => {
      dispatch({
        type: 'CATEGORY_TREE',
        payload: categories
      });
      if (sections && sections.length > 0 && sections[0].hasOwnProperty("postings")) {
        dispatch({
          type: next_key === "" ? 'MARKETS_POSTINGS_LIST' : 'MARKETS_POSTINGS_MORE',
          payload: sections[0]
        });
      }
    });
  }
}
