import { apiRequest, apiFavorite, apiListConversations, apiListMessages, apiNewMessage, apiGetNotifications, track } from "./ActionUtils";
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'MESSAGES_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function changeAutosuggestInput(name, value, search) {
  return function(dispatch) {
    dispatch({
      type: 'MESSAGES_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });
  }
}

export function sendNewMessage(user_to, message) {
  return function(dispatch) {
    let auth = store.getState().auth;

    dispatch({
      type: 'MESSAGES_DISABLED_BUTTON',
      payload: true
    });

    dispatch(changeInputValue("newMessageStatus", { text:"", error: true }));
    apiNewMessage(auth.uuid, auth.username, user_to, message).then((success) => {
      if (success) {
        dispatch(changeInputValue("messageBox", ""));
        dispatch(changeInputValue("newMessageStatus", { text:"Message sent", error: false }));
        dispatch(refreshMessagesList(false));
      }
      else {
        dispatch(changeInputValue("newMessageStatus", { text:"Error sending message", error: true }));
      }

      dispatch({
        type: 'MESSAGES_DISABLED_BUTTON',
        payload: false
      });
    });
  }
}

export function refreshConversationList() {
  return function(dispatch) {
    let auth = store.getState().auth;

    dispatch({
      type: 'MESSAGES_CONVERSATION_LIST',
      payload: { conversations: [], loaded: false }
    });

    apiListConversations(auth.uuid, auth.username).then(({conversations=[], success=false}) => {      
      dispatch({
        type: 'MESSAGES_CONVERSATION_LIST',
        payload: { conversations, loaded: success }
      });

      dispatch(refreshNotifications());
    });

  }
}

export function refreshMessagesList(showLoad) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let messages = store.getState().messages;

    let {
      user=""
    } = messages;

    if (showLoad) {
      dispatch({
        type: 'MESSAGES_LIST',
        payload: { messages: [], user, loaded: false }
      });  
    }

    apiListMessages(auth.uuid, auth.username, user).then(({messages=[], success=false}) => {      
      dispatch({
        type: 'MESSAGES_LIST',
        payload: { messages, user, loaded: success }
      });

      dispatch(refreshNotifications());
      window.scrollTo(0, document.body.scrollHeight);
    });

  }
}

export function changeAutosuggestResults(name, value, search, max, more, selected, nonce) {
  return function(dispatch) {

    let auth = store.getState().auth;

    dispatch({
      type: 'MESSAGES_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });

    apiRequest("autosuggest", { input: value, search: search, max: max, uuid: auth.uuid, username: auth.username }).then((response) => {
      let data = response.data;
      let hasMore = response.hasMore && more;
      let exactMatchMap = response.exactMatchMap;
      
      let results = [];
      let selectedResult = null;
      let resultsFromMatch = data || [];
      for (let i = 0; i < resultsFromMatch.length; i++) {
        let resultEntry = resultsFromMatch[i];
        let type = resultEntry.type;
        if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
          selectedResult = resultEntry;
        }
        else {
          results.push(resultEntry);
        }
      }

      if (selectedResult !== null) {
        selectedResult.selected = true;
        results.unshift(selectedResult);
      }

      dispatch({
        type: 'MESSAGES_CHANGE_AUTOSUGGEST_RESULTS',
        payload: {name, results: results, more: hasMore, match: exactMatchMap, nonce: nonce }
      });
    });
  }
}

export function clearAutosuggestResults(name, input, nonce) {
  return function(dispatch) {
    // On people page clicking autosuggest results navigates to user page 

    dispatch({
      type: 'MESSAGES_CLEAR_AUTOSUGGEST_RESULTS',
      payload: {name, nonce}
    });
  }
}

export function handleAutosuggestFavorite(name, contact, oldFavoriteSelected) {
  return function(dispatch) {
    let favoriteSelected = !oldFavoriteSelected;
    let auth = store.getState().auth;
    dispatch({
      type: 'MESSAGES_AUTOSUGGEST_FAVORITE',
      payload: {name, contact, favoriteSelected, favoritePending:true}
    });
    apiFavorite(auth.uuid, auth.username, contact, favoriteSelected).then((success) => {
      if (success) {
        dispatch({
          type: 'MESSAGES_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected, favoritePending:false}
        });
        dispatch(track("action", "button", JSON.stringify({type:"autosuggest_favorite", contact, favoriteSelected})));
      }
      else {
        dispatch({
          type: 'MESSAGES_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected: oldFavoriteSelected, favoritePending:false}
        });
      }
    });
  }
}


export function refreshNotifications() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;
    apiGetNotifications(uuid, username).then((respObj) => {
      dispatch({
        type: 'NOTIFICATIONS',
        payload: respObj
      });
    });
  }
}