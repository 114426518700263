//import update from 'immutability-helper';

const emptyState = {
  mode: ""
}

const DialogTestModeReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGTESTMODE_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "testmode") {
        let { mode="" } = params;
        return {...state, ...emptyState, mode };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogTestModeReducer