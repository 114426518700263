import React, { Component } from 'react';
import './Common.css';
import './InputDatePicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';

class InputDatePicker extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.clearTextInput = this.clearTextInput.bind(this);
        this.isValidDate = this.isValidDate.bind(this);
        this.getDateFromString = this.getDateFromString.bind(this);
        this.inputIsValid = this.inputIsValid.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleRawDateChange = this.handleRawDateChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    clearTextInput() {
        this.props.onChange(this.props.name, "");
        this.refs["datePicker"].setOpen(false);
    }
  
    getDateFromString(str) {
        if(str === "") { return null; }

        if(/^[0-9][0-9]\/[0-9][0-9]\/[0-9][0-9][0-9][0-9]$/.test(str)) {
            let date = moment.tz(str, "MM/DD/YYYY", 'America/Los_Angeles');
            if(date.isValid() && this.isValidDate(date)) {
                return date;
            }
        }

        return null;
    }

    isValidDate(date) {
      if (date === null) { return false; }
      let diff = date.diff(moment().tz('America/Los_Angeles'), 'minutes');
      return diff >= 1;
    }

    handleDateChange(date) {
        if(date) {
            this.props.onChange(this.props.name, date.format("MM/DD/YYYY"));
        }
        else {
            this.props.onChange(this.props.name, "");
        }
    }

    handleRawDateChange(event) {
      event.preventDefault();
      let value = event.currentTarget.value;
      this.props.onChange(this.props.name, value);
    }

    handleFocus(event) {
      // To prevent keyboard from opening on mobile
      if (this.props.device !== "desktop") {
        event.target.blur();
      }
    }

    inputIsValid(str) {
        const date = this.getDateFromString(str);
        return str === "" || date !== null;
    }

    render() {  

      let dayRemainingStr = "";
      let date = this.getDateFromString(this.props.value);
      if (this.isValidDate(date)) {
        let dateNow = moment().tz('America/Los_Angeles');
        let diffHours = date.diff(dateNow, 'hours');
        if (diffHours < 24) {
          if (diffHours === 1) {
            dayRemainingStr = "1 hour";
          }
          else {
            dayRemainingStr = diffHours + " hours";
          } 
        }
        else {
          let diffDays = date.diff(dateNow, 'days');
          if (diffDays === 1) {
            dayRemainingStr = "1 day";
          }
          else {
            dayRemainingStr = diffDays + " days";
          }  
        }
      }

      return (
          <div style={{position: "relative"}} className="datePickerOuter">
                      
            <div className={this.props.value === "" && this.props.disabled === false ? "textBehindInput" : "hide"}>{this.props.blankText}</div>
            <div className={dayRemainingStr !== "" ? "textBehindRight" : "hide"} style={ this.props.disabled ? {background:"transparent"} : {} }>{dayRemainingStr}</div>

            <div className={this.props.disabled ? "hide" : ""}>
              <DatePicker
              selected={date}
              dateFormat="MM/DD/YYYY"
              onChange={this.handleDateChange}
              onChangeRaw={this.handleRawDateChange}
              className={this.props.className + " textInput transparent"}
              scrollableYearDropdown={true}
              showYearDropdown={true}
              onFocus={this.handleFocus} 
              yearDropdownItemNumber={6}
              placeholderText=""
              value={this.props.value} 
              setOpen={false}
              ref="datePicker"
              calendarClassName="datepickerCustom"
              filterDate={this.isValidDate}
              isClearable={true}
              withPortal={this.props.device !== "desktop"} 
              disabled={this.props.disabled}>
                <div style={{textAlign:"center", marginBottom:"15px"}}><button className="btn" onClick={this.clearTextInput}>{this.props.blankText}</button></div>
              </DatePicker>
            </div>
            <input readOnly={true} type="text" className={this.props.disabled ? this.props.className + " textInput disabled" : "hide"}  value={this.props.value === "" ? this.props.blankText : this.props.value}  />
            {!this.props.disabled && <div className={!this.inputIsValid(this.props.value) ? "inputError moveTop5" : "hide"}>Please enter a valid future date (MM/DD/YYYY) or leave blank</div>}
            <div className={this.props.hover ? "questionHoverHelp" : "questionHoverHelp hide"}>{this.props.hoverText}</div>
          </div>
      );
    }
  }
  
  export default InputDatePicker;
