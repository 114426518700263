import { apiGetAccessCode } from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGACCESSCODE_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function getAccessCode() {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiGetAccessCode(auth.uuid, auth.username).then((responseObj) => {
      if (responseObj.success) {
        dispatch({
          type: 'DIALOGACCESSCODE_ACCESS_CODE',
          payload: responseObj
        });
      }
    });
  }
}
