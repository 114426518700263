import { transferFunds } from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGFUNDING_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function sendToAddress(token, amount, toAddress) {
  return function(dispatch) {
    
    let auth = store.getState().auth;
    transferFunds(token, amount, auth.ethAddress, toAddress, dispatch).then((response) => {
      // Transfer complete
    });
  }
}