import { apiRequest, apiListContacts, apiTopArbitrators, apiFavorite, track } from "./ActionUtils";
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'PEOPLE_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function changeAutosuggestInput(name, value, search) {
  return function(dispatch) {
    dispatch({
      type: 'PEOPLE_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });
  }
}

export function changeAutosuggestResults(name, value, search, max, more, selected, nonce) {
  return function(dispatch) {

    let auth = store.getState().auth;

    dispatch({
      type: 'PEOPLE_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });

    apiRequest("autosuggest", { input: value, search: search, max: max, uuid: auth.uuid, username: auth.username }).then((response) => {
      let data = response.data;
      let hasMore = response.hasMore && more;
      let exactMatchMap = response.exactMatchMap;
      
      let results = [];
      let selectedResult = null;
      let resultsFromMatch = data || [];
      for (let i = 0; i < resultsFromMatch.length; i++) {
        let resultEntry = resultsFromMatch[i];
        let type = resultEntry.type;
        if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
          selectedResult = resultEntry;
        }
        else {
          results.push(resultEntry);
        }
      }

      if (selectedResult !== null) {
        selectedResult.selected = true;
        results.unshift(selectedResult);
      }

      dispatch({
        type: 'PEOPLE_CHANGE_AUTOSUGGEST_RESULTS',
        payload: {name, results: results, more: hasMore, match: exactMatchMap, nonce: nonce }
      });
    });
  }
}

export function clearAutosuggestResults(name, input, nonce) {
  return function(dispatch) {
    // On people page clicking autosuggest results navigates to user page 

    dispatch({
      type: 'PEOPLE_CLEAR_AUTOSUGGEST_RESULTS',
      payload: {name, nonce}
    });
  }
}

export function refreshContacts() {
  return function(dispatch) {
    let auth = store.getState().auth;
    if (auth.username === "") {
      dispatch({
        type: 'PEOPLE_CONTACT_LIST',
        payload: { results:[], favorites: [] }
      });
    }
    else {
      apiListContacts(auth.uuid, auth.username).then((respObj) => {
        dispatch({
          type: 'PEOPLE_CONTACT_LIST',
          payload: respObj
        });
      });
    }
  }
}

export function refreshArbitratorList() {
  return function(dispatch) {
    let auth = store.getState().auth;

    let {
      offset=0,
      count=0
    } = store.getState().people;

    apiTopArbitrators(auth.uuid, auth.username, offset, count).then((list) => {
      dispatch({
        type: 'PEOPLE_ARBITRATOR_LIST',
        payload: {list}
      });
    });
  }
}

export function handleAutosuggestFavorite(name, contact, oldFavoriteSelected) {
  return function(dispatch) {
    let favoriteSelected = !oldFavoriteSelected;
    let auth = store.getState().auth;
    dispatch({
      type: 'PEOPLE_AUTOSUGGEST_FAVORITE',
      payload: {name, contact, favoriteSelected, favoritePending:true}
    });
    apiFavorite(auth.uuid, auth.username, contact, favoriteSelected).then((success) => {
      if (success) {
        dispatch({
          type: 'PEOPLE_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected, favoritePending:false}
        });
        dispatch(track("action", "button", JSON.stringify({type:"autosuggest_favorite", contact, favoriteSelected})));
      }
      else {
        dispatch({
          type: 'PEOPLE_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected: oldFavoriteSelected, favoritePending:false}
        });
      }
    });
  }
}