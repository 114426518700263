import React, { Component } from 'react';
import './AuthPending.css';
import loadImg from '../load_blue.gif';
const SHOW_LOADING_SECONDS = 3;

class AuthPending extends Component {
    constructor(props) {
      super(props);
      this.state = {showLoading: false};
      this.timeoutNum = null;
      this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
      this.setState({showLoading: false});

      clearTimeout(this.timeoutNum);
      this.timeoutNum = window.setTimeout(() => {
        this.setState({showLoading: true});
      }, SHOW_LOADING_SECONDS * 1000);
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutNum);
    }

    refresh(event) {
      window.location.reload();
    }

    render() {
      return (
        <div className="authPendingCnt">
          {this.state.showLoading && <div style={{textAlign:"center"}}>
            <img className="loadIcon" src={loadImg} alt="Loading" />
            <div className="moveTop40">
              Taking too long? <span onClick={this.refresh} className="bluelink">click to refresh</span>
            </div>
          </div>}
        </div>
      );
    }
  }
  
  export default AuthPending;