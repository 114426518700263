import React, { Component } from 'react';
import './LoadCard.css';
import loadImg from '../load_blue.gif';

class LoadCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="loadCard">
        <div><img className="loadCardIcon" src={loadImg} alt="Loading" /></div>
      </div>
    );
  }
}
  
export default LoadCard;