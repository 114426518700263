import React, { Component } from 'react';
import './VersionCard.css';
import './Common.css';
import { datetimeDisplayString, summaryFromAgreementVersion } from "./ActionUtils";

class VersionCard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {};
  }

  handleClick(event) {
    let version = this.props.data;
    if (version) {
      let agreementId = version.agreementid;
      let versionId = version.versionid;
      this.props.onClick(agreementId, versionId);
    }
  }

  render() {
    let username = this.props.username;
    let version = this.props.data;
    let versionid = this.props.data.versionid;
    let dataObj = summaryFromAgreementVersion(version, username);
    let title = this.props.data.title || "";
    let notice = "";
    let date = dataObj.versionTimestamp || 0;
    let proposedby = this.props.data.proposedby || "";
    let versionNum = this.props.num || 0;

    let formattedDate = datetimeDisplayString(date);
    let combinedTitle = title + " (version #" + versionNum + ")";
    let summary = "Proposed by " + proposedby + " on " + formattedDate;

    let user1SelectedThis = false;
    if (dataObj.versionUser1Proposed === versionid) {
      user1SelectedThis = true;
    }

    let user2SelectedThis = false;
    if (dataObj.versionUser2Proposed === versionid) {
      user2SelectedThis = true;
    }

    if (user1SelectedThis && user2SelectedThis) {
      if (dataObj.youAreUser1) {
        notice = "Agreed by you and " + dataObj.user2DisplayValue;
      }
      else if (dataObj.youAreUser2) {
        notice = "Agreed by you and " + dataObj.user1Locked;
      }
      else {
        notice = "Agreed by " + dataObj.user1Locked + " and " + dataObj.user2DisplayValue;
      }
    }
    else if (user1SelectedThis) {
      if (dataObj.youAreUser1) {
        notice = "Selected by you";
      }
      else {
        notice = "Selected by " + dataObj.user1Locked;
      }
    }
    else if (user2SelectedThis) {
      if (dataObj.youAreUser2) {
        notice = "Selected by you";
      }
      else {
        notice = "Selected by " + dataObj.user2DisplayValue;
      }
    }

    return (
      <div className="versionCard" onClick={this.handleClick}>
        <div className="versionCardRow moveTop20"><span style={{fontWeight:"bold"}}>{combinedTitle}</span></div>
        <div className="versionCardRow"><span>{summary}</span></div>
        {notice !== "" && <div className="versionCardRow"><span className="agEmphasisText">{notice}</span></div>}
      </div>
    );
  }
}
  
export default VersionCard;