import React, { Component } from 'react';
import './Common.css';
import './InputContractTerms.css';
import { shared } from '../Shared';

class InputContractTerms extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
      let value = event.currentTarget.value;

      if (this.props.hasOwnProperty("maxlength")) {
        let maxlength = parseInt(this.props.maxlength, 10);

        if (maxlength !== 0 && value.length > maxlength) {
          value = value.substring(0, maxlength);
        }
      }

      if(this.props.hasOwnProperty("onChange")) {
        this.props.onChange(event.currentTarget.getAttribute("name"), value);
      }
    }

    tokenizeWithBR(str) {
      let elem = [];
      if (str && str !== "") {
        let arr = str.trim().split("\n");
        for (let i = 0; i < arr.length; i++) {
          if (i > 0) {
            elem.push(<br key={"br_" + i}/>);
          }
          elem.push(<span key={i}>{arr[i]}</span>);
        }
      }
      return elem;
    }

    render() {  

        let {
          postingid="",
          disabled=false,
          placeholder="",
          value="",
          userPoster="",
          userTaker="",
          youArePoster=false,
          youAreTaker=false,
          title="",
          description="", 
          priceAmount="", 
          priceType="", 
          depositAmount="",
          depositType="",
          deadlineInput="", 
          locationDetails="",
          breadLocation="", 
          breadType="", 
          breadCategory="",
          photoList=[]
        } = this.props;

        let photoElem = [];
        if (photoList && photoList.length > 0) {
          let delim = "";
          for (let i = 0; i < photoList.length; i++) {
            let imageContent = photoList[i].imageContent;
            if (delim !== "") {
              photoElem.push(<span key={"delim_" + i}>{delim}</span>);
            }

            if (imageContent.substring(0, 8) === "https://") {
              photoElem.push(<span key={"img_" + i}>{imageContent}</span>);
              delim = ", ";  
            }
            else {
              photoElem.push(<span key={"img_" + i} className="templatePendingImage">{"[pending image upload]"}</span>);
              delim = ", ";
            }
          }
        }

        if (photoElem.length === 0) {
          photoElem.push(<span key="none">{"No photos"}</span>);
        }
        else {
          photoElem.push(<br key="br"/>);
          photoElem.push(<span key="sha3" className="small">{"Photo filenames include the sha3 hash of the image."}</span>);
        }

        let breadcrumb = "";
        if (breadLocation !== "" && breadType !== "" && breadCategory !== "") {
          breadcrumb = <span>{shared.getBreadcrumbDisplay(breadLocation, "location") + " > " + shared.getBreadcrumbDisplay(breadType, "type") + " > " + shared.getBreadcrumbDisplay(breadCategory, "category")}</span>;
        }
        else if (breadLocation !== "" && breadType !== "") {
          breadcrumb = <span>{shared.getBreadcrumbDisplay(breadLocation, "location") + " > " + shared.getBreadcrumbDisplay(breadType, "type")}</span>;
        }
        else {
          breadcrumb = <span>{shared.getBreadcrumbDisplay(breadLocation, "location")}</span>;
        }

        let descriptionElem = this.tokenizeWithBR(description);
        let deadlineElem = this.tokenizeWithBR(deadlineInput);        

        let rep = {
          postingid,
          break: <br/>,
          breadcrumb,
          userPoster,
          youArePoster,
          userTaker,
          youAreTaker,
          title,
          description: descriptionElem.length > 0 ? descriptionElem : "",
          photos: photoElem,
          separator: <div><div style={{borderBottom: "1px solid #ccc", marginTop:"10px"}}></div></div>,
          bullet: <span><br/>&bull;</span>,
          price: (priceAmount !== "" && priceType !== "") ? priceAmount + " " + priceType : "",
          deposit: (depositAmount !== "" && depositType !== "") ? depositAmount + " " + depositType : "",
          location: locationDetails,
          deadline: deadlineElem.length > 0 ? deadlineElem : ""
        };

        let displayListElem = [];
        let fieldList = shared.templateProcessor(value, rep);
        for (let i = 0; i < fieldList.length; i++) {
          let field = fieldList[i];

          displayListElem.push(
            <span key={i} className={field.type === "markup" ? "markup" : (field.type === "info" ? "templateInfo" : "")}>{field.value}</span>
          );
        }

        if (disabled) {
          return (
            <div className="inputContractTerms">
              {displayListElem}
            </div>
          );
        }
        else {
          return (
            <textarea placeholder={placeholder} readOnly={disabled} className={this.props.className + (disabled ? " disabled" : "")} name={this.props.name} value={value} onChange={this.handleChange} />
          );
        }
    }
  }
  
  export default InputContractTerms;
