import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, IndexRoute, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux';

// Layouts
import App from './App'
import AuthContainer from './components/AuthContainer';
import AuthPending from './components/AuthPending';
import HomePage from './components/HomePage';
import Agreement from './components/Agreement';
import People from './components/People';
import Dashboard from './components/Dashboard';
import UserAccount from './components/UserAccount';
import NotFoundPage from './components/NotFoundPage';
import PleaseLogin from './components/PleaseLogin';
import Ratings from './components/Ratings';
import FAQ from './components/FAQ';
import Examples from './components/Examples';
import Stats from './components/Stats';
import RawContracts from './components/RawContracts';
import Faucet from './components/Faucet';
import Funding from './components/Funding';
import Verify from './components/Verify';
import Incentives from './components/Incentives';
import Attributions from './components/Attributions';
import TOS from './components/TOS';
import Messages from './components/Messages';
import Markets from './components/Markets';
import Posting from './components/Posting';
import PostingsPage from './components/PostingsPage';
import About from './components/About';
import Help from './components/Help';
import How from './components/How';
import Ethereum from './components/Ethereum';

import store from './store'

// Initialize react-router-redux.
const history = syncHistoryWithStore(browserHistory, store)

ReactDOM.render((
  <Provider store={store}>
    <AuthContainer 
        faqComp={<FAQ/>} 
        examplesComp={<Examples/>} 
        rawContractsComp={<RawContracts/>}
        contractsComp={<Dashboard/>} 
        faucetComp={<Faucet/>} 
        tosComp={<TOS/>} 
        attributionsComp={<Attributions/>} 
        incentivesComp={<Incentives/>} 
        verifyComp={<Verify/>} 
        pendingComp={<AuthPending/>} 
        loggedoutComp={<HomePage />}
        viewComp={<Agreement />}
        peopleComp={<People />} 
        userComp={<UserAccount/>} 
        pleaseLoginComp={<PleaseLogin/>}
        notFoundComp={<NotFoundPage/>} 
        ratingsComp={<Ratings/>} 
        marketsComp={<Markets/>} 
        postingComp={<Posting/>}
        postingsPageComp={<PostingsPage />}
        aboutComp={<About/>}
        helpComp={<Help/>}
        howComp={<How/>}
        ethereumComp={<Ethereum/>} 
      >
          <Router history={history}>
            <Route path="/" component={App}>
              <Route path="contracts" component={Dashboard} />
              <Route path="userpostings" component={PostingsPage} />
              <Route path="view" component={Agreement} />
              <Route path="new" component={Agreement} />
              <Route path="edit" component={Agreement} />
              <Route path="people" component={People} />
              <Route path="profile" component={UserAccount} />
              <Route path="balances" component={Funding} />
              <Route path="ratings" component={Ratings} />
              <Route path="faq" component={FAQ} />
              <Route path="examples" component={Examples} />
              <Route path="stats" component={Stats} />
              <Route path="rawcontracts" component={RawContracts} />
              <Route path="faucet" component={Faucet} />
              <Route path="home" component={HomePage} />
              <Route path="messages" component={Messages} />
              <Route path="postings" component={Markets} />
              <Route path="posting" component={Posting} />
              <Route path="newposting" component={Posting} />
              <Route path="about" component={About} />
              <Route path="help" component={Help} />
              <Route path="how" component={How} />
              <Route path="ethereum" component={Ethereum} />
              <Route path="*" component={NotFoundPage} />
              <IndexRoute component={HomePage} />
            </Route>
          </Router>
      </AuthContainer>
    </Provider>      
  ),
  document.getElementById('root')
);