import React, { Component } from 'react'
import { connect } from 'react-redux';
import { navNewURL } from './ActionUtils';
import './DialogVersions.css';
import { changeInputValue, refreshVersionsForAgreementList } from './DialogVersionsActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import LoadCard from './LoadCard';
import VersionCard from './VersionCard';
import { closeOverlay, tallOverlay } from './OverlayActions';

class DialogVersions extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.viewAgreementVersion = this.viewAgreementVersion.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }

    let agreementid = this.props.dialogVersions.agreementid;
    if (agreementid !== "") {
      this.props.dispatch(refreshVersionsForAgreementList(agreementid));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.dialogVersions.list !== prevProps.dialogVersions.list || 
      this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || 
      this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height
    ) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }

    if (
      this.props.dialogVersions.versionListLoaded !== prevProps.dialogVersions.versionListLoaded && 
      this.props.dialogVersions.versionListLoaded === false
    ) {
      let agreementid = this.props.dialogVersions.agreementid;
      if (agreementid !== "") {
        this.props.dispatch(refreshVersionsForAgreementList(agreementid));
      }    
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  viewAgreementVersion(agreementId, versionId) {
    this.props.dispatch(navNewURL('/view?agreementid=' + agreementId + "&versionid=" + versionId));
    this.props.dispatch(closeOverlay());
  }

  render() {

    let username = this.props.auth.username;
    let list = this.props.dialogVersions.list;

    let versionElem = [];
    if (this.props.dialogVersions.versionListLoaded) {
      for (let i = 0; i < list.length; i++) {
        let entry = list[i];
        let versionNum = entry.version_num;
        versionElem.push(<VersionCard key={i+ "_entry"} num={versionNum} username={username} data={entry} onClick={this.viewAgreementVersion} />);
        //versionElem.push(<GapCard size="small" key={i + "_gap"} />);
      }
    }
    else {
      versionElem.push(<LoadCard key="load" />);
      //versionElem.push(<GapCard size="small" key="gap" />);
    }

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle noseparator={true} title="Versions for agreement" />
        </div>
        {versionElem}
        <GapCard size="small" />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogVersions: state.dialogVersions
  }
}

const DialogVersionsContainer = connect(mapStateToProps, null)(DialogVersions);
export default DialogVersionsContainer;