import React, { Component } from 'react';
import './LineCard.css';

class LineCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {    
    return (
      <div className="lineCard"></div>
    );
  }
}
  
export default LineCard;