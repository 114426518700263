import React, { Component } from 'react';
import { getContentWidth, getIsMobile, genMarketsURL } from './ActionUtils';
import Link from './Link';
import { shared } from '../Shared';
import './CategoryList.css';

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let dataList = this.props.data || [];
    let parent = this.props.parent || "";

    let {
       breadLocation="",
       breadType="",
       breadCategory="",
       showMore=false,
       expandMore=false
    } = this.props;

    let contentWidth = getContentWidth();
    let tableWidth = contentWidth - 22;
    let numColumns = 5;
    let maxRows = 5;
    //let maxChildren = 5;

    if (getIsMobile() && tableWidth < 500) {
      numColumns = 2;
      maxRows = 10;
    }
    else if (tableWidth < 750) {
      numColumns = 3;
      maxRows = 7;
    }
    else if (tableWidth < 1000) {
      numColumns = 4;
      maxRows = 5;
    }

    if (breadLocation !== "" && breadType !== "") {
      maxRows = 100;
    }

    if (expandMore) {
      maxRows = 1000;
    }

    let maxItems = (numColumns * maxRows);
    if (showMore && dataList.length > maxItems) {
      let moreChildren = [];
      for (let i = maxItems - 1; i < dataList.length; i++) {
        if (moreChildren.length < 5) {
          moreChildren.push(dataList[i]);
        }
      }

      dataList = dataList.slice(0, maxItems - 1);

      let moreCategory = {
        more: true, type: parent, display:"More ...", level: "more", children: moreChildren, breadLocation, breadType, breadCategory
      };

      dataList.push(moreCategory);
    }

    // adjust the number of columns so that every column is the same length except possibly the last.
    let actualRows = Math.ceil(dataList.length / numColumns);
    let reducedColumns = Math.ceil(dataList.length / actualRows);
    numColumns = reducedColumns;

    let numColumnsWithGaps = (numColumns * 2) - 1;
    let maxRowsWithGaps = (maxRows * 2) - 1;

    let rowElemList = [];
    let cellElemList = [];
    for (let row = 0; row < actualRows; row++) {  //for (let i = 0; i < dataList.length; i++) {
      for (let arrayIndex = row; arrayIndex < dataList.length; arrayIndex += actualRows) {
        // grab the element that we want to put in the ith spot if we're counting row by row
        let dataObj = dataList[arrayIndex];

        if (cellElemList.length === numColumnsWithGaps && rowElemList.length < maxRowsWithGaps) {
          // add a gap row before every row except the first
          if (rowElemList.length > 0) {
            rowElemList.push(
              <tr key={"gaprow_" + arrayIndex} className="categoryGapRow">
                <td colSpan={numColumnsWithGaps} className="categoryGapCell"></td>
              </tr>
            );
          }
    
          rowElemList.push(
            <tr key={"row_" + arrayIndex}className="categoryCardRow">
              {cellElemList}
            </tr>
          );

          cellElemList = [];
        }

        if (rowElemList === maxRowsWithGaps) {
          console.log("ARE WE BREAKING");
          break;
        }

        if (cellElemList.length > 0) {
          cellElemList.push(
            <td key={"gapcell_" + arrayIndex} className="categoryGapCell"></td>
          );  
        }

        /* Don't have children in categories for now.. 
        let children = dataObj.children || [];
        if (children.length > maxChildren) {
          children = children.slice(0, maxChildren - 1);

          let moreChildCategory = {
            more: true, type: parent, display:"More ...", level: "more", children: [], breadLocation: dataObj.breadLocation, breadType: dataObj.breadType, breadCategory: dataObj.breadCategory
          };

          children.push(moreChildCategory);
        }
        */

        let breadType = dataObj.breadType;
        //console.log(dataObj);
        let displayString = dataObj.display;
        if (dataObj.level === "category" && (breadType === "service_offered" || breadType === "service_wanted" || breadType === "rental_offered" || breadType === "rental_wanted")) {
          displayString = shared.trimLastToken(displayString);
        }
        cellElemList.push(
          <td key={"cell_" + arrayIndex} className="categoryCardCell">
            <div><span className=""><Link noTarget={true} rel="noopener noreferrer" className="categoryTitle" href={genMarketsURL(dataObj)}>{displayString}</Link></span></div>
            {/* No children in categories for now.. 
              dataObj.hasOwnProperty("children") ? 
              <div className="moveTop5">
                {children.map((item, key) => {
                  return <div key={key} className="moveTop5"><span className=""><Link noTarget={true} rel="noopener noreferrer" className="categorySubtitle" href={genMarketsURL(item)}>{item.display}</Link></span></div>
                })}
              </div>
            : "" */}
          </td>
        );
      }

      if (cellElemList.length > 0) {
        let i = 0;
        while (cellElemList.length < numColumnsWithGaps) {
          if (cellElemList.length > 0) {
            cellElemList.push(
              <td key={"gapcell_end_" + i} className="categoryGapCell"></td>
            );
          }

          cellElemList.push(
            <td key={"emptycell_end_" + i} className="categoryCardEmptyCell"></td>
          );
          i++;
        }
      }
    }

    if (cellElemList.length === numColumnsWithGaps && rowElemList.length < maxRowsWithGaps) {
      if (rowElemList.length > 0) {
        rowElemList.push(
          <tr key={"gaprow_last"} className="categoryGapRow">
            <td colSpan={numColumnsWithGaps} className="categoryGapCell"></td>
          </tr>
        );
      }

      rowElemList.push(
        <tr key={"row_last"} className="categoryCardRow">
          {cellElemList}
        </tr>
      );

      cellElemList = [];
    }

    return (
        <div className="contentCard">
          <table className="categoryCardCnt">
            <tbody>
              {rowElemList}
            </tbody>
          </table>
        </div>
    );
  }
}
  
export default CategoryList;