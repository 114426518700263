import React, { Component } from 'react'
import Menu from './components/Menu';
import Overlay from './components/Overlay';
import { shared } from './Shared';
import { openOverlay } from './components/OverlayActions';
import { connect } from 'react-redux';
import Footer from './components/Footer';

// Styles
import './css/oswald.css'
import './css/open-sans.css'
import './css/pure-min.css'
import './App.css'
import './components/Common.css'

class App extends Component {
  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleTestMsgClick = this.handleTestMsgClick.bind(this);
    this.doNothing = this.doNothing.bind(this);
  }
  
  handleTestMsgClick(event) {
    let testMode = this.props.auth.testMode;
    
    //TODO: Is pathname wrong?
    this.props.dispatch(openOverlay("testmode", {pathname: "", mode: (testMode ? "test":"prod") }));
  }

  doNothing(event) {
    // Do nothing. This is here so that click events are generated on Safari iOS.
  }

  render() {
    let tall = this.props.overlay.tall;
    let tallHeight = this.props.overlay.tallHeight;
    let limitHeight = {};
    if (tall && tallHeight !== 0) {
      limitHeight = {overflow: "hidden", height: (tallHeight + 10) + "px"};
    }

    let classLoggedIn = "loggedin";
    if (this.props.auth.status === "loggedout" || this.props.auth.status === "pending") {
      classLoggedIn = "loggedout";
    }

    let isLoggedOut = (this.props.auth.status === "loggedout");
    let classTestMode = "";
    let testMode = this.props.auth.testMode;
    if (testMode && !isLoggedOut) {
      classTestMode = "testmode";
    }

    let classPageName = "";
    if (shared.PAGE_LIST.includes(this.props.menu.selectedPage)) {
      classPageName = this.props.menu.selectedPage;
    }

    let shouldUseNetworkId = shared.getNetworkId(testMode);
    let otherModeNetworkId = shared.getNetworkId(!testMode);
    let initCheckAddress = this.props.auth.initCheckAddress;
    let ethNetwork = this.props.auth.ethNetwork;
    let flagWrongNetwork = false;
    if (
      this.props.auth.status === "loggedin" && 
      initCheckAddress && 
      ethNetwork !== "" && 
      ethNetwork !== shouldUseNetworkId
    ) {
      flagWrongNetwork = true;
    }

    let warningMsg = "";
    let testMsg = "";
    if (flagWrongNetwork) {
      warningMsg = <span>{"Using " + shared.getNetworkNameLongFromId(ethNetwork) + ". Use your wallet to switch to " + shared.getNetworkNameLongFromId(shouldUseNetworkId) + " or " + shared.getNetworkNameLongFromId(otherModeNetworkId)}.</span>;
    }
    else if (testMode && !isLoggedOut) {
      testMsg = <span><span className="msgLink" onClick={this.handleTestMsgClick}>Test mode enabled</span>.</span>;
    }

    let footerHeight = this.props.auth.footerHeight;
    let messageBarElem = null;
    if (warningMsg !== "" || testMsg !== "") {
      messageBarElem = (
        <div>
          {warningMsg !== "" && <div className="warningMsgContent">{warningMsg}</div>}
          {testMsg !== "" && <div className="testMsgContent">{testMsg}</div>}
        </div>
      );
    }

    return (
      <div className={classLoggedIn} onClick={this.doNothing}>
        <div className={classTestMode}>
          <div className={this.props.auth.device === "mobile" ? "mobile" : "desktop"}>
            <div className={classPageName}>
              <div className={(this.props.overlay.open || this.props.auth.metamaskOpened) ? "overlay" : "nooverlay"}>
                {(this.props.overlay.open || this.props.auth.metamaskOpened) && <Overlay />}
                <Menu />
                <div style={{paddingBottom: (footerHeight + "px")}}><div style={limitHeight}>{this.props.children}</div></div>
                <Footer messageBar={messageBarElem} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    overlay: state.overlay
  }
}

const AppContainer = connect(mapStateToProps, null)(App);
export default AppContainer;
