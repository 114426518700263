
const emptyState = {
  userExists: false,
  displayname: "",
  summary: "",
  profileFile: "",
  hasLatestData: false,
  linkedAccountsLoaded: false,
  linkedAccountList: [],
  userStats: {}, 
  userStatsLoaded: false,
  accountData: {},
  arbitrationDataProd: {},
  arbitrationDataTest: {},
  defaultEthereumAddress: "",
  createdUnix: 0,
  autoAcceptSettings: {},
  allowAutoApprove: "",
  userHasEmail: false,
  emailNewMessages: false,
  emailNewContracts: false,
  emailContractUpdates: false,
  emailAnnouncements: false,
  disableUpdateButtons: false,
  btnSummaryStatus: { text:"", error: true },
  btnEmailStatus: { text:"", error: true },
  btnLinkedStatus: { text:"", error: true },
  btnArbitratorStatus: { text:"", error: true },
  btnEthereumStatus: { text:"", error: true }
}

const UserAccountReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="" } = action.payload; //, query={}

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "profile") {
        return {
          ...state, 
          ...emptyState
        };
      }

      // Empty everything if page not selected
      return {};
    }
    case "USER_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value };
    }
    case "USER_CHANGE_ARBITRATION_INPUT" : {
      let { name, value, mode } = action.payload;

      if (mode === "prod") {
        let arbitrationDataProd = {...state.arbitrationDataProd, [name]: value};
        return {...state, arbitrationDataProd };
      }
      else if (mode === "test") {
        let arbitrationDataTest = {...state.arbitrationDataTest, [name]: value};
        return {...state, arbitrationDataTest };        
      }

      return {...state};
    }
    case "PROFILE_DATA" : {
      let data = action.payload;

      let { user_exists=false, arbitration_settings_prod={}, arbitration_settings_test={}, auto_accept_settings={}, default_address="", displayname="", summary="", profile_photo="", email_settings={}, created_unix=0 } = data;
      let arbitrationDataProd = {...arbitration_settings_prod};
      let arbitrationDataTest = {...arbitration_settings_test};

      let {
        new_messages=true,
        new_contracts=true,
        contract_updates=true,
        announcements=true
      } = email_settings;

      let emailNewMessages = new_messages;
      let emailNewContracts = new_contracts;
      let emailContractUpdates = contract_updates;
      let emailAnnouncements = announcements;

      let profileFile = "";
      if (profile_photo !== "") {
        profileFile = profile_photo;
      }
    
      let allowAutoApprove = "0";
      if (auto_accept_settings && auto_accept_settings.hasOwnProperty("payment") && auto_accept_settings.payment) {
        allowAutoApprove = "1";
      }
      return {...state, defaultEthereumAddress: default_address, accountData: data, createdUnix: created_unix, userExists: user_exists, displayname, summary, profileFile, autoAcceptSettings: auto_accept_settings, allowAutoApprove, arbitrationDataProd, arbitrationDataTest, emailNewMessages, emailNewContracts, emailContractUpdates, emailAnnouncements };
    }
    case "USER_LINKED_ACCOUNT_LIST" : {
      let { list=[] } = action.payload;

      let userHasEmail = false;
      for (let i = 0; i < list.length; i++) {
        let entry = list[i];
        if (entry.account_type === "email") {
          userHasEmail = true;
        }
      }

      return {...state, linkedAccountList: list, linkedAccountsLoaded: true, hasLatestData: true, userHasEmail };
    }
    case "CLOSE_OVERLAY" : {
      let data = action.payload;
      // Refresh linked accounts when overlay closes only if there was an update
      if (data && data.profileDataUpdate) {
        return {...state, hasLatestData: false };
      }
      return state;
    }
    case "USER_STATS" : {
      let { stats={} } = action.payload;
      return {...state, userStats: stats, userStatsLoaded: true, hasLatestData: true };
    }
    case "USER_ACCOUNT_ATTESTATION_CHANGE" : {
      return {...state, hasLatestData: false };
    }
    case "DIALOGADDATTESTATION_CHANGE_STEP" : {
      let { step="" } = action.payload;
      if (step === "success") {
        return {...state, hasLatestData: false };
      }
      return state;
    }
    default: {
      return state
    }
  }
}

export default UserAccountReducer