import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './QuestionTooltip.css';
import questionImg from '../question_darkgrey.png';

class QuestionTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {showMessage:false, xLeft:0, xRight: 0};
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleContentClick = this.handleContentClick.bind(this);
    this.mouseDownOnQuestion = false;
    this.updateQuestionPos = this.updateQuestionPos.bind(this);
  }

  componentDidMount() {
    this.updateQuestionPos();
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.screenWidth !== prevProps.screenWidth) {
      this.updateQuestionPos();
    }
  }

  updateQuestionPos() {
    let comp = this.refs.question;
    if (comp) {
      let el = ReactDOM.findDOMNode(comp);
      let rect = el.getBoundingClientRect();
      this.setState({xLeft: rect.x, xRight: (window.innerWidth - rect.x - rect.width)});
    }
  }

  handleMouseDown(event) {
    this.mouseDownOnQuestion = true;
  }

  handleMouseUp(event) {
    this.mouseDownOnQuestion = false;
  }

  handleClick(event) {
    let newShowMessage = !this.state.showMessage;
    this.setState({showMessage: newShowMessage});

    if (newShowMessage) {
      this.updateQuestionPos();
    }

    if (this.props.onToggle) { this.props.onToggle(newShowMessage) };
  }

  handleOutsideClick(event) {
    if (!this.mouseDownOnQuestion) {
      if (this.state.showMessage) {
        this.setState({showMessage: false});
        if (this.props.onToggle) { this.props.onToggle(false) };
      }  
    }
  }

  handleContentClick(event) {
    this.setState({showMessage: false});
    if (this.props.onToggle) { this.props.onToggle(false) };
  }

  render() {

    let screenWidth = this.props.screenWidth;
    let tooltipWidth = 200;
    if (screenWidth > 350) {
      tooltipWidth = screenWidth - 140;
      tooltipWidth = tooltipWidth > 490 ? 490 : tooltipWidth;
    }

    let leftPos = -1 * Math.floor(tooltipWidth/2);
    //let leftPos = 0;
    let messageBoxType = "questionMsgBox";
    if (this.props.lowerVersion) {
      messageBoxType = "questionMsgBoxLower";
    }
    else if (this.props.leftVersion) {
      messageBoxType = "questionMsgBoxLeft";
      leftPos = -1 * (Math.floor(tooltipWidth) + 20);
    }

    let xLeft = this.state.xLeft;
    let xRight = this.state.xRight;
    leftPos = Math.min(leftPos, xRight - tooltipWidth - 15); 
    leftPos = Math.max(leftPos, -xLeft + 20); 

    return (
      <div className={this.props.textAligned ? "questionCntTextAligned" : "questionCnt"}>
        <img ref="question" onMouseDown={this.handleMouseDown} onMouseUp={this.handleMouseUp} onClick={this.handleClick} className={this.props.textAligned ? "questionIconTextAligned" : "questionIcon"} src={questionImg} alt={this.props.alt} />
        <div onClick={this.handleContentClick} style={{width: tooltipWidth + "px", left: leftPos + "px"}} className={this.state.showMessage ? messageBoxType : "hide"}>{this.props.message}</div>
      </div>
    );
  }
}
  
export default QuestionTooltip;