import { connect } from 'react-redux';
import React, { Component } from 'react'
import './DialogAddAttestation.css';
import DialogTitle from './DialogTitle';   
import GapCard from './GapCard';
import InputBasic from './InputBasic';
import ButtonAction from './ButtonAction';
import ButtonSelect from './ButtonSelect';
import { titleByType, validForType, formatPhoneNumber, getTextWidth } from "./ActionUtils";
import { 
  DEFAULT_BUTTON_WIDTH, 
  BUTTON_TOGGLE_EXTRA_PIXELS, 
  BUTTON_TOGGLE_FONT,
  BUTTON_ACTION_EXTRA_PIXELS,
  BUTTON_ACTION_FONT
} from './Constants.js';
import loadImg from '../load_blue.gif';
import facebookPublicImg from '../facebook_post3.png';
import facebookCommentImg from '../facebook_comment4.png';
import { changeInputValue, checkCode, checkForVerification, signLinkAccount, resendVerification, addAttestation } from './DialogAddAttestationActions';
import { closeOverlay, tallOverlay } from './OverlayActions';
import store from '../store';
import moment from 'moment-timezone';
import emailImg from '../email.png';
import twitterImg from '../twitter.png';
import facebookImg from '../facebook.png';
import phoneImg from '../phone.png';
import redditImg from '../reddit.png';
import { openOverlay } from './OverlayActions';
import { shared } from '../Shared';
import Link from './Link';

const EVERYONE_LABEL = "Everyone";
const PEOPLE_WITH_AGREEMENTS_LABEL = "People I have contracts with";
const DISABLED_LABEL = "Disabled";

const CHECK_CODE_LABEL = "Check code";
const RESEND_LABEL = "Resend";

class DialogAddAttestation extends Component {

  constructor(props, context) {
    super(props)
    this.state = { cnt: 0 };

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.checkCode = this.checkCode.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.resendText = this.resendText.bind(this);
    this.signMessage = this.signMessage.bind(this);
    this.parseURL = this.parseURL.bind(this);
    this.verify = this.verify.bind(this);
    this.done = this.done.bind(this);
    this.stepEnter = this.stepEnter.bind(this);
    this.checkForVerification = this.checkForVerification.bind(this);
    this.refreshCnt = this.refreshCnt.bind(this);
    this.addAttestation = this.addAttestation.bind(this);
    this.addEthereumAttestation = this.addEthereumAttestation.bind(this);
    this.accessCodeDialog = this.accessCodeDialog.bind(this);
    this.intervalId = null;
    this.intervalCount = 0;
    this.intervalRefreshId = null;
    this.overlayNode = null;
  }
  
  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }

    let step = this.props.dialogAddAttestation.step;
    clearInterval(this.intervalId);
    clearInterval(this.intervalRefreshId);
    this.intervalCount = 0;
    if (step === "start") {
      this.intervalId = setInterval(() => {
        this.checkForVerification();
      }, 2000);
      this.intervalRefreshId = setInterval(() => {
        this.refreshCnt();
      }, 500);
    }

    let everyoneWidth = Math.ceil(getTextWidth(EVERYONE_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let peopleWithAgreementsWidth = Math.ceil(getTextWidth(PEOPLE_WITH_AGREEMENTS_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let disabledWidth = Math.ceil(getTextWidth(DISABLED_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("maxVisibilityButtonWidth", Math.max(everyoneWidth, peopleWithAgreementsWidth, disabledWidth)));

    let checkCodeWidth = Math.ceil(getTextWidth(CHECK_CODE_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    let resendWidth = Math.ceil(getTextWidth(RESEND_LABEL, BUTTON_ACTION_FONT)) + BUTTON_ACTION_EXTRA_PIXELS;
    this.props.dispatch(changeInputValue("maxVerificationButtonWidth", Math.max(checkCodeWidth, resendWidth)));
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalRefreshId);
    this.intervalCount = 0;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }

    let step = this.props.dialogAddAttestation.step;
    if (step !== prevProps.dialogAddAttestation.step) {
      clearInterval(this.intervalId);
      clearInterval(this.intervalRefreshId);
      this.intervalCount = 0;
      if (step === "start") {
        this.intervalId = setInterval(() => {
          this.checkForVerification();
        }, 2000);
        this.intervalRefreshId = setInterval(() => {
          this.refreshCnt();
        }, 500);
      }

      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }

  addAttestation() {
    let type = this.props.dialogAddAttestation.newAttestationType;
    let privacy = this.props.dialogAddAttestation.newAttestationPrivacy;
    let input = this.props.dialogAddAttestation.newAttestationInput;
    let makeDefault = false;
    let valid = validForType(input, type, {});

    if (valid) {
      this.props.dispatch(addAttestation(
        type,
        privacy, 
        input,
        makeDefault 
      ));
    }
    else {
      this.props.dispatch(changeInputValue("newAttestationError", true));
    }
  }

  addEthereumAttestation() {
    let type = "ethereum";
    let privacy = this.props.dialogAddAttestation.newAttestationPrivacy;
    let input = this.props.auth.ethAddress;
    let makeDefault = this.props.dialogAddAttestation.newAttestationMakeDefault === "yes";

    if (input !== "") {
      this.props.dispatch(addAttestation(
        type,
        privacy, 
        input,
        makeDefault 
      ));  
    }
    else {
      this.props.dispatch(changeInputValue("newAttestationError", true));
    }
  }

  accessCodeDialog(event) {
    this.props.dispatch(openOverlay("access_code", {}));
  }

  checkForVerification() {
    let type = this.props.dialogAddAttestation.type;
    let privacy = this.props.dialogAddAttestation.privacy;
    if (type === "reddit") {
      this.intervalCount++;
      if (this.intervalCount < 20 || (this.intervalCount % 100 === 0 && this.intervalCount < 1000)) {
        let value = this.props.dialogAddAttestation.value;
        this.props.dispatch(checkForVerification(type, privacy, value, {}, false));
      }
    }
  }

  refreshCnt() {
    this.setState({cnt: (this.state.cnt + 1)});
  }

  changeInputValue(name, value) {
    if (name !== "confirmationCodeInput") {
      value = value.toLowerCase();
    }
    this.props.dispatch(changeInputValue(name, value));
  }

  checkCode() {
    let type = this.props.dialogAddAttestation.type;
    let value = this.props.dialogAddAttestation.value;
    let privacy = this.props.dialogAddAttestation.privacy;
    let code = this.props.dialogAddAttestation.confirmationCodeInput;
    this.props.dispatch(checkCode(type, value, code, privacy));
  }

  parseURL(url, type) {
    if (type === "facebook") {
      //https://www.facebook.com/juhamil/posts/300544216683177
      //https://www.facebook.com/elliotolds/posts/10105879106645090?comment_id=10112806061696640
      let found = url.match(/^https:\/\/(www\.)?facebook\.com\/([a-zA-Z0-9.]+)\/posts\/([0-9]+)(\?comment_id=[0-9]+)?/);
      if (found && found.length === 5) {
        let name = found[2] || "";
        let postid = found[3] || "";
        let commentid = found[4] || "";

        if (commentid.length > 12) {
          commentid = commentid.substring(12);
        }
        return { name, postid, commentid, success: true };
      }
    }

    if (type === "twitter") {
      //https://twitter.com/EthGeek/status/1064086765118451712
      let found = url.match(/^https:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_]+)\/status\/([0-9]+)/);
      if (found && found.length === 4) {
        let name = found[2] || "";
        let postid = found[3] || "";
        return { name, postid, success: true };
      }
    }

    if (type === "reddit") {
      //https://www.reddit.com/r/test/comments/9wjql2/test_post/
      //https://www.reddit.com/r/test/comments/9wjql2/test_post/e9l5cm1/
      let found = url.match(/^https:\/\/(www\.)?reddit\.com\/r\/([a-zA-Z0-9]+)\/comments\/([a-zA-Z0-9]+)\/([a-zA-Z0-9_]+)(\/[a-zA-Z0-9]+)?/);
      if (found && found.length === 6) {
        let subreddit = found[2] || "";
        let postid = found[3] || "";
        let postname = found[4] || "";
        let commentid = found[5] || "";
        if (commentid.length > 0) {
          commentid = commentid.substring(1);
        }
        return { subreddit, postid, postname, commentid, success: true };
      }
    }

    return { success: false };
  }

  verify() {
    let type = this.props.dialogAddAttestation.type;
    let privacy = this.props.dialogAddAttestation.privacy;
    if (type === "reddit" || type === "twitter" || type === "facebook") {
      let postUrl = this.props.dialogAddAttestation.postUrlInput;
      let parsedObj = this.parseURL(postUrl, type);
      let value = this.props.dialogAddAttestation.value;
      if (parsedObj.hasOwnProperty("postid") && parsedObj.postid !== "") {
        this.props.dispatch(checkForVerification(type, privacy, value, parsedObj, true));
      }
    }
  }

  done() {
    this.props.dispatch(closeOverlay());
  }

  stepEnter() {
    let step = this.props.dialogAddAttestation.step;
    if (step === "start") {
      this.props.dispatch(changeInputValue("step", "enter"));
      this.props.dispatch(changeInputValue("postUrlInput", ""));
    }
    else if (step === "enter") {
      this.props.dispatch(changeInputValue("step", "start"));
      this.props.dispatch(changeInputValue("postUrlInput", ""));
    }
  }

  resendEmail() {
    let emailValue = this.props.dialogAddAttestation.value;
    if (emailValue !== "") {
      this.props.dispatch(resendVerification("email", emailValue));
    }
  }

  resendText() {
    let phoneValue = this.props.dialogAddAttestation.value;
    if (phoneValue !== "") {
      this.props.dispatch(resendVerification("phone", phoneValue));
    }
  }

  signMessage() {
    let privacy = this.props.dialogAddAttestation.privacy;
    let makeDefault = this.props.dialogAddAttestation.makeDefault;
    this.props.dispatch(signLinkAccount(privacy, makeDefault));
  }
  
  render() {
    let auth = store.getState().auth;
    let type = this.props.dialogAddAttestation.type;
    let value = this.props.dialogAddAttestation.value;
    let step = this.props.dialogAddAttestation.step;
    let stepInfo = this.props.dialogAddAttestation.stepInfo;
    let hasAccount = this.props.auth.hasAccount;
    let hasUsernameMatch = this.props.auth.hasUsernameMatch;

    if (type === "phone") {
      value = formatPhoneNumber(value);
    }

    let newAttestationType = this.props.dialogAddAttestation.newAttestationType;
    let newAttestationInput = this.props.dialogAddAttestation.newAttestationInput;
    let title = "Link new " + shared.lowerCase(titleByType(type));
    if (step === "new"  && type !== "ethereum") {
      title = "Link new " + shared.lowerCase(titleByType(newAttestationType));
    }

    let { retryTimestampUnix=0, messageText="", messageType="" } = stepInfo;

    let timestampUnix = moment.tz('America/Los_Angeles').unix();
    let allowRetry = false;
    let retryInSeconds = 0;
    if (timestampUnix >= retryTimestampUnix) {
      allowRetry = true;
    }
    else {
      retryInSeconds = retryTimestampUnix - timestampUnix;
      if (retryInSeconds > 60) {
        retryInSeconds = 0;
      }
    }

    let alreadyLinkedContent = "";
    if (step === "exists") {
      if (stepInfo && stepInfo.hasOwnProperty("linkIsYours") && stepInfo.linkIsYours === true) {
        alreadyLinkedContent = (
          <div>
            <div style={{color:"#1Aa239"}}>That {titleByType(type).toLowerCase()} is already linked!</div>
            <div className="moveTop20">{titleByType(type)} <span className="attestationWrapValue">{value}</span> is already linked to your Atstake account.</div>
            <div>
              <ButtonAction name="done" className="moveTop20" text="Done" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.done}/>
            </div>
          </div>
        );
      }
      else {
        alreadyLinkedContent = (
          <div>
            <div style={{color:"#dd2010"}}>That {titleByType(type).toLowerCase()} is already linked.</div>
            <div className="moveTop20">{titleByType(type)} <span className="attestationWrapValue">{value}</span> is already linked to a different Atstake account.</div>
            <div>
              <ButtonAction name="done" className="moveTop20" text="Done" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.done}/>
            </div>
          </div>
        );
      }
    }

    let successContent = "";
    if (step === "success") {
      successContent = (
        <div>              
          <div style={{wordBreak:"break-word"}}>
            <div style={{color:"#1Aa239",paddingBottom:"20px"}}>Successfully linked your {titleByType(type)}!</div>
            {titleByType(type)} <b>{value}</b> has been verified.
            <div>
              <ButtonAction name="done" className="moveTop20" text="Done" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.done}/>
            </div>
          </div>
        </div>
      );
    }

    let titleTypeTxt = titleByType(newAttestationType);
    let isValidInput = validForType(newAttestationInput, newAttestationType, {});
    let selectedAddress = this.props.auth.ethAddress;

    let newTypeMessage = "Enter the " + titleTypeTxt.toLowerCase() + " you want to link";
    if (newAttestationType === "phone") {
      newTypeMessage = "Enter the " + titleTypeTxt.toLowerCase() + " (USA or Canada) you want to link";
    }

    let urlParsedObj = this.parseURL(this.props.dialogAddAttestation.postUrlInput, this.props.dialogAddAttestation.type)
    let disableVerify = !urlParsedObj.success;

    let twitterMsg = "I'm verifying myself as " + auth.username + " on @atstakeapp";
    let facebookMsg = "I'm verifying myself as " + auth.username + " on Atstake.net";

    let maxIdentifierLength = shared.MAX_IDENTIFIER_LENGTH;
    if (newAttestationType === "twitter") {
      maxIdentifierLength = shared.MAX_TWITTER_USERNAME_LENGTH;
    } else if (newAttestationType === "reddit") {
      maxIdentifierLength = shared.MAX_REDDIT_USERNAME_LENGTH;
    } else if (newAttestationType === "facebook") {
      maxIdentifierLength = shared.MAX_FACEBOOK_USERNAME_LENGTH;
    } else if (newAttestationType === "phone") {
      maxIdentifierLength = shared.MAX_PHONE_NUMBER_LENGTH;
    }

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title={title} />
          {type !== "ethereum" && step === "new" && (
            <div>
              <div className="contentLabel">Select identifier type</div>
              <div className="contactSelectDiv">
                <ButtonSelect className="moveTop5" image={emailImg} text="Email address" name="newAttestationType" value="email" selected={this.props.dialogAddAttestation.newAttestationType} onChange={this.changeInputValue}/>
                <ButtonSelect className="moveTop5" image={phoneImg} text="Phone number" name="newAttestationType" value="phone" selected={this.props.dialogAddAttestation.newAttestationType} onChange={this.changeInputValue}/>
                <ButtonSelect className="moveTop5" image={twitterImg} text="Twitter username" name="newAttestationType" value="twitter" selected={this.props.dialogAddAttestation.newAttestationType} onChange={this.changeInputValue}/>
                <ButtonSelect className="moveTop5" imgstyle={{padding:"5px 7px 5px 3px"}} image={facebookImg} text="Facebook username" name="newAttestationType" value="facebook" selected={this.props.dialogAddAttestation.newAttestationType} onChange={this.changeInputValue}/>
                <ButtonSelect className="moveTop5" image={redditImg} text="Reddit username" name="newAttestationType" value="reddit" selected={this.props.dialogAddAttestation.newAttestationType} onChange={this.changeInputValue}/>
              </div>
              <div>
                <div className="contentLabel moveTop20">{newTypeMessage}</div>
                <InputBasic maxlength={maxIdentifierLength} disabled={false} placeholder={titleTypeTxt} type="text" className="standardWidthInput moveRight5 moveTop5" name="newAttestationInput" value={this.props.dialogAddAttestation.newAttestationInput} onChange={this.changeInputValue} />
                <div className={"inputError moveTop5" + (this.props.dialogAddAttestation.newAttestationError ? "" : " hide")}>{"Please enter a valid " + titleTypeTxt + "."}</div>
              </div>
              <div className="contentLabel moveTop20">Choose who can see this account</div>
              <div className="contactSelectDiv">
                <ButtonSelect className="moveRight20 moveTop5" text={EVERYONE_LABEL} minWidth={this.props.dialogAddAttestation.maxVisibilityButtonWidth} name="newAttestationPrivacy" value="public" selected={this.props.dialogAddAttestation.newAttestationPrivacy} onChange={this.changeInputValue}/>
                <ButtonSelect className="moveRight20 moveTop5" text={PEOPLE_WITH_AGREEMENTS_LABEL} width={this.props.dialogAddAttestation.maxVisibilityButtonWidth} name="newAttestationPrivacy" value="private" selected={this.props.dialogAddAttestation.newAttestationPrivacy} onChange={this.changeInputValue}/>
              </div>
              <div>
                <ButtonAction disabled={!isValidInput} name="add" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20" text="Add" onClick={this.addAttestation}/>
              </div>
            </div>
          )}
          {type === "ethereum" && (
            <div>
              {step === "new" && 
                <div>
                  {selectedAddress === "" ? (
                    <div>
                      <div className="contentLabel ">Please unlock the Ethereum wallet you'd like to use</div>
                      <InputBasic disabled={true} placeholder="Unlock Ethereum wallet" type="text" className="standardWidthInput moveRight5 moveTop5" name="" value="" />
                      <div className={"inputError moveTop5" + (this.props.dialogAddAttestation.newAttestationError ? "" : " hide")}>{"Please unlock your Ethereum wallet."}</div>
                    </div>
                  ) : (
                    <div>
                      <div className="contentLabel">Using your Ethereum wallet, select the address you'd like to link.</div>
                      <div className="contentLabel moveTop20">Your current address is:</div>
                      <InputBasic disabled={true} placeholder="Ethereum address" type="text" className="standardWidthInput moveRight5 moveTop5" name="" value={selectedAddress} />
                    </div>
                  )}
                  {hasAccount && <div className="contentLabel moveTop20">This address is already linked with {hasUsernameMatch ? "your" : "another"} Atstake account. You can only add unlinked addresses.</div>}
                  {!hasAccount && <div>
                    <div className="contentLabel moveTop20">Make default</div>
                    <div className="contactSelectDiv">
                      <ButtonSelect className="moveRight20 moveTop5" text="Yes" minWidth={DEFAULT_BUTTON_WIDTH} name="newAttestationMakeDefault" value="yes" selected={this.props.dialogAddAttestation.newAttestationMakeDefault} onChange={this.changeInputValue}/>
                      <ButtonSelect className="moveRight20 moveTop5" color="black" text="No" minWidth={DEFAULT_BUTTON_WIDTH} name="newAttestationMakeDefault" value="no" selected={this.props.dialogAddAttestation.newAttestationMakeDefault} onChange={this.changeInputValue}/>
                    </div>
                    <div className="contentLabel moveTop20">Choose who can see this address</div>
                    <div className="contactSelectDiv">
                      <ButtonSelect className="moveRight20 moveTop5" text={EVERYONE_LABEL} minWidth={this.props.dialogAddAttestation.maxVisibilityButtonWidth} name="newAttestationPrivacy" value="public" selected={this.props.dialogAddAttestation.newAttestationPrivacy} onChange={this.changeInputValue}/>
                      <ButtonSelect className="moveRight20 moveTop5" text={PEOPLE_WITH_AGREEMENTS_LABEL} minWidth={this.props.dialogAddAttestation.maxVisibilityButtonWidth} name="newAttestationPrivacy" value="private" selected={this.props.dialogAddAttestation.newAttestationPrivacy} onChange={this.changeInputValue}/>
                      <ButtonSelect className="moveRight20 moveTop5" text={DISABLED_LABEL} minWidth={this.props.dialogAddAttestation.maxVisibilityButtonWidth} name="newAttestationPrivacy" value="disabled" selected={this.props.dialogAddAttestation.newAttestationPrivacy} onChange={this.changeInputValue}/>
                    </div>
                    <div>
                      <ButtonAction disabled={selectedAddress === ""} name="add" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20" text="Add" onClick={this.addEthereumAttestation}/>
                    </div>
                  </div>}
                  <div className={hasAccount ? "moveTop20" : "miniText moveTop20"}>Want to link on a different device? <span onClick={this.accessCodeDialog} className="bluelink">Generate an access code</span></div>
                </div>
              }
              {step === "exists" && alreadyLinkedContent}
              {step === "start" && 
                <div className="">
                  <div style={{wordBreak:"break-word"}}>Sign the message <span style={{fontWeight:"bold"}}>"My Atstake username is {auth.username}"</span> with your {titleByType(type)} {value}.</div>
                  <div className="">
                    <ButtonAction name="check" className="moveTop20" text="Click to sign" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.signMessage}/>
                  </div>
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
          {type === "twitter" && (
            <div>
              {step === "exists" && alreadyLinkedContent}
              {(step === "start" || step === "enter") && 
                <div className="">
                  <div className="moveBottom5">Log in to Twitter as <b>{value}</b> and tweet the following message.</div>
                  <div className="verificationMsg">{twitterMsg}</div>
                  <div><Link target="_blank" rel="noopener noreferrer" className="btn moveTop20" href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(twitterMsg)}>Click to tweet</Link></div>
                  <div className="">
                    <div className="moveTop20">Enter the URL of your tweet and click verify.</div>
                    <InputBasic maxlength={shared.MAX_URL_LENGTH} placeholder="URL of tweet" type="text" className="dialogInput moveTop5" name="postUrlInput" value={this.props.dialogAddAttestation.postUrlInput} onChange={this.changeInputValue} />
                    <div><ButtonAction disabled={disableVerify} name="check" className="moveTop20" text="Verify" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.verify}/></div>
                    {messageType === "info" && <div className="inputExplainer moveTop5">{messageText}</div>}
                    {messageType === "error" && <div className="inputError moveTop5">{messageText}</div>}
                  </div>
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
          {type === "facebook" && (
            <div>
              {step === "exists" && alreadyLinkedContent}
              {(step === "start" || step === "enter") && 
                <div className="">
                  <div className="moveBottom5">Log in to Facebook as <b>{value}</b> and comment under a public post with the following message.</div>
                  <div className="verificationMsg">{facebookMsg}</div>
                  <div className="moveTop20">It's important that you leave a comment instead of creating a standalone post. Make sure "Who should see this?" on the post that you're commenting under is set to <span style={{fontWeight:"bold"}}>public</span>.</div>
                  <div><img style={{height:"175px", border:"1px solid #ccc"}} className="moveTop5" src={facebookPublicImg} alt="Make Facebook post public" /></div>
                  <div className="">
                    <div className="moveTop20">Enter the URL of your comment and click verify. You can find the comment URL by clicking on the comment timestamp (E.g. "1d or 14w").
                    Feel free to comment under <Link target="_blank" rel="noopener noreferrer" className="bluelink" href={"https://www.facebook.com/atstakeapp/posts/363150237961804"}>this post</Link> on our Facebook page.</div>
                    <div><img style={{height:"75px", border:"1px solid #ccc"}} className="moveTop5" src={facebookCommentImg} alt="Facebook comment" /></div>
                    <InputBasic maxlength={shared.MAX_URL_LENGTH} placeholder="URL of comment" type="text" className="dialogInput moveTop5" name="postUrlInput" value={this.props.dialogAddAttestation.postUrlInput} onChange={this.changeInputValue} />
                    <div><ButtonAction disabled={disableVerify} name="check" className="moveTop20" text="Verify" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.verify}/></div>
                    {messageType === "info" && <div className="inputExplainer moveTop5">{messageText}</div>}
                    {messageType === "error" && <div className="inputError moveTop5">{messageText}</div>}
                  </div>
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
          {type === "reddit" && (
            <div>
              {step === "exists" && alreadyLinkedContent}
              {(step === "start" || step === "enter") && 
                <div className="">
                  <div className="moveBottom5">Log in to Reddit with username <b>{value}</b> and post the following message in a comment 
                    underneath <Link target="_blank" rel="noopener noreferrer" className="bluelink" href={"https://www.reddit.com/r/atstake/comments/isy4a3/reddit_username_verification_thread/"}>this post</Link>.</div>
                  <div className="verificationMsg">I'm verifying myself as {auth.username} on Atstake.net</div>
                  <div className="">
                    <div className="moveTop20">We are checking Reddit and waiting for your comment to appear.</div>
                    <div><img className="loadIcon" src={loadImg} alt="Loading" /></div>
                    <div className="inputExplainer">Not detected?  <span className="bluelink" onClick={this.stepEnter}>Click here</span>.</div>
                  </div>
                  {step === "enter" &&
                    <div className="">
                      <div className="moveTop20">Enter the URL of your Reddit comment and click verify. Make sure you posted your comment under the post linked above.</div>
                      <InputBasic maxlength={shared.MAX_URL_LENGTH} placeholder="URL of comment" type="text" className="dialogInput moveTop5" name="postUrlInput" value={this.props.dialogAddAttestation.postUrlInput} onChange={this.changeInputValue} />
                      <div><ButtonAction disabled={disableVerify} name="check" className="moveTop20" text="Verify" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.verify}/></div>
                      {messageType === "info" && <div className="inputExplainer moveTop5">{messageText}</div>}
                      {messageType === "error" && <div className="inputError moveTop5">{messageText}</div>}
                    </div>
                  }
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
          {type === "email" && (
            <div>
              {step === "exists" && alreadyLinkedContent}
              {step === "start" && 
                <div className="">
                  <div>We've sent an email to {value} with a confirmation code.  Enter it below.</div>
                  <InputBasic maxlength="6" restrict="upperalphanum" placeholder="Confirmation code" type="text" className="dialogInput moveTop5" name="confirmationCodeInput" value={this.props.dialogAddAttestation.confirmationCodeInput} onChange={this.changeInputValue} />
                  <div className="">
                    <ButtonAction name="check" className="moveTop20 moveRight20" text={CHECK_CODE_LABEL} minWidth={this.props.dialogAddAttestation.maxVerificationButtonWidth} onClick={this.checkCode}/>
                    <ButtonAction disabled={!allowRetry} name="check" className="moveTop20" text={RESEND_LABEL} minWidth={this.props.dialogAddAttestation.maxVerificationButtonWidth} onClick={allowRetry ? this.resendEmail : null}/>
                  </div>
                  {messageType === "info" && <div className="inputExplainer moveTop5">{messageText}</div>}
                  {messageType === "error" && <div className="inputError moveTop5">{messageText}</div>}
                  {!allowRetry && <div className="inputExplainer moveTop5">Resend will be available in {retryInSeconds}s</div>}
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
          {type === "phone" && (
            <div>
              {step === "exists" && alreadyLinkedContent}
              {step === "start" && 
                <div className="">
                  <div>We've sent a text message to {value} with a confirmation code.  Enter it below.</div>
                  <InputBasic maxlength="6" restrict="upperalphanum" placeholder="Confirmation code" type="text" className="dialogInput moveTop5" name="confirmationCodeInput" value={this.props.dialogAddAttestation.confirmationCodeInput} onChange={this.changeInputValue} />
                  <div className="">
                    <ButtonAction name="check" className="moveTop20 moveRight20" text={CHECK_CODE_LABEL} minWidth={this.props.dialogAddAttestation.maxVerificationButtonWidth} onClick={this.checkCode}/>
                    <ButtonAction disabled={!allowRetry} name="check" className="moveTop20" text={RESEND_LABEL} minWidth={this.props.dialogAddAttestation.maxVerificationButtonWidth} onClick={allowRetry ? this.resendText : null}/>
                  </div>
                  {messageType === "info" && <div className="inputExplainer moveTop5">{messageText}</div>}
                  {messageType === "error" && <div className="inputError moveTop5">{messageText}</div>}
                  {!allowRetry && <div className="inputExplainer moveTop5">Resend will be available in {retryInSeconds}s</div>}
                </div>
              }
              {step === "success" && successContent}
            </div>
          )}
        <GapCard />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogAddAttestation: state.dialogAddAttestation
  }
}

const DialogAddAttestationContainer = connect(mapStateToProps, null)(DialogAddAttestation);
export default DialogAddAttestationContainer;