import React, { Component } from 'react';
import Link from './Link';
import { getContentWidth, getIsMobile, /*dateUnixToShortString,*/ getPriceMessageForToken } from './ActionUtils';
import './PostingList.css';
import blankImg from "../blank.jpg";
import NoResultsCard from './NoResultsCard';

class PostingList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let limitRows = this.props.limitRows || false;
    let rows = this.props.rows || 100;
    let dataList = this.props.data || [];
    let contentWidth = getContentWidth();
    let tableWidth = contentWidth - 22;
    let numColumns = 5;
    let maxRows = 3;
    let showLoad = this.props.showLoad || false;
    if (showLoad) {      
      let blankPosting = {
        postingid: "",
        contract_template: "",
        created_unix: 0,
        timestamp_unix: 0,
        title: "",
        username: "",
        thumb_0: ""
       };

      dataList = [];
      for (let i = 0; i < 40; i++) {
        dataList.push(blankPosting);
      }
    }

    if (getIsMobile() && tableWidth < 500) {
      numColumns = 2;
      maxRows = 5;
    }
    else if (tableWidth < 750) {
      numColumns = 3;
      maxRows = 4;
    }
    else if (tableWidth < 1000) {
      numColumns = 4;
      maxRows = 3;
    }

    if (limitRows) {
      maxRows = Math.min(maxRows, rows);
    }
    else {
      maxRows = rows;
    }

    let numColumnsWithGaps = (numColumns * 2) - 1;
    let maxRowsWithGaps = (maxRows * 2) - 1;

    let rowElemList = [];
    let cellElemList = [];
    for (let i = 0; i < dataList.length; i++) {
      let dataObj = dataList[i];

      if (cellElemList.length === numColumnsWithGaps && rowElemList.length < maxRowsWithGaps) {
        if (rowElemList.length > 0) {
          rowElemList.push(
            <tr key={"row_" + i} className="categoryGapRow">
              <td colSpan={numColumnsWithGaps} className="postingGapCell"></td>
            </tr>
          );
        }
  
        rowElemList.push(
          <tr key={"gaprow_" + i} className="categoryCardRow">
            {cellElemList}
          </tr>
        );

        cellElemList = [];
      }

      if (rowElemList.length === maxRowsWithGaps) {
        break;
      }

      if (cellElemList.length > 0) {
        cellElemList.push(
          <td key={"gapcell_" + i} className="postingGapCell"></td>
        );  
      }

      let thumbnailSrc = "";
      let grayScale = false;
      if (showLoad) {
        thumbnailSrc = blankImg;
      }
      else if (dataObj.thumb_0) {
        thumbnailSrc = "https://atstake.s3.amazonaws.com/" + dataObj.thumb_0;
      }
      else {
        grayScale = true;
        if (dataObj.bread_type === "item_wanted" || dataObj.bread_type === "item_offered") {
          thumbnailSrc = "https://atstake.s3.amazonaws.com/a6960e30cb98372e27a16e9d1e50e97782be60975b3e6863349cddf8b432503f.jpg";
        } else if(dataObj.bread_type === "service_wanted" || dataObj.bread_type === "service_offered") {
          thumbnailSrc = "https://atstake.s3.amazonaws.com/dc17669e4751f0f4d633d94378ce25f35e03ecfe5e60ae5f622f3e4b6173b3cd.jpg";
        } else if(dataObj.bread_type === "rental_wanted" || dataObj.bread_type === "rental_offered") {
          thumbnailSrc = "https://atstake.s3.amazonaws.com/7eb32c83c1859675ebc606442e604543b240954fe8ffae95fe9ac7469b7902a5.jpg";
        } else {
          thumbnailSrc = "https://atstake.s3.amazonaws.com/35fa7a0d7b90ebd138cfbd49f299521046086330bcd693885e7fb2f0eff44d65.jpg";
        }
      }
      
      let postingLink = "";
      if (showLoad) {
        //No link
      }
      else {
        postingLink = "/posting?postingid=" + dataObj.postingid;
      }

      let titlePrefix = "";
      if (dataObj.bread_type === "item_wanted" || dataObj.bread_type === "service_wanted" || dataObj.bread_type === "rental_wanted") {
        titlePrefix = "Wanted: ";
      }

      //let timePublished = dataObj.published_unix ? dateUnixToShortString(dataObj.published_unix) : "";

      let priceUSD = getPriceMessageForToken(dataObj.price_amount, dataObj.price_type);
      if (priceUSD.length > 1) {
        priceUSD = priceUSD.substring(1); // get rid of the ~
      }

      cellElemList.push(
        <td key={"cell_" + i} className={dataObj.lifecycle_status === "expired" ? "postingCardExpiredCell" : "postingCardCell"}>
          <Link noTarget={true} className="" rel="noopener noreferrer" href={postingLink}>
            <div className="postingCardImgCnt"><img className={"postingCardImg" + (grayScale ? " grayPosting" : "")} src={thumbnailSrc} alt="Posting" title="Posting" /></div>
            <div className="postingText">
              <div className=""><span className="postingTitle">{titlePrefix + dataObj.title}</span></div>
              <div className="moveTop5"><span className="postingSubtitle">{priceUSD}</span></div>
              <div className="moveTop5"><span className="postingSubtitle">{dataObj.username}</span>{/*timePublished !== "" && <span className="postingSubtitle">&nbsp;&bull;&nbsp;{timePublished}</span>*/}</div>
            </div>
          </Link>
        </td>
      );
    }

    if (cellElemList.length > 0) {
      let i = 0;
      while (cellElemList.length < numColumnsWithGaps) {
        if (cellElemList.length > 0) {
          cellElemList.push(
            <td key={"gapcell_end_" + i} className="postingGapCell"></td>
          );  
        }

        cellElemList.push(
          <td key={"emptycell_end_" + i} className="postingCardEmptyCell"></td>
        );

        i++;
      }
    }

    if (cellElemList.length === numColumnsWithGaps && rowElemList.length < maxRowsWithGaps) {
      if (rowElemList.length > 0) {
        rowElemList.push(
          <tr key={"gaprow_last"} className="categoryGapRow">
            <td colSpan={numColumnsWithGaps} className="postingGapCell"></td>
          </tr>
        );
      }

      rowElemList.push(
        <tr key={"row_last"} className="categoryCardRow">
          {cellElemList}
        </tr>
      );

      cellElemList = [];
    }

    if (rowElemList.length === 0) {
      return <NoResultsCard key="noresults" />;
    }

    return (
        <table className="categoryCardCnt">
          <tbody>
            {rowElemList}
          </tbody>
        </table>
    );
  }
}
  
export default PostingList;