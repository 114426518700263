import update from 'immutability-helper';

const emptyState = {
  postingList: [],
  paginationKey: "",
  postingsLoaded: false,
  updatesLoaded: false,
  needUserDataRefresh: false
}

const PostingsPageReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="" } = action.payload;

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "postings") {
        return {
          ...emptyState
        };
      }

      return state;
    }
    case "POSTINGSPAGE_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "POSTINGSPAGE_LIST": {
      let {
        postings=[],
        pagination_key=""
      } = action.payload;

      let postingList = JSON.parse(JSON.stringify(postings));
      let paginationKey = JSON.parse(JSON.stringify(pagination_key));

      return {...state, postingList, paginationKey, postingsLoaded: true, updatesLoaded: true};
    }
    case "POSTINGSPAGE_MORE": {
      let {
        postings=[],
        pagination_key=""
      } = action.payload;

      let postingList = update(
        state.postingList || [], 
        {$push: postings}
      );
      let paginationKey = JSON.parse(JSON.stringify(pagination_key));
      
      return {...state, postingList, paginationKey, postingsLoaded: true, updatesLoaded: true};
    }
    case "CLOSE_OVERLAY" : {
      let data = action.payload;
      // Refresh linked accounts when overlay closes only if there was an update
      if (data && data.profileDataUpdate) {
        return {...state, needUserDataRefresh: true };
      }
      return state;
    }
    case "PROFILE_DATA" : {
      return {...state, needUserDataRefresh: false };
    }
    default: {
      return state
    }
  }
}

export default PostingsPageReducer