import { getFaucetData, getFaucetCoins } from "./ActionUtils";
import store from '../store';



export function refreshFaucetData(user) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getFaucetData(auth.uuid, auth.username).then((response) => {
      if (response.status === "success") {
        dispatch({
          type: 'FAUCET_DATA',
          payload: {
            //status: response.status,
            faucet_address: response.faucet_address, 
            faucet_last_used: response.faucet_last_used,
            faucet_next_allowed_use: response.faucet_next_allowed_use
          }
        });
      }
    });
  }
}

export function requestFaucetCoins(address) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getFaucetCoins(auth.uuid, auth.username, address).then((response) => {
      if (response.status === "success") {
        dispatch({
          type: 'FAUCET_REQUEST',
          payload: {
            status: response.status,
            transaction_hash: response.transaction_hash, 
            faucet_next_allowed_use: response.faucet_next_allowed_use
          }
        });
      }
    });
  }
}

export function deactivateFaucetButton() {
  return function(dispatch) {
    dispatch({
      type: 'FAUCET_BUTTON_DEACTIVATION',
      payload: {}
    });
  }
}