import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogViewAttestation.css';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonSelect from './ButtonSelect';
import ButtonAction from './ButtonAction';
import { titleByType, getTextWidth } from "./ActionUtils";
import { DEFAULT_BUTTON_WIDTH, BUTTON_TOGGLE_EXTRA_PIXELS, BUTTON_TOGGLE_FONT } from './Constants.js';
import { changeInputValue, saveAttestationConfig } from './DialogViewAttestationActions';
import { cleanPhoneNumber } from './DialogAddAttestationActions';
import moment from 'moment-timezone';
import checkImg from '../check.png'; 
import { tallOverlay } from './OverlayActions';
import Link from './Link';

const EVERYONE_LABEL = "Everyone";
const PEOPLE_WITH_AGREEMENTS_LABEL = "People I have contracts with";
const DISABLED_LABEL = "Disabled";
const DELETE_LABEL = "Delete";

class DialogViewAttestation extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.changeInputValue = this.changeInputValue.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.save = this.save.bind(this);
    this.privacyNonce = 0;
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }

    let everyoneWidth = Math.ceil(getTextWidth(EVERYONE_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let peopleWithAgreementsWidth = Math.ceil(getTextWidth(PEOPLE_WITH_AGREEMENTS_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let disabledWidth = Math.ceil(getTextWidth(DISABLED_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
    let deleteWidth = Math.ceil(getTextWidth(DELETE_LABEL, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;

    this.props.dispatch(changeInputValue("maxVisibilityButtonWidth", Math.max(everyoneWidth, peopleWithAgreementsWidth, disabledWidth, deleteWidth)));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height || this.props.dialogViewAttestation.showVerifiedMessage !== prevProps.dialogViewAttestation.showVerifiedMessage) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  showMessage(event) {
    let showVerifiedMessage = this.props.dialogViewAttestation.showVerifiedMessage;
    this.props.dispatch(changeInputValue("showVerifiedMessage", !showVerifiedMessage));
  }

  save(event) {
    let type = this.props.dialogViewAttestation.type;
    let value = this.props.dialogViewAttestation.value;
    let privacy = this.props.dialogViewAttestation.privacy;
    let defaultAddress = "";
    if (type === "phone") {
      value = cleanPhoneNumber(value);
    }
    if (type === "ethereum" && this.props.dialogViewAttestation.defaultEthereum === "yes") {
      defaultAddress = value;
    }
    else if (type === "ethereum" && this.props.dialogViewAttestation.defaultEthereum === "no") {
      let ethereumAddressList = this.props.dialogViewAttestation.ethereumAddressList;
      if (ethereumAddressList && ethereumAddressList.length > 0) {
        let ethereumAddressObj = null;

        // First keep existing default if possible
        for (let i = 0; i < ethereumAddressList.length; i++) {
          ethereumAddressObj = ethereumAddressList[i];
          if (ethereumAddressObj.isDefault && value !== ethereumAddressObj.address) {
            defaultAddress = ethereumAddressObj.address;
          }
        }

        // Then choose default top-down
        if (defaultAddress === "") {
          for (let i = 0; i < ethereumAddressList.length; i++) {
            ethereumAddressObj = ethereumAddressList[i];
            if (value !== ethereumAddressObj.address) {
              defaultAddress = ethereumAddressObj.address;
              break;
            }
          }
        }
      }
    }

    this.props.dispatch(saveAttestationConfig(type, value, privacy, defaultAddress));
  }

  render() {
    let type = this.props.dialogViewAttestation.type;
    let value = this.props.dialogViewAttestation.value;

    let allowEdit = this.props.dialogViewAttestation.allowEdit;
    let updateMsg = this.props.dialogViewAttestation.updateMsg;
    let isDeleted = false;
    if (updateMsg === "deleted") {
      isDeleted = true;
    }

    let isPending = false;
    if (updateMsg === "pending") {
      isPending = true;
    }

    let displayValue = value;
    let verified = this.props.dialogViewAttestation.verified;
    let user1 = this.props.dialogViewAttestation.user1;
    let showVerifiedMessage = this.props.dialogViewAttestation.showVerifiedMessage;
    
    let title = titleByType(type);
    let verifyDate = "";
    let verifiedBy = "";
    let proofElem = "";
    let verifiedMessagePreamble = <span>Message:</span>;
    let verifiedMessage = "";
    let verifiedSignature = "";
    let isTypeEthereum = false;
    if (verified && verified.timestamp_unix) {
      verifyDate = moment.tz(verified.timestamp_unix, "X", 'America/Los_Angeles').format('MMM D, YYYY');
      
      verifiedBy = "by a post";
      if (type === "ethereum") {
        isTypeEthereum = true;
        verifiedBy = "by a signed message";
      }
      else if (type === "email") {
        verifiedBy = "by an email";
      }
      else if (type === "phone") {
        verifiedBy = "by a text message";
      }
      else if (type === "twitter") {
        verifiedBy = "by a tweet";
      }
      else if (type === "atstake") {
        verifiedBy = "during registration";
      }

      if (verified.hasOwnProperty("proof_url") && verified.proof_url !== "") {
        proofElem = <span> Verified {verifiedBy} on {verifyDate}.<Link target="_blank" rel="noopener noreferrer" className="bluelink" href={verified.proof_url}> (proof)</Link></span>;
      } else if (verified.hasOwnProperty("url") && verified.url !== "") {
        proofElem = <span> Verified {verifiedBy} on {verifyDate}.<Link target="_blank" rel="noopener noreferrer" className="bluelink" href={verified.url}> (proof)</Link></span>;
      }
      else if (verified.hasOwnProperty("message") && verified.message !== "") {
        verifiedMessage = verified.message;
        if(type === "email" || type === "phone"){
          verifiedMessagePreamble = <span>We sent { type === "email" ? "an email" : "a text message" } containing a secret code on {verifyDate} to {displayValue}. This 
            secret code was then revealed to us by <span style={{fontWeight:"bold"}}>{user1}</span>.</span>
        }

        if (type === "ethereum" && verified.hasOwnProperty("signature") && verified.signature !== "") {
          verifiedSignature = <div style={{fontSize:"12px", wordBreak: "break-word"}} className="moveTop20">{verified.signature}</div>;
        }

        proofElem = <span>Verified {verifiedBy} on {verifyDate}.<span className="bluelink" onClick={this.showMessage}> (details)</span></span>;
      }
      else if (type === "atstake") {
        proofElem = <span>Verified {verifiedBy} on {verifyDate}.</span>;
      }
    }

    let allowMakeDefault = allowEdit && this.props.dialogViewAttestation.privacy !== "disabled";
    let allowButtons = allowEdit && !isDeleted && !isPending;

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title={title} />
          <div>
            <div>{titleByType(type)} <span title={value}>{displayValue}</span> is linked to Atstake user <span style={{fontWeight:"bold"}}>{user1}</span>.</div>

            {isTypeEthereum && 
            <div className="moveTop20">
              Default address for new agreements
              <div>
                {this.props.dialogViewAttestation.defaultEthereum === "yes" ? (
                  <div className="yourDefaultBadge"><img className="selectedUserImg" src={checkImg} title="Check" alt="Check" /> Default</div>
                ) : (
                  <ButtonSelect disabled={!allowMakeDefault} className="moveTop5 moveRight20" text={allowMakeDefault ? "Make default" : "Not default"} minWidth={DEFAULT_BUTTON_WIDTH} name="defaultEthereum" value="yes" selected={this.props.dialogViewAttestation.defaultEthereum} onChange={allowMakeDefault ? this.changeInputValue : null}/>
                )}
              </div>
            </div>
            }

            { allowEdit && 
            <div className="moveTop20">
              Choose who can see this account
              <div>
                <ButtonSelect disabled={!allowButtons} className="moveTop5 moveRight20" text={EVERYONE_LABEL} minWidth={this.props.dialogViewAttestation.maxVisibilityButtonWidth} name="privacy" value="public" selected={this.props.dialogViewAttestation.privacy} onChange={allowButtons ? this.changeInputValue : null}/>
                <ButtonSelect disabled={!allowButtons} className="moveTop5 moveRight20" text={PEOPLE_WITH_AGREEMENTS_LABEL} minWidth={this.props.dialogViewAttestation.maxVisibilityButtonWidth} name="privacy" value="private" selected={this.props.dialogViewAttestation.privacy} onChange={allowButtons ? this.changeInputValue : null}/>
                {isTypeEthereum && this.props.dialogViewAttestation.defaultEthereum !== "yes" && <ButtonSelect disabled={!allowButtons} className="moveTop5" text={DISABLED_LABEL} minWidth={this.props.dialogViewAttestation.maxVisibilityButtonWidth} name="privacy" value="disabled" selected={this.props.dialogViewAttestation.privacy} onChange={allowButtons ? this.changeInputValue : null}/>}
                {!isTypeEthereum && <span className="deleteBtn"><ButtonSelect disabled={!allowEdit} className="moveTop5" text={DELETE_LABEL} minWidth={this.props.dialogViewAttestation.maxVisibilityButtonWidth} name="privacy" value="delete" selected={this.props.dialogViewAttestation.privacy} onChange={allowButtons ? this.changeInputValue : null}/></span>}
              </div>
            </div>
            }

            {allowEdit && <div>
              <div className="moveTop20">
                <ButtonAction disabled={isDeleted || isPending} name="add" className="" text="Save" minWidth={DEFAULT_BUTTON_WIDTH} onClick={(isDeleted || isPending) ? null : this.save}/>
              </div>

              <div style={{height:"20px"}}>
                {updateMsg === "success" && <div className="moveTop5" style={{fontSize:"12px", color:"var(--green-color)"}}>Update submitted</div>}
                {updateMsg === "error" && <div className="moveTop5" style={{fontSize:"12px", color:"var(--red-color)"}}>Error submitting update</div>}
                {updateMsg === "deleted" && <div className="moveTop5" style={{fontSize:"12px", color:"var(--green-color)"}}>Linked identifier deleted</div>}
              </div>
            </div>}

            {!isDeleted && <div className="moveTop20">
              <div>{proofElem}</div>
              <div className={showVerifiedMessage ? "moveTop20" : "hide"}><div className="verifiedMsg">{verifiedMessagePreamble} <div className={type === "email" || type === "phone" ? "hide" : "moveTop20"}>{verifiedMessage}</div></div></div>
              <div className={showVerifiedMessage && verifiedSignature !== "" ? "moveTop20" : "hide"}><div className="verifiedMsg">Signature: {verifiedSignature}</div></div>
            </div>}
          </div>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogViewAttestation: state.dialogViewAttestation
  }
}

const DialogViewAttestationContainer = connect(mapStateToProps, null)(DialogViewAttestation);
export default DialogViewAttestationContainer;