import update from 'immutability-helper';
import { getTestModeFromStore, getArbitratorAutosuggestObj, getAutoSuggestObjectFromUsername } from "./ActionUtils";
import { shared } from '../Shared';

function getPostingArbitrationDefaults(breadType, depositInput) {
  let type = "contract";
  if (breadType === "item_offered" && (depositInput === "" || depositInput === "0" || parseFloat(depositInput) === 0.0)) {
    type = "payment";
  }
  else if (breadType === "item_wanted" && (depositInput === "" || depositInput === "0" || parseFloat(depositInput) === 0.0)) {
    type = "request";
  }

  let arbitrationSettings = shared.defaultArbitrationSettings(type);
  let daysToRespondInput = arbitrationSettings.arbitration_response_days;
  let requestArbitrationDays = arbitrationSettings.arbitration_request_days;
  let searchArbitratorAutosuggest = getArbitratorAutosuggestObj();
  let defaultArbitratorSettings = getTestModeFromStore() ? shared.defaultArbitratorSettingsTest() : shared.defaultArbitratorSettingsProd();
  let arbitrationFeeInput = defaultArbitratorSettings.fee_value;
  let arbitrationFeeType = defaultArbitratorSettings.fee_type;
  let autoResolveDays = arbitrationSettings.auto_resolve_days;
  let defaultResolution = arbitrationSettings.default_resolution;
  
  return {
    agreementVisibility: "discreet",
    arbitrationMode: "default",
    daysToRespondInput,
    requestArbitrationDays,
    searchArbitratorAutosuggest,
    arbitrationFeeInput,
    arbitrationFeeType,
    autoResolveDays,
    hasAutomaticOutcome: (autoResolveDays !== 0),
    defaultResolution
  };
}

const emptyState = {
  allowEdit: false,
  breadLocation: "",
  breadType: "",
  breadCategory: "",
  title: "",
  priceInput: "",
  priceType: "eth",
  depositInput: "",
  depositType: "eth",
  locationDetails: "",
  description: "",
  maxButtonWidth: 0,
  photoList: [],
  user: "",
  contractTemplate: "",
  contractCustomTerms: "",
  deadlineInput: "",
  postingid: "",
  postingLoaded: false,
  showDepositBySeller: false,
  showDetailedTerms: false,
  disableDetailedTerms: false,
  agreementVisibility: "discreet",
  arbitrationMode: "default",
  arbitrationFeeInput: "",
  arbitrationFeeType: "",
  requestArbitrationDays: "",
  daysToRespondInput: "",
  autoResolveDays: "",
  hasAutomaticOutcome: false,
  defaultResolution: "",
  searchArbitratorAutosuggest: {
    search: "all",
    input: "",
    results: [],
    selected: null,
    selectedArbitration: null,
    more: false,
    match: {},
    init: false
  },
  lifecycleStatus: "",
  expiredUnix: 0,
  updateUnix: 0,
  allUpdatePostingButtonsDisabled: false,
  showAdmin: false,
  showErrorMsg: false,
  errorMessageText: "",
  errorMessageType: ""
}

const PostingReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "newposting") {
        let breadLocation = "";
        let breadType = "";
        let breadCategory = "";
        if (query && Object.prototype.hasOwnProperty.call(query, "location")) {
          breadLocation = query.location;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "type")) {
          breadType = query.type;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "category")) {
          breadCategory = query.category;
        }

        let {
          agreementVisibility="",
          arbitrationMode="",
          daysToRespondInput="",
          requestArbitrationDays="",
          searchArbitratorAutosuggest="",
          arbitrationFeeInput="",
          arbitrationFeeType="",
          autoResolveDays="",
          hasAutomaticOutcome=false,
          defaultResolution=""
        } = getPostingArbitrationDefaults(breadType, "0");
        
        return {
          ...emptyState,
          breadLocation,
          breadType,
          breadCategory,
          contractTemplate: breadType,
          agreementVisibility,
          arbitrationMode,
          daysToRespondInput,
          requestArbitrationDays,
          autoResolveDays,
          hasAutomaticOutcome,
          defaultResolution,
          searchArbitratorAutosuggest,
          arbitrationFeeInput,
          arbitrationFeeType,
          allowEdit: true,
          postingLoaded: true
        };
      }
      else if (newSelectedPage === "posting") {
        let postingid = "";
        let show_admin = "";
        if (query && Object.prototype.hasOwnProperty.call(query, "postingid")) {
          postingid = query.postingid;
        }
        if (query && Object.prototype.hasOwnProperty.call(query, "show_admin")) {
          show_admin = query.show_admin;
        }

        return {
          ...emptyState,
          postingid,
          showAdmin: (show_admin === "1"),
          allowEdit: false
        };
      }

      return state;
    }
    case "POSTING_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;

      if (name === "hasAutomaticOutcome" && value === true) {
        return {...state, [name]: value, autoResolveDays: "30" };
      }

      if (name === "contractTemplate") {
        return {...state, [name]: value, showDetailedTerms: (value === "custom"), disableDetailedTerms: (value === "none") };
      }

      if (name === "depositInput" && state.arbitrationMode === "default") {
        let {
          daysToRespondInput="",
          requestArbitrationDays="",
          searchArbitratorAutosuggest="",
          arbitrationFeeInput="",
          arbitrationFeeType="",
          autoResolveDays="",
          hasAutomaticOutcome=false,
          defaultResolution=""
        } = getPostingArbitrationDefaults(state.breadType, value);

        return {...state, 
          [name]: value, 
          daysToRespondInput, 
          requestArbitrationDays, 
          searchArbitratorAutosuggest, 
          arbitrationFeeInput,
          arbitrationFeeType,
          autoResolveDays,
          hasAutomaticOutcome,
          defaultResolution
        };
      }

      return {...state, [name]: value };
    }
    case "POSTING_SELECT": {
      let data = action.payload;
      let { postings=[] } = data;
      let posting = postings.length > 0 ? postings[0] : {};

      let {
        postingid="",
        username="",
        bread_location="",
        bread_type="",
        bread_category="",
        title="",
        price_amount="",
        price_type="",
        deposit_amount="",
        deposit_type="",
        location_details="",
        description="",
        photo_list="[]",
        contract_template="",
        contract_details="",
        deadline="",
        arbitration_mode="",
        arbitrator="",
        arbitrator_latest_settings={},
        arbitration_fee_value="",
        arbitration_fee_type="",
        arbitration_days="",
        arbitration_request_days="",
        auto_resolve_days="",
        has_automatic_outcome="",
        default_resolution="",
        visibility="",
        lifecycle_status="",
        expired_unix=0,
        published_unix=0,
        update_unix=0
      } = posting;

      let photoList = [];
      let photoIndexList = JSON.parse(photo_list);
      for (let i = 0; i < photoIndexList.length; i++) {
        let photoIndex = photoIndexList[i];
        
        let thumbnailContent = "";
        if (posting.hasOwnProperty("thumb_" + photoIndex)) {
          thumbnailContent = "https://atstake.s3.amazonaws.com/" + posting["thumb_" + photoIndex];
        }

        let imageContent = "";
        if (posting.hasOwnProperty("photo_" + photoIndex)) {
          imageContent = "https://atstake.s3.amazonaws.com/" + posting["photo_" + photoIndex];
        }

        if (thumbnailContent !== "" && imageContent !== "") {
          photoList.push({thumbnailContent, imageContent, photoIndex});
        }
      }

      let arbitrationSettingsObj = {};
      if (contract_template !== "custom") {
        let {
          agreementVisibility="",
          arbitrationMode="",
          daysToRespondInput="",
          requestArbitrationDays="",
          searchArbitratorAutosuggest="",
          arbitrationFeeInput="",
          arbitrationFeeType="",
          autoResolveDays="",
          hasAutomaticOutcome=false,
          defaultResolution=""
        } = getPostingArbitrationDefaults(bread_type, deposit_amount);

        arbitrationSettingsObj = {
          agreementVisibility,
          arbitrationMode,
          daysToRespondInput,
          requestArbitrationDays,
          searchArbitratorAutosuggest,
          arbitrationFeeInput,
          arbitrationFeeType,
          autoResolveDays,
          hasAutomaticOutcome,
          defaultResolution
        };
      }
      else {
        let searchArbitratorAutosuggest = getAutoSuggestObjectFromUsername(arbitrator, arbitrator_latest_settings);

        arbitrationSettingsObj = {
          agreementVisibility: visibility, 
          arbitrationMode: arbitration_mode,
          daysToRespondInput: arbitration_days,
          requestArbitrationDays: arbitration_request_days,
          searchArbitratorAutosuggest,
          arbitrationFeeInput: arbitration_fee_value,
          arbitrationFeeType: arbitration_fee_type,
          autoResolveDays: auto_resolve_days,
          hasAutomaticOutcome: has_automatic_outcome === "1",
          defaultResolution: default_resolution
        };
      }

      let showDetailedTerms = (contract_template === "custom");
      let disableDetailedTerms = (contract_template === "none");

      let showDepositBySeller = false;
      if (deposit_amount !== "" && deposit_amount !== "0") {
        showDepositBySeller = true;
      }

      // Handle blocked posting use case
      if (state.showAdmin && postingid === "") {
        postingid = state.postingid;
      }

      return {
        ...state, 
        postingid: postingid, 
        user: username,
        breadLocation: bread_location,
        breadType: bread_type, 
        breadCategory: bread_category,
        title: title,
        priceInput: price_amount,
        priceType: price_type,
        depositInput: showDepositBySeller ? deposit_amount : "",
        depositType: showDepositBySeller ? deposit_type : "eth",
        locationDetails: location_details,
        description: description,
        contractTemplate: contract_template,
        contractCustomTerms: contract_details,
        showDepositBySeller,
        showDetailedTerms,
        disableDetailedTerms,
        deadlineInput: deadline,
        photoList,
        lifecycleStatus: lifecycle_status,
        expiredUnix: expired_unix,
        updateUnix: update_unix === 0 ? published_unix : update_unix,
        postingLoaded: true,
        allUpdatePostingButtonsDisabled: false,
        ...arbitrationSettingsObj
      };
    }
    case "POSTING_CHANGE_ARBITRATION_MODE" : {
      let { value } = action.payload;
      
      if (value === "default" && state.arbitrationMode !== "default") {
        let {
          daysToRespondInput="",
          requestArbitrationDays="",
          searchArbitratorAutosuggest="",
          arbitrationFeeInput="",
          arbitrationFeeType="",
          autoResolveDays="",
          hasAutomaticOutcome=false,
          defaultResolution=""
        } = getPostingArbitrationDefaults(state.breadType, state.depositInput);

        return {...state, 
          arbitrationMode: value, 
          daysToRespondInput, 
          requestArbitrationDays, 
          searchArbitratorAutosuggest, 
          arbitrationFeeInput,
          arbitrationFeeType,
          autoResolveDays,
          hasAutomaticOutcome,
          defaultResolution
        };
      }

      return {...state, arbitrationMode: value};
    }
    case "POSTING_ADD_PHOTO" : {
      let { thumbnailContent="", imageContent="" } = action.payload;

      let existingIndexMap = {};
      for (let i = 0; i < state.photoList.length; i++) {
        let entry = state.photoList[i];
        existingIndexMap[parseInt(entry.photoIndex, 10)] = true;
      }

      let photoIndex = 0;
      while (existingIndexMap.hasOwnProperty(photoIndex)) {
        photoIndex++;
      }

      photoIndex = photoIndex.toString();

      let photoList = update(
        state.photoList || [], 
        {$push: [{thumbnailContent, imageContent, photoIndex}]}
      );

      return {...state, photoList };
    }
    case "POSTING_DELETE_PHOTO" : {
      let indexToRemove = action.payload;
      let photoList = update(state.photoList || [], {$splice: [[indexToRemove, 1]]});

      return {...state, photoList };
    }
    case "POSTING_CHANGE_AUTOSUGGEST_INPUT" : {
      let { name, value, search } = action.payload;

      if (value.length > shared.MAX_IDENTIFIER_LENGTH) {
        value = value.substring(0, shared.MAX_IDENTIFIER_LENGTH);
      }

      let newSelected = state[name].selected;
      let newSelectedArbitration = state[name].selectedArbitration;
      if (state[name].input !== value) {
        newSelected = null;
        newSelectedArbitration = null;
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {input: value, selected: newSelected, selectedArbitration: newSelectedArbitration, search: search}}
      );

      return {...state, [name]: newAutosuggest, showErrorMsg: false};
    }
    case "POSTING_CHANGE_AUTOSUGGEST_RESULTS" : {
      let { name, results, more, match, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: results, more: more, match: match, init: true}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "POSTING_CLEAR_AUTOSUGGEST_RESULTS" : {
      let { name, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: [], more: false, match: {}, init: false}}
      );

      return {...state, [name]: newAutosuggest};
    }    
    case "POSTING_CHANGE_AUTOSUGGEST_SELECTION" : {
      let { name, type, value, arbitration } = action.payload;
      let newAutosuggest = {};
      let newSelectedArbitration = arbitration ? JSON.parse(JSON.stringify(arbitration)) : state[name].selectedArbitration;
      let arbitrationFeeInput = state.arbitrationFeeInput; 
      let arbitrationFeeType = state.arbitrationFeeType;
      
      if (type === null || value === null) {
        newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: [], more: false, match: {}, init: false, selected: null, selectedArbitration: null, input: ""}}
        );
      }
      else {
        if (arbitration) {
          arbitrationFeeInput = newSelectedArbitration.fee_value;
          arbitrationFeeType = newSelectedArbitration.fee_type;  
        }
        
        let newSelected = type + "#" + value.toLowerCase();
        newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: [], more: false, match: {}, init: false, selected: newSelected, selectedArbitration: newSelectedArbitration, input: value}}
        );
      }

      return {...state, [name]: newAutosuggest, arbitrationFeeInput, arbitrationFeeType};
    }
    case "POSTING_AUTOSUGGEST_FAVORITE" : {
      let { name="", contact="", favoriteSelected=false, favoritePending=false } = action.payload;            
      
      let hasUpdate = false;
      let resultList = state[name].results || [];
      for (let i = 0; i < resultList.length; i++) {
        let result = resultList[i];
        if (result.value === contact) {
          let newResultObj = JSON.parse(JSON.stringify(result));
          newResultObj.favoriteSelected = favoriteSelected;
          newResultObj.favoritePending = favoritePending;

          resultList = update(
            resultList || [], 
            {[i] : {$set: newResultObj}}
          );

          hasUpdate = true;

          break;
        }
      }

      if (hasUpdate) {
        let newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: resultList}}
        );
        
        return {...state, [name]: newAutosuggest};  
      }

      return {...state};
    }
    case "POSTING_SUBMIT_ERROR" : {
      let { errorField="", errorMsg="" } = action.payload;

      return {
        ...state, 
        showErrorMsg: true,
        errorMessageText: errorMsg,
        errorMessageType: errorField
      };
    }
    default: {
      return state
    }
  }
}

export default PostingReducer