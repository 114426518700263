import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Funding.css';
import { depositFundsInit } from './FundingActions';
import TitleCard from './TitleCard';
import GapCard from './GapCard';
import LineCard from './LineCard';
import ButtonAction from './ButtonAction';
import { openOverlay } from './OverlayActions';
import { shortenAddress, addCommasToNumber, getContentWidth } from './ActionUtils';
import { getFundingPageTokens } from './TokenUtils';

class Funding extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.selectPage = this.selectPage.bind(this);
    this.handleDeposit = this.handleDeposit.bind(this);
    this.handleWithdraw = this.handleWithdraw.bind(this);
  }
  
  componentDidMount() {
    this.props.dispatch(depositFundsInit());
  }

  handleDeposit(name, value) {
    this.props.dispatch(openOverlay("balances", {type: "deposit", token: name}));
  }

  handleWithdraw(name, value) {
    this.props.dispatch(openOverlay("balances", {type: "withdraw", token: name}));
  }

  selectPage(page) {
  }
  
  render() {

    let contentWidth = getContentWidth();
    let selectedAddress = this.props.auth.ethAddress;
    let shortSelectedAddress = selectedAddress;
    if (contentWidth < 650) {
      shortSelectedAddress = shortenAddress(selectedAddress);
    }

    let ethBalance = this.props.auth.ethBalance;
    let erc20Balances = this.props.auth.erc20Balances;
    let balanceCardElem = [];
    let fundingPageTokens = getFundingPageTokens();
    for (let i = 0; i < fundingPageTokens.length; i++) {
      let tokenSymbol = fundingPageTokens[i];
      let tokenSymbolUpper = tokenSymbol.toUpperCase();
      let balanceFixed = "";

      let decimalFactor = 1000;
      if (tokenSymbol === "eth") {
        if (ethBalance) {
          balanceFixed = Math.floor(parseFloat(ethBalance)*decimalFactor)/decimalFactor;
        }
      }
      else {
        if (tokenSymbol === "dai" || tokenSymbol === "usdc") {
          decimalFactor = 100;
        } else if (tokenSymbol === "wbtc") {
          decimalFactor = 10000;
        }
        if (erc20Balances.hasOwnProperty(tokenSymbolUpper)) {
          let tokenBalance = erc20Balances[tokenSymbolUpper].balance;
          balanceFixed = Math.floor(parseFloat(tokenBalance)*decimalFactor)/decimalFactor;
        }
      }
      let balanceWithCommas = addCommasToNumber(balanceFixed);

      balanceCardElem.push(
        <div key={"card_" + i} className="adjacentCard">
          <div>{tokenSymbolUpper}: <span style={{fontWeight:"bold"}}>{balanceWithCommas}</span></div>
          <div>
            <ButtonAction link={true} name={tokenSymbol} className="bluelink moveTop10 moveRight20" text="Deposit" onClick={this.handleDeposit}/>
            <ButtonAction link={true} name={tokenSymbol} className="bluelink moveTop10" text="Withdraw" onClick={this.handleWithdraw}/>
          </div>
        </div>
      );
    }
    balanceCardElem.push(<LineCard key="line" />);

    return (
      <div className="contentSection">
        <GapCard />
        <TitleCard title={"Balances for " + shortSelectedAddress} /> 
        {balanceCardElem}
        <GapCard />        
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    balances: state.balances
  }
}

const FundingContainer = connect(mapStateToProps, null)(Funding);
export default FundingContainer;