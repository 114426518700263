import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogContractFromPosting.css';
import { changeInputValue, createContract } from './DialogContractFromPostingActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import LoadCard from './LoadCard';
import { closeOverlay, openOverlay } from './OverlayActions'
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import { getUserLinkElement } from './ActionUtils';
import { shared } from '../Shared';

class DialogContractFromPosting extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.cancel = this.cancel.bind(this);
    this.createContract = this.createContract.bind(this);
    this.showNewContractDialog = this.showNewContractDialog.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  cancel(name, value) {
    this.props.dispatch(closeOverlay());
  }

  createContract(name, value) {
    this.props.dispatch(createContract());
  }

  showNewContractDialog(name, value) {
    let {
      user=""
    } = this.props.posting;

    let loggedin = this.props.auth.status === "loggedin";

    if (loggedin) {
      this.props.dispatch(openOverlay("new_contract", {user}));
    }
    else {
      this.props.dispatch(openOverlay("login", {}));
    }
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let {
      postingLoaded=false,
      user="",
      contractTemplate="",
      title=""
    } = this.props.posting;

    let {
      hasError=false
    } = this.props.dialogContractFromPosting;

    let userElement = getUserLinkElement(user, user);
    let userElementUC = getUserLinkElement(user, shared.firstLetterUpperCase(user));

    return (
      <div className="contentSection">
        {postingLoaded ? (
          <div className="contentCard">
            <DialogTitle title="New contract" />
            {hasError ? (
              <div>
                <div>Unable to create a new contract due to an error. Please try again and report feedback if the issue persists.</div>
              </div>
            ) : (
              <div>
                {contractTemplate !== "none" ? <div className="">This will create a new contract with {userElement} based on the posting titled "<span style={{fontWeight:"bold"}}>{title}</span>". You'll be able to review and edit the contract details after creation.</div> : ""}
                {contractTemplate === "none" ? <div className="">{userElementUC} hasn't specified contract terms associated with this posting. If you create a new contract you'll need to fill it out from scratch.</div> : ""}
                {contractTemplate === "custom" ? <div className="moveTop20">This posting by {userElement} is using non-standard terms and conditions. Please read them carefully.</div> : ""}
                <div className="moveTop15">
                  <ButtonAction name="cancel" className="moveTop5 moveRight20" color="black" text="Cancel" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.cancel}/>
                  {contractTemplate !== "none" && <ButtonAction name="ok" className="moveTop5 moveRight20" minWidth={DEFAULT_BUTTON_WIDTH} text="Create contract" onClick={this.createContract}/>}
                  {contractTemplate === "none" && <ButtonAction name="ok" className="moveTop5 moveRight20" minWidth={DEFAULT_BUTTON_WIDTH} text={"New contract with " + user} onClick={this.showNewContractDialog}/>}
                </div>
              </div>
            )}
          </div>
        ) : (
          <LoadCard />
        )}
        
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogContractFromPosting: state.dialogContractFromPosting,
    posting: state.posting
  }
}

const DialogContractFromPostingContainer = connect(mapStateToProps, null)(DialogContractFromPosting);
export default DialogContractFromPostingContainer;