import React, { Component } from 'react'
import { connect } from 'react-redux';
import './PostingsPage.css';
import './Common.css';
import GapCard from "./GapCard";
import ProfileHeader from './ProfileHeader';
import TitleCard from "./TitleCard";
import ButtonAction from './ButtonAction';
import PostingList from './PostingList';
import { refreshPostingsList } from './PostingsPageActions';
import { refreshProfileData } from './ProfileHeaderActions';
import { getIsProfileOwnAccount } from './ActionUtils';
import { 
  DEFAULT_BUTTON_WIDTH
} from './Constants.js';
import { openOverlay } from './OverlayActions';
import LoadCard from './LoadCard';
import NotFoundUser from './NotFoundUser';
import loadImg from '../load_blue.gif';

class PostingsPage extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.openNewPostingDialog = this.openNewPostingDialog.bind(this);
    this.loadMoreResults = this.loadMoreResults.bind(this);
  }
  
  componentDidMount() {
    let {
      selectedUser=""
    } = this.props.profileHeader;

    if (selectedUser !== "") {
      this.props.dispatch(refreshPostingsList(selectedUser, ""));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      selectedUser=""
    } = this.props.profileHeader;

    let needUserDataRefresh = this.props.postingsPage.needUserDataRefresh;

    if (selectedUser !== prevProps.profileHeader.selectedUser) {
      if (selectedUser !== "") {
        this.props.dispatch(refreshPostingsList(selectedUser, ""));
      }
    }

    if (
      selectedUser !== "" &&
      (needUserDataRefresh !== prevProps.postingsPage.needUserDataRefresh) && 
      needUserDataRefresh      
    ) {
      this.props.dispatch(refreshProfileData(selectedUser));
    }
  }

  openNewPostingDialog(event) {
    this.props.dispatch(openOverlay("new_posting", {}));
  }

  loadMoreResults(name, value) {
    let {
      selectedUser=""
    } = this.props.profileHeader;

    let {
      paginationKey="",
      postingsLoaded=false,
      updatesLoaded=false
    } = this.props.postingsPage;

    if (selectedUser !== "" && paginationKey !== "" && postingsLoaded && updatesLoaded) {
      this.props.dispatch(refreshPostingsList(selectedUser, paginationKey));
    }
  }
  
  render() {
    let {
      selectedUser="",
      queryParamUser="",
      userExists=false,
      dataLoaded: headerDataLoaded = false
    } = this.props.profileHeader;

    let loggedout = this.props.auth.status === "loggedout";

    let {
      paginationKey="",
      postingsLoaded=false,
      updatesLoaded=false
    } = this.props.postingsPage;

    if ((headerDataLoaded && !userExists) || (loggedout && queryParamUser === "")) {
      return (
        <div>
            <ProfileHeader />
            <NotFoundUser selectedUser={selectedUser} />
        </div>
      );
    }
    else if (!headerDataLoaded || !postingsLoaded) {
      return (
        <div>
            <ProfileHeader />
            <GapCard />
            <LoadCard key="load" />
        </div>
      );
    }

    let ownAccount = getIsProfileOwnAccount();

    let postingList = this.props.postingsPage.postingList;
    if (!postingsLoaded) {
      postingList = [];
    }
    
    let sectionsListElem = [];  
    sectionsListElem.push(
      <div key={"postings"} className="moveTop10">
        <PostingList showLoad={!postingsLoaded} data={postingList} limitRows={false} rows={100000} />
      </div>
    );

    let moreResultsEnabled = postingsLoaded && updatesLoaded && paginationKey !== "";

    return (
      <div className="">
        <ProfileHeader />
        <GapCard />
        <div className="blankCard">
          <ButtonAction key="add_photo" color="light" className="" text={<div><div style={{fontSize:"48px"}}>+</div>New posting</div>} minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.openNewPostingDialog}/>
        </div>
        <div className="moveTop20">
          <TitleCard title={ownAccount ? "Your postings" : "Postings by " + selectedUser} /> 
        </div>
        {sectionsListElem}
        {moreResultsEnabled && <div className="moveTop40" style={{textAlign: "center"}}>
          <ButtonAction link={true} disabled={!moreResultsEnabled} name="more_results" className="" text="More results" onClick={moreResultsEnabled ? this.loadMoreResults : null}/>
        </div>}
        {!moreResultsEnabled && !updatesLoaded && <div className="moveTop40" style={{textAlign: "center"}}><img className="loadIcon" src={loadImg} alt="Loading" /></div>}
        <div className="moveTop40"></div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    postingsPage: state.postingsPage,
    profileHeader: state.profileHeader
  }
}

const PostingsPageContainer = connect(mapStateToProps, null)(PostingsPage);
export default PostingsPageContainer;