import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogReport.css';
import { changeInputValue, refreshEmailAddressList } from './DialogReportActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay } from './OverlayActions'
import InputTextarea from './InputTextarea';
import InputBasic from './InputBasic';
import { summaryFromAgreementBlockchain, apiReportIssue } from "./ActionUtils";
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import { getStatusMessageAndActions } from './AgreementMessageUtils';
import { track } from './ActionUtils';
import { openOverlay, tallOverlay } from './OverlayActions';
import { shared } from '../Shared';

class DialogReport extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleDebug = this.handleDebug.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    this.props.dispatch(refreshEmailAddressList());

    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }

  handleCancel(name, value) {
    this.props.dispatch(closeOverlay());
  }

  handleSubmit(name, value) {
    this.props.dispatch(changeInputValue("submitDisabled", true));
    this.props.dispatch(track("action", "button", JSON.stringify({type:"submit_feedback", dialog: "report"})));

    let auth = this.props.auth;

    let { agreementid="", mode="" } = this.props.agreement;
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);

    let { 
      statusActions, 
      statusDesc, 
      selectedStep
    } = getStatusMessageAndActions(mode, this.props.agreement, blockchainDataObj, null, null, this.props.dispatch);

    let { selectedPage="", fullPath="" } = this.props.menu;

    let { errorList=[] } = this.props.error;

    let reportObj = {
      authObj: this.props.auth,
      agreementDataObj: this.props.agreement,
      blockchainDataObj: blockchainDataObj,
      messageDataObj: { 
        statusActions, 
        statusDesc, 
        selectedStep,
        errorList
      },
      pathDataObj : {
        selectedPage,
        fullPath
      },
      errorObj: this.props.error
    };

    let data = JSON.stringify(reportObj);
    console.log("Submitting feedback with this data:");
    console.log(reportObj);

    let emailInput = this.props.dialogReport.issueEmail;
    let text = this.props.dialogReport.issueDetails + (emailInput !== "" ? ("\n\nemail: " + emailInput) : "");

    apiReportIssue(auth.uuid, auth.username, agreementid, data, text).then((success) => {
      if (success) {
        this.props.dispatch(changeInputValue("isSubmitted", true));
      }
      else {
        alert("Failed to submit");
      }
      this.props.dispatch(changeInputValue("submitDisabled", false));
    });
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleDebug(name, value) {
    this.props.dispatch(openOverlay("debug", {}));
  }
  
  render() {
    let { agreementid="", title="" } = this.props.agreement;
    let { selectedPage="" } = this.props.menu;

    let isSubmitted = this.props.dialogReport.isSubmitted;

    let emailAddressFieldValue = this.props.dialogReport.issueEmail;

    let submitDisabled = this.props.dialogReport.submitDisabled;

    if (isSubmitted) {
      return (
        <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
          <div className="contentCard">
            <DialogTitle title="Contact us" />
            <div className="labelInput">Feedback submission successful. If you provided an email address we’ll get back to you soon.</div>
            
            <div className="moveTop20">
              <ButtonAction name="done" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Done" onClick={this.handleCancel}/>
            </div>
          </div>
          <GapCard />
        </div>
      );
    }

    let maxContactLength = shared.MAX_IDENTIFIER_LENGTH;
    // We embed the contact info in the report text, so need to make room for it.
    let maxTextLength = shared.MAX_REPORT_TEXT_LENGTH - maxContactLength - 50; 

    if (agreementid !== "" && agreementid !== null && (selectedPage === "view" || selectedPage === "edit")) {
      return (
        <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
          <div className="contentCard">
            <DialogTitle title="Contact us" />
            <div className="labelInput">Share feedback, report an issue, or ask a question. Data on the current page <span style={{fontWeight:"bold"}}>{title}</span> will also be shared.</div>
            <div className="moveTop5"><InputTextarea maxlength={maxTextLength} placeholder="Enter feedback or question" className="textAreaInput widthReportIssue" name="issueDetails" value={this.props.dialogReport.issueDetails} onChange={this.changeInputValue} /></div>
            <div className="labelInput moveTop10">Contact info</div>
            <div className="moveTop5"><InputBasic maxlength={maxContactLength} placeholder="Enter email address or other contact info" type="text" className="fullWidthInput" name="issueEmail" value={emailAddressFieldValue} onChange={this.changeInputValue} /></div>
            
            <div className="moveTop20">
              <ButtonAction name="cancel" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Cancel" onClick={this.handleCancel}/>
              <ButtonAction name="submit" disabled={submitDisabled} minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Submit" onClick={submitDisabled ? null : this.handleSubmit}/>
              <span style={{position:"absolute", bottom:"25px", right:"30px", fontSize: "12px", color: "#eee", cursor: "pointer"}} onClick={this.handleDebug}>Debug info</span>
            </div>
          </div>
          <GapCard />
        </div>
      );
    }
    else {
      return (
        <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
          <div className="contentCard">
            <DialogTitle title="Contact us" />
            <div className="labelInput">Share feedback, report an issue, or ask a question. Data on the current page will also be shared.</div>
            <div className="moveTop5"><InputTextarea maxlength={maxTextLength} placeholder="Enter feedback or question" className="textAreaInput widthReportIssue" name="issueDetails" value={this.props.dialogReport.issueDetails} onChange={this.changeInputValue} /></div>
            <div className="labelInput moveTop10">Contact info</div>
            <div className="moveTop5"><InputBasic maxlength={maxContactLength} placeholder="Enter email address or other contact info" type="text" className="fullWidthInput" name="issueEmail" value={emailAddressFieldValue} onChange={this.changeInputValue} /></div>
            <div className="moveTop20">
              <ButtonAction name="cancel" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" color="black" text="Cancel" onClick={this.handleCancel}/>
              <ButtonAction name="submit" disabled={submitDisabled} minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="Submit" onClick={submitDisabled ? null : this.handleSubmit}/>
              <span style={{position:"absolute", bottom:"25px", right:"30px", fontSize: "12px", color: "#eee", cursor: "pointer"}} onClick={this.handleDebug}>Debug info</span>
            </div>
          </div>
          <GapCard />
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    error: state.error,
    dialogReport: state.dialogReport
  }
}

const DialogReportContainer = connect(mapStateToProps, null)(DialogReport);
export default DialogReportContainer;