import { apiRequest } from "./ActionUtils"

export function verifyWithGUID(uuid, username, guid) {
  return function(dispatch) {
    if (uuid !== "" && username !== "" && guid !== "") {
      let paramsObj = {
        uuid: uuid,
        username: username,
        type: "guid", 
        value: guid,
        privacy: "private"
      };
      apiRequest("new_attestation", paramsObj).then((response) => {
        if (
          response && 
          response.status === "success" && 
          response.hasOwnProperty("type") && 
          response.type === "email" && 
          response.hasOwnProperty("value") && 
          response.value !== ""
        ) {
          dispatch({
            type: 'VERIFY_STATUS',
            payload: {status: "success", guid: guid, email: response.value }
          });
        }
        else {
          dispatch({
            type: 'VERIFY_STATUS',
            payload: {status: "error", guid: guid, email: "" }
          });
        }
      });
    }
  }
}