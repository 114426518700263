import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogAddPhoto.css';
import { changeInputValue, saveImageValue, saveImageFromURL, addPhoto } from './DialogAddPhotoActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import InputFile from './InputFile';
import InputBasic from './InputBasic';
import { closeOverlay } from './OverlayActions'
import defaultProfileImg from '../photoicon.svg';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';

class DialogAddPhoto extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleAdd = this.handleAdd.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.showEnterImageURL = this.showEnterImageURL.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.cancelImageURL = this.cancelImageURL.bind(this);
    this.handleImageURL = this.handleImageURL.bind(this);
  }

  handleAdd(name, value) {
    let {
      thumbnailContent="",
      imageContent=""
    } = this.props.dialogAddPhoto;

    this.props.dispatch(addPhoto(thumbnailContent, imageContent));
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  showEnterImageURL(name, value) {
    this.props.dispatch(changeInputValue("showEnterURL", true));
  }

  handleFileUpload(name, value) {
    this.props.dispatch(saveImageValue(value));
  }

  cancelImageURL(name, value) {
    this.props.dispatch(changeInputValue("showEnterURL", false));
  }

  handleImageURL(name, value) {
    let { photoURL="" } = this.props.dialogAddPhoto;
    if (photoURL !== "") {
      this.props.dispatch(saveImageFromURL("https://cors-anywhere.herokuapp.com/" + photoURL));
    }
  }
  
  render() {
    let {
      walletType="",
      android=false
    } = this.props.auth;

    let disableLocalFile = false;
    let disableLocalFileMsg = "";
    if (android && walletType === "alpha") { 
      disableLocalFile = true;
      disableLocalFileMsg = "Alpha Wallet on Android does not support file uploads.";
    }
    else if (android && walletType === "coinbase") { 
      disableLocalFile = true;
      disableLocalFileMsg = "Coinbase Wallet on Android does not support file uploads.";
    }

    let {
      showEnterURL=false,
      photoFile="",
      photoURL="",
      thumbnailContent=""
    } = this.props.dialogAddPhoto;

    let photoSrc = "";
    if (thumbnailContent === "") {
      photoSrc = defaultProfileImg;
    }
    else {
      photoSrc = thumbnailContent;
    }

    if (!showEnterURL) {
      return (
        <div className="contentSection">
          <div className="contentCard">
            <DialogTitle title="Add photo" />
            <div>Choose a photo to add to your posting.</div>
            <div className="moveTop5">
              <InputFile name="photoFile" disabled={disableLocalFile} disabledMsg={disableLocalFileMsg} className="moveTop5 moveRight20" value={photoFile} minWidth={DEFAULT_BUTTON_WIDTH} onChange={this.handleFileUpload} />
              <ButtonAction name="submit" link={true} text="Link to photo" className="moveTop5" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.showEnterImageURL}/>
            </div>
            <div className="moveTop20" style={{background: "var(--content-border-color)", width: "200px", height: "150x", border: "1px solid var(--content-border-color)", textAlign: "center"}}><img style={{maxWidth: "200px", maxHeight: "150px", verticalAlign: "top"}} src={photoSrc} alt="Profile" /></div>
            <ButtonAction disabled={thumbnailContent === ""} name="add" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20" text="Add" onClick={thumbnailContent === "" ? null : this.handleAdd}/>
          </div>
          <GapCard />
        </div>
      );
    }
    else {
      return (
        <div className="contentCard">
          <DialogTitle title="Link to photo" />
          <div className="moveTop20">Enter the URL of a photo</div>
          <div className="moveTop5">
            <InputBasic placeholder="URL" className="dialogDisplaynameInput" name="photoURL" value={photoURL} onChange={this.changeInputValue} />
          </div>
          <div className="moveTop15">
            <ButtonAction name="cancel" className="moveTop5 moveRight20" color="black" text="Cancel" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.cancelImageURL}/>
            <ButtonAction name="submit" className="moveTop5 " text="Select" minWidth={DEFAULT_BUTTON_WIDTH} onClick={this.handleImageURL}/>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogAddPhoto: state.dialogAddPhoto
  }
}

const DialogAddPhotoContainer = connect(mapStateToProps, null)(DialogAddPhoto);
export default DialogAddPhotoContainer;