import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './InputAutosuggest.css';
import './Common.css';
import emailImg from '../email.png';
import twitterImg from '../twitter.png';
import facebookImg from '../facebook.png';
import phoneImg from '../phone.png';
import ethereumImg from '../ethereum.png';
import redditImg from '../reddit.png';
import atstakeImg from '../atstake.png';
import searchImg from '../search.png';
import checkImg from '../check.png';
import favoriteUnselImg from '../star_empty.svg';
import favoriteSelImg from '../star_yellow.svg';

const MAX_RESULTS_DEFAULT = 10,
      MAX_RESULTS_MORE = 50,
      ELLIPSIS_MAX_CHARACTERS = 22,
      ELLIPSIS_END_CHARACTERS = 4,
      ELLIPSIS_THRESHOLD = 24;

class InputAutosuggest extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.cleanInputValue = this.cleanInputValue.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.getLogoByType = this.getLogoByType.bind(this);
        this.getTruncateStartEnd = this.getTruncateStartEnd.bind(this);
        this.valueNoHighlight = this.valueNoHighlight.bind(this);
        this.valueWithHighlight = this.valueWithHighlight.bind(this);
        this.getArbitrationPrice = this.getArbitrationPrice.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.changeWithTimeout = this.changeWithTimeout.bind(this);
        this.handleKeyboard = this.handleKeyboard.bind(this);
        this.handleStarClick = this.handleStarClick.bind(this);
        this.isElementInViewport = this.isElementInViewport.bind(this);
        this.state = {keyboardSelect: false, keyboardItem: 0, type: null, value: null, isFocused: false};
        this.autosuggestNonce = 0;
        this.timeoutId = 0;
        this.node = null;
        this.padBottomNode = null;
        this.inputNode = null;
    }

    componentDidMount() {
      document.addEventListener('mousedown', this.handleOutsideClick, false);
      document.addEventListener("keydown", this.handleKeyboard, false);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOutsideClick, false);
      document.removeEventListener("keydown", this.handleKeyboard, false);
      clearTimeout(this.timeoutId);
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.keyboardItem !== prevState.keyboardItem) {
        let comp = this.state.keyboardItem === 0 ? this.refs.inputBox : this.refs.activeItem;
        if (comp) {
          let el = ReactDOM.findDOMNode(comp);
          if (!this.isElementInViewport(el)) {
            el.scrollIntoView({block: "end", behavior: "smooth"});
          }
        }
      }
    }
    
    handleBlur(event) {
      
      // relatedTarget is typically null with blur due to mouse event 
      if (event.relatedTarget !== null) {
        if (this.node.contains(event.relatedTarget) && this.padBottomNode !== event.relatedTarget) {
          // Do nothing
        }
        else {
          if (this.state.isFocused) {
            this.props.onClearResults(this.props.name, this.props.value.input, this.autosuggestNonce++, this.props.passArbitrationInfo);
          }
    
          this.setState({isFocused: false, keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});
        }
      }
    }

    getArbitrationPrice(settings) {
      let { fee_value="", fee_type="" } = settings || {};

      let displayPrice = "";
      if (fee_value !== "" && fee_type !== "") {
        displayPrice = "(" + fee_value.toString() + " " + fee_type.toUpperCase() + " fee)";
      }

      return displayPrice;
    }

    handleFocus() {
      this.setState({isFocused: true, keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});

      let initialized = this.props.value.init || false;
      if (!initialized) {
        this.changeWithTimeout(0, this.props.value.input, this.props.value.search, MAX_RESULTS_DEFAULT, true, this.autosuggestNonce++);
      }
    }

    handleOutsideClick(event) {
      if(this.node.contains(event.target) && this.padBottomNode !== event.target) {
        if (this.state.isFocused) {
          let initialized = this.props.value.init || false;
          if (initialized) {
            if (this.inputNode.contains(event.target) && this.state.keyboardItem === 0) {
              this.props.onClearResults(this.props.name, this.props.value.input, this.autosuggestNonce++, this.props.passArbitrationInfo);
            }
          }
          else {
            this.changeWithTimeout(300, this.props.value.input, this.props.value.search, MAX_RESULTS_DEFAULT, true, this.autosuggestNonce++);
          }  
        }

        return;
      }

      if (this.state.isFocused) {
        this.props.onClearResults(this.props.name, this.props.value.input, this.autosuggestNonce++, this.props.passArbitrationInfo);
      }
      
      this.setState({isFocused: false, keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});
    }
  
    isElementInViewport(el) {
      let rect = el.getBoundingClientRect();
  
      return (
          rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      );
    }

    handleKeyboard(event){

      if(event.keyCode === 32) {
        if (this.state.isFocused) {
          event.preventDefault();
        }
      }

      if (event.type !== "keydown") { return }
      if (!this.state.isFocused) { return }

      let initialized = this.props.value.init || false;

      if (initialized) {
        if (event.keyCode === 27 || 
          (this.state.keyboardItem === 0 && (event.keyCode === 13 || event.keyCode === 32))
        ) {
          this.props.onClearResults(this.props.name, this.props.value.input, this.autosuggestNonce++, this.props.passArbitrationInfo);
          return;
        }
      }
      else {
        if (event.keyCode === 32 || event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 37 || event.keyCode === 39) {
          this.changeWithTimeout(300, this.props.value.input, this.props.value.search, MAX_RESULTS_DEFAULT, true, this.autosuggestNonce++);
        }
      }

      if (!initialized) { return }
      
      let results = this.props.value.results || [];
      let count = results.length + 1;
      if (this.props.value.more) {
        count++;
      }

      let keyboardItem = this.state.keyboardItem;
      if(event.keyCode === 32 || event.keyCode === 13) {
        if (this.state.type !== null && this.state.value !== null) {
          this.props.onSelect(this.props.name, this.state.type, this.state.value, this.props.passArbitrationInfo ? this.state.arbitration : null);
        }
        else if (this.state.keyboardItem === results.length + 1){
          this.handleLoadMore();
        }
        this.setState({keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});
      }
      else if(event.keyCode === 38) {
        keyboardItem = (keyboardItem + count - 1) % count;
        let resultType = null;
        let resultValue = null;
        let resultArbitration = null;
        if (keyboardItem > 0 && keyboardItem <= results.length) {
          let resultEntry = results[keyboardItem - 1];
          resultType = resultEntry.type || null;
          resultValue = resultEntry.value || null;
          resultArbitration = resultEntry.arbitration_settings || null;
        }
        this.setState({keyboardSelect: true, keyboardItem: keyboardItem, type: resultType, value: resultValue, arbitration: resultArbitration});
      }
      else if(event.keyCode === 40) {
        keyboardItem = (keyboardItem + 1) % count;
        let resultType = null;
        let resultValue = null;
        let resultArbitration = null;
        if (keyboardItem > 0 && keyboardItem <= results.length) {
          let resultEntry = results[keyboardItem - 1];
          resultType = resultEntry.type || null;
          resultValue = resultEntry.value || null;
          resultArbitration = resultEntry.arbitration_settings || null;
        }
        this.setState({keyboardSelect: true, keyboardItem: keyboardItem, type: resultType, value: resultValue, arbitration: resultArbitration});
      }      
    }

    handleStarClick(event) {
      event.stopPropagation();

      let node = event.currentTarget.parentNode.parentNode;
      let type = node.getAttribute("data-type");
      let value = node.getAttribute("data-value");
      let favorite = node.getAttribute("data-favorite") === "1";
      let pending = node.getAttribute("data-pending") === "1";

      if (!pending && type === "atstake" && this.props.hasOwnProperty("onFavoriteClick")) {
        this.props.onFavoriteClick(this.props.name, value, favorite);
      }
    }

    changeWithTimeout(timeout, value, search, max, more, nonce) {
      this.setState({keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});

      if (this.props.hasOwnProperty("onChange")) {
        this.props.onChange(this.props.name, value, search);
      }

      let selected = this.props.value.selected;

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.props.onChangeAutosuggest(this.props.name, value, search, max, more, selected, nonce);
      }, timeout);
    }

    getTruncateStartEnd(value) {
      let end = 0;
      let start = 0;
      let truncate = false;
      if (value.length >= ELLIPSIS_THRESHOLD) {
        let endCharCnt = Math.min(ELLIPSIS_END_CHARACTERS, value.length);
        let startCharCnt = Math.max(Math.min(value.length, ELLIPSIS_MAX_CHARACTERS) - endCharCnt, 0);

        end = value.length - endCharCnt;
        start = startCharCnt;
        truncate = true;
      }

      return { truncate, start, end };
    }

    valueNoHighlight(value, highlight) {
      let truncStartEnd = this.getTruncateStartEnd(value);

      if (value && value.substring(0, highlight.length) === highlight) {
        if (truncStartEnd.truncate) {
          if (truncStartEnd.start <= highlight.length) {
            if (truncStartEnd.end <= highlight.length) {
              return value.substring(highlight.length);
            }
            else {
              return value.substring(truncStartEnd.end);
            }
          }
          else {
            return value.substring(highlight.length, truncStartEnd.start) + "...." + value.substring(truncStartEnd.end);
          }
        }
        
        return value.substring(highlight.length);
      }
      else {
        return value;
      }
    }

    valueWithHighlight(value, highlight) {
      let truncStartEnd = this.getTruncateStartEnd(value);

      if (value && value.substring(0, highlight.length) === highlight) {
        if (truncStartEnd.truncate) {
          if (truncStartEnd.start <= highlight.length) {
            if (truncStartEnd.end <= highlight.length) {
              return highlight.substring(0, truncStartEnd.start) + "...." + highlight.substring(truncStartEnd.end);
            }
            else {
              return highlight.substring(0, truncStartEnd.start) + "....";
            }
          }
          else {
            return highlight;
          }
        }

        return highlight;
    }
      else {
        return "";
      }
    }

    cleanInputValue(str) {
      if (str) {
        str = str.toLowerCase();
        str = str.replace(/["(),:;<>[\\\] ]/gi, "");

        return str;
      }
      return "";
    }

    handleChangeInput(event) {
      this.changeWithTimeout(300, this.cleanInputValue(event.currentTarget.value), this.props.value.search, MAX_RESULTS_DEFAULT, true, this.autosuggestNonce++);
    }
  
    handleChangeSearch(name, value) {
      this.changeWithTimeout(0, this.props.value.input, value, MAX_RESULTS_DEFAULT, true, this.autosuggestNonce++);
    }

    handleSelect(event) {
      this.setState({isFocused: false, keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});
      this.props.onSelect(this.props.name, event.currentTarget.getAttribute("data-type"), event.currentTarget.getAttribute("data-value"), this.props.passArbitrationInfo ? JSON.parse(event.currentTarget.getAttribute("data-arbitration")) : null);

      let comp = this.refs.inputBox;
      if (comp) {
        let el = ReactDOM.findDOMNode(comp);
        if (!this.isElementInViewport(el)) {
          el.scrollIntoView({block: "end", behavior: "smooth"});
        }
      }
    }

    handleClear(name, value) {
      this.setState({ keyboardSelect: false, keyboardItem: 0, type: null, value: null, arbitration: null});
      this.props.onSelect(this.props.name, null, null, null);
    }

    handleLoadMore(event) {
      this.changeWithTimeout(0, this.props.value.input, this.props.value.search, MAX_RESULTS_MORE, false, this.autosuggestNonce++);
    }

    getLogoByType(type) {

      if (type === "atstake") {
        return <img className="autosuggestImg" src={atstakeImg} title="Atstake account" alt="Atstake account" />;
      }
      else if (type === "email") {
        return <img className="autosuggestImg" src={emailImg} title="Email address" alt="Email address" />;
      }
      else if (type === "twitter") {
        return <img className="autosuggestImg" src={twitterImg} title="Twitter username" alt="Twitter username" />;
      }
      else if (type === "facebook") {
        return <img className="autosuggestImg" src={facebookImg} title="Facebook username" alt="Facebook username" />;
      }
      else if (type === "phone") {
        return <img className="autosuggestImg" src={phoneImg} title="Phone number" alt="Phone number" />;
      }
      else if (type === "ethereum") {
        return <img className="autosuggestImg" src={ethereumImg} title="Ethereum account" alt="Ethereum account" />;
      }
      else if (type === "reddit") {
        return <img className="autosuggestImg" src={redditImg} title="Reddit username" alt="Reddit username" />;
      }
      else if (type === "search") {
        return <img className="autosuggestImg" src={searchImg} title="Search all" alt="Search all" />;
      }
      else if (type === "check") {
        return <img className="autosuggestImg" src={checkImg} title="Check" alt="Check" />;
      }
    }

    render() {
      let typeList = ["atstake", "ethereum", "email", "phone", "twitter", "facebook", "reddit"];

      let disabled = this.props.disabled || false;
      let selectedResult = this.props.value.selected;
      let results = this.props.value.results || [];
      let initialized = this.props.value.init || false;
      let selectedType = null;
      let selectedValue = null;
      if (selectedResult) {
        let splitArr = selectedResult.split('#');
        if (splitArr.length > 1) {
          selectedType = splitArr[0];
          selectedValue = splitArr[1];
        }        
      }

      let entryBuffer = null;
      let autosuggestEntryDivs = [];
      for (let i = 0; i < results.length; i++) {
        let resultEntry = results[i];
        let resultType = resultEntry.type || "";
        let resultValue = resultEntry.value || "";
        let favoriteSelected = resultEntry.favoriteSelected || false;
        let favoritePending = resultEntry.favoritePending || false;
        let supportsFavorite = (resultType === "atstake") && this.props.hasOwnProperty("onFavoriteClick");
        let resultArbitrationSettings = resultEntry.arbitration_settings || null;
        let displayArbitrationPrice = "";
        if (this.props.showPrice) {
          displayArbitrationPrice = this.getArbitrationPrice(resultArbitrationSettings);
        }
        let resultTitleDiv = null;
        let resultIdentifierDivList = [];

        let highlightSelected = false;
        if (this.state.keyboardSelect === false && resultEntry.hasOwnProperty("selected") && resultEntry.selected === true) {
          highlightSelected = true;
        }

        for (let attestationid in resultEntry) {
          if (attestationid !== "type" && attestationid !== "value" && attestationid !== "arbitration_settings") {
            let splitArr = attestationid.split('#');
            let type = "";
            if (splitArr.length > 1) {
              type = splitArr[0];
            }

            if (typeList.includes(type)) {
              let resultSubEntry = resultEntry[attestationid];

              if (type === resultType) {
                resultTitleDiv = <div className="autosuggestName">{this.getLogoByType(type)} <span className="autosuggestHighlight">{this.valueWithHighlight(resultSubEntry.value, resultSubEntry.highlight)}</span>{this.valueNoHighlight(resultSubEntry.value, resultSubEntry.highlight)} <span className="autosuggestDisplayPrice">{displayArbitrationPrice}</span></div>;
              }
              else {
                resultIdentifierDivList.push(
                  <div key={attestationid} className="autosuggestIdentifier">{this.getLogoByType(type)} <span className="autosuggestHighlight">{this.valueWithHighlight(resultSubEntry.value, resultSubEntry.highlight)}</span>{this.valueNoHighlight(resultSubEntry.value, resultSubEntry.highlight)}</div>
                );
              }
            }
          }
        }

        if (resultTitleDiv !== null) {          
          let entryDivProps = {};
          let isKeyboardSelected = this.state.keyboardSelect && this.state.keyboardItem === i + 1 && this.state.type === resultType && this.state.value === resultValue;
          if (isKeyboardSelected) {
            entryDivProps.ref = "activeItem";
          }
          
          if (entryBuffer !== null) {
            autosuggestEntryDivs.push(entryBuffer);
          }
          entryBuffer = 
            <div {...entryDivProps} key={i} className={"autosuggestEntry" + (isKeyboardSelected ? " autosuggestKeyboard" : "") + (highlightSelected ? " autosuggestSelected" : "")} onClick={this.handleSelect} name={this.props.name} data-type={resultType} data-value={resultValue} data-favorite={favoriteSelected ? "1" : "0"} data-pending={favoritePending ? "1" : "0"} data-arbitration={JSON.stringify(resultArbitrationSettings)} >
              {resultTitleDiv}
              {resultIdentifierDivList}
              {supportsFavorite && <div>{favoriteSelected ? <img onClick={this.handleStarClick} className="autosuggestStar" src={favoriteSelImg} alt="Is favorite" /> : <img onClick={this.handleStarClick} className="autosuggestStar" src={favoriteUnselImg} alt="Not favorite" />}</div>}
            </div>
          ;
        }
      }
  
      let isMoreKeyboardSelected = selectedResult === null && this.state.keyboardSelect && this.state.keyboardItem === results.length + 1 && this.state.type === null && this.state.value === null;
      let moreDivProps = {};
      if (isMoreKeyboardSelected) {
        moreDivProps.ref = "activeItem";
      }

      if (selectedResult === null && this.props.value.more) {
        if (entryBuffer !== null) {
          autosuggestEntryDivs.push(entryBuffer);
        }
        entryBuffer = 
          <div {...moreDivProps} key="more_results" onClick={this.handleLoadMore} name={this.props.name} className={isMoreKeyboardSelected ? "autosuggestEntry autosuggestKeyboard" : "autosuggestEntry"}>
            <div className="autosuggestName">More results ...</div>
          </div>
        ;
      }

      if (entryBuffer !== null) {
        autosuggestEntryDivs.push(<div key="last" className="autosuggestLast">{entryBuffer}</div>);
      }

      let inputValue = this.props.value.input;
      if (selectedResult && selectedType && selectedValue) {
        inputValue = selectedValue;
      }
      
      return (
        <div ref={node => this.node = node} style={{position: "relative"}} className={(autosuggestEntryDivs.length === 0 ? "autosuggestNoResults" : "") + (disabled ? " autosuggestDisabled" : "")}>
          <div ref={inputNode => this.inputNode = inputNode}><input readOnly={disabled} ref="inputBox" onBlur={this.handleBlur} onFocus={this.handleFocus} autoComplete="new-password" placeholder="Username, email, Twitter handle, etc." type="text" className={"textInput autosuggestInput transparentBg" + (this.state.isFocused ? " autosuggestInputFocused" : "")} value={inputValue} onChange={this.handleChangeInput} /></div>
          <div className="autosuggestLeftSideExplainer"><span style={{color:"#fff", visibility:"hidden"}}>&nbsp;</span><span className="autosuggestSearchIcon">{selectedType ? this.getLogoByType(selectedType) : this.getLogoByType("search")}</span></div>
          {selectedResult !== null && <div className="autosuggestRightSideExplainer"><span className="autosuggestSearchIcon">{this.getLogoByType("check")}</span></div>}
          <div className={this.state.isFocused ? "autosuggestCnt" : "hide"}>
            <div className="autosuggestResults">
              {autosuggestEntryDivs}
              {selectedResult === null && initialized && autosuggestEntryDivs.length === 0 && (
                <div className="autosuggestEntry">
                  <div className="autosuggestName">No matches</div>
                </div>
              )}
              {initialized && <div ref={padBottomNode => this.padBottomNode = padBottomNode} onClick={this.handleOutsideClick} className="autosuggestPadBottom"></div>}
            </div>
          </div>
        </div>
      );
      
    }
  }
  
  export default InputAutosuggest;
