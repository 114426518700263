import { apiRequest, apiBatchListPostingsWithTree } from "./ActionUtils"
//import store from '../store';
import { 
  HOMEPAGE_SECTIONS
} from './Constants.js';

export function homePageSubmitEmail(email) {
  return function(dispatch) {
    apiRequest("email", {email}).then((response) => {
      let success = response.status === "success";

      if (success) {
        document.getElementById("subscribeEmail").value = "";
      }

      dispatch({
        type: 'HOMEPAGE_SUBMIT_EMAIL',
        payload: success ? "success" : "error"
      });
    });
  }
}

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'HOMEPAGE_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function openMenuList() {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_MENU_LIST'
    });
  }
}

export function closeMenuList() {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_MENU_LIST'
    });
  }
}

export function refreshHomepageSections() {
  return function(dispatch) {
    apiBatchListPostingsWithTree(HOMEPAGE_SECTIONS).then(({categories, sections}) => {
      dispatch({
        type: 'CATEGORY_TREE',
        payload: categories
      });
      dispatch({
        type: 'HOMEPAGE_SECTIONS_LIST',
        payload: sections
      });
    });
  }
}
