import { apiContractFromPosting, navNewURL } from "./ActionUtils"
import { closeOverlay } from './OverlayActions'
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGCONTRACTFROMPOSTING_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function createContract() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let posting = store.getState().posting;

    apiContractFromPosting(
      auth.uuid, 
      auth.username, 
      posting.postingid, 
      auth.ethAddress
    ).then((respObj) => {
      if (respObj.success) {
        console.log("Success creating contract");
        dispatch(navNewURL('/view?agreementid=' + respObj.agreementid));
        dispatch(closeOverlay());
      }
      else {
        console.log("Error creating contract");
        dispatch(changeInputValue("hasError", true));
      }
    });
  }
}