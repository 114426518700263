//import update from 'immutability-helper';

const emptyState = {
  dataLoaded: false,
  queryParamUser: "",
  selectedUser: "",
  selectedFilter: "",
  selectedTab: "",
  userExists: false,
  displayname: "",
  summary: "",
  favoriteSelected: false,
  favoritePending: false,
  profileFile: "",
  offset: "0",
  count: "50"
}

const ProfileHeaderReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;
      let page = pathname.replace(/^\/|\/$/g, '');

      let queryParamUser = "";
      if (query && Object.prototype.hasOwnProperty.call(query, "user")) {
        queryParamUser = query.user;
      }

      let selectedFilter = "";
      if (query && Object.prototype.hasOwnProperty.call(query, "filter")) {
        selectedFilter = query.filter;
      }

      let offset = "0";
      if (query && Object.prototype.hasOwnProperty.call(query, "offset")) {
        offset = query.offset;
      }

      let count = "50";
      if (query && Object.prototype.hasOwnProperty.call(query, "count")) {
        count = query.count;
      }

      let selectedTab = "profile";
      if (page === "contracts" || page === "userpostings" || page === "ratings") {
        selectedTab = page;
      }

      if (queryParamUser !== state.queryParamUser) {
        return {...state, ...emptyState, queryParamUser, selectedFilter, selectedTab, offset, count };
      }

      return {...state, queryParamUser, selectedFilter, selectedTab, offset, count };
    }
    case "PROFILE_SELECT_USER": {
      return {...state, selectedUser: action.payload, dataLoaded: false };
    }
    case "PROFILE_DATA": {
      let data = action.payload;
      let { 
        user_exists=false, 
        displayname="", 
        summary="", 
        profile_photo="",
        favorite=false
      } = data;
    
      return {...state, dataLoaded: true, userExists: user_exists, displayname, summary, profileFile: profile_photo, favoriteSelected: favorite, favoritePending: false };
    }
    case "PROFILE_FAVORITE" : {
      let { contact, favorite, pending } = action.payload;
      if (state.selectedUser === contact) {
        return {...state, favoriteSelected: favorite, favoritePending: pending };
      }
      else {
        return state;
      }
    }
    default: {
      return state
    }
  }
}

export default ProfileHeaderReducer