import { connect } from 'react-redux';
import React, { Component } from 'react';
import './Attributions.css';
import GapCard from "./GapCard";
import LineCard from "./LineCard";
import TitleCard from "./TitleCard";
import Link from './Link';

class Attributions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {


    return (
      <div className="contentSection">
        <GapCard />
        <TitleCard title="Attributions" /> 
        <div className="helpCard">
          <div className="">exchange by Dániel Aczél from the Noun Project</div>
          <div className="moveTop5">trophy by Hare Krishna from the Noun Project</div>
          <div className="moveTop5">lightbulb by Maxim Kulikov from the Noun Project</div>
          <div className="moveTop5">tokens by Alina Oleynik from the Noun Project</div>
          <div className="moveTop5">shield by ibrandify from the Noun Project</div>
          <div className="moveTop5">market by Andi Nur Abdillah from the Noun Project</div>
          <div className="moveTop5">digitalization by insticon from the Noun Project</div>
          <div className="moveTop5">Facebook by Abdul karim from the Noun Project</div>
          <div className="moveTop5">Download by Iconnic from the Noun Project</div>
          <div className="moveTop5">Medium by e Gandi from shareicon.net</div>
          <div className="moveTop5">email by SELicon from the Noun Project</div>
          <div className="moveTop5">Object by Adrien Coquet from the Noun Project</div>
          <div className="moveTop5">clock by James Rainsford from the Noun Project</div>
          <div className="moveTop5">Wrench by Arthur Shlain from the Noun Project</div>
          <div className="moveTop5">three dots by Icon Island from the Noun Project</div>
          <div className="moveTop5">Arrow by Alice Design from the Noun Project</div>
          <div className="moveTop5">Cart icon by www.joethegoatfarmer.com</div> 
          <div className="moveTop5">Icons made by Gregor Cresnar from www.flaticon.com</div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/icon-works" title="Icon Works">Icon Works</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/icomoon" title="Icomoon">Icomoon</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/roundicons" title="Roundicons">Roundicons</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/gregor-cresnar" title="Gregor Cresnar">Gregor Cresnar</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank" rel="noopener noreferrer">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.freepik.com/" title="Freepik">Freepik</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs">DinosoftLabs</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link> is licensed by <Link href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</Link></div>
          <div className="moveTop5">Icons made by <Link href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</Link> from <Link href="https://www.flaticon.com/"             title="Flaticon">www.flaticon.com</Link></div>
          <div className="moveTop5">Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a></div>
        </div>
        <LineCard />
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    attributions: state.attributions,
    overlay: state.overlay
  }
}

const AttributionsContainer = connect(mapStateToProps, null)(Attributions);
export default AttributionsContainer;