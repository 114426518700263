import uuidv4 from  'uuid/v4';
import { apiRequest, getCookie, setCookie, eraseCookie, isMobile, numColumns, getAddressAndNetwork, signLoginPromise, signAccessCodeLoginPromise, signRegisterPromise, apiGetTokenPrice, apiGetNotifications, navNewURL, isAndroid, isIOS } from "./ActionUtils";
import store from '../store';
import moment from 'moment-timezone';
import { 
  syncBlockchainEvidence,
  checkBlockchainSync,
  reloadBlockchainSyncList
} from './BlockchainActions';
import { 
  clearLockOnAgreement,
  saveHashAgreement,
  finalizeAgreement,
  updateAgreementTransaction,
  refreshTransactionsFromDB,
  checkForReplacementDeployTransaction
} from "./AgreementActions";
import { shared } from '../Shared';
import { track, apiGetTokenBalance, getRawEthereumProvider } from './ActionUtils';
import { openOverlay, closeOverlay } from './OverlayActions';
import { 
  EXPERIMENT_COUNT
} from './Constants.js';

export function init() {
  return function(dispatch) {
    let cp = getRawEthereumProvider();
    if (cp !== null) {
      let walletType = "";
      if (!!cp.isToshi) {
        walletType = "coinbase";
      }
      else if (!!cp.isMetaMask) {
        walletType = "metamask";
      }
      else if (!!cp.isTrust) {
        walletType = "trust";
      }
      else if (!!cp.isGoWallet) {
        walletType = "go";
      }
      else if (!!cp.isAlphaWallet) {
        walletType = "alpha";
      }
      else if (!!cp.isStatus) {
        walletType = "status";
      }
      else if (typeof window.__CIPHER__ !== 'undefined') {
        walletType = "cipher";
      }

      dispatch({
        type: 'AUTH_WALLET_TYPE',
        payload: walletType
      });
    }

    dispatch(initAuth());

    if (window.ethereum) {
      //window.ethereum.enable().then((response) => {
      //}).catch((error) => {
      //});
      window.ethereum.autoRefreshOnNetworkChange = false;
    }

    dispatch(initWeb3());
  }
}

export function getTestModeCookie() {
  let cookieVal = getCookie("testmode");
  if (cookieVal === "1") {
    return true;
  }
  return false;
}

export function hexToBinary(hex) {
  return (parseInt(hex, 16).toString(2)).padStart(8, '0');
}

export function getExperimentStatus(experimentCount) {
  let experimentGUID = getCookie("expguid");
  if (experimentGUID === "") {
    experimentGUID = uuidv4().toLowerCase();
    experimentGUID = experimentGUID.replace(/-/g, "");
    setCookie("expguid", experimentGUID);
  }

  let expStatusList = [];
  for (let i = 0; i < experimentCount; i++) {
    let offset = i;
    let byteOffset = Math.floor(offset / 8);
    let bitOffset = offset % 8;
    let byteString = experimentGUID.substring(byteOffset * 2, (byteOffset * 2) + 2);
    let bitString = hexToBinary(byteString);
    let bitValue = parseInt(bitString.substring(bitOffset, bitOffset + 1), 10) === 1;
    expStatusList.push(bitValue);
  }
  
  return expStatusList;
}

export function inExperiment(experimentId) {
  let auth = store.getState().auth;

  if (Array.isArray(experimentId)) {
    let boolStatus = false;
    for (let i = 0; i < experimentId.length; i++) {
      let expObj = experimentId[i];
      let tempStatus = true;
      for (let key in expObj) {
        if (Object.prototype.hasOwnProperty.call(expObj, key)) {
          let value = key < auth.expStatus.length ? auth.expStatus[key] : false;
          tempStatus = tempStatus && (value === expObj[key]);
        }
      }

      boolStatus = boolStatus || tempStatus;
    }

    return boolStatus;
  }
  else if (experimentId < auth.expStatus.length) {
    return auth.expStatus[experimentId];
  }

  return false;
}

export function setTestModeCookie(testMode) {
  let oldTestMode = getTestModeCookie();

  let cookieVal = testMode ? "1" : "0";
  setCookie("testmode", cookieVal);

  if (oldTestMode !== testMode) {
    window.location.reload();
  }
}

export function initAuth() {
  return function(dispatch) {
    let uuid = getCookie("uuid");
    let testMode = getTestModeCookie();
    let expStatus = getExperimentStatus(EXPERIMENT_COUNT);
    if (uuid === "") {
      dispatch({
        type: 'INIT_AUTH',
        payload: {loggedin:false, username:"", uuid:"", testMode, expStatus}
      });
    }
    else {
      apiRequest("auth_uuid", {uuid}).then((response) => {
        let { loggedin=false, username="" } = response || {};
        dispatch({
          type: 'INIT_AUTH',
          payload: {loggedin, username, uuid, testMode, expStatus}
        });
      }).catch((err) => {
        console.log("Error logging in");
      });
    }
  }
}

export function updateScreenDimensions(width, height) {
  return function(dispatch) {

    let auth = store.getState().auth;
    let loggedin =  auth.status === "loggedin";
    let mobile = isMobile(navigator.userAgent, width);
    let columns = numColumns(navigator.userAgent, width, loggedin);
    let android = isAndroid(navigator.userAgent);
    let ios = isIOS(navigator.userAgent);
    dispatch({
      type: 'UPDATE_SCREEN_DIMENSIONS',
      payload: { width, height, mobile, columns, android, ios }
    });
  }
}

export function setFooterHeight(height) {
  return function(dispatch) {
    let oldHeight = store.getState().auth.footerHeight;

    if (oldHeight !== height) {
      dispatch({
        type: 'FOOTER_HEIGHT',
        payload: height
      });
    }
  }
}

export function doesUsernameExist(username) {
  return function(dispatch) {
    if (username !== "") {
      apiRequest("check_username", {username:username}).then((response) => {
        let { hasUsername=false } = response || {};
        dispatch({
          type: 'USERNAME_EXISTS',
          payload: {username, exists:hasUsername}
        });
      }).catch((err) => {
      });
    }
  }
}

export function logInWithAccessCode(username, access_code, address) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let initialStatus = auth.status;
    let nonce = uuidv4().toLowerCase();
    nonce = nonce.replace(/-/g, "");
    nonce = nonce.substring(0, 15);
    signAccessCodeLoginPromise(username, access_code, nonce).then((signature) => {
      let params = {username, access_code, address, signature, nonce};
      
      apiRequest("access_code_login", params).then((response) => {
        let { loggedin=false, uuid="" } = response || {};
        if (loggedin === true && uuid !== "") {
          setCookie("uuid", uuid);
          dispatch(initAuth());
          dispatch(closeOverlay());
          checkAddress(dispatch, address);

          let menu = store.getState().menu;
          if (menu.selectedPage === "home") {
            dispatch(navNewURL('/'));
          }
          if (initialStatus === "loggedin") {
            window.location.reload();
          }

          window.scrollTo(0, 0);
        }
      })
    }).catch((err) => {
      console.log("Error");
    });
  }
}

export function logInWithSignature(address) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let initialStatus = auth.status;
    let nonce = uuidv4().toLowerCase();
    nonce = nonce.replace(/-/g, "");
    nonce = nonce.substring(0, 15);
    signLoginPromise(nonce).then((signature) => {
      let params = {address:address, signature:signature, nonce:nonce};
      
      apiRequest("login", params).then((response) => {

        dispatch({
          type: 'LOG_ERROR',
          payload: {info:"logInWithSignature", data:JSON.stringify(response)}
        });

        let { loggedin=false, uuid="" } = response || {};
        if (loggedin === true && uuid !== "") {

          dispatch({
            type: 'LOG_ERROR',
            payload: {info:"uuid", data:JSON.stringify(uuid)}
          });

          setCookie("uuid", uuid);
          dispatch(initAuth());
          dispatch(closeOverlay());
          checkAddress(dispatch, address);

          let menu = store.getState().menu;
          if (menu.selectedPage === "home") {
            dispatch(navNewURL('/'));
          }
          if (initialStatus === "loggedin") {
            window.location.reload();
          }

          window.scrollTo(0, 0);
        }
      })
    }).catch((err) => {
      console.log("Error");
    });
  }
}

export function registerWithSignature(address, username) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let initialStatus = auth.status;
    signRegisterPromise(username).then((signature) => {
      let params = {address:address, signature:signature, username:username};
      
      apiRequest("register", params).then((response) => {
        let { loggedin=false, uuid="" } = response || {};
        if (loggedin === true && uuid !== "") {
          setCookie("uuid", uuid);
          dispatch(initAuth());
          checkAddress(dispatch, address);

          if (initialStatus === "loggedin") {
            window.location.reload();
            dispatch(closeOverlay());
          }
          else {
            dispatch({
              type: 'LOGIN_ASK_FOR_EMAIL',
              payload: {}
            });
          }

          window.scrollTo(0, 0);
        }
        else {
          dispatch({
            type: 'SIGNUP_FAILURE',
            payload: response
          });
        }
      });
    }).catch((err) => {
      dispatch({
        type: 'SIGNUP_FAILURE',
        payload: {status: "error", errorMsg: "Signature failed"}
      });
    });
  }
}

export function logOut() {
  return function(dispatch) {
    eraseCookie("uuid");
    dispatch(initAuth());
    dispatch(closeOverlay());
    window.location.assign("/");
  }
}

export function initWeb3() {
  return function(dispatch) {
    window.forceTokenSync = true;

    window.setTimeout(() => {
      checkWeb3(dispatch);
    }, 100);

    window.setTimeout(() => {
      checkWeb3(dispatch);
    }, 250);

    window.setTimeout(() => {
      checkWeb3(dispatch);
    }, 500);

    window.setTimeout(() => {
      checkWeb3(dispatch);
    }, 2500);
  
    window.setInterval(() => {
      checkWeb3(dispatch);
    }, 1000);
  }
}

export function checkAddress(dispatch, address) {
  let uuid = getCookie("uuid");
  apiRequest("check_address", {address,uuid}).then((response) => {
    let { status="error", hasAccount=false, hasUsernameMatch=false, isDisabled=false } = response || {};
    dispatch({
      type: 'HAS_CONNECTION',
      payload: {hasConnection: (status === "success")}
    });
    let testMode = getTestModeCookie();
    dispatch({
      type: 'HAS_ACCOUNT',
      payload: {hasAccount, hasUsernameMatch, isDisabled, testMode}
    });

    if (hasAccount && !hasUsernameMatch && !isDisabled) {
      let auth = store.getState().auth;
      if (auth.status === "loggedin") {
        dispatch(openOverlay("login", {}));
      }
    }

  }).catch((err) => {
    console.log("Error checking address");
    dispatch({
      type: 'HAS_CONNECTION',
      payload: {hasConnection: false}
    });
    let testMode = getTestModeCookie();
    dispatch({
      type: 'HAS_ACCOUNT',
      payload: {hasAccount: false, hasUsernameMatch: false, isDisabled: false, testMode}
    });
  });
}

export function checkWeb3(dispatch) {
  getAddressAndNetwork().then((response) => {
    let { address="", network="", update=false } = response;
    if (update) {
      if (
        window.selectedAddressVal !== address || 
        window.selectedNetworkVersionVal !== network
      ) {
        if (address !== "" && network !== "") {
          let isNewAddress = window.selectedAddressVal !== address || window.selectedNetworkVersionVal !== network;

          window.selectedAddressVal = address;
          window.selectedNetworkVersionVal = network;

          let networkName = shared.getNetworkNameLongFromId(network);

          if (network === "1") {
            setTestModeCookie(false);
          }
          else if (network === "4") {
            setTestModeCookie(true);
          }

          dispatch({
            type: 'ETH_ADDRESS',
            payload: { address, network, networkName }
          });

          if (isNewAddress) {
            if (address) {
              checkAddress(dispatch, address);
            }
            else {
              let testMode = getTestModeCookie();
              dispatch({
                type: 'HAS_ACCOUNT',
                payload: {hasAccount: false, hasUsernameMatch: false, isDisabled: false, testMode}
              });
            }
          }
        }
        else {
          window.selectedAddressVal = "";
          window.selectedNetworkVersionVal = "";

          dispatch({
            type: 'DISCONNECT_ETH_ADDRESS'
          });
          let testMode = getTestModeCookie();
          dispatch({
            type: 'HAS_ACCOUNT',
            payload: {hasAccount: false, hasUsernameMatch: false, isDisabled: false, testMode}
          });
        }
      }
    }

    let auth = store.getState().auth;
    let menu = store.getState().menu;
    let { lastNavUnix=0 } = menu;
    let nowUnix = moment.tz('America/Los_Angeles').unix();
    let syncRate = "normal";
    if (nowUnix > (lastNavUnix + 48*3600)) {
      syncRate = "stop";
    }
    else if (nowUnix > (lastNavUnix + 6*3600)) {
      syncRate = "extra_slow";
    }
    else if (nowUnix > (lastNavUnix + 600)) {
      syncRate = "slow";
    }

    if (auth && auth.status !== "pending") {
      if (typeof window.checkERC20BalanceCnt === "undefined") { 
        window.checkERC20BalanceCnt = 0;
      }
      else {
        window.checkERC20BalanceCnt = (window.checkERC20BalanceCnt + 1) % 9000;
      }
      
      //Only check balances every 60 seconds
      if (
        (syncRate === "normal" && window.checkERC20BalanceCnt % 60 === 0) || 
        (syncRate === "slow" && window.checkERC20BalanceCnt % 900 === 0) || 
        (syncRate === "extra_slow" && window.checkERC20BalanceCnt % 9000 === 0) || 
        window.forceTokenSync
      ) {

        if (auth.status === "loggedin") {
          if (address !== "") {
            dispatch(checkTokenBalances(address));
          }
  
          dispatch(checkNotifications());  
        }
      }

      if ((window.checkERC20BalanceCnt % 15) === 0) {
        if (address !== "" && auth.status === "loggedin") {
          dispatch(checkDeployTransactionForUpdates());
          dispatch(checkStalledAgreementLoad());
          dispatch(checkBlockchainSync());
        }

        if (!auth.hasTokenPriceData) {
          dispatch(checkTokenPriceData());
        }
      }
    }
  });
}

export function checkTokenBalances(ethAddress) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let { selectedPage="" } = store.getState().menu;

    let uuid = auth.uuid;
    let username = auth.username;
    let networkName = shared.getNetworkName(auth.testMode);  
    
    let only_menu = "1";
    if (selectedPage === "balances" || window.forceAllTokens) {
      only_menu = "0";
    }
    
    apiGetTokenBalance(uuid, username, ethAddress, networkName, only_menu).then((balanceObj) => {
      if (balanceObj) {  
        let { resultList=[] } = balanceObj;          
        for (let i = 0; i < resultList.length; i++) {
          let balanceEntry = resultList[i];
          let { token_symbol="", balance_raw="0", balance_display="0.0", token_address="", decimals="", allowance_data={} } = balanceEntry;

          if (token_symbol !== "" && token_address !== "") {
            dispatch({
              type: 'ERC20_TOKEN_BALANCE',
              payload: {symbol: token_symbol.toUpperCase(), address: token_address, balance: balance_display, decimals: decimals, balanceStr: balance_raw, allowanceObj: allowance_data}
            });  
          }
        }
        
      }
    });

    window.forceTokenSync = false;
    window.forceAllTokens = false;
  }
}

export function checkNotifications() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let uuid = auth.uuid;
    let username = auth.username;
    apiGetNotifications(uuid, username).then((respObj) => {
      dispatch({
        type: 'NOTIFICATIONS',
        payload: respObj
      });
    });
  }
}

export function handleDeployTransactionReceipt(agreementid, contractType, responseObj) {
  return function(dispatch) {
    console.log("handleDeployTransactionReceipt");
    console.log(responseObj);

    if (responseObj !== null) {
      let { transactionHash="", blockchainId="", hashOfAgreement="", status=false } = responseObj;
      if (status) {
        if (hashOfAgreement !== "") {
          console.log("Agreement finalized with id " + blockchainId);
          dispatch(finalizeAgreement(hashOfAgreement, blockchainId, transactionHash, contractType));
        }
      }
      else {
        console.log("Transaction reverted!");
        dispatch(clearLockOnAgreement(agreementid, true));
      }
    }
  }
}

export function hasDeployTransactionUpdate(agreementid, contractType, updateSource, updateType, updateData) {
  return function(dispatch) {
    if (updateType === "start") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: true
      });
    }
    else if (updateType === "hash") {
      if (updateSource === "local") {
        dispatch(saveHashAgreement(updateData, contractType));
        // We could piggyback on the saveHashAgreement call, but keep things separate for simplicity for now..
        dispatch(updateAgreementTransaction(updateData, "create", "pending")); 
        dispatch({
          type: 'AGREEMENT_PENDING_FINALIZE_TRANSACTION',
          payload: {agreementid, transactionType: contractType, transactionHash: updateData, type: "deposit"}
        });
        let dialog = store.getState().overlay.dialog;
        if (dialog === "blockchain_deposit" || dialog === "blockchain_resolve" || dialog === "blockchain_arbitrate") {
          dispatch({
            type: 'CLOSE_OVERLAY'
          });  
        }
        dispatch({
          type: 'METAMASK_OPENED',
          payload: false
        });
        window.setTimeout(() => {
            dispatch(hasDeployTransactionUpdate(agreementid, contractType, updateSource, "hash_callback", updateData));
        }, 30000);
      }
      else {
        getAgreementHashFromTransactionHash(updateData).then((responseObj) => {
          dispatch(handleDeployTransactionReceipt(agreementid, contractType, responseObj));
        });
        dispatch(refreshTransactionsFromDB(agreementid));
      }
    }
    else if (updateType === "hash_callback") {
      getAgreementHashFromTransactionHash(updateData).then((responseObj) => {
        dispatch(handleDeployTransactionReceipt(agreementid, contractType, responseObj));
      });
      dispatch(checkForReplacementDeployTransaction(dispatch, agreementid));
      dispatch(refreshTransactionsFromDB(agreementid));
    }
    else if (updateType === "receipt") {
      let receipt = updateData;
      if (receipt && receipt.hasOwnProperty('status')) {
        let txStatus = receipt.status ? "succeeded" : "failed";
        dispatch(updateAgreementTransaction(updateData.transactionHash, "create", txStatus));
      }
      getAgreementHashFromTransactionReceipt(updateData).then((responseObj) => {
        dispatch(handleDeployTransactionReceipt(agreementid, contractType, responseObj));
      });
    }
    else if (updateType === "error") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      console.log("Transaction error!");
      dispatch(clearLockOnAgreement(agreementid, true));
    }
  }
}

// actionType is string representing the function being called, i.e. "deposit", "resolve_party", etc
// updateType is the step
// updateData is the hash
export function hasActionTransactionUpdate(agreementid, actionType, updateType, updateData) {
  return function(dispatch) {
    if (updateType === "start") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: true
      });
      dispatch({
        type: 'AGREEMENT_BLOCKCHAIN_TRANSACTION',
        payload: {agreementid, hash: "", type: actionType}
      });  
    }
    else if (updateType === "hash") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      dispatch(track("action", "update", JSON.stringify({type:"blockchain_transaction", agreementid, actionType: actionType, hash: updateData })));
      dispatch(updateAgreementTransaction(updateData, actionType, "pending"));

      dispatch({
        type: 'AGREEMENT_BLOCKCHAIN_TRANSACTION',
        payload: {agreementid, type: actionType, hash: updateData }
      });

      let dialog = store.getState().overlay.dialog;
      if (dialog === "blockchain_deposit" || dialog === "blockchain_resolve" || dialog === "blockchain_arbitrate") {
        dispatch({
          type: 'CLOSE_OVERLAY'
        });  
      }

      window.setTimeout(() => {
        dispatch(hasActionTransactionUpdate(agreementid, actionType, "hash_callback", updateData));
      }, 30000);
    }
    else if (updateType === "hash_callback") {
      transactionComplete(dispatch, true);
      dispatch(refreshTransactionsFromDB(agreementid));
    }
    else if (updateType === "receipt") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      //dispatch({
      //  type: 'AGREEMENT_CLEAR_BLOCKCHAIN_TRANSACTION'
      //});
      let receipt = updateData;
      if (receipt && receipt.hasOwnProperty('status')) {
        let txStatus = receipt.status ? "succeeded" : "failed";
        dispatch(updateAgreementTransaction(updateData.transactionHash, actionType, txStatus));
      }
      transactionComplete(dispatch, true);
    }
    else if (updateType === "error") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      dispatch({
        type: 'AGREEMENT_CLEAR_BLOCKCHAIN_TRANSACTION'
      });
    }
  }
}

export function hasEvidenceTransactionUpdate(agreementid, actionType, updateType, updateData) {
  return function(dispatch) {
    if (updateType === "start") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: true
      });
      dispatch({
        type: 'AGREEMENT_EVIDENCE_TRANSACTION',
        payload: {agreementid, hash: "", type: actionType}
      });  
    }
    else if (updateType === "hash") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      dispatch(track("action", "update", JSON.stringify({type:"blockchain_transaction", agreementid, actionType: actionType, hash: updateData })));

      dispatch({
        type: 'AGREEMENT_EVIDENCE_TRANSACTION',
        payload: {agreementid, type: actionType, hash: updateData }
      });

      window.setTimeout(() => {
        dispatch(hasEvidenceTransactionUpdate(agreementid, actionType, "hash_callback", updateData));
      }, 30000);
    }
    else if (updateType === "hash_callback") {
      transactionComplete(dispatch, true);
    }
    else if (updateType === "receipt") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      let agreement = store.getState().agreement;
      let { agreedData="", status="", deployedid="", contractType="", agreementid="" } = agreement;
      let agreedDataObj = agreedData === "" ? {} : JSON.parse(agreedData);
      if (status === "finalized" && agreedDataObj.hasOwnProperty("arbitrator_address")) {
        let arbitratorAddress = agreedDataObj.arbitrator_address;
        dispatch(syncBlockchainEvidence(agreementid, deployedid, arbitratorAddress, contractType));
      }
    }
    else if (updateType === "error") {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: false
      });

      dispatch({
        type: 'AGREEMENT_CLEAR_EVIDENCE_TRANSACTION'
      });
    }
  }
}

export function checkDeployTransactionForUpdates() {
  return function(dispatch) {
    let agreement = store.getState().agreement;
    let {
      youAreUser1=false,
      youAreUser2=false,
      agreementid="",
      transactionType="",
      transactionHash="",
      transactionSource="",
      deployedid="",
      status=""
    } = agreement;

    if ((youAreUser1 || youAreUser2) && transactionSource !== "local" && transactionHash !== "" && transactionType !== "" && deployedid === "" && (status === "agreed" || status === "proposed")) {
      if (window.ethersProvider) {
        dispatch(hasDeployTransactionUpdate(agreementid, transactionType, "remote", "hash", transactionHash));
      }
    }
  }
}

export function transactionComplete(dispatch, removePrompt) {
  if (removePrompt) {
    window.syncRemovePrompt = true;
  }
  dispatch(reloadBlockchainSyncList());
}

export function checkStalledAgreementLoad() {
  return function(dispatch) {
    let agreement = store.getState().agreement;
    let menu = store.getState().menu;
    let { agreementid="", loadTimestamp=0, status="", deployedid="" } = agreement;
    let { selectedPage="" } = menu;

    if (selectedPage === "view" && agreementid !== "" && status === "finalized" && deployedid !== "") {
      let blockchain = store.getState().blockchain;
      let stateDataByAgreement = blockchain.stateData;
    
      if (stateDataByAgreement.hasOwnProperty(agreementid)) {
        // Then no issue
      }
      else {
        let now_unix = moment.tz('America/Los_Angeles').unix();
        if (loadTimestamp !== 0 && now_unix > loadTimestamp + 30) {
          console.log("Stalled blockchain sync detected");
          window.location.reload();
        }
      }
    }
  }
}

export async function getAgreementHashFromTransactionReceipt(receipt) {
  if (window.ethersProvider) {
    if (receipt && receipt.hasOwnProperty("status")) {
      if (receipt.status === true || receipt.status === 1) {
        return shared.readAgreementCreatedReceipt(receipt);
      }
    }
  }

  return null;
}

export async function getAgreementHashFromTransactionHash(txHash) {
  if (txHash !== "" && window.ethersProvider) { 
    try {
      let receipt = await window.ethersProvider.waitForTransaction(txHash);
      if (receipt && receipt.hasOwnProperty("status")) {
        if (receipt.status) {
          return shared.readAgreementCreatedReceipt(receipt);
        }
      }
    }
    catch (err) {
      console.log("Error fetching tx [*]");
    }
  }

  return null;
}

export function requireConfirmNav() {
  return function(dispatch) {
    dispatch({
      type: 'REQUIRE_CONFIRM_NAV',
      payload: true
    });
  }
}

export function checkTokenPriceData() {
  return function(dispatch) {
    let auth = store.getState().auth;
    if (!auth.hasTokenPriceData) {
      apiGetTokenPrice().then((response) => {
        if (response && response.status === "success") {
          dispatch({
            type: 'AUTH_TOKEN_PRICE',
            payload: response.price
          });
        }
      });
    }
  }
}