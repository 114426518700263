import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Common.css';
import './HomePage.css';
import { homePageSubmitEmail, changeInputValue, openMenuList, closeMenuList, refreshHomepageSections } from './HomePageActions';
import { pageNotLoaded, getLoadingPostingList } from './ActionUtils';
import { getIsMobile, getContentWidth, navNewURL, urlForBreadcrumb, validateEmail } from './ActionUtils';
import SearchCard from './SearchCard';
import CategoryBar from './CategoryBar';
import GapCard from "./GapCard";
import PostingList from './PostingList';
import Link from './Link';
import { genURL } from "./ActionUtils";
import TwoColumnContainer from './TwoColumnContainer';
import { 
  HOMEPAGE_SECTIONS
} from './Constants.js';
import { shared } from '../Shared';

class HomePage extends Component {
  constructor(props) {
    super(props);

      // This binding is necessary to make `this` work in the callback
      this.submitEmail = this.submitEmail.bind(this);
      this.scrollFromHash = this.scrollFromHash.bind(this);
      this.forceScroll = this.forceScroll.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleEmailChange = this.handleEmailChange.bind(this);
      this.handleSearch = this.handleSearch.bind(this);
      this.openMenuList = this.openMenuList.bind(this);
      this.closeMenuList = this.closeMenuList.bind(this);
  }

  componentDidMount() {
    this.scrollFromHash(this.props.menu.hash);
    this.props.dispatch(refreshHomepageSections());
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.menu.hash !== prevProps.menu.hash) {
      this.scrollFromHash(this.props.menu.hash);
    }
  }

  openMenuList(event) {
    this.props.dispatch(openMenuList());
  }

  closeMenuList(event) {
    this.props.dispatch(closeMenuList());
  }

  forceScroll() {
    this.scrollFromHash(this.props.menu.hash);
  }

  scrollFromHash(hash) { 
    /*   
    window.setTimeout(() => {
      if (hash === "#whatisatstake") {
        document.getElementById('whatisatstake').scrollIntoView({block: "center", behavior: "smooth"});
      }
    }, 300);
    */
  }

  submitEmail(event) {
    let email = document.getElementById("subscribeEmail").value;
    if (email.trim() !== "") {
      this.props.dispatch(homePageSubmitEmail(email));
      // Remove email address so they can't submit repeatedly by mashing the button
      this.props.dispatch(changeInputValue("emailAddress", "")); 
    }
  }

  handleInputChange(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  handleEmailChange(event) {
    let value = event.currentTarget.value;
    if (value.length > shared.MAX_IDENTIFIER_LENGTH) {
      value = value.substring(0, shared.MAX_IDENTIFIER_LENGTH);
    }
    this.handleInputChange("emailAddress", value);
  }

  handleSearch(name, value) {
    let { searchBox="" } = this.props.homePage;
    this.props.dispatch(navNewURL('/postings?search=' + encodeURIComponent(searchBox)));
  }

  render() {
    if (pageNotLoaded(this.props.homePage)) { return null };

    let authUsername = this.props.auth.username;
    let isMobile = getIsMobile();
    let contentWidth = getContentWidth();
    let showCategoryBar = !isMobile && contentWidth > 0;
    //let isSingleColumn = this.props.auth.columns === 1;
    let emailSubmitMessage = this.props.homePage.emailSubmitMessage;
    let emailAddress = this.props.homePage.emailAddress;

    let emailIsValid = validateEmail(emailAddress);

    let sectionsLoaded = this.props.homePage.sectionsLoaded;
    let sectionsList = this.props.homePage.sectionsList;
    if (!sectionsLoaded) {
      sectionsList = getLoadingPostingList(HOMEPAGE_SECTIONS);
    }

    let sectionsListElem = [];

    for (let i = 0; i < sectionsList.length; i++) {
      let sectionObj = sectionsList[i];
      let postingList = sectionObj.postings;
      let maxRows = postingList.length < 10 || sectionObj.postingids === "" ? 1 : 2;
      
      if (sectionsLoaded && postingList.length === 0) {
        // Skip
      }
      else {
        sectionsListElem.push(<GapCard key={"gap_"+ i} />);
        let titleLink = urlForBreadcrumb(sectionObj.bread_location, sectionObj.bread_type, sectionObj.bread_category);
        let moreText = sectionObj.postingids === "" ? "See more" : "See all postings";
        sectionsListElem.push(
          <div key={"title_"+ i} className="marketCategoryTitle">
            <Link noTarget={true} className="marketlink moveRight10" href={titleLink}>{sectionObj.title}</Link> <Link noTarget={true} className="underlinelink" href={titleLink}>{moreText}</Link>
          </div>
        );

        sectionsListElem.push(
          <div key={"postings_"+ i} className="moveTop10">
            <PostingList showLoad={!sectionsLoaded} data={postingList} limitRows={true} rows={maxRows} />
          </div>
        );
      }
    }

    return (
      <div className="homeSection">
        <SearchCard 
          showMenuButton={!showCategoryBar}
          name="searchBox" 
          value={this.props.homePage.searchBox} 
          onChange={this.handleInputChange}
          onSubmit={this.handleSearch}
          onMenu={this.openMenuList}
        />
        {showCategoryBar && (
          <CategoryBar />
        )}
        {authUsername === "" && (
          <div className="homeTopBanner moveTop20">
            <div className="homeTopBannerTitle">An open marketplace built on Ethereum</div>
            <div className="moveTop10"><Link noTarget={true} useSpan={true} className="underlinelink" href="/home#whatisatstake" onClick={this.forceScroll}>Learn more about Atstake</Link></div>
          </div>
        )}
        {authUsername !== "" && (
          <div className="blankCard moveTop20">
            <span className="homeWelcomeBack moveRight10 moveTop10">Welcome back, {authUsername}!</span> <Link noTarget={true} style={{display: "inline-block"}} className="underlinelink moveTop10" href="/profile">View your account</Link>
          </div>
        )}
        {sectionsListElem}
        <div id="whatisatstake" className="homeAboutBanner moveTop20">
          <div className="homeAboutBannerTitle">What is Atstake?</div>
          <div className="homeAboutBannerSubtitle"><Link noTarget={true} className="underlinelink" href="/about">Read our story and meet our team</Link></div>
          <div className="blankCard ">
            <TwoColumnContainer showBorder={false} 
              numRows={2}
              leftComp={[
                (<div className="homeAboutReasonTitle moveTop40">A marketplace for anything</div>),
                (
                  <div className="homeAboutReasonDesc moveTop10">
                    An Ethereum powered marketplace for any kind of contract between two people. Freelancing, ecommerce, competitions
                    and <Link href={genURL("/examples")} className="underlinelink" noTarget={true}>more</Link>.
                  </div>
                )
              ]}
              middleComp={[
                (<div className="homeAboutReasonTitle moveTop40">Multiple sources of protection</div>),
                (
                  <div className="homeAboutReasonDesc moveTop10">
                    A system of <Link href={genURL("/incentives")} className="underlinelink" noTarget={true}>customizable escrow and reputation</Link> ensures that your contracts are fairly enforced.
                  </div>
                )
              ]}
              rightComp={[
                (<div className="homeAboutReasonTitle moveTop40">Free and open</div>),
                (<div className="homeAboutReasonDesc moveTop10">Atstake doesn’t charge any fees and is available to anyone in the world with an Internet connection.</div>)
              ]}
            />
          </div>
          <div className="homeAboutBannerBottom moveTop40">Got questions? <span>Visit our <Link noTarget={true} className="underlinelink" href="/help">help page</Link>, </span> <span><Link noTarget={true} className="underlinelink" href="/faq">FAQ</Link>, or our <Link noTarget={true} className="underlinelink" href="/help#gettingstarted">getting started guide</Link>.</span></div>
        </div>
        <div className="pageSection">
          <TwoColumnContainer showBorder={false} className="" fullComp={
              <div style={{textAlign:"center"}}>
                <div className="pageCardHeading">Sign up to receive updates about Atstake</div>
                <div>
                  <input className="emailInputBox moveTop20 moveRight5" id="subscribeEmail" type="text" placeholder="Email address" name="email" value={this.props.homePage.emailAddress} onChange={this.handleEmailChange} />
                  <button disabled={!emailIsValid} style={{padding:"12px 20px"}} className="btn btnGeneric moveTop20" onClick={this.submitEmail}>Sign up</button>  
                </div>
                <div className="moveTop10" style={{fontSize:"12px", color:"#999"}}>
                  We will never share your email with third parties
                </div>
                <div style={{position:"relative", paddingTop: "20px", height:"20px"}}>
                  {emailSubmitMessage === "success" && <div className="" style={{fontSize:"12px", color:"var(--green-color)"}}>Email submitted</div>}
                  {emailSubmitMessage === "error" && <div className="" style={{fontSize:"12px", color:"var(--red-color)"}}>Error submitting email</div>}
                </div>
              </div>
            } />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    homePage: state.homePage,
    overlay: state.overlay,
    error: state.error
  }
}

const HomePageContainer = connect(mapStateToProps, null)(HomePage);
export default HomePageContainer;