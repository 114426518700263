import { connect } from 'react-redux';
import React, { Component } from 'react'
import './DialogLogin.css';
import { changeInputValue } from './DialogLoginActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import InputBasic from './InputBasic';
import ButtonAction from './ButtonAction';
import { logInWithSignature, registerWithSignature, logInWithAccessCode, doesUsernameExist } from "./AuthActions";
import { genURL, validateEmail } from './ActionUtils';
import loadImg from '../load_blue.gif';
import { track } from './ActionUtils';
import Link from './Link';
import moment from 'moment-timezone';
import { closeOverlay, tallOverlay } from './OverlayActions';
import { addAttestation } from './DialogAddAttestationActions';
import { shared } from '../Shared';

class DialogLogin extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.getLoginMode = this.getLoginMode.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.handleAccessCodeLogin = this.handleAccessCodeLogin.bind(this);
    this.handleShowRequestAccessCode = this.handleShowRequestAccessCode.bind(this);
    this.handleHideRequestAccessCode = this.handleHideRequestAccessCode.bind(this);
    this.handleDisclaimerContinue = this.handleDisclaimerContinue.bind(this);
    this.handleAcceptTerms = this.handleAcceptTerms.bind(this);
    this.refresh = this.refresh.bind(this);
    this.enableEthereum = this.enableEthereum.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.handleIgnoreUnsupportedWallet = this.handleIgnoreUnsupportedWallet.bind(this);
    this.handleLinkEmail = this.handleLinkEmail.bind(this);
    this.handleSkipEmail = this.handleSkipEmail.bind(this);
    this.overlayNode = null;
  }
  
  componentDidMount() {
    let mode = this.getLoginMode(this.props).mode;
    if (mode !== "no_init") {
      this.props.dispatch(track("dialog", "login", JSON.stringify({mode})));
      if (mode === "needs_ethereum_enable" || mode === "no_metamask") {
        this.enableEthereum();
        window.setTimeout(() => {
          this.enableEthereum();
        }, 2000);
      }
    }

    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dialogLogin.username !== prevProps.dialogLogin.username) {
      if (this.props.dialogLogin.username !== "") {
        this.props.dispatch(doesUsernameExist(this.props.dialogLogin.username));
      }
    }

    let mode = this.getLoginMode(this.props).mode;
    if (mode !== this.getLoginMode(prevProps).mode) {
      this.props.dispatch(track("dialog", "login", JSON.stringify({mode})));
      if (mode === "needs_ethereum_enable" || mode === "no_metamask") {
        this.enableEthereum();
        window.setTimeout(() => {
          this.enableEthereum();
        }, 2000);
      }
    }

    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, false));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  handleAcceptTerms(event) {
    let hasAcceptedTerms = this.props.dialogLogin.hasAcceptedTerms;
    this.props.dispatch(changeInputValue("hasAcceptedTerms", !hasAcceptedTerms));
  }

  handleIgnoreUnsupportedWallet(event) {
    this.props.dispatch(changeInputValue("ignoreUnsupportedWallet", true));
  }

  handleLinkEmail(event) {
    let {
      email=""
    } = this.props.dialogLogin;

    this.props.dispatch(addAttestation("email", "private", email, false));
  }

  handleSkipEmail(event) {
    this.props.dispatch(closeOverlay());
  }

  handleDisclaimerContinue(event) {
    let hasAcceptedTerms = this.props.dialogLogin.hasAcceptedTerms;
    if (hasAcceptedTerms) {
      this.props.dispatch(changeInputValue("hasAcceptedDisclaimer", true));
    }
  }

  handleLogin(event) {
    let selectedAddress = this.props.auth.ethAddress;
    if (selectedAddress !== "") {
      this.props.dispatch(logInWithSignature(selectedAddress));
    }
  }

  handleSignup(event) {
    let selectedAddress = this.props.auth.ethAddress;
    let username = this.props.dialogLogin.username;
    if (selectedAddress !== "" && username !== "") {
      this.props.dispatch(registerWithSignature(selectedAddress, username));
    }
  }

  handleAccessCodeLogin(event) {
    let selectedAddress = this.props.auth.ethAddress;
    if (selectedAddress !== "") {
      if (this.props.dialogLogin.username === "") {
        this.props.dispatch(changeInputValue("errorEmptyUsername", true));
      }
      else if (this.props.dialogLogin.accessCodeInput.length !== 10) {
        this.props.dispatch(changeInputValue("invalidAccessCode", true));
      }
      else {
        this.props.dispatch(logInWithAccessCode(
          this.props.dialogLogin.username, 
          this.props.dialogLogin.accessCodeInput,
          selectedAddress
        ));
      }
    }
  }

  handleShowRequestAccessCode(event) {
    this.props.dispatch(changeInputValue("showRequestAccessCode", true));
  }

  handleHideRequestAccessCode(event) {
    this.props.dispatch(changeInputValue("showRequestAccessCode", false));
  }

  refresh(name, value) {
    window.location.reload();
  }

  enableEthereum(name, value) {
    if (window.ethereum) {
      window.ethereum.enable().then((response) => {}).catch((error) => {});
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  getLoginMode(props) {
    // State of login dialog
    let {
      hasAcceptedDisclaimer=false,
      initTimestampUnix=0,
      ignoreUnsupportedWallet=false,
      askForEmail=false
    } = props.dialogLogin;

    // Check authentication status
    let isLoggedIn = (this.props.auth.status === "loggedin");
    let hasUsernameMatch = this.props.auth.hasUsernameMatch;
    let initCheckAddress = props.auth.initCheckAddress;
    let hasAccount = props.auth.hasAccount;
    let isDisabled = props.auth.isDisabled;
    let hasConnection = props.auth.hasConnection;
    let selectedAddress = props.auth.ethAddress;
    let loggedInNoMatch = isLoggedIn && !hasUsernameMatch;

    // Web3 initialization status
    let web3Initialized = false;
    let needsEthereumEnable = false;
    if (window.ethersProvider) {
      web3Initialized = true;
      needsEthereumEnable = (selectedAddress === "");
    }

    let hasTimeout = false;
    let nowTimestampUnix = moment().tz('America/Los_Angeles').unix();
    if (initTimestampUnix !== 0 && nowTimestampUnix > initTimestampUnix + 30) {
      hasTimeout = true;
    }

    // Determine what version of the login dialog to show
    let mode = "no_init";
    let dialogTitle = "";
    if (initCheckAddress) {
      if (isLoggedIn && askForEmail) {
        mode = "success_link_email";
        dialogTitle = "Welcome to Atstake";
      }
      else if (isLoggedIn && hasUsernameMatch) {
        mode = "loggedin";
        dialogTitle = "Already logged in";
      }
      else if (props.dialogLogin.showRequestAccessCode) {
        mode = "access_code";
        dialogTitle = "Log in with access code";
      }
      else if (hasAccount && isDisabled) {
        mode = "is_disabled";
        dialogTitle = "Log in disabled";
      }
      else if (hasAccount && loggedInNoMatch) {
        mode = "switch_account";
        dialogTitle = "Switch account";  
      }
      else if (hasAccount) {
        mode = "has_account";
        dialogTitle = "Log in";  
      }
      else if (needsEthereumEnable) {
        mode = "needs_ethereum_enable";
        dialogTitle = "Ethereum wallet not connected";
      }
      else if (selectedAddress === "" || (hasTimeout && !web3Initialized)) {
        mode = "no_metamask";
        dialogTitle = "One step before you can sign up";
      }
      else if (!hasConnection) {
        mode = "no_connection";
        dialogTitle = "No internet connection";
      }
      else if (!hasAcceptedDisclaimer) {
        mode = "show_disclaimer";
        dialogTitle = "Sign up";
      }
      else if (web3Initialized) {
        mode = "register";
        dialogTitle = "Sign up";
      }
    }

    if (!ignoreUnsupportedWallet && props.auth.walletType === "alpha" && props.auth.android) {
      mode = "unsupported";
      dialogTitle = "Unsupported Ethereum wallet";
    }

    return { mode, dialogTitle };
  }
  
  render() {
    // State of login dialog
    let usernameExistsMap = this.props.dialogLogin.usernameExistsMap;
    let username = this.props.dialogLogin.username;
    let errorEmptyUsername = this.props.dialogLogin.errorEmptyUsername;
    let invalidAccessCode = this.props.dialogLogin.invalidAccessCode;
    let signupFailed = this.props.dialogLogin.signupFailed;
    let failureMessage = this.props.dialogLogin.failureMessage;
    let hasAcceptedTerms = this.props.dialogLogin.hasAcceptedTerms;
    let email = this.props.dialogLogin.email;

    let isValidEmail = validateEmail(email);

    // Check authentication status
    let selectedAddress = this.props.auth.ethAddress;

    // Determine whether username has already been registered
    let usernameInMap = false;
    let usernameExists = false;
    if (usernameExistsMap.hasOwnProperty(username)) {
      usernameInMap = true;
      usernameExists = usernameExistsMap[username];
    }

    // Determine what version of the login dialog to show
    let { mode="no_init", dialogTitle="" } = this.getLoginMode(this.props);

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection"> 
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          {mode === "no_init" && (
            <div>
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>Initializing ...</div>
                <div><img style={{position:"relative", left:"-8px"}} className="loadIcon" src={loadImg} alt="Loading" /></div>
              </div>
            </div>
          )}
          {mode === "loggedin" && (
            <div>
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>Hi {this.props.auth.username}! You're already logged in to your Atstake account.</div>
              </div>
            </div>
          )}
          {mode === "no_connection" && (
            <div>
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>You appear to be disconnected from the internet. Please check your internet connection and refresh to try again.</div>
                <div className="moveTop20"><ButtonAction name="refresh" text="Refresh" onClick={this.refresh}/></div>
              </div>
            </div>
          )}
          {mode === "needs_ethereum_enable" && (
            <div>
              <div className="contentLabel">
                <div style={{lineHeight:"18px"}}>We've detected that you have an Ethereum wallet, but it's disconnected. You need to connect your wallet to use Atstake.</div>
                <div className="moveTop20"><ButtonAction name="enable_ethereum" text="Connect Ethereum Wallet" onClick={this.enableEthereum}/></div>
              </div>
            </div>
          )}
          {mode === "no_metamask" && (
            <div>
              <div className="contentLabel"> 
                <div style={{lineHeight:"18px"}}>
                To sign up with Atstake you'll need 
                an <Link target="_blank" rel="noopener noreferrer" className="bluelink" href="https://en.wikipedia.org/wiki/Ethereum">Ethereum</Link> wallet.</div>
                
                <div className="moveTop20">We can't detect an Ethereum wallet in your browser, but getting one takes just a couple minutes. Choose one of these options:</div>
                
                <ul>
                  <li>
                    <div style={{lineHeight:"18px"}} className="moveTop20">On mobile: Install an Ethereum wallet app
                    such as <Link target="_blank" rel="noopener noreferrer" className="bluelink" href="https://wallet.coinbase.com">Coinbase Wallet</Link>, then return to this page from
                    within the wallet's mobile browser.</div>
                  </li>
                  <li>
                    <div style={{lineHeight:"18px"}} className="moveTop20">On desktop: Install the browser extension&nbsp; 
                    <Link target="_blank" rel="noopener noreferrer" className="bluelink" href="https://metamask.io">Metamask</Link>. 
                    Then use Metamask to create an Ethereum account and return to this page. 
                    If you're already using Metamask make sure your wallet is unlocked.</div>
                  </li>
                </ul>

                <div className="moveTop20">Questions? See our <Link href={genURL("/faq")} className="bluelink" target="_blank" rel="noopener noreferrer">FAQ</Link>.</div>
                
              </div>
            </div>
          )}
          {mode === "show_disclaimer" && (
            <div>
              <div className="contentLabel">
              Atstake is provided "as is", without warranty of any kind. When using Atstake you must comply with all applicable laws.</div>

              <div className="contentLabel moveTop20">
              Do you accept this disclaimer as well as our <Link href={genURL("/tos")} className="bluelink" target="_blank" rel="noopener noreferrer">terms of service</Link>?</div> 
              <div className="moveTop20"><label className="checkboxLabel" ><input className="checkboxInput" type="checkbox"  
              name="accept_terms" checked={hasAcceptedTerms} onChange={this.handleAcceptTerms} /> <span>I accept</span></label></div>

              <div className="moveTop20 moveRight20"><ButtonAction disabled={!hasAcceptedTerms} name="continue" text="Continue" onClick={this.handleDisclaimerContinue}/></div>
            </div>
          )}
          {mode === "register" && (
            <div>
              <div className="contentLabel">Welcome! Your Ethereum address {selectedAddress} is not registered with Atstake.</div>
              <div className="contentLabel moveTop20">Please choose a username to sign up.</div>
              <InputBasic maxlength={shared.MAX_ATSTAKE_USERNAME_LENGTH} restrict="username" placeholder="Username" type="text" className="dialogInput moveTop5" name="username" value={this.props.dialogLogin.username} onChange={this.changeInputValue} />
              {signupFailed && <div className="inputError moveTop5">{failureMessage}</div>}
              {!signupFailed && usernameInMap && usernameExists && <div className="inputError moveTop5">Username {this.props.dialogLogin.username} already exists.</div>}
              <div><ButtonAction disabled={usernameExists} name="signup" className="moveTop20 moveRight20" text="Sign up" onClick={usernameExists ? null : this.handleSignup}/></div>
              <div className="miniText">Already have an account? <span onClick={this.handleShowRequestAccessCode} className="bluelink">Log in with access code here.</span></div>
            </div>
          )}
          {mode === "access_code" && (
            <div>
              <div className="contentLabel">Welcome! Your Ethereum address {selectedAddress} is not registered with Atstake.</div>
              <div className="contentLabel moveTop20">Please enter username and access code to log in.</div>
              <InputBasic restrict="username" placeholder="Username" type="text" className="dialogInput moveTop5" name="username" value={this.props.dialogLogin.username} onChange={this.changeInputValue} />
              {errorEmptyUsername && <div className="inputError moveTop5">Please enter username.</div>}
              {!errorEmptyUsername && usernameInMap && !usernameExists && <div className="inputError moveTop5">Username {this.props.dialogLogin.username} does not exist.</div>}
              <InputBasic restrict="upperalphanum" placeholder="Access code" type="text" className="dialogInput moveTop5" name="accessCodeInput" value={this.props.dialogLogin.accessCodeInput} onChange={this.changeInputValue} />
              {invalidAccessCode && <div className="inputError moveTop5">Invalid access code.</div>}
              <div><ButtonAction name="signup" className="moveTop20 moveRight20" text="Log in with access code" onClick={this.handleAccessCodeLogin}/></div>
              <div className="miniText">Not yet registered? <span onClick={this.handleHideRequestAccessCode} className="bluelink">Sign up here.</span></div>
            </div>
          )}
          {mode === "is_disabled" && (
            <div>
              <div className="contentLabel">Welcome back. Your Ethereum address {selectedAddress} is currently disabled. Please use a different Ethereum address to log in.</div>
            </div>
          )}
          {mode === "switch_account" && (
            <div>
              <div className="contentLabel">{"Your Ethereum address "}{selectedAddress}{" is linked to a different Atstake account.  Please log in to switch your account."}</div>
              <div><ButtonAction name="login" className="moveTop20" text="Log in to switch account" onClick={this.handleLogin}/></div>
            </div>
          )}      
          {mode === "has_account" && (
            <div>
              <div className="contentLabel">{"Welcome back.  We've detected that you already have an account.  Please log in."}</div>
              <div><ButtonAction name="login" className="moveTop20" text="Log in" onClick={this.handleLogin}/></div>
            </div>
          )}
          {mode === "unsupported" && ( 
            <div>
              <div className="contentLabel">Atstake does not yet work* with the Android version of Alpha Wallet. We're working with the Alpha Wallet team to resolve this.</div>

              <div className="moveTop20">
              Until then we recommend <Link target="_blank" rel="noopener noreferrer" className="bluelink" href="https://wallet.coinbase.com">Coinbase Wallet</Link> on mobile,
              or you can use <Link target="_blank" rel="noopener noreferrer" className="bluelink" href="https://metamask.io/">Metamask</Link> on desktop.
              </div>

              <div className="moveTop20" style={{fontSize:"12px"}}>*Versions of Alpha Wallet released on or after September 13 2019 will partially work. The wallet may become unresponsive when declining transactions and may require pages to be manually refreshed. If you wish to use Alpha Wallet anyway please make sure you have the latest version before <span className="bluelink" onClick={this.handleIgnoreUnsupportedWallet}>logging in</span>.</div>
            </div>
          )}
          {mode === "success_link_email" && (
            <div>
              <div className="contentLabel">{"Success! Link your email address so we can notify you when the status of your contracts change or when users message you."}</div>
              <InputBasic maxlength={shared.MAX_IDENTIFIER_LENGTH} placeholder="Email address" type="text" className="dialogInput moveTop5" name="email" value={this.props.dialogLogin.email} onChange={this.changeInputValue} />
              <div><ButtonAction disabled={!isValidEmail} name="link_email" className="moveTop20" text="Link email" onClick={this.handleLinkEmail}/></div>
              <div className="moveTop5"><span onClick={this.handleSkipEmail} className="small bluelink">Skip linking email</span></div>
            </div>
          )}
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogLogin: state.dialogLogin
  }
}

const DialogLoginContainer = connect(mapStateToProps, null)(DialogLogin);
export default DialogLoginContainer;