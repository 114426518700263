import React, { Component } from 'react';
import './Common.css';
import './Attestation.css';
import { titleByType, srcForType, shortenAddress, getContentWidth } from "./ActionUtils"

class Attestation extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      this.props.onClick(this.props.allowEdit, this.props.type, this.props.value, this.props.verified, this.props.privacy, this.props.user1, this.props.isDefault ? "yes" : "no", this.props.ethereumAddressList);
    }

    render() {

      let isNarrow = (getContentWidth() < 650);

      let allowEdit = this.props.allowEdit;
      let type = this.props.type;
      let title = titleByType(type);
      let className = this.props.className || "";
      let privacy = this.props.privacy;
      let isDefault = this.props.isDefault;
      let displayValue = this.props.value;
      if (type === "ethereum" && isNarrow) {
        displayValue = shortenAddress(this.props.value);
      }

      let extraInfo = privacy;
      if (isDefault) {
        extraInfo = "default, " + privacy;
      }
      
      return (
        <div className={"attestation " + className} >
          <div className={"attestationIdentifier" + (privacy === "disabled" ? " attestationDisabled" : "")}><span className="attestationValueIconCnt"><span style={{position:"relative", display:"inline-block", width:"17px"}}><img title={title} alt={title} src={srcForType(type)} className="attestationImg"></img></span><span className="attestationValue">{displayValue} </span></span><span className="extraInfoSpan">{extraInfo}</span><span className="attestationAction bluelink" onClick={this.handleClick}>{allowEdit ? "view/edit" : "view"}</span></div>
        </div>
      );

    }
  }
  
  export default Attestation;
