import React, { Component } from 'react';
import './ButtonIcon.css';
import './Common.css';

class ButtonIcon extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (this.props.hasOwnProperty("onClick") && this.props.onClick !== null) {
        this.props.onClick(event.currentTarget.getAttribute("name"), event.currentTarget.getAttribute("value"));
      }
    }

    render() {
      let disabled = this.props.disabled || false;
      let classNameVal = "btnIcon";

      if (this.props.hasOwnProperty("className")) {
        classNameVal += " " + this.props.className;
      }

      if (disabled) {
        classNameVal += " disabled";
      }

      return (
        <button name={this.props.name} value={this.props.value} className={classNameVal} onClick={this.handleClick}><img className="btnIconImg" src={this.props.icon} title={this.props.text} alt={this.props.text} /></button>
      );
    }
  }
  
  export default ButtonIcon;
