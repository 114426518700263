var utils = require('web3-utils');
var moment = require('moment-timezone');

exports.testMode = false;

// Shared constants used to validate user input in both lambda and the UI
exports.MAX_ARBITRATION_DAYS = 365000; // ~1000 years
exports.NUMBER_OF_DAYS_MAX_STRING_LENGTH = 6;
exports.MAX_POSTING_TITLE_LENGTH = 40;
exports.MAX_POSTING_LOCATION_DETAILS_LENGTH = 80;
exports.MAX_POSTING_DESCRIPTION_LENGTH = 3000;
exports.MAX_POSTING_DEADLINE_LENGTH = 1000;
exports.MAX_POSTING_CONTRACT_DETAILS_LENGTH = 4000;
exports.MAX_CONTRACT_TITLE_LENGTH = 80;
exports.MAX_CONTRACT_TERMS_LENGTH = 10000;
exports.MAX_CONTRACT_COMMENT_LENGTH = 5000;
exports.MAX_PRIVATE_MESSAGE_LENGTH = 5000;
exports.MAX_IDENTIFIER_LENGTH = 100; // any attestation type we support: email, ETH address, etc 
exports.MAX_REPORT_TEXT_LENGTH = 5000;
exports.MAX_REPORT_DATA_LENGTH = 50000; // includes a bunch of user and site data
exports.MAX_ATSTAKE_USERNAME_LENGTH = 15;
exports.MAX_TWITTER_USERNAME_LENGTH = 15;
exports.MAX_FACEBOOK_USERNAME_LENGTH = 50;
exports.MAX_REDDIT_USERNAME_LENGTH = 20;
exports.MAX_PHONE_NUMBER_LENGTH = 15;
exports.MAX_SEARCH_LENGTH = 100;
exports.MAX_DISPLAY_NAME_LENGTH = 40;
exports.MAX_PROFILE_SUMMARY_LENGTH = 1000;
exports.MAX_ARBITRATION_DESCRIPTION_LENGTH = 1000;
exports.MAX_URL_LENGTH = 3000;
exports.MAX_ETHEREUM_ADDRESS_LENGTH=42;
exports.AGREEMENT_LOCK_EXPIRATION_SECONDS = 900;


exports.deepCopy = function(obj) {
  return JSON.parse(JSON.stringify(obj));
}

exports.CONTRACT_TYPE_LIST = [
  "simple_eth",
  "simple_erc20",
  "kleros_eth",
  "kleros_erc20"
];

exports.NULL_RESOLUTION = "115792089237316195423570985008687907853269984665640564039457584007913129639935";

exports.contractType = function(type1, type2, arbitrationFeeType) {
  if (type1 === "eth" && type2 === "eth" && arbitrationFeeType === "eth") {
    return "simple_eth";
  }
  
  return "simple_erc20";
}

exports.truffleContractName = function(contractType) {
  if (contractType === "simple_eth") {
    return "AgreementManagerETH_Simple";
  }
  else if (contractType === "simple_erc20") {
    return "AgreementManagerERC20_Simple";
  }
  else if (contractType === "kleros_eth") {
    return "AgreementManagerETH_ERC792";
  }
  else if (contractType === "kleros_erc20") {
    return "AgreementManagerERC20_ERC792";
  }
  
  return "";
}

exports.getKlerosAddress = function(isTest) {
  if (isTest) {
    // Rinkeby
    return "";
  }
  else {
    // Kovan address is 0x23c8118Ae9FB45A0cb7Fcfe3aF65D081233D82A5
    // We have no Mainnet address yet
    return "";
  }
}

exports.contractInterfaceETH = function(contractType) {
  return (contractType === "simple_eth" || contractType === "kleros_eth");
}

exports.contractInterfaceERC20 = function(contractType) {
  return (contractType === "simple_erc20" || contractType === "kleros_erc20");  
}

exports.contractInterfaceERC792 = function(contractType) {
  return (contractType === "kleros_eth" || contractType === "kleros_erc20");  
}

exports.getContractLink = function(isTest, contractType) {
  if (isTest) {
    return "https://rinkeby.etherscan.io/address/" + exports.getContractAddress(isTest, contractType);
  }
  else {
    return "https://etherscan.io/address/" + exports.getContractAddress(isTest, contractType);
  }
}

exports.getContractAddress = function(isTest, contractType) {
  if (contractType === "simple_eth") {
    if (isTest) {
      // Rinkeby
      return "0x46c3Be6A0aA0a1F94B5AA2f45a045EA860ee02a4";
    }
    else {
      // Mainnet
      return "0x4C0fC7abfa8d2a44B379704a8Dc1e5b6169F8454";
    }
  }
  else if (contractType === "simple_erc20") {
    if (isTest) {
      // Rinkeby
      return "0x531B663af8BBE55214C9B4838ABe343fddc26715";
    }
    else {
      // Mainnet
      return "0xba5A6e8BbcdA99932e86A0aa3F87EbdbE4B20c28";
    }
  }
  else if (contractType === "kleros_eth") {
    if (isTest) {
      // Rinkeby
      return "0x0000000000000000000000000000000000000000";
    }
    else {
      // Mainnet
      return "0x0000000000000000000000000000000000000000";
    }
  }
  else if (contractType === "kleros_erc20") {
    if (isTest) {
      // Rinkeby
      return "0x0000000000000000000000000000000000000000";
    }
    else {
      // Mainnet
      return "0x0000000000000000000000000000000000000000";
    }
  }
}

exports.getContractABI = function(isTest, contractType) {
  var abiETH = [
    "function getState(uint agreementID) external view returns (address[3] memory, uint[16] memory, bool[12] memory, bytes memory)"
  ];
  var abiERC20 = [
    "function getState(uint agreementID) external view returns (address[6] memory, uint[23] memory, bool[12] memory, bytes memory)"
  ];

  if (contractType === "simple_eth" || contractType === "kleros_eth") {
    return abiETH;
  }
  else if (contractType === "simple_erc20" || contractType === "kleros_erc20") {
    return abiERC20;
  }

  return "";
}

exports.getNetworkName = function(isTest) {
  if (isTest) {
    // Rinkeby
    return "rinkeby";
  }
  else {
    // Mainnet
    return "mainnet";
  }
}

exports.getNetworkNameLong = function(isTest) {
  if (isTest) {
    // Rinkeby
    return "Rinkeby Test Network";
  }
  else {
    // Mainnet
    return "Main Ethereum Network";
  }
}

exports.getTransactionTypeDisplay = function(type) {
  if (type === "create" || type === "deposit") {
    return "deposit";
  } else if (type === "resolve_party" || type === "resolve_arbitrator") {
    return "outcome report";
  } else if (type === "withdraw" || type === "early_withdraw") {
    return "withdraw";
  } else if (type === "arbitration") {
    return "arbitration request";
  } else if (type === "default_judgment") {
    return "request default judgment";
  } else if (type === "auto_resolution") {
    return "request automatic outcome";
  } else if (type === "withdraw_fee") {
    return "withdraw dispute fee";
  } else if (type === "submit_evidence") {
    return "submit evidence";
  } else {
    return "";
  }
}

exports.getTransactionLink = function(isTest, hash) {
  if (isTest) {
    return "https://rinkeby.etherscan.io/tx/" + hash;
  }
  else {
    return "https://etherscan.io/tx/" + hash;
  }
}

exports.getNetworkNameLongFromId = function(networkId) {
  if (networkId === "1") {
    return "Main Ethereum Network";
  }
  else if (networkId === "3") {
    return "Ropsten Test Network";
  }
  else if (networkId === "4") {
    return "Rinkeby Test Network";
  }
  else if (networkId === "42") {
    return "Kovan Test Network";
  }
  else if (networkId === "77") {
    return "Sokol Test Network";
  }
  else if (networkId === "99") {
    return "POA Network";
  }
  else if (networkId === "100") {
    return "xDai Network";
  }
  else {
    return "Unknown Network";
  }
}

exports.isSupportedNetwork = function(network) {
  return exports.getNetworkId(false) === network || exports.getNetworkId(true) === network;
}

exports.getNetworkId = function(isTest) {
  if (isTest) {
    // Rinkeby
    return "4";
  }
  else {
    // Mainnet
    return "1";
  }
}

// Mainnet
exports.SUPPORTED_TOKEN_LIST_PROD = [
  {
    symbol: "eth",
    display: "Ether",
    contract: "0x0000000000000000000000000000000000000000",
    power: "12",
    decimals: "18"
  },
  { // The new DAI, aka MCD
    symbol: "dai",
    display: "DAI",
    contract: "0x6b175474e89094c44da98b954eedeac495271d0f",
    power: "16",
    decimals: "18"
  },
  {
    symbol: "usdc",
    display: "USDC",
    contract: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    power: "16",
    decimals: "18"
  },
  {
    // Token contract linked from https://www.wbtc.network/dashboard/order-book
    symbol: "wbtc",
    display: "WBTC",
    contract: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    power: "12",
    decimals: "18"
  } /*,
  {
    symbol: "zrx",
    display: "ZRX",
    contract: "0xe41d2489571d322189246dafa5ebde1f4699f498",
    power: "16",
    decimals: "18"
  },
  {
    symbol: "pnk",
    display: "Kleros",
    contract: "0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d", 
    power: "16",
    decimals: "18"
  } */
];

// Rinkeby
exports.SUPPORTED_TOKEN_LIST_TEST = [
  {
    symbol: "eth",
    display: "Ether",
    contract: "0x0000000000000000000000000000000000000000",
    power: "12",
    decimals: "18"
  },
  {
    symbol: "dai",
    display: "DAI",
    contract: "0x8d8df3ec3ca5d0b23f465754830b866238c43b7e",
    power: "16",
    decimals: "18"
  },
  {
    symbol: "usdc",
    display: "USDC",
    contract: "0x89a48f746ed7aba689c1fd60a950b5726b376c31",
    power: "16",
    decimals: "18"
  },
  {
    symbol: "wbtc",
    display: "WBTC",
    contract: "0xb82993988494968d447C684bd6bba708D5fD3ced",
    power: "12",
    decimals: "18"
  } /*,
  {
    symbol: "zrx",
    display: "ZRX",
    contract: "0x216eAE9609527527D22183C797524Fbef8643e7f",
    power: "16",
    decimals: "18"
  },
  {
    symbol: "pnk",
    display: "Kleros",
    contract: "0xbadE11fbe8a1bB8b56D33bD776Be1f8caA9E6549",
    power: "16",
    decimals: "18"
  } */
];

exports.getSupportedTokenList = function(isTest) {
  if (isTest) {
    return exports.SUPPORTED_TOKEN_LIST_TEST;
  }
  else {
    return exports.SUPPORTED_TOKEN_LIST_PROD;
  }
}

exports.getSupportedTokenTypes = function(isTest) {
  var list = exports.getSupportedTokenList(isTest);
  var tokens = [];
  for (var i = 0; i < list.length; i++) {
    tokens.push(list[i].symbol)
  }
  return tokens;
}

exports.FAUCET_ADDRESS_PROD = "0x0"; // We don't have a mainnet faucet. Our code will never try to use this.
exports.FAUCET_ADDRESS_TEST = "0x4ccf52796898c8dcda481290e0b2263fca5be78a"; // Rinkeby

exports.getFaucetAddress = function(isTest) {
  if (isTest) {
    return exports.FAUCET_ADDRESS_TEST;
  } 
  else {
    return exports.FAUCET_ADDRESS_PROD;
  }
}

exports.getFaucetTokenAddresses = function(isTest) {
  var tokenList = exports.getSupportedTokenList(isTest);
  var retVal = [];
  for (var i = 0; i < tokenList.length; i++) {
    if (tokenList[i].symbol === "dai") {
      retVal.push(tokenList[i].contract);
    }
    else if (tokenList[i].symbol === "usdc") {
      retVal.push(tokenList[i].contract);
    }
    else if (tokenList[i].symbol === "wbtc") {
      retVal.push(tokenList[i].contract);
    }
  }
  return retVal;
}

exports.BREADCRUMB_LOCATION_LIST = [
  { key: "amsterdam", display: "Amsterdam", level: "location" },
  { key: "berlin", display: "Berlin", level: "location" },
  { key: "buenosaires", display: "Buenos Aires", level: "location" },
  { key: "caracas", display: "Caracas", level: "location" },
  { key: "hongkong", display: "Hong Kong", level: "location" },
  { key: "london", display: "London", level: "location" },
  { key: "losangeles", display: "Los Angeles", level: "location" },
  { key: "newyork", display: "New York", level: "location" },
  { key: "sanjuan", display: "San Juan", level: "location" },
  { key: "seattle", display: "Seattle", level: "location" },
  { key: "sfbayarea", display: "SF Bay Area", level: "location" },
  { key: "singapore", display: "Singapore", level: "location" },
  { key: "telaviv", display: "Tel Aviv", level: "location" },
  { key: "tokyo", display: "Tokyo", level: "location" },
  { key: "toronto", display: "Toronto", level: "location" },
  { key: "vancouver", display: "Vancouver", level: "location" },
  { key: "zug", display: "Zug", level: "location" }
];

exports.BREADCRUMB_SERVICE_CATEGORY_LIST = [
  { key: "ads_marketing", display: "Ads & Marketing Services", level: "category" },
  { key: "automotive", display: "Automotive Services", level: "category" },
  { key: "creative", display: "Creative Services", level: "category" },
  { key: "financial", display: "Financial Services", level: "category" },
  { key: "household", display: "Household Services", level: "category" },
  { key: "labor_move", display: "Labor / Move Services", level: "category" },
  { key: "legal", display: "Legal Services", level: "category" },
  { key: "lessons", display: "Lessons Services", level: "category" },
  { key: "software", display: "Software Services", level: "category" },
  { key: "writing", display: "Writing Services", level: "category" },
  { key: "other", display: "Other Services", level: "category" }
];

exports.BREADCRUMB_ITEM_CATEGORY_LIST = [
  { key: "automotive", display: "Automotive", level: "category" },
  { key: "arts_crafts", display: "Arts & Crafts", level: "category" },
  { key: "books", display: "Books", level: "category" },
  { key: "clothing", display: "Clothing", level: "category" },
  { key: "collectibles", display: "Collectibles", level: "category" },
  { key: "cryptocurrency", display: "Cryptocurrency", level: "category" },
  { key: "domains", display: "Domains", level: "category" },
  { key: "electronics", display: "Electronics", level: "category" },
  { key: "gift_cards", display: "Gift Cards", level: "category" },
  { key: "sport_hobby", display: "Sport & Hobby", level: "category" },
  { key: "tickets", display: "Tickets", level: "category" },
  { key: "tools", display: "Tools", level: "category" },
  { key: "toys_children", display: "Toys & Children", level: "category" },
  { key: "video_gaming", display: "Video Gaming", level: "category" },
  { key: "other", display: "Other Items", level: "category" }
];

exports.BREADCRUMB_RENTAL_CATEGORY_LIST = [
  { key: "clothing", display: "Clothing Rental", level: "category" },
  { key: "housing", display: "Housing Rental", level: "category" },
  { key: "sport_hobby", display: "Sport & Hobby Rental", level: "category" },
  { key: "vehicle", display: "Vehicle Rental", level: "category" },
  { key: "video_gaming", display: "Video Gaming Rental", level: "category" },
  { key: "other", display: "Other Rental", level: "category" }
];

exports.BREADCRUMB_OTHER_CATEGORY_LIST = [
  { key: "other", display: "Other", level: "category" }
];

exports.BREADCRUMB_POSTING_TYPE_LIST = [
  { key: "service_offered", display: "Service offered", level: "type" },
  { key: "service_wanted", display: "Service wanted", level: "type" },
  { key: "item_offered", display: "Item for sale", level: "type" },
  { key: "item_wanted", display: "Item wanted", level: "type" },
  { key: "rental_offered", display: "Rental offered", level: "type" },
  { key: "rental_wanted", display: "Rental wanted", level: "type" },
  //{ key: "other", display: "Other", level: "type" }
];

exports.getKeysFromList = function(list) {
  var keyList = [];

  for (var i = 0; i < list.length; i++) {
    var entry = list[i];
    if (entry.hasOwnProperty("key")) {
      keyList.push(entry.key);
    }
  }

  return keyList;
}

exports.getPostingCategoryList = function(type) {
  if (type === "service_offered" || type === "service_wanted") {
    return exports.deepCopy(exports.BREADCRUMB_SERVICE_CATEGORY_LIST);
  }
  else if (type === "item_offered" || type === "item_wanted") {
    return exports.deepCopy(exports.BREADCRUMB_ITEM_CATEGORY_LIST);
  }
  else if (type === "rental_offered" || type === "rental_wanted") {
    return exports.deepCopy(exports.BREADCRUMB_RENTAL_CATEGORY_LIST);
  }
  /*else if (type === "other") {
    return exports.BREADCRUMB_OTHER_CATEGORY_LIST;
  }*/
  return [];
}

exports.getFullLocationList = function() {
  var combinedLocationList = [];
  combinedLocationList = combinedLocationList.concat(
    [{ key: "global", display: "Global", level: "location" }],
    exports.BREADCRUMB_LOCATION_LIST, 
    [{ key: "other", display: "Other Location", level: "location" }]
  );
  return combinedLocationList;
}

exports.getDisplayHelper = function(list, key) {
  for (var i = 0; i < list.length; i++) {
    if (list[i].key === key) {
      return list[i].display;
    }
  }

  return "Unknown";
}

exports.getBreadcrumbDisplay = function(key, breadLevel) {
  if (breadLevel === "location") {
    if (key === "global") {
      return "Global";
    }
    else if (key === "choose") {
      return "Choose Location";
    }
    else if (key === "other") {
      return "Other";
    }

    return exports.getDisplayHelper(exports.BREADCRUMB_LOCATION_LIST, key);
  }
  else if (breadLevel === "type") {
    if (key === "other") {
      return "Other";
    }

    return exports.getDisplayHelper(exports.BREADCRUMB_POSTING_TYPE_LIST, key);
  }
  else if (breadLevel === "category") {
    if (key === "other") {
      return "Other";
    }

    var combinedCategoryList = [];
    combinedCategoryList = combinedCategoryList.concat(
      exports.BREADCRUMB_SERVICE_CATEGORY_LIST, 
      exports.BREADCRUMB_ITEM_CATEGORY_LIST,
      exports.BREADCRUMB_RENTAL_CATEGORY_LIST
    );

    return exports.getDisplayHelper(combinedCategoryList, key);
  }

  return "";
}

exports.getPostingLocationKeys = function() {
  return exports.getKeysFromList(exports.getFullLocationList());
}

exports.getPostingTypeKeys = function() {
  return exports.getKeysFromList(exports.BREADCRUMB_POSTING_TYPE_LIST);
}

exports.getPostingCategoryKeys = function(type) {
  return exports.getKeysFromList(exports.getPostingCategoryList(type));
}

exports.PAGE_LIST = [
  "home",
  "profile",
  "verify", 
  "incentives", 
  "attributions", 
  "faq",
  "examples", 
  "tos", 
  "view", 
  "people", 
  "new", 
  "edit", 
  "balances",
  "ratings",
  "faucet",
  "contracts",
  "rawcontracts",
  "stats",
  "postings",
  "posting",
  "userpostings",
  "newposting",
  "messages",
  "about",
  "help",
  "how",
  "ethereum"
];

exports.USERNAME_EXCLUDE_LIST = [
  "dashboard",
  "home",
  "account",
  "user",
  "you",
  "none",
  "kleros",
  "help",
  "terms",
  "pay",
  "request",
  "welcome",
  "about",
  "questions",
  "contact",
  "tutorial",
  "balance",
  "example", 
  "incentive", 
  "attribution", 
  "accounts", 
  "mediator", 
  "arbitrator",
  "arbiter",
  "referee",
  "jury",
  "judge",
  "lawyer",
  "fuck",
  "shit",
  "cunt",
  "kike",
  "nigger",
  "nigga",
  "ass",
  "slut",
  "whore",
  "bitch",
  "contacts",
  "contract",
  "marketplace",
  "marketplaces",
  "market",
  "markets",
  "message",
  "postings",
  "posting",
  "debug",
  "login"
];

exports.DIALOG_LIST = [
  "add_attestation",
  "view_attestation",
  "balances",
  "login",
  "confirm",
  "deposit_warning",
  "change_displayname",
  "message",
  "contract_from_posting",
  "add_photo",
  "new_posting",
  "new_contract",
  "photo_preview",
  "debug",
  "testmode",
  "address",
  "versions",
  "report",
  "review",
  "blockchain_resolve",
  "blockchain_deposit",
  "blockchain_arbitrate",
  "access_code",
  "visibility"
];

exports.sleep = function(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

exports.tokenInfo = function(token) {
  var list = exports.getSupportedTokenList(exports.testMode);
  for (var i = 0; i < list.length; i++) {
    var entry = list[i];
    if (entry.symbol === token) {
      return entry;
    }
  }

  return null;
}

exports.powerForToken = function(token) {
  var tokenObj = exports.tokenInfo(token);
  if (tokenObj) {
    return tokenObj.power;
  }
  return "12";
}

exports.decimalsForToken = function(token) {
  return "18";
}

exports.roundBasedOnPower = function(amountStr, powerStr) {
  var dividedStr = exports.divideDecimals(amountStr, powerStr);
  dividedStr = exports.floor(dividedStr);
  var roundedStr = exports.multiplyDecimals(dividedStr, powerStr);
  return roundedStr;
}

exports.floor = function(amountStr) {
  amountStr = amountStr.toString();
  amountStr = amountStr.replace(/\.[0-9]+$/g, '');
  return amountStr;
}

exports.rawValueForToken = function(inputStr, tokenStr) {
  var decimals = exports.decimalsForToken(tokenStr);
  var power = exports.powerForToken(tokenStr);
  var rawStr = exports.multiplyDecimals(inputStr, decimals);
  rawStr = exports.roundBasedOnPower(rawStr, power);
  return rawStr;
}

exports.addressForToken = function(token) {
  var tokenObj = exports.tokenInfo(token);
  if (tokenObj) {
    return tokenObj.contract;
  }

  return "0x0000000000000000000000000000000000000000";
}

exports.displayForToken = function(token) {
  var tokenObj = exports.tokenInfo(token);
  if (tokenObj) {
    return tokenObj.display;
  }

  return "Unknown";
}

exports.getInt = function(str) {
  return parseInt(str, 10) || 0;
}

exports.multiplyDecimals = function(amountStr, decimalsStr) {
  var decimals = parseInt(decimalsStr, 10) || 0;
  amountStr = amountStr.toString();

  // Only keep numbers and dots
  amountStr = amountStr.replace(/[^\d.]/g, '');

  // Find first dot.  Add dot at end if there isn't one.
  var dotIndex = amountStr.indexOf(".");
  if (dotIndex === -1) {
    amountStr += ".0";
    dotIndex = amountStr.indexOf(".");
  }

  // Pad zeros to end of string
  for (var i = 0; i < decimals; i++) {
    amountStr += "0";
  }

  // Remove all dots
  amountStr = amountStr.replace(/\./g, '');

  // Insert new dot at correct position (shifted by decimals)
  var newDotIndex = dotIndex + decimals;
  amountStr = amountStr.slice(0, newDotIndex) + "." + amountStr.slice(newDotIndex);

  // Remove leading zeros
  amountStr = amountStr.replace(/^0+/g, '');

  // Remove dot and everything after
  amountStr = amountStr.replace(/\.[0-9]*$/g, '');

  // If empty string then "0"
  if (amountStr === "") {
    amountStr = "0";
  }

  return amountStr;
}

exports.divideDecimals = function(amountStr, decimalsStr) {
  var decimals = parseInt(decimalsStr, 10) || 0;
  amountStr = amountStr.toString();

  // Remove non-numeric characters
  amountStr = amountStr.replace(/[^\d.]/g, '');
  
  while (amountStr.length <= decimals) {
    amountStr = "0" + amountStr;
  }

  if (decimals >= 0 && amountStr.length > decimals) {
    amountStr = amountStr.substring(0, amountStr.length - decimals) + "." + amountStr.substring(amountStr.length - decimals);
  }

  // Remove leading zeros
  amountStr = amountStr.replace(/^0+([1-9])/g, '$1');

  // Remove trailing zeros
  amountStr = amountStr.replace(/([1-9.])0+$/g, '$1');

  // Remove dot at end
  amountStr = amountStr.replace(/\.$/g, '');

  if (amountStr === "") {
    amountStr = "0";
  }

  return amountStr;
}

exports.lowerCase = function(str) {
  if (str && typeof str === "string") {
    return str.toLowerCase();
  }

  return "";
}

exports.upperCase = function(str) {
  if (str && typeof str === "string") {
    return str.toUpperCase();
  }

  return "";
}

exports.toString = function(val) {
  if (val !== null && val !== undefined) {
    return val.toString();
  }

  return "";
}

exports.trimZeros = function(valStr) {
  // Remove leading zeros
  valStr = valStr.replace(/^0+([1-9])/g, '$1');
  valStr = valStr.replace(/^0+(0\.)/g, '$1');

  var dotIndex = valStr.indexOf(".");
  if (dotIndex !== -1) {
    // Remove trailing zeros if there's a dot
    valStr = valStr.replace(/([1-9.])0+$/g, '$1');

    // Remove dot at end if remaining
    valStr = valStr.replace(/\.$/g, '');
  }

  return valStr;
}

exports.removeExtraDots = function(valStr) {
  var dotIndex = valStr.indexOf(".");
  if (dotIndex !== -1) {
    valStr = valStr.replace(/\./g, '');
    valStr = valStr.slice(0, dotIndex) + "." + valStr.slice(dotIndex);  
  }

  return valStr;
}

exports.getNumberString = function(val) {
  if (val) {
    var valStr = val.toString();
    valStr = exports.removeExtraDots(valStr);

    if ((/^[0-9]+$/i.test(valStr) || /^[0-9]+\.[0-9]+$/i.test(valStr)) && valStr.length < 500) {
      return exports.trimZeros(valStr);
    }
    else if (/^\.[0-9]+$/i.test(valStr) && valStr.length < 500) {
      // String starts with "."
      return exports.trimZeros("0" + valStr);
    }
  }

  return "0";
}

exports.roundFloatBasedOnPower = function(amountStr, typeStr) {
  amountStr = exports.getNumberString(amountStr);
  var decimals = exports.decimalsForToken(typeStr);
  var power = exports.powerForToken(typeStr);
  var amountRaw = exports.multiplyDecimals(amountStr, decimals);
  amountRaw = exports.roundBasedOnPower(amountRaw, power);
  amountStr = exports.divideDecimals(amountRaw, decimals);
  return amountStr;
}

exports.roundFloatPower0 = function(amountStr, typeStr) {
  amountStr = exports.getNumberString(amountStr);
  var decimals = exports.decimalsForToken(typeStr);
  var power = "0";
  var amountRaw = exports.multiplyDecimals(amountStr, decimals);
  amountRaw = exports.roundBasedOnPower(amountRaw, power);
  amountStr = exports.divideDecimals(amountRaw, decimals);
  return amountStr;
}

exports.floatWillLosePrecision = function(amountStr, typeStr) {
  return exports.roundFloatPower0(amountStr, typeStr) !== exports.roundFloatBasedOnPower(amountStr, typeStr);
}

exports.hexToDecBN = function(valStr, debug) {
  if (typeof valStr !== "string") { console.log("ERROR: Invalid hexToDecBN (" + debug + ")")}
  var BN = utils.BN;
  var valBN = new BN(valStr, 16);
  return valBN.toString();
}

exports.decimalToHexBN = function(valStr, debug) {
  if (typeof valStr !== "string") { console.log("ERROR: Invalid decimalToHexBN (" + debug + ") '" + valStr + "'")}
  var BN = utils.BN;
  var valBN = new BN(valStr);

  return valBN.toString(16);
}

exports.greaterThanBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid greaterThanBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  return valBN1.gt(valBN2);
}

exports.lessThanBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid lessThanBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  return valBN1.lt(valBN2);
}

exports.greaterThanEqualBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid greaterThanEqualBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  return valBN1.gte(valBN2);
}

exports.lessThanEqualBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid lessThanEqualBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  return valBN1.lte(valBN2);
}

exports.equalBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid equalBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  return valBN1.eq(valBN2);
}

exports.addBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid addBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  var resultBN = valBN1.add(valBN2);
  return resultBN.toString();
}

exports.subBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid subBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  var resultBN = valBN1.sub(valBN2);
  return resultBN.toString();
}

exports.mulBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid mulBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  var resultBN = valBN1.mul(valBN2);
  return resultBN.toString();
}

exports.divBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid divBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var BN = utils.BN;
  var valBN1 = new BN(valStr1);
  var valBN2 = new BN(valStr2);
  var resultBN = valBN1.div(valBN2);
  return resultBN.toString();
}

exports.addFloatBN = function(valStr1, valStr2, debug) {
  if (typeof valStr1 !== "string" || typeof valStr2 !== "string") { console.log("ERROR: Invalid addFloatBN (" + debug + ") '" + valStr1 + "', '" + valStr2 + "'")}
  var valRaw1 = exports.multiplyDecimals(valStr1, 18);
  var valRaw2 = exports.multiplyDecimals(valStr2, 18);
  var resultRaw = exports.addBN(valRaw1, valRaw2, "addFloatBN");
  var result = exports.divideDecimals(resultRaw, 18);
  return result;
}

exports.readInteger = function(rawVal) {
  if (rawVal.hasOwnProperty("_hex")) {
    rawVal = rawVal._hex;
    rawVal = rawVal.replace("0x", "");
    rawVal = exports.hexToDecBN(rawVal, "readField");
  }

  var val = rawVal ? rawVal.toString() : "0";
  return val;
}

exports.readField = function(dataObj, stateArr, field, type, hasCol, row, col) {
  var rawVal = "";
  if (hasCol) {
    rawVal = stateArr[row][col];
  }
  else {
    rawVal = stateArr[row];
  }

  if (rawVal.hasOwnProperty("_hex")) {
    rawVal = rawVal._hex;
    rawVal = rawVal.replace("0x", "");
    rawVal = exports.hexToDecBN(rawVal, "readField");
  }

  if (type === "boolean") {
    dataObj[field] = rawVal;
  }
  else if (type === "resolution") {
    if (rawVal === exports.NULL_RESOLUTION) {
      dataObj[field] = null;
    }
    else {
      dataObj[field] = rawVal ? rawVal.toString() : "0";
    }
  }
  else if (type === "integer") {
    dataObj[field] = rawVal ? rawVal.toString() : "0";
  }
  else if (type === "timestamp") {
    dataObj[field] = parseInt(rawVal, 10);
  }
  else if (type === "address") {
    dataObj[field] = exports.lowerCase(rawVal);
  }
  else if (type === "bytes") {
    dataObj[field] = rawVal.replace("0x", "");
  }
  else {
    dataObj[field] = rawVal;
  }

  return dataObj;
}

exports.readRawFieldValue = function(rawVal, type) {
  if (rawVal.hasOwnProperty("_hex")) {
    rawVal = rawVal._hex;
    rawVal = rawVal.replace("0x", "");
    rawVal = exports.hexToDecBN(rawVal, "readField");
  }

  var val = "";
  if (type === "boolean") {
    val = rawVal;
  }
  else if (type === "resolution") {
    if (rawVal === exports.NULL_RESOLUTION) {
      val = null;
    }
    else {
      val = rawVal ? rawVal.toString() : "0";
    }
  }
  else if (type === "integer") {
    val = rawVal ? rawVal.toString() : "0";
  }
  else if (type === "timestamp") {
    val = parseInt(rawVal, 10);
  }
  else if (type === "address") {
    val = exports.lowerCase(rawVal);
  }
  else if (type === "bytes") {
    val = rawVal.replace("0x", "");
  }
  else {
    val = rawVal;
  }

  return val;
}

exports.readSha3FromReceipt = function(receipt, deployedid) {
  var sha3 = "";

  if (
    receipt && 
    receipt.status
  ) {
    var parsedReceiptObj = exports.readAgreementCreatedReceipt(receipt);
    if (parsedReceiptObj.blockchainId === deployedid) {
      sha3 = parsedReceiptObj.hashOfAgreement;
    }
  }

  return sha3;
}

exports.readAgreementCreatedReceipt = function(receipt) {
  console.log("readAgreementCreatedReceipt");
  console.log(receipt);
  var blockchainId = "";
  var hashOfAgreement = "";

  if (
    receipt && 
    receipt.status && 
    receipt.hasOwnProperty("transactionHash") && 
    receipt.hasOwnProperty("blockNumber")
  ) {
    if (
      receipt.hasOwnProperty("logs") && 
      receipt.logs.length > 0 && 
      receipt.logs[0].hasOwnProperty("data")
    ) {
      for (var i = 0; i < receipt.logs.length; i++) {
        var logEntry = receipt.logs[i]
        if (logEntry.topics.length === 2 && logEntry.topics[0] === utils.keccak256("AgreementCreated(uint32,bytes32)")) {
          var logData = logEntry.data;
          blockchainId = exports.getFieldFromEventTopics(logEntry.topics, 1, "integer");
          blockchainId = blockchainId.toString();
  
          hashOfAgreement = exports.getFieldFromEventLogData(logData, 0, "hex");
  
          return { transactionHash: receipt.transactionHash, blockchainId: blockchainId, hashOfAgreement: hashOfAgreement, status: true };
        }
      }
    }
    else if (
      receipt.hasOwnProperty("events") &&
      receipt.events.hasOwnProperty("AgreementCreated") &&
      receipt.events.AgreementCreated.hasOwnProperty("returnValues") &&
      receipt.events.AgreementCreated.returnValues.hasOwnProperty("agreementID") &&
      receipt.events.AgreementCreated.returnValues.hasOwnProperty("agreementHash")      
    ) {
      blockchainId = receipt.events.AgreementCreated.returnValues.agreementID;
      hashOfAgreement = receipt.events.AgreementCreated.returnValues.agreementHash;
      return { transactionHash: receipt.transactionHash, blockchainId: blockchainId, hashOfAgreement: hashOfAgreement, status: true };
    }
  }

  return { transactionHash: "", blockchainId: "", hashOfAgreement: "", status: false };
}

exports.getFieldFromEventLogData = function(logData, index, type) {
  var rawValue = logData.substring(2+(64*index), 2+(64*(index+1)));
  if (type === "address") {
    return "0x" + rawValue.substring(24);
  }
  else if (type === "hex") {
    return "0x" + rawValue;
  }
  else if (type === "integer") {
    return parseInt(rawValue, 16);
  }

  return rawValue;
}

exports.getFieldFromEventTopics = function(topic, index, type) {
  var rawValue = topic[index].substring(2, 2 + 64);
  if (type === "address") {
    return "0x" + rawValue.substring(24);
  }
  else if (type === "hex") {
    return "0x" + rawValue;
  }
  else if (type === "integer") {
    return parseInt(rawValue, 16);
  }

  return rawValue;
}

exports.getResolutionAmountsTwoTokens = function(resolutionType, partyAStakeAmount, partyBStakeAmount) {
  var splitArr = resolutionType.split('_');
  if (splitArr.length > 1) {
    var tokenAPayA = splitArr[0];
    var tokenBPayA = splitArr[1];
    var tokenAPayB = exports.subBN(partyAStakeAmount, tokenAPayA, "getResolutionAmountsTwoTokens");
    var tokenBPayB = exports.subBN(partyBStakeAmount, tokenBPayA, "getResolutionAmountsTwoTokens");

    return { tokenAPayA: tokenAPayA, tokenBPayA: tokenBPayA, tokenAPayB: tokenAPayB, tokenBPayB: tokenBPayB };
  }

  return { tokenAPayA: "0", tokenBPayA: "0", tokenAPayB: "0", tokenBPayB: "0" };
}

exports.getResolutionAmountsOneToken = function(resolutionType, partyAStakeAmount, partyBStakeAmount) {
  var combinedStakeAmount = exports.addBN(partyAStakeAmount, partyBStakeAmount, "getResolutionAmountsOneToken");
  var tokenPayA = resolutionType;
  var tokenPayB = exports.subBN(combinedStakeAmount, tokenPayA, "getResolutionAmountsOneToken");
  return { tokenPayA: tokenPayA, tokenPayB: tokenPayB };  
}

exports.timestampAfterNow = function(timestampUnix, timestampNow) {
  if (timestampUnix !== 0 && timestampNow > timestampUnix) {
    return true;
  }

  return false;
}

exports.secondsRemaining = function(timestampUnix, timestampNow) {
  if (timestampUnix !== 0 && timestampUnix > timestampNow) {
    return timestampUnix - timestampNow;
  }

  return 0;
}

exports.getResolutionTypeForETH = function(amount) {
  if (amount === null) {
    return null;
  }

  var type = amount;
  return type;
}

exports.getResolutionTypeForERC20 = function(amountA, amountB, isSingleTokenType) {
  if (amountA === null || amountB === null) {
    return null;
  }

  var type = amountA + "_" + amountB;
  if (isSingleTokenType) {
    type = exports.addBN(amountA, amountB, "getResolutionTypeForERC20");
  }

  return type;
}

exports.resolutionTokenRemainder = function(payAmount, stakeAmount) {
  if (payAmount === null) {
    return null;
  }

  if (exports.greaterThanEqualBN(stakeAmount, payAmount, "resolutionTokenRemainder")) {
    return exports.subBN(stakeAmount, payAmount, "resolutionTokenRemainder");
  }
  
  return "0";
}

exports.processBlockchainData = function(user1Finalized, dataObj, agreedObj, agreedSha3) {
  if (exports.contractInterfaceERC20(dataObj.dataType)) {
    dataObj.resolutionTokenAPayB = exports.resolutionTokenRemainder(dataObj.resolutionTokenAPayA, dataObj.partyAStakeAmount);
    dataObj.resolutionTokenBPayB = exports.resolutionTokenRemainder(dataObj.resolutionTokenBPayA, dataObj.partyBStakeAmount);
    dataObj.partyATokenType = user1Finalized ? agreedObj.amount_type1 : agreedObj.amount_type2;
    dataObj.partyBTokenType = user1Finalized ? agreedObj.amount_type2 : agreedObj.amount_type1;
    dataObj.disputeTokenType = agreedObj.arbitration_fee_type;
    dataObj.isSingleTokenType = (dataObj.partyAToken === dataObj.partyBToken) || dataObj.partyAStakeAmount === "0" || dataObj.partyBStakeAmount === "0";
    if (dataObj.isSingleTokenType) {
      if (dataObj.partyAStakeAmount === "0") {
        dataObj.combinedTokenType = dataObj.partyBTokenType;
      }
      else {
        dataObj.combinedTokenType = dataObj.partyATokenType;
      }

      dataObj.combinedStakeAmount = exports.addBN(dataObj.partyAStakeAmount, dataObj.partyBStakeAmount, "combinedStakeAmount");
      dataObj.partyATokenType = dataObj.combinedTokenType;
      dataObj.partyBTokenType = dataObj.combinedTokenType;
    }

    dataObj.partyAResolutionType = exports.getResolutionTypeForERC20(dataObj.partyAResolutionTokenAPayA, dataObj.partyAResolutionTokenBPayA, dataObj.isSingleTokenType);
    dataObj.partyBResolutionType = exports.getResolutionTypeForERC20(dataObj.partyBResolutionTokenAPayA, dataObj.partyBResolutionTokenBPayA, dataObj.isSingleTokenType);
    dataObj.resolutionType = exports.getResolutionTypeForERC20(dataObj.resolutionTokenAPayA, dataObj.resolutionTokenBPayA, dataObj.isSingleTokenType);
    dataObj.automaticResolutionType = exports.getResolutionTypeForERC20(dataObj.automaticResolutionTokenAPayA, dataObj.automaticResolutionTokenBPayA, dataObj.isSingleTokenType);
  }
  else if (exports.contractInterfaceETH(dataObj.dataType)) {
    dataObj.combinedStakeAmount = exports.addBN(dataObj.partyAStakeAmount, dataObj.partyBStakeAmount, "combinedStakeAmount");
    dataObj.resolutionPayB = exports.resolutionTokenRemainder(dataObj.resolutionPayA, dataObj.combinedStakeAmount);
    dataObj.isSingleTokenType = true;
    dataObj.partyATokenType = "eth";
    dataObj.partyBTokenType = "eth";
    dataObj.combinedTokenType = "eth";
    dataObj.disputeTokenType = "eth";

    dataObj.partyAResolutionType = exports.getResolutionTypeForETH(dataObj.partyAResolutionPayA);
    dataObj.partyBResolutionType = exports.getResolutionTypeForETH(dataObj.partyBResolutionPayA);
    dataObj.resolutionType = exports.getResolutionTypeForETH(dataObj.resolutionPayA);
    dataObj.automaticResolutionType = exports.getResolutionTypeForETH(dataObj.automaticResolutionPayA);
  }

  dataObj.arbitratorResolutionType = null;
  if (dataObj.arbitratorResolved) {
    dataObj.arbitratorResolutionType = dataObj.resolutionType;
  }

  dataObj.isPayment = dataObj.partyAStakeAmount === "0" || dataObj.partyBStakeAmount === "0";
  dataObj.partyBResolvedLast = dataObj.resolutionType !== null && !dataObj.partyAResolvedLast;
  dataObj.allStakeFundsDeposited = dataObj.partyAStakePaid && dataObj.partyBStakePaid && !dataObj.partyAWithdrew && !dataObj.partyBWithdrew;
  dataObj.disputeRequested = dataObj.partyARequestedArbitration || dataObj.partyBRequestedArbitration;
  dataObj.partyACanEarlyWithdraw = dataObj.partyAStakePaid && !dataObj.partyBStakePaid && !dataObj.partyAWithdrew;
  dataObj.partyBCanEarlyWithdraw = dataObj.partyBStakePaid && !dataObj.partyAStakePaid && !dataObj.partyBWithdrew;

  dataObj.partyACanResolve = false;
  dataObj.partyBCanResolve = false;
  dataObj.arbitratorCanResolve = false;
  if (dataObj.allStakeFundsDeposited && !dataObj.isResolved && !dataObj.isCanceled) {
    dataObj.partyACanResolve = true;
    dataObj.partyBCanResolve = true;

    if (dataObj.partyARequestedArbitration && dataObj.partyBRequestedArbitration) {
      dataObj.arbitratorCanResolve = true;
    }
  }

  dataObj.arbitratorDidResolve = dataObj.arbitratorResolved;
  dataObj.arbitratorCanWithdrawFee = false;
  dataObj.partyAWonDisputeFee = false;
  dataObj.partyBWonDisputeFee = false;
  dataObj.disputeFeeOutcomeIsTie = false;      
  dataObj.partyAHasFeeToWithdraw = false;
  dataObj.partyBHasFeeToWithdraw = false;
  dataObj.partyAFeeWithdrawAmount = "0";
  dataObj.partyBFeeWithdrawAmount = "0";

  if (dataObj.arbitratorDidResolve || dataObj.arbitratorWithdrewDisputeFee) {
    // Arbitrator was paid. See who gets refunds.
    dataObj.arbitratorCanWithdrawFee = !dataObj.arbitratorWithdrewDisputeFee && exports.greaterThanBN(dataObj.disputeFee, "0", "disputeFee");

    if (exports.greaterThanBN(dataObj.disputeFee, "0", "disputeFee")) {
      dataObj.partyAWonDisputeFee = !dataObj.partyADisputeFeeLiability && dataObj.partyBDisputeFeeLiability;
      dataObj.partyBWonDisputeFee = dataObj.partyADisputeFeeLiability && !dataObj.partyBDisputeFeeLiability;
      dataObj.disputeFeeOutcomeIsTie = dataObj.partyADisputeFeeLiability && dataObj.partyBDisputeFeeLiability;

      if (dataObj.partyAWonDisputeFee) {
        dataObj.partyAHasFeeToWithdraw = true;
        dataObj.partyBHasFeeToWithdraw = false;
        dataObj.partyAFeeWithdrawAmount = dataObj.disputeFee;
        dataObj.partyBFeeWithdrawAmount = "0";
      }

      if (dataObj.partyBWonDisputeFee) {
        dataObj.partyAHasFeeToWithdraw = false;
        dataObj.partyBHasFeeToWithdraw = true;
        dataObj.partyAFeeWithdrawAmount = "0";
        dataObj.partyBFeeWithdrawAmount = dataObj.disputeFee;
      }

      if (dataObj.disputeFeeOutcomeIsTie) {
        var disputeFeeHalf = exports.divBN(dataObj.disputeFee, "2", "disputeFee");
        dataObj.partyAFeeWithdrawAmount = disputeFeeHalf;
        dataObj.partyBFeeWithdrawAmount = disputeFeeHalf;
        if (exports.greaterThanBN(disputeFeeHalf, "0", "disputeFee")) {
          dataObj.partyAHasFeeToWithdraw = true;
          dataObj.partyBHasFeeToWithdraw = true;
        }
      }
    }
  } else if (dataObj.isResolved) {
    // Arbitrator was not paid, so everyone gets back what they paid in.
    if (exports.greaterThanBN(dataObj.disputeFee, "0", "disputeFee")) {        
      if (dataObj.partyARequestedArbitration) {
        dataObj.partyAHasFeeToWithdraw = true;
        dataObj.partyAFeeWithdrawAmount = dataObj.disputeFee;
      }
      if (dataObj.partyBRequestedArbitration) {
        dataObj.partyBHasFeeToWithdraw = true;
        dataObj.partyBFeeWithdrawAmount = dataObj.disputeFee;
      }
    }
  }

  dataObj.resolutionNothingPartyA = false;
  if (
    (exports.contractInterfaceETH(dataObj.dataType) && dataObj.resolutionPayA === "0") ||
    (exports.contractInterfaceERC20(dataObj.dataType) && dataObj.resolutionTokenAPayA === "0" && dataObj.resolutionTokenBPayA === "0")
  ) {
    dataObj.resolutionNothingPartyA = true;
  }

  dataObj.resolutionNothingPartyB = false;
  if (
    (exports.contractInterfaceETH(dataObj.dataType) && dataObj.resolutionPayB === "0") ||
    (exports.contractInterfaceERC20(dataObj.dataType) && dataObj.resolutionTokenAPayB === "0" && dataObj.resolutionTokenBPayB === "0")
  ) {
    dataObj.resolutionNothingPartyB = true;
  }

  dataObj.partyACanWithdraw = false;
  dataObj.partyBCanWithdraw = false;
  if (dataObj.isResolved) {
    dataObj.partyACanWithdraw = !dataObj.partyAWithdrew && (!dataObj.resolutionNothingPartyA || dataObj.partyAHasFeeToWithdraw);
    dataObj.partyBCanWithdraw = !dataObj.partyBWithdrew && (!dataObj.resolutionNothingPartyB || dataObj.partyBHasFeeToWithdraw);
  }

  dataObj.isDead = false;
  dataObj.isComplete = false;
  if (dataObj.isResolved || dataObj.isCanceled) {
    dataObj.isComplete = true;
    if (dataObj.partyACanWithdraw || dataObj.partyBCanWithdraw || dataObj.arbitratorCanWithdrawFee) {
      //Not dead
    }
    else {
      dataObj.isDead = true;
    }
  }

  var dataIntegrityObj = exports.checkBlockchainDataIntegrity(dataObj, agreedObj, agreedSha3);
  dataObj.hasDataIntegrity = dataIntegrityObj.hasMatch;
  if (!dataIntegrityObj.hasMatch) {
    console.log("Blockchain data integrity failure: " + dataIntegrityObj.reason);
    dataObj.dataIntegrityReason = dataIntegrityObj.reason;
  }

  return dataObj;
}

exports.timeCheckBlockchainData = function(dataObj) {

  dataObj.partyACanRequestDefaultJudgment = false;
  dataObj.partyBCanRequestDefaultJudgment = false;
  dataObj.partyACountdownRequestDefaultJudgment = false;
  dataObj.partyBCountdownRequestDefaultJudgment = false;
  dataObj.timeToRequestDefaultJudgment = 0;
  dataObj.partyACountdownRequestArbitration = false;
  dataObj.partyBCountdownRequestArbitration = false;
  dataObj.timeToRequestArbitration = 0;
  dataObj.canRequestAutomaticResolution = false;
  dataObj.countdownRequestAutomaticResolution = false;
  dataObj.timeToRequestAutomaticResolution = 0;
  dataObj.partyACanRequestArbitration = false;
  dataObj.partyBCanRequestArbitration = false;
  if (dataObj.allStakeFundsDeposited && !dataObj.isResolved && !dataObj.isCanceled) {
    var timestampNow = moment().tz('America/Los_Angeles').unix();
    dataObj.partyACanRequestDefaultJudgment = 
      dataObj.partyARequestedArbitration && !dataObj.partyBRequestedArbitration && 
      exports.timestampAfterNow(dataObj.nextArbitrationStepAllowedAfterTimestamp, timestampNow);

    dataObj.partyBCanRequestDefaultJudgment = 
      dataObj.partyBRequestedArbitration && !dataObj.partyARequestedArbitration && 
      exports.timestampAfterNow(dataObj.nextArbitrationStepAllowedAfterTimestamp, timestampNow);

    dataObj.partyACountdownRequestArbitration = timestampNow < dataObj.nextArbitrationStepAllowedAfterTimestamp && dataObj.partyADidResolve && !dataObj.partyARequestedArbitration && !dataObj.partyBRequestedArbitration;
    dataObj.partyBCountdownRequestArbitration = timestampNow < dataObj.nextArbitrationStepAllowedAfterTimestamp && dataObj.partyBDidResolve && !dataObj.partyARequestedArbitration && !dataObj.partyBRequestedArbitration;
    dataObj.partyACountdownRequestDefaultJudgment = dataObj.nextArbitrationStepAllowedAfterTimestamp !== 0 && dataObj.partyARequestedArbitration && !dataObj.partyBRequestedArbitration && !dataObj.partyACanRequestDefaultJudgment && !dataObj.partyBCanRequestDefaultJudgment;
    dataObj.partyBCountdownRequestDefaultJudgment = dataObj.nextArbitrationStepAllowedAfterTimestamp !== 0 && dataObj.partyBRequestedArbitration && !dataObj.partyARequestedArbitration && !dataObj.partyACanRequestDefaultJudgment && !dataObj.partyBCanRequestDefaultJudgment;
  
    if (dataObj.partyACountdownRequestArbitration || dataObj.partyBCountdownRequestArbitration) {
      dataObj.timeToRequestArbitration = exports.secondsRemaining(dataObj.nextArbitrationStepAllowedAfterTimestamp, timestampNow);
    }

    if (dataObj.partyACountdownRequestDefaultJudgment || dataObj.partyBCountdownRequestDefaultJudgment) {
      dataObj.timeToRequestDefaultJudgment = exports.secondsRemaining(dataObj.nextArbitrationStepAllowedAfterTimestamp, timestampNow);
    }

    dataObj.canRequestAutomaticResolution = exports.timestampAfterNow(dataObj.autoResolveAfterTimestamp, timestampNow) && !dataObj.disputeRequested;
    dataObj.countdownRequestAutomaticResolution = dataObj.autoResolveAfterTimestamp !== 0 && !dataObj.disputeRequested && !dataObj.canRequestAutomaticResolution;
    dataObj.timeToRequestAutomaticResolution = exports.secondsRemaining(dataObj.autoResolveAfterTimestamp, timestampNow);

    // Can only request arbitration after resolving
    if (
      !dataObj.partyARequestedArbitration && 
      dataObj.partyAResolutionType !== null && 
      !dataObj.partyACountdownRequestArbitration && 
      !dataObj.partyBCountdownRequestArbitration
    ) {
      dataObj.partyACanRequestArbitration = true;
    }

    // Can only request arbitration after resolving
    if (
      !dataObj.partyBRequestedArbitration && 
      dataObj.partyBResolutionType !== null && 
      !dataObj.partyACountdownRequestArbitration && 
      !dataObj.partyBCountdownRequestArbitration
    ) {
      dataObj.partyBCanRequestArbitration = true;
    }
  }

  return dataObj;
}

exports.readBlockchainEntry = function(blockchainEntry, transactiontype, agreedObj, agreedSha3, user1Finalized) {
  var dataObj = {};
  if (blockchainEntry && (exports.CONTRACT_TYPE_LIST.includes(transactiontype))) {
    dataObj.dataType = transactiontype;
    dataObj.user1Finalized = user1Finalized;
    dataObj.sha3 = agreedSha3;

    var row = 0;
    var col = 0;
    dataObj = exports.readField(dataObj, blockchainEntry, "partyAAddress", "address", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyBAddress", "address", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "arbitratorAddress", "address", true, row, col++);

    if (exports.contractInterfaceERC20(transactiontype)) {
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAToken", "address", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBToken", "address", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "arbitratorToken", "address", true, row, col++);  
    }
    else if (exports.contractInterfaceETH(transactiontype)) {
      dataObj.partyAToken = "0x0000000000000000000000000000000000000000";
      dataObj.partyBToken = "0x0000000000000000000000000000000000000000";
      dataObj.arbitratorToken = "0x0000000000000000000000000000000000000000";  
    }

    if (exports.contractInterfaceERC20(transactiontype)) {
      row++;
      col = 0;
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAResolutionTokenAPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAResolutionTokenBPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBResolutionTokenAPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBResolutionTokenBPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "resolutionTokenAPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "resolutionTokenBPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "automaticResolutionTokenAPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "automaticResolutionTokenBPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAStakeAmount", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBStakeAmount", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAInitialArbitratorFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBInitialArbitratorFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "disputeFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "nextArbitrationStepAllowedAfterTimestamp", "timestamp", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "autoResolveAfterTimestamp", "timestamp", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "daysToRespondToArbitrationRequest", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyATokenPower", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBTokenPower", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "arbitratorTokenPower", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountDisputePaidPartyA", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountDisputePaidPartyB", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountPaidToArbitrator", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "disputeID", "integer", true, row, col++);          

      dataObj.isResolved = (dataObj.resolutionTokenAPayA === null && dataObj.resolutionTokenBPayA === null) ? false : true;
      dataObj.partyADidResolve = (dataObj.partyAResolutionTokenAPayA === null && dataObj.partyAResolutionTokenBPayA === null) ? false : true;
      dataObj.partyBDidResolve = (dataObj.partyBResolutionTokenAPayA === null && dataObj.partyBResolutionTokenBPayA === null) ? false : true;
    }
    else if (exports.contractInterfaceETH(transactiontype)) {
      row++;
      col = 0;
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAResolutionPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBResolutionPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "resolutionPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "automaticResolutionPayA", "resolution", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAStakeAmount", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBStakeAmount", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyAInitialArbitratorFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "partyBInitialArbitratorFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "disputeFee", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "nextArbitrationStepAllowedAfterTimestamp", "timestamp", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "autoResolveAfterTimestamp", "timestamp", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "daysToRespondToArbitrationRequest", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountDisputePaidPartyA", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountDisputePaidPartyB", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "amountPaidToArbitrator", "integer", true, row, col++);
      dataObj = exports.readField(dataObj, blockchainEntry, "disputeID", "integer", true, row, col++);

      dataObj.isResolved = dataObj.resolutionPayA === null ? false : true;
      dataObj.partyADidResolve = dataObj.partyAResolutionPayA === null ? false : true;
      dataObj.partyBDidResolve = dataObj.partyBResolutionPayA === null ? false : true;

      dataObj.partyATokenPower = 12;
      dataObj.partyBTokenPower = 12;
      dataObj.arbitratorTokenPower = 12;
    }

    row++;
    col = 0;
    dataObj = exports.readField(dataObj, blockchainEntry, "partyAStakePaid", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyBStakePaid", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyARequestedArbitration", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyBRequestedArbitration", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyAWithdrew", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyBWithdrew", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyAResolvedLast", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "arbitratorResolved", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "arbitratorWithdrewDisputeFee", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyADisputeFeeLiability", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "partyBDisputeFeeLiability", "boolean", true, row, col++);
    dataObj = exports.readField(dataObj, blockchainEntry, "disputeCreated", "boolean", true, row, col++);

    dataObj.isCanceled = (dataObj.partyAWithdrew || dataObj.partyBWithdrew) && !dataObj.isResolved;
    dataObj.wasFunded = (dataObj.partyAStakePaid && dataObj.partyBStakePaid);
    if (exports.contractInterfaceERC792(dataObj.dataType)) {
      dataObj.wasArbitratorSummoned = dataObj.disputeCreated;
    } else {
      dataObj.wasArbitratorSummoned = dataObj.partyARequestedArbitration && dataObj.partyBRequestedArbitration;
    }

    dataObj = exports.processBlockchainData(user1Finalized, dataObj, agreedObj, agreedSha3);
  }

  return dataObj;
}


exports.checkMatch = function(matchObj, blockchainStr, agreedStr, msg) {
  if (!matchObj.hasMatch) {
    return matchObj;
  }

  if (blockchainStr !== agreedStr) {
    return {
      hasMatch: false,
      reason: msg + " (" + blockchainStr + " !== " + agreedStr + ")"
    }
  }

  return matchObj;
}

exports.convertPartyToUser = function(dataObj, field, isUser1PartyA) {
  if (dataObj && dataObj.hasOwnProperty(field)) {
    var value = dataObj[field];
    var newField = field;
    if (isUser1PartyA) {
      newField = newField.replace(/partyA/g, 'user1');
      newField = newField.replace(/partyB/g, 'user2');
      newField = newField.replace(/PartyA/g, 'User1');
      newField = newField.replace(/PartyB/g, 'User2');

      newField = newField.replace(/TokenA/g, 'Token1');
      newField = newField.replace(/TokenB/g, 'Token2');
      newField = newField.replace(/PayA/g, 'Pay1');
      newField = newField.replace(/PayB/g, 'Pay2');
    }
    else {
      newField = newField.replace(/partyA/g, 'user2');
      newField = newField.replace(/partyB/g, 'user1');
      newField = newField.replace(/PartyA/g, 'User2');
      newField = newField.replace(/PartyB/g, 'User1');

      newField = newField.replace(/TokenA/g, 'Token2');
      newField = newField.replace(/TokenB/g, 'Token1');
      newField = newField.replace(/PayA/g, 'Pay2');
      newField = newField.replace(/PayB/g, 'Pay1');
    }

    if (dataObj.hasOwnProperty(newField)) {
      console.log("ERROR: Field " + newField + " already exists");
    }
    else {
      dataObj[newField] = value;
    }
  }

  return dataObj;
}

exports.convertAllPartyToUser = function(dataObj, isUser1PartyA) {
  if (dataObj) {
    var fieldList = [];
    var field = "";
    for (field in dataObj) {
      if (
        field && 
        field !== "youArePartyA" && 
        field !== "youArePartyB" && (
        field.includes("partyA") || 
        field.includes("partyB") || 
        field.includes("PartyA") || 
        field.includes("PartyB") || 
        field.includes("TokenA") || 
        field.includes("TokenB") || 
        field.includes("PayA") || 
        field.includes("PayB")
      )) {
        fieldList.push(field);
      }
    }
  
    for (var i = 0; i < fieldList.length; i++) {
      field = fieldList[i];
      dataObj = exports.convertPartyToUser(dataObj, field, isUser1PartyA);
    }
  }

  return dataObj;
}

exports.checkBlockchainDataIntegrity = function(dataObj, agreedObj, agreedSha3) {

  var matchObj = {
    hasMatch: true,
    reason: ""
  }

  var user1Address = dataObj.user1Finalized ? dataObj.partyAAddress : dataObj.partyBAddress;
  var user2Address = dataObj.user1Finalized ? dataObj.partyBAddress : dataObj.partyAAddress;
  var user1StakeAmount = dataObj.user1Finalized ? dataObj.partyAStakeAmount : dataObj.partyBStakeAmount;
  var user2StakeAmount = dataObj.user1Finalized ? dataObj.partyBStakeAmount : dataObj.partyAStakeAmount;
  var user1InitialArbitratorFee = dataObj.user1Finalized ? dataObj.partyAInitialArbitratorFee : dataObj.partyBInitialArbitratorFee;
  var user2InitialArbitratorFee = dataObj.user1Finalized ? dataObj.partyBInitialArbitratorFee : dataObj.partyAInitialArbitratorFee;

  matchObj = exports.checkMatch(
    matchObj, 
    exports.lowerCase(user1Address), 
    exports.lowerCase(agreedObj.user1_address), 
    "User1 address doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.lowerCase(user2Address), 
    exports.lowerCase(agreedObj.user2_address), 
    "User2 address doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.lowerCase(dataObj.arbitratorAddress), 
    exports.lowerCase(agreedObj.arbitrator_address), 
    "Arbitrator address doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(user1StakeAmount), 
    exports.toString(agreedObj.amount_raw1), 
    "User1 stake amount doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(user2StakeAmount), 
    exports.toString(agreedObj.amount_raw2), 
    "User2 stake amount doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(user1InitialArbitratorFee), 
    exports.toString("0"), 
    "User1 initial fee doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(user2InitialArbitratorFee), 
    exports.toString("0"), 
    "User2 initial fee doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(dataObj.disputeFee), 
    exports.toString(agreedObj.arbitration_fee_raw), 
    "Dispute fee doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(dataObj.disputeFee), 
    exports.toString(agreedObj.arbitration_fee_raw), 
    "Dispute fee doesn't match"
  );

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(dataObj.daysToRespondToArbitrationRequest), 
    exports.toString(agreedObj.arbitration_days), 
    "Arbitration days doesn't match"
  );

  if (!dataObj.disputeRequested) {
    matchObj = exports.checkMatch(
      matchObj, 
      exports.toString(dataObj.nextArbitrationStepAllowedAfterTimestamp), 
      exports.toString(agreedObj.arbitration_request_unix), 
      "Arbitration request unix timestamp fee doesn't match"
    );
  }

  matchObj = exports.checkMatch(
    matchObj, 
    exports.toString(dataObj.autoResolveAfterTimestamp), 
    exports.toString(agreedObj.auto_resolve_unix), 
    "Auto resolve unix timestamp doesn't match"
  );
  
  if (exports.contractInterfaceETH(dataObj.dataType)) {
    var agreedAutomaticResolutionPayA = "";
    if (dataObj.user1Finalized) {
      agreedAutomaticResolutionPayA = exports.addBN(exports.toString(agreedObj.default_resolution_type1_give1), exports.toString(agreedObj.default_resolution_type2_give1), "");

    }
    else {
      agreedAutomaticResolutionPayA = exports.addBN(exports.toString(agreedObj.default_resolution_type1_give2), exports.toString(agreedObj.default_resolution_type2_give2), "");
    }

    matchObj = exports.checkMatch(
      matchObj, 
      exports.toString(dataObj.automaticResolutionPayA), 
      agreedAutomaticResolutionPayA, 
      "Automatic resolution doesn't match"
    );
  }
  else if (exports.contractInterfaceERC20(dataObj.dataType)) {
    var agreedAutomaticResolutionTokenAPayA = "";
    var agreedAutomaticResolutionTokenBPayA = "";
    if (dataObj.user1Finalized) {
      agreedAutomaticResolutionTokenAPayA = exports.toString(agreedObj.default_resolution_type1_give1);
      agreedAutomaticResolutionTokenBPayA = exports.toString(agreedObj.default_resolution_type2_give1);
    }
    else {
      agreedAutomaticResolutionTokenAPayA = exports.toString(agreedObj.default_resolution_type2_give2);
      agreedAutomaticResolutionTokenBPayA = exports.toString(agreedObj.default_resolution_type1_give2);
    }

    matchObj = exports.checkMatch(
      matchObj, 
      exports.toString(dataObj.automaticResolutionTokenAPayA), 
      agreedAutomaticResolutionTokenAPayA, 
      "Automatic resolution doesn't match"
    );

    matchObj = exports.checkMatch(
      matchObj, 
      exports.toString(dataObj.automaticResolutionTokenBPayA), 
      agreedAutomaticResolutionTokenBPayA, 
      "Automatic resolution doesn't match"
    );
  }
  else {
    matchObj = exports.checkMatch(
      matchObj, 
      dataObj.dataType, 
      "", 
      "Invalid contract type"
    );
  }

  if (dataObj.sha3 === "") {
    matchObj = exports.checkMatch(
      matchObj, 
      "", 
      "EMPTY_HASH_NOT_ALLOWED", 
      "Hash blockchain sha3 is empty"
    );
  }

  if (agreedSha3 === "") {
    matchObj = exports.checkMatch(
      matchObj, 
      "", 
      "EMPTY_HASH_NOT_ALLOWED", 
      "Hash agreedSha3 is empty"
    );
  }

  matchObj = exports.checkMatch(
    matchObj, 
    dataObj.sha3, 
    agreedSha3, 
    "Hashes don't match"
  );
  
  return matchObj;
}

exports.splitAttestationId = function(attestationid) {
  var splitArr = attestationid.split('#');
  var attestation_type = "";
  var attestation_value = "";
  if (splitArr.length > 1) {
    attestation_type = splitArr[0];
    attestation_value = splitArr[1];
  }

  return { attestation_type: attestation_type, attestation_value: attestation_value };
}

exports.firstLetterUpperCase = function(str) {
  if (str.length > 0) {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  }
  
  return str;
}

// returns a string with the last token removed
exports.trimLastToken = function(str) {
  var lastIndex = str.lastIndexOf(" ");
  return lastIndex > 0 ? str.substring(0, lastIndex) : str;
}


exports.visibilityText = function(visibility) {
  if (visibility === "public") {
    return "Public";
  }
  else if (visibility === "discreet") {
    return "Ratings only";
  }
  else if (visibility === "private") {
    return "Private";
  }

  return "";
}

exports.initTrace = function(t, d) {
  return [{t:t, d:d}];
}

exports.addTrace = function(traceArr, t, d) {
  traceArr.push({t:t, d:d});
  return traceArr;
}

// Different from arbitration settings because they relate specifically to the arbitrator.
exports.defaultArbitratorSettingsProd = function() {
  return {
    fee_value: "0.005",
    fee_type: "eth",
    auto_status: "agreed"
  };
}
exports.defaultArbitratorSettingsTest = function() {
  return {
    fee_value: "0.0001",
    fee_type: "eth",
    auto_status: "agreed"
  };
}

exports.getArbitrationSettings = function(userObj) {
  if (
    userObj && 
    userObj.hasOwnProperty("arbitration_settings_test") && 
    userObj.hasOwnProperty("arbitration_settings_prod")
  ) {
    if (exports.testMode) {
      return userObj.arbitration_settings_test;
    }
    else {
      return userObj.arbitration_settings_prod;
    }      
  }

  return {};
}

exports.readAutoStatus = function(settingsObj) {
  if (settingsObj && settingsObj.hasOwnProperty("auto_status")) {
    return settingsObj.auto_status;
  } 
  return "";
}

// Non-arbitrator specific arbitration settings for payments
exports.defaultArbitrationSettings = function(agreementType) {
  if (agreementType === "payment") {
    return {
      arbitration_response_days: 15,
      auto_resolve_days: 30,
      arbitration_request_days: 0, // any time
      default_resolution: "all_user2"
    };
  } else if (agreementType === "request") {
    return {
      arbitration_response_days: 15,
      auto_resolve_days: 30,
      arbitration_request_days: 0, // any time
      default_resolution: "all_user1"
    };
  } else { // must be contract
      return {
        arbitration_response_days: 30,
        auto_resolve_days: 0, // DO NOT CHANGE THIS FROM 0. Our 'new agreement' UI can't handle it
        arbitration_request_days: 0, // any time
        default_resolution: "refund" // won't be used if auto_resolve_days is 0
    };
  }
}

exports.resolutionDisplay = function(res, user1Disp, user2Disp) {
  if (res === "all_user2") {
    return "All funds to " + user2Disp;
  }
  else if (res === "all_user1") {
    return "All funds to " + user1Disp;
  }
  else if (res === "refund") {
    return "Refund deposits";
  }
  else if (res === "trade") {
    return "Swap deposits";
  }
  else if (res === "fifty") {
    return "Split all funds 50-50";
  }
  else {
    return "Custom";
  }
}

exports.processContractTemplate = function(template, contract_details, rep) {
  var terms = "";
  if (template === "custom") {
    terms = contract_details;
  }
  else {
    terms = exports.getTermsForTemplate(template);
  }

  var fieldList = exports.templateProcessor(terms, rep);
  var response = "";
  for (var i = 0; i < fieldList.length; i++) {
    response += fieldList[i].value; 
  }

  return  response;
}

exports.convertMarkup = function(terms, isOffered) {

  if (isOffered) {
    terms = terms.replace(/\[Buyer\]/g, "[Counterparty]");
    terms = terms.replace(/\[buyer\]/g, "[counterparty]");
    terms = terms.replace(/\[Seller\]/g, "[Poster]");
    terms = terms.replace(/\[seller\]/g, "[poster]");
  }
  else {
    terms = terms.replace(/\[Buyer\]/g, "[Poster]");
    terms = terms.replace(/\[buyer\]/g, "[poster]");
    terms = terms.replace(/\[Seller\]/g, "[Counterparty]");
    terms = terms.replace(/\[seller\]/g, "[counterparty]");  
  }

  return terms;
}

exports.getTermsForTemplate = function(template) {
  if (template === "service_offered" || template === "service_wanted") {
    return exports.convertMarkup(
`TIMEFRAME:
If [seller] does not meet a deadline described in the posting copied below, [buyer] is entitled to a full refund.

AS DESCRIBED:
If the service provided had any significant difference in any relevant aspect from the service described and depicted in the posting copied below, and if this difference plausibly made [buyer] significantly worse off than if the service had been provided as described, then a full refund will be issued. 

TERMS NOT SPECIFIED:
In any scenario not covered explicitly by these terms, the arbitrator will rule in the manner that they deem to be most fair in light of these terms.

PRECEDENCE:
If any of these terms conflict with what is described or depicted in the posting copied below, the posting should take precedence.

[separator]

POSTING DETAILS:
Category:
[category]

Posted by:
[poster]

Title:
[title]

Location details:
[location]

Description:
[description]

Deadline:
[deadline]

[separator]

PHOTOS:
[photos]`, template === "service_offered");
  }
  else if (template === "item_offered" || template === "item_wanted") {
    return exports.convertMarkup(
`TIMEFRAME:
If [seller] does not meet a deadline described in the posting copied below, [buyer] may opt to a receive a full refund. If [buyer] opts to receive a refund for this reason, [seller] is entitled to have the item returned. See the RETURNS section for details.

AS DESCRIBED:
If the item has any significant difference in any relevant aspects from the item described and depicted in the posting copied below or is in significantly worse condition, then a full refund will be issued and [seller] is entitled to have the item returned. See the RETURNS section for details.

RETURNS:
If [seller] is entitled to have the item returned then [seller] is responsible for providing for all expenses related to the item's return, including providing return shipping labels.

TERMS NOT SPECIFIED:
In any scenario not covered explicitly by these terms, the arbitrator will rule in the manner that they deem to be most fair in light of these terms.

PRECEDENCE:
If any of these terms conflict with what is described or depicted in the posting copied below, the posting should take precedence.

[separator]

POSTING DETAILS:
Category:
[category]

Posted by:
[poster]

Title:
[title]

Location details:
[location]

Description:
[description]

Deadline:
[deadline]

[separator]

PHOTOS:
[photos]`, template === "item_offered");
  }
  else if (template === "rental_offered" || template === "rental_wanted") {
    return exports.convertMarkup(
`TIMEFRAME:
If [seller] does not meet a deadline described in the posting copied below, or if the rental item is not available for the entire agreed upon rental period, then [buyer] is entitled to a full refund.

AS DESCRIBED:
If the rental item provided by [seller] had any significant difference in any relevant aspect from the item described and depicted in the posting copied below, and if this difference plausibly made [buyer] significantly worse off than if the item had been provided as described, then a full refund will be issued. 

TERMS NOT SPECIFIED:
In any scenario not covered explicitly by these terms, the arbitrator will rule in the manner that they deem to be most fair in light of these terms.

PRECEDENCE:
If any of these terms conflict with what is described or depicted in the posting copied below, the posting should take precedence.

[separator]

POSTING DETAILS:
Category:
[category]

Posted by:
[poster]

Title:
[title]

Location details:
[location]

Description:
[description]

Deadline:
[deadline]

[separator]

PHOTOS:
[photos]`, template === "rental_offered");
  } else if (template === "other") {
    return exports.convertMarkup(
`TIMEFRAME:
If either party does not meet a deadline described in the posting copied below, the other party is entitled to a full refund.

AS DESCRIBED:
If either party's performance of the terms of the contract has any significant difference in any relevant aspect from what was described and depicted in the posting copied below, and if this difference plausibly made the other party significantly worse off than if the service had been provided as described, then a full refund will be issued. 

TERMS NOT SPECIFIED:
In any scenario not covered explicitly by these terms, the arbitrator will rule in the manner that they deem to be most fair in light of these terms.

PRECEDENCE:
If any of these terms conflict with what is described or depicted in the posting copied below, the posting should take precedence.

[separator]

POSTING DETAILS:
Category:
[category]

Posted by:
[poster]

Title:
[title]

Location details:
[location]

Description:
[description]

Deadline:
[deadline]

[separator]

PHOTOS:
[photos]`, template === "other");
  }

  return "";
}

exports.adjustCasing = function(str, firstLetterUC) {
  if (firstLetterUC) {
    return exports.firstLetterUpperCase(str);
  }
  else {
    return str;
  }
}

exports.templateProcessor = function(terms, rep) {
  var termsArr = terms.split(/(\[[a-zA-Z0-9_]+\]|\n|\r)/);
  var fieldList = [];
  for (var i = 0; i < termsArr.length; i++) {
    var entry = termsArr[i];
    var contentElem = entry;
    var showMarkup = false;
    var isInfo = false;
    var entryLower = exports.lowerCase(entry);
    var markupTag = entry.length > 2 ? entry.substring(1, entry.length-1) : "";
    var firstLetterUC = markupTag === exports.firstLetterUpperCase(exports.lowerCase(markupTag));

    if (entryLower === "" || entryLower === "\r") {
      // skip
    }
    else {
      if (entryLower === "[postingid]") {
        contentElem = rep.postingid;
      }
      if (entryLower === "[category]") {
        contentElem = rep.breadcrumb;
        isInfo = true;
      }
      else if (entryLower === "\n" || entryLower === "[break]" || entryLower === "[br]") {
        contentElem = rep.break;
      }
      else if (entryLower === "[poster]") {
        if (rep.userPoster !== "") {
          contentElem = exports.adjustCasing(rep.userPoster, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[counterparty]") {
        if (rep.userTaker !== "") {
          contentElem = exports.adjustCasing(rep.userTaker, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[title]") {
        if (rep.title !== "") {
          contentElem = exports.adjustCasing(rep.title, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[description]") {
        if (rep.description !== "") {
          contentElem = exports.adjustCasing(rep.description, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[photos]") {
        if (rep.photos !== "") {
          contentElem = rep.photos;
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[separator]" || entryLower === "[sep]") {
        contentElem = rep.separator;
      }
      else if (entryLower === "[bullet]" || entryLower === "[bul]") {
        contentElem = rep.bullet;
      }
      else if (entryLower === "[price]") {
        if (rep.price !== "") {
          contentElem = rep.price;
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[deposit]") {
        if (rep.deposit !== "") {
          contentElem = rep.deposit;
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[location]") {
        if (rep.location !== "") {
          contentElem = exports.adjustCasing(rep.location, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }
      else if (entryLower === "[deadline]") {
        if (rep.deadline !== "") {
          contentElem = exports.adjustCasing(rep.deadline, firstLetterUC);
          isInfo = true;
        }
        else {
          showMarkup = true;
        }
      }

      if (showMarkup) {
        fieldList.push({type: "markup", value: contentElem});
      }
      else if (isInfo) {
        fieldList.push({type: "info", value: contentElem});
      }
      else {
        fieldList.push({type: "text", value: contentElem});
      }
    }
  }

  return fieldList;
}

exports.isEmail = function(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

exports.daysToSeconds = function(days) {
  return exports.getInt(days) * (24 * 3600);
}