import React, { Component } from 'react';
import './CommentCard.css';
import recordImg from '../record.png';
import blockchainImg from '../blockchain.png';
import Link from './Link';
import { getAccountLink } from './ActionUtils';
//import store from '../store';

class CommentCard extends Component {
  constructor(props) {
    super(props);

    this.handleEvidenceClick = this.handleEvidenceClick.bind(this);
  }

  handleEvidenceClick(event) {
    //console.log("handleEvidenceClick");
    let evidenceHash = this.props.evidenceHash;
    //console.log(evidenceHash);
    this.props.onEvidenceClick(evidenceHash);
  }

  render() {

    let isEvidenceSaved = false;
    let evidenceHash = this.props.evidenceHash;
    let evidenceArr = this.props.evidenceArr;
    let evidenceLink = "";
    if (evidenceArr) {
      for (let i = 0; i < evidenceArr.length; i++) {
        let evidenceURI = evidenceArr[i];
        let evidenceURIHash = evidenceURI.replace(/^.*\//g, '');
        evidenceURIHash = evidenceURIHash.replace(/\?.*$/, '');
        //store.dispatch({
        //  type: 'LOG_ERROR',
        //  payload: {info:"evidence", data:JSON.stringify({evidenceHash, evidenceURIHash})}
        //});

        if (evidenceHash === evidenceURIHash) {
          isEvidenceSaved = true;
          evidenceLink = evidenceURI;
        }
      }
    }

    let enableEvidenceLink = this.props.enableEvidenceLink;

    let commentUserLink = this.props.commentUser;
    if (this.props.commentUser !== "") {
      commentUserLink = <Link className="blacklink" noTarget={true} rel="noopener noreferrer" href={getAccountLink(this.props.commentUser)}>{this.props.commentUser}</Link>;
    }

    let commentContent = this.props.comment || "";

    return (
      <div className="commentCard">
        {enableEvidenceLink && !isEvidenceSaved && <img onClick={this.handleEvidenceClick} className="commentCornerImg" src={recordImg} title="Record on blockchain" alt="Record on blockchain" />}
        {isEvidenceSaved && evidenceLink !== "" && <Link href={evidenceLink} target="_blank" rel="noopener noreferrer"><img className="commentCornerImg" src={blockchainImg} title="Saved to blockchain" alt="Saved to blockchain" /></Link>}
        <div className=""><span className="commentUsername">{commentUserLink}</span><span className="commentTimestamp">{this.props.timestamp}</span></div>
        <div className="commentContent">
          {commentContent.split('\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
          })}
        </div>
      </div>
    );
  }
}
  
export default CommentCard;