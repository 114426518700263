import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Stats.css';
import './Common.css';
import GapCard from "./GapCard";
import TitleCard from "./TitleCard";
import LoadCard from './LoadCard';
import { apiRequest } from "./ActionUtils";

class Stats extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.loadStats = this.loadStats.bind(this);
  }
  
  componentDidMount() {
    this.loadStats();
  }

  loadStats() {
    let { user="", exclude_test="0" } = this.props.menu.queryParams;
    let { uuid="", username="" } = this.props.auth; 
    let timestamp = "1568808000";
    apiRequest("statistics", {uuid, username, timestamp, user, exclude_test}).then((response) => {
      let success = response.status === "success";
      if (success && response.data) {
        this.props.dispatch({
          type: "STATS_INIT",
          payload: response.data
        });
      }
    });
  }
  
  render() {
    let { user="" } = this.props.menu.queryParams;
    let data = this.props.stats.data;
    let dataArr = data || [];

    let usersElem = [];
    for (let i = 0; i < dataArr.length; i++) {
      let obj = dataArr[i];
      usersElem.push(<div className="statRow" key={i}><span className="statLabel">{obj.label}:</span> <span className="statValue">{obj.value}</span></div>);
    }

    return (
      <div className="contentSection">
        <GapCard />
        <TitleCard title={user === "" ? "Statistics page" : "Statistics page for " + user} /> 
        {data ? (
          <div className="contentCard">
            {usersElem}
          </div>
        ) : (
          <LoadCard key="load" />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    stats: state.stats
  }
}

const StatsContainer = connect(mapStateToProps, null)(Stats);
export default StatsContainer;