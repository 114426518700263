import update from 'immutability-helper';

const emptyState = {
  agreementList: [], 
  agreementsLoaded: false,
  dashboardFilter: "",
  offset: "0",
  count: "50",
  needUserDataRefresh: false
}

const DashboardReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;
      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');

      let dashboardFilter = "";
      if (query && Object.prototype.hasOwnProperty.call(query, "filter")) {
        dashboardFilter = query.filter;
      }

      let offset = "0";
      if (query && Object.prototype.hasOwnProperty.call(query, "offset")) {
        offset = query.offset;
      }

      let count = "50";
      if (query && Object.prototype.hasOwnProperty.call(query, "count")) {
        count = query.count;
      }

      if (newSelectedPage === "contracts") {
        return {...state, 
          agreementList: [],
          agreementsLoaded: false,
          dashboardFilter,
          offset,
          count
        };
      }

      // Empty everything if page not selected
      return {};
    }
    case "DASHBOARD_AGREEMENT_LIST" : {
      let { list=[] } = action.payload;
      return {...state, agreementList: list, agreementsLoaded: true };
    }
    case "AGREEMENTCARD_HIDDEN" : {
      let { agreementid="", username="", hidden=false, pending=false } = action.payload;

      if (state.hasOwnProperty("agreementList")) {
        let doUpdate = false;
        let updatePos = 0;
        let updateObj = {};
        for (let i = 0; i < state.agreementList.length; i++) {
          let agreementObj = state.agreementList[i];
          if (agreementObj.agreementid === agreementid && agreementObj.hasOwnProperty("agreement")) {
            updatePos = i;
            updateObj = JSON.parse(JSON.stringify(agreementObj));
            doUpdate = true;
          }
        }
  
        let newAgreementList = state.agreementList;
  
        if (doUpdate) {
          updateObj.agreement.hidden_pending = pending;
  
          if (hidden) {
            updateObj.agreement.hidden_user1 = "hidden";
            updateObj.agreement.hidden_user2 = "hidden";
            updateObj.agreement.hidden_arbitrator_map = {[username]: "hidden"};
          }
          else {
            updateObj.agreement.hidden_user1 = "";
            updateObj.agreement.hidden_user2 = "";
            updateObj.agreement.hidden_arbitrator_map = {[username]: ""};
          }
  
          newAgreementList = update(
            state.agreementList || [], 
            {[updatePos] : {$set: updateObj}}
          );
        }
  
        return {...state, agreementList: newAgreementList };
      }

      return {...state};
    }
    case "DASHBOARD_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;

      if (name === "dashboardFilter") {
        return {...state, [name]: value, agreementsLoaded: false};
      }

      return {...state, [name]: value};
    }
    case "CLOSE_OVERLAY" : {
      let data = action.payload;
      // Refresh linked accounts when overlay closes only if there was an update
      if (data && data.profileDataUpdate) {
        return {...state, needUserDataRefresh: true };
      }
      return state;
    }
    case "PROFILE_DATA" : {
      return {...state, needUserDataRefresh: false };
    }
    default: {
      return state
    }
  }
}

export default DashboardReducer