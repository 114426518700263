import React, { Component } from 'react';
import './Common.css';
import './Dropdown.css';

class Dropdown extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleToggleClick = this.handleToggleClick.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.handleKeyboard = this.handleKeyboard.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.state = { focused: false, open: false, keyboardItem: 0 };
        this.node = null;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick, false);
        document.addEventListener("keydown", this.handleKeyboard, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick, false);
        document.removeEventListener("keydown", this.handleKeyboard, false);
    }

    handleBlur() {
      this.setState({focused: false});
    }

    handleFocus() {
      this.setState({focused: true});
    }

    handleKeyboard(event){
      if (this.state.open === true || this.state.focused === true) {

        let count = Math.max(1, this.props.list.length);
        let keyboardItem = this.state.keyboardItem;

        if(event.keyCode === 27) {
          this.setState({open: false});
          event.preventDefault();
        }
        else if(event.keyCode === 38 || event.keyCode === 37) {
          keyboardItem = (keyboardItem + count - 1) % count;
          this.setState({open: true, keyboardItem: keyboardItem});
          event.preventDefault();
        }
        else if(event.keyCode === 40 || event.keyCode === 39) {
          keyboardItem = (keyboardItem + 1) % count;
          this.setState({open: true, keyboardItem: keyboardItem});
          event.preventDefault();
        }
        else if(event.keyCode === 32 || event.keyCode === 13) {
          if (this.state.open === false) {
            this.setState({open: true});
          }
          else {
            let entryList = this.props.list;
            if(keyboardItem < entryList.length) {
              let selectedName = entryList[keyboardItem].name;
              this.props.onChange(this.props.name, selectedName);
              this.setState({open: false});
            }  
          }
          event.preventDefault();
        }
      }
    }

    handleOutsideClick(event) {
      if(this.node.contains(event.target)) {
        return;
      }

      this.setState({open: false});
    }

    handleItemClick(event) {
      event.preventDefault();
      this.props.onChange(this.props.name, event.currentTarget.getAttribute("name"));
      this.setState({open: false});
    }

    handleToggleClick(event) {

        let initialKeyboardItem = 0;
        let selectedName = this.props.selected;
        let entryList = this.props.list;

        for(let i=0; i<entryList.length; i++) {
            if(entryList[i].name === selectedName) {
                initialKeyboardItem = i;
            }
        }

        let open = this.state.open || false;
        this.setState({open: !open, keyboardItem: initialKeyboardItem});
    }

    render() {  

        let disabled = this.props.disabled || false;
        let open = this.state.open || false;
        let focused = this.state.focused || false;
        let entryList = this.props.list;

        let selectedEntry = entryList[0];
        let selectedName = this.props.selected;
        for(let i=0; i<entryList.length; i++) {
            if(entryList[i].name === selectedName) {
                selectedEntry = entryList[i];
            }
        }

        let isSmall = this.props.hasOwnProperty("size") && this.props.size === "small";
        let isMedium = this.props.hasOwnProperty("size") && this.props.size === "medium";
        let dropdownCntClass = isSmall ? "dropdownCntSmall" : (isMedium ? "dropdownCntMedium" : "dropdownCnt");

        if (disabled) {
          return (
            <div ref={node => this.node = node} className={dropdownCntClass}>
              <div className="dropdownTitle disabled">{selectedEntry.text} <div className="dropdownArrow disabled">&#x25BC;</div></div>
            </div>
          );
        }
        
        return (
          <div tabIndex={0} onBlur={this.handleBlur} onFocus={this.handleFocus} ref={node => this.node = node} className={dropdownCntClass}>
                <div onClick={this.handleToggleClick} className={"dropdownTitle" + (open || focused ? " dropdownTitleOpen" : "")}>{selectedEntry.text} <div className="dropdownArrow">&#x25BC;</div></div>
                <table className={"dropdownMenu" + (open ? "" : " hide")}><tbody>
                  {
                    entryList.map((entry, index) => {
                        return (
                          <tr key={index}>
                            <td name={entry.name} onClick={this.handleItemClick} className={"dropdownItem" + (index === this.state.keyboardItem ? " dropdownItemKeyboard" : "")}>{entry.text}</td>
                          </tr>
                        );
                    })
                  }
                </tbody></table>
            </div>
        );
    }
  }
  
  export default Dropdown;
