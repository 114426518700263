//import update from 'immutability-helper';

const emptyState = {
  emailSubmitMessage: "",
  emailAddress: "",
  sectionsList: [],
  sectionsLoaded: false,
  searchBox: ""
}

const HomePageReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "HOMEPAGE_CHANGE_INPUT_VALUE": {
      let { name, value } = action.payload;
      return {...state, [name]: value };
    }
    case "HOMEPAGE_SECTIONS_LIST": {
      let data = JSON.parse(JSON.stringify(action.payload));
      return {...state, sectionsList: data, sectionsLoaded: true};
    }
    case "HOMEPAGE_SUBMIT_EMAIL": {
      return {...state, emailSubmitMessage: action.payload};
    }
    default: {
      return state
    }
  }
}

export default HomePageReducer