
import { apiUserAccountData, apiFavorite } from "./ActionUtils";
import store from '../store';

export function refreshProfileData(user) {
  return function(dispatch) {
    dispatch({
      type: 'PROFILE_SELECT_USER',
      payload: user
    });

    let auth = store.getState().auth;
    apiUserAccountData(auth.uuid, auth.username, user).then((response) => {
      if (response.status === "success") {
        let data = response.data;

        dispatch({
          type: 'PROFILE_DATA',
          payload: data
        });
      }
    });
  }
}

export function favoriteSelect(contact, select) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch({
      type: 'PROFILE_FAVORITE',
      payload: {contact, favorite: select, pending:true}
    });
    apiFavorite(auth.uuid, auth.username, contact, select).then((success) => {
      if (success) {
        dispatch({
          type: 'PROFILE_FAVORITE',
          payload: {contact, favorite: select, pending:false}
        });
      }
      else {
        dispatch({
          type: 'PROFILE_FAVORITE',
          payload: {contact, favorite: !select, pending:false}
        });
      }
    });
  }
}