import React, { Component } from 'react';
import './TitleCard.css';

class TitleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let onClick = this.props.hasOwnProperty("onClick") ? this.props.onClick : null;
    
    let titleElem = this.props.title;
    if (onClick !== null) {
      titleElem = <span onClick={onClick} className="titleHover">{this.props.title}</span>;
    }

    if (titleElem === "" || titleElem === null) {
      titleElem = <span>&nbsp;</span>;
    }

    return (
      <div className="titleCard">{titleElem}</div>
    );
  }
}
  
export default TitleCard;