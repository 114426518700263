import React, { Component } from 'react';
import './Common.css';
import './InputFile.css';

class InputFile extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
        this.handleDisabledClick = this.handleDisabledClick.bind(this);
    }

    handleChange(event) {
      if (this.props.hasOwnProperty("onChange") && event.target.files && event.target.files.length > 0) {
        let value = event.target.files[0];
        this.props.onChange(event.currentTarget.getAttribute("name"), value);
      }
    }

    handleDisabledClick(event) {
      let disabledMsg = this.props.disabledMsg ? this.props.disabledMsg : "File upload is disabled.";
      alert(disabledMsg);
    }

    render() {  
        let disabled = this.props.disabled ? true : false;
        let value = this.props.value ? this.props.value : "";
        let className = this.props.className ? "bluelink " + this.props.className : "bluelink";
        if (disabled) {
          return (
            <span onClick={this.handleDisabledClick} className={className + " disabled"}>Upload photo</span>
          );
        }

        return (
          <span><input type="file" accept="image/*" name={this.props.name} id="file" className="hide" value={value} onChange={this.handleChange} /><label className={className} htmlFor="file">Upload photo</label></span>
        );
    }
  }
  
  export default InputFile;
