import { connect } from 'react-redux';
import React, { Component } from 'react'
import imageTwitterLogoBlack from '../logo-twitter-black.png';
import imageTelegramLogoBlack from '../logo-telegram-black.png';
//import imageMediumLogoBlack from '../logo-medium-black.png';
import imageFacebookLogoBlack from '../logo-facebook-black.png';
import imageRedditLogoBlack from '../logo-reddit-black.png';
import imageEmailLogoBlack from '../logo-email-black.png';
import { getIsMobile } from './ActionUtils';
import Link from './Link';
import './Footer.css';
import { setFooterHeight } from './AuthActions';

/*
 * Create component.
 */

class Footer extends Component {


  constructor(props, context) {
    super(props)
    this.footerNode = null;
  }

  componentDidMount() {
    const height = this.footerNode ? this.footerNode.clientHeight : 0;
    this.props.dispatch(setFooterHeight(height));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width) {
      const height = this.footerNode ? this.footerNode.clientHeight : 0;
      this.props.dispatch(setFooterHeight(height));  
    }
  }

  render() {

    let messageBar = this.props.messageBar || null;
    let isMobile = getIsMobile();
    let socialMediaImageCSS = isMobile ? "socialMediaImgSmall" : "socialMediaImgLarge"; 
    let socialMediaSpacingCSS = isMobile ? "moveRight15 moveLeft15 moveTop20" : "moveRight20 moveLeft20 moveTop20"; 

    return (
      <div>
        <div ref={footerNode => this.footerNode = footerNode} className="footer">
          <div className="footerIcons">
            <div className="">
              <Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="https://twitter.com/atstakeapp" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageTwitterLogoBlack} alt="Twitter" /></Link> 
              {/*<Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="https://medium.com/@atstakeapp" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageMediumLogoBlack} alt="Medium" /></Link> */}
              <Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="https://t.me/joinchat/DLQZBw-joeqOAUoKEi879Q" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageTelegramLogoBlack} alt="Telegram" /></Link>
              <Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="https://www.reddit.com/r/atstake" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageRedditLogoBlack} alt="Reddit" /></Link>
              <Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="https://www.facebook.com/atstakeapp/" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageFacebookLogoBlack} alt="Facebook" /></Link> 
              <Link style={{display:"inline-block"}} className={socialMediaSpacingCSS} href="mailto:support@atstake.net" noTarget={true} rel="noopener noreferrer"><img className={socialMediaImageCSS} src={imageEmailLogoBlack} alt="Email" /></Link> 
            </div>
          </div>
          <div className="footerText moveTop20"><Link noTarget={true} className="footerlink" href="/faq">FAQ</Link> &nbsp;<Link noTarget={true} className="footerlink" href="/incentives">Incentives</Link> &nbsp;<Link noTarget={false} className="footerlink" href="https://github.com/atstake/atstake">Github</Link> &nbsp;<Link noTarget={true} className="footerlink" href="/tos">Terms</Link></div>
          <div className="footerGreyText moveTop20">© 2019 Byzantine Labs LLC</div>
          {messageBar && <div className="appWarningMessageAbs">{messageBar}</div>}
        </div>
        {messageBar && <div className="appWarningMessageFlt">{messageBar}</div>}
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

const FooterWrap = connect(mapStateToProps, null)(Footer);
export default FooterWrap;