import { connect } from 'react-redux';
import React, { Component } from 'react';
import { navNewURL } from './ActionUtils'
import ButtonAction from './ButtonAction';
import './Verify.css';
import logoImage from "../logo_bluefill_whiteborders_80.svg";
import { verifyWithGUID } from './VerifyActions';
import Overlay from './Overlay';
import { openOverlay } from './OverlayActions';

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.done = this.done.bind(this);
    this.login = this.login.bind(this);
    this.verifyGUID = this.verifyGUID.bind(this);
  }

  componentDidMount() {
    let uuid = this.props.auth.uuid;
    let username = this.props.auth.username;
    let guid = this.props.verify.guid;
    if (uuid !== "" && username !== "" && guid !== "") {
      this.verifyGUID(uuid, username, guid);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.auth.uuid !== prevProps.auth.uuid || 
      this.props.auth.username !== prevProps.auth.username ||
      this.props.verify.guid !== prevProps.verify.guid
    ) {
      let uuid = this.props.auth.uuid;
      let username = this.props.auth.username;
      let guid = this.props.verify.guid;
      if (uuid !== "" && username !== "" && guid !== "") {
        this.verifyGUID(uuid, username, guid);
      }
    }
  }

  done() {
    this.props.dispatch(navNewURL('/'));
  }

  login() {
    this.props.dispatch(openOverlay("login", {}));
  }

  verifyGUID(uuid, username, guid) {
    if (uuid !== "" && username !== "" && guid !== "") {
      this.props.dispatch(verifyWithGUID(uuid, username, guid));
    }
  }

  render() {

    let guid = this.props.verify.guid;
    let email = this.props.verify.email;
    let verifyStatus = this.props.verify.status;
    let authStatus = this.props.auth.status;
    let message = <span>This verification link is no longer valid. :(</span>;
    let btnText = "Done";
    let btnColor = "";
    let showLogin = false;
    if (verifyStatus === "pending") {
      if (authStatus === "loggedout") {
        message = <span>Please log in to verify your email address.</span>;
        btnText = "Cancel";
        btnColor = "black";
        showLogin = true;
      }
      else if (guid === "") {
        message = <span>Invalid verification link.</span>;
        btnText = "Cancel";
        btnColor = "black";
      }
      else {
        message = <span>Verifying your email address ... </span>;
        btnText = "Cancel";
        btnColor = "black";
      }
    }
    else if (verifyStatus === "success") {
      message = <span>Your email address <b>{email}</b> has been verified.</span>;
    }
    else if (verifyStatus === "error") {
      message = <span>This verification link is no longer valid. :(</span>;
    }

    return (
      <div className="verifyCnt loggedout">
        <div style={{width:"100%", height:"100%"}} className={this.props.auth.device === "mobile" ? "mobile" : "desktop"}>
          <div className={(this.props.overlay.open || this.props.auth.metamaskOpened) ? "overlay" : "nooverlay"}>
            {(this.props.overlay.open || this.props.auth.metamaskOpened) && <Overlay />}
          </div>
          <div className="verifyWrap">
            <div className="verifyBox">
              <img className="logoVerifyImg" src={logoImage} alt="Atstake" />
              <div className="verifyMessage">{message}</div>
              {showLogin && <ButtonAction name="login" className="moveTop20 moveRight20" text="Log in" onClick={this.login}/>}
              <ButtonAction name="done" className="moveTop20" color={btnColor} text={btnText} onClick={this.done}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    verify: state.verify,
    overlay: state.overlay
  }
}

const VerifyContainer = connect(mapStateToProps, null)(Verify);
export default VerifyContainer;