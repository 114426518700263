import React, { Component } from 'react'
import { connect } from 'react-redux';
import './Messages.css';
import './Common.css';
import GapCard from "./GapCard";
import TitleCard from "./TitleCard";
import InputAutosuggest from './InputAutosuggest';
import { navNewURL, track, getAccountLink, getIsTestAgreement } from './ActionUtils';
import Link from './Link';
import { shared } from '../Shared';
import { 
  changeAutosuggestInput, 
  clearAutosuggestResults, 
  changeAutosuggestResults, 
  handleAutosuggestFavorite,
  changeInputValue,
  refreshConversationList,
  refreshMessagesList,
  sendNewMessage
} from './MessagesActions';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import InputTextarea from './InputTextarea';
import ButtonAction from './ButtonAction';
import CommentCard from './CommentCard';
import LoadCard from './LoadCard';
import moment from 'moment-timezone';

class Messages extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.autosuggestChangeInput = this.autosuggestChangeInput.bind(this);
    this.autosuggestChangeResults = this.autosuggestChangeResults.bind(this);
    this.autosuggestChangeSelection = this.autosuggestChangeSelection.bind(this);
    this.autosuggestClearResults = this.autosuggestClearResults.bind(this);
    this.autosuggestFavoriteClick = this.autosuggestFavoriteClick.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.viewMessage = this.viewMessage.bind(this);
  }

  componentDidMount() {
    if (this.props.messages.user === "") {
      this.props.dispatch(refreshConversationList());
    }

    if (this.props.messages.user !== "") {
      this.props.dispatch(refreshMessagesList(true));      
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.messages.user === "" && prevProps.messages.user !== this.props.messages.user) {
      this.props.dispatch(refreshConversationList());
    }

    if (this.props.messages.user !== "" && prevProps.messages.user !== this.props.messages.user) {
      this.props.dispatch(refreshMessagesList(true));
    }
  }

  autosuggestClearResults(name, input, nonce) {
    this.props.dispatch(clearAutosuggestResults(name, input, nonce));
  }

  autosuggestChangeInput(name, value, search) {
    this.props.dispatch(changeAutosuggestInput(name, value, search));
  }

  autosuggestChangeResults(name, value, search, max, more, selected, nonce) {
    this.props.dispatch(changeAutosuggestResults(name, value, search, max, more, selected, nonce));
  }

  autosuggestChangeSelection(name, type, value, arbitration) {
    this.props.dispatch(track("action", "button", JSON.stringify({type:"autosuggest_select", name, selection: type + "#" + value})));
    this.props.dispatch(navNewURL('/messages?user=' + value));
  }

  autosuggestFavoriteClick(name, contact, favorite) {
    this.props.dispatch(handleAutosuggestFavorite(name, contact, favorite));
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }

  sendMessage(name, value) {
    let {
       user="",
       messageBox=""
    } = this.props.messages;
    
    if (messageBox.trim() !== "") {
      this.props.dispatch(sendNewMessage(user, messageBox));
    }
  }

  viewMessage(user) {
    this.props.dispatch(navNewURL('/messages?user=' + user));
  }

  viewContract(agreementid) {
    this.props.dispatch(navNewURL('/view?agreementid=' + agreementid + "#comments"));
  }
  
  render() {

    let username = this.props.auth.username;
    let selectedUser = this.props.messages.user;
    if (selectedUser === "") {
      let {
        conversationsLoaded=false,
        conversationList=[]
      } = this.props.messages;
  
      let convCardElemList = [];
      if (conversationsLoaded) {
        for (let i = 0; i < conversationList.length; i++) {
          let convObj = conversationList[i];
          if (convObj.messagegroup.indexOf("#") >= 0) {
            // this is a comment thread, not a direct message
            let userElem = "";
            let threeUsers = convObj.user_other2 && convObj.user_other2 !== "";
            if (threeUsers) {
              userElem = <span><span className="convUserSpan">{convObj.user_other1}</span> and <span className="convUserSpan">{convObj.user_other2} </span></span>; 
            } else {
              userElem = <span className="convUserSpan">{convObj.user_other1} </span>;
            }
            let titleElem = <span className="convUserSpan">{convObj.title}  &middot; </span>;
            let lastCommenterString = "";
            if (convObj.username === username){
              lastCommenterString = "You:";
            } else if (threeUsers) {
              lastCommenterString = convObj.username + ":"
            }
            let agreementid = (convObj.messagegroup.split("#"))[0];
            let contractDescription = getIsTestAgreement(agreementid) ? "in test contract " : "in contract ";
            convCardElemList.push(<table className={"conversationCard" + (convObj.unread ? " conversationUnread" : "")} key={i}>
              <tbody>
                <tr>
                  <td className="conversationUser" onClick={() => this.viewContract(agreementid)}>{userElem}{contractDescription}{titleElem}<span className="convMessageSpan"><span style={{color: "#999", fontStyle: "italic"}}>{lastCommenterString} </span>{convObj.message}</span></td>
                </tr>
              </tbody>
            </table>);
          }
          else {
            // this is a direct message
            convCardElemList.push(<table className={"conversationCard" + (convObj.unread ? " conversationUnread" : "")} key={i}>
              <tbody>
                <tr>
                  <td className="conversationUser" onClick={() => this.viewMessage(convObj.user_other1)}><span className="convUserSpan">{convObj.user_other1} &middot; </span><span className="convMessageSpan">{convObj.username === username ? <span style={{color: "#999", fontStyle: "italic"}}>You: </span> : ""}{convObj.message}</span></td>
                </tr>
              </tbody>
            </table>);
          }
        }
      }
      else {
        convCardElemList.push(<LoadCard key="load" />);
      }

      if (convCardElemList.length === 0) {
        convCardElemList.push(<div key="none" className="contentCard">None</div>);
      }


      return (
        <div className="contentSection">
          <GapCard />
          <TitleCard title="Search people" /> 
          <div className="contentCard">
            <InputAutosuggest disabled={false} showPrice={false} className="" name="searchAutosuggest" value={this.props.messages.searchAutosuggest} onChange={this.autosuggestChangeInput} onChangeAutosuggest={this.autosuggestChangeResults} onClearResults={this.autosuggestClearResults} onSelect={this.autosuggestChangeSelection} onFavoriteClick={this.autosuggestFavoriteClick} />
          </div>
          <GapCard />
          <TitleCard title="Messages and comments" /> 
          {convCardElemList}
          <GapCard />
        </div>
      );
    }
    else {
      let {
        submitMessageDisabled=false,
        messagesLoaded=false,
        messageList=[]
      } = this.props.messages;

      /*
      let messageList = [
        { user:"elliot", message:"Hey! What time are you arriving in Osaka?", timestamp_unix: 1570174313 },
        { user:"elliot", message:"Did you want to grab dinner tomorrow night? How about we get korean food?", timestamp_unix: 1570175313 },
        { user:"elliot", message:"Can you please get me an invite to the rooftop party?", timestamp_unix: 1570176313 }
      ];
      */
  
      let messagesElem = [];
      if (messagesLoaded) {
        if (messageList.length > 0) {
          for (let i = messageList.length - 1; i >= 0; i--) {
            let messageEntry = messageList[i];
            let messageTimestamp = moment.tz(messageEntry.timestamp_unix, "X", 'America/Los_Angeles').format('h:mma MMM D, YYYY');
            messagesElem.push(<CommentCard username={this.props.auth.username} key={i + "_message"} enableEvidenceLink={false} comment={messageEntry.message} commentUser={messageEntry.username} timestamp={messageTimestamp} /> );
          }
        }
      }
      else {
        messagesElem.push(<LoadCard key="load" />);
      }

      let titleString = <span>Messages with <Link noTarget={true} className="blacklink" href={getAccountLink(selectedUser)}>{selectedUser}</Link></span>; 

      return (
        <div className="contentSection">
          <GapCard />
          <TitleCard title={titleString} />
          {messagesElem}
          {messagesLoaded && 
            <div className="contentCard">
              <div>
                <div><InputTextarea maxlength={shared.MAX_PRIVATE_MESSAGE_LENGTH} placeholder="Enter message" className="textAreaComment widthAgreementTerms" name="messageBox" value={this.props.messages.messageBox} onChange={this.changeInputValue} /></div>
                <ButtonAction disabled={submitMessageDisabled} enableMessageLine={true} message={this.props.messages.newMessageStatus} className="moveTop20" text="Message" minWidth={DEFAULT_BUTTON_WIDTH} onClick={submitMessageDisabled ? null : this.sendMessage}/>
              </div>
            </div>
          }
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    messages: state.messages
  }
}

const MessagesContainer = connect(mapStateToProps, null)(Messages);
export default MessagesContainer;