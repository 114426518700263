import { connect } from 'react-redux';
import React, { Component } from 'react';
import './NotFoundPage.css';
import './Common.css';
import { genURL } from './ActionUtils';
import Link from './Link';

class NotFoundPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {


    return (
      <div>
        <div className="contentCard moveTop40" >
          <div style={{fontSize:"24px"}}>That page doesn't exist!</div>
          <div className="moveTop20">Check the URL you entered or <Link noTarget={true} href={genURL("/")} className="bluelink">return to the homepage</Link>.</div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    notFound: state.notFound,
    overlay: state.overlay
  }
}

const NotFoundPageContainer = connect(mapStateToProps, null)(NotFoundPage);
export default NotFoundPageContainer;