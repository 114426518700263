import update from 'immutability-helper';
import { isMobile, numColumns } from "./ActionUtils";
import { shared } from '../Shared';
import uuidv4 from 'uuid/v4';

const emptyState = {
  status: "pending",
  screenDimensions : { 
    width: document.body.clientWidth, 
    height: window.innerHeight 
  },
  footerHeight: 0,
  device: isMobile(navigator.userAgent, document.body.clientWidth) ? "mobile" : "desktop",
  columns: numColumns(navigator.userAgent, document.body.clientWidth, false),
  align: "left",
  backLocationJSON: "",
  pathname: "",
  locationJSON: "",
  cleanLocationJSON: "",
  ethAddress: "",
  ethNetwork: "",
  ethNetworkName: "",
  ethBalance: "",
  weiBalance: "",
  erc20Balances: {},
  uuid: "",
  username: "",
  hasAccount: false,
  hasUsernameMatch: false,
  isDisabled: false,
  hasConnection: false,
  confirmNav: false,
  initCheckAddress: false,
  metamaskOpened: false,
  tokenPriceObj: {}, 
  hasTokenPriceData: false,
  testMode: false,
  pageguid: "",
  navParam: "",
  walletType: "",
  ios: false, 
  android: false,
  unreadCount: 0,
  expStatus: [],
  categoryTreeLoaded: false,
  enabledCategoryTree: {}
}

const AuthReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "INIT_AUTH": {
      let {loggedin=false, username="", uuid="", testMode=false, expStatus=[] } = action.payload;

      let align = "center";
      let newValue = "loggedout";
      if (loggedin) {
        newValue = "loggedin";
        align = "left";

        if (window.ethereum) {
          window.ethereum.enable().then((response) => {}).catch((error) => {});
        }    
      }

      shared.testMode = testMode;

      let pageguid = state.pageguid;
      if (state.status === "loggedout" && newValue === "loggedin") {
        pageguid = uuidv4().toLowerCase();
      }

      return {...state, status: newValue, username, uuid, align, testMode, expStatus, pageguid };
    }
    case "HAS_ACCOUNT": {
      let {hasAccount=false, hasUsernameMatch=false, isDisabled=false, testMode=false} = action.payload;
      let initCheckAddress = true;
      return {...state, hasAccount, hasUsernameMatch, isDisabled, initCheckAddress, testMode};
    }
    case "HAS_CONNECTION": {
      let {hasConnection=false} = action.payload;
      return {...state, hasConnection};
    }
    case "UPDATE_SCREEN_DIMENSIONS": {
      let { width, height, mobile, columns, ios, android } = action.payload;
      let newDevice = mobile ? "mobile" : "desktop";
      return {...state, screenDimensions: { width, height }, device: newDevice, columns: columns, ios, android };
    }
    case "FOOTER_HEIGHT": {
      return {...state, footerHeight: action.payload };
    }
    case "CATEGORY_TREE": {
      let enabledCategoryTree = JSON.parse(JSON.stringify(action.payload));

      // Enable top level global types
      enabledCategoryTree["global+service_offered"] = true;
      enabledCategoryTree["global+service_wanted"] = true;
      enabledCategoryTree["global+item_offered"] = true;
      enabledCategoryTree["global+item_wanted"] = true;
      enabledCategoryTree["global+rental_offered"] = true;
      enabledCategoryTree["global+rental_wanted"] = true;
      enabledCategoryTree["global+other"] = true;

      // Some initial example categories
      //enabledCategoryTree["global+service_offered+creative"] = true;
      //enabledCategoryTree["global+service_wanted+creative"] = true;
      //enabledCategoryTree["global+item_offered+arts_crafts"] = true;
      //enabledCategoryTree["global+item_wanted+arts_crafts"] = true;
      //enabledCategoryTree["global+rental_offered+sport_hobby"] = true;
      //enabledCategoryTree["global+rental_wanted+sport_hobby"] = true;
      
      return {...state, enabledCategoryTree, categoryTreeLoaded: true };
    }
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let cleanQuery = JSON.parse(JSON.stringify(query));
      if (cleanQuery.hasOwnProperty("filter")) {
        delete cleanQuery["filter"];
      }

      let navParam = "";
      if (cleanQuery && cleanQuery.hasOwnProperty("nav") && cleanQuery["nav"] === "contracts") { 
        navParam = cleanQuery["nav"];
      }
      
      let pageguid = uuidv4().toLowerCase();
      let backLocationJSON = state.locationJSON;
      let newLocationJSON = JSON.stringify({pathname,query});
      let newCleanLocationJSON = JSON.stringify({pathname,query:cleanQuery});
      return {...state, backLocationJSON: backLocationJSON, pathname: pathname, locationJSON: newLocationJSON, cleanLocationJSON: newCleanLocationJSON, confirmNav: false, navParam, pageguid };
    }
    case "ETH_ADDRESS": {
      let { address, network, networkName } = action.payload;

      console.log("Eth wallet: connected");

      let initCheckAddress = state.initCheckAddress;
      let newErc20Balances = {};
      if (state.ethAddress === address && state.ethNetwork === network) {
        newErc20Balances = state.erc20Balances || {};
      }
      else {
        delete window.checkERC20BalanceCnt;
        window.forceTokenSync = true;
        initCheckAddress = false;
      }

      // Reset initCheckAddress to false if ETH address changes
      return {...state, initCheckAddress, ethAddress: address, ethNetwork: network, ethNetworkName: networkName, erc20Balances: newErc20Balances };
    }
    case "DISCONNECT_ETH_ADDRESS": {
      console.log("Eth wallet: disconnected");
      return {...state, ethAddress: "", ethNetwork: "", ethNetworkName: "", ethBalance: "", weiBalance: "", erc20Balances: {} };
    }
    case "ERC20_TOKEN_BALANCE": {
      let { symbol="", address="", balance="0", decimals="0", balanceStr="0", allowanceObj={} } = action.payload;
      let newErc20Balances = update(
        state.erc20Balances || {}, 
        {$merge: {[symbol]: {address, balance, decimals, balanceStr, ...allowanceObj}}}
      );

      if (symbol === "ETH") {
        let ethBalance = balance;
        let weiBalance = balanceStr;
        return {...state, erc20Balances: newErc20Balances, ethBalance, weiBalance};
      }

      return {...state, erc20Balances: newErc20Balances};
    }
    case "NOTIFICATIONS": {
      let { unreadCount=0 } = action.payload;
      return {...state, unreadCount };
    }
    case "METAMASK_OPENED": {
      console.log("metamaskOpened: " + (action.payload ? "yes" : "no"));
      return {...state, metamaskOpened: action.payload };
    }
    case "REQUIRE_CONFIRM_NAV": {
      return {...state, confirmNav: action.payload };
    }
    case "AUTH_TOKEN_PRICE": {
      return {...state, tokenPriceObj: action.payload, hasTokenPriceData: true };
    }
    case "AUTH_WALLET_TYPE": {
      return {...state, walletType: action.payload };
    }
    default: {
      return state
    }
  }
}

export default AuthReducer