//import update from 'immutability-helper';

const emptyState = {
  photoFile: "",
  photoURL: "",
  displayname: "",
  summary: "",
  photoContent: "",
  photoColor: "",
  showEnterURL: false,
  hasPhotoToUpload: false,
  hasError: false, 
  errorMsg: ""
}

const DialogChangeDisplaynameReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGCHANGEDISPLAYNAME_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGCHANGEDISPLAYNAME_SAVE_IMAGE" : {
      let { thumbnail={} } = action.payload;
      let { value="", color="" } = thumbnail;
      return {...state, photoContent: value, photoColor: color, hasPhotoToUpload: true };
    }
    case "DIALOGCHANGEDISPLAYNAME_ERROR" : {
      let { hasError, errorMsg } = action.payload;
      return {...state, hasError, errorMsg };
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "change_displayname") {
        let { displayname="", summary="", photoContent="" } = params;
        return {...state, ...emptyState, photoContent, displayname, summary };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogChangeDisplaynameReducer