import React, { Component } from 'react';
import './ButtonSelect.css';

class ButtonSelect extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (!this.props.disabled) {
        this.props.onChange(event.currentTarget.getAttribute("name"), event.currentTarget.getAttribute("value"));
      }
    }

    render() {

      let classNameVal = "btnToggle";
      if (this.props.disabled) {
        classNameVal = "btnToggleDisabled";
      }

      let imgstyle = this.props.imgstyle || null;

      let imageURL = null;
      if (this.props.hasOwnProperty("image") && this.props.image !== null) {
        imageURL = this.props.image;
        classNameVal = "btnToggleImg";
      }

      if (this.props.hasOwnProperty("className")) {
        classNameVal += " " + this.props.className;
      }

      let isBlackColor = this.props.hasOwnProperty("color") && this.props.color === "black";
      let isGreenColor = this.props.hasOwnProperty("color") && this.props.color === "green";

      if (this.props.selected === this.props.value) {
        if (this.props.disabled) {
          classNameVal += " btnToggleSelectedDisabled";
        }
        else if (isBlackColor) {
          classNameVal += " btnToggleSelectedBlack";
        }
        else if (isGreenColor) {
          classNameVal += " btnToggleSelectedGreen";
        }
        else {
          classNameVal += " btnToggleSelected";
        }
      }

      if (isBlackColor) {
        classNameVal += " btnSelectBlack";
      }
      if (isGreenColor) {
        classNameVal += " btnSelectGreen";
      }

      if (imageURL === null) {
        return (
          <button name={this.props.name} style={{minWidth:this.props.minWidth}} value={this.props.value} className={classNameVal} onClick={this.handleClick}>{this.props.text}</button>
        );
      }
      else {
        return (
          <button className="btnWrapper" style={{minWidth:this.props.minWidth}} name={this.props.name} value={this.props.value} onClick={this.handleClick} ><img style={imgstyle} title={this.props.text} alt={this.props.text} className={classNameVal} src={imageURL} /></button>
        );
      }
    }
  }
  
  export default ButtonSelect;
