import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogPhotoPreview.css';
import { changeInputValue } from './DialogPhotoPreviewActions';
import { closeOverlay } from './OverlayActions'

class DialogPhotoPreview extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  handleClick(name, value) {
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {

    let {
      photo=""
    } = this.props.dialogPhotoPreview;
    
    let {
      width,
      height
    } = this.props.auth.screenDimensions;

    return (
      <div className="contentSection" onClick={this.handleClick}>
        <div className="">
          <div style={{margin:"0px auto", textAlign: "center"}}><img style={{verticalAlign: "top", maxWidth: width + "px", maxHeight: height + "px"}} src={photo} alt="Preview" /></div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogPhotoPreview: state.dialogPhotoPreview
  }
}

const DialogPhotoPreviewContainer = connect(mapStateToProps, null)(DialogPhotoPreview);
export default DialogPhotoPreviewContainer;