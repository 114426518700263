//import update from 'immutability-helper';

const emptyState = {
  onTOSPage: false
}

const TOSReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="" } = action.payload;
      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "tos") {
        return {...state, onTOSPage: true};
      }
      return {...state, onTOSPage: false};
    }
    default: {
      return state
    }
  }
}

export default TOSReducer