import { apiAttestationPrivacy, apiUpdateDefaultAddress, apiDeleteAttestation } from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGVIEWATTESTATION_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function saveAttestationConfig(type, value, privacy, defaultAddress) {
  return function(dispatch) {
    let auth = store.getState().auth;

    let isDelete = false;
    let promiseList = [];
    if (type !== "ethereum" && privacy === "delete") {
      isDelete = true;
      promiseList.push(apiDeleteAttestation(auth.uuid, auth.username, type, value));
    }
    else {
      promiseList.push(apiAttestationPrivacy(auth.uuid, auth.username, type, value, privacy));
      if (defaultAddress !== "") {
        promiseList.push(apiUpdateDefaultAddress(auth.uuid, auth.username, defaultAddress));
      }  
    }

    dispatch({
      type: 'DIALOGVIEWATTESTATION_CHANGE_INPUT_VALUE',
      payload: {name: "updateMsg", value: "pending"}
    });

    Promise.all(promiseList).then((valueList) => {
      let success = false;
      if (valueList && valueList.length > 0) {
        success = true;
        for (let i = 0; i < valueList.length; i++) {
          if (valueList[i] === false) {
            success = false;
          }
        }
      }

      if (success) {
        dispatch({
          type: 'USER_ACCOUNT_ATTESTATION_CHANGE'
        });
      }

      dispatch({
        type: 'DIALOGVIEWATTESTATION_CHANGE_INPUT_VALUE',
        payload: {name: "updateMsg", value: success ? (isDelete ? "deleted" : "success") : "error"}
      });
    });
  }
}