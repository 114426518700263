//import update from 'immutability-helper';

const emptyState = {
  mode: "start",
  hasLocation: false,
  searchLocations: "",
  breadLocation: "",
  breadType: "",
  breadCategory: ""
}

const DialogNewPostingReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGNEWPOSTING_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGNEWPOSTING_SELECT_MODE" : {
      let { mode } = action.payload;

      if (mode === "start") {
        return {...state, ...emptyState, mode};
      }
      else if (mode === "choose_location") {
        return {...state, breadLocation: "", breadType: "", breadCategory: "", mode};
      }
      else if (mode === "posting_type") {
        return {...state, breadType: "", breadCategory: "", mode};
      }
      else if (mode === "category_type") {
        return {...state, breadCategory: "", mode};
      }

      return {...state, mode};
    }
    case "DIALOGNEWPOSTING_SET_HAS_LOCATION" : {
      let { hasLocation } = action.payload;
      if (hasLocation) {
        return {...state, breadLocation: "", hasLocation, mode: "choose_location" };
      }
      else {
        return {...state, breadLocation: "global", hasLocation, mode: "posting_type" };
      }
    }
    case "DIALOGNEWPOSTING_SELECT_LOCATION" : {
      let { type } = action.payload;
      return {...state, breadLocation: type, mode: "posting_type" };
    }
    case "DIALOGNEWPOSTING_SELECT_CATEGORY" : {
      let { type } = action.payload;
      return {...state, breadCategory: type };
    }
    case "DIALOGNEWPOSTING_SELECT_POSTING_TYPE" : {
      let { type } = action.payload;
      return {...state, breadType: type, mode: "category_type" };
    }
    case "OPEN_OVERLAY": {
      let { dialog } = action.payload;//, params
      if (dialog === "new_posting") {
        return {...state, ...emptyState };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogNewPostingReducer