import { getVersionsForAgreementList } from "./ActionUtils"
import store from '../store';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGVERSIONS_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function refreshVersionsForAgreementList(agreementid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getVersionsForAgreementList(auth.uuid, auth.username, agreementid).then((list) => {
      dispatch({
        type: 'DIALOGVERSIONS_LIST',
        payload: {list, username: auth.username}
      });
    });
  }
}