import React, { Component } from 'react';
import './Common.css';
import './ArbitratorList.css';
import { getContentWidth, getIsMobile, upperCase, pluralIfNeeded, pluralIfNeededSpan } from './ActionUtils';
import twitterImg from '../twitter.png';
import facebookImg from '../facebook.png';
import redditImg from '../reddit.png';
import defaultProfileImg from '../profile.svg';
import Link from './Link';

class ArbitratorList extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }
    
    handleClick(event) {
      let username = this.props.username || "";
      this.props.onClick(username);
    }

    render() {
        let className = this.props.className || "";
        let arbitratorList = this.props.arbitratorList || [];

        let maxTableWidth = 1000;
        let profileImgSize = 48;
        //let profileImgTop = 0;
        let photoSrc = "https://atstake.s3.amazonaws.com/9e29f6701505eaaec1e93b905b22462317be061b2f31b2cd1023693d83979bd3.jpg";

        // Get content width and subtract 22px for padding and border
        let contentWidth = getContentWidth();
        let tableWidth = contentWidth - 22;

        let widthColumn = [];
        let viewType = "wide";
        if (getIsMobile() && tableWidth < 500) {
          viewType = "narrow";
        }
        else if (tableWidth < maxTableWidth) {
          viewType = "regular";
        }

        let numCol = 4;
        if (viewType === "narrow") {
          numCol = 2;
          let columnWidth = Math.min(Math.floor(tableWidth/(numCol)), maxTableWidth);
          widthColumn[0] = columnWidth;
          widthColumn[1] = tableWidth - (columnWidth * (numCol - 1));
        }
        else if (viewType === "regular") {
          numCol = 4;
          let columnWidth = Math.min(Math.floor(tableWidth/(numCol)), maxTableWidth);
  
          widthColumn[0] = columnWidth;
          widthColumn[1] = columnWidth;
          widthColumn[2] = columnWidth;
          widthColumn[3] = tableWidth - (columnWidth * (numCol - 1));
        }
        else if (viewType === "wide") {
          numCol = 5;
          let extraPixels = profileImgSize + 25;
          let columnWidth = Math.min(Math.floor((tableWidth-extraPixels)/(numCol)), maxTableWidth);
  
          widthColumn[0] = columnWidth + extraPixels;
          widthColumn[1] = columnWidth;
          widthColumn[2] = columnWidth;
          widthColumn[3] = columnWidth;
          widthColumn[4] = tableWidth - (columnWidth * (numCol - 1)) - extraPixels;
        }

        let rowElem = [];
        for (let i = 0; i < arbitratorList.length; i++) {
          let arbitratorObj = arbitratorList[i];
          let { 
            profile_photo="",
            attestation_links={},
            arbitration_settings={}, 
            total_resolved_as_arbitrator=0, 
            /*total_rulings_as_arbitrator=0,*/ 
            ratings_positive_as_arbitrator=0, 
            ratings_total_as_arbitrator=0 
          } = arbitratorObj;
          let { fee_value=0, fee_type="" } = arbitration_settings;

          if (profile_photo === "") {
            photoSrc = defaultProfileImg;
          } else {
            photoSrc = "https://atstake.s3.amazonaws.com/" + profile_photo;
          }

          let ratingsPercentPositive = 0;
          if (ratings_total_as_arbitrator > 0) {
            ratingsPercentPositive = Math.floor(100 * ratings_positive_as_arbitrator / ratings_total_as_arbitrator);
          }

          // grab the links to the arbitrator's social media profiles
          let twitterSrc = "";
          let facebookSrc = "";
          let redditSrc = "";
          for (let key in attestation_links) {
            if (key.startsWith("twitter#")) {
              twitterSrc = "https://twitter.com/" + key.substring(8);
            } else if (key.startsWith("facebook#")) {
              facebookSrc = "https://facebook.com/" + key.substring(9);
            } else if (key.startsWith("reddit#")) {
              redditSrc = "https://www.reddit.com/user/" + key.substring(7);
            }
          }
          let socialMediaArray = [];
          if (twitterSrc !== "") {
            socialMediaArray.push(<Link key="twitter" noTarget={true} rel="noopener noreferrer" className="bluelink" href={twitterSrc}><img className="socialMediaSmallImg" src={twitterImg} title="Twitter" alt="Twitter" /></Link>);
          }
          if (facebookSrc !== "") {
            socialMediaArray.push(<Link key="facebook" noTarget={true} rel="noopener noreferrer" className="bluelink" href={facebookSrc}><img className="socialMediaSmallImg" src={facebookImg} title="Facebook" alt="Facebook" /></Link>);
          }
          if (redditSrc !== "") {
            socialMediaArray.push(<Link key="reddit" noTarget={true} rel="noopener noreferrer" className="bluelink" href={redditSrc}><img className="socialMediaSmallImg" src={redditImg} title="Reddit" alt="Reddit" /></Link>);
          }

          let elemFee = <span className="primaryStat">{fee_value} <span className="smallStatLabel">{upperCase(fee_type)} fee</span></span>;
          let elemRatings = <span className="primaryStat">{ratings_total_as_arbitrator === 0 ? <span>0 <span className="smallStatLabel">ratings</span></span> : <span>{ratingsPercentPositive + "% "}<span className="smallStatLabel">{"positive"} <span style={{whiteSpace:"nowrap"}}>{"(" + pluralIfNeeded(ratings_total_as_arbitrator, "rating", "ratings") + ")"}</span></span></span>}</span>;
          //let elemRulings = <span className="primaryStat">{pluralIfNeededSpan(total_rulings_as_arbitrator, <span className="smallStatLabel">ruling</span>, <span className="smallStatLabel">rulings</span>)}</span>;
          let elemSocialMedia = socialMediaArray;
          let elemAgreements = <span className="primaryStat">{pluralIfNeededSpan(total_resolved_as_arbitrator, <span className="smallStatLabel">contract</span>, <span className="smallStatLabel">contracts</span>)}</span>;

          if (viewType === "narrow") {
            rowElem.push(
              <tr key={i + "_rowtop"} className="arbitratorListRowTop" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td colSpan={numCol}>
                  <img className="arbitratorListPhoto" src={photoSrc} alt="Profile" title="Profile" />
                  <span style={{fontSize:"24px"}} className="arbitratorListUsername">{arbitratorObj.username}</span>
                </td>
            </tr>);

            rowElem.push(
              <tr key={i + "_rowmiddle"} className="arbitratorListRowMiddle" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemSocialMedia}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemAgreements}</div>
                </td>
            </tr>);

            rowElem.push(
              <tr key={i + "_rowbottom"} className="arbitratorListRowBottom" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemRatings}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemFee}</div>
                </td>
            </tr>);
          }
          else if (viewType === "regular") {
            rowElem.push(
              <tr key={i + "_rowtop"} className="arbitratorListRowTop" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td colSpan={numCol}>
                  <img className="arbitratorListPhoto" src={photoSrc} alt="Profile" title="Profile" />
                  <span style={{fontSize:"24px"}} className="arbitratorListUsername">{arbitratorObj.username}</span>
                </td>
            </tr>);

            rowElem.push(
              <tr key={i + "_rowbottom"} className="arbitratorListRowBottom" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemSocialMedia}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemAgreements}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemRatings}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemFee}</div>
                </td>
            </tr>);
          }
          else if (viewType === "wide") {
            rowElem.push(
              <tr key={i + "_row"} className="arbitratorListRow" onClick={() => this.props.onClick(arbitratorObj.username)}>
                <td>
                  <img className="arbitratorListPhoto" src={photoSrc} alt="Profile" title="Profile" />
                  <span className="arbitratorListUsername" style={{fontSize:"24px"}}>{arbitratorObj.username}</span>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemSocialMedia}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemAgreements}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemRatings}</div>
                </td>
                <td className="arbitratorListCell">
                  <div className="moveTop5">{elemFee}</div>
                </td>
              </tr>
            );
          }

          /*
          rowElem.push(
          <tr key={i + "_gap"}>
            <td colSpan={numCol}>
              <div style={{height:"5px"}}></div>
            </td>
          </tr>);
          */
        }

        let topColumnElem = [];
        for (let i = 0; i < numCol; i++) {
          topColumnElem.push(
            <td key={i} style={{width:(widthColumn[i] + "px")}}></td>
          );
        }

        return (
          <div>
            <table className={"arbitratorList " + className} style={{tableLayout:"fixed", width:"calc(100% - 2px)", wordWrap: "break-word"}}><tbody>
              <tr>
                {topColumnElem}
              </tr>
              {rowElem}
            </tbody></table>
          </div>
        );
    }
  }
  
  export default ArbitratorList;
