import { apiChangeVisibility } from "./ActionUtils"
import store from '../store';
import { closeOverlay } from './OverlayActions';
import { refreshAgreement } from './AgreementActions';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGVISIBILITY_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function submitChangeVisibility(agreementid, new_visibility) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;

    apiChangeVisibility(auth.uuid, auth.username, agreementid, new_visibility).then((success) => {
      if (success) {
        dispatch(closeOverlay());
        dispatch(refreshAgreement(agreement.agreementid, agreement.versionid));
      }
    });
  }
}