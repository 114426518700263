//import update from 'immutability-helper';

const emptyState = {
  nextDialog: "",
  nextParams: {}
}

const DialogDepositWarningReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGDEPOSITWARNING_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      if (dialog === "deposit_warning") {
        let { nextDialog, nextParams } = params;
        nextParams = JSON.parse(JSON.stringify(nextParams));
        
        return {...state, ...emptyState, nextDialog, nextParams };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogDepositWarningReducer