//import { navNewURL } from './ActionUtils';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'APPROVE_ALLOWANCE_STATUS',
      payload: {name, value}
    });
  }
}
