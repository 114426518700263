import { connect } from 'react-redux';
import React, { Component } from 'react';
import './How.css';
import { genURL } from './ActionUtils';
import TwoColumnContainer from './TwoColumnContainer';
import Link from './Link';
import imageScreenshot from '../phone_usecase_cropped2.png';

class How extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let isSingleColumn = this.props.auth.columns === 1;

    return (
      <div>
        <div id="howitworks" style={{backgroundColor:"var(--page-section-a-bg-color)"}} className="pageSection">
          <div className="restrictSectionWidth">

            <TwoColumnContainer showBorder={false} className="" middleLeftComp={
              <div style={{paddingBottom:"20px", paddingRight:"20px", height:"100%"}}>
                <div style={{textAlign: "left"}} className="pageTitle">How Atstake Works</div>

                <div style={{position:"relative"}} >
                  <div className="howNumber">1</div>
                  <div className="pageCardHeading moveTop40 howPadLeftNumber">Agree to terms</div>
                  <div className="pageLightText moveTop5 howPadLeftNumber">Find a counterparty (in 
                  our <Link href={genURL("/postings")} className="bluelink" noTarget={true} rel="noopener noreferrer">Postings</Link> page or on your own) 
                  then create a proposal with all terms of the contract 
                  and send it to them for review. They can approve, reject, or propose edits.</div>
                </div>

                <div style={{position:"relative"}} >
                  <div className="howNumber">2</div>
                  <div className="pageCardHeading moveTop40 howPadLeftNumber">Deposit funds to blockchain</div>
                  <div className="pageLightText moveTop5 howPadLeftNumber">When everyone has agreed on terms,
                  the contract is deployed to the Ethereum blockchain in a publicly verifiable form.
                  One or both of the parties (depending on the contract details) deposit funds onto the Ethereum blockchain. At this point   
                  no one can receive any of the funds unless either their counterparty or the arbitrator agrees.</div>
                </div>

                <div style={{position:"relative"}} >
                  <div className="howNumber">3</div>
                  <div className="pageCardHeading moveTop40 howPadLeftNumber">Report the outcome</div>
                  <div className="pageLightText moveTop5 howPadLeftNumber">When you know the outcome of 
                  the contract you report this to the Ethereum blockchain.
                  If you and your counterparty report different outcomes either of you can request that the arbitrator step in and 
                  settle the disagreement. 
                  We've created <span onClick={this.navIncentives} className="bluelink">incentives</span> so that 
                  arbitration is rarely needed.</div>
                </div>

                <div style={{position:"relative"}} >
                  <div className="howNumber">4</div>
                  <div className="pageCardHeading moveTop40 howPadLeftNumber">Funds distributed</div>
                  <div className="pageLightText moveTop5 howPadLeftNumber">Based on the reported outcomes, code running on the Ethereum blockchain
                  automatically distributes any funds that either party is entitled to.</div>
                </div>

              </div>
            } rightComp={
              <div className={isSingleColumn ? "moveTop80" : "moveTop20"} style={{paddingBottom:"20px", paddingLeft: "0px", height:"100%"}}>
                <div style={{textAlign:"center"}}><img style={{width:"320px"}} src={imageScreenshot} alt="How it works" /></div>
              </div>
            } />
          </div>
          <div className="helpLightText moveTop40" style={{textAlign:"center"}}>
            Have questions? See our <Link href={genURL("/help")} className="bluelink" noTarget={true} rel="noopener noreferrer">help page</Link>.
          </div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    how: state.how,
    overlay: state.overlay
  }
}

const HowContainer = connect(mapStateToProps, null)(How);
export default HowContainer;