import { apiRequest, signLinkAccountPromise, apiUpdateDefaultAddress } from "./ActionUtils"
import store from '../store';
import moment from 'moment-timezone';
import { openOverlay } from './OverlayActions';
import { track } from './ActionUtils';
import { checkAddress } from './AuthActions';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGADDATTESTATION_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function cleanPhoneNumber(num) {
  let cleanStr = num.replace(/\D/g,'');
  if (cleanStr.length === 11 && cleanStr.charAt(0) === "1") {
    return cleanStr;
  }
  else if (cleanStr.length === 10) {
    return "1" + cleanStr;
  }
  
  return "";
}

export async function checkLink(type, value) {
  let auth = store.getState().auth;
  let respObj = await apiRequest("check_link", { 
    uuid: auth.uuid, 
    username: auth.username, 
    type: type, 
    value: value 
  }).then((response) => {
    if (response.status === "success") {
      return response;
    }
    else {
      return {
        status: "success",
        linkExists: false,
        linkIsYours: false
      };
    }
  });

  return respObj;
}

export function checkCode(type, value, code, privacy) {
  return function(dispatch) {
    if (code !== "" && code.length === 6 && (type === "phone" || type === "email")) {
      let auth = store.getState().auth;
      let paramsObj = {
        uuid: auth.uuid,
        username: auth.username,
        type: type, 
        value: value,
        privacy: privacy,
        code: code
      };
      apiRequest("new_attestation", paramsObj).then((response) => {
        if (response && response.status === "success") {
          let step = "success";
          let stepInfo = {};
  
          dispatch(track("action", "update", JSON.stringify({type:"attestation_added", attestation_type: type, attestation_value: value})));

          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });
        }
        else {
          let step = "start";
          let stepInfo = {messageText: "Invalid code. Only the most recent code is valid.", messageType: "error"};

          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });
        }
      });
    }
    else {
      let step = "start";
      let stepInfo = {messageText: "Invalid code. Only the most recent code is valid.", messageType: "error"};

      dispatch({
        type: 'DIALOGADDATTESTATION_CHANGE_STEP',
        payload: {step, stepInfo}
      });
    }
  }
}

export function checkForVerification(type, privacy, value, postObj, showMsgOnError) {
  return function(dispatch) {
    let postid = "";
    let commentid = "";
    let subreddit = "";

    if (postObj.hasOwnProperty("postid")) {
      postid = postObj.postid;
    }

    if (postObj.hasOwnProperty("commentid")) {
      commentid = postObj.commentid;
    }

    if (postObj.hasOwnProperty("subreddit")) {
      subreddit = postObj.subreddit;
    }

    if (type === "reddit" || type === "twitter" || (type === "facebook" && postid !== "" && commentid !== "")) {
      let auth = store.getState().auth;
      let paramsObj = {
        uuid: auth.uuid,
        username: auth.username,
        type: type, 
        value: value,
        privacy: privacy,
        postid: postid,
        commentid: commentid,
        subreddit: subreddit
      };
      apiRequest("new_attestation", paramsObj).then((response) => {
        if (response && response.status === "success") {
          let step = "success";
          let stepInfo = {};

          dispatch(track("action", "update", JSON.stringify({type:"attestation_added", attestation_type: type, attestation_value: value})));

          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });
        }
        else {
          if (showMsgOnError) {
            let step = "enter";
            let stepInfo = {messageText: "Error linking " + type, messageType: "error"};

            dispatch({
              type: 'DIALOGADDATTESTATION_CHANGE_STEP',
              payload: {step, stepInfo}
            });
          }
        }
      });
    }
  }
}

export function signLinkAccount(privacy, makeDefault) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let ethAddress = auth.ethAddress;
    signLinkAccountPromise(auth.username).then((signature) => {
      apiRequest("new_attestation", {
        uuid: auth.uuid,
        username: auth.username,
        type: "ethereum", 
        value: ethAddress,
        signature: signature,
        privacy: privacy
      }).then((response) => {
        if (response && response.status === "success") {
          let step = "success";
          let stepInfo = {};

          dispatch(track("action", "update", JSON.stringify({type:"attestation_added", attestation_type: "ethereum", attestation_value: ethAddress})));

          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });

          if (makeDefault) {
            return apiUpdateDefaultAddress(auth.uuid, auth.username, ethAddress).then((success) => {
              console.log("Default address updated");
            });
          }

          if (ethAddress) {
            checkAddress(dispatch, ethAddress);
          }
        }
      });
    }).catch((err) => {
      console.log("Error with signature");
    });
  }
}

export function resendVerification(type, input) {
  return function(dispatch) {    
    if (type === "email" || type === "phone") {
      let auth = store.getState().auth;
      apiRequest("send_verification", { 
        uuid: auth.uuid, 
        username: auth.username, 
        type: type, 
        value: input 
      }).then((response) => {
        if (response.hasOwnProperty("status") && response.status === "success") {
          let timestampUnix = moment.tz('America/Los_Angeles').unix();
          timestampUnix += 60;
          let step = "start";
          let stepInfo = {messageText: "Verification code has been re-sent", messageType: "info", retryTimestampUnix: timestampUnix};
    
          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });  
        }
        else {
          let step = "start";
          let stepInfo = {messageText: "Error sending verification code", messageType: "error"};
    
          dispatch({
            type: 'DIALOGADDATTESTATION_CHANGE_STEP',
            payload: {step, stepInfo}
          });
        }
      });
    }
  }
}


export function addAttestation(type, privacy, input, makeDefault) {
  return function(dispatch) {

    let cleanInput = input;
    if (type === "phone") {
      cleanInput = cleanPhoneNumber(input);
    }
    checkLink(type, cleanInput).then((response) => {
      if (response && response.linkExists) {
        dispatch(openOverlay("add_attestation", {
          type: type,
          privacy: privacy, 
          makeDefault: false,
          value: cleanInput,
          step: "exists",
          stepInfo: response
        }));
      }
      else {
        if (type === "email" || type === "phone") {
          let auth = store.getState().auth;
          apiRequest("send_verification", { 
            uuid: auth.uuid, 
            username: auth.username, 
            type: type, 
            value: cleanInput 
          }).then((response) => {
            let timestampUnix = moment.tz('America/Los_Angeles').unix();
            timestampUnix += 60;

            dispatch(openOverlay("add_attestation", {
              type: type,
              privacy: privacy, 
              makeDefault: false,
              value: cleanInput,
              step: "start",
              stepInfo: { retryTimestampUnix: timestampUnix}
            }));

          });
        }
        else {
          dispatch(openOverlay("add_attestation", {
            type: type,
            privacy: privacy, 
            makeDefault: makeDefault,
            value: cleanInput,
            step: "start",
            stepInfo: {}
          }));
        }
      }
    });
  }
}