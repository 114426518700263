import React, { Component } from 'react';
import './Common.css';
import './InputCheckbox.css';

class InputCheckbox extends Component {
    constructor(props) {
      super(props);

      this.state = {isFocused: false};

      // This binding is necessary to make `this` work in the callback
      this.handleChange = this.handleChange.bind(this);
      this.handleKeyboard = this.handleKeyboard.bind(this);
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
      document.addEventListener("keydown", this.handleKeyboard, false);
    }

    componentWillUnmount() {
      document.removeEventListener("keydown", this.handleKeyboard, false);
      clearTimeout(this.timeoutId);
    }

    handleFocus(event) {
      this.setState({isFocused: true});
    }

    handleBlur(event) {
      this.setState({isFocused: false});
    }

    handleKeyboard(event) {
      if (event.type !== "keydown") { return }
      if (!this.state.isFocused) { return }

      if (event.keyCode === 13) {
        let value = this.props.value;
        let name = this.props.name;
        if (this.props.hasOwnProperty("onChange") && this.props.onChange) {
          this.props.onChange(name, !value);
        }  
      }
    }
    
    handleChange(event) {
      let value = this.props.value;
      let name = this.props.name;
    
      if (this.props.hasOwnProperty("onChange") && this.props.onChange) {
        this.props.onChange(name, !value);
      }
    }

    handleButtonAction(event) {
      if (this.props.onButtonAction) { this.props.onButtonAction(this.props.name); }
    }

    render() {
        return (
          <div style={{position: "relative", display:"inline-block"}} >
            <label className="checkboxLabel" ><input className="checkboxInput" type="checkbox" name={this.props.name} checked={this.props.value} onChange={this.handleChange} /> <span>{this.props.label}</span></label>
          </div>
        );
    }
  }
  
  export default InputCheckbox;
