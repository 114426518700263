//import update from 'immutability-helper';

const emptyState = {
  onRatingsPage: false,
  ratingsList: [],
  ratingsLoaded: false,
  needUserDataRefresh: false
}

const TOSReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="" } = action.payload;
      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "ratings") {
        return {...state, onRatingsPage: true, ratingsList: [], ratingsLoaded: false};
      }
      return {...state, onRatingsPage: false};
    }
    case "RATINGS_LIST": {
      let ratingsList = action.payload;
      return {...state, ratingsList, ratingsLoaded: true};
    }
    case "CLOSE_OVERLAY" : {
      let data = action.payload;
      // Refresh linked accounts when overlay closes only if there was an update
      if (data && data.profileDataUpdate) {
        return {...state, needUserDataRefresh: true };
      }
      return state;
    }
    case "PROFILE_DATA" : {
      return {...state, needUserDataRefresh: false };
    }
    default: {
      return state
    }
  }
}

export default TOSReducer