import React, { Component } from 'react';
import InputBasic from './InputBasic';
import ButtonIcon from './ButtonIcon';
import searchImg from '../searchwhite.png';
import menuNavImg from '../menu_triple_bars.png';
import { shared } from '../Shared';
import './SearchCard.css';

class SearchCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {    
    let showMenuButton = this.props.showMenuButton || false;
    let name = this.props.name || "";
    let value = this.props.value || "";
    let onChange = this.props.onChange || null;
    let onSubmit = this.props.onSubmit || null;
    let onMenu = this.props.onMenu || null;

    return (
      <div className="blankCard">
        <div className={showMenuButton ? "showHamburger": ""}>
          <InputBasic maxlength={shared.MAX_SEARCH_LENGTH} placeholder="Search postings" type="text" className="homePageSearchInput moveRight5" name={name} value={value} onChange={onChange} onEnter={onSubmit} />
          <ButtonIcon name="search" icon={searchImg} className="marketSearchBtn" text="Search" onClick={onSubmit}/>
          {showMenuButton && <div className="menuNavCnt" onClick={onMenu}><img className="menuNavImg" src={menuNavImg} alt="" /></div>}
        </div>
      </div>
    );
  }
}
  
export default SearchCard;