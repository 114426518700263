import { getEthersJSContractForAgreement } from "./ActionUtils";
import { ethers } from 'ethers';
import { shared } from '../Shared';
import store from '../store';

export const ACTIONS_LIST_ETH_SIMPLE = [
  {
    display: "Get State",
    action: "getState",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    read: ["agreementID"],
    response: [
      [
        { field: "partyAAddress", type: "address" },
        { field: "partyBAddress", type: "address" },
        { field: "arbitratorAddress", type: "address" }
      ],
      [
        { field: "partyAResolutionPayA", type: "resolution" }, 
        { field: "partyBResolutionPayA", type: "resolution" }, 
        { field: "resolutionPayA", type: "resolution" }, 
        { field: "automaticResolutionPayA", type: "resolution" }, 
        { field: "partyAStakeAmount", type: "integer" }, 
        { field: "partyBStakeAmount", type: "integer" }, 
        { field: "partyAInitialArbitratorFee", type: "integer" }, 
        { field: "partyBInitialArbitratorFee", type: "integer" }, 
        { field: "disputeFee", type: "integer" }, 
        { field: "nextArbitrationStepAllowedAfterTimestamp", type: "timestamp" }, 
        { field: "autoResolveAfterTimestamp", type: "timestamp" }, 
        { field: "daysToRespondToArbitrationRequest", type: "integer" }, 
        { field: "amountDisputePaidPartyA", type: "integer" }, 
        { field: "amountDisputePaidPartyB", type: "integer" }, 
        { field: "amountPaidToArbitrator", type: "integer" }, 
        { field: "disputeID", type: "integer"}
      ],
      [
        { field: "partyAStakePaid", type: "boolean" }, 
        { field: "partyBStakePaid", type: "boolean" }, 
        { field: "partyARequestedArbitration", type: "boolean" }, 
        { field: "partyBRequestedArbitration", type: "boolean" }, 
        { field: "partyAWithdrew", type: "boolean" }, 
        { field: "partyBWithdrew", type: "boolean" }, 
        { field: "partyAResolvedLast", type: "boolean" }, 
        { field: "arbitratorResolved", type: "boolean" }, 
        { field: "arbitratorWithdrewDisputeFee", type: "boolean" }, 
        { field: "partyADisputeFeeLiability", type: "boolean" }, 
        { field: "partyBDisputeFeeLiability", type: "boolean" }, 
        { field: "disputeCreated", type: "boolean" }
      ],
      { field: "arbitrationExtraData", type: "bytes" }
    ]
  },
  {
    display: "Get Number Of Agreements",
    action: "getNumberOfAgreements",
    params: [],
    read: [],
    response: [
      { field: "agreementCount", type: "integer" }     
    ]
  },
  {
    display: "Get Null Resolution",
    action: "getResolutionNull",
    params: [],
    read: [],
    response: [   
      { field: "resolutionNull", type: "resolution" }
    ]
  },
  {
    display: "Create New Agreement",
    action: "createAgreementA",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement hash",
        field: "agreementHash",
        type: "bytes32"
      },
      {
        display: "Agreement URI",
        field: "agreementURI",
        type: "string"
      },
      {
        display: "Address of party A",
        field: "participants_1",
        type: "address"
      },
      {
        display: "Address of party B",
        field: "participants_2",
        type: "address"
      },
      {
        display: "Address of arbitrator",
        field: "participants_3",
        type: "address"
      },
      {
        display: "Amount that party A is staking",
        field: "quantities_1",
        type: "integer"
      },
      {
        display: "Amount that party B is staking",
        field: "quantities_2",
        type: "integer"
      },
      {
        display: "Amount that party A pays arbitrator upfront",
        field: "quantities_3",
        type: "integer"
      },
      {
        display: "Amount that party B pays arbitrator upfront",
        field: "quantities_4",
        type: "integer"
      },
      {
        display: "Fee for arbitrator if there is a dispute",
        field: "quantities_5",
        type: "integer"
      },
      {
        display: "Automatic resolution wei to party A",
        field: "quantities_6",
        type: "integer"
      },
      {
        display: "# of days to respond to arbitration (16 bit)",
        field: "quantities_7",
        type: "integer"
      },
      {
        display: "Timestamp when arbitration allowed (32 bit)",
        field: "quantities_8",
        type: "integer"
      },
      {
        display: "Timestamp when auto-resolution allowed (32 bit)",
        field: "quantities_9",
        type: "integer"
      },
      {
        display: "Arbitration extra data",
        field: "arbExtraData",
        type: "bytes"
      }
    ],
    transaction: [
      "agreementHash",
      "agreementURI",
      ["participants_1", "participants_2", "participants_3"],
      ["quantities_1", "quantities_2", "quantities_3", "quantities_4", "quantities_5", "quantities_6", "quantities_7", "quantities_8", "quantities_9"],
      "arbExtraData",
      "payableAmount"
    ]
  },
  {
    display: "Withdraw Funds",
    action: "withdraw",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Request Arbitration",
    action: "requestArbitration",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID", "payableAmount"]
  },
  {
    display: "Resolve As Arbitrator",
    action: "resolveAsArbitrator",
    params: [      
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Resolution wei",
        field: "resolutionWei",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "resolutionWei", "distributeFunds"]
  },
  {
    display: "Early Withdraw",
    action: "earlyWithdrawA",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Resolve As Party",
    action: "resolveAsParty",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Resolution wei",
        field: "resolutionWei",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "resolutionWei", "distributeFunds"]
  },
  {
    display: "Request Automatic Resolution",
    action: "requestAutomaticResolution",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "distributeFunds"]
  },
  {
    display: "Withdraw Dispute Fee",
    action: "withdrawDisputeFee",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Submit Evidence",
    action: "submitEvidence",
    params: [   
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Evidence",
        field: "evidence",
        type: "string"
      }
    ],
    transaction: ["agreementID", "evidence"]
  },
  {
    display: "Request Default Judgment",
    action: "requestDefaultJudgment",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "distributeFunds"]
  },
  {
    display: "Deposit Funds",
    action: "depositB",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID", "payableAmount"]
  }
];

export const ACTIONS_LIST_ERC20_SIMPLE = [
  {
    display: "Get State",
    action: "getState",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    read: ["agreementID"],
    response: [
      [
        { field: "partyAAddress", type: "address" },
        { field: "partyBAddress", type: "address" },
        { field: "arbitratorAddress", type: "address" },
        { field: "partyAToken", type: "address" },
        { field: "partyBToken", type: "address" },
        { field: "arbitratorToken", type: "address" }
      ],
      [
        { field: "partyAResolutionTokenAPayA", type: "resolution" }, 
        { field: "partyAResolutionTokenBPayA", type: "resolution" }, 
        { field: "partyBResolutionTokenAPayA", type: "resolution" }, 
        { field: "partyBResolutionTokenBPayA", type: "resolution" }, 
        { field: "resolutionTokenAPayA", type: "resolution" }, 
        { field: "resolutionTokenBPayA", type: "resolution" }, 
        { field: "automaticResolutionTokenAPayA", type: "resolution" }, 
        { field: "automaticResolutionTokenBPayA", type: "resolution" }, 
        { field: "partyAStakeAmount", type: "integer" }, 
        { field: "partyBStakeAmount", type: "integer" }, 
        { field: "partyAInitialArbitratorFee", type: "integer" }, 
        { field: "partyBInitialArbitratorFee", type: "integer" }, 
        { field: "disputeFee", type: "integer" }, 
        { field: "nextArbitrationStepAllowedAfterTimestamp", type: "timestamp" }, 
        { field: "autoResolveAfterTimestamp", type: "timestamp" }, 
        { field: "daysToRespondToArbitrationRequest", type: "integer" }, 
        { field: "partyATokenPower", type: "integer" }, 
        { field: "partyBTokenPower", type: "integer" }, 
        { field: "arbitratorTokenPower", type: "integer" }, 
        { field: "amountDisputePaidPartyA", type: "integer" }, 
        { field: "amountDisputePaidPartyB", type: "integer" }, 
        { field: "amountPaidToArbitrator", type: "integer" }, 
        { field: "disputeID", type: "integer"} 
      ],
      [
        { field: "partyAStakePaid", type: "boolean" }, 
        { field: "partyBStakePaid", type: "boolean" }, 
        { field: "partyARequestedArbitration", type: "boolean" }, 
        { field: "partyBRequestedArbitration", type: "boolean" }, 
        { field: "partyAWithdrew", type: "boolean" }, 
        { field: "partyBWithdrew", type: "boolean" }, 
        { field: "partyAResolvedLast", type: "boolean" }, 
        { field: "arbitratorResolved", type: "boolean" }, 
        { field: "arbitratorWithdrewDisputeFee", type: "boolean" }, 
        { field: "partyADisputeFeeLiability", type: "boolean" }, 
        { field: "partyBDisputeFeeLiability", type: "boolean" }, 
        { field: "disputeCreated", type: "boolean" }
      ],
      { field: "arbitrationExtraData", type: "bytes" }
    ]
  },
  {
    display: "Get Number Of Agreements",
    action: "getNumberOfAgreements",
    params: [],
    read: [],
    response: [
      { field: "agreementCount", type: "integer" }
    ]
  },
  {
    display: "Get Null Resolution",
    action: "getResolutionNull",
    params: [],
    read: [],
    response: [
      { field: "resolutionNullA", type: "resolution" },      
      { field: "resolutionNullB", type: "resolution" }
    ]
  },
  {
    display: "Create New Agreement",
    action: "createAgreementA",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement hash",
        field: "agreementHash",
        type: "bytes32"
      },
      {
        display: "Agreement URI",
        field: "agreementURI",
        type: "string"
      },
      {
        display: "Address of party A",
        field: "addresses_1",
        type: "address"
      },
      {
        display: "Address of party B",
        field: "addresses_2",
        type: "address"
      },
      {
        display: "Address of arbitrator",
        field: "addresses_3",
        type: "address"
      },
      {
        display: "Token that party A is depositing (0 for ETH)",
        field: "addresses_4",
        type: "address"
      },
      {
        display: "Token that party B is depositing (0 for ETH)",
        field: "addresses_5",
        type: "address"
      },
      {
        display: "Token that arbitrator is paid in (0 for ETH)",
        field: "addresses_6",
        type: "address"
      },
      {
        display: "Amount that party A is staking",
        field: "quantities_1",
        type: "integer"
      },
      {
        display: "Amount that party B is staking",
        field: "quantities_2",
        type: "integer"
      },
      {
        display: "Amount that party A pays arbitrator upfront",
        field: "quantities_3",
        type: "integer"
      },
      {
        display: "Amount that party B pays arbitrator upfront",
        field: "quantities_4",
        type: "integer"
      },
      {
        display: "Fee for arbitrator if there is a dispute",
        field: "quantities_5",
        type: "integer"
      },
      {
        display: "Automatic resolution of token A to party A",
        field: "quantities_6",
        type: "integer"
      },
      {
        display: "Automatic resolution of token B to party A",
        field: "quantities_7",
        type: "integer"
      },
      {
        display: "# of days to respond to arbitration (16 bit)",
        field: "quantities_8",
        type: "integer"
      },
      {
        display: "Timestamp when arbitration allowed (32 bit)",
        field: "quantities_9",
        type: "integer"
      },
      {
        display: "Timestamp when auto-resolution allowed (32 bit)",
        field: "quantities_10",
        type: "integer"
      },
      {
        display: "Token A rounding power",
        field: "quantities_11",
        type: "integer"
      },
      {
        display: "Token B rounding power",
        field: "quantities_12",
        type: "integer"
      },
      {
        display: "Arbitrator token rounding power",
        field: "quantities_13",
        type: "integer"
      },
      {
        display: "Arbitration extra data",
        field: "arbExtraData",
        type: "bytes"
      }
    ],
    transaction: [
      "agreementHash",
      "agreementURI",
      ["addresses_1", "addresses_2", "addresses_3", "addresses_4", "addresses_5", "addresses_6"],
      ["quantities_1", "quantities_2", "quantities_3", "quantities_4", "quantities_5", "quantities_6", "quantities_7", "quantities_8", "quantities_9", "quantities_10", "quantities_11", "quantities_12", "quantities_13"],
      "arbExtraData",
      "payableAmount"
    ]
  },
  {
    display: "Withdraw Funds",
    action: "withdraw",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Request Arbitration",
    action: "requestArbitration",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID", "payableAmount"]
  },
  {
    display: "Resolve As Arbitrator",
    action: "resolveAsArbitrator",
    params: [   
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Resolution token A",
        field: "resTokenA",
        type: "integer"
      },
      {
        display: "Resolution token B",
        field: "resTokenB",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "resTokenA", "resTokenB", "distributeFunds"]
  },
  {
    display: "Early Withdraw",
    action: "earlyWithdrawA",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Resolve As Party",
    action: "resolveAsParty",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Resolution token A",
        field: "resTokenA",
        type: "integer"
      },
      {
        display: "Resolution token B",
        field: "resTokenB",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "resTokenA", "resTokenB", "distributeFunds"]
  },
  {
    display: "Request Automatic Resolution",
    action: "requestAutomaticResolution",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "distributeFunds"]
  },
  {
    display: "Withdraw Dispute Fee",
    action: "withdrawDisputeFee",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID"]
  },
  {
    display: "Submit Evidence",
    action: "submitEvidence",
    params: [   
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Evidence",
        field: "evidence",
        type: "string"
      }
    ],
    transaction: ["agreementID", "evidence"]
  },
  {
    display: "Request Default Judgment",
    action: "requestDefaultJudgment",
    params: [
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      },
      {
        display: "Distribute funds",
        field: "distributeFunds",
        type: "boolean"
      }
    ],
    transaction: ["agreementID", "distributeFunds"]
  },
  {
    display: "Deposit Funds",
    action: "depositB",
    params: [
      {
        display: "Wei to send with transaction",
        field: "payableAmount",
        type: "payable"
      },
      {
        display: "Agreement ID",
        field: "agreementID",
        type: "integer"
      }
    ],
    transaction: ["agreementID", "payableAmount"]
  }
];

export function getFieldType(contractType, actn, fld) {
  let actionObj = getActionObj(contractType, actn);
  if (actionObj) {
    let params = actionObj.params;
    for (let i = 0; i < params.length; i++) {
      let paramObj = params[i];
      if (paramObj.field === fld) {
        return paramObj.type;
      }
    }
  }

  return "";
}

export function getActionList(contractType) {
  if (contractType === "simple_eth") {
    return ACTIONS_LIST_ETH_SIMPLE;
  }
  else if (contractType === "simple_erc20") {
    return ACTIONS_LIST_ERC20_SIMPLE;
  }

  return [];
}

export function getActionObj(contractType, actn) {
  let actionList = getActionList(contractType);

  for (let i = 0; i < actionList.length; i++) {
    let actionObj = actionList[i];
    if (actionObj.action === actn) {
      return actionObj;
    }
  }

  return null;
}

export function field(contractType, actn, fld) {
  let rawContracts = store.getState().rawContracts;
  let type = getFieldType(contractType, actn, fld);
  let inputKey = contractType + "_" + actn + "_" + fld;
  let rawValue = "";
  if (rawContracts.hasOwnProperty(inputKey)) {
    rawValue = rawContracts[inputKey];
  }

  if (type === "integer") {
    if (rawValue === "") {
      return "0";
    }
    else {
      return rawValue;
    }
  }
  else if (type === "payable") {
    return {
      value: ethers.utils.bigNumberify(rawValue.toString())
    };
  }

  return rawValue;
}

export function handleSubmit(actn, contractType) {
  return function(dispatch) {
    handleSubmitPromise(actn, contractType).then((resp) => {}).catch((err) => {});
  }
}

export async function runFunction(fn, contractType, actionObj) {
  let baseArgs = []; // "41"
  if (actionObj.hasOwnProperty("read")) {
    baseArgs = actionObj.read;
  }
  else if (actionObj.hasOwnProperty("transaction")) {
    baseArgs = actionObj.transaction;
  }

  let args = [];
  for (let i = 0; i < baseArgs.length; i++) {
    let fld = baseArgs[i];
    if (typeof fld === "string") {
      let val = field(contractType, actionObj.action, fld);
      args.push(val);
    }
    else if (Array.isArray(fld)) {
      let val = [];
      for (let j = 0; j < fld.length; j++) {
        let subFld = fld[j];
        let subVal = field(contractType, actionObj.action, subFld);
        val.push(subVal);
      }
      args.push(val);
    }
  }

  return await fn.apply(this, args || []);
}

export function getTemplateFieldObj(template, i, j, hasCol) {
  let fieldObj = { field: "unknown", type: "" };

  if (hasCol) {
    if (template && i < template.length && j < template[i].length) {
      fieldObj = template[i][j];
    }
  }
  else {
    if (template && i < template.length) {
      fieldObj = template[i];
    }
  }

  return fieldObj;
}

export function getHumanReadableResponse(rawResp, template, makeFlat) {
  let resp = [];
  let flatResp = [];
  if (rawResp && Array.isArray(rawResp)) {
    for (let i = 0; i < rawResp.length; i++) {
      let field = rawResp[i];
      let val = null;
      if (Array.isArray(field)) {
        val = [];
        for (let j = 0; j < field.length; j++) {
          let subField = field[j];
          let fieldInfo = getTemplateFieldObj(template, i, j, true);
          let subVal = shared.readRawFieldValue(subField, fieldInfo.type);
          val.push({[fieldInfo.field]: subVal});
          flatResp.push({[fieldInfo.field]: subVal});
        }
      }
      else {
        let fieldInfo = getTemplateFieldObj(template, i, 0, false);
        let tempVal = shared.readRawFieldValue(field, fieldInfo.type);
        val = {[fieldInfo.field]: tempVal};
        flatResp.push({[fieldInfo.field]: tempVal});
      }

      resp.push(val);
    }
  }
  else {
    let fieldInfo = getTemplateFieldObj(template, 0, 0, false);
    let val = shared.readRawFieldValue(rawResp, fieldInfo.type);
    resp.push({[fieldInfo.field]: val});
    flatResp.push({[fieldInfo.field]: val});
  }

  if (makeFlat) {
    return flatResp;
  }
  else {
    return resp;
  }
}

export async function handleSubmitPromise(actn, c) {

  let actionObj = getActionObj(c, actn);
  if (actionObj) {
    let contract = getEthersJSContractForAgreement(c);
    let receipt = null;
    let txObj = null;

    try {
      txObj = await runFunction(contract[actn], c, actionObj);

      if (actionObj.hasOwnProperty("read")) {
        let respObj = getHumanReadableResponse(txObj, actionObj.response, true);
        store.dispatch({
          type: "RAW_CONTRACTS_CHANGE_INPUT_VALUE",
          payload: { name: c + "_" + actionObj.action + "_response", value: JSON.stringify(respObj) }
        });
      }
      else {
        const hash = txObj ? txObj.hash : "";
        receipt = await window.ethersProvider.waitForTransaction(hash);  
      }
    }
    catch (err) {
      receipt = null
    }

    return receipt;
  }
}