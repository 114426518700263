import update from 'immutability-helper';

const emptyState = {
  stateData: {},
  evidenceData: {}
}

const BlockchainReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "BLOCKCHAIN_STATE_DATA" : {
      let stateData = update(
        state.stateData || {}, 
        {$merge: action.payload}
      );

      //let stateData = JSON.parse(JSON.stringify(action.payload));
      return {...state, stateData};
    }
    case "BLOCKCHAIN_EVIDENCE" : {
      let newEvidence = action.payload;
      return {...state, evidenceData: newEvidence};
    }
    default: {
      return state
    }
  }
}

export default BlockchainReducer