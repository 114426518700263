import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogTestMode.css';
import { changeInputValue } from './DialogTestModeActions';
import DialogTitle from './DialogTitle';  
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay, tallOverlay } from './OverlayActions';
import { genURL } from './ActionUtils';
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';
import Link from './Link';

class DialogTestMode extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleCancel = this.handleCancel.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, false));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  handleCancel(name, value) {
    this.props.dispatch(closeOverlay());
  }
  
  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title="Test mode" />
          <div>Test mode allows you to try out Atstake on 
          the <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href={"https://www.rinkeby.io"}>Rinkeby Test Network</Link>, 
          using fake Ether and tokens so that you can get a feel for how Atstake works without risking anything of real value.
          </div>
          
          <div className="moveTop20">
          The following Ethereum wallets work with Rinkeby.
          </div>
          <div className="moveTop10">
            <ul>
              <li>On mobile: <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://wallet.coinbase.com">Coinbase Wallet</Link></li>
              {/*and <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://alphawallet.com/">Alpha Wallet</Link>.*/}
              <li>On desktop: <Link noTarget={true} rel="noopener noreferrer" className="bluelink" href="https://metamask.io">Metamask</Link></li>
            </ul>
          </div>

          <div className="moveTop20"> 
          You can get fake Ether and tokens to use in test mode {/*by first linking a Twitter, Facebook, or Reddit account to your 
          Atstake account (from your Account page) and then*/} by using <Link href={genURL("/faucet")} className="bluelink" noTarget={true} rel="noopener noreferrer">our faucet</Link>.</div>
          <div className="moveTop20">Enable test mode by using your wallet to switch to the Rinkeby Test Network. Disable test mode by switching back to the Main Ethereum Network.</div>
          <div className='moveTop20'>
            <ButtonAction name="cancel" minWidth={DEFAULT_BUTTON_WIDTH} className="moveRight20" text="OK" onClick={this.handleCancel}/>
          </div>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogTestMode: state.dialogTestMode
  }
}

const DialogTestModeContainer = connect(mapStateToProps, null)(DialogTestMode);
export default DialogTestModeContainer;