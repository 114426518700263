//import update from 'immutability-helper';

const emptyState = {
  contractType: "simple_eth",
  deployedAgreementId: ""
}

const RawContractsReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "RAW_CONTRACTS_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value };
    }
    default: {
      return state
    }
  }
}

export default RawContractsReducer