import React, { Component } from 'react';
import './Common.css';
import './InputTextarea.css';

class InputTextarea extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
      let value = event.currentTarget.value;

      if (this.props.hasOwnProperty("maxlength")) {
        let maxlength = parseInt(this.props.maxlength, 10);

        if (maxlength !== 0 && value.length > maxlength) {
          value = value.substring(0, maxlength);
        }
      }
      
      if(this.props.hasOwnProperty("onChange")) {
        this.props.onChange(event.currentTarget.getAttribute("name"), value);
      }
    }

    render() {  
        let disabled = this.props.disabled ? this.props.disabled : false;
        let placeholder = this.props.placeholder ? this.props.placeholder : "";
        let value = this.props.value ? this.props.value : "";

        return (
          <textarea placeholder={placeholder} readOnly={disabled} className={this.props.className + (disabled ? " disabled" : "")} name={this.props.name} value={value} onChange={this.handleChange} />
        );
    }
  }
  
  export default InputTextarea;
