import React, { Component } from 'react';
import { connect } from 'react-redux';
import './CategoryBar.css';
import { getCategoryTree, genMarketsURL } from './ActionUtils';
import Breadcrumb from './Breadcrumb';
import Link from './Link';
import { shared } from '../Shared';
import { openOverlay } from './OverlayActions';

class CategoryBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCategory: ""
    };
    this.toggleHover = this.toggleHover.bind(this);
    this.selectCategory = this.selectCategory.bind(this);
    this.openNewPostingDialog = this.openNewPostingDialog.bind(this);
    this.timeoutNum = null;
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutNum);
  }  

  toggleHover(show, category) {
    clearTimeout(this.timeoutNum);

    if (show) {
      this.timeoutNum = window.setTimeout(() => {
        this.selectCategory(category);
      }, 300);
    }
    else {
      this.timeoutNum = window.setTimeout(() => {
        this.selectCategory("");
      }, 500);
    }
  }

  selectCategory(category) {
    this.setState({
      showCategory: category
    });
  }

  openNewPostingDialog(event) {
    this.props.dispatch(openOverlay("new_posting", {}));
  }

  render() {
    
    let showCategory = this.state.showCategory;
    let showCategoryExpand = showCategory !== "";
    let loggedin = this.props.auth.status === "loggedin";
    let categoryTreeLoaded = this.props.auth.categoryTreeLoaded;

    let breadLocation = "";
    let breadType = "";
    if (showCategory !== "location") {
      breadLocation = this.props.location === "" || !this.props.location ? "global" : this.props.location;
      breadType = showCategory;
    }
    let breadCategory = "";
    let categoryList = getCategoryTree(breadLocation, breadType, breadCategory, true);
    // Hide showing categories for type other
    if (breadType === "other") {
      categoryList = [];
    }

    let categoryTitle = "";
    let categoryLink = "";
    if (showCategory === "location") {
      categoryTitle = "Location";
      categoryLink = "/postings";
    }
    else {
      categoryTitle = shared.getBreadcrumbDisplay(breadType, "type");
      categoryLink = genMarketsURL({
        breadLocation: breadLocation,
        breadType: breadType,
        breadCategory: ""
      });
    }

    let breadList = [];

    if (breadLocation !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadLocation, "location"), field: breadLocation, level: "location" }
      );
    }
    else {
      breadList.push(
        { display: shared.getBreadcrumbDisplay("choose", "location"), field: "choose", level: "location" }
      );
    }

    if (breadType !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadType, "type"), field: breadType, level: "type" }
      );  
    }

    if (breadCategory !== "") {
      breadList.push(
        { display: shared.getBreadcrumbDisplay(breadCategory, "category"), field: breadCategory, level: "category" }
      );  
    }

    let categoryContentElemA = [];
    let categoryContentElemB = [];
    let titleElem = [];
    titleElem.push(<div key="title" className="navTitleRow"><Link href={categoryLink} noTarget={true} rel="noopener noreferrer" className="navTitle">{categoryTitle}</Link></div>);
    titleElem.push(<div key="crumb" className="navCrumbRow"><Breadcrumb noTarget={true} list={breadList} /></div>);

    let midpointIndex = Math.floor((categoryList.length + 1) / 2); // Alphebetize so first column is before entire second column
    for (let i = 0; i < categoryList.length; i++) {
      let categoryObj = categoryList[i];
      let url = genMarketsURL(categoryObj);
      let elem = <div key={i} className="navNameRow"><Link href={url} noTarget={true} rel="noopener noreferrer" className="navName">{categoryObj.display}</Link></div>;
      if (i < midpointIndex) {
        categoryContentElemA.push(elem);
      }
      else {
        categoryContentElemB.push(elem);
      }
    }

    // We've disabled this elsewhere for now
    if (showCategory === "other") {
      categoryContentElemA.push(<div key="other" className="navNameRow">
        Looking for something that isn't a service, item or rental? <Link href={"/postings?location=" + breadLocation + "&type=other"} noTarget={true} rel="noopener noreferrer" style={{whiteSpace: "nowrap"}} className="bluelink">Click to explore</Link></div>);
    }

    if (categoryContentElemA.length === 0 && categoryTreeLoaded) {
      categoryContentElemA.push(<div key="no_results" className="navNameRow">
        <Link href={categoryLink} noTarget={true} rel="noopener noreferrer" className="bluelink">{categoryTitle}</Link> doesn't have any postings yet in {shared.getBreadcrumbDisplay(breadLocation, "location")}.{loggedin && <span> <span onClick={this.openNewPostingDialog} className="bluelink">Be the first to post</span>.</span>}</div>);
    }

    return (
      <div className="seperatorCardOuter">
        <div className="seperatorCardInner">
          <table className="navTable">
            <tbody>
              <tr>
                <td className="navCell textLeft"><Link href="/postings?more=1" noTarget={true} rel="noopener noreferrer" className={showCategory === "location" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "location")} onMouseLeave={() => this.toggleHover(false, "location")}>Location</Link></td>
                <td className="navCell textCenter"><span className="navText">Service:&nbsp;</span><span className="navNoWrap"><Link href={"/postings?location=" + breadLocation + "&type=service_offered"} noTarget={true} rel="noopener noreferrer" className={showCategory === "service_offered" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "service_offered")} onMouseLeave={() => this.toggleHover(false, "service_offered")}>Offered</Link><span className="navText">&nbsp;/&nbsp;</span><Link href={"/postings?location=" + breadLocation + "&type=service_wanted"} noTarget={true} rel="noopener noreferrer" className={showCategory === "service_wanted" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "service_wanted")} onMouseLeave={() => this.toggleHover(false, "service_wanted")}>Wanted</Link></span></td>
                <td className="navCell textCenter"><span className="navText">Item:&nbsp;</span><span className="navNoWrap"><Link href={"/postings?location=" + breadLocation + "&type=item_offered"} noTarget={true} rel="noopener noreferrer" className={showCategory === "item_offered" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "item_offered")} onMouseLeave={() => this.toggleHover(false, "item_offered")}>For Sale</Link><span className="navText">&nbsp;/&nbsp;</span><Link href={"/postings?location=" + breadLocation + "&type=item_wanted"} noTarget={true} rel="noopener noreferrer" className={showCategory === "item_wanted" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "item_wanted")} onMouseLeave={() => this.toggleHover(false, "item_wanted")}>Wanted</Link></span></td>
                <td className="navCell textCenter"><span className="navText">Rental:&nbsp;</span><span className="navNoWrap"><Link href={"/postings?location=" + breadLocation + "&type=rental_offered"} noTarget={true} rel="noopener noreferrer" className={showCategory === "rental_offered" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "rental_offered")} onMouseLeave={() => this.toggleHover(false, "rental_offered")}>Offered</Link><span className="navText">&nbsp;/&nbsp;</span><Link href={"/postings?location=" + breadLocation + "&type=rental_wanted"} noTarget={true} rel="noopener noreferrer" className={showCategory === "rental_wanted" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "rental_wanted")} onMouseLeave={() => this.toggleHover(false, "rental_wanted")}>Wanted</Link></span></td>
                {/*<td className="navCell textRight"><Link href={"/postings?location=" + breadLocation + "&type=other"} noTarget={true} rel="noopener noreferrer" className={showCategory === "other" ? "navLinkSelected" : "navLink"} onMouseEnter={() => this.toggleHover(true, "other")} onMouseLeave={() => this.toggleHover(false, "other")}>Other</Link></td>*/}
              </tr>
            </tbody>
          </table>
          {showCategoryExpand && (
            <div className="navCategoriesAnchor">
              <div className="navCategories" onMouseEnter={() => this.toggleHover(true, showCategory)} onMouseLeave={() => this.toggleHover(false, showCategory)} >
                <table style={{width: "100%"}}><tbody>
                    <tr>
                      <td colSpan="2">
                        <div style={{padding:"10px 20px 0px 20px"}}>{titleElem}</div>
                      </td>
                    </tr>
                    <tr>
                    <td style={{verticalAlign:"top"}}>
                      <div className="navColumn">{categoryContentElemA}</div>
                    </td>
                    <td style={{verticalAlign:"top"}}>
                      <div className="navColumn">{categoryContentElemB}</div>
                    </td>
                  </tr>
                </tbody></table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const CategoryBarContainer = connect(mapStateToProps, null)(CategoryBar);
export default CategoryBarContainer;