import { 
  apiRequest,
  apiRequestPost, 
  resultsNewByType, 
  apiGetAgreement, 
  getComments, 
  newComment, 
  checkPendingTransactions,
  dateStringToUnix, 
  apiSelectVersion, 
  apiUnselectVersion, 
  apiHideAgreement, 
  apiUnhideAgreement, 
  apiFinalizeAgreement, 
  apiArbitratorStatus, 
  apiImplicitSelectVersion, 
  apiAddTransactionHash,
  apiUpdateAgreementTransaction, 
  apiGetUser, 
  getUserLinkElement,
  apiFavorite,
  getSelectedArbitrator,
  apiGetAgreementTransactions,
  apiCheckForDeploymentTransaction
} from "./ActionUtils"
import { updateBlockchainSyncList, initBlockchainFromCache, reloadBlockchainSyncList, deployNewAgreement } from "./BlockchainActions"
import store from '../store';
import { navNewURL } from './ActionUtils';
import { track } from './ActionUtils';
import { openOverlay, closeOverlay } from './OverlayActions';
import { cleanPhoneNumber } from './DialogAddAttestationActions';
import React from 'react';
import { shared } from '../Shared';
import assert from 'assert';
import { hasDeployTransactionUpdate } from './AuthActions';

export function navAccountPage() {
  return function(dispatch) {
    dispatch(navNewURL('/account'));
    dispatch(closeOverlay());
  }
}

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function changeArbitrationMode(value) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_CHANGE_ARBITRATION_MODE',
      payload: {value}
    });
  }
}

export function changeAutosuggestInput(name, value, search) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });
  }
}

export function changeAutosuggestResults(name, value, search, max, more, selected, nonce) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let exclude = agreement.user2Locked;
    
    dispatch({
      type: 'AGREEMENT_CHANGE_AUTOSUGGEST_INPUT',
      payload: {name, value, search}
    });

    let filter = (name === "searchArbitratorAutosuggest") ? "arbitrator" : "";

    apiRequest("autosuggest", { input: value, search: search, max: max, uuid: auth.uuid, username: auth.username, exclude, filter }).then((response) => {
      let data = response.data;
      let hasMore = response.hasMore && more;
      let exactMatchMap = response.exactMatchMap;
      
      let results = [];
      let selectedResult = null;
      let resultsFromMatch = data || [];
      for (let i = 0; i < resultsFromMatch.length; i++) {
        let resultEntry = resultsFromMatch[i];
        let type = resultEntry.type;
        if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
          selectedResult = resultEntry;
        }
        else {
          results.push(resultEntry);
        }
      }

      if (name !== "searchArbitratorAutosuggest") {
        let resultsByType = resultsNewByType(search, value, exactMatchMap);
        for (let i = 0; i < resultsByType.length; i++) {
          let resultEntry = resultsByType[i];
          let type = resultEntry.type;
          if (selected && selected.hasOwnProperty(type) && selected[type] === resultEntry[type].value) {
            selectedResult = resultEntry;
          }
          else {
            results.push(resultEntry);
          }
        }
      }

      if (selectedResult !== null) {
        selectedResult.selected = true;
        results.unshift(selectedResult);
      }

      dispatch({
        type: 'AGREEMENT_CHANGE_AUTOSUGGEST_RESULTS',
        payload: {name, results: results, more: hasMore, match: exactMatchMap, nonce: nonce }
      });
    });
  }
}

export function changeAutosuggestSelection(name, type, value, arbitration) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_CHANGE_AUTOSUGGEST_SELECTION',
      payload: { name, type, value, arbitration }
    });
  }
}

export function handleAutosuggestFavorite(name, contact, oldFavoriteSelected) {
  return function(dispatch) {    
    let favoriteSelected = !oldFavoriteSelected;
    let auth = store.getState().auth;
    dispatch({
      type: 'AGREEMENT_AUTOSUGGEST_FAVORITE',
      payload: {name, contact, favoriteSelected, favoritePending:true}
    });
    apiFavorite(auth.uuid, auth.username, contact, favoriteSelected).then((success) => {
      if (success) {
        dispatch({
          type: 'AGREEMENT_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected, favoritePending:false}
        });
        dispatch(track("action", "button", JSON.stringify({type:"autosuggest_favorite", contact, favoriteSelected})));
      }
      else {
        dispatch({
          type: 'AGREEMENT_AUTOSUGGEST_FAVORITE',
          payload: {name, contact, favoriteSelected: oldFavoriteSelected, favoritePending:false}
        });
      }
    });
  }
}


export function clearAutosuggestResults(name, input, nonce, setArbitrationInfo) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_CLEAR_AUTOSUGGEST_RESULTS',
      payload: {name, nonce}
    });
    
    if (input !== "") {
      if (name === "searchPartyAutosuggest" && shared.isEmail(input)) {
        dispatch({
          type: 'AGREEMENT_CHANGE_AUTOSUGGEST_SELECTION',
          payload: {name, type: "email", value: input, arbitration: null }
        });
      }
      else {
        apiGetUser(input).then((response) => {
          let { hasUsername=false, arbitration_settings=null } = response;
          if (hasUsername) {
            let arbitration = setArbitrationInfo ? arbitration_settings : null;
            dispatch({
              type: 'AGREEMENT_CHANGE_AUTOSUGGEST_SELECTION',
              payload: {name, type: "atstake", value: input, arbitration }
            });    
          }
        });
      }
    }
  }
}

function getArbitrationFeeForAgreement(agreement) {
  let arbitration_fee_value = agreement.arbitrationFeeInput;
  let arbitration_fee_type = agreement.arbitrationFeeType;

  if (agreement.arbitrationMode === "default") {
    let { searchArbitratorAutosuggest={} } = agreement;
    let { selectedArbitration=null } = searchArbitratorAutosuggest;
    if (selectedArbitration) {
      arbitration_fee_value = selectedArbitration.fee_value;
      arbitration_fee_type = selectedArbitration.fee_type;
    }  
  }
  
  return {arbitration_fee_value, arbitration_fee_type};
}

export function createNewAgreement() {
  return function(dispatch) {

    dispatch({
      type: 'AGREEMENT_CREATE_PENDING',
      payload: {pending:true}
    });

    let auth = store.getState().auth;
    let agreement = store.getState().agreement;

    let user2_linkid = "";
    let selectedUser2 = agreement.searchPartyAutosuggest.selected;
    if (selectedUser2 !== null) {
      let splitArr = selectedUser2.split('#');
      if (splitArr.length === 2) {
        let selectedUserType = splitArr[0];
        if (selectedUserType === "phone") {
          selectedUser2 = selectedUserType + "#" + cleanPhoneNumber(splitArr[1]);
        }
      }
      user2_linkid = selectedUser2;
    }

    let { searchArbitratorAutosuggest={} } = agreement;
    let { arbitration_fee_value="", arbitration_fee_type="" } = getArbitrationFeeForAgreement(agreement);
    let arbitration_days = agreement.daysToRespondInput;

    let arbitrator = getSelectedArbitrator(searchArbitratorAutosuggest);
    let arbitration_request_unix = dateStringToUnix(agreement.requestArbitrationDate);
    let auto_resolve_unix = dateStringToUnix(agreement.autoResolveDate);

    let params = {
      uuid: auth.uuid,
      username: auth.username,
      user1: auth.username,
      user2_linkid: user2_linkid,
      title: agreement.title,
      type: agreement.agreementType,
      amount_value1: agreement.user1ContributionInput,
      amount_value2: agreement.user2ContributionInput,
      amount_type1: agreement.user1ContributionType,
      amount_type2: agreement.user2ContributionType,
      arbitration_mode: agreement.arbitrationMode,
      arbitrator,
      arbitration_fee_value,
      arbitration_fee_type,
      arbitration_days,
      arbitration_request_unix,
      auto_resolve_unix,
      default_resolution: agreement.defaultResolution,
      visibility: agreement.agreementVisibility,
      user1_address: auth.ethAddress
    };
    let body = {
      terms: agreement.agreementTerms
    };

    apiRequestPost("new_agreement", params, body).then((response) => {
      if (response && response.status && response.status === "success") {
        dispatch({
          type: 'AGREEMENT_CREATE_PENDING',
          payload: {pending:false}
        });
  
        let agreementid = response.agreementid;
        dispatch(navNewURL('/view?agreementid=' + agreementid + '&nav=contracts'));
      }
      else {
        dispatch({
          type: 'AGREEMENT_SUBMIT_ERROR',
          payload: response
        });
  
        dispatch({
          type: 'LOG_ERROR',
          payload: {reason:"New contract failed", error:JSON.stringify(response)}
        });
      }
    }).catch((err) => {
      dispatch({
        type: 'AGREEMENT_SUBMIT_ERROR',
        payload: {
          status: "error",
          reason: "",
          errorField: "",
          errorMsg: "Unable to submit contract"
        }
      });

      dispatch({
        type: 'LOG_ERROR',
        payload: {reason:"New contract exception", error:JSON.stringify(err)}
      });
    });
  }
}

export function createNewVersion() {
  return function(dispatch) {

    dispatch({
      type: 'VERSION_CREATE_PENDING',
      payload: {pending:true}
    });

    let auth = store.getState().auth;
    let agreement = store.getState().agreement;

    let agreementid = agreement.agreementid;

    let { searchArbitratorAutosuggest={} } = agreement;
    let { arbitration_fee_value="", arbitration_fee_type="" } = getArbitrationFeeForAgreement(agreement);
    let arbitration_days = agreement.daysToRespondInput;

    let arbitrator = getSelectedArbitrator(searchArbitratorAutosuggest);    
    let arbitration_request_unix = dateStringToUnix(agreement.requestArbitrationDate);
    let auto_resolve_unix = dateStringToUnix(agreement.autoResolveDate);

    let params = {
      uuid: auth.uuid,
      username: auth.username,
      agreementid: agreementid,
      user1: auth.username,
      title: agreement.title,
      type: agreement.agreementType,
      amount_value1: agreement.user1ContributionInput,
      amount_value2: agreement.user2ContributionInput,
      amount_type1: agreement.user1ContributionType,
      amount_type2: agreement.user2ContributionType,
      arbitration_mode: agreement.arbitrationMode,
      arbitrator,
      arbitration_fee_value,
      arbitration_fee_type,
      arbitration_days,
      arbitration_request_unix,
      auto_resolve_unix,
      default_resolution: agreement.defaultResolution
    };
    let body = {
      terms: agreement.agreementTerms
    };

    apiRequestPost("new_version", params, body).then((response) => {

      if (response && response.status && response.status === "success") {
        dispatch({
          type: 'VERSION_CREATE_PENDING',
          payload: {pending:false}
        });

        let agreementid = response.agreementid;
        let versionid = response.versionid;
        dispatch(navNewURL('/view?agreementid=' + agreementid + '&versionid=' + versionid + '&nav=contracts'));
      }
      else {
        dispatch({
          type: 'AGREEMENT_SUBMIT_ERROR',
          payload: response
        });
      }
    }).catch((err) => {
      dispatch({
        type: 'AGREEMENT_SUBMIT_ERROR',
        payload: {
          status: "error",
          reason: "",
          errorField: "",
          errorMsg: "Unable to submit contract"
        }
      });
    });
  }
}

// grabs the lists of pendings transactions from the DB and updates our state with it.
export function refreshTransactionsFromDB(agreementid) {
  console.log("refreshTransactionsFromDB");
  return function(dispatch) {
    let auth = store.getState().auth;
    apiGetAgreementTransactions(auth.uuid, auth.username, agreementid).then((respObj) => {
      if (respObj) {
        if (respObj.hasOwnProperty('transactions_user1')) {
          dispatch(changeInputValue("transactionsUser1", respObj.transactions_user1));
        }
        if (respObj.hasOwnProperty('transactions_user2')) {
          dispatch(changeInputValue("transactionsUser2", respObj.transactions_user2));
        }
        if (respObj.hasOwnProperty('transactions_arbitrator')) {
          dispatch(changeInputValue("transactionsArbitrator", respObj.transactions_arbitrator));
        } 
      }
    });
  }
}

// refreeshes by querying the blockchain
export function refreshMyPendingTransactions(agreementObj, curTransactionString) {
  return function(dispatch) {
    checkPendingTransactions(agreementObj, curTransactionString).then((respObj) => {
      for (let i = 0; i < respObj.listOfUpdates.length; i++) {
        let tx = respObj.listOfUpdates[i];
        dispatch(updateAgreementTransaction(tx.hash, tx.type, tx.status));
      }
      
      if (respObj.listOfUpdates.length > 0) {
        let txString = respObj.updatedTxString;
        if (agreementObj.youAreUser1) {
          dispatch(changeInputValue("transactionsUser1", txString));
        } else if (agreementObj.youAreUser2) {
          dispatch(changeInputValue("transactionsUser2", txString));
        } else if (agreementObj.youAreArbitrator) {
          dispatch(changeInputValue("transactionsArbitrator", txString));
        } 
      }
    });    
  }
}

export function refreshAgreement(agreementid, versionid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let previewid = store.getState().agreement.previewid;
    apiGetAgreement(auth.uuid, auth.username, agreementid, versionid, previewid).then((respObj) => {
      let version = null;
      let versionCount = 0;
      if (respObj.results && respObj.results.length > 0) {
        version = respObj.results[0];
      }
      if (respObj.versionCount) {
        versionCount = respObj.versionCount;
      }

      let {
        user1Address="",
        user2Address="",
        arbitratorAddress="",
        user1AddressDefault="",
        user2AddressDefault="",
        arbitratorAddressDefault=""
      } = respObj;

      let {
        agreement={}
      } = version;

      let { transactionhash="", transactiontype="", status="", deployedid="", agreed_data="", agreed_sha3="", finalized_by="", user1="", blockchain_data="", user2_linkid="" } = agreement;

      if (version) {
        if (status === "unlinked" && user1 !== auth.username && previewid !== "" && user2_linkid !== "") {
          let { attestation_type="", attestation_value="" } = shared.splitAttestationId(user2_linkid);
          if (attestation_type === "email" && attestation_value !== "") {
            if (auth.status === "loggedin") {
              dispatch(openOverlay("message", {title: "Is this your agreement?", message:<span>Is {attestation_value}{" your email address? Visit the "}<span onClick={() => dispatch(navAccountPage())} className="blacklink">{"Account page"}</span>{" to link your email address and interact with this agreement."}</span>}));
            }
            else {
              dispatch(openOverlay("message", {title: "Is this your agreement?", message:<span>Is {attestation_value}{" your email address? Log in and visit the Account page to link your email address and interact with this agreement."}</span>}));
            }
          }
        }

        dispatch({
          type: 'AGREEMENT_SELECTED',
          payload: {
            version, 
            username: auth.username, 
            agreementid, 
            versionid, 
            versionCount,
            user1Address, 
            user2Address, 
            arbitratorAddress,
            user1AddressDefault,
            user2AddressDefault,
            arbitratorAddressDefault,
            transactionhash,
            transactiontype,
            status
          }
        });

        if (transactionhash !== "" && transactiontype !== "" && (status === "agreed" || status === "proposed")) {
          dispatch(hasDeployTransactionUpdate(agreementid, transactiontype, "remote", "hash", transactionhash));
        }

        if (status === "finalized" && deployedid && deployedid !== "") {    
          let syncList = [{
            agreementid, 
            deployedid, 
            transactiontype, 
            transactionhash,
            agreed_data,
            agreed_sha3,
            user1_finalized: (finalized_by === user1),
            blockchain_data
          }];

          dispatch(initBlockchainFromCache(syncList));
          dispatch(updateBlockchainSyncList(syncList));
        }
      }
      else {
        dispatch({
          type: 'AGREEMENT_PERMISSION_DENIED',
          payload: true
        });
      }
    }).catch((err) => {
      dispatch({
        type: 'AGREEMENT_PERMISSION_DENIED',
        payload: true
      });
    });
  }
}

export function refreshComments(agreementid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    getComments(auth.uuid, auth.username, agreementid).then((list) => {
      dispatch({
        type: 'AGREEMENT_COMMENTS',
        payload: {list, username: auth.username}
      });
    });
  }
}

export function createComment(agreementid, text) {
  return function(dispatch) {
    dispatch({
      type: 'AGREEMENT_COMMENT_SUBMITTING',
      payload: true
    });

    let auth = store.getState().auth;
    newComment(auth.uuid, auth.username, agreementid, text).then((comment) => {
      if (comment !== null) {
        dispatch({
          type: 'AGREEMENT_NEW_COMMENT',
          payload: {comment}
        });
      }
      else {
        dispatch({
          type: 'AGREEMENT_COMMENT_SUBMITTING',
          payload: false
        });
      }
    });
  }
}

export function selectVersion() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    let versionid = agreement.versionid;

    let user1Locked = agreement.user1Locked;
    let user2Locked = agreement.user2Locked;
    let versionArbitrator = agreement.versionArbitrator;

    if (user1Locked === versionArbitrator || user2Locked === versionArbitrator) {
      let user1Element = getUserLinkElement(user1Locked, user1Locked);
      let user2Element = getUserLinkElement(user2Locked, user2Locked);
      dispatch(openOverlay("message", {title: "Arbitrator cannot be party in agreement", message:<span>{"The arbitrator cannot be "}{user2Element} or {user1Element}{". Please choose a different arbitrator."}</span>}));
    }
    else if (user1Locked === user2Locked) {
      let user1Element = getUserLinkElement(user1Locked, user1Locked);
      dispatch(openOverlay("message", {title: "Countparty cannot be your own account", message:<span>{"Counterparty cannot be you ("}{user1Element}{"). Please create a new agreement with a different counterparty."}</span>}));
    }
    else {
      apiSelectVersion(auth.uuid, auth.username, agreementid, versionid).then((respObj) => {
        if (respObj.success) {
          dispatch(refreshAgreement(agreementid, versionid));
        }
        else if (respObj.locked) {
          dispatch(openOverlay("message", {title: "Unable to update", message:<span>{"Unable to update at this time due to a pending transaction. Try again in a few minutes."}</span>}));
        }
      });
    }
  }
}

export function unselectVersion(navAfter) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    let versionid = agreement.versionid;
    apiUnselectVersion(auth.uuid, auth.username, agreementid).then((respObj) => {
      if (respObj.success) {
        dispatch(refreshAgreement(agreementid, versionid));
        if (navAfter !== "") {
          dispatch(navNewURL(navAfter));
        }
      }
      else if (respObj.locked) {
        dispatch(openOverlay("message", {title: "Unable to update", message:<span>{"Unable to update at this time due to a pending transaction. Try again in a few minutes."}</span>}));
      }
    });
  }
}

export function rateUser(ratingUser, oldRatingVal, oldRatingText, newRatingVal) {
  return function(dispatch) {
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    dispatch(openOverlay("review", {agreementid, user: ratingUser, rating: newRatingVal, ratingText: oldRatingText}));
  }
}

export function hideAgreement() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    dispatch({
      type: 'AGREEMENT_UPDATE_HIDDEN',
      payload: {agreementid, hidden:true, pending:true}
    });
    apiHideAgreement(auth.uuid, auth.username, agreementid).then((success) => {
      if (success) {
        dispatch({
          type: 'AGREEMENT_UPDATE_HIDDEN',
          payload: {agreementid, hidden:true, pending:false}
        });
      }
      else {
        dispatch({
          type: 'AGREEMENT_UPDATE_HIDDEN',
          payload: {agreementid, hidden:false, pending:false}
        });
      }
    });
  }
}

export function unhideAgreement() {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    dispatch({
      type: 'AGREEMENT_UPDATE_HIDDEN',
      payload: {agreementid, hidden:false, pending:true}
    });
    apiUnhideAgreement(auth.uuid, auth.username, agreementid).then((success) => {
      if (success) {
        dispatch({
          type: 'AGREEMENT_UPDATE_HIDDEN',
          payload: {agreementid, hidden:false, pending:false}
        });
      }
      else {
        dispatch({
          type: 'AGREEMENT_UPDATE_HIDDEN',
          payload: {agreementid, hidden:true, pending:false}
        });
      }
    });
  }
}

export function hideAgreementById(agreementid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch({
      type: 'AGREEMENTCARD_HIDDEN',
      payload: {agreementid, hidden:true, pending:true, username: auth.username}
    });
    apiHideAgreement(auth.uuid, auth.username, agreementid).then((success) => {
      if (success) {
        dispatch({
          type: 'AGREEMENTCARD_HIDDEN',
          payload: {agreementid, hidden:true, pending:false, username: auth.username}
        });
      }
      else {
        dispatch({
          type: 'AGREEMENTCARD_HIDDEN',
          payload: {agreementid, hidden:false, pending:false, username: auth.username}
        });
      }
    });
  }
}

export function unhideAgreementById(agreementid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    dispatch({
      type: 'AGREEMENTCARD_HIDDEN',
      payload: {agreementid, hidden:false, pending:true, username: auth.username}
    });
    apiUnhideAgreement(auth.uuid, auth.username, agreementid).then((success) => {
      if (success) {
        dispatch({
          type: 'AGREEMENTCARD_HIDDEN',
          payload: {agreementid, hidden:false, pending:false, username: auth.username}
        });
      }
      else {
        dispatch({
          type: 'AGREEMENTCARD_HIDDEN',
          payload: {agreementid, hidden:true, pending:false, username: auth.username}
        });
      }
    });
  }
}

export function checkForReplacementDeployTransaction(dispatch, agreementid) {
  console.log("checkForReplacementDeployTransaction");
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    apiCheckForDeploymentTransaction(auth.uuid, auth.username, agreementid).then((hash) => {
      if (hash) {
        console.log("checkForReplacementDeployTransaction found a replacement transaction: " + hash);
        dispatch(changeInputValue("transactionHash", hash));
      }
    });
  }
}

// contractFunction can be: "create", "deposit", "resolve_party", "resolve_arbitrator", "withdraw", "arbitration", "early_withdraw", 
//  "withdraw_fee", "auto_resolution", "default_judgment"
// updateType is the new status of the transaction. It can be: "pending" "succeeded", or "failed"   
export function updateAgreementTransaction(txHash, contractFunction, updateType) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    apiUpdateAgreementTransaction(auth.uuid, auth.username, agreementid, contractFunction, updateType, txHash).then((response) => {
      if (response) {
        console.log("Transaction_<username> data updated");
        // now sync back transactions from the DB.. 
        dispatch(refreshTransactionsFromDB(agreementid));
      } else {
        console.log("Transaction_<username> update failure");
      }
    });
  }
}

export function saveHashAgreement(txHash, contractType) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, txHash, contractType, "add").then((hasLock) => {
      if (hasLock) {
        console.log("Transaction hash saved");
      }
    });
  }
}

export function finalizeAgreement(agreedSha3, blockchainId, txHash, contractType) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    let versionid = agreement.versionid;
    apiFinalizeAgreement(auth.uuid, auth.username, agreementid, versionid, blockchainId, agreedSha3, txHash, contractType).then((isFinalized) => {
      // Refresh agreement (even if lock fails)
      if (isFinalized) {
        dispatch(track("action", "update", JSON.stringify({type:"blockchain_finalize", agreementid, versionid, blockchainId, agreedSha3, hash: txHash, contractType })));
      }

      dispatch(refreshAgreement(agreementid, versionid));
    });
  }
}

export function implicitSelectDeployAgreement(youAreUser1, youAreUser2, agreementid, versionid, agreedSha3, metaevidenceHash, agreedData, selectedUser) {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiImplicitSelectVersion(auth.uuid, auth.username, agreementid, versionid, selectedUser).then((respObj) => {
      if (respObj.success) {
        dispatch(refreshAgreement(agreementid, versionid));
        dispatch(deployAgreement(youAreUser1, youAreUser2, agreementid, respObj.agreed_sha3, respObj.metaevidence_hash, respObj.agreed_data));
      }
    });
  }
}

export function deployAgreement(youAreUser1, youAreUser2, agreementid, agreedSha3, metaevidenceHash, agreedData) {
  return function(dispatch) {

    let auth = store.getState().auth;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, "", "", "lock").then((hasLock) => {
      if (hasLock) {
        dispatch(deployNewAgreement(youAreUser1, youAreUser2, agreedSha3, metaevidenceHash, agreedData));
      }
      else {
        dispatch(openOverlay("message", {title: "Unable to deposit", message:<span>{"Unable to deposit at this time due to a pending transaction."}</span>}));
      }
    });
  }
}

export function addLockOnAgreement(agreementid) {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, "", "", "lock").then((success) => {
      //if (success) {
      //  console.log("Locked added");        
      //}
    });
  }
}

export function clearLockOnAgreement(agreementid, refreshAfter) {
  return function(dispatch) {
    let auth = store.getState().auth;
    apiAddTransactionHash(auth.uuid, auth.username, agreementid, "", "", "clear").then((success) => {
      if (success) {
        console.log("Cleared lock");
        if (refreshAfter) {
          dispatch(reloadBlockchainSyncList());
        }
      }
    });
  }
}

export function changeArbitratorStatus(newArbitrationStatus) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;
    let agreementid = agreement.agreementid;
    let arbitratorStatusPending = agreement.arbitratorStatusPending;
    let versionid = agreement.versionid;

    if (!arbitratorStatusPending) {
      let previousArbitrationStatus = agreement.agreedArbitratorStatus;
      dispatch({
        type: 'AGREEMENT_ARBITRATION_STATUS',
        payload: {agreementid, arbitrationStatus: newArbitrationStatus, pending:true}
      });
  
      apiArbitratorStatus(auth.uuid, auth.username, agreementid, newArbitrationStatus).then((success) => {
        if (success) {
          dispatch({
            type: 'AGREEMENT_ARBITRATION_STATUS',
            payload: {agreementid, arbitrationStatus: newArbitrationStatus, pending:false}
          });
          dispatch(refreshAgreement(agreementid, versionid));
        }
        else {
          dispatch({
            type: 'AGREEMENT_ARBITRATION_STATUS',
            payload: {agreementid, arbitrationStatus: previousArbitrationStatus, pending:false}
          });
        }
      });
    }
  }
}

// Some of our code makes some assumptions about our default values for simplicity.
// This function ensures that we won't forget to make the code more general if these
// assumptions ever fail to hold in the future.
export function executeAgreementAssertions() {
  let contractSettings = shared.defaultArbitrationSettings("contract");
  assert(contractSettings.auto_resolve_days === 0, "Error: contract auto_resolve_days nonzero");
  assert(contractSettings.arbitration_request_days === 0, "Error: contract arbitration_request_days nonzero");

  let paymentSettings = shared.defaultArbitrationSettings("payment");
  assert(paymentSettings.arbitration_request_days === 0, "Error: payment arbitration_request_days nonzero");
  assert(paymentSettings.default_resolution === "all_user2", "Error: payment default_resolution not all_user2");

  let requestSettings = shared.defaultArbitrationSettings("request");
  assert(requestSettings.arbitration_request_days === 0, "Error: request arbitration_request_days nonzero");
  assert(requestSettings.default_resolution === "all_user1", "Error: request default_resolution not all_user1");
}