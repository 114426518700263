import React, { Component } from 'react';
import './ButtonSelectSubtext.css';
import ButtonSelect from './ButtonSelect';

class ButtonSelectSubtext extends Component {
  render() {
    let statusList = this.props.statusList;
    let statusHighlight = this.props.statusHighlight || "";
    let selectedMsgElem = [];

    for (let i = 0; i < statusList.length; i++) {
      let statusText = statusList[i];
      let styleObj = {fontWeight:"bold"};
      if (statusText === statusHighlight) {
        styleObj = {fontWeight:"bold", textDecoration:"underline"};
      }
      selectedMsgElem.push(<div key={i} style={styleObj}>{statusText}</div>);
    }

    return (
      <div className="moveRight20 moveTop5" style={{display:"inline-block", verticalAlign:"top"}}>
        <ButtonSelect {...this.props} />
        <div style={{paddingBottom:"10px"}} className="miniText">{selectedMsgElem}</div>
      </div>
    );
  }
}
  
export default ButtonSelectSubtext;
