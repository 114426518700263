import AgreementManagerETH_Simple from '../deploy_data/contracts/AgreementManagerETH_Simple.json'
import AgreementManagerERC20_Simple from '../deploy_data/contracts/AgreementManagerERC20_Simple.json'
import AgreementManagerETH_ERC792 from '../deploy_data/contracts/AgreementManagerETH_ERC792.json'
import AgreementManagerERC20_ERC792 from '../deploy_data/contracts/AgreementManagerERC20_ERC792.json'
import utils from 'web3-utils';
import EXIF from 'exif-js';
import emailImg from '../email.png';
import twitterImg from '../twitter.png';
import facebookImg from '../facebook.png';
import phoneImg from '../phone.png';
import ethereumImg from '../ethereum.png';
import redditImg from '../reddit.png';
import atstakeImg from '../atstake.png';
import store from '../store';
import moment from 'moment-timezone';
import { shared } from '../Shared';
import { push } from 'react-router-redux';
import React from 'react';
import { cleanPhoneNumber } from './DialogAddAttestationActions';
import Link from './Link';
import { initialDialogGUID, checkDialogGUID } from './OverlayActions';
import { API_ENDPOINT_PREFIX } from './EndpointsConfig.js';
import { ethers } from 'ethers';
import FastJsonRpcSigner from '../FastJsonRpcSigner';
import Eth from 'web3-eth';
import { getMenuTokens } from './TokenUtils';

export function track(event_type, event_name, event_value) {
  return function(dispatch) {
    apiTrack(event_type, event_name, event_value).then((success) => {
    });
  }  
}

export function getTestModeFromStore() {
  return store.getState().auth.testMode;
}

export function apiEvidenceURL(basePath) {
  let testMode = store.getState().auth.testMode;
  if (testMode) {
    if (basePath.includes("?")) {
      basePath += "&test=1";
    }
    else {
      basePath += "?test=1";
    }
    return basePath;
  } 
  return basePath;
}

export function genURL(navPath) {
  navPath = navPath.replace(/&test=[01]/gi,'');
  navPath = navPath.replace(/\?test=[01]&/gi,'?');
  navPath = navPath.replace(/\?test=[01]/gi,'');

  let auth = store.getState().auth;
  let navParam = auth.navParam;
  let pathname = navPath.replace(/\?.*/gi,'');
  let basepage = pathname.replace(/^\/|\/$/g, '');

  if (basepage === "view") {
    if (navParam === "contracts") {
      if (navParam !== "") {
        if (navPath.includes("?")) {
          navPath += "&nav=" + navParam;
        }
        else {
          navPath += "?nav=" + navParam;
        }
      }
    }
  }

  return navPath;
}

export function navNewURL(navPath) {
  return function(dispatch) {
    navPath = genURL(navPath);
    dispatch(push(navPath));
  }
}

export function navChangeParams(changeObj) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let locationJSON = auth.locationJSON;
    if (locationJSON && changeObj) {
      let locationObj = JSON.parse(locationJSON);
      let { pathname="", query={} } = locationObj;

      let navPath = pathname;
      let delim = "?";
      for (let key in query) {
        if (query.hasOwnProperty(key)) {
          let value = query[key];
          if (!changeObj.hasOwnProperty(key)) {
            navPath += delim + key + "=" + value;
            delim = "&";  
          }
        }
      }

      for (let key in changeObj) {
        if (changeObj.hasOwnProperty(key)) {
          let value = changeObj[key];
          if (value !== null) {
            navPath += delim + key + "=" + value;
            delim = "&";   
          }
        }
      }

      dispatch(push(navPath));
    }
  }
}

export function pageNotLoaded(obj) {
  return (Object.keys(obj).length === 0);
}

export function getContentWidth() {
  let auth = store.getState().auth;
  let screenWidth = auth.screenDimensions.width;

  let hasMenu = false;

  let contentWidth = hasMenu ? (screenWidth - 300) : screenWidth;
  contentWidth = Math.min(contentWidth, 1060) - 20;
  
  return contentWidth;
}

export function getIsMobile() {
  let auth = store.getState().auth;
  return auth.device === "mobile";
}

export function getIsProfileOwnAccount() {
  let { username="" } = store.getState().auth;
  let { selectedUser="" } = store.getState().profileHeader;
  let ownAccount = (username !== "") && (username === selectedUser);
  return ownAccount;
}

export function pluralIfNeeded(val, singular, plural) {
  if (val === 1) {
    return val + " " + singular;
  }
  return val + " " + plural;
}

export function pluralIfNeededSpan(val, singular, plural) {
  if (val === 1) {
    return <span>{val} {singular}</span>;
  }
  return <span>{val} {plural}</span>;
}

export function calcPercentage(num, denom) {
  if (typeof num === "number" && typeof denom === "number") {
    if (denom > 0) {
      return Math.floor(num*100.0 / denom) + "%";
    }
  }

  return "0%";
}

export function lowerCase(str) {
  if (str && typeof str === "string") {
    return str.toLowerCase();
  }

  return "";
}

export function upperCase(str) {
  if (str && typeof str === "string") {
    return str.toUpperCase();
  }

  return "";
}

export function string(val) {
  if (val !== null && val !== undefined) {
    return val.toString();
  }

  return val;
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
export function addCommasToNumber(numberString) {
  if (numberString) {
    var parts = numberString.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  return numberString;
}

export function roundWithCommas(numberString) {
  if (typeof numberString === "string" || typeof numberString === "number") {
    return addCommasToNumber(Math.floor(parseFloat(numberString)*100)/100);  
  }
  else {
    return "";
  }
}

export function getUserLinkElement(user, userDisp, styleModification) {
  if (styleModification === "normalWeight") {
    return <Link style={{fontWeight:"normal"}} className="blacklink" noTarget={true} rel="noopener noreferrer" href={getAccountLink(user)}>{userDisp}</Link>;
  } else {
    return <Link className="blacklink" noTarget={true} rel="noopener noreferrer" href={getAccountLink(user)}>{userDisp}</Link>;
  }  
}

export function getEthersJSContractForAgreement(contractType) {
  let auth = store.getState().auth;
  let networkId = auth.ethNetwork;
  let cacheKey = contractType + "_" + networkId;

  if (typeof window.contractEthersJSCacheObj === "undefined") { 
    window.contractEthersJSCacheObj = {};
  }

  let contract = null;
  if (window.contractEthersJSCacheObj.hasOwnProperty(cacheKey)) {
    contract = window.contractEthersJSCacheObj[cacheKey];
  }

  if (contract === null && networkId) {
    let deployDataObj = null;
    if (contractType === "simple_eth") {
      deployDataObj = AgreementManagerETH_Simple;
    }
    else if (contractType === "simple_erc20") {
      deployDataObj = AgreementManagerERC20_Simple;
    }
    else if (contractType === "kleros_eth") {
      deployDataObj = AgreementManagerETH_ERC792;
    }
    else if (contractType === "kleros_erc20") {
      deployDataObj = AgreementManagerERC20_ERC792;
    }
  
    if (deployDataObj) {
      let address = deployDataObj.networks[networkId].address;
      const baseContract = new ethers.Contract(address, deployDataObj.abi, window.ethersProvider);
      contract = baseContract.connect(window.ethersFastSigner);
      window.contractEthersJSCacheObj[cacheKey] = contract;
    }
  }

  return contract;
}

export function getEthersJSContractForToken(token) {

  if (window.ethersProvider) {
    let tokenSymbol = token.toUpperCase();
    let auth = store.getState().auth;
    if (auth.erc20Balances.hasOwnProperty(tokenSymbol)) {
      return getEthersJSContractForAddress(auth.erc20Balances[tokenSymbol].address);
    }
  }

  return null;
}

export function getEthersJSContractForAddress(tokenAddress) {
  const minABI = [
    // balanceOf
    {
      "constant":true,
      "inputs":[{"name":"_owner","type":"address"}],
      "name":"balanceOf",
      "outputs":[{"name":"balance","type":"uint256"}],
      "type":"function"
    },
    // transfer
    {
      "constant":false,
      "inputs":[{"name":"_to","type":"address"},{"name":"_value","type":"uint256"}],
      "name":"transfer",
      "outputs":[{"name":"","type":"bool"}],
      "payable":false,
      "stateMutability":"nonpayable",
      "type":"function"
    },
    // approve
    {
      "constant": false,
      "inputs": [{"name": "_spender","type": "address"},{"name": "_value","type": "uint256"}],
      "name": "approve",
      "outputs": [{"name": "","type": "bool"}],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "function"
    },
    // allowance
    {
      "constant": true,
      "inputs": [{"name": "_owner","type": "address"},{"name": "_spender","type": "address"}],
      "name": "allowance",
      "outputs": [{"name": "","type": "uint256"}],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
  ];

  if (typeof window.contractEthersJSCacheObj === "undefined") { 
    window.contractEthersJSCacheObj = {};
  }

  let contract = null;
  if (window.contractEthersJSCacheObj.hasOwnProperty(tokenAddress)) {
    contract = window.contractEthersJSCacheObj[tokenAddress];
  }

  if (contract === null) {
    //console.log("Listening to " + tokenAddress);
    const baseContract = new ethers.Contract(tokenAddress, minABI, window.ethersProvider);
    contract = baseContract.connect(window.ethersFastSigner);
    window.contractEthersJSCacheObj[tokenAddress] = contract;
  }

  return contract;
}

export function encodeParams(params) {
  let str = "";
  for (let key in params) {
    if (str === "") {
      str += "?";
    }
    else {
      str += "&";
    }

    str += key + "=" + encodeURIComponent(params[key]);
  }

  return str;
}

export async function apiRequest(type, params) {  

  let body = {};
  let auth = store.getState().auth;
  if (typeof params !== "object") {
    params = {};
  }

  let testMode = auth.testMode;
  if (testMode) {
    if (!params.hasOwnProperty("test")) {
      params.test = "1";
    }
  }

  //if (auth.walletType === "coinbase" && auth.android) {
  //  params.require_with = "org.toshi";
  //}

  return fetch(API_ENDPOINT_PREFIX + type + encodeParams(params), {
    method: "POST",
    mode: "cors",
    headers: {
        "Accept": "application/json"
    },
    body: JSON.stringify(body)
  })
  .then(function(response) {
    return response.json();
  })
  .then(function (json) {
    if (json !== null && typeof json === "object" && json.hasOwnProperty("status")) {
      return json;
    }

    return { status: "error" };
  })
  .catch(function (err) {
    return { status: "error" };
  });
}

export function getEthereumAddressList(attestationList, defaultAddress) {
  let ethereumAddressList = [];

  for (let i = 0; i < attestationList.length; i++) {
    let entry = attestationList[i];
    if (entry.account_type === "ethereum") {
      let isDefault = (defaultAddress === entry.account_name);
      ethereumAddressList.push({address: entry.account_name, isDefault});
    }
  }

  return ethereumAddressList;
}

export async function apiRequestPost(type, params, body) {
  let auth = store.getState().auth;
  if (typeof params !== "object") {
    params = {};
  }

  let testMode = auth.testMode;

  if (testMode) {
    if (!params.hasOwnProperty("test")) {
      params.test = "1";
    }
  }

  //if (auth.walletType === "coinbase" && auth.android) {
  //  params.require_with = "org.toshi";
  //}

  return fetch(API_ENDPOINT_PREFIX + type + encodeParams(params), {
    method: "POST",
    mode: "cors",
    headers: {
        "Accept": "application/json"
    },
    body: JSON.stringify(body)
  })
  .then(function(response) {
    return response.json();
  })
  .then(function (json) {
    if (json !== null && typeof json === "object" && json.hasOwnProperty("status")) {
      return json;
    }

    return { status: "error" };
  })
  .catch(function (err) {
    return { status: "error" };
  });
}

export function setCookie(name, value) {
  setCookieWithExpiry(name, value, 180);
}

export function setCookieWithExpiry(name, value, days) {
  let expires = "";

  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(';');

  for (let i=0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') { 
      c = c.substring(1,c.length);
    }

    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
  }

  return "";
}

export function eraseCookie(name) {   
  document.cookie = name + '=; Max-Age=-99999999; path=/';
}

export function isMobile(userAgent, width) {
  if (userAgent.match(/Mobi/)) {
    return true;
  }
  
  if (width <= 900) {
    return true;
  }

  return false;
}

export function isAndroid(userAgent) {
  if (userAgent.match(/Android/)) {
    return true;
  }
  
  return false;
}

export function isIOS(userAgent) {
  if (userAgent.match(/iPhone/)) {
    return true;
  }
  
  return false;
}

export function dateStringToUnix(dateStr) {
  let dateUnix = "";
  if (dateStr !== "") {
    dateUnix = moment.tz(dateStr, "MM/DD/YYYY", 'America/Los_Angeles').unix().toString();
  }
  return dateUnix;
}

export function dateUnixToString(dateUnix) {
  let dateStr = "";
  if (dateUnix > 0) {
    dateStr = moment.tz(dateUnix, "X", 'America/Los_Angeles').format('MM/DD/YYYY');
  }
  return dateStr;
}

export function datetimeDisplayString(time_unix) {
  let dateStr = "";
  if (time_unix > 0) {
    dateStr = moment.tz(time_unix, "X", 'America/Los_Angeles').format('lll z');
  }
  return dateStr;
}

export function minutesBeforeNow(start_unix) {
  let dateStart = moment.tz(start_unix, "X", 'America/Los_Angeles');
  let dateNow = moment().tz('America/Los_Angeles');
  return dateNow.diff(dateStart, 'minutes');
}

export function dateUnixToShortString(dateUnix) {
  let dateStart = moment.tz(dateUnix, "X", 'America/Los_Angeles');
  let dateNow = moment().tz('America/Los_Angeles');
  let dayRemainingStr = "";

  let diffMinutes = dateNow.diff(dateStart, 'minutes');
  if (diffMinutes < 0) {
    dayRemainingStr = "";
  }
  else if (diffMinutes < 60) {
    dayRemainingStr = diffMinutes + "m";
  }
  else {
    let diffHours = dateNow.diff(dateStart, 'hours');
    if (diffHours < 24) {
      dayRemainingStr = diffHours + "h";
    }
    else {
      let diffDays = dateNow.diff(dateStart, 'days');
      if (diffDays <= 99) {
        dayRemainingStr = diffDays + "d";
      }
      else {
        let diffWeeks = dateNow.diff(dateStart, 'weeks');
        dayRemainingStr = diffWeeks + "w";
      }
    }
  }

  return dayRemainingStr;
}


export function getTextWidth(text, font) {
  // re-use canvas object for better performance
  let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

export function numColumns(userAgent, width, loggedin) {

  // Note also need to adjust isMobile width threshold
  let threshold_three = 1000;
  let threshold_two = 1000;

  if (loggedin) {
    threshold_three = 1300;
    threshold_two = 1300;
  }

  if (isMobile(userAgent, width)) {
    return 1;
  }
  else {
    if (width > threshold_two) {
      return width > threshold_three ? 3 : 2;
    }

    return 1;
  }
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validForType(input, type, match) {

  if (match.hasOwnProperty(type) && match[type] === true) {
    return false;
  }

  if (input !== "") {
    if (type === "ethereum" && utils.isAddress(input) ) {
      return true;
    }
    else if (type === "email" && validateEmail(input)) {
      return true;
    }
    else if (type === "phone" && /^(\+?1-?)?[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/i.test(input)) {
      return true;
    }
    else if (type === "twitter" && /^[a-zA-Z0-9_]+$/i.test(input) && input.length <= shared.MAX_TWITTER_USERNAME_LENGTH) {
      return true;
    }
    else if (type === "facebook" && /^[a-zA-Z0-9.]+$/i.test(input) && input.length >= 5 && input.length <= shared.MAX_FACEBOOK_USERNAME_LENGTH) {
      return true;
    }
    else if (type === "reddit" && /^[a-zA-Z0-9_-]+$/i.test(input) && input.length >= 3 && input.length <= shared.MAX_REDDIT_USERNAME_LENGTH) {
      return true;
    }
  }

  return false;
}

export function getTypeList(includeAtstake) {
  if (includeAtstake) {
    return ["atstake", "ethereum", "email", "phone", "twitter", "facebook", "reddit"];
  }
  else {
    return ["ethereum", "email", "phone", "twitter", "facebook", "reddit"];
  }
}

export function resultsNewByType(search, input, match) {

  let results = [];
  let typeList = ["ethereum", "email", "phone", "twitter", "facebook", "reddit"];
  for (let i = 0; i < typeList.length; i++) {
    let type = typeList[i];

    if (validForType(input, type, match) && (search === type || search === "all")) {
      let attestationid = type + "#" + input.toLowerCase();
      results.push(
        {
          type: type,
          value: input,
          [attestationid]: {
            type: type,
            value: input,
            highlight: input,
            partialMatch: true,
            exactMatch: true
          }
        }
      );
    }
  }
  return results;
}

export function getTopMarketLink(category) {
  let link = "";
  if (category === "location") {
    link = genMarketsURL({});
  }
  else {
    link = genMarketsURL({breadLocation: "global", breadType: category});
  }
  return link;
}

export function urlForBreadcrumb(breadLocation, breadType, breadCategory) {
  return genMarketsURL({breadLocation, breadType, breadCategory, more: false});
}

export function genMarketsURL(entry) {

  let {
    breadLocation="",
    breadType="",
    breadCategory="",
    more=false
  } = entry;

  let list = [];
  if (breadLocation !== "") {
    list.push({field: breadLocation, level: "location"})

    if (breadType !== "") {
      list.push({field: breadType, level: "type"})

      if (breadCategory !== "") {
        list.push({field: breadCategory, level: "category"})
      }
    }
  }

  let paramLocation = "";
  let paramType = "";
  let paramCategory = "";
  let delim = "?";
  for (let i = 0; i < list.length; i++) {
    let entry = list[i];

    if (entry.level === "location") {
      paramLocation = delim + "location=" + entry.field;
      delim = "&";
    }
    else if (entry.level === "type") {
      paramType = delim + "type=" + entry.field;
      delim = "&";
    }
    else if (entry.level === "category") {
      paramCategory = delim + "category=" + entry.field;
      delim = "&";
    }
  }

  let paramMore = "";
  if (more) {
    paramMore = delim + "more=1";
  }


  let url = "/postings" + paramLocation + paramType + paramCategory + paramMore;
  return url;
}

// We need to do a deep copy because this modifies entry, which is sometimes shared data that we pass in
export function crumbifyEntry(entry, breadLocation, breadType, breadCategory) {
  let {
    key="",
    level=""
  } = entry;

  if (level === "location") {
    breadLocation = key;
  }
  else if (level === "type") {
    breadType = key;
  }
  else if (level === "category") {
    breadCategory = key;
  }

  let entryCopy = shared.deepCopy(entry);

  entryCopy.breadLocation = breadLocation;
  entryCopy.breadType = breadType;
  entryCopy.breadCategory = breadCategory;

  return entryCopy;
}

export function isCategoryEnabled(breadLocation, breadType, breadCategory, enabledCategoryTree) {
  if (breadLocation !== "" && breadType !== "" && breadCategory !== "") {
    if (enabledCategoryTree.hasOwnProperty(breadLocation + "+" + breadType + "+" + breadCategory)) {
      return true;
    }
  }
  else if (breadLocation !== "" && breadType !== "") {
    if (enabledCategoryTree.hasOwnProperty(breadLocation + "+" + breadType)) {
      return true;
    }
  }
  else if (breadLocation !== "") {
    if (enabledCategoryTree.hasOwnProperty(breadLocation)) {
      return true;
    }
  }

  return false;
}

export function crumbifyList(list, breadLocation, breadType, breadCategory) {
  let auth = store.getState().auth;
  let {
    enabledCategoryTree={}
  } = auth;

  let newList = [];
  for (let i = 0; i < list.length; i++) {
    let entry = crumbifyEntry(list[i], breadLocation, breadType, breadCategory);
    if (isCategoryEnabled(entry.breadLocation, entry.breadType, entry.breadCategory, enabledCategoryTree)) {
      newList.push(entry);  
    }
  }
  return newList;
}

export function getCategoryTree(breadLocation, breadType, breadCategory, expandMore) {
  let auth = store.getState().auth;
  let {
    enabledCategoryTree={}
  } = auth;

  if (breadLocation === "") {
    // Has 0/3
    let categoryList = [];      
    let combinedLocationList = shared.getFullLocationList();
    
    for (let i = 0; i < combinedLocationList.length; i++) {
      let entry = crumbifyEntry(combinedLocationList[i], breadLocation, breadType, breadCategory);
      if (isCategoryEnabled(entry.breadLocation, entry.breadType, entry.breadCategory, enabledCategoryTree)) {
        entry.children = crumbifyList(shared.BREADCRUMB_POSTING_TYPE_LIST, entry.breadLocation, breadType, breadCategory);
        categoryList.push(entry);
      }
    }

    return categoryList;
  }
  else if (breadType === "") {
    // Has 1/3
    let categoryList = [];    
    for (let i = 0; i < shared.BREADCRUMB_POSTING_TYPE_LIST.length; i++) {
      let entry = crumbifyEntry(shared.BREADCRUMB_POSTING_TYPE_LIST[i], breadLocation, breadType, breadCategory);
      if (isCategoryEnabled(entry.breadLocation, entry.breadType, entry.breadCategory, enabledCategoryTree)) {
        entry.children = crumbifyList(shared.getPostingCategoryList(entry.key), breadLocation, entry.breadType, breadCategory);
        if (entry.children.length > 0) {
          categoryList.push(entry);
        }
      }
    }

    return categoryList;
  }
  else if (breadCategory === "") {
    // Has 2/3
    return crumbifyList(shared.getPostingCategoryList(breadType), breadLocation, breadType, breadCategory);
  }
  else {
    // Has 3/3
    return [];
  }
}

export function getBreadPathDisplay(breadLocation, breadType, breadCategory) {
  if (breadCategory !== "") {
    return shared.getBreadcrumbDisplay(breadCategory, "category");
  }
  else if (breadType !== "") {
    return shared.getBreadcrumbDisplay(breadType, "type");
  }
  else if (breadLocation !== "") {
    return shared.getBreadcrumbDisplay(breadLocation, "location");
  }
  else {
    return "Most recent";
  }
}

export function srcForType(type) {
  if (type === "ethereum") {
    return ethereumImg;
  }
  else if (type === "phone") {
    return phoneImg;
  }
  else if (type === "email") {
    return emailImg;
  }
  else if (type === "twitter") {
    return twitterImg;
  }
  else if (type === "facebook") {
    return facebookImg;
  }
  else if (type === "reddit") {
    return redditImg;
  }
  else if (type === "atstake") {
    return atstakeImg;
  }

  return null;
}

export function titleByType(type) {
  if (type === "ethereum") {
    return "Ethereum address";
  }
  else if (type === "email") {
    return "Email address";
  }
  else if (type === "phone") {
    return "Phone number";
  }
  else if (type === "twitter") {
    return "Twitter username";
  }
  else if (type === "facebook") {
    return "Facebook username";
  }
  else if (type === "reddit") {
    return "Reddit username";
  }
  else if (type === "atstake") {
    return "Atstake account";
  }

  return "Identifier";
}


export function signRegisterPromise(username) {
  const message = "Register Atstake username " + username.toLowerCase();
  return sign(message);
}

export function signLinkAccountPromise(username) {
  const message = "Link this ETH address to Atstake username " + username.toLowerCase();
  return sign(message);
}

export function signLoginPromise(nonce) {
  const message = "Log in to Atstake by signing this secret code: " + nonce + "";
  return sign(message);
}

export function signAccessCodeLoginPromise(username, access_code, nonce) {
  const message = "Log in to Atstake username " + username.toLowerCase() + " using access code " + access_code + " and signing this secret code: " + nonce + "";
  return sign(message);
}

export async function sign(message) {
  const data = ethers.utils.toUtf8Bytes(message);
  let signature = "";

  if (window.ethersProvider) {
    try {
      const signer = window.ethersProvider.getSigner();
      if (signer) {
        const addr = await signer.getAddress();
        if (addr) {
          signature = await window.ethersProvider.send('personal_sign', [ethers.utils.hexlify(data), addr.toLowerCase()]);
        }
      }
    }
    catch (err) {
      console.log("Signature error");
      console.log(err);
    }
  }

  return signature;
}

export function getRawEthereumProvider() {
  if (typeof window.ethereum !== "undefined") {
    return window.ethereum;
  }
  else if (typeof window.web3 !== "undefined" && window.web3.hasOwnProperty("currentProvider")) {
    return window.web3.currentProvider;
  }
  return null;
}

export async function getMetamaskNetworkId(rawEthereumProvider) {
  let networkVersion = "";  

  if (typeof window.web3EthProvider === "undefined" && rawEthereumProvider !== null) {
    window.web3EthProvider = new Eth(rawEthereumProvider);
  }

  if (window.web3EthProvider) {
    try {
      networkVersion = await window.web3EthProvider.net.getId();
    }
    catch (err) {
      networkVersion = "";
    }
  }

  return networkVersion;
}

export async function initEthersJS() {
  let rawEthereumProvider = getRawEthereumProvider();
  let networkId = await getMetamaskNetworkId(rawEthereumProvider);

  if (typeof window.ethersNetworkMap === "undefined") {
    window.ethersNetworkMap = {};
  }

  if (!window.ethersNetworkMap.hasOwnProperty(networkId) && rawEthereumProvider !== null) {
    let provider = null;
    let signer = null;
    try {
      provider = new ethers.providers.Web3Provider(rawEthereumProvider);
      const basicSigner = provider.getSigner();
      if (basicSigner) {
        signer = new FastJsonRpcSigner(basicSigner);
      }
    }
    catch (err) {
      provider = null;
      signer = null;
    }

    if (provider !== null && signer !== null) {
      window.ethersNetworkMap[networkId] = { provider, signer };    
    }
  }

  if (window.ethersNetworkMap.hasOwnProperty(networkId)) {
    let ethersNetworkObj = window.ethersNetworkMap[networkId];
    window.ethersProvider = ethersNetworkObj.provider;
    window.ethersFastSigner = ethersNetworkObj.signer;
  }
}

export async function getAddressAndNetwork(web3) {
  await initEthersJS();

  if (window.ethersProvider) {
    let accountList = [];
    try {
      accountList = await window.ethersProvider.listAccounts();
    }
    catch (err) {
      accountList = [];
    }
    if (accountList && accountList.length > 0) {
      let address = lowerCase(accountList[0]);
      let network = "";
      try {
        let networkObj = await window.ethersProvider.getNetwork();
        network = (networkObj && networkObj.hasOwnProperty("chainId")) ? string(networkObj.chainId) : "";
      }
      catch (err) {
        network = "";
      }

      if (network !== "") {
        return {address, network, update: true};
      }
    }
  }

  return {address: "", network: "", update: true};
}

export function shortenAddress(address) {
  if (address.length > 10) {
    return address.substring(0, 6) + "..." + address.substring(address.length - 4);
  }
  else {
    return address;
  }
}

export function shortenAddressMedium(address) {
  if (address.length > 18) {
    return address.substring(0, 14) + "..." + address.substring(address.length - 4);
  }
  else {
    return address;
  }
}

export function getVisibilityTooltipContent() {
  return (
    <div>
      <div>Visibility applies to all versions of a contract.</div>
      <div><span className="strongText moveTop10">Public: </span> All contract details and
      ratings are visible to anyone and are listed on your public profile.</div>
      <div className="moveTop10"><span className="strongText">Ratings only: </span> Anyone can see ratings you receive for this contract, who rated you, and when the contract was created. 
      All other details of the contract (including its title) are hidden from non-participants.</div>
      <div className="moveTop10"><span className="strongText">Private: </span> Information about the contract is 
      completely hidden except from your counterparty and the arbitrator. 
      This is the only option that won't affect anyone's statistics or ratings.</div>
    </div>
  );
}

export async function getLinkedAccountList(uuid, username, user1) {
  let list = await apiRequest("list_attestations", { 
    uuid: uuid, 
    username: username, 
    user1: user1 
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("results")) {
      return response.results;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}

export async function apiGetTokenPrice() {
  let respObj = await apiRequest("token_price", { 
  }).then((response) => {
    return response;
  }).catch((err) => {
    return null;
  });

  return respObj;
}

export async function apiGetTokenBalance(uuid, username, address, network, only_menu) {
  let respObj = await apiRequest("balance", { 
    uuid, 
    username,
    address,
    network,
    only_menu
  }).then((response) => {
    return response;
  }).catch((err) => {
    return null;
  });

  return respObj;
}

export async function apiGetNotifications(uuid, username) {
  return await apiRequest("notifications", { 
    uuid, 
    username
  }).then((response) => {
    return response;
  }).catch((err) => {
    return null;
  });
}

export function getPriceMessageForToken(input, type) {
  let auth = store.getState().auth;
  let typeUC = upperCase(type);
  let msg = "";

  if (
    input && 
    auth.hasTokenPriceData && 
    auth.tokenPriceObj &&
    auth.tokenPriceObj.hasOwnProperty(typeUC)
  ) {
    let priceInt = auth.tokenPriceObj[typeUC]["USD"] * 100000;
    if (typeUC === "DAI" || typeUC === "USDC") {
      // If it's within 2% of 1 USD, normalize to 1 USD.
      if (priceInt >= 98000 && priceInt <= 102000) {
        priceInt = 100000;
      }
    }
    let priceIntStr = priceInt.toString();
    priceIntStr = priceIntStr.replace(/\.[0-9]*$/g, '');

    let inputIntStr = shared.multiplyDecimals(input, 5);

    if (/^[0-9.]+$/i.test(input)) {
      let mulStr = shared.mulBN(inputIntStr, priceIntStr, "getPriceMessage");
      // Round to nearest value, not always down. This is equivalent to adding a half cent, since things are scaled up by 10^10
      let adjustedStr = shared.addBN(mulStr, shared.multiplyDecimals("5", 7), "getPriceMessage"); 
      let divideStr = shared.divideDecimals(adjustedStr, 10);
      let amount = roundWithDecimals(divideStr, 2);
      msg = "~$" + amount;
    }
  }

  return msg;
}

export function getPriceMessageWidthCutoff(input, type, otherContent) {
  let msg = getPriceMessageForToken(input, type);
  let combinedLength = msg.length + otherContent.length;
  let contentWidth = getContentWidth();

  if (contentWidth < 340) {
    msg = "";
  }
  else if (contentWidth < 370 && combinedLength > 12) {
    msg = "";
  }
  else if (contentWidth < 400 && combinedLength > 14) {
    msg = "";
  }
  else if (contentWidth < 450 && combinedLength > 17) {
    msg = "";
  }
  else if (contentWidth < 600 && combinedLength > 23) {
    msg = "";
  }
  else if (combinedLength > 30) {
    msg = "";
  }

  return msg;
}

export async function apiListContacts(uuid, username) {
  return await apiRequest("list_contacts", { 
    uuid, 
    username
  }).then((response) => {
    let { results=[], favorites=[] } = response;
    if (response.status === "success" && response.hasOwnProperty("results")) {
      return { results, favorites };
    }
    else {
      return { results:[], favorites: [] };
    }
  }).catch((err) => {
    return { results:[], favorites: [] };
  });
}

export async function apiPostingLifecycle(uuid, username, postingid, lifecycle_status) {
  return await apiRequest("posting_lifecycle", { 
    uuid, 
    username, 
    postingid,
    lifecycle_status
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });
}

export async function apiListMessages(uuid, username, user_other) {
  return await apiRequest("list_messages", { 
    uuid, 
    username,
    user_other
  }).then((response) => {
    let { messages=[] } = response;
    if (response.status === "success") {
      return { messages, success: true };
    }
    else {
      return { messages: [], success: false };
    }
  }).catch((err) => {
    return { messages: [], success: false };
  });
}

export async function apiNewMessage(uuid, username, user_to, message) {
  return await apiRequestPost("new_message", { 
    uuid, 
    username,
    user_to
  }, {
    message
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });
}

export async function apiListConversations(uuid, username) {
  return await apiRequest("list_conversations", { 
    uuid, 
    username
  }).then((response) => {
    let { conversations=[] } = response;
    if (response.status === "success") {
      return { conversations, success: true };
    }
    else {
      return { conversations: [], success: false };
    }
  }).catch((err) => {
    return { conversations: [], success: false };
  });
}

export async function apiTopArbitrators(uuid, username, offset, count) {
  let list = await apiRequest("top_arbitrators", { 
    offset,
    count
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("results")) {
      return response.results;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}

// returns an object with items transactions_user1, transactions_user2, transactions_arbitrator
export async function apiGetAgreementTransactions(uuid, username, agreementid) {
  let result = await apiRequest("get_agreement_transactions", { 
    uuid,
    username,
    agreementid
  }).then((response) => {
    if (response.status === "succeeded" && response.hasOwnProperty("results")) {
      return response.results;
    }
    else {
      return null;
    }
  }).catch((err) => {
    return null;
  });
 
  return result;
}

export async function getVersionsForAgreementList(uuid, username, agreementid) {
  let paramsObj = { 
    uuid: uuid, 
    username: username,
    agreementid: agreementid
  };

  paramsObj.test = getIsTestAgreement(agreementid) ? "1" : "0";

  let list = await apiRequest("list_versions", paramsObj).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("results")) {
      return response.results;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}

export async function apiGetAgreementList(uuid, username, selected_user, filter, offset, count, max_visibility) {
  let after = "";
  let list = await apiRequest("list_agreements", { 
    uuid, 
    username,
    selected_user,
    filter,
    offset,
    count,
    max_visibility,
    after
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("results")) {
      return response.results;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}

export function getIsTestAgreement(agreementid) {
  return (agreementid.charAt(0) === 't');
}

export async function apiGetAgreement(uuid, username, agreementid, versionid, previewid) {
  let paramsObj = { 
    uuid, 
    username,
    agreementid,
    versionid,
    previewid
  };

  paramsObj.test = getIsTestAgreement(agreementid) ? "1" : "0";  

  let respObj = await apiRequest("get_agreement", paramsObj).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("results") && response.hasOwnProperty("version_count")) {
      return {
        results: response.results, 
        versionCount: response.version_count, 
        user1Address: response.user1_address, 
        user2Address: response.user2_address, 
        arbitratorAddress: response.arbitrator_address,
        user1AddressDefault: response.user1_address_default,
        user2AddressDefault: response.user2_address_default,
        arbitratorAddressDefault: response.arbitrator_address_default
      };
    }
    else {
      return {
        results:[], 
        versionCount:0, 
        user1Address:"", 
        user2Address:"", 
        arbitratorAddress: "",
        user1AddressDefault: "",
        user2AddressDefault: "",
        arbitratorAddressDefault: ""
      };
    }
  }).catch((err) => {
    return {
      results:[], 
      versionCount:0, 
      user1Address:"", 
      user2Address:"", 
      arbitratorAddress: "",
      user1AddressDefault: "",
      user2AddressDefault: "",
      arbitratorAddressDefault: ""
    };
  });

  return respObj;
}


export async function checkPendingTransactions(agreementObj, curTransactionString) {

  let txList = JSON.parse(curTransactionString);

  let stillPendingTransactions = false;
  if (!window.ethersProvider) {
    console.log("Initializing ethers");
    await initEthersJS();
  }
  let listOfUpdates = [];
  for (let i = 0; i < txList.length; i++ ){
    let tx = txList[i];
    if (tx.status === "pending") {
      if (window.ethersProvider) {
        let respObj = await window.ethersProvider.getTransactionReceipt(tx.hash);
        //console.log("getTransactionReceipt response in checkPendingTransactions: ");
        //console.log(respObj);
        if (respObj) {
          tx.status = (respObj.status ? "succeeded" : "failed");
          listOfUpdates.push(tx);
        } else {
          let res = await window.ethersProvider.getTransaction(tx.hash);
          if (res) {
            stillPendingTransactions = true;
          } else {
            tx.status = "unseen";
            listOfUpdates.push(tx);
          }
        }
      } else {
        console.log("ethersProvider could not be initialized");
        stillPendingTransactions = true;
      }
    }
  }

  return {
    updatedTxString: JSON.stringify(txList),
    listOfUpdates: listOfUpdates,
    stillPending: stillPendingTransactions
  };
}

export async function getComments(uuid, username, agreementid) {
  let paramsObj = { 
    uuid: uuid, 
    username: username,
    agreementid: agreementid
  };

  paramsObj.test = getIsTestAgreement(agreementid) ? "1" : "0";

  let list = await apiRequest("list_comments", paramsObj).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("comments")) {
      return response.comments;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}


export async function apiAttestationPrivacy(uuid, username, type, value, privacy) {
  let success = await apiRequest("attestation_privacy", { 
    uuid: uuid, 
    username: username,
    type: type, 
    value: value, 
    privacy: privacy
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function getUserStats(uuid, username, user1) {
  let statsObj = await apiRequest("user_stats", { 
    username: user1
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("stats")) {
      return response;
    }
    else {
      return {status:"error"};
    }
  }).catch((err) => {
    return {status:"error"};
  });

  return statsObj;
}

export async function apiUserAccountData(uuid, username, user1) {
  let statsObj = await apiRequest("user_data", { 
    uuid: uuid, 
    username: username,
    user1: user1
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("data")) {
      return response;
    }
    else {
      return {status: "error"};
    }
  }).catch((err) => {
    return {status: "error"};
  });

  return statsObj;
}

export async function getFaucetData(uuid, username) {
  let faucetObj = await apiRequest("faucet_data", {
    uuid: uuid, 
    username: username
  }).then((response) => {
    if (response.status === "success" && 
        response.hasOwnProperty("faucet_last_used") && 
        response.hasOwnProperty("faucet_next_allowed_use") &&
        response.hasOwnProperty("faucet_address")) {
      return response;
    }
    else {
      return {status:"error"};
    }
  }).catch((err) => {
    return {status:"error"};
  });

  return faucetObj;
}

export async function getFaucetCoins(uuid, username, address) {
  let faucetObj = await apiRequest("faucet", { 
    uuid: uuid, 
    username: username,
    address: address
  }).then((response) => {
    if (response.status === "success" && 
        response.hasOwnProperty("transaction_hash") && 
        response.hasOwnProperty("faucet_next_allowed_use")) {
      return response;
    }
    else {
      return {status:"error"};
    }
  }).catch((err) => {
    return {status:"error"};
  });

  return faucetObj;
}

export async function apiGetAccessCode(uuid, username) {
  let statsObj = await apiRequest("get_access_code", { 
    uuid, 
    username
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("code") && response.hasOwnProperty("expiration_unix")) {
      let {code="", expiration_unix=0} = response;
      return {success:true, code, expiration_unix};
    }
    else {
      return {success:false};
    }
  }).catch((err) => {
    return {success:false};
  });

  return statsObj;
}

export async function apiChangeAgreementAddress(uuid, username, agreementid, versionid, status, address) {
  if ((status === "agreed" || status === "proposed") && versionid !== "") {
    return await apiRequest("change_agreed_agreement_address", { 
      uuid, 
      username,
      agreementid,
      versionid,
      address
    }).then((response) => {
      if (response.status === "success") {
        return {success:true};
      }
      else {
        return {success:false, locked: (response.locked === true)};
      }
    }).catch((err) => {
      return {success:false};
    });  
  }
  else {
    return await apiRequest("change_agreement_address", { 
      uuid, 
      username,
      agreementid,
      address
    }).then((response) => {
      if (response.status === "success") {
        return {success:true};
      }
      else {
        return {success:false, locked: (response.locked === true)};
      }
    }).catch((err) => {
      return {success:false};
    });
  }
}

export async function apiUpdateArbitrationSettings(uuid, username, fee_value, fee_type, auto_status, description, mode) {
  let success = await apiRequest("arbitration_settings", { 
    uuid, 
    username, 
    fee_value, 
    fee_type, 
    auto_status,
    description,
    mode
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiChangeVisibility(uuid, username, agreementid, new_visibility) {
  let success = await apiRequest("change_visibility", { 
    uuid, 
    username, 
    agreementid, 
    new_visibility
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiUpdateDefaultAddress(uuid, username, address) {
  let success = await apiRequest("change_default_address", { 
    uuid: uuid, 
    username: username, 
    address
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiDeleteAttestation(uuid, username, type, value) {
  let success = await apiRequest("delete_attestation", { 
    uuid, 
    username, 
    type,
    value
  }).then((response) => {
    if (response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function newComment(uuid, username, agreementid, text) {
  let comment = await apiRequestPost("new_comment", { 
    uuid: uuid, 
    username: username,
    agreementid: agreementid
  }, {
    text: text
  }).then((response) => {
    if (response.status === "success" && response.comment) {
      return response.comment;
    }
    else {
      return null;
    }
  }).catch((err) => {
    return null;
  });

  return comment;
}

export async function apiSelectVersion(uuid, username, agreementid, versionid) {
  return await apiRequest("select", { 
    uuid, 
    username,
    agreementid,
    versionid
  }).then((response) => {
    if (response && response.status === "success") {
      return { success: true };
    }
    else {
      return { success: false, locked: (response.locked === true) };
    }
  }).catch((err) => {
    return { success: false };
  });
}

export async function apiImplicitSelectVersion(uuid, username, agreementid, versionid, selected_user) {
  let resp = await apiRequest("implicit_select", { 
    uuid, 
    username,
    agreementid,
    versionid,
    selected_user
  }).then((response) => {
    if (response && response.status === "success") {
      return {
        success: true, 
        agreed_data: response.agreed_data, 
        agreed_sha3: response.agreed_sha3, 
        metaevidence_hash: response.metaevidence_hash
      };
    }
    else {
      return {success: false};
    }
  }).catch((err) => {
    return {success: false};
  });

  return resp;
}


export async function apiReportIssue(uuid, username, agreementid, data, text) {
  let body = {
    text,
    data
  };
  let success = await apiRequestPost("report", { 
    uuid, 
    username,
    agreementid
  }, body).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiGetUser(username) {
  let resp = await apiRequest("get_user", { 
    username
  }).then((response) => {
    let { hasUsername=false, arbitration_settings=null } = response || {};
    return { hasUsername, arbitration_settings };
  }).catch((err) => {
    return { hasUsername: false, arbitration_settings: null };
  });

  return resp;
}

export async function apiUnselectVersion(uuid, username, agreementid) {
  return await apiRequest("unselect", { 
    uuid, 
    username,
    agreementid
  }).then((response) => {
    if (response && response.status === "success") {
      return {success: true};
    }
    else {
      return {success: false, locked: (response.locked === true) };
    }
  }).catch((err) => {
    return {success: false};
  });
}

export async function apiHideAgreement(uuid, username, agreementid) {
  let success = await apiRequest("hide", { 
    uuid, 
    username,
    agreementid
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiUnhideAgreement(uuid, username, agreementid) {
  let success = await apiRequest("unhide", { 
    uuid, 
    username,
    agreementid
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiFavorite(uuid, username, contact, select) {
  let value = select ? "1" : "0";
  return await apiRequest("favorite", { 
    uuid, 
    username,
    contact,
    value
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });
}

export async function apiSettings(uuid, username, default_testmode) {
  let success = await apiRequest("settings", { 
    uuid, 
    username,
    default_testmode
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiTrack(event_type, event_name, event_value) {
  let auth = store.getState().auth;
  let uuid = auth.uuid;
  let username = auth.username;
  let pageguid = auth.pageguid;

  let success = await apiRequest("track", { 
    uuid, 
    username,
    pageguid, 
    event_type, 
    event_name, 
    event_value
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiRateUser(uuid, username, agreementid, rating_user, rating_val, rating_text) {
  let success = await apiRequest("rate_user", { 
    uuid, 
    username,
    agreementid,
    rating_user,
    rating_val,
    rating_text
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export function base64ToArrayBuffer(base64) {
  base64 = base64.replace(/^data:([^;]+);base64,/gmi, '');
  let binaryString = atob(base64);
  let len = binaryString.length;
  let bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

export async function imageConvertAndResize(file, maxWidth, maxHeight) {
  let imageBase64 = await getBase64(file);
  let binFile = base64ToArrayBuffer(imageBase64);
  let exif = EXIF.readFromBinaryFile(binFile);
  let orientation = exif.Orientation;
  let thumbnail = await resizeImg(imageBase64, maxWidth, maxHeight, orientation, false);
  let image = await resizeImg(imageBase64, 800, 800, orientation, true);
  return {thumbnail, image};
}

export async function urlConvertAndResize(url, maxWidth, maxHeight) {
  let orientation = 1;
  let thumbnail = await resizeImg(url, maxWidth, maxHeight, orientation, false);
  let image = await resizeImg(url, 800, 800, orientation, true);
  return {thumbnail, image};
}

export async function loadImg(imgurl) {
  return new Promise(function(resolve, reject){
    var img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function(){
        resolve(img);
    };
    img.onerror = function() {
      alert("Error loading image.");
    };
    img.src = imgurl;
  });
}

export function calculateResizeOffsets(initialWidth, initialHeight, maxWidth, maxHeight, orientation) {
  let top = 0;
  let left = 0;
  let width = 0;
  let height = 0;

  let isTall = (initialHeight / initialWidth > maxHeight / maxWidth);

  if (isTall) {
    //TALL
    width = maxWidth;
    height = Math.floor(initialHeight * maxWidth / initialWidth);
    top = -1 * Math.floor((height - maxHeight) / 2);
  }
  else {
    //WIDE
    height = maxHeight;
    width = Math.floor(initialWidth * maxHeight / initialHeight);
    left = -1 * Math.floor((width - maxWidth) / 2);
  }

  console.log({ isTall, initialWidth, initialHeight, maxWidth, maxHeight, top, left, width, height, orientation });
  return { top, left, width, height };
}

export function rgbToHex(rgb) { 
  var hex = Number(rgb).toString(16);
  if (hex.length < 2) {
    hex = "0" + hex;
  }
  return hex;
}

export function rotateFromExif(ctx, maxWidth, maxHeight, orientation) {
  let angle = 0;
  let leftAdjust = 0;
  let topAdjust = 0;
  let scaleX = 1;
  let scaleY = 1;
  let canvasWidth = maxWidth;
  let canvasHeight = maxHeight;
  if (orientation === 1) {
    angle = 0;
    leftAdjust = 0;
    topAdjust = 0;
    scaleX = 1;
    scaleY = 1;
    canvasWidth = maxWidth;
    canvasHeight = maxHeight;
  }
  else if (orientation === 6) {
    angle = 90;
    leftAdjust = 0;
    topAdjust = -maxHeight;
    scaleX = 1;
    scaleY = 1;
    canvasWidth = maxHeight;
    canvasHeight = maxWidth;
  }
  else if (orientation === 3) {
    angle = 180;
    leftAdjust = -maxWidth;
    topAdjust = -maxHeight;
    scaleX = 1;
    scaleY = 1;
    canvasWidth = maxWidth;
    canvasHeight = maxHeight;
  }
  else if (orientation === 8) {
    angle = 270;
    leftAdjust = -maxWidth;
    topAdjust = 0;
    scaleX = 1;
    scaleY = 1;
    canvasWidth = maxHeight;
    canvasHeight = maxWidth;
  }
  else if (orientation === 2) {
    angle = 0;
    leftAdjust = -maxWidth;
    topAdjust = 0;
    scaleX = -1;
    scaleY = 1;
    canvasWidth = maxWidth;
    canvasHeight = maxHeight;
  }
  else if (orientation === 5) {
    angle = 270;
    leftAdjust = 0;
    topAdjust = 0;
    scaleX = -1;
    scaleY = 1;
    canvasWidth = maxHeight;
    canvasHeight = maxWidth;
  }
  else if (orientation === 4) {
    angle = 180;
    leftAdjust = 0;
    topAdjust = -maxHeight;
    scaleX = -1;
    scaleY = 1;
    canvasWidth = maxWidth;
    canvasHeight = maxHeight;
  }
  else if (orientation === 7) {
    angle = 90;
    leftAdjust = -maxWidth;
    topAdjust = -maxHeight;
    scaleX = -1;
    scaleY = 1;
    canvasWidth = maxHeight;
    canvasHeight = maxWidth;
  }

  //console.log({orientation, angle, leftAdjust, topAdjust, scaleX, scaleY});

  let ctx2 = document.createElement('canvas').getContext("2d");
  ctx2.canvas.width = canvasWidth;
  ctx2.canvas.height = canvasHeight;
  ctx2.save();
  ctx.translate(maxWidth/2, maxHeight/2);
  ctx2.rotate(angle * Math.PI / 180);
  ctx2.scale(scaleX, scaleY);
  ctx2.drawImage(ctx.canvas, leftAdjust, topAdjust);
  ctx2.restore();
  return ctx2;
}

export function cropImage(ctx, maxSize, maxWidth, maxHeight) {
  let ctx2 = document.createElement('canvas').getContext("2d");
  ctx2.canvas.width = maxWidth;
  ctx2.canvas.height = maxHeight;
  ctx2.drawImage(ctx.canvas, Math.round((maxWidth - maxSize) / 2), Math.round((maxHeight - maxSize) / 2));
  return ctx2;
}

export async function resizeImg(imgurl, maxWidth, maxHeight, orientation, keepAspectRatio) {  
  let loadedImg = await loadImg(imgurl);
  let ctx = document.createElement('canvas').getContext("2d");

  if (orientation === 5 || orientation === 6 || orientation === 7 || orientation === 8) {
    let tempMaxWidth = maxWidth;
    let tempMaxHeight = maxHeight;
    maxWidth = tempMaxHeight;
    maxHeight = tempMaxWidth;  
  }

  let initialWidth = loadedImg.width;
  let initialHeight = loadedImg.height;

  if (keepAspectRatio) {
    if (initialWidth < maxWidth) { 
      maxWidth = initialWidth; 
    }

    if (initialHeight < maxHeight) { 
      maxHeight = initialHeight; 
    }

    if (maxHeight / maxWidth > initialHeight / initialWidth) {
      maxHeight = Math.floor(initialHeight * maxWidth / initialWidth);
    }
    else {
      maxWidth = Math.floor(initialWidth * maxHeight / initialHeight);
    }
  }

  let { top, left, width, height } = calculateResizeOffsets(initialWidth, initialHeight, maxWidth, maxHeight, orientation);
  ctx.canvas.width = maxWidth;
  ctx.canvas.height = maxHeight;
  ctx.drawImage(loadedImg, left, top, width, height);
  ctx = rotateFromExif(ctx, maxWidth, maxHeight, orientation);

  let imageData = null;
  try {
    imageData = ctx.getImageData(0, 0, width, height);
  } catch(e) {
    imageData = null;
  }

  let imageColor = "#cccccc";
  if (imageData) {
    let dataLength = imageData.data.length;
    let red = 0;
    let green = 0;
    let blue = 0;
    let blockSize = 5;
    let count = 0;
    let i = 0;
    while ((i += blockSize * 4) < dataLength) {
      let alpha = imageData.data[i+3];
      if (alpha > 128) {
        count++;
        red += imageData.data[i];
        green += imageData.data[i+1];
        blue += imageData.data[i+2];  
      }
      else {
        count++;
        red += 255;
        green += 255;
        blue += 255;
      }
    }

    red = Math.floor(red/count);
    green = Math.floor(green/count);
    blue = Math.floor(blue/count);

    let colorSum = red + green + blue;
    const MAX_COLOR_SUM = 740;
    if (colorSum > MAX_COLOR_SUM) {
      red = Math.floor(red * MAX_COLOR_SUM / colorSum);
      green = Math.floor(green * MAX_COLOR_SUM / colorSum);
      blue = Math.floor(blue * MAX_COLOR_SUM / colorSum);  
    }

    imageColor = "#" + rgbToHex(red) + rgbToHex(green) + rgbToHex(blue);
  }

  return { value: ctx.canvas.toDataURL(), color: imageColor };
}

export async function getBase64(file) {
  return new Promise(function(resolve, reject) {
      var reader = new FileReader();
      reader.onload = function() { resolve(reader.result); };
      reader.onerror = reject;
      reader.readAsDataURL(file);
  });
}

export async function apiChangeDisplayname(uuid, username, displayname, summary, photoBase64) {
  
  let success = await apiRequestPost("change_displayname", { 
    uuid, 
    username,
    displayname,
    summary
  }, {file: photoBase64}).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiBatchListPostingsWithTree(list) {
  let promiseList = [];
  promiseList.push(apiGetCategoryTree());
  promiseList.push(apiBatchListPostings(list));
  let response = await Promise.all(promiseList);
  if (response && response.length > 1) {
    return {
      categories: response[0],
      sections: response[1]
    };  
  }
  else {
    return {
      categories: [],
      sections: []
    };  
  }
}

export async function apiGetCategoryTree() {
  let paramsObj = {};

  let list = await apiRequest("get_category_tree", paramsObj).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("categories")) {
      return response.categories;
    }
    else {
      return [];
    }
  }).catch((err) => {
    return [];
  });

  return list;
}

export async function apiBatchListPostings(list) {
  let auth = store.getState().auth;
  let uuid = auth.uuid;
  let username = auth.username;
  let promiseList = [];
  for (let i = 0; i < list.length; i++) {
    let entry = list[i];
    promiseList.push(apiListPostings(uuid, username, entry.user, entry.bread_location, entry.bread_type, entry.bread_category, entry.search, entry.postingids, entry.next_key));
  }

  return await Promise.all(promiseList);
}

export function getPostingSectionTitle(user, bread_location, bread_type, bread_category, search, postingids) {
  let title = user !== "" ? ("Postings for " + user) : getBreadPathDisplay(bread_location, bread_type, bread_category);
  if (postingids && postingids.indexOf(",") >= 0) {
    title = "Featured postings";
  }
  else if (postingids !== "") {
    title = "";
  }
  else if (search !== "") {
    title = "Postings for " + search;
  }
  return title;
}

export async function apiListPostings(uuid, requesting_user, user, bread_location, bread_type, bread_category, search, postingids, next_key) {
  let title = getPostingSectionTitle(user, bread_location, bread_type, bread_category, search, postingids);

  return await apiRequest("list_postings", { 
    uuid,
    requesting_user,
    user, 
    bread_location, 
    bread_type, 
    bread_category,
    search,
    postingids, 
    next_key: (next_key === "" ? "" : JSON.stringify(next_key))
  }).then((response) => {
    if (response.status === "success" && response.hasOwnProperty("postings") && response.hasOwnProperty("pagination_key")) {
      let { postings=[], pagination_key="" } = response;
      return { postings, pagination_key, user, bread_location, bread_type, bread_category, search, postingids, title, status: "success" };
    }
    else {
      return { postings: [], pagination_key: "", user, bread_location, bread_type, bread_category, search, postingids, title, status: "failed" };
    }
  }).catch((err) => {
    return { postings: [], pagination_key: "", user, bread_location, bread_type, bread_category, search, postingids, title, status: "failed" };
  });
}

export function getLoadingPostingList(list) {
  let loadingList = [];
  for (let i = 0; i < list.length; i++) {
    let entry = list[i];
    let {
      user="", 
      bread_location="", 
      bread_type="", 
      bread_category="",
      search="",
      postingids=""
    } = entry;

    let title = getPostingSectionTitle(user, bread_location, bread_type, bread_category, search, postingids);
    loadingList.push({ postings: [], user, bread_location, bread_type, bread_category, search, postingids, title, status: "pending" });
  }

  return loadingList;
}

export function getMarketsPageSections(bread_location, bread_type, bread_category, search, next_key ) {
  return [
    { user: "", bread_location, bread_type, bread_category, search, postingids: "", next_key }
  ];
}

export function getPostingsPageSections(user, next_key) {
  return [
    { user, bread_location: "", bread_type: "", bread_category: "", search: "", postingids: "", next_key }
  ];
}

export async function apiNewPosting(uuid, username, postingid, bread_location, bread_type, bread_category, title, description, location_details, price_amount, price_type, deposit_amount, deposit_type, deadline, contract_template, contract_details, visibility, arbitration_mode, arbitrator, arbitration_fee_value, arbitration_fee_type, arbitration_days, arbitration_request_days, auto_resolve_days, has_automatic_outcome, default_resolution, photo_list, image_content_list) {

  let errorField = "";
  let errorMsg = "";
  let warningMsg = "";

  let success = await apiRequestPost((postingid === "" ? "new_posting" : "update_posting"), { 
    uuid,
    username,
    postingid,
    bread_location,
    bread_type,
    bread_category,
    title,
    location_details,
    price_amount,
    price_type,
    deposit_amount,
    deposit_type,
    contract_template,
    visibility,
    arbitration_mode,
    arbitrator,
    arbitration_fee_value,
    arbitration_fee_type,
    arbitration_days,
    arbitration_request_days,
    auto_resolve_days, 
    has_automatic_outcome, 
    default_resolution, 
    photo_list: JSON.stringify(photo_list)
  }, {
    description,
    deadline,
    contract_details
  }).then((response) => {
    if (response && response.status === "success") {
      postingid = response.postingid;
      return true;
    }
    else {
      if (response && response.hasOwnProperty("errorField") && response.hasOwnProperty("errorMsg")) {
        errorField = response.errorField;
        errorMsg = response.errorMsg;
      }

      return false;
    }
  }).catch((err) => {
    errorField = "";
    errorMsg = "There was a submission error";
    return false;
  });

  if (success && photo_list.length === image_content_list.length) {
    for (let i = 0; i < photo_list.length; i++) {
      let photoIndex = photo_list[i];
      let imageContent = image_content_list[i];
      if (imageContent.substring(0, 8) !== "https://") {
        let imageAdded = await apiAppPostingPhotos(uuid, username, postingid, [photoIndex], [imageContent]);
        if (!imageAdded) {
          console.log("Failed to add image");
          warningMsg = "There was an issue uploading images. Please try again.";
        }  
      }
      else {
        console.log("Skipping image " + i);
      }
    }
  }



  return {success, postingid, errorField, errorMsg, warningMsg};
}

export async function apiContractFromPosting(uuid, username, postingid, user1_address) {
  return await apiRequest("new_agreement_from_posting", { 
    uuid,
    username,
    postingid,
    user1_address
  }).then((response) => {
    if (response && response.status === "success") {
      return {success: true, agreementid: response.agreementid};
    }
    else {
      return {success: false};
    }
  }).catch((err) => {
    return {success: false};
  });
}

export async function apiAppPostingPhotos(uuid, username, postingid, photo_list, photoBase64) {
  return await apiRequestPost("add_posting_photos", { 
    uuid,
    username,
    postingid,
    photo_list: JSON.stringify(photo_list)
  }, {file: photoBase64}).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });
}

export async function apiUpdateProfilePhoto(uuid, username, displayname, photoBase64) {
  let success = await apiRequestPost("update_profile_photo", { 
    uuid, 
    username,
    displayname
  }, {file: photoBase64}).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiUpdateProfileSummary(uuid, username, summary) {
  let success = await apiRequest("update_profile_summary", { 
    uuid, 
    username,
    summary
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiUpdateProfileEmail(uuid, username, email_settings) {
  let success = await apiRequest("update_profile_email", { 
    uuid, 
    username,
    email_settings: JSON.stringify(email_settings)
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiCheckForDeploymentTransaction(uuid, username, agreementid) {
  let retVal = await apiRequest("check_for_deployment_transaction", { 
    uuid, 
    username,
    agreementid
  }).then((response) => {
    if (response && response.status === "succeeded" && response.hasOwnProperty('hash')) {
      return response.hash;
    }
    else {
      return null;
    }
  }).catch((err) => {
    return null;
  });

  return retVal;
}

export async function apiUpdateAgreementTransaction(uuid, username, agreementid, function_name, status, hash) {
  let success = await apiRequest("update_agreement_transaction", { 
    uuid, 
    username,
    agreementid,
    function_name,
    status,
    hash
  }).then((response) => {
    if (response && response.status === "succeeded") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

// function is the ETH contract function that this transaction corresponds to (create, deposit, etc)
export async function apiAddTransactionHash(uuid, username, agreementid, hash, contract, action) {
  let success = await apiRequest("add_transaction_hash", { 
    uuid, 
    username,
    agreementid,
    hash,
    contract,
    action
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiFinalizeAgreement(uuid, username, agreementid, versionid, deployedid, sha3, hash, contract_type) {
  let success = await apiRequest("finalize", { 
    uuid, 
    username,
    agreementid,
    versionid,
    deployedid,
    sha3,
    hash,
    contract_type
  }).then((response) => {
    if (response && response.hasOwnProperty("agreement_status") && response.agreement_status === "finalized") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export async function apiArbitratorStatus(uuid, username, agreementid, arbitrator_status) {
  let success = await apiRequest("arbitrator_status", { 
    uuid, 
    username,
    agreementid,
    arbitrator_status
  }).then((response) => {
    if (response && response.status === "success") {
      return true;
    }
    else {
      return false;
    }
  }).catch((err) => {
    return false;
  });

  return success;
}

export function roundWithDecimals(amountStr, zerosStr) {
  let zeros = parseInt(zerosStr, 10) || 0;
  amountStr = amountStr.toString();

  let amountArr = amountStr.split(".");
  let amountStartStr = "";
  let amountEndStr = "";

  if (amountArr.length === 2) {
    amountStartStr = amountArr[0];
    amountEndStr = amountArr[1];
  }
  else if (amountArr.length === 1) {
    amountStartStr = amountArr[0];
    amountEndStr = "";
  }
  else {
    amountStartStr = "0";
    amountEndStr = "";
  }

  let delim = "";
  let amountStartWithCommasStr = "";
  for (let i = amountStartStr.length; i >= 0; i -= 3) {
    let start = i - 3 > 0 ? i - 3 : 0;
    let threeChars = amountStartStr.substring(start, i);
    if (threeChars !== "") {
      amountStartWithCommasStr = threeChars + delim + amountStartWithCommasStr;
    }
    delim = ",";
  }

  while (amountEndStr.length < zeros) {
    amountEndStr += "0";
  }

  if (amountEndStr.length > 2) {
    amountEndStr = amountEndStr.substring(0, 2);
  }

  return amountStartWithCommasStr + "." + amountEndStr;
}

export async function transferFunds(token, amount, fromAddress, toAddress, dispatch) {
  let initialDialogGuid = initialDialogGUID();

  if (utils.isAddress(fromAddress) && utils.isAddress(toAddress)) {
    let receipt = null;
    if (token === "eth") {
      let amountStr = amount.toString();

      dispatch({
        type: 'METAMASK_OPENED',
        payload: true
      });

      const rawTransaction = {
        to: toAddress,
        value: ethers.utils.parseEther(amountStr)
      };

      const signer = window.ethersProvider.getSigner();
    
      try {
        const hash = await signer.sendUncheckedTransaction(rawTransaction);

        if (checkDialogGUID(initialDialogGuid)) {
          dispatch({
            type: 'METAMASK_OPENED',
            payload: false
          });

          dispatch({
            type: 'DIALOGFUNDING_WITHDRAW_STATUS',
            payload: { status: "pending", hash }
          });              
        }

        receipt = await window.ethersProvider.waitForTransaction(hash);
      }
      catch (err) {
        if (checkDialogGUID(initialDialogGuid)) {
          dispatch({
            type: 'METAMASK_OPENED',
            payload: false
          });
        }
        receipt = null;
      }

      if (receipt !== null) {
        if (checkDialogGUID(initialDialogGuid)) {
          dispatch({
            type: 'DIALOGFUNDING_WITHDRAW_STATUS',
            payload: { status: "success" }
          });
        }
      }
      else {
        if (checkDialogGUID(initialDialogGuid)) {
          dispatch({
            type: 'DIALOGFUNDING_WITHDRAW_STATUS',
            payload: { status: "error" }
          });
        }
      }

      //console.log(receipt);

      // Done
    }
    else {
      let tokenSymbol = token.toUpperCase();
      let auth = store.getState().auth;
      if (auth.erc20Balances.hasOwnProperty(tokenSymbol)) {
        let contract = getEthersJSContractForToken(tokenSymbol);
        let decimals = auth.erc20Balances[tokenSymbol].decimals;
        let amountStr = shared.multiplyDecimals(amount, decimals);

        dispatch({
          type: 'METAMASK_OPENED',
          payload: true
        });
        
        try {
          const txObj = await contract.transfer(toAddress, amountStr);
          const hash = txObj ? txObj.hash : "";

          if (checkDialogGUID(initialDialogGuid)) {
            dispatch({
              type: 'METAMASK_OPENED',
              payload: false
            });
  
            dispatch({
              type: 'DIALOGFUNDING_WITHDRAW_STATUS',
              payload: { status: "pending", hash }
            });
          }

          receipt = await window.ethersProvider.waitForTransaction(hash);
        }
        catch (err) {
          if (checkDialogGUID(initialDialogGuid)) {
            dispatch({
              type: 'METAMASK_OPENED',
              payload: false
            });
          }
          receipt = null
        }        

        if (receipt !== null) {
          if (checkDialogGUID(initialDialogGuid)) {
            dispatch({
              type: 'DIALOGFUNDING_WITHDRAW_STATUS',
              payload: { status: "success" }
            });
          }
        }
        else {
          if (checkDialogGUID(initialDialogGuid)) {
            dispatch({
              type: 'DIALOGFUNDING_WITHDRAW_STATUS',
              payload: { status: "error" }
            });
          }
        }

        //console.log(receipt);
      }
    }
  }
}

export async function hasAllowance(token, amount, contractType) {
  if (!shared.CONTRACT_TYPE_LIST.includes(contractType)) {
    console.log("Invalid contract type: " + contractType);
  }

  if (token === "eth") {
    return true;
  }

  let tokenSymbol = token.toUpperCase();
  let auth = store.getState().auth;
  let ethAddress = auth.ethAddress;
  let contractAddress = shared.getContractAddress(auth.testMode, contractType);
  let hasAllowance = false;
  if (auth.erc20Balances.hasOwnProperty(tokenSymbol)) {
    let contract = getEthersJSContractForToken(tokenSymbol);
    let rawAllowance = "0";
    try {
      
      if (contract !== null) {
        //rawAllowance = await contract.allowance(ethAddress, contractAddress);

        rawAllowance = await Promise.race([
          contract.allowance(ethAddress, contractAddress),
          new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 2000))
        ]);

        rawAllowance = rawAllowance.toString();
      }

      /*
      if (contract !== null) {
        // Includes a timeout so that await doesn't cause a stall
        rawAllowance = await Promise.race([
          contract.methods.allowance(ethAddress, contractAddress).call({from: ethAddress}),
          new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 2000))
        ]);

        rawAllowance = rawAllowance.toString();
      }
      */
    }
    catch (err) {
      console.log("Catch rawAllowance");
      console.log(err);
      rawAllowance = "0";
    }

    let decimals = auth.erc20Balances[tokenSymbol].decimals;
    let amountStr = shared.multiplyDecimals(amount, decimals);
    if (shared.lessThanBN(rawAllowance, amountStr, "hasAllowance")) {
      hasAllowance = false;
    }
    else {
      hasAllowance = true;
    }
  }

  return hasAllowance;
}

export async function approveAllowance(token, amountStr, contractType, dispatch) {
  if (!shared.CONTRACT_TYPE_LIST.includes(contractType)) {
    console.log("Invalid contract type: " + contractType);
  }

  if (amountStr !== "0" && shared.lessThanBN(amountStr, "10", "approveAllowance")) {
    alert("Warning: approveAllowance too small!");
  }

  if (token === "eth") {
    return true;
  }

  let tokenSymbol = token.toUpperCase();
  let auth = store.getState().auth;
  let ethAddress = auth.ethAddress;
  let contractAddress = shared.getContractAddress(auth.testMode, contractType);
  let hasAllowance = false;
  if (auth.erc20Balances.hasOwnProperty(tokenSymbol)) {
    let contract = getEthersJSContractForToken(tokenSymbol);
    let rawAllowance = "0";
    try {  

      if (contract !== null) {
        //rawAllowance = await contract.allowance(ethAddress, contractAddress);

        rawAllowance = await Promise.race([
          contract.allowance(ethAddress, contractAddress),
          new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 2000))
        ]);

        rawAllowance = rawAllowance.toString();
      }

      /*
      if (contract !== null) {
        // Includes a timeout so that await doesn't cause a stall
        rawAllowance = await Promise.race([
          contract.methods.allowance(ethAddress, contractAddress).call({from: ethAddress}),
          new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 2000))
        ]);

        rawAllowance = rawAllowance.toString();
      }
      */
    }
    catch (err) {
      //console.log("Catch rawAllowance");
      //console.log(err);
      rawAllowance = "0";
    }

    if (shared.lessThanBN(rawAllowance, amountStr, "approveAllowance")) {
      dispatch({
        type: 'METAMASK_OPENED',
        payload: true
      });

      dispatch({
        type: 'APPROVE_ALLOWANCE_STATUS',
        payload: { name: "waitingForApproveAllowance", value: true }
      });

      dispatch({
        type: 'APPROVE_ALLOWANCE_STATUS',
        payload: { name: "waitingTimestampUnix", value: moment().tz('America/Los_Angeles').unix() }
      });
      
      try {
        const txObj = await contract.approve(contractAddress, amountStr);
        const hash = txObj ? txObj.hash : "";

        //console.log(hash); 

        dispatch({
          type: 'APPROVE_ALLOWANCE_STATUS',
          payload: { name: "waitingTimestampUnix", value: moment().tz('America/Los_Angeles').unix() }
        });

        dispatch({
          type: 'APPROVE_ALLOWANCE_STATUS',
          payload: { name: "waitingApproveAllowanceHash", value: hash }
        });

        dispatch({
          type: 'METAMASK_OPENED',
          payload: false
        });

        await window.ethersProvider.waitForTransaction(hash);

        dispatch({
          type: 'APPROVE_ALLOWANCE_STATUS',
          payload: { name: "allowanceApprovalTransactionComplete", value: true }
        });

        //console.log("Has receipt");
        //console.log(receipt);
        hasAllowance = true;
      }
      catch (err) {
        //console.log("Catch approve allowance");
        hasAllowance = false;

        dispatch({
          type: 'METAMASK_OPENED',
          payload: false
        });
      }

      dispatch({
        type: 'APPROVE_ALLOWANCE_STATUS',
        payload: { name: "waitingForApproveAllowance", value: false }
      });
    }
    else {
      //console.log("Has enough allowance already");
      hasAllowance = true;
    }
  }

  return hasAllowance;
}

export function isMenuToken(type) {
  type = type.toLowerCase();
  return getMenuTokens().includes(type);
}

export function summaryFromAgreementVersion(version, username) {
  let summary = {};

  if (version && version.agreement) {

    summary.postingid = version.agreement.postingid || "";
    summary.agreementVisibility = version.agreement.visibility || "";
    summary.user1VisibilityRequest = version.agreement.user1_visibility_request || "";
    summary.user2VisibilityRequest = version.agreement.user2_visibility_request || "";
    summary.user1Locked = version.agreement.user1 || "";
    summary.user2Locked = version.agreement.user2 || "";
    summary.agreementType = version.type || "";
    summary.user1ContributionInput = version.amount_value1 || "0";
    summary.user1ContributionType = version.amount_type1 || "eth";
    summary.user2ContributionInput = version.amount_value2 || "0";
    summary.user2ContributionType = version.amount_type2 || "eth";
    summary.user1ZeroContribution = summary.user1ContributionInput === "0";
    summary.user2ZeroContribution = summary.user2ContributionInput === "0";
    summary.title = version.title || "";
    summary.agreementTerms = version.terms || "";
    summary.youAreUser1 = (summary.user1Locked === username && username !== "");
    summary.youAreUser2 = (summary.user2Locked === username && username !== "");
    summary.youAreArbitrator = (version.agreement.arbitrator === username && username !== "");
    summary.youAreThisVersionAbitrator = (version.arbitrator === username && username !== "");
    summary.youAgreedToAbitrate = (version.agreement.arbitrator === username && username !== "" && version.agreement.arbitrator_status === "agreed");
    summary.agreedArbitratorUsername = version.agreement.arbitrator || "";
    summary.agreedArbitratorStatus = version.agreement.arbitrator_status || "";
    summary.user1ExplicitApproval = version.agreement.version_user1_explicit || false;
    summary.user2ExplicitApproval = version.agreement.version_user2_explicit || false;
    summary.user2LinkId = version.agreement.user2_linkid || "";
    summary.versionUser1Proposed = version.agreement.version_user1_proposed || "";
    summary.versionUser2Proposed = version.agreement.version_user2_proposed || "";
    summary.versionLatestProposed = version.agreement.version_latest_proposed || "";
    summary.versionUser1Num = version.agreement.version_user1_num || "";
    summary.versionUser2Num = version.agreement.version_user2_num || "";
    summary.versionLatestNum = version.agreement.version_latest_num || "";
    summary.versionUser1Timestamp = version.agreement.version_user1_timestamp || 0;
    summary.versionUser2Timestamp = version.agreement.version_user2_timestamp || 0;
    summary.versionLatestTimestamp = version.agreement.version_latest_timestamp || 0;
    summary.completeTimestamp = version.agreement.complete_unix || 0;
    summary.status = version.agreement.status || "";
    summary.versionContractType = version.version_contract_type || "";

    summary.user1AllowsImplicitApproval = false;
    if (version.user1_allows_implicit_approval) {
      summary.user1AllowsImplicitApproval = true;
    }

    summary.user2AllowsImplicitApproval = false;
    if (version.user2_allows_implicit_approval) {
      summary.user2AllowsImplicitApproval = true;
    }
    
    summary.hiddenForYou = false;
    summary.hiddenUser1 = false;
    if (version.agreement.hidden_user1 && version.agreement.hidden_user1 === "hidden") {
      summary.hiddenUser1 = true;
      if (summary.youAreUser1) {
        summary.hiddenForYou = true;
      }
    }

    summary.hiddenUser2 = false;
    if (version.agreement.hidden_user2 && version.agreement.hidden_user2 === "hidden") {
      summary.hiddenUser2 = true;
      if (summary.youAreUser2) {
        summary.hiddenForYou = true;
      }
    }

    summary.hiddenArbitrator = false;
    if (
      version.agreement.hasOwnProperty("hidden_arbitrator_map") && 
      version.agreement.hidden_arbitrator_map.hasOwnProperty(username) && 
      version.agreement.hidden_arbitrator_map[username] === "hidden"
    ) {
      summary.hiddenArbitrator = true;
      if (summary.youAreArbitrator) {
        summary.hiddenForYou = true;
      }
    }

    summary.hiddenPending = false;
    if (
      version.agreement.hasOwnProperty("hidden_pending")
    ) {
      summary.hiddenPending = version.agreement.hidden_pending;
    }

    summary.ratingsByUser1 = JSON.parse(version.agreement.ratings_by_user1 || "{}");
    summary.ratingsByUser2 = JSON.parse(version.agreement.ratings_by_user2 || "{}");
    summary.ratingsByArbitrator = JSON.parse(version.agreement.ratings_by_arbitrator || "{}");
    summary.ratingsByYou = {};
    if (summary.youAreUser1) {
      summary.ratingsByYou = summary.ratingsByUser1;
    }
    else if (summary.youAreUser2) {
      summary.ratingsByYou = summary.ratingsByUser2;
    }
    else if (summary.youAreArbitrator) {
      summary.ratingsByYou = summary.ratingsByArbitrator;
    }

    summary.agreedSha3 = version.agreement.agreed_sha3 || "";
    summary.agreedData = version.agreement.agreed_data || "";
    summary.agreedDataObj = summary.agreedData === "" ? {} : JSON.parse(summary.agreedData);
    summary.metaevidenceHash = version.agreement.metaevidence_hash || "";
    summary.metacontentHash = version.agreement.metacontent_hash || "";
    summary.deployedid = version.agreement.deployedid || "";
    summary.finalizedBy = version.agreement.finalized_by || "";
    summary.transactionType = version.agreement.transactiontype || "";
    summary.transactionHash = version.agreement.transactionhash || "";
    summary.contractType = version.agreement.contract_type || "";
    summary.transactionByUsername = version.agreement.lock_by || "";
    summary.transactionByYou = (summary.transactionByUsername === username && summary.transactionByUsername !== "");
    summary.transactionByOtherParty = (summary.transactionByUsername !== username && summary.transactionByUsername !== "");
    summary.transactionLockUntilUnix = (version.agreement.lock_timestamp + 900) || 0;
    summary.transactionsUser1 = version.agreement.transactions_user1 || "[]";
    summary.transactionsUser2 = version.agreement.transactions_user2 || "[]";
    summary.transactionsArbitrator = version.agreement.transactions_arbitrator || "[]";
    summary.arbitrationFeeInput = version.arbitration_fee_value || 0;
    summary.arbitrationFeeType = version.arbitration_fee_type || "";
    summary.defaultResolution = version.default_resolution || "";
    summary.autoResolveDate = dateUnixToString(version.auto_resolve_unix);
    summary.autoResolveUnix = version.auto_resolve_unix;
    summary.requestArbitrationDate = dateUnixToString(version.arbitration_request_unix);
    summary.autoResolveDisplay = datetimeDisplayString(version.auto_resolve_unix);
    summary.requestArbitrationDisplay = datetimeDisplayString(version.arbitration_request_unix);
    summary.daysToRespondInput = version.arbitration_days || "";
    summary.arbitrationMode = version.arbitration_mode || "";
    summary.agreementid = version.agreement.agreementid || "";
    summary.versionid = version.versionid || "";
    summary.isTestAgreement = getIsTestAgreement(summary.agreementid);
    summary.versionNum = version.version_num || "";
    summary.versionTimestamp = version.timestamp_unix || 0;
    summary.createdTimestamp = version.agreement.created_unix || 0;
    if (summary.agreedDataObj.hasOwnProperty("user1") && summary.agreedDataObj.hasOwnProperty("user2")) {
      summary.user1StakeDisplay = getTokenAmountDisplay(summary.agreedDataObj.amount_raw1, summary.agreedDataObj.amount_type1);
      summary.user2StakeDisplay = getTokenAmountDisplay(summary.agreedDataObj.amount_raw2, summary.agreedDataObj.amount_type2);
      summary.autoResolveDate = dateUnixToString(summary.agreedDataObj.auto_resolve_unix);
      summary.autoResolveUnix = parseInt(summary.agreedDataObj.auto_resolve_unix, 10);
      summary.requestArbitrationDate = dateUnixToString(summary.agreedDataObj.arbitration_request_unix);
      summary.autoResolveDisplay = datetimeDisplayString(summary.agreedDataObj.auto_resolve_unix);
      summary.requestArbitrationDisplay = datetimeDisplayString(summary.agreedDataObj.arbitration_request_unix);
    }
    summary.hasOnlyMenuTokens = isMenuToken(summary.user1ContributionType) && isMenuToken(summary.user2ContributionType) && isMenuToken(summary.arbitrationFeeType);

    let versionArbitratorUsername = version.arbitrator;
    summary.versionArbitrator = version.arbitrator;

    if (versionArbitratorUsername) {
      let arbitratorLatestSettings = version.arbitrator_latest_settings;
      summary.searchArbitratorAutosuggest = getAutoSuggestObjectFromUsername(versionArbitratorUsername, arbitratorLatestSettings);
    }

    summary.user2LinkIdType = "";
    summary.user2LinkIdValue = "";
    summary.user2DisplayValue = "";
    if (summary.user2LinkId) {
      let splitArr = summary.user2LinkId.split('#');
      if (splitArr.length > 1) {
        summary.user2LinkIdType = splitArr[0];
        summary.user2LinkIdValue = splitArr[1];
      }
    }

    if (summary.user2Locked) {
      summary.user2DisplayValue = summary.user2Locked;
    }
    else {
      summary.user2DisplayValue = summary.user2LinkIdValue;
    }

    summary.user1Disp = (summary.youAreUser1 ? "you" : summary.user1Locked);
    summary.user2Disp = (summary.youAreUser2 ? "you" : summary.user2DisplayValue);
    summary.arbitratorDisp = (summary.youAreArbitrator ? "you" : summary.agreedArbitratorUsername);
  
    summary.displayAmount = [];
    if (summary.agreementType === "contract") {
      if (summary.user1ContributionType === summary.user2ContributionType) {
        let totalAmount = shared.addFloatBN(shared.getNumberString(summary.user1ContributionInput), shared.getNumberString(summary.user2ContributionInput), "displayAmount");
        totalAmount = totalAmount.toString();
        summary.displayAmount.push(totalAmount + " " + summary.user1ContributionType.toUpperCase());
      }
      else {
        summary.displayAmount.push(summary.user1ContributionInput + " " + summary.user1ContributionType.toUpperCase());
        summary.displayAmount.push(summary.user2ContributionInput + " " + summary.user2ContributionType.toUpperCase());
      }
    }
    else if (summary.agreementType === "request")  {
      summary.displayAmount.push(summary.user2ContributionInput + " " + summary.user2ContributionType.toUpperCase());
    }
    else {
      summary.displayAmount.push(summary.user1ContributionInput + " " + summary.user1ContributionType.toUpperCase());
    }

    summary.amountDisp = "";
    summary.detailsDisp = "";
    for (let j = 0; j < summary.displayAmount.length; j++) {
      if (j !== 0) {
        summary.amountDisp += " and ";
      }
      summary.amountDisp += summary.displayAmount[j];
    }

    if (summary.youAreUser1) {
      if (summary.agreementType === "payment") {
        summary.detailsDisp = "Pay " + summary.amountDisp + " to " + summary.user2Disp;
      }
      else if (summary.agreementType === "request") {
        summary.detailsDisp = "Request " + summary.amountDisp + " from " + summary.user2Disp;
      }
      else if (summary.agreementType === "contract") {
        summary.detailsDisp = "Contract with " + summary.user2Disp + " for " + summary.amountDisp;
      }
    }
    else if (summary.youAreUser2) {
      if (summary.agreementType === "request") {
        summary.detailsDisp = "Pay " + summary.amountDisp + " to " + summary.user1Disp;
      }
      else if (summary.agreementType === "payment") {
        summary.detailsDisp = "Receive " + summary.amountDisp + " from " + summary.user1Disp;
      }
      else if (summary.agreementType === "contract") {
        summary.detailsDisp = "Contract with " + summary.user1Disp + " for " + summary.amountDisp;
      }
    }
    else {
      if (summary.agreementType === "payment") {
        summary.detailsDisp = "Payment of " + summary.amountDisp + " from " + summary.user1Disp + " to " + summary.user2Disp;
      }
      else if (summary.agreementType === "request") {
        summary.detailsDisp = "Request for " + summary.amountDisp + " from " + summary.user1Disp + " to " + summary.user2Disp;
      }
      else if (summary.agreementType === "contract") {
        summary.detailsDisp = "Contract between " + summary.user1Disp + " and " + summary.user2Disp + " for " + summary.amountDisp;
      }
    }
  }

  return summary;
}

export function resolutionPartyToUser(resolutionType, user1Finalized) {
  if (resolutionType === "all_partya") {
    if (user1Finalized) {
      return "all_user1";
    }
    else {
      return "all_user2";
    }
  }
  else if (resolutionType === "all_partyb") {
    if (user1Finalized) {
      return "all_user2";
    }
    else {
      return "all_user1";
    }
  }

  return resolutionType;
}

export function combinedValueAB(obj) {
  let { valueTokenA="", valueTokenB="" } = obj;
  obj.value = valueTokenA + "_" + valueTokenB;
  return obj;
}

export function getValueListFromResolutionList(resolutionList) {
  let valueList = [];
  let existingMap = {};
  for (let i = 0; i < resolutionList.length; i++) {
    let resolutionObj = resolutionList[i];
    let value = resolutionObj.value;
    if (!existingMap.hasOwnProperty(value)) {
      existingMap[value] = true;
      valueList.push(value);
    }
  }

  return valueList;
}

export function getResolutionListOneType(stakeA, stakeB, user1Finalized, isPayment) {
  let resolutionList = [];

  resolutionList.push({name:resolutionPartyToUser("all_partya", user1Finalized), value: shared.addBN(stakeA, stakeB, "")});
  resolutionList.push({name:resolutionPartyToUser("all_partyb", user1Finalized), value: "0"});
  if (!isPayment) {
    resolutionList.push({name:"refund", value: stakeA});
    resolutionList.push({name:"trade", value: stakeB});
  }
  resolutionList.push({name:"fifty", value: shared.divBN(shared.addBN(stakeA,stakeB, ""), "2", "")});

  return resolutionList;
}

export function getResolutionListTwoTypes(stakeA, stakeB, user1Finalized, isPayment) {
  let resolutionList = [];

  resolutionList.push(combinedValueAB({name:resolutionPartyToUser("all_partya", user1Finalized), valueTokenA: stakeA, valueTokenB: stakeB}));
  resolutionList.push(combinedValueAB({name:resolutionPartyToUser("all_partyb", user1Finalized), valueTokenA: "0", valueTokenB: "0"}));
  if (!isPayment) {
    resolutionList.push(combinedValueAB({name:"refund", valueTokenA: stakeA, valueTokenB: "0"}));
    resolutionList.push(combinedValueAB({name:"trade", valueTokenA: "0", valueTokenB: stakeB}));
  }
  resolutionList.push(combinedValueAB({name:"fifty", valueTokenA: shared.divBN(stakeA, "2", ""), valueTokenB: shared.divBN(stakeB, "2", "")}));

  return resolutionList;
}

export function timestampAfterNow(timestampUnix, timestampNow) {
  if (timestampUnix !== 0 && timestampNow > timestampUnix) {
    return true;
  }

  return false;
}

export function secondsRemaining(timestampUnix, timestampNow) {
  if (timestampUnix !== 0 && timestampUnix > timestampNow) {
    return timestampUnix - timestampNow;
  }

  return 0;
}

export function getResolutionDisplayList(resolutionValueList, resolutionTypeList, user1Disp, user2Disp) {
  let resolutionList = [];
  for (let i = 0; i < resolutionValueList.length; i++) {
    let value = resolutionValueList[i];
    let text = "";
    let delim = "";
    for (let j = 0; j < resolutionTypeList.length; j++) {
      let resolutionTypeObj = resolutionTypeList[j];
      let name = resolutionTypeObj.name;
      let display = "";
      if (resolutionTypeObj.value === value) {
        if (name === "all_user1") {
          display = "All to " + user1Disp;
        }
        else if (name === "all_user2") {
          display = "All to " + user2Disp;
        }
        else if (name === "refund") {
          display = "Refund";
        }
        else if (name === "trade") {
          display = "Swap";
        }
        else if (name === "fifty") {
          display = "Split 50-50";
        }
        else {
          display = "Custom";
        }

        text += delim + display;
        delim = " / ";
      }
    }

    if (text !== "") {
      resolutionList.push({
        name: value, 
        text: text
      });
    }
  }

  return resolutionList;
}

export function addToResolutionDisplayList(resolutionList, value, text, selectedBy1, selectedBy2) {
  if (value !== null && text !== null && text !== "") {
    let hasMatch = false;
    for (let i = 0; i < resolutionList.length; i++) {
      if (resolutionList[i].name === value) {
        hasMatch = true;
        resolutionList[i][selectedBy1] = true;
        resolutionList[i][selectedBy2] = true;
      }
    }
  
    if (!hasMatch) {
      resolutionList.push({
        name: value, 
        text: text,
        [selectedBy1]: true,
        [selectedBy2]: true
      });
    }
  }

  return resolutionList;
}

export function hasEnoughFunds(contractType, amountRawStr, tokenType) {
  amountRawStr = amountRawStr.toString();

  let auth = store.getState().auth;
  let erc20Balances = auth.erc20Balances;
  let balanceStr = "0";
  let allowanceStr = "0";
  let hasBalance = false;
  let hasAllowance = false;
  let hasETH = false;
  if (erc20Balances) {
    let tokenTypeUC = tokenType.toUpperCase();
    let balanceObj = {};
    if (erc20Balances.hasOwnProperty(tokenTypeUC)) {
      balanceObj = erc20Balances[tokenTypeUC];
    }

    if (balanceObj.hasOwnProperty("balanceStr")) {
      balanceStr = balanceObj.balanceStr;
    }

    let allowanceKey = "allowance_" + contractType;
    if (balanceObj.hasOwnProperty(allowanceKey)) {
      allowanceStr = balanceObj[allowanceKey];
    }

    hasAllowance = shared.lessThanEqualBN(amountRawStr, allowanceStr, "hasEnoughFunds");
    hasBalance = shared.lessThanEqualBN(amountRawStr, balanceStr, "hasEnoughFunds");

    // make sure we don't have zero ETH, otherwise we definitely can't pay a transaction fee
    if (hasBalance && tokenTypeUC === "ETH") {
      hasETH = true;
    } else {
      let ethBalanceObj = erc20Balances["ETH"];
      if (ethBalanceObj && ethBalanceObj.hasOwnProperty("balanceStr")) {
        if (shared.greaterThanBN(ethBalanceObj.balanceStr, "0", "hasEnoughFunds")) {
          hasETH = true;
        }
      }
    }
  }
  
  return { hasAllowance, hasBalance, hasETH, amountRawStr, allowanceStr, balanceStr };
}

export function getTokenAmountDisplay(amount, tokenType) {
  let val = "0";
  if (amount) {
    val = shared.divideDecimals(amount, shared.decimalsForToken(tokenType));
  }
  return val + " " + tokenType.toUpperCase();
}

export function getCombinedTokenAmountDisplay(amount1, tokenType1, amount2, tokenType2, amount3, tokenType3) {
  amount1 = amount1 ? amount1 : "0";
  amount2 = amount2 ? amount2 : "0";
  amount3 = amount3 ? amount3 : "0";

  let displayAmountList = [];

  if (tokenType1 === tokenType2 && tokenType1 === tokenType3) {
    let combinedAmount = shared.addBN(amount1, amount2, "getCombinedTokenAmountDisplay");
    combinedAmount = shared.addBN(combinedAmount, amount3, "getCombinedTokenAmountDisplay");
    displayAmountList.push(getTokenAmountDisplay(combinedAmount, tokenType1));
  }
  else if (tokenType1 === tokenType2) {
    let combinedAmount = shared.addBN(amount1, amount2, "getCombinedTokenAmountDisplay");
    displayAmountList.push(getTokenAmountDisplay(combinedAmount, tokenType1));
    displayAmountList.push(getTokenAmountDisplay(amount3, tokenType3));
  }
  else if (tokenType1 === tokenType3) {
    let combinedAmount = shared.addBN(amount1, amount3, "getCombinedTokenAmountDisplay");
    displayAmountList.push(getTokenAmountDisplay(combinedAmount, tokenType1));
    displayAmountList.push(getTokenAmountDisplay(amount2, tokenType2));
  }
  else if (tokenType2 === tokenType3) {
    let combinedAmount = shared.addBN(amount2, amount3, "getCombinedTokenAmountDisplay");
    displayAmountList.push(getTokenAmountDisplay(combinedAmount, tokenType2));
    displayAmountList.push(getTokenAmountDisplay(amount1, tokenType1));
  }
  else {
    displayAmountList.push(getTokenAmountDisplay(amount1, tokenType1));
    displayAmountList.push(getTokenAmountDisplay(amount2, tokenType2));
    displayAmountList.push(getTokenAmountDisplay(amount3, tokenType3));
  }

  let responseMsg = "";
  let delim = "";
  for (let i = 0; i < displayAmountList.length; i++) {
    let disp = displayAmountList[i];
    if (disp.substring(0, 2) !== "0 ") {
      responseMsg += delim + disp;
      delim = ", ";  
    }
  }

  if (responseMsg === "") {
    responseMsg = "none";
  }

  return responseMsg;
}

export function getResolutionDisplayOneToken(resolutionType, resolutionDisplayList, dataObj) {
  if (resolutionType === null) {
    return null;
  }

  let { 
    combinedTokenType="",
    combinedStakeAmount="",
    user1Finalized=false,
    user1Disp="",
    user2Disp="",
    youAreUser2=false
  } = dataObj;
  
  for (let i = 0; i < resolutionDisplayList.length; i++) {
    let resolutionDisplay = resolutionDisplayList[i];
    if (resolutionType === resolutionDisplay.name) {
      return resolutionDisplay.text;
    }
  }

  let partyAPayAmount = resolutionType;
  let partyBPayAmount = shared.subBN(combinedStakeAmount, partyAPayAmount, "getResolutionDisplayOneToken");
  let user1PayAmount = user1Finalized ? partyAPayAmount : partyBPayAmount;
  let user2PayAmount = user1Finalized ? partyBPayAmount : partyAPayAmount;

  let user1PayDisplay = getTokenAmountDisplay(user1PayAmount, combinedTokenType);
  let user2PayDisplay = getTokenAmountDisplay(user2PayAmount, combinedTokenType);

  if (youAreUser2) {
    return user2PayDisplay + " to " + user2Disp;
    //return "Pay " + user2Disp + " " + user2PayDisplay + " and pay " + user1Disp + " " + user1PayDisplay + "";
  }
  else {
    return user1PayDisplay + " to " + user1Disp;
    //return "Pay " + user1Disp + " " + user1PayDisplay + " and pay " + user2Disp + " " + user2PayDisplay + "";
  }
}

export function getResolutionDisplayTwoTokens(resolutionType, resolutionDisplayList, dataObj) {
  if (resolutionType === null) {
    return null;
  }

  if (typeof resolutionType === "undefined") {
    console.log("Undefined resolution type");
    console.log(dataObj);
  }

  let { 
    partyAStakeAmount="",
    partyBStakeAmount="",
    partyATokenType="",
    partyBTokenType="",
    user1Finalized=false,
    user1Disp="",
    user2Disp="",
    youAreUser2=false
  } = dataObj;

  let user1TokenType = user1Finalized ? partyATokenType : partyBTokenType;
  let user2TokenType = user1Finalized ? partyBTokenType : partyATokenType;

  for (let i = 0; i < resolutionDisplayList.length; i++) {
    let resolutionDisplay = resolutionDisplayList[i];
    if (resolutionType === resolutionDisplay.name) {
      return resolutionDisplay.text;
    }
  }

  let splitArr = resolutionType.split('_');
  if (splitArr.length > 1) {
    let partyATokenAPayAmount = splitArr[0];
    let partyATokenBPayAmount = splitArr[1];
    let partyBTokenAPayAmount = shared.subBN(partyAStakeAmount, partyATokenAPayAmount, "getResolutionDisplayTwoTokens");
    let partyBTokenBPayAmount = shared.subBN(partyBStakeAmount, partyATokenBPayAmount, "getResolutionDisplayTwoTokens");
    let user1Token1PayAmount = user1Finalized ? partyATokenAPayAmount : partyBTokenBPayAmount;
    let user1Token2PayAmount = user1Finalized ? partyATokenBPayAmount : partyBTokenAPayAmount;
    let user2Token1PayAmount = user1Finalized ? partyBTokenAPayAmount : partyATokenBPayAmount;
    let user2Token2PayAmount = user1Finalized ? partyBTokenBPayAmount : partyATokenAPayAmount;

    let user1Token1PayDisplay = getTokenAmountDisplay(user1Token1PayAmount, user1TokenType);
    let user1Token2PayDisplay = getTokenAmountDisplay(user1Token2PayAmount, user2TokenType);
    let user2Token1PayDisplay = getTokenAmountDisplay(user2Token1PayAmount, user1TokenType);
    let user2Token2PayDisplay = getTokenAmountDisplay(user2Token2PayAmount, user2TokenType);

    if (youAreUser2) {
      return user2Token2PayDisplay + " + " + user2Token1PayDisplay + " to " + user2Disp;
      //return "Pay " + user2Disp + " " + user2Token2PayDisplay + " + " + user2Token1PayDisplay + " and pay " + user1Disp + " " + user1Token2PayDisplay + " + " + user1Token1PayDisplay + "";
    }
    else {
      return user1Token1PayDisplay + " + " + user1Token2PayDisplay + " to " + user1Disp;
      //return "Pay " + user1Disp + " " + user1Token1PayDisplay + " + " + user1Token2PayDisplay + " and pay " + user2Disp + " " + user2Token1PayDisplay + " + " + user2Token2PayDisplay + "";
    }
  }

  return "Error: no summary";
}

export function resolutionTokenRemainder(payAmount, stakeAmount) {
  if (payAmount === null) {
    return null;
  }

  if (shared.greaterThanEqualBN(stakeAmount, payAmount, "resolutionTokenRemainder")) {
    return shared.subBN(stakeAmount, payAmount, "resolutionTokenRemainder");
  }
  
  return "0";
}

export function summaryFromAgreementBlockchain(agreementSummaryObj) {

  let {
    user1Locked="",
    user1Disp="",
    user2Disp="",
    finalizedBy="", 
    agreementid="", 
    agreedSha3="",
    agreedDataObj="",
    youAreUser1=false,
    youAreUser2=false,
    youAreArbitrator=false,
    user1Address="",
    user2Address="",
    arbitratorAddress="",
    contractType=""
  } = agreementSummaryObj;
  
  let dataObj = {};
  let errorTrace = "";
  let auth = store.getState().auth;
  let username = auth.username;
  let ethAddress = auth.ethAddress;
  let ethNetwork = auth.ethNetwork;

  dataObj.blockchainSynced = false;
  dataObj.databaseSynced = false;
  dataObj.blockchainInit = false;
  dataObj.canSubmitEvidence = false;
  dataObj.hasDataIntegrity = false;
  dataObj.usingWrongAddress = false;
  dataObj.usingWrongNetwork = false;
  dataObj.shouldUseAddress = "";
  dataObj.flagNoAddress = (auth.initCheckAddress && auth.ethAddress === "");
  dataObj.hasBalanceForDeposit = false;
  dataObj.hasAllowanceForDeposit = false;
  dataObj.hasBalanceForArbitrationFee = false;
  dataObj.hasAllowanceForArbitrationFee = false;
  dataObj.hasETHForTransactionFee = false;
  if (agreedSha3 !== "" && agreedDataObj !== "" && agreedDataObj.hasOwnProperty("user1") && agreedDataObj.hasOwnProperty("user2")) {
    dataObj.databaseSynced = true;
    if (youAreUser1) {
       let fundsObj = hasEnoughFunds(contractType, agreedDataObj.amount_raw1, agreedDataObj.amount_type1);
       let { hasAllowance=false, hasBalance=false, hasETH=false } = fundsObj;
       dataObj.hasBalanceForDeposit = hasBalance;
       dataObj.hasAllowanceForDeposit = hasAllowance;
       dataObj.hasETHForTransactionFee = hasETH;
    }

    if (youAreUser2) {
      let fundsObj = hasEnoughFunds(contractType, agreedDataObj.amount_raw2, agreedDataObj.amount_type2);
      let { hasAllowance=false, hasBalance=false, hasETH=false } = fundsObj;
      dataObj.hasBalanceForDeposit = hasBalance;
      dataObj.hasAllowanceForDeposit = hasAllowance;
      dataObj.hasETHForTransactionFee = hasETH;
    }
    
    let fundsObj = hasEnoughFunds(contractType, agreedDataObj.arbitration_fee_raw, agreedDataObj.arbitration_fee_type);
    let { hasAllowance=false, hasBalance=false, hasETH=false } = fundsObj;
    dataObj.hasBalanceForArbitrationFee = hasBalance;
    dataObj.hasAllowanceForArbitrationFee = hasAllowance;
    dataObj.hasETHForTransactionFee = hasETH;
  }

  let user1Finalized = (user1Locked === finalizedBy);
  dataObj.agreementid = agreementid;
  dataObj.user1Finalized = user1Finalized;
  dataObj.youArePartyA = false;
  dataObj.youArePartyB = false;
  dataObj.youAreArbitrator = youAreArbitrator;
  dataObj.youAreUser1 = youAreUser1;
  dataObj.youAreUser2 = youAreUser2;

  if (youAreUser1 && user1Address !== "") {
    dataObj.usingWrongAddress = (lowerCase(ethAddress) !== lowerCase(user1Address));
    dataObj.shouldUseAddress = user1Address;
  }
  else if (youAreUser2 && user2Address !== "") {
    dataObj.usingWrongAddress = (lowerCase(ethAddress) !== lowerCase(user2Address));
    dataObj.shouldUseAddress = user2Address;
  }
  else if (youAreArbitrator && arbitratorAddress !== "") {
    dataObj.usingWrongAddress = (lowerCase(ethAddress) !== lowerCase(arbitratorAddress));
    dataObj.shouldUseAddress = arbitratorAddress;
  }

  if (ethNetwork !== shared.getNetworkId(auth.testMode)) {
    dataObj.usingWrongNetwork = true;
  }

  dataObj.disputeRequested = false;
  dataObj.user1Disp = user1Disp;
  dataObj.user2Disp = user2Disp;
  let resolutionTypeList = [];
  let resolutionValueList = [];
  let resolutionDisplayList = [];
  let blockchain = store.getState().blockchain;
  let stateDataByAgreement = blockchain.stateData;

  if (agreementid !== "" && stateDataByAgreement.hasOwnProperty(agreementid)) {
    errorTrace += "[1] ";
    let stateDataObj = stateDataByAgreement[agreementid];
    let stateObj = null;

    if (stateDataObj) {
      stateObj = stateDataObj.state;
    }

    if (stateObj) {
      dataObj = {...dataObj, ...stateObj};
      dataObj = shared.timeCheckBlockchainData(dataObj);
      dataObj.blockchainSynced = true;

      if (shared.contractInterfaceETH(dataObj.dataType)) {
        errorTrace += "[2] ";

        resolutionTypeList = getResolutionListOneType(dataObj.partyAStakeAmount, dataObj.partyBStakeAmount, user1Finalized, dataObj.isPayment);
        resolutionValueList = getValueListFromResolutionList(resolutionTypeList);
        resolutionDisplayList = getResolutionDisplayList(
          resolutionValueList, 
          resolutionTypeList, 
          user1Disp,
          user2Disp
        );
      }
      else if (shared.contractInterfaceERC20(dataObj.dataType)) {
        errorTrace += "[4] ";

        if (dataObj.isSingleTokenType) {
          resolutionTypeList = getResolutionListOneType(dataObj.partyAStakeAmount, dataObj.partyBStakeAmount, user1Finalized, dataObj.isPayment);
        }
        else {
          resolutionTypeList = getResolutionListTwoTypes(dataObj.partyAStakeAmount, dataObj.partyBStakeAmount, user1Finalized, dataObj.isPayment);
        }
        resolutionValueList = getValueListFromResolutionList(resolutionTypeList);
        resolutionDisplayList = getResolutionDisplayList(
          resolutionValueList, 
          resolutionTypeList, 
          user1Disp,
          user2Disp
        );
      }
    }

    if (dataObj.blockchainSynced && dataObj.databaseSynced) {
      errorTrace += "[6] ";

      if (dataObj.isSingleTokenType) {
        dataObj.partyAResolutionDisplay = getResolutionDisplayOneToken(dataObj.partyAResolutionType, resolutionDisplayList, dataObj);
        dataObj.partyBResolutionDisplay = getResolutionDisplayOneToken(dataObj.partyBResolutionType, resolutionDisplayList, dataObj);
        dataObj.resolutionDisplay = getResolutionDisplayOneToken(dataObj.resolutionType, resolutionDisplayList, dataObj);
        dataObj.automaticResolutionDisplay = getResolutionDisplayOneToken(dataObj.automaticResolutionType, resolutionDisplayList, dataObj);
        dataObj.arbitratorResolutionDisplay = getResolutionDisplayOneToken(dataObj.arbitratorResolutionType, resolutionDisplayList, dataObj);
      }
      else {
        dataObj.partyAResolutionDisplay = getResolutionDisplayTwoTokens(dataObj.partyAResolutionType, resolutionDisplayList, dataObj);
        dataObj.partyBResolutionDisplay = getResolutionDisplayTwoTokens(dataObj.partyBResolutionType, resolutionDisplayList, dataObj);
        dataObj.resolutionDisplay = getResolutionDisplayTwoTokens(dataObj.resolutionType, resolutionDisplayList, dataObj);
        dataObj.automaticResolutionDisplay = getResolutionDisplayTwoTokens(dataObj.automaticResolutionType, resolutionDisplayList, dataObj);
        dataObj.arbitratorResolutionDisplay = getResolutionDisplayTwoTokens(dataObj.arbitratorResolutionType, resolutionDisplayList, dataObj);
      }

      resolutionDisplayList = addToResolutionDisplayList(resolutionDisplayList, dataObj.partyAResolutionType, dataObj.partyAResolutionDisplay, "partyA", user1Finalized ? "user1" : "user2");
      resolutionDisplayList = addToResolutionDisplayList(resolutionDisplayList, dataObj.partyBResolutionType, dataObj.partyBResolutionDisplay, "partyB", user1Finalized ? "user2" : "user1");
      resolutionDisplayList = addToResolutionDisplayList(resolutionDisplayList, dataObj.resolutionType, dataObj.resolutionDisplay, "final", "final");
      if (dataObj.autoResolveAfterTimestamp > 0) {
        resolutionDisplayList = addToResolutionDisplayList(resolutionDisplayList, dataObj.automaticResolutionType, dataObj.automaticResolutionDisplay, "automatic", "automatic");
      }
      resolutionDisplayList = addToResolutionDisplayList(resolutionDisplayList, dataObj.arbitratorResolutionType, dataObj.arbitratorResolutionDisplay, "arbitrator", "arbitrator");

      resolutionDisplayList.push({
        name: "custom", 
        text: "Custom"
      });

      dataObj.resolutionDisplayList = resolutionDisplayList;
      
      dataObj.partyAStakeDisplay = getTokenAmountDisplay(dataObj.partyAStakeAmount, dataObj.partyATokenType);
      dataObj.partyBStakeDisplay = getTokenAmountDisplay(dataObj.partyBStakeAmount, dataObj.partyBTokenType);
      dataObj.disputeFeeDisplay = getTokenAmountDisplay(dataObj.disputeFee, dataObj.disputeTokenType);

      if (shared.contractInterfaceETH(dataObj.dataType)) {
        dataObj.partyADisplayWithdrawAmount = getCombinedTokenAmountDisplay(dataObj.resolutionPayA, "eth", dataObj.partyAFeeWithdrawAmount, "eth", "0", "eth");
        dataObj.partyBDisplayWithdrawAmount = getCombinedTokenAmountDisplay(dataObj.resolutionPayB, "eth", dataObj.partyBFeeWithdrawAmount, "eth", "0", "eth");
      }
      else {
        dataObj.partyADisplayWithdrawAmount = getCombinedTokenAmountDisplay(dataObj.resolutionTokenAPayA, dataObj.partyATokenType, dataObj.resolutionTokenBPayA, dataObj.partyBTokenType, dataObj.partyAFeeWithdrawAmount, dataObj.disputeTokenType);
        dataObj.partyBDisplayWithdrawAmount = getCombinedTokenAmountDisplay(dataObj.resolutionTokenAPayB, dataObj.partyATokenType, dataObj.resolutionTokenBPayB, dataObj.partyBTokenType, dataObj.partyBFeeWithdrawAmount, dataObj.disputeTokenType);
      }

      // Convert from partyA/partyB to user1/user2
      dataObj = shared.convertAllPartyToUser(dataObj, user1Finalized);
      
      if (!dataObj.hasDataIntegrity) {
        errorTrace += "[hasDataIntegrity === false] ";
        errorTrace += "[" + dataObj.dataIntegrityReason + "]";
      }
      else {
        dataObj.blockchainInit = true;
        if (!dataObj.isResolved) {
          dataObj.canSubmitEvidence = true;
        }
        errorTrace += "[8] ";

        if (lowerCase(ethAddress) === lowerCase(dataObj.user1Address) && username === agreedDataObj.user1) {
          if (user1Finalized) {
            dataObj.youArePartyA = true;
          }
          else {
            dataObj.youArePartyB = true;
          }
        }
        else if (lowerCase(ethAddress) === lowerCase(dataObj.user2Address) && username === agreedDataObj.user2) {
          if (user1Finalized) {
            dataObj.youArePartyB = true;
          }
          else {
            dataObj.youArePartyA = true;
          }
        }
      }
    }
    else {
      errorTrace += "[B] ";
    }
  }

  dataObj.errorTrace = errorTrace;

  return dataObj;
}

export function getAccountLink(user1) {
  let auth = store.getState().auth;
  let username = auth.username;
  if (user1 === username) {
    return "/profile";
  }
  else {
    return "/profile?user=" + user1;
  }
}

export function formatPhoneNumber(num) {
  let clean = cleanPhoneNumber(num);
  if (clean.length === 11) {
    let match = clean.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
    }
  }
  return "";
}

export function getDropdownVisibilityList() {
  let dropdownVisibilityList = [];
  dropdownVisibilityList.push({
    name: "public", 
    text: shared.visibilityText("public")
  });
  dropdownVisibilityList.push({
    name: "discreet", 
    text: shared.visibilityText("discreet")
  });
  dropdownVisibilityList.push({
    name: "private", 
    text: shared.visibilityText("private")
  });
  return dropdownVisibilityList;
}

export function getDefaultArbitrationBullets(agreementType, defaultArbitrationSettings, defaultArbitrationFee, defaultArbitrationFeePriceMsg, showAutoResolveDays, showDefaultArbitrator) {
  if (agreementType === "payment" || agreementType === "request") {
    return (
      <div>
        {defaultArbitrationFee !== "" ? <div className="bullet contentLabel moveTop20">&bull; Loser of dispute pays arbitrator {defaultArbitrationFee}{(defaultArbitrationFeePriceMsg === "" ? "" : " (" + defaultArbitrationFeePriceMsg + ")")}</div> : <div className="bullet contentLabel moveTop20">&bull; Arbitration fee is based on arbitrator</div>}
        <div className="bullet contentLabel moveTop5">&bull; Arbitration can be requested {defaultArbitrationSettings.arbitration_request_days > 0 ? "after " + defaultArbitrationSettings.arbitration_request_days + " days" : "at any time"}</div>
        <div className="bullet contentLabel moveTop5">&bull; Parties must respond to arbitration requests within {defaultArbitrationSettings.arbitration_response_days} days</div>
        {showAutoResolveDays && <div className="bullet contentLabel moveTop5">&bull; Automatic payment completion after {defaultArbitrationSettings.auto_resolve_days} days</div>}
        {showDefaultArbitrator && <div className="bullet contentLabel moveTop5">&bull; Arbitrated by <Link className="blacklink" href={genURL("/profile?user=atstake")}>atstake</Link></div>}
      </div>
    );
  }
  else if (agreementType === "contract") {
    return (
      <div>
        {defaultArbitrationFee !== "" ? <div className="bullet contentLabel moveTop20">&bull; Loser of dispute pays arbitrator {defaultArbitrationFee}{(defaultArbitrationFeePriceMsg === "" ? "" : " (" + defaultArbitrationFeePriceMsg + ")")}</div> : <div className="bullet contentLabel moveTop20">&bull; Arbitration fee is based on arbitrator</div>}
        <div className="bullet contentLabel moveTop5">&bull; Arbitration can be requested {defaultArbitrationSettings.arbitration_request_days > 0 ? "after " + defaultArbitrationSettings.arbitration_request_days + " days" : "at any time"}</div>
        <div className="bullet contentLabel moveTop5">&bull; Parties must respond to arbitration requests within {defaultArbitrationSettings.arbitration_response_days} days</div>
        <div className="bullet contentLabel moveTop5">&bull; Contract never reaches an automatic outcome{/*We assert that this is the default resolution for contract settings above*/}</div>
        {showDefaultArbitrator && <div className="bullet contentLabel moveTop5">&bull; Arbitrated by <Link className="blacklink" href={genURL("/profile?user=atstake")}>atstake</Link></div>}
      </div>
    );
  }
}

export function getArbitratorAutosuggestObj() {
  let arbitratorUsername = "atstake";
  let arbitratorKey = "atstake#" + arbitratorUsername;
  let arbitrationSettings = getTestModeFromStore() ? shared.defaultArbitratorSettingsTest() : shared.defaultArbitratorSettingsProd();

  return {
    search: "all",
    input: arbitratorUsername,
    results: [{
      type: "atstake",
      value: arbitratorUsername,
      arbitration_settings: arbitrationSettings,
      [arbitratorKey]: {
        type: "atstake",
        value: arbitratorUsername,
        highlight: arbitratorUsername,
        partialMatch: true,
        exactMatch: true
      }
    }],
    selected: arbitratorKey,
    selectedArbitration: arbitrationSettings,
    more: false,
    match: { [arbitratorKey]: true },
    init: true
  };
}

export function getSelectedArbitrator(autosuggestObj) {
  let arbitrator = "";
  if (autosuggestObj && autosuggestObj.hasOwnProperty("selected")) {
    let selectedArbitrator = autosuggestObj.selected;
    if (selectedArbitrator !== null) {
      let splitArr = selectedArbitrator.split('#');
      if (splitArr.length > 1) {
        let selectedArbitratorType = splitArr[0];
        if (selectedArbitratorType === "atstake") {
          arbitrator = splitArr[1];
        }
      }
    }
  }
  return arbitrator;
}

export function getAutoSuggestObjectFromUsername(versionArbitratorUsername, arbitratorLatestSettings) {
  if (versionArbitratorUsername) {
    let versionArbitratorKey = "atstake#" + versionArbitratorUsername;
    return {
      search: "all",
      input: versionArbitratorUsername,
      results: [{
        type: "atstake",
        value: versionArbitratorUsername,
        arbitration_settings: arbitratorLatestSettings,
        [versionArbitratorKey]: {
          type: "atstake",
          value: versionArbitratorUsername,
          highlight: versionArbitratorUsername,
          partialMatch: true,
          exactMatch: true
        }
      }],
      selected: versionArbitratorKey,
      selectedArbitration: arbitratorLatestSettings,
      more: false,
      match: { [versionArbitratorKey]: true },
      init: true
    };
  }

  return {};
}