import React, { Component } from 'react';
import Link from './Link';
import './Breadcrumb.css';
import './Common.css';

class Breadcrumb extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
    }

    render() {
      let list = this.props.list || [];

      list.unshift(
        { display: "Postings", field: "postings", level: "top" }
      );

      //field: breadLocation, level: 
      let noTarget = this.props.noTarget || false;
      let breadLocation = "";
      let breadType = "";
      let breadCategory = "";
      let delim = "?";
      let breadElemList = [];
      for (let i = 0; i < list.length; i++) {
        let entry = list[i];

        if (entry.level === "location") {
          breadLocation = delim + "location=" + entry.field;
          delim = "&";
        }
        else if (entry.level === "type") {
          breadType = delim + "type=" + entry.field;
          delim = "&";
        }
        else if (entry.level === "category") {
          breadCategory = delim + "category=" + entry.field;
          delim = "&";
        }

        if (entry.display !== "") {
          if (i !== 0) {
            breadElemList.push(<span key={"sep_" + i} className="breadSep">&nbsp;&gt;&nbsp;</span>);
          }
          breadElemList.push(
            <Link noTarget={noTarget} key={"link_" + i} className="breadLink" target="_blank" rel="noopener noreferrer" href={"/postings" + breadLocation + breadType + breadCategory}>{entry.display}</Link>
          );
        }
      }

      return (
        <div>{breadElemList}</div>
      );
    }
  }
  
  export default Breadcrumb;
