import moment from 'moment-timezone';

const emptyState = {
  selectedPage: "home",
  menuListOpen: false,
  fullPath: "/",
  queryParams: {},
  hash: "",
  lastNavUnix: 0,
  lastAgreementId: "",
  selectedNavMenu: ""
}

const MenuReducer = (state = emptyState, action) => {

  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={}, hash="" } = action.payload;

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "") {
        newSelectedPage = "home";
      }

      let lastAgreementId = state.lastAgreementId;
      if (state.selectedPage !== "view") {
        lastAgreementId = "";
      }

      return {
        ...state, 
        hash,
        selectedPage: newSelectedPage,
        fullPath: pathname,
        queryParams: JSON.parse(JSON.stringify(query)),
        lastNavUnix: moment.tz('America/Los_Angeles').unix(),
        lastAgreementId
      };
    }
    case "MENU_NAV_DROPDOWN" : {
      let selectedNavMenu = action.payload;
      return {...state, selectedNavMenu};
    }
    case "AGREEMENT_SELECTED" : {
      let { agreementid="" } = action.payload;
      return {...state, lastAgreementId: agreementid};
    }
    case "OPEN_MENU_LIST": {
      return {...state, menuListOpen: true};
    }
    case "CLOSE_MENU_LIST": {
      return {...state, menuListOpen: false};
    }
    case "UPDATE_SCREEN_DIMENSIONS": {
      // Mark menu as closed if device is desktop
      let { mobile } = action.payload;
      let newMenuListOpen = state.menuListOpen;
      if (!mobile) {
        newMenuListOpen = false;
      }
      return {...state, menuListOpen: newMenuListOpen};
    }    
    default: {
      return state
    }
  }
}

export default MenuReducer