import React, { Component } from 'react';
import './AgreementCard.css';
import './Common.css';
import { 
  hideAgreementById, 
  unhideAgreementById
} from './AgreementActions';
import { getIsMobile, getContentWidth, dateUnixToShortString } from './ActionUtils';
import restoreImg from '../restore_grey.png';
import hideImg from '../close_grey.png';

class AgreementCard extends Component {
  constructor(props) {
    super(props);
    this.hideAgreement = this.hideAgreement.bind(this);
    this.unhideAgreement = this.unhideAgreement.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.nodeList = [];
    this.state = {};
  }

  hideAgreement(data) {
    let {version=null, agreementDataObj} = data;
    let hiddenPending = agreementDataObj.hiddenPending || false;

    if (version && !hiddenPending) {
      let agreementid = version.agreementid;
      this.props.dispatch(hideAgreementById(agreementid));
    }
  }

  unhideAgreement(data) {
    let {version=null, agreementDataObj} = data;
    let hiddenPending = agreementDataObj.hiddenPending || false;

    if (version && !hiddenPending) {
      let agreementid = version.agreementid;
      this.props.dispatch(unhideAgreementById(agreementid));
    }
  }

  handleClick(event, data) {
    if (this.nodeList.includes(event.target)) {
      return;
    }

    let {version=null} = data;
    if (version) {
      let agreementid = version.agreementid;
      this.props.onClick(agreementid, "");
    }
  }

  render() {

    let dataList = this.props.data;
    let lastAgreementId = this.props.lastAgreementId;
    let agreementElemList = [];

    // Get content width and subtract 42px for padding and border
    let contentWidth = getContentWidth();
    let tableWidth = contentWidth - 42;
    let isMobile = getIsMobile();
    let useTableCard = !isMobile || tableWidth > 600;
    this.nodeList = [];

    for (let i = 0; i < dataList.length; i++) {
      let data = dataList[i];
      let { agreementDataObj, messageDataObj } = data;
      let dataObj = agreementDataObj;
      let type = dataObj.agreementType || "";
      let agreementid = dataObj.agreementid || "";
      let amountList = dataObj.displayAmount || [];
      let title = dataObj.title;
      let date = dataObj.createdTimestamp || 0;
      let hiddenForYou = dataObj.hiddenForYou || false;
      let youHaveAccess = dataObj.youAreUser1 || dataObj.youAreUser2 || dataObj.youAreArbitrator;
      let isLastAgreement = (agreementid === lastAgreementId && agreementid !== "");

      let { 
        statusNotice="",
        actionRequired=""
      } = messageDataObj;

      let summary = "";

      let user1Elem = <span style={{fontWeight:"bold", wordWrap: "break-word"}}>{dataObj.user1Locked}</span>;
      let user2Elem = <span style={{fontWeight:"bold", wordWrap: "break-word"}}>{dataObj.user2DisplayValue}</span>;

      let amountElem = [];
      for (let j = 0; j < amountList.length; j++) {
        if (j !== 0) {
          amountElem.push(<span key={j + "_and"}> and </span>);
        }
        amountElem.push(<span key={j + "_val"}>{amountList[j]}</span>);
      }

      if (dataObj.youAreUser1) {
        if (type === "payment") {
          summary = <span>{"Pay "}{amountElem}{" to "}{user2Elem}</span>;
        }
        else if (type === "request") {
          summary = <span>{"Request "}{amountElem}{" from "}{user2Elem}</span>;
        }
        else if (type === "contract") {
          summary = <span>{"Contract with "}{user2Elem}{" for "}{amountElem}</span>;
        }
      }
      else if (dataObj.youAreUser2) {
        if (type === "request") {
          summary = <span>{"Pay "}{amountElem}{" to "}{user1Elem}</span>;
        }
        else if (type === "payment") {
          summary = <span>{"Receive "}{amountElem}{" from "}{user1Elem}</span>;
        }
        else if (type === "contract") {
          summary = <span>{"Contract with "} {user1Elem} {" for "}{amountElem}</span>;
        }
      }
      else {
        if (type === "payment") {
          summary = <span>{"Payment of "}{amountElem}{" from "}{user1Elem}{" to "}{user2Elem}</span>;
        }
        else if (type === "request") {
          summary = <span>{"Request for "}{amountElem}{" from "}{user1Elem}{" to "}{user2Elem}</span>;
        }
        else if (type === "contract") {
          summary = <span>{"Contract between "}{user1Elem}{" and "}{user2Elem}{" for "}{amountElem}</span>;
        }
      }

      //let hiddenPage = this.props.hiddenPage;
      let daysAgo = dateUnixToShortString(date);

      let showEmphasis = actionRequired === "you";
      let hasStatus = statusNotice !== "" && statusNotice !== "...";

      let titleElem = <span><span style={{fontWeight:"bold"}}>{title}</span>&nbsp;&middot;&nbsp;<span className="agCardGrayText" title="created">{daysAgo}</span></span>;
      let noticeElem = <span className={showEmphasis ? "agCardEmphasisText" : "agCardGrayText"}>{statusNotice}</span>;
      let hiddenRowElem = (
        <span>
          <span style={{fontWeight:"bold"}}>{title}</span>&nbsp;&middot;&nbsp;<span className="agCardGrayText" title="created">{daysAgo}</span>&nbsp;&middot;&nbsp;
          <span>{summary}</span>{hasStatus && <span><span>&nbsp;&middot;&nbsp;</span><span className={showEmphasis ? "agCardEmphasisText" : "agCardGrayText"}>{statusNotice}</span></span>}
        </span>
      );
      let toggleElem = <div>{youHaveAccess && <img ref={node => this.nodeList.push(node)} onClick={hiddenForYou ? () => this.unhideAgreement(data) : () => this.hideAgreement(data)} className={hiddenForYou ? "cardShowIcon" : "cardHideIcon"} src={hiddenForYou ? restoreImg : hideImg} alt={hiddenForYou ? "Agreement is hidden" : "Agreement is visible"} title={hiddenForYou ? "unhide" : "hide"} />}</div>;

      if (useTableCard) {
        if (hiddenForYou) {
          agreementElemList.push(
            <tr id={isLastAgreement ? "last_agreement" : ""} className={(isLastAgreement ? "agreementTableCardLast" : "")} key={i} onClick={(event) => this.handleClick(event, data)}>
              <td className="tableCardHiddenRow">{titleElem}</td>
              <td className="tableCardHiddenRow">{summary}</td>
              <td className="tableCardHiddenRow"><div style={{position:"relative"}}>{noticeElem}<div style={{position:"absolute", top:"-5px", right:"-20px"}}>{toggleElem}</div></div></td>
            </tr>
          );
        }
        else {
          agreementElemList.push(
            <tr id={isLastAgreement ? "last_agreement" : ""} className={(isLastAgreement ? "agreementTableCardLast" : "")} key={i} onClick={(event) => this.handleClick(event, data)}>
              <td className="tableCardLeft">{titleElem}</td>
              <td className="tableCardMiddle">{summary}</td>
              <td className="tableCardRight"><div style={{position:"relative"}}>{noticeElem}<div style={{position:"absolute", top:"-10px", right:"-30px"}}>{toggleElem}</div></div></td>
            </tr>
          );
        }
      }
      else {
        agreementElemList.push(
          <div id={isLastAgreement ? "last_agreement" : ""} className={"agreementCard" + (isLastAgreement ? " agreementCardLast" : "")} key={i} onClick={(event) => this.handleClick(event, data)}>
            <div>{youHaveAccess && <img ref={node => this.nodeList.push(node)} onClick={hiddenForYou ? () => this.unhideAgreement(data) : () => this.hideAgreement(data)} className={hiddenForYou ? "cardShowIcon" : "cardHideIcon"} src={hiddenForYou ? restoreImg : hideImg} alt={hiddenForYou ? "Agreement is hidden" : "Agreement is visible"} title={hiddenForYou ? "unhide" : "hide"} />}</div>
            {hiddenForYou && <div className="cardHiddenRow">{hiddenRowElem}</div>}
            {!hiddenForYou && (
            <div>
              <div className="topCardRow">{titleElem}</div>
              <div className={hasStatus ? "middleCardRow" : "bottomCardRow"}>{summary}</div>
              {hasStatus && <div className="bottomCardRow">{noticeElem}</div>}
            </div>
            )}
          </div>
        );
      }
    }

    if (useTableCard) {
      let columnWidth = Math.min(Math.floor(tableWidth/(3)), 1000);      
      let widthColumn = [columnWidth, columnWidth];
      let widthElem = (
        <tr>
          <td style={{width:(widthColumn[0] + "px")}}></td>
          <td style={{width:(widthColumn[1] + "px")}}></td>
          <td></td>
        </tr>
      );
      return <table className="agreementCardCnt"><tbody>{widthElem}{agreementElemList}</tbody></table>;
    }
    else {
      return <div className="agreementCardCnt">{agreementElemList}</div>;
    }
  }
}
  
export default AgreementCard;