import React, { Component } from 'react';
import './Link.css';
import { connect } from 'react-redux';
import { navNewURL } from './ActionUtils';
import store from '../store';

class Link extends Component {
    constructor(props) {
      super(props);

        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
        this.stopProp = this.stopProp.bind(this);
    }

    handleClick(event) {
      let noRefresh = this.props.noRefresh || false;
      let navURL = event.currentTarget.getAttribute("nav");

      if (noRefresh) {
        store.dispatch(navNewURL(navURL));
      }
      else {
        window.location.assign(navURL);  
      }

      if (this.props.hasOwnProperty("onClick")) {
        this.props.onClick();
      }
    }

    stopProp(event) {
      event.stopPropagation();
    }

    render() {
      let disabled = this.props.disabled || false;
      let classNameVal = this.props.className || "";
      let styleVal = this.props.style || {};

      if (disabled) {
        classNameVal += " disabled";
      }

      let useSpan = this.props.useSpan || false;
      let noRefresh = this.props.noRefresh || false;
      let noTarget = this.props.noTarget || false;
      let target = noTarget ? "" : "_blank";
      if (this.props.auth.walletType === "coinbase") {
        target = "";
        useSpan = true;
      }

      if (useSpan || noRefresh) {
        return (
          <span nav={this.props.href} style={styleVal} className={classNameVal} onClick={this.handleClick} onMouseEnter={this.props.onMouseEnter || null} onMouseLeave={this.props.onMouseLeave || null}>{this.props.children}</span>
        );
      }
      else {
        return (
          <a onClick={this.stopProp} href={this.props.href} style={styleVal} className={classNameVal} target={target} rel="noopener noreferrer" onMouseEnter={this.props.onMouseEnter || null} onMouseLeave={this.props.onMouseLeave || null}>{this.props.children}</a>
        );
      }
    }
  }

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const LinkContainer = connect(mapStateToProps, null)(Link);
export default LinkContainer;