import { shared } from '../Shared';
import store from '../store';

export function getDropdownTokenList() {
  let auth = store.getState().auth;
  let dropdownList = [];
  let list = shared.getSupportedTokenList(auth.testMode);
  for (let i = 0; i < list.length; i++) {
    let entry = list[i];
    dropdownList.push({
      name: entry.symbol, 
      text: entry.display  
    });
  }
  return dropdownList;
}

export function getTokenAddressList(networkId) {
  let auth = store.getState().auth;
  let addressList = [];

  if (shared.getNetworkId(auth.testMode) === networkId || networkId === "") {
    let list = shared.getSupportedTokenList(auth.testMode);
    for (let i = 0; i < list.length; i++) {
      let entry = list[i];
      if (entry.contract !== "0x0000000000000000000000000000000000000000") {
        addressList.push({
          tokenSymbol: entry.symbol.toUpperCase(), 
          tokenAddress: entry.contract
        });  
      }
    }
  }

  return addressList;
}

export function getMenuTokens() {
  return ["eth", "dai", "usdc"];
}

export function getFundingPageTokens() {
  return ["eth", "dai", "usdc", "wbtc"];
}
