import React, { Component } from 'react';
import './GapCard.css';

class GapCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let hide = this.props.hide || "";
    if (hide === "mobile") {
      return (
        <div className="gapCardHideMobile"></div>
      );
    }
    else if (hide === "desktop") {
      return (
        <div className="gapCardHideDesktop"></div>
      );
    }

    return (
      this.props.size === "small" ? <div className="smallGapCard"></div> : <div className="gapCard"></div>
    );
  }
}
  
export default GapCard;