import { connect } from 'react-redux';
import React, { Component } from 'react';
import './PleaseLogin.css';
import './Common.css';
import { openOverlay } from './OverlayActions';

class PleaseLogin extends Component {
  constructor(props) {
    super(props);
    this.showLogin = this.showLogin.bind(this);
  }

  showLogin(event) {
    this.props.dispatch(openOverlay("login", {}));
  }

  render() {

    return (
      <div>
        <div className="contentCard moveTop40" >
          <div style={{fontSize:"24px"}}>You need to be logged in to view this page!</div>
          <div className="moveTop20">Please <span onClick={this.showLogin} className="bluelink">log in</span> to continue.</div>
        </div>
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    pleaseLogin: state.pleaseLogin,
    overlay: state.overlay
  }
}

const PleaseLoginContainer = connect(mapStateToProps, null)(PleaseLogin);
export default PleaseLoginContainer;