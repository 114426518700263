import { connect } from 'react-redux';
import React, { Component } from 'react';
import './TOS.css';
import GapCard from "./GapCard";
import LineCard from "./LineCard";
import TitleCard from "./TitleCard";
import { genURL } from "./ActionUtils";
import Link from './Link';

class TOS extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {


    return (
      <div className="contentSection">
        <GapCard />
        <TitleCard title="Terms of Service" /> 
        <div className="helpCard">
          <div className="helpLightText">
          Last updated: February 5th, 2019. 
          </div>
          <div className="helpLightText moveTop20">
          Atstake is a website created and operated by Byzantine Labs LLC (“Byzantine Labs”), 
          a company formed in the State of Washington in the United States of America.
          </div>

          <div className="helpLightText moveTop20">
          Atstake is a website which acts as an interface for interacting with a collection of Ethereum contracts 
          (“the AgreementManager Contracts”) for the purpose of entering into agreements with other users of 
          the AgreementManager Contracts.</div>

          <div className="helpLightText moveTop20">
          Atstake is beta software, provided as-is, and may contain errors that can result in total loss of funds. 
          Atstake is not liable for any damage of any kind, including loss of property, resulting from your use of Atstake 
          or inability to use Atstake.</div>

          <div className="helpLightText moveTop20">
          When using Atstake you must comply with all applicable laws.</div>

          <div className="helpLightText moveTop20">
          Before using Atstake, you must read and agree to the full terms of service below.</div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Eligibility</div>
          <div className="helpLightText moveTop5">
          By using Atstake, you acknowledge and accept these terms and agree to be bound by them. 
          You must be able to form a legally binding contract either on behalf of an organization or as an individual. 
          You must be at least 18 years old. If you’re agreeing to these terms on behalf of an organization, 
          you must have the authority to do so. You must not live in any jurisdiction where your use of 
          Atstake would be illegal or otherwise violate any applicable law.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Control of Funds</div>
          <div className="helpLightText moveTop5">
          Neither Byzantine Labs nor Atstake ever controls your funds, and can’t help you if you lose access to your private keys. 
          Your ability to log in to 
          your Atstake account is determined by your control of your private keys. If you lose access to these keys you’ll lose 
          access to your account. In this situation there is nothing that Byzantine Labs can do to restore your account access
          or recover any lost funds.
          </div>
          <div className="helpLightText moveTop20">
          Byzantine Labs is able to manage and make changes to the Atstake website, but
          Byzantine Labs is not able to influence the operation of the AgreementManager Contracts.
          The AgreementManager Contracts exists on the public Ethereum blockchain and Byzantine Labs has no more power
          to influence them than any other Ethereum user.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Parties to Agreements</div>
          <div className="helpLightText moveTop5">
          Atstake facilitates your interactions with the AgreementManager Contracts. 
          Agreements created using Atstake consist of you, your counterparty, and the arbitrator all entering into an 
          agreement with each other. Byzantine Labs is not a party to any agreement made using Atstake.
          </div>

          <div className="helpLightText moveTop20">
          Atstake is not liable for the actions of any of the parties involved in an agreement. 
          It’s your responsibility to evaluate and mitigate risks stemming from the behavior of others 
          who you make agreements with.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Only Legal Agreements</div>
          <div className="helpLightText moveTop5">
          You must comply with any and all applicable laws, and enter only into agreements that are legal for all 
          participants, including the arbitrator.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Liability</div>
          <div className="helpLightText moveTop5">
          Under no circumstance shall Byzantine Labs be held liable for any damages of any type to you or any other party 
          resulting from your use of or inability to use Atstake or the AgreementManager Contracts. 
          Such damages may include total loss of funds and reputational damage. Byzantine Labs is not liable for any actions or inactions 
          of any party to any agreements made through Atstake.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Warranty</div>
          <div style={{fontWeight:"bold"}} className="helpLightText moveTop5">
          Atstake is presented as-is, as-available, and is to be used at your own risk. 
          You acknowledge that Atstake is beta software and as such has not been fully tested and may not perform as intended.
          </div>
          <div style={{fontWeight:"bold"}} className="helpLightText moveTop20">
          Atstake may contain errors resulting in damages including total loss of funds and reputational damage. 
          Atstake may contain errors which are never fixed.
          </div>
          <div style={{fontWeight:"bold"}} className="helpLightText moveTop20">
          In addition to potential errors in Atstake, the AgreementManager Contracts may have errors or not work 
          as intended, potentially resulting in damages including total loss of funds and reputational damage.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Indemnity</div>
          <div className="helpLightText moveTop5">
          You will indemnify and hold harmless Byzantine Labs and its officers, directors, members, employees, and agents,
          from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including,
          without limitation, reasonable legal and accounting fees arising out of or in any way connected with (i) your
          access to or use of Atstake (ii) any content that you upload to Atstake, or your violation of these terms.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Availability</div>
          <div className="helpLightText moveTop5">
          Atstake may become unavailable at any time, for any length of time. Byzantine Labs may intentionally stop operating Atstake 
          entirely at any time. You are responsible for knowing how to continue interacting with any 
          AgreementManager Contracts that may be holding any of your funds if Atstake becomes unavailable. 
          Information about using the AgreementManager contracts when Atstake is unavailable can be found in 
          our <Link href={genURL("/faq")} className="bluelink" noTarget={true} rel="noopener noreferrer">FAQ</Link>.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Privacy</div>
          <div className="helpLightText moveTop5">
          Byzantine Labs may retain any information that you provide, including IP addresses, web browser information, 
          public Ethereum addresses, your history of actions on Atstake, and any other information 
          associated with your account that you provide. Byzantine Labs may use this information for the purpose 
          of operating and improving Atstake and assisting Atstake users in making agreements.
          </div>
          <div className="helpLightText moveTop20">
          Byzantine Labs will not share any of the information that you provide via Atstake to any third party 
          except when required by law to do so.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Support</div>
          <div className="helpLightText moveTop5">
          Byzantine Labs makes no guarantee of support or assistance in using Atstake. 
          Byzantine Labs may offer assistance to users who email support@atstake.net, depending on resources available.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Terminating Accounts</div>
          <div className="helpLightText moveTop5">
          Byzantine Labs may terminate the Atstake account of any user for any reason.
          This will not prevent such a user from continuuing to interact with the AgreementManager Contracts, as 
          described in our <Link href={genURL("/faq")} className="bluelink" noTarget={true} rel="noopener noreferrer">FAQ</Link>.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Severability</div>
          <div className="helpLightText moveTop5">
          If any terms in this document are unenforceable, its other terms will remain in effect.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Jurisdiction</div>
          <div className="helpLightText moveTop5">
          These terms shall be governed by laws of the State of Washington in the United States of America.
          Any dispute arising from this agreement or from the use of Atstake shall be solely brought before the 
          courts of the State of Washington.
          You agree to not initiate any class action lawsuit against Byzantine Labs or any user of Atstake.
          </div>
        </div>
        <div className="helpCard">
          <div className="helpCardHeading">Information on our Website</div>
          <div className="helpLightText moveTop5">
          The information contained on our website is for general information purposes only. 
          The information is provided by Atstake and while we endeavor to keep the information up to date and correct, 
          we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability 
          of this information for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
          </div>
          <div className="helpLightText moveTop20">
          Byzantine Labs may remove content posted to Atstake by any user if such content is judged to pose a legal risk to Byzantine Labs or judged to 
          be harmful to any other Atstake user or third party.
          </div>
          <div className="helpLightText moveTop20">
          Icons used by Atstake are credited on our <Link href={genURL("/attributions")} className="bluelink" noTarget={true} rel="noopener noreferrer">attributions page</Link>.
          </div>
        </div>
        <LineCard />
      </div>
    );
  }
}

/*
 * Export connected component.
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    tos: state.tos,
    overlay: state.overlay
  }
}

const TOSContainer = connect(mapStateToProps, null)(TOS);
export default TOSContainer;