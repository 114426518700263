//import update from 'immutability-helper';

const emptyState = {
  type: "", 
  privacy: "",
  value: "",
  makeDefault: false,
  step: "",
  stepInfo: {},
  confirmationCodeInput: "",
  postUrlInput: "",
  newAttestationType: "email",
  newAttestationInput: "",
  newAttestationError: false,
  newAttestationMakeDefault: "no",
  newAttestationPrivacy: "private",
  maxVisibilityButtonWidth: 0,
  maxVerificationButtonWidth: 0
}

const DialogAddAttestationReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGADDATTESTATION_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      if (name === "newAttestationType") {
        let newPrivacy = (value === "email" || value === "phone" ? "private" : "public");
        return {...state, [name]: value, newAttestationPrivacy: newPrivacy, newAttestationError: false};
      }
      if (name !== "newAttestationError") {
        return {...state, [name]: value, newAttestationError: false};
      }

      return {...state, [name]: value};
    }
    case "DIALOGADDATTESTATION_CHANGE_STEP" : {
      let { step, stepInfo } = action.payload;
      return {...state, step: step, stepInfo: stepInfo};
    }
    case "OPEN_OVERLAY": {
      let { dialog, params } = action.payload;
      let { type="", privacy="", value="", makeDefault=false, step="", stepInfo={} } = params;
      
      if (dialog === "add_attestation") {
        return {...state, ...emptyState, maxVisibilityButtonWidth: state.maxVisibilityButtonWidth, maxVerificationButtonWidth: state.maxVerificationButtonWidth, type, privacy, value, makeDefault, step, stepInfo, confirmationCodeInput: "", postUrlInput: "" };
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogAddAttestationReducer