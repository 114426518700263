import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogDebug.css';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonAction from './ButtonAction';
import { closeOverlay, tallOverlay } from './OverlayActions'
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';

class DialogDebug extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    }
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }
    
  render() {
    let {
      status="",
      device="",
      columns="",
      pathname="",
      ethAddress="",
      ethNetwork="",
      ethNetworkName="",
      ethBalance="",
      weiBalance="",
      username="",
      hasAccount=false,
      hasUsernameMatch=false,
      isDisabled=false,
      hasConnection=false,
      initCheckAddress=false,
      metamaskOpened=false,
      testMode=false,
      pageguid="",
      walletType=""
    } = this.props.auth;

    let {
      errorList=[]
    } = this.props.error;

    let message = JSON.stringify({
      status,
      device,
      columns,
      pathname,
      ethAddress,
      ethNetwork,
      ethNetworkName,
      ethBalance,
      weiBalance,
      username,
      hasAccount,
      hasUsernameMatch,
      isDisabled,
      hasConnection,
      initCheckAddress,
      metamaskOpened,
      testMode,
      pageguid,
      walletType,
      errorList
    });

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title="Debug information" />
          {message !== null && (<div className="padBottom20" style={{wordWrap: "break-word"}}>{message}</div>)}
          <ButtonAction name="ok" minWidth={DEFAULT_BUTTON_WIDTH} className="" text="OK" onClick={this.handleOk}/>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    error: state.error
  }
}

const DialogDebugContainer = connect(mapStateToProps, null)(DialogDebug);
export default DialogDebugContainer;