//import update from 'immutability-helper';

const emptyState = {
  code:"", 
  expiration_unix:0
}

const DialogAccessCodeReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "DIALOGACCESSCODE_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "DIALOGACCESSCODE_ACCESS_CODE" : {
      let { code="", expiration_unix=0 } = action.payload;
      return {...state, code, expiration_unix};
    }
    case "OPEN_OVERLAY": {
      let { dialog } = action.payload;
      if (dialog === "access_code") {        
        return {...state, ...emptyState};
      }

      // Empty everything if not selected
      return {};
    }
    case "CLOSE_OVERLAY": {
      // Empty everything if not selected
      return {};
    }
    default: {
      return state
    }
  }
}

export default DialogAccessCodeReducer