import React, { Component } from 'react';
import './Common.css';
import './InputSelectedUser.css';
import emailImg from '../email.png';
import twitterImg from '../twitter.png';
import facebookImg from '../facebook.png';
import phoneImg from '../phone.png';
import ethereumImg from '../ethereum.png';
import redditImg from '../reddit.png';
import atstakeImg from '../atstake.png';
import searchImg from '../search.png';
import checkImg from '../check.png';
import { getUserLinkElement } from './ActionUtils';

class InputSelectedUser extends Component {
    constructor(props) {
      super(props);
      this.getLogoByType = this.getLogoByType.bind(this);
      this.splitAttestationId = this.splitAttestationId.bind(this);
    }

    getLogoByType(type) {

      if (type === "atstake") {
        return <img className="selectedUserImg" src={atstakeImg} title="Atstake account" alt="Atstake account" />;
      }
      else if (type === "email") {
        return <img className="selectedUserImg" src={emailImg} title="Email address" alt="Email address" />;
      }
      else if (type === "twitter") {
        return <img className="selectedUserImg" src={twitterImg} title="Twitter username" alt="Twitter username" />;
      }
      else if (type === "facebook") {
        return <img className="selectedUserImg" src={facebookImg} title="Facebook username" alt="Facebook username" />;
      }
      else if (type === "phone") {
        return <img className="selectedUserImg" src={phoneImg} title="Phone number" alt="Phone number" />;
      }
      else if (type === "ethereum") {
        return <img className="selectedUserImg" src={ethereumImg} title="Ethereum account" alt="Ethereum account" />;
      }
      else if (type === "reddit") {
        return <img className="selectedUserImg" src={redditImg} title="Reddit username" alt="Reddit username" />;
      }
      else if (type === "search") {
        return <img className="selectedUserImg" src={searchImg} title="Search all" alt="Search all" />;
      }
      else if (type === "check") {
        return <img className="selectedUserImg" src={checkImg} title="Check" alt="Check" />;
      }
    }

    splitAttestationId(attestationid) {
      let splitArr = attestationid.split('#');
      let attestation_type = "";
      let attestation_value = "";
      if (splitArr.length > 1) {
        attestation_type = splitArr[0];
        attestation_value = splitArr[1];
      }
    
      return { attestation_type, attestation_value };
    }

    render() {
        let selectedUserLocked = this.props.selectedUserLocked || "";
        let selectedUserLinkId = this.props.selectedUserLinkId || "";
        let selectedType = "";
        let selectedValue = "";
        if (selectedUserLocked !== "") {
          selectedType = "atstake";
          selectedValue = getUserLinkElement(selectedUserLocked, selectedUserLocked, "normalWeight");
        }
        else if (selectedUserLinkId !== "") {
          let { attestation_type="", attestation_value="" } = this.splitAttestationId(selectedUserLinkId);
          selectedType = attestation_type;
          selectedValue = attestation_value;
        }
        
        return (
          <div className={"selectedUserDisabled " + this.props.className} style={{position: "relative", display:"inline-block", width:"100%"}} >
            <div><div disabled={true} autoComplete="off" placeholder="" type="text" className={"textInput selectedUserInput transparentBg"} >{selectedValue}</div></div>
            <div className="selectedUserLeftSideExplainer"><span style={{color:"#fff", visibility:"hidden"}}>&nbsp;</span><span className="selectedUserSearchIcon">{this.getLogoByType(selectedType)}</span></div>
            <div className="selectedUserRightSideExplainer"><span className="selectedUserSearchIcon">{this.getLogoByType("check")}</span></div>
          </div>
        );
    }
  }
  
  export default InputSelectedUser;
