import React, { Component } from 'react';
import './ButtonAction.css';
import './Common.css';
const SHOW_MESSAGE_SECONDS = 15;

class ButtonAction extends Component {
    constructor(props) {
      super(props);
      this.state = { showMessage: false };

      // This binding is necessary to make `this` work in the callback
      this.handleClick = this.handleClick.bind(this);
      this.updateShowMessage = this.updateShowMessage.bind(this);
      this.getMessage = this.getMessage.bind(this);
      this.timeoutNum = null;
    }

    componentDidMount() {
      if (this.props.enableMessageLine) {
        if (this.getMessage(this.props).text !== "") {
          this.updateShowMessage(true);
          clearTimeout(this.timeoutNum);
          this.timeoutNum = window.setTimeout(() => {
            this.updateShowMessage(false);
          }, SHOW_MESSAGE_SECONDS * 1000);
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.enableMessageLine) {
        if (this.getMessage(prevProps).text !== this.getMessage(this.props).text || this.getMessage(prevProps).error !== this.getMessage(this.props).error) {
          if (this.getMessage(this.props).text !== "") {
            this.updateShowMessage(true);
            clearTimeout(this.timeoutNum);
            this.timeoutNum = window.setTimeout(() => {
              this.updateShowMessage(false);
            }, SHOW_MESSAGE_SECONDS * 1000);  
          }
        }
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutNum);
    }  

    updateShowMessage(showMessage) {
      this.setState({showMessage});
    }

    getMessage(props) {
      let {
        message={}
      } = props;

      let {
        text="",
        error=true
      } = message;

      return { text, error };
    }

    handleClick(event) {
      if (this.props.hasOwnProperty("onClick") && this.props.onClick !== null) {
        this.props.onClick(event.currentTarget.getAttribute("name"), event.currentTarget.getAttribute("value"));
      }
    }

    render() {
      let disabled = this.props.disabled || false;
      let showAsLink = this.props.link ? this.props.link : false;
      let classNameVal = showAsLink ? "bluelink" : "btnAction";

      if (this.props.hasOwnProperty("color")) {
        if (this.props.color === "black") {
          classNameVal = "btnAction btnActionBlack";
        }
        else if (this.props.color === "gray") {
          classNameVal = "btnAction btnActionGray";
        }
        else if (this.props.color === "green") {
          classNameVal = "btnAction btnActionGreen";
        }
        else if (this.props.color === "disabled") {
          classNameVal = "btnAction btnActionDisabled";
        }
        else if (this.props.color === "light") {
          classNameVal = "btnAction btnActionLight";
        }
      }

      if (this.props.hasOwnProperty("className")) {
        classNameVal += " " + this.props.className;
      }

      if (disabled) {
        classNameVal += " disabled";
      }

      let minWidth = this.props.minWidth || "";

      let buttonElem = <button name={this.props.name} style={{minWidth}} value={this.props.value} className={classNameVal} onClick={this.handleClick}>{this.props.text}</button>;

      if (this.props.enableMessageLine) {
        let showMessage = this.state.showMessage;
        return <div>{buttonElem}{<div className={(this.getMessage(this.props).error ? "inputError" : "inputExplainer") + " moveTop5"}>{showMessage ? this.getMessage(this.props).text : <span>&nbsp;</span>}</div>}</div>;
      }
      else {
        return buttonElem;
      }
    }
  }
  
  export default ButtonAction;
