import { apiRateUser } from "./ActionUtils"
import store from '../store';
import { track } from './ActionUtils';

export function changeInputValue(name, value) {
  return function(dispatch) {
    dispatch({
      type: 'DIALOGREVIEW_CHANGE_INPUT_VALUE',
      payload: {name, value}
    });
  }
}

export function submitUserReview(agreementid, user, rating, ratingText) {
  return function(dispatch) {
    let auth = store.getState().auth;
    let agreement = store.getState().agreement;

    if (agreement.agreementid === agreementid) {
      let ratingStr = "neutral";
      if (rating === 1) { ratingStr = "good" };
      if (rating === -1) { ratingStr = "bad" };
  
      dispatch(track("action", "button", JSON.stringify({type:"rate_user", agreementid, user, rating, ratingText})));
      dispatch({
        type: 'REVIEW_UPDATE',
        payload: {agreementid, user, rating, ratingText, pending: true, complete: false }
      });
      apiRateUser(auth.uuid, auth.username, agreementid, user, ratingStr, ratingText).then((success) => {
        if (success) {
          dispatch({
            type: 'REVIEW_UPDATE',
            payload: {agreementid, user, rating, ratingText, pending: false, complete: true }
          });
        }
        else {
          dispatch({
            type: 'REVIEW_UPDATE',
            payload: {agreementid, user, rating, ratingText, pending: false, complete: false }
          });
        }
      });
    }
  }
}
