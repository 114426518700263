import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogBlockchainResolve.css';
import './ButtonSelect.css';
import { changeInputValue } from './DialogBlockchainResolveActions';
import DialogTitle from './DialogTitle';
import GapCard from './GapCard';
import ButtonSelectSubtext from './ButtonSelectSubtext';
import ButtonAction from './ButtonAction';
import InputBasic from './InputBasic';
import { tallOverlay } from './OverlayActions'
import { 
  summaryFromAgreementBlockchain,
  getTextWidth 
} from "./ActionUtils";
import { shared } from '../Shared';
import { 
  resolveAsParty,
  resolveAsArbitrator
} from './BlockchainActions';
import { track } from './ActionUtils';
import { DEFAULT_BUTTON_WIDTH, BUTTON_TOGGLE_EXTRA_PIXELS, BUTTON_TOGGLE_FONT } from './Constants.js';

class DialogBlockchainResolve extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.getResolutionInfo = this.getResolutionInfo.bind(this);
    this.submitResolution = this.submitResolution.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
    this.overlayNode = null;
  }

  componentDidMount() {
    const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
    if (height >= this.props.auth.screenDimensions.height) {
      this.props.dispatch(tallOverlay(true, height, true));
    }
    else {
      this.props.dispatch(tallOverlay(false, height, false));
    }

    // Should we save blockchainDataObj in state, so we're not getting it again on each render()?
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { 
      resolutionDisplayList=[],
    } = blockchainDataObj;

    let maxWidth = 0;
    for (let i = 0; i < resolutionDisplayList.length; i++) {
      let resolutionObj = resolutionDisplayList[i];
      let w = Math.ceil(getTextWidth(resolutionObj.text, BUTTON_TOGGLE_FONT)) + BUTTON_TOGGLE_EXTRA_PIXELS;
      if (w > maxWidth) {
        maxWidth = w;
      }
    }

    this.props.dispatch(changeInputValue("maxButtonWidth", maxWidth));
  }

  componentDidUpdate(prevProps, prevState) {
    //if (this.props.auth.screenDimensions.width !== prevProps.auth.screenDimensions.width || this.props.auth.screenDimensions.height !== prevProps.auth.screenDimensions.height) {
      const height = this.overlayNode ? this.overlayNode.clientHeight : 0;
      if (height >= this.props.auth.screenDimensions.height) {
        this.props.dispatch(tallOverlay(true, height, false));
      }
      else {
        this.props.dispatch(tallOverlay(false, height, false));
      }
    //}
  }

  getResolutionInfo(blockchainDataObj) {
    let { 
      resolutionInput="",
      customInputUser1Combined="",
      customInputUser2Combined="",
      customInputUser1Token1="",
      customInputUser1Token2="",
      customInputUser2Token1="",
      customInputUser2Token2=""
      
    } = this.props.dialogBlockchainResolve;

    let { 
      isSingleTokenType=false,
      user1TokenType="",
      user2TokenType="",
      combinedTokenType="",
      user1StakeAmount="",
      user2StakeAmount="",
      partyAStakeAmount="",
      combinedStakeAmount="",
      user1Finalized=false
    } = blockchainDataObj;

    let customInputCombined = "";
    let customInputToken1 = "";
    let customInputToken2 = "";
    if (user1Finalized) {
      customInputCombined = customInputUser1Combined;
      customInputToken1 = customInputUser1Token1;
      customInputToken2 = customInputUser1Token2;
    }
    else {
      customInputCombined = customInputUser2Combined;
      customInputToken1 = customInputUser2Token1;
      customInputToken2 = customInputUser2Token2;
    }

    let resolution = resolutionInput;
    if (resolution === "custom") {
      resolution = "";

      if (isSingleTokenType) {
        if (customInputCombined !== "") {
          let resTokenCombined = shared.multiplyDecimals(customInputCombined, shared.decimalsForToken(combinedTokenType));
          resolution = resTokenCombined;
        }
      }
      else {
        if (customInputToken1 !== "" && customInputToken2 !== "") {
          let resToken1 = shared.multiplyDecimals(customInputToken1, shared.decimalsForToken(user1TokenType));
          let resToken2 = shared.multiplyDecimals(customInputToken2, shared.decimalsForToken(user2TokenType));
          let resTokenA = user1Finalized ? resToken1 : resToken2;
          let resTokenB = user1Finalized ? resToken2 : resToken1;
          resolution = resTokenA + "_" + resTokenB;
        }
      }
    }
    
    let modifiedResolutionERC20 = "";
    if (resolution !== "" && resolution !== null) {
      if (isSingleTokenType) {
        let resTokenCombined = resolution;
        if (shared.greaterThanBN(resTokenCombined, partyAStakeAmount, "dialogResolve A")) {
          let remainderPartyBStakeAmount = shared.subBN(resTokenCombined, partyAStakeAmount, "");
          modifiedResolutionERC20 = partyAStakeAmount + "_" + remainderPartyBStakeAmount;
        }
        else {
          modifiedResolutionERC20 = resTokenCombined + "_0";
        }

        if (shared.greaterThanEqualBN(combinedStakeAmount, resTokenCombined, "dialogResolve B")) {
          let remainderTokenCombined = shared.subBN(combinedStakeAmount, resTokenCombined, "dialogResolve C");
          let dispResTokenCombined = shared.divideDecimals(resTokenCombined, shared.decimalsForToken(combinedTokenType));
          let dispRemainderTokenCombined = shared.divideDecimals(remainderTokenCombined, shared.decimalsForToken(combinedTokenType));

          return {
            infoInit: true,
            resolution,
            modifiedResolutionERC20: modifiedResolutionERC20 !== "" ? modifiedResolutionERC20 : resolution,
            resTokenCombined,
            remainderTokenCombined,
            combinedTokenType,
            dispResTokenCombined,
            dispRemainderTokenCombined
          }
        }
      }
      else {
        let resolutionSplit = resolution.split("_");
        if (resolutionSplit.length > 1) {
          let resTokenA = resolutionSplit[0];
          let resTokenB = resolutionSplit[1];
          let resToken1 = user1Finalized ? resTokenA : resTokenB;
          let resToken2 = user1Finalized ? resTokenB : resTokenA;

          if (shared.greaterThanEqualBN(user1StakeAmount, resToken1, "dialogResolve D") && shared.greaterThanEqualBN(user2StakeAmount, resToken2, "dialogResolve E")) {
            let remainderToken1 = shared.subBN(user1StakeAmount, resToken1, "dialogResolve F");
            let remainderToken2 = shared.subBN(user2StakeAmount, resToken2, "dialogResolve G");
            let dispResToken1 = shared.divideDecimals(resToken1, shared.decimalsForToken(user1TokenType));
            let dispResToken2 = shared.divideDecimals(resToken2, shared.decimalsForToken(user2TokenType));
            let dispRemainderToken1 = shared.divideDecimals(remainderToken1, shared.decimalsForToken(user1TokenType));
            let dispRemainderToken2 = shared.divideDecimals(remainderToken2, shared.decimalsForToken(user2TokenType));
    
            return {
              infoInit: true,
              resolution,
              modifiedResolutionERC20: resolution,
              resTokenA,
              resTokenB,
              resToken1,
              resToken2,
              remainderToken1,
              remainderToken2,
              user1TokenType,
              user2TokenType,
              dispResToken1,
              dispResToken2,
              dispRemainderToken1,
              dispRemainderToken2
            }
          }
        }
      }
    }
    
    return { 
      infoInit: false,
      resolution: ""
    };
  }

  submitResolution(name, value) {
    let { deployedid="", agreedData="", contractType="" } = this.props.agreement;
    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { youAreArbitrator=false } = blockchainDataObj;
    let resolutionInfo = this.getResolutionInfo(blockchainDataObj);
    let { resolution="", modifiedResolutionERC20="", infoInit=false } = resolutionInfo;

    if (infoInit && modifiedResolutionERC20 !== "") {
      this.props.dispatch({
        type: 'DIALOGBLOCKCHAINRESOLVE_SUBMIT_ERROR',
        payload: false
      });

      this.props.dispatch(track("action", "button", JSON.stringify({type:"submit_resolution", dialog:"blockchain_resolve", submit_by: (youAreArbitrator ? "arbitrator" : "party")})));

      if (youAreArbitrator) {
        this.props.dispatch(resolveAsArbitrator(blockchainDataObj, deployedid, resolution, modifiedResolutionERC20, agreedData, contractType));
      }
      else {
        this.props.dispatch(resolveAsParty(blockchainDataObj, deployedid, resolution, modifiedResolutionERC20, agreedData, contractType));
      }  
    }
    else {
      this.props.dispatch({
        type: 'DIALOGBLOCKCHAINRESOLVE_SUBMIT_ERROR',
        payload: true
      });
    }
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let dialogTitle = "Report outcome";

    let { 
      youAreUser2=false,
      user1ContributionInput="",
      user2ContributionInput="",
      user1Disp="", 
      user2Disp=""
    } = this.props.agreement;

    let blockchainDataObj = summaryFromAgreementBlockchain(this.props.agreement);
    let { 
      resolutionDisplayList=[],
      isSingleTokenType=false,
      user1TokenType="",
      user2TokenType="",
      combinedTokenType="",
      user1Finalized=false
    } = blockchainDataObj;

    let resolutionInfo = this.getResolutionInfo(blockchainDataObj);

    let contributionMessage = "";
    let andString = user1ContributionInput > 0 && user2ContributionInput > 0 ? " and " : "";
    if (youAreUser2) {
      contributionMessage = 
        (user2ContributionInput > 0 ? user2ContributionInput + " " + user2TokenType.toUpperCase() + " deposited by " + user2Disp : "") +
        andString +
        (user1ContributionInput > 0 ? user1ContributionInput + " " + user1TokenType.toUpperCase() + " deposited by " + user1Disp : "");
    }
    else {
      contributionMessage = 
        contributionMessage = 
        (user1ContributionInput > 0 ? user1ContributionInput + " " + user1TokenType.toUpperCase() + " deposited by " + user1Disp : "") +
        andString +
        (user2ContributionInput > 0 ? user2ContributionInput + " " + user2TokenType.toUpperCase() + " deposited by " + user2Disp : "");
    }

    let {
      customInputUser1Combined="",
      customInputUser2Combined="",
      customInputUser1Token1="",
      customInputUser1Token2="",
      customInputUser2Token1="",
      customInputUser2Token2="",
      resolutionInput="",
      submitError=false
    } = this.props.dialogBlockchainResolve;

    let hasValidInput = false;
    let warningRounding = false;
    if (isSingleTokenType) {
      let {
        infoInit=false,
        dispResTokenCombined="",
        dispRemainderTokenCombined=""
      } = resolutionInfo;

      if (infoInit && resolutionInput !== "custom") {
        if (user1Finalized) {
          customInputUser1Combined = dispResTokenCombined;
          customInputUser2Combined = dispRemainderTokenCombined;  
        }
        else {
          customInputUser1Combined = dispRemainderTokenCombined;
          customInputUser2Combined = dispResTokenCombined;  
        }
      }

      if (infoInit) {
        hasValidInput = true;
      }

      if (
        shared.floatWillLosePrecision(customInputUser1Combined, combinedTokenType) ||
        shared.floatWillLosePrecision(customInputUser2Combined, combinedTokenType)
      ) {
        warningRounding = true;
      }
    }
    else {
      let {
        infoInit=false,
        dispResToken1="",
        dispResToken2="",
        dispRemainderToken1="",
        dispRemainderToken2=""
      } = resolutionInfo;

      if (infoInit && resolutionInput !== "custom") {
        if (user1Finalized) {
          customInputUser1Token1 = dispResToken1;
          customInputUser1Token2 = dispResToken2;
          customInputUser2Token1 = dispRemainderToken1;
          customInputUser2Token2 = dispRemainderToken2;
        }
        else {
          customInputUser1Token1 = dispRemainderToken1;
          customInputUser1Token2 = dispRemainderToken2;
          customInputUser2Token1 = dispResToken1;
          customInputUser2Token2 = dispResToken2; 
        }
      }

      if (infoInit) {
        hasValidInput = true;
      }

      if (
        shared.floatWillLosePrecision(customInputUser1Token1, user1TokenType) ||
        shared.floatWillLosePrecision(customInputUser1Token2, user2TokenType) ||
        shared.floatWillLosePrecision(customInputUser2Token1, user1TokenType) ||
        shared.floatWillLosePrecision(customInputUser2Token2, user2TokenType)
      ) {
        warningRounding = true;
      }
    }

    let resolutionList = resolutionDisplayList;
    let selectButtonElem = [];
    for (let i = 0; i < resolutionList.length; i++) {
      let resolutionObj = resolutionList[i];

      let statusList = [];
      let statusHighlight = "";

      if (resolutionObj.hasOwnProperty("final") && resolutionObj.final === true) {
        let statusHighlight = "Final outcome";
        statusList.push(statusHighlight);
      }

      if (resolutionObj.hasOwnProperty("automatic") && resolutionObj.automatic === true) {
        statusList.push("Automatic outcome");
      }

      if (resolutionObj.hasOwnProperty("arbitrator") && resolutionObj.arbitrator === true) {
        statusList.push("Outcome reported by arbitrator");
      }

      if (resolutionObj.hasOwnProperty("user1") && resolutionObj.user1 === true) {
        statusList.push("Outcome reported by " + user1Disp);
      }

      if (resolutionObj.hasOwnProperty("user2") && resolutionObj.user2 === true) {
        statusList.push("Outcome reported by " + user2Disp);
      }

      selectButtonElem.push(
        <ButtonSelectSubtext key={i} statusList={statusList} minWidth={this.props.dialogBlockchainResolve.maxButtonWidth} statusHighlight={statusHighlight} text={resolutionObj.text} name="resolutionInput" value={resolutionObj.name} selected={this.props.dialogBlockchainResolve.resolutionInput} onChange={this.changeInputValue}/>
      );
    }

    let disableCustomInput = (this.props.dialogBlockchainResolve.resolutionInput !== "custom");

    return (
      <div ref={overlayNode => this.overlayNode = overlayNode} className="contentSection">
        <div className="contentCard">
          <DialogTitle title={dialogTitle} />
          <div>How should the {contributionMessage} be distributed, given the contract outcome?</div>
          <div style={{marginTop:"15px"}}>
            {selectButtonElem}
          </div>
          {isSingleTokenType && <div className="">
            {!youAreUser2 ? (
              <div>
                <div className="moveTop20">Amount to pay {user1Disp}</div>
                <InputBasic append={combinedTokenType.toUpperCase()}  disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + combinedTokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Combined" value={customInputUser1Combined} onChange={this.changeInputValue} />
                <div className="moveTop20">Amount to pay {user2Disp}</div>
                <InputBasic append={combinedTokenType.toUpperCase()}  disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + combinedTokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Combined" value={customInputUser2Combined} onChange={this.changeInputValue} />
              </div>
            ) : (
              <div>
                <div className="moveTop20">Amount to pay {user2Disp}</div>
                <InputBasic append={combinedTokenType.toUpperCase()}  disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + combinedTokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Combined" value={customInputUser2Combined} onChange={this.changeInputValue} />
                <div className="moveTop20">Amount to pay {user1Disp}</div>
                <InputBasic append={combinedTokenType.toUpperCase()}  disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + combinedTokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Combined" value={customInputUser1Combined} onChange={this.changeInputValue} />
              </div>
            )}
          </div>}
          {!isSingleTokenType && <div className="">
            {!youAreUser2 ? (
              <div>
                <div className="moveTop20">Amount to pay {user1Disp}</div>
                <InputBasic append={user1TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user1TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Token1" value={customInputUser1Token1} onChange={this.changeInputValue} />
                <InputBasic append={user2TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user2TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Token2" value={customInputUser1Token2} onChange={this.changeInputValue} />
                <div className="moveTop20">Amount to pay {user2Disp}</div>
                <InputBasic append={user1TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user1TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Token1" value={customInputUser2Token1} onChange={this.changeInputValue} />
                <InputBasic append={user2TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user2TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Token2" value={customInputUser2Token2} onChange={this.changeInputValue} />
              </div>
            ) : (
              <div>
                <div className="moveTop20">Amount to pay {user2Disp}</div>
                <InputBasic append={user2TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user2TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Token2" value={customInputUser2Token2} onChange={this.changeInputValue} />
                <InputBasic append={user1TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user1TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser2Token1" value={customInputUser2Token1} onChange={this.changeInputValue} />
                <div className="moveTop20">Amount to pay {user1Disp}</div>
                <InputBasic append={user2TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user2TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Token2" value={customInputUser1Token2} onChange={this.changeInputValue} />
                <InputBasic append={user1TokenType.toUpperCase()} disabled={disableCustomInput} restrict="float" placeholder={"Amount in " + user1TokenType.toUpperCase()} type="text" className="resolveInputWidth moveRight5 moveTop5" name="customInputUser1Token1" value={customInputUser1Token1} onChange={this.changeInputValue} />
              </div>
            )}
          </div>}
          <div className="moveTop20">
            <ButtonAction name="submit" disabled={!hasValidInput} className="moveRight20" text="Submit" minWidth={DEFAULT_BUTTON_WIDTH} onClick={hasValidInput ? this.submitResolution : null}/>
            {submitError && <div className="moveTop5 inputError">You entered an invalid payment amount</div>}
            {warningRounding && <div className="moveTop5 inputError">Warning: the selected outcome will result in rounding</div>}
          </div>
        </div>
        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    agreement: state.agreement,
    dialogBlockchainResolve: state.dialogBlockchainResolve
  }
}

const DialogBlockchainResolveContainer = connect(mapStateToProps, null)(DialogBlockchainResolve);
export default DialogBlockchainResolveContainer;