import React, { Component } from 'react'
import { connect } from 'react-redux';
import './DialogAddress.css';
import { changeInputValue, refreshChangeAddressList, refreshUserDefaultAddress, submitAddressForAgreement, lockAgreement, unlockAgreement } from './DialogAddressActions';
import DialogTitle from './DialogTitle'; 
import GapCard from './GapCard';
import LoadCard from './LoadCard';
import ButtonAction from './ButtonAction';
import ButtonSelectSubtext from './ButtonSelectSubtext';
import { closeOverlay } from './OverlayActions'
import { shortenAddressMedium, lowerCase } from "./ActionUtils";
import { DEFAULT_BUTTON_WIDTH } from './Constants.js';


class DialogAddress extends Component {

  constructor(props, context) {
    super(props)

    // This binding is necessary to make `this` work in the callback
    this.handleOk = this.handleOk.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectCurrent = this.selectCurrent.bind(this);
    this.selectDefault = this.selectDefault.bind(this);
    this.cannotSelectCurrent = this.cannotSelectCurrent.bind(this);
    this.changeInputValue = this.changeInputValue.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(refreshChangeAddressList());
    this.props.dispatch(refreshUserDefaultAddress());

    let {agreementid=""} = this.props.dialogAddress;
    if (agreementid !== "") {
      this.props.dispatch(lockAgreement(agreementid));
    }
  }

  componentWillUnmount() {
    let {agreementid=""} = this.props.dialogAddress;
    if (agreementid !== "") {
      this.props.dispatch(unlockAgreement(agreementid));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.dialogAddress.addressListLoaded !== prevProps.dialogAddress.addressListLoaded && 
      this.props.dialogAddress.addressListLoaded === false && 
      this.props.dialogAddress.mode === "change"
    ) {
      this.props.dispatch(refreshChangeAddressList());
    }

    if (
      this.props.dialogAddress.defaultAddressLoaded !== prevProps.dialogAddress.defaultAddressLoaded && 
      this.props.dialogAddress.defaultAddressLoaded === false && 
      this.props.dialogAddress.mode === "change"
    ) {
      this.props.dispatch(refreshUserDefaultAddress());
    }

    if (
      (this.props.dialogAddress.addressListLoaded !== prevProps.dialogAddress.addressListLoaded || 
      this.props.dialogAddress.defaultAddressLoaded !== prevProps.dialogAddress.defaultAddressLoaded) && 
      this.props.dialogAddress.addressListLoaded === true &&
      this.props.dialogAddress.defaultAddressLoaded === true && 
      this.props.dialogAddress.mode === "change" && 
      this.props.dialogAddress.changeType === "current"
    ) {
      this.selectCurrent("", "");
    }

    if (this.props.dialogAddress.mode !== "change") {
      let {agreementid=""} = this.props.dialogAddress;
      if (agreementid !== "") {
        this.props.dispatch(unlockAgreement(agreementid));
      }  
    }
  }

  handleOk(name, value) {
    this.props.dispatch(closeOverlay());
  }

  handleSubmit(name, value) {
    let addressDropdownSelected = this.props.dialogAddress.addressDropdownSelected;
    let agreementid = this.props.dialogAddress.agreementid;
    let versionid = this.props.dialogAddress.versionid;
    let status = this.props.dialogAddress.status;
    this.props.dispatch(submitAddressForAgreement(agreementid, versionid, status, addressDropdownSelected));
  }

  selectCurrent(name, value) {
    //console.log("selectCurrent");

    let { ethAddress="" } = this.props.auth;
    let addressList = this.props.dialogAddress.addressList;
    for (let i = 0; i < addressList.length; i++) {
      let entry = addressList[i];
      if (entry && entry.account_name === ethAddress) {
        this.changeInputValue("addressDropdownSelected", ethAddress);
        break;
      }
    }
  }

  selectDefault(name, value) {
    //console.log("selectDefault");

    let defaultAddress = this.props.dialogAddress.defaultAddress;
    let addressList = this.props.dialogAddress.addressList;
    for (let i = 0; i < addressList.length; i++) {
      let entry = addressList[i];
      if (entry && entry.account_name === defaultAddress) {
        this.changeInputValue("addressDropdownSelected", defaultAddress);
        break;
      }
    }
  }

  cannotSelectCurrent(name, value) {
    alert("Current Ethereum address is not registered with this Atstake account.");
  }

  changeInputValue(name, value) {
    this.props.dispatch(changeInputValue(name, value));
  }
  
  render() {
    
    let title = "";
    if (this.props.dialogAddress.title !== null) {
      title = this.props.dialogAddress.title;
    }

    let { selectedAddress="" } = this.props.dialogAddress;

    let { ethAddress="" } = this.props.auth;
    let dataLoaded = this.props.dialogAddress.addressListLoaded && this.props.dialogAddress.defaultAddressLoaded;
    let addressList = this.props.dialogAddress.addressList;

    let selectButtonElem = [];
    for (let i = 0; i < addressList.length; i++) {
      let statusList = [];
      let entry = addressList[i];
      if (entry && entry.account_type === "ethereum" && entry.account_name !== "") {
        if (lowerCase(selectedAddress) === lowerCase(entry.account_name)) {
          statusList.push("current for agreement");
        }

        let addressMatchesWeb3 = false;
        if (ethAddress === entry.account_name) {
          addressMatchesWeb3 = true;
        }

        if (addressMatchesWeb3) {
          statusList.push("current active wallet");
        }

        selectButtonElem.push(
          <ButtonSelectSubtext key={i} statusList={statusList} text={shortenAddressMedium(entry.account_name)} name="addressDropdownSelected" value={entry.account_name} selected={this.props.dialogAddress.addressDropdownSelected} onChange={this.changeInputValue}/>
        );
      }
    }

    let message = this.props.dialogAddress.message;
    if (this.props.dialogAddress.mode === "change") {
      message = "What Ethereum address would you like to use for this agreement?";
    }

    let displayAddress = "No address selected";
    if (selectedAddress !== "") {
      displayAddress = selectedAddress;
    }
 
    return (
      <div className="contentSection">
        {dataLoaded ? (
          <div className="contentCard">
            <DialogTitle title={title} />
            {message !== null && (<div className="">{message}</div>)}
            {this.props.dialogAddress.mode === "view" && 
              <div>
                <div className="moveTop5 viewAddress">{displayAddress}</div>
                <ButtonAction name="ok" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20" text="OK" onClick={this.handleOk}/>
              </div>
            }
            {this.props.dialogAddress.mode === "change" && 
              <div>
                <div className="addressDropdownCnt moveTop5">{selectButtonElem}</div>
                <div><ButtonAction name="submit" minWidth={DEFAULT_BUTTON_WIDTH} className="moveTop20" text="Submit" onClick={this.handleSubmit}/></div>
              </div>
            }
          </div>
        ) : (
          <LoadCard />
        )}

        <GapCard />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    menu: state.menu,
    dialogAddress: state.dialogAddress
  }
}

const DialogAddressContainer = connect(mapStateToProps, null)(DialogAddress);
export default DialogAddressContainer;