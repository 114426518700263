import update from 'immutability-helper';
import { shared } from '../Shared';

const emptyState = {
  onMessagesPage: false,
  messageBox: "",
  submitMessageDisabled: false,
  conversationList: [],
  conversationsLoaded: false,
  messageList: [],
  messagesLoaded: false,
  user: "",
  newMessageStatus: { text:"", error: true },
  searchAutosuggest: {
    search: "all",
    input: "",
    results: [],
    selected: null,
    selectedArbitration: null,
    more: false,
    match: {},
    init: false
  }
}

const MessagesReducer = (state = emptyState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE": {
      let { pathname="", query={} } = action.payload;

      let user = "";
      if (query && Object.prototype.hasOwnProperty.call(query, "user")) {
        user = query.user;
      }

      let newSelectedPage = pathname.replace(/^\/|\/$/g, '');
      if (newSelectedPage === "messages") {
        return {...state, user, onMessagesPage: true};
      }
      return {...state, ...emptyState, onMessagesPage: false};
    }
    case "MESSAGES_CHANGE_INPUT_VALUE" : {
      let { name, value } = action.payload;
      return {...state, [name]: value};
    }
    case "MESSAGES_CONVERSATION_LIST" : {
      let { conversations=[], loaded=false } = action.payload;
      return {...state, conversationList: conversations, conversationsLoaded: loaded };
    }
    case "MESSAGES_DISABLED_BUTTON" : {
      let submitMessageDisabled = action.payload;
      return {...state, submitMessageDisabled };
    }
    case "MESSAGES_LIST" : {
      let { messages=[], user="", loaded=false } = action.payload;
      if (user === state.user) {
        return {...state, messageList: messages, messagesLoaded: loaded };
      }

      return state;
    }
    case "MESSAGES_CHANGE_AUTOSUGGEST_INPUT" : {
      let { name, value, search } = action.payload;

      if (value.length > shared.MAX_IDENTIFIER_LENGTH) {
        value = value.substring(0, shared.MAX_IDENTIFIER_LENGTH);
      }

      let newSelected = state[name].selected;
      if (state[name].input !== value) {
        newSelected = null;
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {input: value, selected: newSelected, search: search}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "MESSAGES_CHANGE_AUTOSUGGEST_RESULTS" : {
      let { name, results, more, match, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: results, more: more, match: match, init: true}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "MESSAGES_CLEAR_AUTOSUGGEST_RESULTS" : {
      let { name, nonce } = action.payload;

      if (state.nonce > nonce) {
        return {...state};
      }

      let newAutosuggest = update(
        state[name] || {}, 
        {$merge: {results: [], more: false, match: {}, init: false}}
      );

      return {...state, [name]: newAutosuggest};
    }
    case "MESSAGES_AUTOSUGGEST_FAVORITE" : {
      let { name="", contact="", favoriteSelected=false, favoritePending=false } = action.payload;            
      
      let hasUpdate = false;
      let resultList = state[name].results || [];
      for (let i = 0; i < resultList.length; i++) {
        let result = resultList[i];
        if (result.value === contact) {
          let newResultObj = JSON.parse(JSON.stringify(result));
          newResultObj.favoriteSelected = favoriteSelected;
          newResultObj.favoritePending = favoritePending;

          resultList = update(
            resultList || [], 
            {[i] : {$set: newResultObj}}
          );

          hasUpdate = true;

          break;
        }
      }

      if (hasUpdate) {
        let newAutosuggest = update(
          state[name] || {}, 
          {$merge: {results: resultList}}
        );
        
        return {...state, [name]: newAutosuggest};  
      }

      return {...state};
    }
    default: {
      return state
    }
  }
}

export default MessagesReducer