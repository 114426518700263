import { track } from './ActionUtils';
import store from '../store';
import moment from 'moment-timezone';

export function openOverlay(dialog, params) {
  return function(dispatch) {
    dispatch({
      type: 'OPEN_OVERLAY',
      payload: {dialog, params}
    });

    let trackParams = (dialog === "photo_preview") ? {} : params;
    dispatch(track("dialog", dialog, JSON.stringify(trackParams)));  
  }
}

export function closeOverlay(data) {
  return function(dispatch) {
    dispatch({
      type: 'CLOSE_OVERLAY',
      payload: data
    });
  }
}

export function tallOverlay(tall, height, scrollToTop) {
  return function(dispatch) {
    let oldTall = store.getState().overlay.tall;

    dispatch({
      type: 'TALL_OVERLAY',
      payload: {tall, height}
    });

    if (oldTall === false && tall === true) {
      window.scrollTo(0, 0);
    }
  }
}

export function initialDialogGUID() {
  let initialDialogGuid = store.getState().overlay.dialogGuid;
  return initialDialogGuid;
}

export function checkDialogGUID(initialDialogGuid) {
  let dialogGuid = store.getState().overlay.dialogGuid;
  return (dialogGuid !== "" && dialogGuid === initialDialogGuid);
}

export function shouldShowDepositWarning(createdUnix, autoResolveUnix) {
  let nowUnix = moment.tz('America/Los_Angeles').unix();
  if (createdUnix !== 0 && autoResolveUnix !== 0) {
    let timeElapsed = nowUnix - createdUnix;
    let totalTime = autoResolveUnix - createdUnix;
    let elapsedPercent = (timeElapsed * 100) / totalTime;

    // Show warning if you've waited over 50% of the time between creation and autoresolution.
    if (elapsedPercent >= 50) {
      return true;
    }
  }
  // Otherwise, if we're within ten days of the autoresolution, show a message. 
  if (autoResolveUnix !== 0 && nowUnix + (10 * 24 * 60 * 60) > autoResolveUnix) {
    return true;
  }
  return false;
}

export function hasTimestampExpired(timestampUnix) {
  let nowUnix = moment.tz('America/Los_Angeles').unix();
  if (timestampUnix !== 0 && nowUnix > timestampUnix) {
    return true;
  }
  return false;
}

export function openOverlayDepositWarning(dialog, params, showWarning) {
  return function(dispatch) {
    if (showWarning) {
      dispatch(openOverlay("deposit_warning", {
        nextDialog: dialog,
        nextParams: params
      }));
    }
    else {
      dispatch(openOverlay(dialog, params));
    }
  }
}