import React, { Component } from 'react';
import './PermissionDeniedCard.css';
import TitleCard from "./TitleCard";

class PermissionDeniedCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    let maybePreviewExpired = this.props.hasPreviewID;

    return (
      <div>
        <TitleCard title="Permission Denied" />
        <div className="permissionDeniedCard">
          <div>You don't have permission to access this page.</div>
          {maybePreviewExpired && <div className="moveTop20">The preview link you're using may have expired, in which case you must be logged in and a participant in the contract to view it.</div>}
          {!maybePreviewExpired && <div className="moveTop20">If this is unexpected, make sure that you're logged in and connected to the internet.</div>}
        </div>
      </div>
    );
  }
}
  
export default PermissionDeniedCard;